import type { AvailableWidgetPreview as TAvailableWidgetPreview, WidgetRef } from 'report/types';

export type AvailableDashboards = {
  id: string;
  title: string;
  widgets: Array<TAvailableWidgetPreview>;
  owner: string;
  search_id: string;
  last_updated_at: Date;
};

export type BackendWidgetRef = {
  dashboard_id: string;
  dashboard_widget_id: string;
};

export const toBackendWidgetRef = ({ widgetId, dashboardId, title, description }: WidgetRef) => ({
  dashboard_id: dashboardId,
  dashboard_widget_id: widgetId,
  title,
  description,
});
export const widgetId = (widget: { id: string }, dashboard: AvailableDashboards): WidgetRef => ({
  widgetId: widget.id,
  dashboardId: dashboard.id,
});
export const compareWidgetRefs = (widgetRef1: WidgetRef, widgetRef2: WidgetRef) =>
  widgetRef1.widgetId === widgetRef2.widgetId && widgetRef1.dashboardId === widgetRef2.dashboardId;
