import * as React from 'react';
import { Formik, Form } from 'formik';

import { Button, Col, Row } from 'components/bootstrap';
import SectionComponent from 'components/common/Section/SectionComponent';

import NameFormGroup from '../form/NameFormGroup';
import DescriptionFormGroup from '../form/DescriptionFormGroup';
import type Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';

type Props = {
  team: Team;
};

const _updateTeam = (team, { name, description }) => {
  const newTeam = team.toBuilder().name(name).description(description).build();

  return TeamsDomain.update(newTeam);
};

const ProfileSection = ({ team }: Props) => {
  const { name, description } = team;

  return (
    <SectionComponent title="Profile">
      <Formik onSubmit={(data) => _updateTeam(team, data)} initialValues={{ name, description }}>
        {({ isSubmitting, isValid }) => (
          <Form className="form form-horizontal">
            <NameFormGroup />
            <DescriptionFormGroup />
            <Row className="no-bm">
              <Col xs={12}>
                <div className="pull-right">
                  <Button bsStyle="success" disabled={isSubmitting || !isValid} title="Update Profile" type="submit">
                    Update Profile
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </SectionComponent>
  );
};

export default ProfileSection;
