import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES, DATA_LAKE_CONFIG_QUERY_KEYS } from 'data-lake/Constants';
import type { Backend } from 'archive/types';

const fetchDataLakeBackend = (id: string) =>
  fetch('GET', qualifyUrl(`${DATA_LAKE_API_ROUTES.DataLakeAPIController.backend.get(id).url}`));

const useDataLakeBackend = (
  id: string,
  enabled = true,
): {
  data: Backend;
  refetch: () => void;
  isInitialLoading: boolean;
  isSuccess: boolean;
  error: Error;
} => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<Backend, Error>(
    [...DATA_LAKE_CONFIG_QUERY_KEYS, id],
    () => fetchDataLakeBackend(id),
    {
      keepPreviousData: true,
      enabled,
    },
  );

  return {
    data: data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  };
};

export default useDataLakeBackend;
