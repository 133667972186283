import * as React from 'react';

import { Spinner } from 'components/common';
import { useListRepoContent } from 'security-app/hooks/useSigmaAPI';
import type { GitRepoAPIModel, GitRepoContent } from 'security-app/hooks/api/sigmaAPI.types';

import PanelHeader from './PanelHeader';
import RuleItem from './RuleItem';
import SearchRule from './SearchRule';

import { WindowBody, WindowFooter } from '../styledComponents';

type Props = {
  repo: GitRepoAPIModel;
  repos: GitRepoAPIModel[];
  loadingRepos: boolean;
  setRepo: (newRepo: GitRepoAPIModel) => void;
};

function LeftPanel({ repo, repos, loadingRepos, setRepo }: Props) {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [repoPath, setRepoPath] = React.useState<string>('');
  const { repoRules, loadingRepoRules } = useListRepoContent(repo?.id, repoPath, searchQuery);

  return (
    <>
      <PanelHeader
        repoRules={repoRules || []}
        repo={repo}
        repoPath={repoPath}
        setRepoPath={setRepoPath}
        setRepo={setRepo}
      />
      <WindowBody>
        {loadingRepoRules || loadingRepos ? (
          <Spinner />
        ) : (
          (repoRules || repos).map((item: GitRepoContent & GitRepoAPIModel) => (
            <RuleItem
              key={`import-modal-${item.id}`}
              item={item}
              repoPath={repoPath}
              setRepo={setRepo}
              setRepoPath={setRepoPath}
            />
          ))
        )}
      </WindowBody>
      {repo && (
        <WindowFooter>
          <SearchRule
            query={searchQuery}
            searching={loadingRepoRules}
            onSearch={(query: string) => setSearchQuery(query)}
          />
        </WindowFooter>
      )}
    </>
  );
}

export default LeftPanel;
