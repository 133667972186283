import type { GraphGenericInputCreateRequest, GraphInputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const toGraphInputCreateRequest = ({
  tenantId,
  graphName,
  clientId,
  clientSecret,
  subscriptionType,
  content_type,
  readTimeOffset,
  graphThrottleEnabled,
  pollingInterval,
}: FormDataType): GraphInputCreateRequest => ({
  name: graphName.value,
  polling_interval: pollingInterval.value,
  tenant_id: tenantId.value,
  enable_throttling: !!graphThrottleEnabled?.value,
  client_id: clientId.value,
  client_secret: clientSecret.value,
  subscription_type: subscriptionType.value,
  content_type: content_type.value.split(','),
  read_time_offset: readTimeOffset.value,
});

export const toGenericInputCreateRequest = ({
  graphName,
  tenantId,
  clientId,
  clientSecret,
  subscriptionType,
  graphThrottleEnabled,
  pollingInterval,
  content_type,
  readTimeOffset,
}: FormDataType): GraphGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.microsoftgraph.GraphInput',
  title: graphName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    tenant_id: tenantId.value,
    throttling_allowed: !!graphThrottleEnabled?.value,
    client_id: clientId.value,
    client_secret: clientSecret.value,
    subscription_type: subscriptionType.value,
    content_type: content_type.value.split(','),
    read_time_offset: readTimeOffset.value,
  },
});
