import * as React from 'react';

import type View from 'views/logic/views/View';
import { MenuItem, Button } from 'components/bootstrap';
import useIsInvestigationDetailsOnSecurityUI from 'security-app/components/Investigations/plugin/shared/useIsInvestigationDetailsOnSecurityUI';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';
import useActionCondition from 'security-app/components/Investigations/plugin/useActionCondition';

import AddEvidenceModal from './shared/AddEvidenceModal';

type Props = {
  dashboard: View;
  modalRef: () => ModalHandler;
};

export const InvestigationsModal = React.forwardRef(
  ({ dashboard }: { dashboard: View }, ref: (r: ModalHandler) => void) => {
    const isAvailable = useActionCondition();

    if (!isAvailable) {
      return null;
    }

    return <AddEvidenceModal ids={[dashboard.id]} type="dashboards" ref={ref} />;
  },
);

const CoreDashboardActions = ({ dashboard, modalRef }: Props) => {
  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const isInvestigationDetailsOnSecurityUI = useIsInvestigationDetailsOnSecurityUI();

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddDashboard = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { dashboards: [dashboard.id] },
    });

    setCollapsed(false);
  };

  return isInvestigationDetailsOnSecurityUI ? (
    <Button bsSize="xs" onClick={onAddDashboard} disabled={disabledAdd}>
      Add to Investigation
    </Button>
  ) : (
    <>
      {disabledAdd || (
        <MenuItem onClick={onAddDashboard} icon="extension">
          Add to investigation
        </MenuItem>
      )}
      <MenuItem icon="extension" onClick={() => modalRef()?.toggle()}>
        Select an investigation
      </MenuItem>
    </>
  );
};

export default CoreDashboardActions;
