import React, { useContext } from 'react';
import styled from 'styled-components';

import { Button, Col, Row } from 'components/bootstrap';

import InputProfileSelectionList from './InputProfileSelectionList';
import InputProfileHelpPanel from './InputProfileHelpPanel';

import { StyledSection, StyledTitle } from '../StyledWizardComponents';
import { ForwarderWizardContext } from '../ForwarderWizardProvider';
import type { InputProfile } from '../../Types';

const StyledHelpComponent = styled.div`
  margin-top: 30px;
`;

type Props = {
  onCreateInputProfileClick: () => void;
  onInputProfileSelect: (inputProfile: InputProfile) => void;
};

const SelectInputProfile = ({ onCreateInputProfileClick, onInputProfileSelect }: Props) => {
  const { selectedForwarder } = useContext(ForwarderWizardContext);

  if (!selectedForwarder) {
    return <div>Please select a Forwarder before continuing to this step.</div>;
  }

  return (
    <Row>
      <Col md={6}>
        <StyledTitle>
          Add Inputs to <em>{selectedForwarder.title}</em>
        </StyledTitle>
        <StyledSection>
          <p>Input Profiles contain a group of Inputs and can be assigned to Forwarders.</p>
          <p>Do you want to create a custom Input Profile?</p>
          <Button bsStyle="success" onClick={onCreateInputProfileClick}>
            Create Input Profile
          </Button>
        </StyledSection>
        <StyledSection>
          <p>Or you can also select an existing Input Profile:</p>
          <InputProfileSelectionList onInputProfileSelect={onInputProfileSelect} />
        </StyledSection>
      </Col>
      <Col md={4} mdOffset={1}>
        <StyledHelpComponent>
          <InputProfileHelpPanel />
        </StyledHelpComponent>
      </Col>
    </Row>
  );
};

export default SelectInputProfile;
