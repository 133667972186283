import * as React from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import AggregationWidgetConfig from 'views/logic/aggregationbuilder/AggregationWidgetConfig';
import Pivot, { DateType } from 'views/logic/aggregationbuilder/Pivot';
import Series from 'views/logic/aggregationbuilder/Series';
import InteractiveContext from 'views/components/contexts/InteractiveContext';
import FullSizeContainer from 'views/components/aggregationbuilder/FullSizeContainer';
import XYPlot from 'views/components/visualizations/XYPlot';

const config = AggregationWidgetConfig.builder()
  .visualization('bar')
  .rowPivots([Pivot.create(['category'], DateType)])
  .series([Series.forFunction('count()')])
  .rollup(false)
  .build();

const height = 180;

const GraphContainer = styled.div`
  height: ${height}px;
  width: 100%;
  margin: 20px 0;
`;
export type Bucket = { tactic: string; id: string; count: number };

const defaultLayout = (type: string) => ({
  type: 'bar',
  name: type,
  textposition: 'auto',
  originalName: type,
  marker: {
    color: '#4478b3',
  },
  outsidetextfont: {
    color: '#F6F7FC',
  },
  showlegend: false,
  xaxis: {
    tickangle: -45,
  },
  yaxis: 'y',
  fullPath: `${type}⸱count()`,
});

export const otherBucket = ['Other', 'Other'] as const;

type XYPlotProps = React.ComponentProps<typeof XYPlot>;
export type GraphProps = {
  onClickMarker: XYPlotProps['onClickMarker'];
  x: Array<string>;
  y: Array<number>;
  text: Array<string>;
  type: string;
};

const Histogram = ({ x, y, text, type, onClickMarker }: GraphProps) => {
  const store = useMemo(
    () =>
      configureStore({
        reducer: (state) => state,
        preloadedState: { view: { activeQuery: 'deadbeef' } },
      }),
    [],
  );
  const onZoom = useCallback(() => true, []);

  const chartData = useMemo(
    () => [
      {
        ...defaultLayout(type),
        x,
        y,
        text,
      },
    ],
    [type, x, y, text],
  );

  return (
    <Provider store={store}>
      <GraphContainer>
        <InteractiveContext.Provider value>
          <FullSizeContainer>
            {(dimensions) => (
              <XYPlot
                config={config}
                chartData={chartData}
                onZoom={onZoom}
                height={height}
                width={dimensions.width}
                onClickMarker={onClickMarker}
              />
            )}
          </FullSizeContainer>
        </InteractiveContext.Provider>
      </GraphContainer>
    </Provider>
  );
};

export default Histogram;
