/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */

type RowEntry = Row | string | number;

type Row = { [key: string]: RowEntry };
type Rows = Array<Row>;

export default (rows: Rows, rowFieldNames: Array<string>) => {
  const duplicateKeys = {};

  return rows.map((item) => {
    const reducedItem = { ...item };
    const entries = Object.entries(reducedItem);

    entries.forEach(([key, value], entryIdx) => {
      if (!rowFieldNames.includes(key)) {
        return;
      }

      if (duplicateKeys[key] === value) {
        delete reducedItem[key];
      } else {
        entries.slice(entryIdx + 1).forEach((entry) => delete duplicateKeys[entry[0]]);

        duplicateKeys[key] = value;
      }
    });

    return reducedItem;
  });
};
