import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';

import {
  INPUT_WIZARD_STEPS,
  useInputSetupWizardSteps,
  useInputSetupWizardStepsHelper,
  ProgressMessage,
} from 'components/inputs/InputSetupWizard';
import { useGetInstallationStatus } from 'illuminate/hooks/useBundlesAPI';
import type { InstallStatus } from 'illuminate/types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { getPathnameWithoutId } from 'util/URLUtils';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import useSetupIlluminate from './hooks/useSetupIlluminate';
import type { EnterpriseStepsData } from './types';

const StartIlluminate = () => {
  const sendTelemetry = useSendTelemetry();
  const { pathname } = useLocation();
  const telemetryPathName = useMemo(() => getPathnameWithoutId(pathname), [pathname]);
  const { enableBundlesMutation } = useSetupIlluminate();
  const { stepsData } = useInputSetupWizardSteps<EnterpriseStepsData>();
  const { getStepData } = useInputSetupWizardStepsHelper<EnterpriseStepsData>();
  const illuminateStepData = getStepData(stepsData, INPUT_WIZARD_STEPS.SELECT_ILLUMINATE);
  const { installationStatus, gettingStatus } = useGetInstallationStatus();
  const [installationHasBeenStarted, setInstallationHasBeenStarted] = useState<boolean>(false);

  useEffect(() => {
    if (illuminateStepData?.selectedIlluminatePacks && illuminateStepData?.selectedIlluminatePacks.length > 0) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.INPUT_SETUP_WIZARD.START_INPUT_ILLUMINATE, {
        app_pathname: telemetryPathName,
        app_action_value: 'trigger-input-setup-wizard-start-illuminate',
        selected_processing_packs: illuminateStepData?.selectedIlluminatePacks,
        selected_spotlight_packs: illuminateStepData?.selectedSpotlightPacks,
      });

      enableBundlesMutation.mutate({
        processing_pack_ids: illuminateStepData?.selectedIlluminatePacks,
        spotlight_pack_ids: illuminateStepData?.selectedSpotlightPacks,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [illuminateStepData?.selectedIlluminatePacks]);

  useEffect(() => {
    if (installationStatus.install_in_progress) {
      setInstallationHasBeenStarted(true);
    }
  }, [installationStatus]);

  if (!illuminateStepData?.selectedIlluminatePacks) return null;
  if (illuminateStepData?.selectedIlluminatePacks.length <= 0) return null;
  if (gettingStatus || (!installationHasBeenStarted && !installationStatus.install_in_progress)) return null;

  const installStatus = installationStatus as InstallStatus;

  return (
    <>
      {installStatus.install_progress.map((progressItem) => (
        <ProgressMessage
          isSuccess={progressItem.successful}
          isError={progressItem.complete && !progressItem.successful}
          isLoading={progressItem.install_in_progress}
          title={progressItem.title}
          details={progressItem.description}
        />
      ))}

      {installationHasBeenStarted && !installationStatus.install_in_progress && installStatus.previous_install && (
        <>
          <ProgressMessage
            isSuccess={installStatus.previous_install.successful}
            isError={!installStatus.previous_install.successful}
            isLoading={false}
            title={installStatus.previous_install.title}
            details={installStatus.previous_install.description}
          />
          {installStatus.previous_install.successful &&
            [
              ...installStatus.previous_install.pack_titles,
              ...installStatus.previous_install.spotlight_pack_titles,
            ].map((packTitle) => <ProgressMessage isSuccess isError={false} isLoading={false} title={packTitle} />)}
        </>
      )}
    </>
  );
};
export default StartIlluminate;
