import * as React from 'react';
import type { ColorVariant } from '@graylog/sawmill';
import styled from 'styled-components';

import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import { IfPermitted, Spinner } from 'components/common';
import { Alert, Col, Row } from 'components/bootstrap';
import AppConfig from 'util/AppConfig';
import type { LicenseSubject } from 'license/types';
import { LICENSE_TYPES, LICENSE_SUBJECTS } from 'license/constants';

import useLicenseValidityForSubject from './hooks/useLicenseValidityForSubject';

const isCloud = AppConfig.isCloud();

type WarningProps = {
  licenseSubject?: LicenseSubject;
  featureName: string;
  title?: string;
  text?: string;
  bsStyle?: ColorVariant;
};

const StyledP = styled.p`
  white-space: break-spaces;
`;

export const Warning = ({
  title = null,
  text = null,
  bsStyle = 'info',
  licenseSubject = LICENSE_SUBJECTS.enterprise,
  featureName,
}: WarningProps) => {
  const licenseType = LICENSE_TYPES[licenseSubject];

  return (
    <Alert bsStyle={bsStyle} title={title ?? `${licenseType} license required`}>
      <StyledP>{text ?? `A valid ${licenseType} license is required to use ${featureName ?? 'this'} functionality.`}</StyledP>
      {isCloud ? (
        <>Contact your Graylog account manager.</>
      ) : (
        <IfPermitted permissions="licenses:create">
          <p>
            See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
          </p>
        </IfPermitted>
      )}
    </Alert>
  );
};

type InvalidLicenseWarningProps = WarningProps & {
  displayWarningContainer: boolean;
};

export const InvalidLicenseWarning = ({
  licenseSubject = LICENSE_SUBJECTS.enterprise,
  featureName,
  displayWarningContainer,
  title = null,
  text = null,
  bsStyle = 'info',
}: InvalidLicenseWarningProps) => {
  if (displayWarningContainer) {
    return (
      <Row className="content">
        <Col md={12}>
          <Warning
            licenseSubject={licenseSubject}
            featureName={featureName}
            title={title}
            text={text}
            bsStyle={bsStyle}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Warning licenseSubject={licenseSubject} featureName={featureName} title={title} text={text} bsStyle={bsStyle} />
  );
};

/**
 * Component which provides a `licenseIsValid` prop. E.g. to display a read only version of its children.
 */
type Props = Partial<InvalidLicenseWarningProps> & {
  children?: React.ReactNode | ((props: { licenseIsValid: boolean }) => React.ReactNode);
  displayLicenseWarning?: boolean;
  hideChildren?: boolean;
  featureName?: string;
  licenseSubject?: string;
  title?: string;
  text?: string;
};

const LicenseCheck = ({
  children = undefined,
  featureName = null,
  licenseSubject = LICENSE_SUBJECTS.enterprise,
  displayLicenseWarning = true,
  displayWarningContainer = false,
  hideChildren = false,
  title = null,
  text = null,
  bsStyle = 'info',
}: Props) => {
  const {
    data: { valid },
    isInitialLoading: isLoading,
  } = useLicenseValidityForSubject(licenseSubject);
  const resultChildren = typeof children === 'function' ? children({ licenseIsValid: valid }) : children;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!valid && displayLicenseWarning && (
        <InvalidLicenseWarning
          licenseSubject={licenseSubject}
          featureName={featureName}
          displayWarningContainer={displayWarningContainer}
          title={title}
          text={text}
          bsStyle={bsStyle}
        />
      )}
      {valid && resultChildren}
      {!valid && !hideChildren && resultChildren}
    </>
  );
};

export default LicenseCheck;
