import * as React from 'react';

import IlluminateInstallStatus from 'illuminate/components/illuminate/IlluminateInstallStatus';
import { useGetInstallationStatus } from 'illuminate/hooks/useBundlesAPI';

export const PacksInstallStatus = ({ children }: { children: React.ReactNode }) => {
  const { installationStatus } = useGetInstallationStatus();
  const { install_in_progress: installInProgress } = React.useMemo(() => installationStatus, [installationStatus]);

  if (!installInProgress) {
    return <>{children}</>;
  }

  return <IlluminateInstallStatus installStatusObj={installationStatus} />;
};

export default PacksInstallStatus;
