import Reflux from 'reflux';
import URI from 'urijs';

import { qualifyUrl } from 'util/URLUtils';
import Store from 'logic/local-storage/Store';
import { Builder } from 'logic/rest/FetchProvider';
import { SessionActions, SessionStore } from 'stores/sessions/SessionStore';
import AppConfig from 'util/AppConfig';

import { OidcConfigurationStore } from './OidcConfigurationStore';

export const OidcSessionActions = Reflux.createActions({
  login: { asyncResult: true },
  logout: {},
});

const TOKEN_STORE_KEY = 'oidcToken';
const isCloud = AppConfig.isCloud();
export const OidcSessionStore = Reflux.createStore({
  listenables: [OidcSessionActions],
  sourceUrl: '/system/sessions',
  baseUrl: undefined,
  idToken: Store.get(TOKEN_STORE_KEY),
  isLoading: false,

  init() {
    if (isCloud) {
      this.listenTo(OidcConfigurationStore, this.handleConfigChange, this.handleConfigChange);
      this.listenTo(SessionActions.logout.completed, this.handleGraylogLogout);
      this.listenTo(SessionActions.validate.completed, this.handleGraylogValidation);
    }
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    return { isLoading: this.isLoading };
  },

  propagateState() {
    this.trigger(this.getState());
  },

  login(authCode) {
    const builder = new Builder('POST', qualifyUrl(this.sourceUrl)).json({ authorization_code: authCode });
    const promise = builder.build();

    promise.then(({ session_id: sessionId, username, id_token: idToken }) => {
      this.idToken = idToken;
      const session = { sessionId: sessionId, username: username };
      SessionActions.login.completed(session);

      return session;
    });

    OidcSessionActions.login.promise(promise);
  },

  loginCompleted() {
    Store.set(TOKEN_STORE_KEY, this.idToken);
  },

  handleConfigChange(stateChange) {
    if (!stateChange.configuration) {
      return;
    }

    const { configuration } = stateChange;
    this.baseUrl = configuration.base_url;
  },

  handleGraylogValidation() {
    if (this.idToken && !SessionStore.isLoggedIn()) {
      if (this.baseUrl) {
        this.handleGraylogLogout();
      } else {
        // Without a valid Graylog session we can't fetch the OIDC log-out URL, clear the session from localstorage.
        this.clearOidcSession();
      }
    }
  },

  clearOidcSession(redirectTo = '/') {
    Store.delete(TOKEN_STORE_KEY);
    this.idToken = undefined;
    window.location.replace(redirectTo);
  },

  handleGraylogLogout() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.propagateState();

    OidcSessionActions.logout();
  },

  logout() {
    const logoutUri = new URI(this.baseUrl);
    logoutUri.segment('v1/logout');

    logoutUri.query({
      id_token_hint: this.idToken,
      post_logout_redirect_uri: window.location.origin,
    });

    this.clearOidcSession(logoutUri.href());
  },
});
