import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';

type Props = {
  fieldName: string;
  inputOptions: Array<{ label: string; value: string }> | undefined;
};

const FieldContainer = styled.div`
  flex-basis: 100%;
`;

const InputFilterFieldValue = ({ fieldName, inputOptions }: Props) => (
  <Field name={fieldName} required>
    {({ field: { name, value, onChange }, meta: { error } }) => (
      <FieldContainer>
        <Input error={error} name={name} formGroupClassName="" id={name}>
          <Select
            options={inputOptions ?? []}
            value={value}
            inputId={name}
            onChange={(newVal) => {
              onChange({ target: { value: newVal, name } });
            }}
            placeholder="Input"
            allowCreate
          />
        </Input>
      </FieldContainer>
    )}
  </Field>
);

export default InputFilterFieldValue;
