import { createGlobalStyle, css } from 'styled-components';

import type { Orientation } from 'search/ExportWidgetAction/types';
import { PAGE_PRINT_MARGIN, PAGE_PRINT_MARGIN_LARGE } from 'report/Constants';

const pdfExportTableStyles = ({ $orientation }: { $orientation: Orientation }) => css`
  .table-data-row .table-data-cell,
  .fields-row.message-group td {
    min-width: ${$orientation === 'portrait' ? '50px' : '75px'}!important;
    max-width: ${$orientation === 'portrait' ? undefined : '700px'}!important;
  }
`;

const printMargin = (needsLargeMargin: boolean) => (needsLargeMargin ? PAGE_PRINT_MARGIN_LARGE : PAGE_PRINT_MARGIN);

const GlobalPDFStyles = createGlobalStyle<{
  $orientation: Orientation;
  $rootSelector?: string;
  $printHeader?: boolean;
  $printFooter?: boolean;
}>(
  ({ $orientation, $rootSelector = 'html body', $printHeader = false, $printFooter = false }) => css`
    ${$rootSelector} {
      margin-top: 0;
      font-size: 18px;

      ${pdfExportTableStyles({ $orientation })}
    }

    @media print {
      @page {
        margin: ${printMargin($printHeader)} ${PAGE_PRINT_MARGIN} ${printMargin($printFooter)} ${PAGE_PRINT_MARGIN};
      }
    }
  `,
);

export default GlobalPDFStyles;
