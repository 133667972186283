import * as React from 'react';
import styled, { css } from 'styled-components';

import { Badge } from 'components/bootstrap';
import useHeaderBadge from 'customization/header-badge/useHeaderBadge';

const StyledBadge = styled(Badge)<{ $color: string }>(
  ({ $color, theme }) => css`
    background-color: ${$color};
    color: ${theme.utils.readableColor($color)};
  `,
);

type Props = {
  smallScreen?: boolean;
};

const HeaderBadge = ({ smallScreen = false }: Props) => {
  const smallScreenClass = smallScreen ? 'small-scrn-badge' : '';
  const { data: badgeConfig } = useHeaderBadge();

  if (badgeConfig?.badge_enable) {
    return (
      <StyledBadge $color={badgeConfig.badge_color as string} className={`${smallScreenClass} dev-badge`}>
        {badgeConfig.badge_text}
      </StyledBadge>
    );
  }

  return null;
};

export default HeaderBadge;
