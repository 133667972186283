import * as React from 'react';
import styled from 'styled-components';

import useFeature from 'hooks/useFeature';
import Description from 'report/common/Description';

const Container = styled.div`
  padding-top: 30%;
  min-height: 600px;
`;

const Headline = styled.h1`
  color: black !important;
  font-size: 2em;
  text-align: center;
  margin-bottom: 0.8em;
`;

const Subheadline = styled.h2`
  color: #555 !important;
  font-size: 1.6em;
  text-align: center;
`;

const Logo = styled.div`
  margin-left: auto;
  margin-top: 100px;
  padding: 10px;
  text-align: center;

  img {
    max-height: 150px;
    max-width: 150px;
  }
`;

type Props = {
  description: string;
  logo: string | undefined;
  subtitle: string | undefined;
  title: string;
};

const CoverPage = ({ title, description, subtitle, logo }: Props) => {
  const newReportCreationEnabled = useFeature('new_report_creation');

  return (
    <Container>
      <Headline>{title}</Headline>
      <Subheadline className="has-bm">{subtitle}</Subheadline>
      {logo && !newReportCreationEnabled && (
        <Logo>
          <img src={logo} alt="report-logo" />
        </Logo>
      )}
      {description && <Description>{description}</Description>}
    </Container>
  );
};

export default CoverPage;
