import { useMemo } from 'react';

import Query from 'views/logic/queries/Query';
import Search from 'views/logic/search/Search';
import SearchResult from 'views/logic/SearchResult';
import { ICEBERG_QUERY, PREVIEW_JOB_STATUS } from 'data-lake/preview/Constants';
import type { FormValues } from 'data-lake/preview/SearchBar';
import useFetchActiveSearch from 'data-lake/preview/hooks/useFetchActiveSearch';
import type { PreviewJobStatus } from 'data-lake/preview/Types';

// If the active search has one of the following statuses, we want to make use of its data.
const VALID_INITIAL_SEARCH_STATUSES: Array<Partial<PreviewJobStatus>> = [
  PREVIEW_JOB_STATUS.done,
  PREVIEW_JOB_STATUS.running,
  PREVIEW_JOB_STATUS.expired,
  PREVIEW_JOB_STATUS.error,
];

const useActiveSearch = (initialUrlParams: FormValues) => {
  const { data, isFetching: isLoading, refetch } = useFetchActiveSearch();
  const hasInitialUrlParams = Object.values(initialUrlParams).some((filter) => !!filter);

  return useMemo(() => {
    if (hasInitialUrlParams || !data || !VALID_INITIAL_SEARCH_STATUSES.includes(data.status)) {
      return {
        search: undefined,
        result: undefined,
        isLoading,
        searchJob: {
          status: data?.status,
          progress: data?.progress,
          nodeId: data?.executing_node,
          id: data?.id,
        },
        refetch,
      };
    }

    const searchType = data.result.query.search_types[0];
    const query = Query.builder().id(data.result.query.id).query(ICEBERG_QUERY).searchTypes([searchType]).build();

    return {
      isLoading,
      search: Search.create().toBuilder().id(data.search_id).queries([query]).build(),
      result: {
        result: new SearchResult({
          results: { [data.result.query.id]: data.result },
          id: data.id,
          owner: data.owner,
          search_id: data.search_id,
          errors: data.error_message,
          execution: { done: true, cancelled: false, completed_exceptionally: false },
        }),
      },
      searchJob: {
        progress: data.progress,
        status: data.status,
        nodeId: data.executing_node,
        id: data.id,
      },
      refetch,
    };
  }, [data, hasInitialUrlParams, isLoading, refetch]);
};

export default useActiveSearch;
