import React from 'react';

type ThreatFoxAdapterSummaryProps = {
  dataAdapter: {
    config: {
      include_older_iocs: boolean;
      refresh_interval: number;
      case_insensitive_lookup: boolean;
    };
  };
};

const ThreatFoxAdapterSummary = ({ dataAdapter }: ThreatFoxAdapterSummaryProps) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>Include Older IOCs</dt>
      <dd>{config.include_older_iocs ? 'Yes' : 'No'}</dd>
      <dt>Refresh Interval</dt>
      <dd>{config.refresh_interval} seconds </dd>
      <dt>Case Insensitive Lookups</dt>
      <dd>{config.case_insensitive_lookup ? 'Yes' : 'No'}</dd>
    </dl>
  );
};

export default ThreatFoxAdapterSummary;
