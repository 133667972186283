import * as React from 'react';
import styled from 'styled-components';
import { create, windowScheduler, keyResolver } from '@yornaath/batshit';
import { useQuery } from '@tanstack/react-query';

import { SystemCatalog } from '@graylog/server-api';

import { Spinner } from 'components/common';

const assetNames = create({
  fetcher: (ids: Array<string>) =>
    SystemCatalog.getTitles({ entities: ids.map((id) => ({ id, type: 'assets' })) }).then(({ entities }) => entities),
  resolver: keyResolver('id'),
  scheduler: windowScheduler(10),
});

const useAssetName = (
  assetId: string,
): {
  data: string | undefined;
  isInitialLoading: boolean;
} => {
  const { data, isInitialLoading } = useQuery<{ title: string }>(['assets', 'name', assetId], () =>
    assetNames.fetch(assetId),
  );

  return {
    data: data?.title,
    isInitialLoading,
  };
};

const AssetName = ({ assetId }: { assetId: string }) => {
  const { data: assetName, isInitialLoading } = useAssetName(assetId);

  if (isInitialLoading) {
    return (
      <span>
        <Spinner size="sm" />
      </span>
    );
  }

  return <span title={assetId}>{assetName ?? assetId}</span>;
};

const AssetsList = styled.span`
  span:not(:last-child)::after {
    content: ', ';
  }
`;

type Props = {
  value: string | Array<string>;
};

const AssetsField = ({ value }: Props) =>
  Array.isArray(value) ? (
    <AssetsList>
      {value.map((assetId) => (
        <AssetName assetId={assetId} key={assetId} />
      ))}
    </AssetsList>
  ) : (
    String(value)
  );

export default AssetsField;
