import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { useStore } from 'stores/connect';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';

import ForwardersListComponent from './forwarders/ForwardersListComponent';
import InputProfilesStore, { InputProfilesActions } from './stores/InputProfilesStore';
import EmptyForwarderListComponent from './forwarders/EmptyForwarderListComponent';
import ForwardersContext from './forwarders/ForwardersContext';
import PaginatedForwarderListProvider from './forwarders/PaginatedForwarderListProvider';
import withIsPermitted from './util/withIsPermitted';
import ForwarderPageNavigation from './ForwarderPageNavigation';

const REQUIRED_PERMISSIONS = ['forwarders:read', 'inputprofiles:read'];

const ForwardersPage = () => {
  const { all: inputProfiles } = useStore(InputProfilesStore);

  useEffect(() => {
    InputProfilesActions.listAll();
  }, []);

  return (
    <DocumentTitle title="Forwarders">
      <ForwarderPageNavigation />
      <PageHeader
        title="Forwarders"
        documentationLink={{
          title: 'Forwarder documentation',
          path: ForwarderDocs.FORWARDER,
        }}>
        <span>List of Forwarders that have registered with this Graylog instance.</span>
      </PageHeader>

      <LicenseCheck
        licenseSubject={LICENSE_SUBJECTS.forwarder}
        displayWarningContainer
        featureName="Forwarder"
        bsStyle="danger"
      />
      <PaginatedForwarderListProvider>
        <ForwardersContext.Consumer>
          {({ forwarders, pagination, changeQuery, changeSort, changeStateFilter, isLoading }) => {
            if (forwarders && inputProfiles && pagination) {
              return forwarders.length === 0 && isEmpty(pagination?.query) && pagination?.stateFilter === 'any' ? (
                <EmptyForwarderListComponent />
              ) : (
                <ForwardersListComponent
                  forwarders={forwarders}
                  isLoading={isLoading}
                  inputProfiles={inputProfiles}
                  pagination={pagination}
                  onQueryChange={changeQuery}
                  onSortChange={changeSort}
                  onStateFilterChange={changeStateFilter}
                />
              );
            }

            return <Spinner />;
          }}
        </ForwardersContext.Consumer>
      </PaginatedForwarderListProvider>
    </DocumentTitle>
  );
};

export default withIsPermitted(ForwardersPage, REQUIRED_PERMISSIONS);
