import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import Route from './common/Routes';

type GCPInputConfigurationProps = {
  url?: string;
};

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state
const GCPInputConfiguration = ({ url = Route.INTEGRATIONS.GCP.ACTIVITYAPI.index }: GCPInputConfigurationProps) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

export default GCPInputConfiguration;
