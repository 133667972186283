import * as React from 'react';

import useLocation from 'routing/useLocation';
import useLicenseCheck from 'license/useLicenseCheck';
import withIsPermitted from 'forwarder/util/withIsPermitted';
import Permissions from 'security-app/Permissions';
import StringUtils from 'util/StringUtils';
import { DocumentTitle, Spinner } from 'components/common';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';
import { Container, ContentArea } from 'components/security/page-layout';
import SecurityTeaserPage from 'components/security/teaser/SecurityPage';

const REQUIRED_PERMISSIONS = [Permissions.securityApp.read];

const SecurityLayout = ({ children }: React.PropsWithChildren) => {
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';
  const [title, setTitle] = React.useState('security');
  const {
    security: { isValid: isValidSecurityLicense },
  } = useLicenseCheck();
  const location = useLocation();
  const { isLoading: isLoadingSearchVersion } = useSearchVersionCheck('opensearch');

  React.useEffect(() => {
    const searchPattern = /\/security\//;
    const newLocation: string = location.pathname.replace(searchPattern, '');

    if (newLocation === '/security') {
      setTitle('Security');
    } else {
      setTitle(StringUtils.toTitleCase(newLocation, '-'));
    }
  }, [location]);

  if (isLoadingSearchVersion) {
    return <Spinner text="Loading security module ..." />;
  }

  if (!isValidSecurityLicense) {
    return <SecurityTeaserPage />;
  }

  return (
    <DocumentTitle title={title}>
      <Container>
        <ContentArea $isSecurityPerspective={isSecurityPerspective}>{children}</ContentArea>
      </Container>
    </DocumentTitle>
  );
};

export default withIsPermitted(SecurityLayout, REQUIRED_PERMISSIONS);
