import React from 'react';

import ArchivePageHeaderDescription from 'archive/components/ArchivePageHeaderDescription';
import ArchiveConfigForm from 'archive/components/ArchiveConfigForm';
import { Row, Col } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { ARCHIVE_LICENSE_WARNING_TITLE, ARCHIVE_LICENSE_WARNING_TEXT } from 'archive/constants';

import ArchivePageNavigation from './components/ArchivePageNavigation';

const ArchiveConfigurationPage = () => (
  <DocumentTitle title="Archives configuration">
    <ArchivePageNavigation />
    <PageHeader title="Edit archives configuration">
      <ArchivePageHeaderDescription />
    </PageHeader>
    <LicenseCheck
      text={ARCHIVE_LICENSE_WARNING_TEXT}
      title={ARCHIVE_LICENSE_WARNING_TITLE}
      licenseSubject={LICENSE_SUBJECTS.archive}
      displayWarningContainer
      bsStyle="danger"
    />
    <Row className="content">
      <Col lg={8}>
        <ArchiveConfigForm />
      </Col>
    </Row>
  </DocumentTitle>
);

export default ArchiveConfigurationPage;
