import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Icon, Spinner } from 'components/common';
import { Button, Input } from 'components/bootstrap';
import type { Forwarder } from 'forwarder/Types';

import { ForwarderWizardContext } from '../ForwarderWizardProvider';

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

type Props = {
  forwarders: Array<Forwarder>;
  onForwarderSelect: (forwarder: Forwarder) => void;
};

const ForwarderSelectionList = ({ forwarders, onForwarderSelect }: Props) => {
  const { setSelectedForwarder: setNextSelectedForwarder } = useContext(ForwarderWizardContext);
  const [selectedForwarder, setSelectedForwarder] = useState<Forwarder>();

  const handleSelection = (event) => {
    const { value: id } = event.target;
    const forwarder = forwarders.find((f) => f.id === id);

    if (forwarder === undefined) {
      throw new Error('Selected Forwarder is not in the list of Forwarders.');
    }

    setSelectedForwarder(forwarder);
  };

  const handleForwarderSelect = (event) => {
    event.preventDefault();

    if (selectedForwarder) {
      setNextSelectedForwarder(selectedForwarder);
      onForwarderSelect(selectedForwarder);
    }
  };

  return (
    <form onSubmit={handleForwarderSelect}>
      <div>
        {forwarders.length === 0 ? (
          <>
            <p>
              <StyledIcon name="info" /> There are no new Forwarders to configure.
            </p>
            <p>
              <Spinner text="Looking for Forwarders..." />
            </p>
          </>
        ) : (
          <>
            {forwarders.map((forwarder) => (
              <Input
                key={forwarder.hostname}
                id={forwarder.id}
                type="radio"
                name="forwarder"
                value={forwarder.id}
                label={
                  <span>
                    {forwarder.hostname} <small>({forwarder.node_id})</small>
                  </span>
                }
                onChange={handleSelection}
              />
            ))}
            <p>
              <Spinner text="Looking for more Forwarders..." />
            </p>
          </>
        )}
      </div>
      <Button type="submit" bsStyle="primary" disabled={selectedForwarder === undefined}>
        Configure selected Forwarder
      </Button>
    </form>
  );
};

export default ForwarderSelectionList;
