import * as React from 'react';

import type { AssetAPIType, MachineAssetAPIType } from 'security-app/hooks/api/assetsAPI.types';
import { DataTable } from 'security-app/components/common';

type Props = {
  machineAsset: AssetAPIType<MachineAssetAPIType>;
};

function MachineDetails({ machineAsset }: Props) {
  return (
    <DataTable condensed striped>
      <tbody>
        <DataTable.Row>
          <DataTable.Item $label>Category</DataTable.Item>
          <DataTable.Item>{machineAsset.category.join(', ') || <i>Uncategorized</i>}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Type</DataTable.Item>
          <DataTable.Item>{machineAsset.details.type}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Owner</DataTable.Item>
          <DataTable.Item>{machineAsset.details.owner || <i>No owner assigned</i>}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Hostnames</DataTable.Item>
          <DataTable.Item>{machineAsset.details.hostnames.join(', ') || <i>No hostnames</i>}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>IP Addresses</DataTable.Item>
          <DataTable.Item>{machineAsset.details.ip_addresses.join(', ') || <i>No IPs</i>}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>MAC Addresses</DataTable.Item>
          <DataTable.Item>{machineAsset.details.mac_addresses.join(', ') || <i>No MACs</i>}</DataTable.Item>
        </DataTable.Row>
      </tbody>
    </DataTable>
  );
}

export default MachineDetails;
