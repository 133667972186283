import { IlluminateInstall, IlluminateCustomize, Illuminate } from 'illuminate/pages';

export default {
  routes: [
    { path: '/illuminate', component: Illuminate },
    { path: '/illuminate/customize', component: IlluminateCustomize },
    { path: '/illuminate/customize/:id', component: IlluminateCustomize },
    { path: '/illuminate/install', component: IlluminateInstall },
  ],
};
