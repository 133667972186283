import styled from 'styled-components';

const NoEntitiesExistWrapper = styled.div`
  width: 100%;

  & > div {
    margin-top: 0;
    width: 100%;
  }
`;

export default NoEntitiesExistWrapper;
