import * as React from 'react';

import type { UserAPIType, TeamAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import { useGetUsers, useGetTeams } from 'security-app/hooks/useInvestigationsAPI';
import FilterValueSelect from 'views/components/widgets/overview-configuration/filters/FilterValueSelect';

type Props = {
  value?: string;
  onSelect: (newValue: string) => void;
  selectedAssignees: Array<string>;
};

const AssigneeFilter = ({ value, onSelect, selectedAssignees }: Props) => {
  const { users } = useGetUsers();
  const { teams } = useGetTeams();

  const assignToOptions = React.useMemo(
    () => [
      { value: 'Users', label: 'Users', disabled: true },
      ...users
        .map((user: UserAPIType) => ({
          value: user.id,
          label: user.full_name,
          disabled: selectedAssignees.includes(user.id),
        }))
        .sort((a: { label: string }, b: { label: string }) => {
          if (a.label > b.label) return 1;
          if (a.label < b.label) return -1;

          return 0;
        }),

      { value: 'Teams', label: 'Teams', disabled: true },
      ...teams
        .map((team: TeamAPIType) => ({
          value: team.id,
          label: team.name,
          disabled: selectedAssignees.includes(team.id),
        }))
        .sort((a: { label: string }, b: { label: string }) => {
          if (a.label > b.label) return 1;
          if (a.label < b.label) return -1;

          return 0;
        }),
    ],
    [users, teams, selectedAssignees],
  );

  return (
    <FilterValueSelect
      value={value}
      onChange={onSelect}
      placeholder="Select user or team..."
      options={assignToOptions}
    />
  );
};

export default AssigneeFilter;
