import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Row } from 'common/components';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';

const WarningRow = styled(Row)`
  color: ${({ theme }) => theme.colors.variant.warning};
`;

type Props = {
  mutableRules: Array<SigmaRuleListAPIType>;
  immutableRules: Array<SigmaRuleListAPIType>;
};

function DeleteMessage({ mutableRules, immutableRules }: Props) {
  return (
    <>
      {mutableRules.length > 0 && (
        <>
          <b>
            Are you sure you want to delete the following {mutableRules.length > 1 ? 'rules' : 'rule'}:
          </b>
          {mutableRules.map((rule: SigmaRuleListAPIType) => (<span key={rule.id}>{rule.title}</span>))}
        </>
      )}
      {immutableRules.length > 0 && (
        <>
          <WarningRow $align="center" style={{ marginTop: '1rem' }}>
            <Icon name="warning" />
            <b>The following rules won&apos;t be deleted</b>
          </WarningRow>
          <strong>The following rules are part of Illuminate, to removed them go into illuminate hub and uninstall the packages related to them</strong>
          {immutableRules.map((immutableRule: SigmaRuleListAPIType) => (<span key={immutableRule.id}>{immutableRule.title}</span>))}
        </>
      )}
    </>
  );
}

export default DeleteMessage;
