import __request__ from 'routing/request';
interface DataLakeBackendSettingsDTO {
    readonly type: string;
}
interface DataLakeBackendConfig {
    readonly settings: DataLakeBackendSettingsDTO;
    readonly description: string;
    readonly id: string;
    readonly title: string;
}
type DataLakeBackendConfigArray = DataLakeBackendConfig[];
/**
 * Get backend configurations
 */
export function getAll(): Promise<DataLakeBackendConfigArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create new backend config
 */
export function createConfig(config: DataLakeBackendConfig): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends', config, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check if the backend is active
 */
export function hasActiveBackend(): Promise<boolean> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/active', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update backend config
 */
export function updateConfig(config: DataLakeBackendConfig, backendId: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${backendId}`, config, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get backend configuration
 */
export function getConfig(backendId?: string): Promise<DataLakeBackendConfig> {
    return __request__('GET', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${backendId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete backend configuration
 */
export function remove(id: string): Promise<DataLakeBackendConfig> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
