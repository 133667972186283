import LogViewWidget from './logic/LogViewWidget';

const LogViewConfigGenerator = (widget: LogViewWidget) => {
  const {
    config: { fields, size, sort, after, tieBreaker },
  } = widget;

  return [
    {
      type: LogViewWidget.type,
      fields,
      size,
      sort,
      after,
      tie_breaker: tieBreaker,
    },
  ];
};

export default LogViewConfigGenerator;
