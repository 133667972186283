import * as React from 'react';

import PageNavigation from 'components/common/PageNavigation';
import Routes from 'routing/Routes';
import { Row } from 'components/bootstrap';

const AuditLogPageNavigation = () => {
  const NAV_ITEMS = [
    { title: 'Overview', path: Routes.pluginRoute('SYSTEM_AUDITLOG'), exactPathMatch: true },
    {
      title: 'Configuration',
      path: Routes.pluginRoute('SYSTEM_AUDITLOG_CONFIGURATION'),
      permissions: 'eventdefinitions:read',
    },
  ];

  return (
    <Row>
      <PageNavigation items={NAV_ITEMS} />
    </Row>
  );
};

export default AuditLogPageNavigation;
