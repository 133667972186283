import React, { useEffect } from 'react';

import ArchivePageHeaderDescription from 'archive/components/ArchivePageHeaderDescription';
import ArchiveBackends from 'archive/components/ArchiveBackends';
import type { ArchiveBackendsStoreState } from 'archive/ArchiveBackendsStore';
import ArchiveBackendsStore, { ArchiveBackendsActions } from 'archive/ArchiveBackendsStore';
import type { Backend, Pagination } from 'archive/types';
import { PAGE_SIZES } from 'archive/hooks/useArchivesQuery';
import type { Store } from 'stores/StoreTypes';
import AppConfig from 'util/AppConfig';
import Routes from 'routing/Routes';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { Button, Row, Col } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import connect from 'stores/connect';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { ARCHIVE_LICENSE_WARNING_TITLE, ARCHIVE_LICENSE_WARNING_TEXT } from 'archive/constants';

import ArchivePageNavigation from './components/ArchivePageNavigation';

const isCloud = AppConfig.isCloud();

type Props = {
  backends?: {
    backends: Array<Backend>;
    pagination: Pagination;
  };
};

const ArchiveBackendsPage = ({ backends }: Props) => {
  const { page, pageSize } = usePaginationQueryParameter(PAGE_SIZES);
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    ArchiveBackendsActions.listBackends(page, pageSize);
  }, [page, pageSize]);

  const _onDelete = (backend: Backend, deleteArchives: boolean) => {
    ArchiveBackendsActions.deleteBackend(backend, deleteArchives).then(() => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.BACKEND_CONFIGURATION_DELETED, {
        app_pathname: 'archive',
        app_section: 'backend-configuration',
      });
    });
  };

  const _onPageChange = (newPage: number, newPageSize: number) => {
    ArchiveBackendsActions.listBackends(newPage, newPageSize);
  };

  if (!backends) {
    return <Spinner text="Loading backends" />;
  }

  return (
    <DocumentTitle title="Archive backend configuration">
      <ArchivePageNavigation />
      <PageHeader
        title="Edit archive backend configuration"
        actions={
          !isCloud && (
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS_NEW')}>
              <Button bsStyle="success">Create backend</Button>
            </LinkContainer>
          )
        }>
        <ArchivePageHeaderDescription />
      </PageHeader>
      <LicenseCheck
        text={ARCHIVE_LICENSE_WARNING_TEXT}
        title={ARCHIVE_LICENSE_WARNING_TITLE}
        licenseSubject={LICENSE_SUBJECTS.archive}
        displayWarningContainer
        bsStyle="danger"
      />
      <Row className="content archive-backends">
        <Col lg={12}>
          <ArchiveBackends
            backends={backends.backends}
            pagination={backends.pagination}
            onDelete={_onDelete}
            onPageChange={_onPageChange}
          />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default connect(
  ArchiveBackendsPage,
  { archiveBackends: ArchiveBackendsStore as Store<ArchiveBackendsStoreState> },
  ({ archiveBackends, ...otherProps }) => ({
    backends: archiveBackends.backends,
    ...otherProps,
  }),
);
