import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import { ButtonToolbar, Button } from 'components/bootstrap';

import type Team from '../../logic/Team';

type Props = {
  team: Team | null | undefined;
};

const TeamActionLinks = ({ team }: Props) => {
  if (!team) {
    return null;
  }

  return (
    <ButtonToolbar>
      <LinkContainer to={Routes.pluginRoute('SYSTEM_TEAMS_TEAMID')(team.id)}>
        <Button bsStyle="success">View Details</Button>
      </LinkContainer>
      <LinkContainer to={Routes.pluginRoute('SYSTEM_TEAMS_TEAMID_EDIT')(team.id)}>
        <Button bsStyle="success">Edit Team</Button>
      </LinkContainer>
    </ButtonToolbar>
  );
};

export default TeamActionLinks;
