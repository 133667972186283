import React, { useCallback } from 'react';

import { DataLake } from '@graylog/enterprise-api';

import { Modal } from 'components/bootstrap';
import { Pluralize, ModalSubmit } from 'components/common';
import RetrievalHelp from 'data-lake/RetrievalHelp';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';
import UserNotification from 'util/UserNotification';
import useSelectableLogViewMessages from 'logview/components/contexts/useSelectableLogViewMessages';

type Props = {
  onCancel: () => void;
  streamId: string;
};

const handleFailures = (failures: Array<{ entity_id: string }> = []) => {
  if (failures.length) {
    UserNotification.error('Could not launch retrieval Data Lake messages operation.');
  } else {
    UserNotification.success('Data Lake messages retrieval operation launched successfully.', 'Success');
  }
};

const BulkRetrievalModal = ({ onCancel, streamId }: Props) => {
  const { selectedEntities } = useSelectedEntities();
  const { selectableLogViewMessages } = useSelectableLogViewMessages();

  const onSubmit = useCallback(() => {
    const selectedSet = new Set(selectedEntities);
    const selectedMessages = selectableLogViewMessages.filter(({ id }) => selectedSet.has(id));

    DataLake.retrieveByrequest({
      messages: selectedMessages,
      stream_id: streamId,
      timeout: undefined,
    })
      .then((response: { failures: Array<{ entity_id: string }> }) => {
        handleFailures(response?.failures);
        onCancel();
      })
      .catch((error) => {
        UserNotification.error(`An error occurred while retrieving data. ${error}`);
      });
  }, [selectedEntities, selectableLogViewMessages, streamId, onCancel]);

  return (
    <Modal show onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Retrieving from Data Lake</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are about to retrieve {selectedEntities?.length}{' '}
          <Pluralize singular="log" plural="logs" value={selectedEntities?.length} />.
        </p>
        <RetrievalHelp />
      </Modal.Body>
      <Modal.Footer>
        <ModalSubmit onCancel={onCancel} submitButtonText="Retrieve" onSubmit={onSubmit} />
      </Modal.Footer>
    </Modal>
  );
};

export default BulkRetrievalModal;
