import React from 'react';
import * as Immutable from 'immutable';

import { Button, Col, ControlLabel, Form, FormGroup, HelpBlock } from 'components/bootstrap';
import type Parameter from 'views/logic/parameters/Parameter';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import TypeSpecificParameterInput from 'enterprise/parameters/components/TypeSpecificParameterInput';
import type ParameterBinding from 'views/logic/parameters/ParameterBinding';

type Props = {
  parameters: ParameterMap;
  onSubmit: (newParameterValues: Immutable.Map<string, ParameterBinding>) => void;
  searchId: string;
};

export default class ParameterInputForm extends React.Component<Props, any> {
  static defaultProps = {
    onSubmit: () => {},
  };

  constructor(props: Props) {
    super(props);

    const values = {};

    props.parameters.valueSeq().forEach((param: Parameter) => {
      if (!param.needsBinding) {
        return;
      }

      const value = (param.binding && param.binding.value) || param.defaultValue;

      if (value) {
        values[param.name] = value;
      }
    });

    this.state = values;
  }

  handleValueChange = (name: string, value: any) => this.setState({ [name]: value });

  handleFormSubmit = (e?: React.FormEvent<Form>) => {
    const { onSubmit } = this.props;

    if (e) {
      e.preventDefault();
    }

    onSubmit(Immutable.Map<string, ParameterBinding>(this.state));
  };

  render() {
    const { parameters, searchId } = this.props;
    const parameterInputs = parameters
      .valueSeq()
      .map((parameter: Parameter) => {
        const { name, title, description } = parameter;
        // eslint-disable-next-line react/destructuring-assignment
        const parameterValue = this.state[name] || '';

        return (
          <FormGroup key={`parameter-${name}`} controlId={`parameter-${name}`}>
            <Col sm={9} smOffset={3}>
              <ControlLabel>{title}</ControlLabel>
              <TypeSpecificParameterInput
                parameter={parameter}
                value={parameterValue}
                onChange={this.handleValueChange}
                searchId={searchId}
              />
              <HelpBlock>{description}</HelpBlock>
            </Col>
          </FormGroup>
        );
      })
      .toArray();

    return (
      <Form horizontal onSubmit={this.handleFormSubmit}>
        <Col md={8} mdOffset={1}>
          {parameterInputs}
        </Col>
        <Col md={8} mdOffset={3}>
          <Button type="submit" bsStyle="success">
            Continue
          </Button>
        </Col>
      </Form>
    );
  }
}
