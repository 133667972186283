import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type { DataLake } from 'data-lake/Types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';

export type DataLakeRetrievalOperationDeleteProps = {
  streamId: string;
  retrievalOperationId: string;
};

const fetchDataLake = (streamId: string) =>
  fetch('GET', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.get(streamId).url));

const onDeleteDataLakeRetrievalOperation = ({
  streamId,
  retrievalOperationId,
}: DataLakeRetrievalOperationDeleteProps) =>
  fetch('DELETE', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.delete(streamId, retrievalOperationId).url));

const useDataLake = (
  streamId: string,
): {
  dataLakeData: {
    data: DataLake;
    refetch: () => void;
    isFetching: boolean;
    error: Error;
  };
  onDeleteDataLakeRetrievalOperationMutation: (
    retrievalOperationDeleteProps: DataLakeRetrievalOperationDeleteProps,
  ) => Promise<void>;
} => {
  const queryClient = useQueryClient();
  const { data, refetch, isFetching, error } = useQuery<DataLake, Error>(
    ['data-lake', streamId],
    () => fetchDataLake(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  const { mutateAsync: onDeleteDataLakeRetrievalOperationMutation } = useMutation(onDeleteDataLakeRetrievalOperation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data-lake']);

      UserNotification.success(
        'Successfully deleted Data Lake retrieval operation',
        'Delete Data Lake retrieval operation',
      );
    },
    onError: (errorThrown) => {
      UserNotification.error(
        `Deleting Data Lake retrieval operation failed with status: ${errorThrown}`,
        'Could not delete Data Lake retrieval operation',
      );
    },
  });

  return {
    dataLakeData: {
      data,
      refetch,
      isFetching,
      error,
    },
    onDeleteDataLakeRetrievalOperationMutation,
  };
};

export default useDataLake;
