import __request__ from 'routing/request';
interface UsedSearchFilter {
}
interface Query {
    readonly filter: Filter;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly search_types: SearchType[];
}
interface SingleMessageQueryRequest {
    readonly stream_id: string;
    readonly message_id: string;
    readonly timeout: number;
    readonly timestamp: string;
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface DataLakeQueryResult {
    readonly stats: ExecutionStats;
    readonly messages: DataLakeRow[];
    readonly errors: SearchError[];
    readonly status: 'RUNNING' | 'DONE' | 'CANCELLED' | 'TIMEOUT' | 'EXPIRED' | 'ERROR' | 'RESET';
}
interface Result {
    readonly name: string;
    readonly id: string;
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface QueryResult {
    readonly query: Query;
    readonly execution_stats: QueryExecutionStats;
    readonly search_types: {
        readonly [_key: string]: Result;
    };
    readonly state: 'INCOMPLETE' | 'FAILED' | 'COMPLETED';
    readonly errors: SearchError[];
}
interface QueryExecutionStats {
    readonly duration: number;
    readonly effective_timerange: AbsoluteRange;
    readonly timestamp: string;
}
interface Filter {
    readonly filters: Filter[];
    readonly type: string;
}
interface SearchError {
    readonly description: string;
}
interface SearchJobExecutionState {
    readonly progress: number;
    readonly status: 'RUNNING' | 'DONE' | 'CANCELLED' | 'TIMEOUT' | 'EXPIRED' | 'ERROR' | 'RESET';
}
interface SearchType {
    readonly stream_categories: string[];
    readonly filter: Filter;
    readonly timerange: unknown;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly name: string;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly type: string;
}
interface DataLakeRow {
    readonly message: {
        readonly [_key: string]: Object;
    };
}
interface BackendQuery {
}
interface SearchJobState {
    readonly owner: string;
    readonly result: QueryResult;
    readonly error_message: SearchError[];
    readonly updated_at: string;
    readonly created_at: string;
    readonly progress: number;
    readonly id: string;
    readonly type: 'DATA_LAKE';
    readonly search_id: string;
    readonly executing_node: string;
    readonly status: 'RUNNING' | 'DONE' | 'CANCELLED' | 'TIMEOUT' | 'EXPIRED' | 'ERROR' | 'RESET';
}
interface ExecutionStats {
    readonly duration: number;
    readonly size_of_opened_files: number;
    readonly opened_files: number;
    readonly records_filtered_in_memory: number;
    readonly files_matching_filter_expression: number;
}
/**
 * Get active (latest) data lake query job for the user
 */
export function activeQuery(page: number = 1, per_page: number = 500): Promise<SearchJobState> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/active_query', null, { 'page': page, 'per_page': per_page }, {
        'Accept': ['application/json']
    });
}
/**
 * Get execution state (a.k.a. simplified state) of an active job for a user
 */
export function searchJobExecutionState(): Promise<SearchJobExecutionState> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/active_search_job_execution_state', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reset active (latest) data lake query job for the user
 */
export function resetActiveQuery(): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/reset_active_query', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get execution state (a.k.a. simplified state) of a given search job
 */
export function searchJobExecutionStateByjob_id(job_id?: string): Promise<SearchJobExecutionState> {
    return __request__('GET', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/search_job_execution_state/${job_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve a single message with all fields extracted from the DataLake
 */
export function singleMessageQuery(request?: SingleMessageQueryRequest): Promise<DataLakeQueryResult> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/single_message', request, {}, {
        'Accept': ['application/json']
    });
}
