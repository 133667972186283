import React from 'react';

import type { ForwarderMetric } from '../Types';
import formatMetric from '../util/formatMetric';

type Props = {
  metrics: ForwarderMetric;
};

const InputThroughput = ({ metrics }: Props) => {
  const {
    rate: { one_minute: oneMinute, five_minute: fiveMinute, fifteen_minute: fifteenMinute },
  } = metrics;

  return (
    <div className="graylog-input-metrics">
      <h3>Throughput / Metrics</h3>
      <span>
        {!Number.isNaN(oneMinute) && `1 minute average rate: ${formatMetric(oneMinute)} msg/s`}
        <br />
        {!Number.isNaN(fiveMinute) && `5 minute average rate: ${formatMetric(fiveMinute)} msg/s`}
        <br />
        {!Number.isNaN(fifteenMinute) && `15 minute average rate: ${formatMetric(fifteenMinute)} msg/s`}
      </span>
    </div>
  );
};

export default InputThroughput;
