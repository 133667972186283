import React, { useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './Routes';

import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import { renderOptions } from '../common/Options';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = (
  {
    onSubmit,
    onChange,
  }: StepAuthorizeProps,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { connectionString } = formData;
  const subscriptions = [
    { label: 'Global Service', value: 'GLOBAL' },
    { label: 'US Government', value: 'US_GOV' },
    { label: 'US DOD', value: 'US_DOD' },
  ];

  const handleSubmit = () => {
    const fetcher = fetch(
      'POST',
      qualifyUrl(ApiRoutes.INTEGRATIONS.Graph.CHECK_CREDENTIALS),
      {
        tenant_id: formData?.tenantId?.value || '',
        client_id: formData?.clientId?.value || '',
        client_secret: formData?.clientSecret?.value || '',
        subscription_type: formData?.subscriptionType?.value || '',
      },
    );

    fetcher
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message:
              'Unable to connect to Microsoft Graph API using provided configuration.',
          });
        }
      })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message:
            'Unable to connect to Microsoft Graph API using provided configuration.',
        });
      });
  };

  const isFormValid = formValidation.isFormValid(
    ['graphName', 'tenantId', 'clientId', 'clientSecret', 'subscriptionType'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      className="col-md-7"
      title=""
      error={formError}
      description="">
      <ValidatedInput className=""
        id="graphName"
        type="text"
        fieldData={formData.graphName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={connectionString?.value}
        required />

      <ValidatedInput className=""
        id="tenantId"
        type="text"
        fieldData={formData.tenantId}
        onChange={onChange}
        label="Tenant ID"
        help="The Tenant ID for the registered app."
        autoComplete="off"
        required />

      <ValidatedInput className=""
        id="clientId"
        type="text"
        onChange={onChange}
        fieldData={formData.clientId}
        label="Client ID"
        help="The Client ID for the registered app."
        required />

      <ValidatedInput className=""
        id="clientSecret"
        type="password"
        onChange={onChange}
        fieldData={formData.clientSecret}
        label="Client Secret"
        help="The Client Secret for the registered app."
        required />

      <ValidatedInput
        type="select"
        name="subscriptionType"
        id="subscriptionType"
        onChange={onChange}
        fieldData={formData.subscriptionType}
        help="Azure AD subscription plan for your organization."
        required
        label="Subscription Type">
        {renderOptions(subscriptions, 'Choose a Subscription')}
      </ValidatedInput>
    </FormWrap>
  );
};

export default StepAuthorize;
