import * as React from 'react';
import { useRef, useLayoutEffect, useMemo } from 'react';

import type { InfiniteScrollDirection } from 'logview/types';
import { isInfiniteScrollUp } from 'logview/helpers';

import useUpdateScrollPosition from './useUpdateScrollPosition';
import ScrollPositionContext from './ScrollPositionContext';
import type { LogViewState } from './LogViewStateProvider';

import type { TableRef, PageRefs } from '../LogViewWidget';

const _setBottomScrollPosition = (tableRef: TableRef) => {
  if (tableRef.current) {
    // eslint-disable-next-line no-param-reassign
    tableRef.current.scrollTop = tableRef.current.scrollHeight - tableRef.current.clientHeight;
  }
};

type Props = {
  children: React.ReactNode;
  listStateStatus: LogViewState['status'];
  pageRefs: PageRefs;
  scrollPositionUpdate: LogViewState['scrollPositionUpdate'];
  tableRef: TableRef;
  infiniteScrollDirection: InfiniteScrollDirection;
};

const ScrollPositionContextProvider = ({
  children,
  tableRef,
  pageRefs,
  scrollPositionUpdate,
  listStateStatus,
  infiniteScrollDirection,
}: Props) => {
  // We update last position when we update the list state
  const lastPositionRef = useRef(0);
  const finishedScrollPositionUpdateRef = useRef(true);

  const setFinishedScrollPositionUpdate = (finished: boolean) => {
    finishedScrollPositionUpdateRef.current = finished;
  };

  const setLastPosition = (position: number) => {
    lastPositionRef.current = position;
  };

  useLayoutEffect(() => {
    if (isInfiniteScrollUp(infiniteScrollDirection) && (listStateStatus === 'initial' || listStateStatus === 'reset')) {
      _setBottomScrollPosition(tableRef);
    }
  }, [tableRef, listStateStatus, infiniteScrollDirection]);

  useUpdateScrollPosition({
    lastPositionRef,
    pageRefs,
    tableRef,
    scrollPositionUpdate,
    setFinishedScrollPositionUpdate,
    infiniteScrollDirection,
  });

  const contextValue = useMemo(
    () => ({
      finishedScrollPositionUpdateRef,
      lastPositionRef,
      setFinishedScrollPositionUpdate,
      setLastPosition,
    }),
    [],
  );

  return <ScrollPositionContext.Provider value={contextValue}>{children}</ScrollPositionContext.Provider>;
};

export default ScrollPositionContextProvider;
