import { useQuery } from '@tanstack/react-query';

import type { Attribute, SearchParams, Attributes } from 'stores/PaginationTypes';
import type { DataLake } from 'data-lake/Types';
import PaginationURL from 'util/PaginationURL';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

const INITIAL_DATA = {
  pagination: { total: 0 },
  list: [],
  attributes: [],
};

const refetchInterval = 5000;

type Options = {
  enabled: boolean;
};

type PaginatedResponse = {
  pagination: {
    count: number;
    total: number;
    page: number;
    per_page: number;
  };
  query: string;
  attributes: Attributes;
  elements: Array<DataLake>;
};

export const fetchDataLakes = (searchParams: SearchParams) => {
  const url = PaginationURL(
    DATA_LAKE_API_ROUTES.DataLakeAPIController.paginated().url,
    searchParams.page,
    searchParams.pageSize,
    searchParams.query,
    {
      sort: searchParams.sort.attributeId,
      order: searchParams.sort.direction,
    },
  );

  return fetch('GET', qualifyUrl(url)).then((response: PaginatedResponse) => {
    const {
      elements,
      query,
      attributes,
      pagination: { count, total, page, per_page: perPage },
    } = response;

    return {
      list: elements,
      attributes,
      pagination: {
        count,
        total,
        page,
        perPage,
        query,
      },
    };
  });
};

export const loadStreamsDataLakeOptions = ({ page, perPage, query }) =>
  fetchDataLakes({
    page,
    pageSize: perPage,
    query,
    sort: { attributeId: 'last_known_stream_title', direction: 'asc' },
  }).then(({ list, pagination }) => ({
    list: list.map(({ last_known_stream_title, stream_id }) => ({ label: last_known_stream_title, value: stream_id })),
    pagination: { page: pagination.page, perPage: pagination.perPage, query: pagination.query },
    total: pagination.total,
  }));

export const keyFn = (searchParams: SearchParams) => ['data-lake', 'overview', searchParams];

const useDataLakes = (
  searchParams: SearchParams,
  { enabled }: Options = { enabled: true },
): {
  data: {
    list: Array<DataLake>;
    pagination: { total: number };
    attributes: Array<Attribute>;
  };
  refetch: () => void;
  isInitialLoading: boolean;
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    keyFn(searchParams),
    () =>
      defaultOnError(fetchDataLakes(searchParams), 'Loading Data Lake failed with status', 'Could not load Data Lake'),
    {
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return {
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  };
};

export default useDataLakes;
