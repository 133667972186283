import * as React from 'react';

import QueryHelper from 'components/common/QueryHelper';

const queryExample = (
  <p>
    Find roles with a description containing security:
    <br />
    <code>description:security</code>
    <br />
  </p>
);
const TeamsQueryHelp = () => (
  <QueryHelper entityName="Team" example={queryExample} commonFields={['name', 'description']} />
);

export default TeamsQueryHelp;
