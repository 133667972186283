import React, { createContext, useState } from 'react';

import type { StepsContextType, isDisabledStepType, SetEnabledStepType } from '../types';

export const StepsContext = createContext<StepsContextType | null>(null);

export const StepsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [currentStep, setCurrentStep] = useState<string>('authorize');
  const [enabledSteps, enableStep] = useState<string[]>(['authorize']);
  const [availableSteps, setAvailableStep] = useState<string[]>([]);

  const isDisabledStep: isDisabledStepType = (step) => {
    if (!enabledSteps || enabledSteps.length === 0) {
      return true;
    }

    return !enabledSteps.includes(step);
  };

  const setEnabledStep: SetEnabledStepType = (step) => {
    enableStep([...enabledSteps, step]);
  };

  return (
    <StepsContext.Provider
      value={{
        availableSteps,
        currentStep,
        enabledSteps,
        isDisabledStep,
        setAvailableStep,
        setCurrentStep,
        setEnabledStep,
      }}>
      {children}
    </StepsContext.Provider>
  );
};
