import CustomizationPage from './CustomizationPage';
import HeaderBadge from './header-badge/HeaderBadge';
import useThemeCustomizer from './theme-editor/useThemeCustomizer';
import usePublicNotifications from './public-notifications/usePublicNotifications';
import useCustomThemeColors from './theme-editor/useCustomThemeColors';

export default {
  navigationItems: [
    {
      key: 'headerBadge',
      component: HeaderBadge,
    },
  ],
  routes: [{ path: '/customization', component: CustomizationPage, permissions: 'inputs:create' }],
  'customization.theme.customizer': {
    hooks: {
      useThemeCustomizer,
      useCustomThemeColors,
    },
  },
  'customization.publicNotifications': {
    hooks: {
      usePublicNotifications,
    },
  },
};
