import { useMemo } from 'react';

import useInputs from 'hooks/useInputs';
import { defaultCompare } from 'logic/DefaultCompare';

const useInputOptions = () => {
  const inputs = useInputs();

  return useMemo(() => {
    if (!inputs) return [];

    return Object.values(inputs)
      .map(({ id: value, title: label }) => ({ value, label }))
      .sort((input1, input2) => defaultCompare(input1.label, input2.label));
  }, [inputs]);
};

export default useInputOptions;
