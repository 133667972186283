import * as React from 'react';
import styled, { css } from 'styled-components';
import type Immutable from 'immutable';

import WidgetFrame from 'views/components/widgets/WidgetFrame';
import { WidgetHeader } from 'views/components/widgets';
import WidgetContainer from 'views/components/WidgetContainer';
import LogViewHeader from 'logview/components/LogViewHeader';
import LogViewCell from 'logview/components/LogViewCell';
import LogViewRow from 'logview/components/LogViewRow';
import { Skeleton } from 'components/common';

const ROWS_AMOUNT = 5;

const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
  overflow-y: auto;
  position: relative;
`;

const TableInner = styled.div`
  /* Remove bottom border of last row of last page */
  > *:last-child > *:last-child {
    border-bottom: 0;
  }
`;

const SkeletonContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacings.xs} 0;
  `,
);

type Props = {
  columns: Immutable.Set<string>;
  widgetId: string;
};

const LogViewSkeleton = ({ columns, widgetId }: Props) => (
  <WidgetContainer className="widgetFrame" isFocused>
    <WidgetFrame widgetId={widgetId}>
      <WidgetHeader title="Data Lake Logs" hideDragHandle editing={false} />
      <Table>
        <TableInner>
          <LogViewHeader columns={columns} />

          {Array.from({ length: ROWS_AMOUNT }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <LogViewRow key={`row-skeleton-${index}`}>
              {columns.toArray().map((fieldName) => (
                <LogViewCell fieldName={fieldName} key={`row-skeleton-${fieldName}`}>
                  <SkeletonContainer>
                    <Skeleton height={10} width="60%" />
                  </SkeletonContainer>
                </LogViewCell>
              ))}
            </LogViewRow>
          ))}
        </TableInner>
      </Table>
    </WidgetFrame>
  </WidgetContainer>
);

export default LogViewSkeleton;
