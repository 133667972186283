import * as React from 'react';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import styled, { css } from 'styled-components';

import type { DateTime, DateTimeFormats } from 'util/DateTime';
import { isValidDate } from 'util/DateTime';
import TimeRangeSelectInput from 'data-lake/components/TimeRangeSelectInput';
import useDataLakeEstimate from 'data-lake/hooks/useDataLakeEstimate';
import ArchiveSizeEstimate from 'data-lake/components/ArchiveSizeEstimate';
import { Modal, Input, HelpBlock } from 'components/bootstrap';
import { FormikInput, ModalSubmit, Select } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import TimeRangeDisplay from 'views/components/searchbar/time-range-filter/TimeRangeDisplay';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import StreamSelectField from 'data-lake/components/StreamSelectField';
import { BOTH_INCLUSION_TYPE, DATA_LAKE_INCLUSION_TYPE, INDEXER_INCLUSION_TYPE } from 'data-lake/Constants';
import type {
  DataLakeActionFormValues,
  DataLakeActionValues,
  EstimateParams,
  InclusionType,
  FieldFilters,
  FieldsOperator,
} from 'data-lake/Types';
import FieldFiltersConfiguration from 'data-lake/FieldFiltersConfiguration/FieldFiltersConfiguration';
import OperatorField from 'data-lake/preview/OperatorField';

const MAX_FILTERS = 3;

export type Props = {
  modalTitle: string;
  defaultTimeRange?: AbsoluteTimeRange;
  show: boolean;
  onHide: () => void;
  submitButtonText: string;
  children: React.ReactNode;
  onSubmit: (values: DataLakeActionValues) => void;
  hourOnly?: boolean;
  streamIds: Array<string>;
  type: 'retrieval' | 'delete';
  defaultFieldFilters?: Array<FieldFilters>;
  defaultFieldOperator?: FieldsOperator;
};
type FormatTime = (time: DateTime, format?: DateTimeFormats) => string;

const StyledModal = styled(Modal)`
  z-index: 1070;
`;

const StyledP = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;

const FullDelete = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.lg};
    margin-top: ${theme.spacings.lg};
  `,
);

const StyledLabel = styled.h5(
  ({ theme }) => css`
    font-weight: bold;
    margin-bottom: ${theme.spacings.xxs};
  `,
);

const INCLUSION_TYPE_OPTIONS = [
  { value: DATA_LAKE_INCLUSION_TYPE, label: 'Must exclude: Search Cluster' },
  { value: INDEXER_INCLUSION_TYPE, label: 'Must include: Search Cluster' },
  { value: BOTH_INCLUSION_TYPE, label: 'Include All' },
];

const getDefaultValues = (
  formatTime: FormatTime,
  isDeleteAction: boolean,
  timeRange?: AbsoluteTimeRange,
  streamIds?: Array<string>,
  defaultFieldFilters: Array<FieldFilters> = [],
  defaultFieldOperator: FieldsOperator = 'AND',
) => {
  const from = timeRange?.from || new Date().setMinutes(0, 0);
  const to = timeRange?.to || new Date().setMinutes(0, 0);

  return {
    ...(!isDeleteAction && { inclusionType: BOTH_INCLUSION_TYPE as InclusionType }),
    streamIds,
    fieldFilters: defaultFieldFilters,
    operator: defaultFieldOperator,
    nextTimeRange: {
      type: 'absolute' as const,
      from: formatTime(from),
      to: formatTime(to),
    },
  };
};

const DataLakeActionModalForm = ({
  modalTitle,
  defaultTimeRange = null,
  show,
  onSubmit,
  onHide,
  children,
  submitButtonText,
  hourOnly = false,
  streamIds,
  type,
  defaultFieldFilters = [],
  defaultFieldOperator = 'AND',
}: Props) => {
  const [estimateParam, setEstimateParam] = useState<EstimateParams>({
    from: defaultTimeRange?.from || '',
    to: defaultTimeRange?.to || '',
    streamIds: streamIds || undefined,
    inclusionType: undefined,
    fieldFilters: defaultFieldFilters,
    operator: defaultFieldOperator,
  });
  const { data: estimate } = useDataLakeEstimate(estimateParam);

  const { formatTime } = useUserDateTime();
  const formatError = 'Format must be: YYYY-MM-DD [HH:mm].';
  const rangeError = 'The "To" date must come after the "From" date.';

  const isDeleteAction = type === 'delete';

  const validate = (values: DataLakeActionFormValues) => {
    const {
      nextTimeRange: { to, from },
      fullDelete,
      inclusionType,
      streamIds: streamIdList,
      fieldFilters = [],
      operator,
    } = values;
    setEstimateParam({ streamIds: streamIdList, from, to, inclusionType, fieldFilters, operator });
    let errors: {
      nextTimeRange?: {
        from?: string;
        to?: string;
      };
      inclusionType?: string;
      fieldFilters?: Array<{ field_name?: string; value?: string }>;
    } = {};

    if (!isDeleteAction && !inclusionType) {
      errors = { ...errors, inclusionType: 'The Include Message field is required.' };
    }

    if (!fullDelete && !from) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, from: 'The "From" field is required.' } };
    }

    if (!fullDelete && !to) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, to: 'The "To" field is required.' } };
    }

    if (!fullDelete && from && !isValidDate(from)) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, from: formatError } };
    }

    if (!fullDelete && to && !isValidDate(to)) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, to: formatError } };
    }

    if (!fullDelete && from >= to) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, to: rangeError } };
    }

    if (fieldFilters.length > MAX_FILTERS) {
      errors = {
        ...errors,
        fieldFilters: fieldFilters.map(() => ({ field_name: `You can add only ${MAX_FILTERS} filters` })),
      };
    }

    return errors;
  };

  const handleSubmit = ({
    nextTimeRange: { from, to },
    fullDelete,
    wipeRestores,
    inclusionType,
    streamIds: streamIdList,
    fieldFilters,
    operator,
  }: DataLakeActionFormValues) => {
    onSubmit({
      from,
      to,
      full_delete: fullDelete,
      wipe_restores: wipeRestores,
      inclusion_type: inclusionType,
      stream_ids: streamIdList,
      field_filters: fieldFilters,
      operator,
    });
  };

  return (
    <StyledModal title={modalTitle} onHide={() => onHide()} show={show}>
      <Formik
        initialValues={{
          ...getDefaultValues(
            formatTime,
            isDeleteAction,
            defaultTimeRange,
            streamIds,
            defaultFieldFilters,
            defaultFieldOperator,
          ),
        }}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnMount>
        {({
          isSubmitting,
          isValidating,
          isValid,
          setFieldValue,
          values: { fullDelete, nextTimeRange, inclusionType, fieldFilters },
          errors,
        }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children}

              {isDeleteAction && (
                <FullDelete>
                  <Input id="fullDelete" label="Full Delete:">
                    <FormikInput
                      label="Delete all data in the Data Lake"
                      name="fullDelete"
                      id="fullDelete"
                      type="checkbox"
                    />
                  </Input>
                  {fullDelete && (
                    <Input id="wipe_restores" label="Wipe retrievals:">
                      <FormikInput
                        label="Wipe all retrievals"
                        name="wipeRestores"
                        id="wipeRestores"
                        disabled={!fullDelete}
                        type="checkbox"
                      />
                    </Input>
                  )}
                </FullDelete>
              )}
              {!isDeleteAction && (
                <StreamSelectField
                  value={streamIds}
                  disabled={false}
                  onChange={(value) => setFieldValue('streamIds', value)}
                />
              )}
              {!fullDelete && (
                <>
                  <StyledP>Time Range: </StyledP>
                  <p>Please select a time range and click {type}.</p>
                  <TimeRangeDisplay timerange={nextTimeRange} />
                  <TimeRangeSelectInput
                    timerange={nextTimeRange}
                    disableTimeMinute={hourOnly}
                    disableTimeSecond={hourOnly}
                  />
                </>
              )}
              {!isDeleteAction && (
                <Field name="inclusion_type">
                  {() => (
                    <Input
                      help="Type of message to be retrieved"
                      id="inclusion-type"
                      error={errors.inclusionType}
                      label="Filter Retrieval by Original Destination">
                      <Select
                        id="inclusionType"
                        name="inclusionYype"
                        placeholder="Select type of retrieval filter"
                        options={INCLUSION_TYPE_OPTIONS}
                        matchProp="label"
                        onChange={(option) => {
                          setFieldValue('inclusionType', option);
                        }}
                        value={inclusionType}
                      />
                    </Input>
                  )}
                </Field>
              )}
              {!isDeleteAction && (
                <>
                  <StyledLabel>Filter by fields</StyledLabel>
                  <HelpBlock>Here you can add field filters.</HelpBlock>
                  {!!fieldFilters.length && <OperatorField />}
                  <FieldFiltersConfiguration maxFilters={3} />
                </>
              )}
              {!fullDelete && isValid && estimate && <ArchiveSizeEstimate estimate={estimate} />}
            </Modal.Body>
            <Modal.Footer>
              <ModalSubmit
                submitButtonText={submitButtonText}
                submitLoadingText="Submitting request..."
                onCancel={() => onHide()}
                disabledSubmit={isValidating || !isValid}
                isSubmitting={isSubmitting}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </StyledModal>
  );
};

export default DataLakeActionModalForm;
