import * as React from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Formik } from 'formik';

import { Button, Col, Modal, Row } from 'components/bootstrap';
import FormikInput from 'components/common/FormikInput';
import Spinner from 'components/common/Spinner';
import { InputDescription, ModalSubmit, IfPermitted } from 'components/common';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import { FailureActions } from './stores/FailureStore';
import type { FailureConfig } from './actions/FailureActions';

const StyledDefList = styled.dl.attrs({
  className: 'deflist',
})(
  ({ theme }) => css`
    &&.deflist {
      dd {
        padding-left: ${theme.spacings.md};
        margin-left: 200px;
      }
    }
  `,
);

const LabelSpan = styled.span(
  ({ theme }) => css`
    margin-left: ${theme.spacings.sm};
    font-weight: bold;
  `,
);

const handleLoadFailureConfig = () => FailureActions.loadFailureConfig();
const handleSaveFailureConfig = (config: FailureConfig) => FailureActions.updateFailureConfig(config);

const FailureProcessingConfig = () => {
  const [failureConfig, setFailureConfig] = useState<FailureConfig>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const sendTelemetry = useSendTelemetry();

  const _saveConfig = (values) => {
    handleSaveFailureConfig(values).then(() => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.FAILURE_PROCESSING_CONFIGURATION_SAVED, {
        app_pathname: 'configurations',
        app_section: 'failure-processing',
      });

      setShowModal(false);
    });
  };

  const _resetConfig = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!showModal) {
      handleLoadFailureConfig().then((loadedConfig) => {
        setFailureConfig(loadedConfig);
      });
    }
  }, [showModal]);

  const modalTitle = 'Configure Failure Processing';

  return (
    <div>
      <h2>Failure Processing</h2>
      <p>
        Configuration for Failure Processing to enable / disable handling of individual failure types and their features
      </p>

      {!failureConfig ? (
        <Spinner />
      ) : (
        <>
          <StyledDefList>
            <dt>Log Indexing Failures:</dt>
            <dd>{failureConfig.persistIndexingFailure ? 'Enabled' : 'Disabled'}</dd>
            <dt>Log Processing Failures:</dt>
            <dd>{failureConfig.submitProcessingFailure ? 'Enabled' : 'Disabled'}</dd>
            <dt>Log Input Failures:</dt>
            <dd>{failureConfig.persistInputFailure ? 'Enabled' : 'Disabled'}</dd>
            <dt>Include Failed Messages:</dt>
            <dd>{failureConfig.persistFailedMessage ? 'Enabled' : 'Disabled'}</dd>
            <dt>Continue processing on error:</dt>
            <dd>{failureConfig.keepFailedMessageDuplicate ? 'Enabled' : 'Disabled'}</dd>
          </StyledDefList>

          <IfPermitted permissions="clusterconfigentry:edit">
            <p>
              <Button
                type="button"
                bsSize="xs"
                bsStyle="info"
                onClick={() => {
                  setShowModal(true);
                }}>
                Edit configuration
              </Button>
            </p>
          </IfPermitted>

          <Modal show={showModal} onHide={_resetConfig} aria-modal="true" aria-labelledby="dialog_label">
            <Formik onSubmit={_saveConfig} initialValues={failureConfig}>
              {({ isSubmitting, values }) => (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title id="dialog_label">{modalTitle}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div>
                      <Row>
                        <Col sm={12}>
                          <FormikInput
                            type="checkbox"
                            name="persistIndexingFailure"
                            id="persistIndexingFailure"
                            label={<LabelSpan>Log Indexing Failures</LabelSpan>}
                          />
                          <InputDescription help={<>Indexing errors will be stored in Elasticsearch</>} />
                        </Col>
                        <Col sm={12}>
                          <FormikInput
                            type="checkbox"
                            name="submitProcessingFailure"
                            id="submitProcessingFailure"
                            label={<LabelSpan>Log Processing Failures</LabelSpan>}
                          />
                          <InputDescription
                            help={
                              <>
                                Processing errors (e.g. pipeline rule evaluation errors) will be stored in Elasticsearch
                              </>
                            }
                          />
                        </Col>
                        <Col sm={12}>
                          <FormikInput
                            type="checkbox"
                            name="persistInputFailure"
                            id="persistInputFailure"
                            label={<LabelSpan>Log Input Failures</LabelSpan>}
                          />
                          <InputDescription help={<>Input errors will be stored in Elasticsearch</>} />
                        </Col>
                        <Col sm={12}>
                          <FormikInput
                            type="checkbox"
                            name="persistFailedMessage"
                            id="persistFailedMessage"
                            disabled={!values.submitProcessingFailure && !values.persistIndexingFailure}
                            label={<LabelSpan>Include Failed Messages</LabelSpan>}
                          />
                          <InputDescription help={<>Include the problematic message into the failure</>} />
                        </Col>
                        <Col sm={12}>
                          <FormikInput
                            type="checkbox"
                            name="keepFailedMessageDuplicate"
                            id="keepFailedMessageDuplicate"
                            disabled={!values.submitProcessingFailure}
                            label={<LabelSpan>Continue processing on error</LabelSpan>}
                          />
                          <InputDescription
                            help={
                              <>
                                If a pipeline rule raises an error, a <code>gl2_processing_error</code> field will be
                                attached to the original message and regular processing we be continued
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <ModalSubmit
                      onCancel={_resetConfig}
                      isSubmitting={isSubmitting}
                      submitButtonText="Update configuration"
                      submitLoadingText="Updating configuration..."
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </div>
  );
};

export default FailureProcessingConfig;
