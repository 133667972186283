import React, { useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
  FormFieldDataType,
} from './types';

import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const [showProxyOptions, setShowProxyOptions] = useState<boolean>(false);

  const { connectionString } = formData;

  const handleShowProxyChange = (event: React.ChangeEvent<HTMLInputElement>, fieldData?: FormFieldDataType) => {
    const enableProxy = event.target.checked;
    setShowProxyOptions(enableProxy);

    if (!enableProxy) {
      // Clear proxy options in case of unchecking the Enable Proxy box.
      formData.proxyOptions.value = '';
    }

    // Propagate the field change to the form since it was intercepted here.
    // This allows it to be saved later.
    onChange(event, fieldData);
  };

  const handleSubmit = () => {
    const fetcher = fetch('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.Azure.CHECK_CREDENTIALS), {
      azure_event_hub_name: formData?.eventHubName?.value || '',
      azure_connection_string: formData?.connectionString?.value || '',
      azure_consumer_group: formData?.consumerGroup?.value || '',
      azure_enable_proxy: formData?.enableProxy?.value || false,
      azure_proxy_options: formData?.proxyOptions?.value || '',
    });

    fetcher
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message: 'Unable to connect to Azure using provided configuration.',
          });
        }
      })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message: 'Unable to connect to Azure using provided configuration.',
        });
      });
  };

  const proxyIsValid = () => {
    const missingProxyOptions = formData?.proxyOptions?.value === '';
    const proxyEnabled = !!formData?.enableProxy?.value || false;

    return !(proxyEnabled && missingProxyOptions) || false;
  };

  const isFormValid = () => {
    const formValid = !formValidation.isFormValid(
      ['azureName', 'eventHubName', 'connectionString', 'consumerGroup'],
      formData,
    );

    return formValid && proxyIsValid();
  };

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={!isFormValid()}
      title=""
      error={formError}
      description="">
      <ValidatedInput
        className=""
        id="azureName"
        type="text"
        fieldData={formData.azureName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={connectionString?.value}
        required
      />

      <ValidatedInput
        className=""
        id="eventHubName"
        type="text"
        fieldData={formData.eventHubName}
        onChange={onChange}
        label="Event Hub Name"
        help="The name of the Event Hub."
        autoComplete="off"
        required
      />

      <ValidatedInput
        className=""
        id="connectionString"
        type="password"
        onChange={onChange}
        fieldData={formData.connectionString}
        help="The connection string required to communicate with Event Hub."
        label="Connection String"
        required
      />

      <ValidatedInput
        className=""
        id="consumerGroup"
        type="text"
        onChange={onChange}
        fieldData={formData.consumerGroup}
        label="Consumer Group"
        help="The consumer group name in the Event Hub."
        placeholder="$Default"
        required
      />

      <ValidatedInput
        type="checkbox"
        className=""
        id="enableProxy"
        onChange={handleShowProxyChange}
        fieldData={formData.enableProxy}
        label="Enable Proxy"
      />

      {showProxyOptions && (
        <ValidatedInput
          className=""
          id="proxyOptions"
          type="text"
          onChange={onChange}
          fieldData={formData.proxyOptions}
          label="Proxy URI"
          placeholder="https://<proxy-host>:5672"
          help="The HTTPS forward proxy URI. If specified, the connection to Azure will be made over port 443 (HTTPS) using the AMQP over WebSockets protocols."
        />
      )}
    </FormWrap>
  );
};

export default StepAuthorize;
