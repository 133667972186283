import * as React from 'react';
import type { Set } from 'immutable';

import { singleton } from 'logic/singleton';
import type { ParameterBindings } from 'views/logic/search/SearchExecutionState';
import type Parameter from 'views/logic/parameters/Parameter';

const ParametersContext = React.createContext<
  | {
      parameterBindings: ParameterBindings;
      parameters: Set<Parameter>;
    }
  | undefined
>(undefined);

export default singleton('contexts.SearchFiltersParametersContext', () => ParametersContext);
