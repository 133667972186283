import * as React from 'react';

import { Alert } from 'components/bootstrap';
import { StyledHeading, DescriptionCol } from 'components/inputs/InputSetupWizard';

import IlluminateList from './IlluminateList';

import { ILLUMINATE_PACK_TYPES } from '../SelectIlluminateStep';
import type { IlluminatePacks } from '../../hooks/useFilterIlluminatePacks';

type Props = {
  selectedProcessingPacks: Array<string>;
  setSelectedProcessingPacks: (packs: Array<string>) => void;
  processingPacksData: IlluminatePacks;
  isLoadingProcessingPacks: boolean;
  setTouched: (touched: boolean) => void;
};

const SelectProcessingPacks = ({
  selectedProcessingPacks,
  setSelectedProcessingPacks,
  isLoadingProcessingPacks,
  processingPacksData,
  setTouched,
}: Props) => {
  const illuminateInfoText =
    'Illuminate Packs contain parsing rules that format logs to the GIM schema that is used by Illuminate Spotlight Packs and Graylog Security Content.';

  return (
    <>
      <StyledHeading>Select Illuminate Packs</StyledHeading>
      <DescriptionCol>
        {processingPacksData.length > 0 ? (
          <>
            {' '}
            <p>
              Please select one or more Illuminate Packs to use with logs received from this Input.
              <br />
              {illuminateInfoText}
            </p>
            <p>Selected packs will be enabled, if not already.</p>
          </>
        ) : (
          <p> {illuminateInfoText}</p>
        )}
      </DescriptionCol>
      <Alert bsStyle="info">
        If not planning to use an Illuminate pack or Spotlight content with logs from this input, please proceed instead
        with the <em>Skip Illuminate</em> option.
      </Alert>
      <IlluminateList
        isLoading={isLoadingProcessingPacks}
        listData={processingPacksData}
        selectedPacks={selectedProcessingPacks}
        setSelectedPacks={setSelectedProcessingPacks}
        setTouched={setTouched}
        type={ILLUMINATE_PACK_TYPES.PROCESSING}
      />
    </>
  );
};

export default SelectProcessingPacks;
