import * as React from 'react';
import numeral from 'numeral';

import { Alert, Col, Row } from 'components/bootstrap';
import { RelativeTime } from 'components/common';
import NumberUtils from 'util/NumberUtils';

import useDataLakeJournalMetrics from '../hooks/useDataLakeJournalMetrics';

type Props = {
  nodeId: string;
};

const DataLakeJournal = ({ nodeId }: Props) => {
  const metrics = useDataLakeJournalMetrics(nodeId);

  return (
    <Row className="content">
      <Col md={12}>
        <h2>Data Lake Journal</h2>
        <p className="description">
          Incoming messages are written to the disk journal to ensure they are kept safe in case of a server failure.
        </p>
        {Object.keys(metrics).length === 0 ? (
          <Alert bsStyle="warning">Data Lake Journal metrics unavailable.</Alert>
        ) : (
          <Row className="row-sm">
            <Col md={6}>
              <dl className="system-journal">
                <dt>Size</dt>
                <dd>{NumberUtils.formatBytes(metrics.size)}</dd>
                <dt>Earliest entry:</dt>
                <dd>
                  <RelativeTime dateTime={metrics.oldestSegment} />
                </dd>
              </dl>
            </Col>
            <Col>
              <strong>{numeral(metrics.messageUncommitted).format('0,0')} uncommitted messages</strong> are currently in
              the journal, in {metrics.segments} segments.
              <br />
              <strong>{numeral(metrics.unflushedMessages).format('0,0')} unflushed messages</strong> are currently in
              the buffer.
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default DataLakeJournal;
