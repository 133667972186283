import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import useReportAndLogo from 'report/hooks/useReportAndLogo';
import DocsHelper from 'util/DocsHelper';
import { REPORT_TZ_FALLBACK } from 'report/Constants';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';
import useParams from 'routing/useParams';

import ReportContents from './ReportContents';

import ReportSubNav from '../common/ReportSubNav';

const EditReportContentsPage = () => {
  const { reportId } = useParams();
  const { report, reportLogo } = useReportAndLogo(reportId);

  if (!report) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  const reportWithTimezone = { ...report, timezone: report.timezone ?? REPORT_TZ_FALLBACK };

  return (
    <DocumentTitle title={`Edit report ${report.title}`}>
      <div>
        <PageHeader
          title={`Edit report ${report.title}`}
          actions={<ReportSubNav reportId={report.id} />}
          documentationLink={{
            title: 'Reporting documentation',
            path: DocsHelper.PAGES.REPORTING,
          }}>
          <span>
            Decide which content should be part of the report while you see its preview. You can schedule and configure
            the report in the next screen.
          </span>
        </PageHeader>

        <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report} displayLicenseWarning={false} hideChildren>
          <Row className="content">
            <Col md={12}>
              <ReportContents key={report.id} report={reportWithTimezone} reportLogo={reportLogo} action="edit" />
            </Col>
          </Row>
        </LicenseCheck>
      </div>
    </DocumentTitle>
  );
};

const EditReportContentsPageWrapper = () => (
  <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report} bsStyle="danger" displayWarningContainer hideChildren>
    <EditReportContentsPage />
  </LicenseCheck>
);

export default EditReportContentsPageWrapper;
