import styled, { css } from 'styled-components';

const TH = styled.th(
  ({ theme }) => css`
    vertical-align: middle !important;
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: ${theme.spacings.md} ${theme.spacings.sm} !important;
    background-color: ${theme.colors.table.head.background} !important;
  `,
);

export default TH;
