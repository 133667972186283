import __request__ from 'routing/request';
interface DataWarehouseConfig {
    readonly iceberg_commit_interval: string;
    readonly parallel_retrieval_enabled: boolean;
    readonly active_backend: string;
    readonly iceberg_target_file_size: number;
    readonly retrieval_convert_batch_size: number;
    readonly journal_reader_batch_size: number;
    readonly retrieval_inflight_requests: number;
    readonly optimize_job_interval: string;
    readonly retrieval_bulk_batch_size: number;
    readonly parquet_row_group_size: number;
    readonly retention_time: string;
    readonly parquet_page_size: number;
    readonly optimize_job_enabled: boolean;
    readonly retrieval_convert_threads: number;
}
/**
 * Get Data Lake configuration
 */
export function get(): Promise<DataWarehouseConfig> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update Data Lake configuration
 */
export function update(config: DataWarehouseConfig): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/config', config, {}, {
        'Accept': ['application/json']
    });
}
