import * as React from 'react';
import { useState, useContext, useMemo } from 'react';

import type { LogViewMessage } from 'logview/types';

import MessageDetailsContext from './MessageDetailsContext';
import ListStateContext from './ListStateContext';

const _nextMessageDetails = (visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId) => {
  const index = visibleMessages.findIndex((message) => message.message._id === activeMessageDetailsId);

  if (index >= 0 && index < visibleMessages.length - 1) {
    return () => setActiveMessageDetailsId(visibleMessages[index + 1].message._id);
  }

  return undefined;
};

const _prevMessageDetails = (visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId) => {
  const index = visibleMessages.findIndex((message) => message.message._id === activeMessageDetailsId);

  if (index > 0) {
    return () => setActiveMessageDetailsId(visibleMessages[index - 1].message._id);
  }

  return undefined;
};

type Props = {
  children: React.ReactNode;
};

const MessageDetailsProvider = ({ children }: Props) => {
  const { pages } = useContext(ListStateContext);
  const [activeMessageDetailsId, setActiveMessageDetailsId] = useState(null);

  const contextValue = useMemo(() => {
    const visibleMessages: Array<LogViewMessage> = pages.flatMap(([, messages]) => messages).toJS();
    const activeMessageDetails = visibleMessages.find((message) => message.message._id === activeMessageDetailsId);
    const onSelectNextMessage = _nextMessageDetails(visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId);
    const onSelectPreviousMessage = _prevMessageDetails(
      visibleMessages,
      setActiveMessageDetailsId,
      activeMessageDetailsId,
    );

    return {
      activeMessageDetails,
      selectNextMessage: onSelectNextMessage,
      selectPrevMessage: onSelectPreviousMessage,
      setActiveMessageDetailsId,
    };
  }, [activeMessageDetailsId, pages]);

  return <MessageDetailsContext.Provider value={contextValue}>{children}</MessageDetailsContext.Provider>;
};

export default MessageDetailsProvider;
