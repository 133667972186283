import * as React from 'react';

import { singleton } from 'logic/singleton';
import type { ParameterValues } from 'report/types';
import type GlobalOverride from 'views/logic/search/GlobalOverride';

type ContextValue = {
  globalOverride: GlobalOverride;
  parameterValues: ParameterValues;
};
const ReportPreviewContext = React.createContext<undefined | ContextValue>(undefined);

export default singleton('contexts.ReportPreviewContext', () => ReportPreviewContext);
