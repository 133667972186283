import * as React from 'react';
import { useCallback, useState } from 'react';
import * as Immutable from 'immutable';

import useParams from 'routing/useParams';
import useExportData from 'export/useExportData';
import { Spinner, Timestamp } from 'components/common';
import ErrorBoundary from 'export/ErrorBoundary';
import ExportErrorPage from 'export/ExportErrorPage';
import { RenderCompletionObserver } from 'components/visualizations';
import { Container, InvisibleDiv } from 'export/Components';
import EventsHistogram from 'components/events/EventsHistogram';
import type { SearchParams } from 'stores/PaginationTypes';
import EventsCategoriesHistogram from 'security-app/components/SecurityEvents/Alerts/MitreCategories/EventsCategoriesHistogram';
import useUserDateTime from 'hooks/useUserDateTime';
import SecurityEventsHistogram from 'security-app/components/SecurityEvents/Alerts/SecurityEventsHistogram';
import InteractiveContext from 'views/components/contexts/InteractiveContext';

type WidgetType = {
  widgetType: string;
  renderType: string;
  search_parameters: SearchParams;
};

const dispatchHistogramComponent = (renderType: string) => {
  switch (renderType) {
    case 'EVENTS_HISTOGRAM':
      return EventsHistogram;
    case 'SECURITY_EVENTS_HISTOGRAM':
      return SecurityEventsHistogram;
    case 'MITRE_CATEGORY_HISTOGRAM':
      return EventsCategoriesHistogram;
    default:
      throw new Error(`Invalid render type: ${renderType}`);
  }
};
const histogramHeadline = (renderType: string) => {
  switch (renderType) {
    case 'EVENTS_HISTOGRAM':
    case 'SECURITY_EVENTS_HISTOGRAM':
      return 'Alerts & Events over Time';
    case 'MITRE_CATEGORY_HISTOGRAM':
      return 'Mitre Att&ck Tactics/Techniques Distribution';
    default:
      throw new Error(`Invalid render type: ${renderType}`);
  }
};

const EventsMetricsExportPage = () => {
  const { userTimezone } = useUserDateTime();
  const { exportCallId } = useParams();
  const { isFetching, data } = useExportData<unknown, WidgetType>(exportCallId);
  const [isRenderComplete, setIsRenderComplete] = useState(false);
  const handleRenderComplete = useCallback(() => {
    setIsRenderComplete(true);
  }, []);

  if (isFetching) {
    return <Spinner />;
  }

  const rawSearchParameters = data.widget.search_parameters;
  const searchParams: SearchParams = {
    ...rawSearchParameters,
    query: rawSearchParameters.query ?? '',
    filters: Immutable.Map(rawSearchParameters.filters),
  };

  const Histogram = dispatchHistogramComponent(data.widget.renderType);
  const headline = histogramHeadline(data.widget.renderType);

  return (
    <Container>
      <RenderCompletionObserver onRenderComplete={handleRenderComplete}>
        <ErrorBoundary FallbackComponent={ExportErrorPage}>
          <InteractiveContext.Provider value={false}>
            <h2>{headline}</h2>
            <Histogram searchParams={searchParams} setFilters={() => {}} />
            <p>
              Created at <Timestamp dateTime={new Date()} /> ({userTimezone})
            </p>
          </InteractiveContext.Provider>
        </ErrorBoundary>
      </RenderCompletionObserver>
      {isRenderComplete && <InvisibleDiv id="render-complete" />}
    </Container>
  );
};
export default EventsMetricsExportPage;
