import * as React from 'react';
import { useState, useCallback } from 'react';

import PaginatedItemOverview from 'components/common/PaginatedItemOverview';
import SectionComponent from 'components/common/Section/SectionComponent';
import RolesQueryHelp from 'components/users/RolesQueryHelp';
import type { PaginatedListType } from 'components/common/PaginatedItemOverview';

import type Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';

type Props = {
  team: Team;
};

const RolesSection = ({ team: { id, name } }: Props) => {
  const [loading, setLoading] = useState(false);

  const _onLoad = useCallback(
    (pagination, isSubscribed: boolean) => {
      setLoading(true);

      return TeamsDomain.loadRolesForTeam(id, name, pagination).then((paginatedRoles): PaginatedListType => {
        if (isSubscribed) {
          setLoading(false);
        }

        return paginatedRoles;
      });
    },
    [id, name],
  );

  return (
    <SectionComponent title="Roles" showLoading={loading}>
      <PaginatedItemOverview
        noDataText="No selected roles have been found."
        onLoad={_onLoad}
        queryHelper={<RolesQueryHelp />}
      />
    </SectionComponent>
  );
};

export default RolesSection;
