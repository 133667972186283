import { OrderedMap } from 'immutable';

import { SecurityAppSecurityEvents } from '@graylog/enterprise-api';

import { qualifyUrl } from 'util/URLUtils';
import { stringifyFilters, buildOpenSearchQuery } from 'security-app/components/common/Filters/stringifyFilters';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import fetch from 'logic/rest/FetchProvider';
import { attributes } from 'security-app/components/SecurityEvents/Alerts/Constants';
import { defaultTimeRange } from 'components/events/fetchEvents';
import type { ColumnFilterData, FilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';
import type { SearchParams } from 'stores/PaginationTypes';
import type { UrlQueryFilters } from 'components/common/EntityFilters/types';
import parseSecurityEventFilters from 'security-app/components/SecurityEvents/Alerts/parseSecurityEventFilters';

import type {
  PaginatedSecurityEventsAPIType,
  PaginatedEventDefinitionsAPIType,
  SecurityEventAPIType,
  NewSecurityEventPayloadType,
  UserType,
  TeamType,
  OwnerOptionType,
  PaginatedNotificationsAPIType,
  NewNotificationPayloadType,
} from './securityEventsAPI.types';

export const fetchSecurityEventByIds = async (eventIds: Array<string>): Promise<Array<SecurityEventAPIType>> =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.detailsByIds().url), { event_ids: eventIds });

export const fetchSecurityEvents = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = null,
  direction: string = null,
  filters: ColumnFilterData = {},
  userTimezone: string = null,
): Promise<PaginatedSecurityEventsAPIType> => {
  const { alerts, timerange } = filters;

  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    `sort=${orderBy || 'timestamp'}`,
    `direction=${direction || 'desc'}`,
    `user_timezone=${encodeURI(userTimezone)}`,
    `alerts=${alerts || 'include'}`,
    `timerange=${timerange || '0'}`,
  ];

  if (query) params.push(`query=${encodeURI(query)}`);

  const mongoFiltersArr = [];

  const mongoFilters = Object.entries(filters)
    .filter(
      ([key, values]: [string, FilterData[]]) =>
        key !== 'alerts' && key !== 'timerange' && values[0].backend !== 'opensearch',
    )
    .reduce((cur, [key]) => Object.assign(cur, { [key]: filters[key] }), {});

  if (mongoFilters && Object.keys(mongoFilters).length > 0) {
    mongoFiltersArr.push(`${encodeURI(stringifyFilters(mongoFilters))}`);
  }

  if (mongoFiltersArr.length > 0) {
    const mongofilterStr = `filters=${mongoFiltersArr.join(';')}`;
    params.push(mongofilterStr);
  }

  const openSearchFilters = Object.entries(filters)
    .filter(
      ([key, values]: [string, FilterData[]]) =>
        key !== 'alerts' && key !== 'timerange' && values[0].backend === 'opensearch',
    )
    .reduce((cur, [key]) => Object.assign(cur, { [key]: filters[key] }), {});

  if (openSearchFilters && Object.keys(openSearchFilters).length > 0) {
    params.push(`openSearchFilters=${encodeURI(buildOpenSearchQuery(openSearchFilters))}`);
  }

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.index(params.join('&')).url));
};

export const fetchSecurityEventsFilterOptions = async ({
  timerange,
  fields,
}: {
  timerange: number;
  fields: string[];
}) => {
  const payload = { timerange, fields };

  return fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.filters().url), payload);
};

export const securityEventEntitiesKeyFn = (searchParams: SearchParams) => ['events', 'search', searchParams];

const deserializeSecurityEvents = ({
  security_events,
  page,
  per_page,
  grand_total,
  count,
}: PaginatedSecurityEventsAPIType) => ({
  attributes,
  list: security_events.map(({ event, ...rest }) => ({
    ...event,
    ...rest,
    id: rest.event_id,
  })),
  pagination: { total: grand_total, page: page, per_page: per_page, count },
});

const mapSortBy = (attribute: string) => {
  if (!attribute) {
    return undefined;
  }

  switch (attribute) {
    case 'scores':
      return 'scores.normalized_risk';
    default:
      return attribute;
  }
};

export const parseSearchParams = (searchParams: SearchParams, timezone: string) => {
  const omFilters: UrlQueryFilters = OrderedMap.isOrderedMap(searchParams.filters)
    ? searchParams.filters
    : OrderedMap(Object.entries(searchParams.filters));
  const parsedFilters = parseSecurityEventFilters(omFilters, defaultTimeRange);
  const { timerange, filter } = parsedFilters;
  const sortBy = mapSortBy(searchParams.sort?.attributeId);

  return {
    page: searchParams.page,
    per_page: searchParams.pageSize,
    sort_by: sortBy,
    sort_direction: searchParams.sort?.direction,
    query: searchParams.query ?? '',
    filter,
    timerange,
    timezone,
  };
};

export const fetchPaginatedSecurityEventEntities = (searchParams: SearchParams, timezone: string = null) =>
  SecurityAppSecurityEvents.search(parseSearchParams(searchParams, timezone)).then(deserializeSecurityEvents);

export const newSecurityEvent = async ({
  data,
}: {
  data: NewSecurityEventPayloadType;
}): Promise<SecurityEventAPIType> => fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.new().url), data);

export const updateSecurityEvent = async ({
  id,
  data,
}: {
  id: string;
  data: NewSecurityEventPayloadType;
}): Promise<SecurityEventAPIType> =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.singleEvent(id).url), data);

export const fetchUsersNTeams = async (): Promise<OwnerOptionType[]> => {
  const { users } = await fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.users().url));
  const { teams } = await fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.teams().url));

  const normUsers = users.map((user: UserType) => ({ id: user.id, name: user.full_name, type: 'User' }));
  const normTeams = teams.map((team: TeamType) => ({ id: team.id, name: team.name, type: 'Team' }));

  return Promise.resolve([...normUsers, ...normTeams]);
};

export const triggerSecurityEventNotification = async ({
  id,
  notificationId,
}: {
  id: string;
  notificationId: string;
}) => {
  await fetch('POST', qualifyUrl(`${EnterpriseApiRoutes.SecurityEvents.notifications().url}/${id}/${notificationId}`));
};

export const triggerBulkSecurityEventNotification = async ({
  ids,
  notificationId,
}: {
  ids: Array<string>;
  notificationId: string;
}) => {
  await fetch('POST', qualifyUrl(`${EnterpriseApiRoutes.SecurityEvents.notifications().url}`), {
    event_ids: ids,
    notification_id: notificationId,
  });
};

/**
 * Event Definitions
 * */

export const fetchEventDefinitions = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = 'title',
  direction: 'asc' | 'desc' = 'asc',
): Promise<PaginatedEventDefinitionsAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`, `sort=${orderBy}`, `order=${direction}`];

  if (query) params.push(`query=${encodeURI(query)}`);

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.index(params.join('&')).url));
};

export const fetchEventDefinitionDetailsByIds = async (
  eventDefinitionIds: Array<string>,
): Promise<Array<EventDefinition>> =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.detailsByIds().url), {
    event_definition_ids: eventDefinitionIds,
  });

export const fetchEntityTypes = async (): Promise<{ processor_types: string[] }> =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.types().url));

export const toggleEventDefinitionStatus = async ({ id, schedule }: { id: string; schedule: boolean }) =>
  schedule
    ? fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.schedule(id).url))
    : fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.unschedule(id).url));

export const copyEventDefinition = async ({ id }: { id: string }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.duplicate(id).url));

export const clearEventDefinitionNotificationsQueue = async ({ id }: { id: string }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.clearQueue(id).url));

export const deleteEventDefinition = async ({ id }: { id: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.details(id).url));

export const blkEnableDefinition = async ({ entity_ids }: { entity_ids: string[] }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.bulkSchedule().url), { entity_ids });

export const blkDisableDefinition = async ({ entity_ids }: { entity_ids: string[] }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.bulkUnschedule().url), { entity_ids });

export const blkDeleteDefinition = async ({ entity_ids }: { entity_ids: string[] }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.bulkDelete().url), { entity_ids });

/**
 * Notifications
 * */

export const fetchNotifications = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = 'title',
  direction: 'asc' | 'desc' = 'asc',
): Promise<PaginatedNotificationsAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`, `sort=${orderBy}`, `order=${direction}`];

  if (query) params.push(`query=${encodeURI(query)}`);

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.securityNotifications.index(params.join('&')).url));
};

export const fetchNotificationDetails = async (notificationId: string): Promise<PaginatedNotificationsAPIType> =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.securityNotifications.details(notificationId).url));

export const deleteNotification = async ({ id }: { id: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.securityNotifications.details(id).url));

export const updateNotification = async ({ id, payload }: { id: string; payload: NewNotificationPayloadType }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.securityNotifications.details(id).url), payload);

export const testNotification = async ({ id }: { id: string }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityEvents.securityNotifications.test(id).url));

export const fetchDetectionChainEventDefinitionSummary = async (eventDefinitionId: string) =>
  fetch(
    'GET',
    qualifyUrl(EnterpriseApiRoutes.SecurityEvents.definitions.ThreatCampaigns.summary(eventDefinitionId).url),
  );
