import { useContext } from 'react';

import SelectableLogViewMessagesContext from 'logview/components/contexts/SelectableLogViewMessagesContext';

const useSelectableLogViewMessages = () => {
  const contextValue = useContext(SelectableLogViewMessagesContext);

  if (!contextValue) {
    throw new Error('useSelectableLogViewMessages hook needs to be used inside SelectableLogViewMessagesContext.Provider');
  }

  return contextValue;
};

export default useSelectableLogViewMessages;
