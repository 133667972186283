import React, { useMemo, useState } from 'react';

import { Link } from 'components/common/router';
import { IfPermitted } from 'components/common';
import { DropdownButton, MenuItem, BootstrapModalConfirm, DeleteMenuItem } from 'components/bootstrap';
import Routes from 'routing/Routes';

import InputProfileAssignForm from './InputProfileAssignForm';
import EditInputProfileForm from './EditInputProfileForm';

import type { InputProfile, Forwarder } from '../Types';
import { InputProfilesActions } from '../stores/InputProfilesStore';

type Props = {
  inputProfile: InputProfile;
  forwarderCount: number;
  forwarders: Array<Forwarder>;
  onForwardersAssign: (updatedForwarders: Array<{ forwarderId: string; inputProfileId: string | null }>) => void;
  onInputProfileUpdate: (inputProfile: InputProfile) => void;
};

const InputProfileEntry = ({
  inputProfile,
  forwarderCount,
  forwarders,
  onForwardersAssign,
  onInputProfileUpdate,
}: Props) => {
  const { id, title, description } = inputProfile;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const _openConfirmation = () => setShowModal(true);
  const _closeConfirmation = () => setShowModal(false);

  const assignedForwarders = useMemo(
    () => forwarders.filter((f) => f.input_profile_id === inputProfile.id),
    [forwarders, inputProfile],
  );

  const _onDelete = () => {
    InputProfilesActions.delete(id);
  };

  return (
    <tr>
      <td>
        <Link to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(id)}>{title}</Link>
      </td>
      <td>{description}</td>
      <td>{forwarderCount}</td>
      <td>
        <IfPermitted permissions="inputprofiles:edit">
          <EditInputProfileForm
            inputProfile={inputProfile}
            onSave={onInputProfileUpdate}
            buttonBsStyle="primary"
            buttonBsSize="xsmall"
          />
        </IfPermitted>
        &nbsp;
        <span key="split-button-default">
          <DropdownButton bsSize="xsmall" id="input-profile-action" title="Choose action" bsStyle="default" pullRight>
            <MenuItem eventKey="1" href={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(id)}>
              View
            </MenuItem>
            <IfPermitted permissions="forwarders:edit">
              <MenuItem eventKey="3" onClick={() => setShowAssignModal(true)}>
                Assign to Forwarders
              </MenuItem>
            </IfPermitted>
            <IfPermitted permissions="inputprofiles:delete">
              {forwarderCount > 0 ? (
                <DeleteMenuItem eventKey="3" disabled>
                  Delete disabled: still in use
                </DeleteMenuItem>
              ) : (
                <DeleteMenuItem eventKey="3" onClick={_openConfirmation} />
              )}
            </IfPermitted>
          </DropdownButton>
        </span>
        {showAssignModal && (
          <InputProfileAssignForm
            showModal={showAssignModal}
            onClose={() => setShowAssignModal(false)}
            inputProfileId={id}
            assignedForwarders={assignedForwarders}
            forwarders={forwarders}
            onForwardersAssign={onForwardersAssign}
          />
        )}
        {showModal && (
          <BootstrapModalConfirm
            showModal={showModal}
            title="Delete Input profile"
            onConfirm={_onDelete}
            onCancel={_closeConfirmation}>
            {`Are you sure you want to delete Input Profile "${title}"? This will delete all 
            inputs in this input profile.`}
          </BootstrapModalConfirm>
        )}
      </td>
    </tr>
  );
};

export default InputProfileEntry;
