import * as React from 'react';
import { useRef, useEffect, useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';
import type * as Immutable from 'immutable';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { Message } from 'views/components/messagelist/Types';
import CopyToClipboardCapture from 'components/common/CopyToClipboardCapture';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';
import InvalidLicenseWidgetInfo from 'search/InvalidLicenseWidgetInfo';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import type {
  After,
  OnLoadMessages,
  LogViewMessage,
  InfiniteScrollDirection,
  LogViewBulkSelection,
  GenericLogViewWidgetConfig,
} from 'logview/types';
import type FieldType from 'views/logic/fieldtypes/FieldType';
import { INFINITE_SCROLL_DIRECTION_UP } from 'logview/Constants';
import BulkActionsRow from 'components/common/EntityDataTable/BulkActionsRow';
import LogViewSelectedEntitiesProvider from 'logview/components/contexts/LogViewSelectedEntitiesProvider';
import SelectableLogViewMessagesProvider from 'logview/components/contexts/SelectableLogViewMessagesProvider';

import ExportSettingsTimerange from './ExportSettingsTimerange';
import MessageDetailsProvider from './contexts/MessageDetailsProvider';
import LogViewMessageDetails from './LogViewMessageDetails';
import LogViewTable from './LogViewTable';
import LogViewStateProvider from './contexts/LogViewStateProvider';

export type PageRefs = {
  current: { [pageId: number]: HTMLDivElement | undefined };
};

export type TableRef = {
  current: HTMLDivElement | undefined;
};

const Container = styled(CopyToClipboardCapture)(
  ({ theme }) => css`
    font-size: ${theme.fonts.size.small};
    height: 100%;
    overflow-x: auto;
    position: relative;
  `,
);

const _formatOnCopyToClipboard = (selection: Selection) =>
  selection
    .toString()
    .split(/\n\u200b\n|\u200c\n/)
    .join(' ');

type Props<T extends GenericLogViewWidgetConfig> = {
  config: T;
  defaultFields: Immutable.OrderedSet<string>;
  effectiveTimerange?: AbsoluteTimeRange;
  initialAfter: After;
  messages: Array<LogViewMessage>; // messages need to be sorted
  editing: boolean;
  setLoadingState: (loading: boolean) => void;
  fields: FieldTypeMappingsList;
  onChangeConfig: (newConfig: T) => Promise<unknown>;
  onLoadMessages: OnLoadMessages; // promise to load more pages, returned messages need to be sorted
  children?: (payload: { resetListState: () => void }) => React.ReactNode;
  onLoadMessage: (message: unknown) => Promise<Message>;
  renderFieldName?: (field: string, fieldType: FieldType) => React.ReactElement;
  renderFieldValue?: (field: string, fieldType: FieldType, value: string) => React.ReactElement;
  infiniteScrollDirection?: InfiniteScrollDirection;
  bulkSelection?: LogViewBulkSelection;
  bottomSection?: (loadedMessagesCount: number) => React.ReactNode;
  loadedAllMessages: (loadedMessagesCount: number, after: After) => boolean;
  onOpenMessageDetails?: () => void;
};

const LogViewWidget = <T extends GenericLogViewWidgetConfig>({
  children = undefined,
  effectiveTimerange = undefined,
  onLoadMessage,
  config,
  defaultFields,
  initialAfter,
  messages,
  setLoadingState,
  editing,
  fields,
  onChangeConfig,
  onLoadMessages,
  renderFieldName = undefined,
  renderFieldValue = undefined,
  infiniteScrollDirection = INFINITE_SCROLL_DIRECTION_UP,
  bulkSelection = {},
  bottomSection = undefined,
  loadedAllMessages,
  onOpenMessageDetails = undefined,
}: Props<T>) => {
  const columns = config.fields;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const pageRefs = useRef<{ [pageId: number]: HTMLDivElement | undefined }>({});
  const renderComplete = useContext(RenderCompletionCallback);

  const _onChangeConfig = useCallback(
    (newConfig: T) => {
      setLoadingState(true);

      return onChangeConfig(newConfig).then(() => setLoadingState(false));
    },
    [onChangeConfig, setLoadingState],
  );

  useEffect(() => {
    renderComplete();
  }, [renderComplete]);

  const displayBulkAction = !!bulkSelection?.actions && !editing;
  const displayBulkSelectCol =
    (typeof bulkSelection?.onChangeSelection === 'function' || displayBulkAction) && !editing;
  const _isEntitySelectable = useCallback(
    (entity: LogViewMessage) => {
      if (!displayBulkSelectCol) return false;

      if (typeof bulkSelection?.isEntitySelectable === 'function') return bulkSelection?.isEntitySelectable(entity);

      return true;
    },
    [displayBulkSelectCol, bulkSelection],
  );

  return (
    <>
      <InvalidLicenseWidgetInfo featureName="log view" />
      <LogViewStateProvider
        pageRefs={pageRefs}
        onLoadMessages={onLoadMessages}
        initialAfter={initialAfter}
        effectiveTimerange={effectiveTimerange}
        infiniteScrollDirection={infiniteScrollDirection}
        messages={messages}
        loadedAllMessages={loadedAllMessages}
        tableRef={tableRef}>
        {({ resetListState }) => (
          <MessageDetailsProvider>
            <SelectableLogViewMessagesProvider
              isEntitySelectable={_isEntitySelectable}
              displayBulkSelectCol={displayBulkSelectCol}>
              <LogViewSelectedEntitiesProvider bulkSelection={bulkSelection}>
                <ExportSettingsTimerange sort={config.sort} />
                {!editing && <BulkActionsRow bulkActions={bulkSelection.actions} />}
                <Container formatSelection={_formatOnCopyToClipboard}>
                  {children?.({ resetListState })}
                  <LogViewTable
                    columns={columns}
                    fields={fields}
                    infiniteScrollDirection={infiniteScrollDirection}
                    tableRef={tableRef}
                    pageRefs={pageRefs}
                    bottomSection={bottomSection}
                    isEntitySelectable={_isEntitySelectable}
                    displayBulkSelectCol={displayBulkSelectCol}
                    onOpenMessageDetails={onOpenMessageDetails}
                  />
                  <LogViewMessageDetails
                    defaultFields={defaultFields}
                    editing={editing}
                    renderFieldName={renderFieldName}
                    renderFieldValue={renderFieldValue}
                    onLoadMessage={onLoadMessage}
                    onChangeConfig={_onChangeConfig}
                    widgetConfig={config}
                  />
                </Container>
              </LogViewSelectedEntitiesProvider>
            </SelectableLogViewMessagesProvider>
          </MessageDetailsProvider>
        )}
      </LogViewStateProvider>
    </>
  );
};

export default LogViewWidget;
