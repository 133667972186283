import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { OverlayTrigger, Icon } from 'components/common';
import { Row, Col } from 'common/components/layout-styles';
import User from 'security-app/components/Assets/AssetModals/AssetDetailsDrawer/UserData';
import Machine from 'security-app/components/Assets/AssetModals/AssetDetailsDrawer/MachineData';
import type {
  AssetAPIType,
  AssetDetailsType,
  MachineAssetAPIType,
  UserAssetAPIType,
} from 'security-app/hooks/api/assetsAPI.types';

const DetailsContainer = styled(Col)`
  max-height: 600px;
  overflow: auto;
`;

const componentByType = {
  machine: (machine: AssetAPIType<MachineAssetAPIType>) => <Machine machineAsset={machine} />,
  user: (user: AssetAPIType<UserAssetAPIType>) => <User userAsset={user} />,
};


type Props = {
  asset: AssetAPIType<AssetDetailsType>;
  addToQuery?: (id: string) => void;
};

function OverlayTitle({ asset, addToQuery = undefined }: Props) {
  return (
    <Row $justify="space-between">
      <strong>{asset.name}</strong>
      {addToQuery && <Button bsSize="xs" bsStyle="primary" onClick={() => addToQuery(asset.id)}>Add to query</Button>}
    </Row>
  );
}

function AssetDetailsOverlay({ asset, addToQuery = undefined }: Props) {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="right"
      overlay={<DetailsContainer $gap="lg">{componentByType[asset.details.type](asset)}</DetailsContainer>}
      title={<OverlayTitle asset={asset} addToQuery={addToQuery} />}
      width={600}
    >
      <Row $gap="xxs" $align="center">
        <Button bsStyle="link">
          <strong>{asset.name}</strong>
        </Button>
        <Icon name="arrow_right" size="sm" />
      </Row>
    </OverlayTrigger>
  );
}

export default AssetDetailsOverlay;
