import Reflux from 'reflux';

import { singletonActions } from 'logic/singleton';
import type { RefluxActions } from 'stores/StoreTypes';

export type WatchlistStatus = {
  [watchlistFieldType: string]: Array<string> | undefined;
};

export type WatchlistUpdateSuccess = {
  payload: {
    watchlistFieldType: string;
    value: any;
  };
};

export type ActionsType = {
  delete: (watchlistFieldType: string, value: string) => Promise<WatchlistUpdateSuccess>;
  update: (watchlistFieldType: string, value: string) => Promise<WatchlistUpdateSuccess>;
};

const WatchlistActions: RefluxActions<ActionsType> = singletonActions('enterprise.Watchlist', () =>
  Reflux.createActions({
    delete: { asyncResult: true },
    update: { asyncResult: true },
  }),
);

export default WatchlistActions;
