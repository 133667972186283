import * as React from 'react';
import styled from 'styled-components';

import { Badge, RowTitle, Row } from 'common/components';
import type { ColumnRenderers } from 'components/common/EntityDataTable';
import useExpandedSections from 'components/common/EntityDataTable/hooks/useExpandedSections';

import type { PackType } from '../types';

const Title = styled(RowTitle)<{ $disabled?: boolean }>`
  & * {
    color: ${({ theme, $disabled }) => ($disabled ? theme.colors.text.disabled : theme.colors.link.default)} !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

const TitleRenderer = ({ pack }: { pack: PackType }) => {
  const { toggleSection } = useExpandedSections();
  const toggleExtraSection = () => toggleSection(pack.pack_id, 'restFieldsExpandedSection');

  return (
    <TitleContainer>
      <Title $disabled={!pack.included_in_bundle} onClick={toggleExtraSection}>
        {pack.title}
      </Title>
      <Row $align="center" $gap="xxs">
        {pack.is_new && (
          <Badge variant="success" size="xs" solid>
            new
          </Badge>
        )}
        {pack.update_available && (
          <Badge variant="info" size="xs" solid>
            updated
          </Badge>
        )}
      </Row>
    </TitleContainer>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const columnRendereds: ColumnRenderers<PackType & { id: string }> = {
  attributes: {
    title: {
      width: 0.2,
      renderCell: (_title: string, pack: PackType) => <TitleRenderer pack={pack} />,
    },
    enabled: {
      staticWidth: 150,
      renderCell: (enable: boolean) => (enable ? <Badge variant="success">enabled</Badge> : null),
    },
    type: {
      staticWidth: 150,
      renderCell: (type: string) => <Badge>{type}</Badge>,
    },
    is_new: {
      staticWidth: 150,
      renderCell: (is_new: string) => (is_new ? <Badge variant="success">new</Badge> : null),
    },
    category: {
      staticWidth: 150,
      renderCell: (category: string) => <Badge>{category}</Badge>,
    },
    pack_id: {
      staticWidth: 300,
    },
  },
};
