import * as React from 'react';
import styled from 'styled-components';

import { Row, Col, Alert } from 'components/bootstrap';
import OrientationSelect from 'report/report-creation/OrientationSelect';
import FormikSwitch from 'report/report-creation/FormikSwitch';
import Separator from 'report/report-creation/Separator';
import useReportForm from 'report/report-creation/useReportForm';
import { FormikInput, InputDescription } from 'components/common';
import ReportLogoEdit from 'report/report-creation/content/ReportLogoEdit';

import PageSizeSelect from './PageSizeSelect';

const ChildOptions = styled.div`
  padding-top: 5px;
`;

const PageNumbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;

  label {
    font-size: 16px;
  }
`;

const Style = () => {
  const {
    values: { layout, hideWidgetDescription },
  } = useReportForm();

  const format = layout?.format;
  const isPDF = !format || format === 'PDF';

  return (
    <>
      {!isPDF && (
        <Alert bsStyle="warning">
          These options do not take effect because they can only be applied for &quot;PDF format&quot;.
        </Alert>
      )}
      <Row>
        <Col md={6}>
          <PageSizeSelect disabled={!isPDF} />
        </Col>
        <Col md={6}>
          <OrientationSelect disabled={!isPDF} />
        </Col>
        <Col md={12}>
          <Separator />
          <FormikSwitch name="layout.printHeader" label="Header" disabled={!isPDF} />
          {layout?.printHeader && (
            <ChildOptions>
              <InputDescription help="The header will be displayed for every page in the actual PDF file." />
              <ReportLogoEdit />
              <FormikInput
                label="Header text"
                name="layout.header"
                id="layout.header"
                type="textarea"
                help="The header will not be shown in the cover page or the table of contents once printed as a PDF file. Please ignore this on the preview."
                maxLength={330}
              />
            </ChildOptions>
          )}
          <Separator />
          <FormikSwitch name="layout.printFooter" label="Footer" disabled={!isPDF} />
          {layout?.printFooter && (
            <ChildOptions>
              <InputDescription help="The footer will be displayed for every page in the actual PDF file." />
              <PageNumbers>
                <FormikSwitch label="Page numbers" name="layout.printPageNumbers" />
              </PageNumbers>
              <FormikInput
                label="Footer text"
                name="layout.footer"
                id="layout.footer"
                type="textarea"
                maxLength={330}
              />
            </ChildOptions>
          )}
          <Separator />
          <FormikSwitch name="layout.printToc" label="Table of Contents" disabled={!isPDF} />
          <Separator />
          <FormikSwitch name="hideWidgetDescription" label="Hide widget description" disabled={!isPDF} />
          <Separator />
          <FormikSwitch
            name="hideWidgetQuery"
            label="Hide query in widget description"
            disabled={!isPDF || hideWidgetDescription}
          />
        </Col>
      </Row>
    </>
  );
};

export default Style;
