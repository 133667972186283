import * as React from 'react';
import { useFormikContext, useField } from 'formik';
import get from 'lodash/get';

import { TimeUnitInput } from 'components/common';

import { ErrorMessage } from './ErrorMessage';

const HelpOrError = ({ help, errorMessage, touched }) => {
  if (errorMessage && touched) return <ErrorMessage>{errorMessage}</ErrorMessage>;

  return help;
};

type Props = {
  field: any;
  label?: string;
  units?: string[];
  clearable?: boolean;
  required?: boolean;
  disabled?: boolean;
  showCheckbox?: boolean;
  help?: React.ReactNode;
};

function FormikTimeUnitInput({
  field,
  label = undefined,
  units = undefined,
  clearable = false,
  required = false,
  disabled = false,
  showCheckbox = false,
  help = undefined,
}: Props) {
  const { setFieldValue, values, errors, touched, setFieldTouched } = useFormikContext();
  const [{ name }] = useField({ name: field.name });

  const updateTimes = (newTime: number, newUnit: string) => {
    if (get(values, name) !== newTime) setFieldValue(name, newTime);
    if (get(values, `${name}_unit`) !== newUnit) setFieldValue(`${name}_unit`, newUnit);

    setTimeout(() => {
      setFieldTouched(name, true);
      setFieldTouched(`${name}_unit`, true);
    }, 10);
  };

  return (
    <TimeUnitInput
      label={label}
      name={name}
      unitName={`${name}_unit`}
      update={updateTimes}
      value={get(values, name)}
      unit={get(values, `${name}_unit`)}
      units={units}
      clearable={clearable}
      required={required}
      enabled={!disabled}
      hideCheckbox={!showCheckbox}
      help={<HelpOrError help={help} errorMessage={get(errors, name)} touched={get(touched, name)} />}
    />
  );
}

export default FormikTimeUnitInput;
