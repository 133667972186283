import Reflux from 'reflux';
import URI from 'urijs';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

import type { InputProfile } from '../Types';

type PaginatedInputProfileResponse = {
  total: number;
  page: number;
  per_page: number;
  count: number;
  forwarder_input_profiles: Array<InputProfile>;
  grand_total: number;
};
type Pagination = {
  query?: string;
  page?: number;
  pageSize?: number;
};
type InputProfilesActionsType = {
  list: (pagination: Pagination) => Promise<PaginatedInputProfileResponse>;
  listAll: () => Promise<PaginatedInputProfileResponse>;
  create: (inputProfile: InputProfile) => Promise<InputProfile>;
  get: (id: string) => Promise<InputProfile>;
  update: (updatedInputProfile: InputProfile, id: string) => Promise<InputProfile>;
  delete: (id: string) => Promise<void>;
};
export type InputProfilesStoreState = {
  inputProfile: InputProfile;
  all: Array<InputProfile>;
  inputProfiles: Array<InputProfile>;
  pagination: {
    total: number;
    count: number;
    page: number;
    perPage: number;
    query: string;
  };
};

export const InputProfilesActions = Reflux.createActions<InputProfilesActionsType>({
  list: { asyncResult: true },
  listAll: { asyncResult: true },
  create: { asyncResult: true },
  get: { asyncResult: true },
  update: { asyncResult: true },
  delete: { asyncResult: true },
});

const InputProfilesStore = Reflux.createStore<InputProfilesStoreState>({
  listenables: [InputProfilesActions],
  sourceUrl: '/plugins/org.graylog.plugins.forwarder/forwarder/profiles',
  inputProfile: undefined,
  all: undefined,
  inputProfiles: undefined,
  pagination: {
    total: 0,
    count: 0,
    page: 1,
    perPage: 10,
    query: '',
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    return {
      inputProfile: this.inputProfile,
      all: this.all,
      inputProfiles: this.inputProfiles,
      pagination: this.pagination,
    };
  },

  propagateUpdate() {
    this.trigger(this.getState());
  },

  _inputProfilesUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = uri.segment().concat(segments);
    uri.segmentCoded(nextSegments);
    uri.query(query);

    return qualifyUrl(uri.resource());
  },

  listAll() {
    const promise = fetch(
      'GET',
      this._inputProfilesUrl({
        query: {
          query: '',
          page: 1,
          per_page: 0,
        },
      }),
    );

    promise.then(
      (response: PaginatedInputProfileResponse) => {
        this.all = response.forwarder_input_profiles;
        this.propagateUpdate();

        return response;
      },
      (error) => {
        UserNotification.error(`Retrieving List of Input Profiles failed with error : ${error},
        Could not get Input Profile list`);
      },
    );

    InputProfilesActions.listAll.promise(promise);
  },

  list({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch(
      'GET',
      this._inputProfilesUrl({
        query: {
          query: query,
          page: page,
          per_page: pageSize,
        },
      }),
    );

    promise.then(
      (response) => {
        this.inputProfiles = response.forwarder_input_profiles;

        this.pagination = {
          total: response.total,
          count: response.count,
          page: response.page,
          perPage: response.per_page,
          query: query,
        };

        this.propagateUpdate();

        return response;
      },
      (error) => {
        UserNotification.error(`Retrieving List of Input Profiles failed with error : ${error},
        Could not get Input Profile list`);
      },
    );

    InputProfilesActions.list.promise(promise);
  },

  create(inputProfile: InputProfile) {
    const promise = fetch('POST', this._inputProfilesUrl({}), inputProfile);

    promise.then(
      (response: InputProfile) => {
        UserNotification.success('Input Profile created successfully');
        this.list({});

        return response;
      },
      (error) => {
        UserNotification.error(`Creating Input Profile failed with error : ${error},
        Could not Create Input Profile ${inputProfile.title}`);
      },
    );

    InputProfilesActions.create.promise(promise);
  },

  get(id: string) {
    const url = qualifyUrl(`${this.sourceUrl}/${id}`);
    const promise = fetch('GET', url);

    promise.then(
      (response: InputProfile) => {
        this.inputProfile = response;
        this.propagateUpdate();

        return response;
      },
      (error) => {
        UserNotification.error(`Fetching Input Profile failed with status: ${error.message},
         Could not retrieve Input Profile`);
      },
    );

    InputProfilesActions.get.promise(promise);
  },

  update(updatedInputProfile: InputProfile, id: string) {
    const url = qualifyUrl(`${this.sourceUrl}/${id}`);
    const promise = fetch('PUT', url, updatedInputProfile);

    promise.then(
      (response) => {
        this.list({});
        this.get(id);
        UserNotification.success('Input Profile details updated successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Updating Input Profile failed with error : ${error},
           Could not update Input Profile ${updatedInputProfile.title}`);
      },
    );

    InputProfilesActions.update.promise(promise);
  },

  delete(id: string) {
    const url = qualifyUrl(`${this.sourceUrl}/${id}`);
    const promise = fetch('DELETE', url);

    promise.then(
      (response) => {
        const nextPage =
          this.pagination.count !== 1
            ? // still has items on page, fetch same page
              this.pagination.page
            : // no items left, go to previous page
              this.pagination.page - 1;

        this.list({ page: nextPage });
        UserNotification.success('Input Profile successfully deleted.');

        return response;
      },
      (error) => {
        UserNotification.error(`Deleting Input Profile failed with error : ${error},
           Could not delete Input Profile with id: ${id}`);
      },
    );

    InputProfilesActions.update.promise(promise);
  },
});

export default InputProfilesStore;
