import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSystemJobsQuery } from 'archive/hooks';
import { Row, Col } from 'components/bootstrap';
import SystemJob from 'components/systemjobs/SystemJob';

import type { Job } from '../types';

const StyledHr = styled.hr`
  margin-bottom: 5px;
  margin-top: 10px;
`;

const ArchiveSystemJobs = () => {
  const [groupedJobs, setGroupedJobs] = useState<Array<Job>>([]);

  const { data: jobs, isLoading } = useSystemJobsQuery();

  useEffect(() => {
    const restoreSystemJobs = [];
    const createSystemJobs = [];
    const buildCatalogSystemJobs = [];

    Object.keys(jobs).forEach((nodeId: string) => {
      jobs[nodeId]?.jobs?.forEach((job) => {
        switch (job.name) {
          case 'org.graylog.plugins.archive.job.ArchiveRestoreSystemJob':
            restoreSystemJobs.push(job);
            break;
          case 'org.graylog.plugins.archive.job.ArchiveCreateSystemJob':
            createSystemJobs.push(job);
            break;
          case 'org.graylog.plugins.archive.job.BuildCatalogSystemJob':
            buildCatalogSystemJobs.push(job);
            break;
          default:
          // Ignore non-archive jobs.
        }
      });
    });

    setGroupedJobs([restoreSystemJobs, createSystemJobs, buildCatalogSystemJobs].flat());
  }, [jobs]);

  const systemJobComponents = groupedJobs.map((job: Job) => <SystemJob key={job.id} job={job} />);

  return (
    !isLoading &&
    systemJobComponents.length > 0 && (
      <Row key="system-job-progress" className="content">
        <Col md={12}>
          <h2>Job Progress</h2>
          <StyledHr />
          {systemJobComponents}
        </Col>
      </Row>
    )
  );
};

export default ArchiveSystemJobs;
