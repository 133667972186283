import * as React from 'react';
import * as Immutable from 'immutable';
import compact from 'lodash/compact';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import type { PaginatedListType } from 'components/common/PaginatedItemOverview';
import type UserOverview from 'logic/users/UserOverview';
import { UsersActions } from 'stores/users/UsersStore';
import { Button } from 'components/bootstrap';
import { Select } from 'components/common';

import type Team from '../../logic/Team';

type Props = {
  onSubmit: (user: Immutable.Set<UserOverview>) => Promise<PaginatedListType | void>;
  team: Team;
};

const SubmitButton = styled(Button)`
  margin-left: 15px;
`;

const FormElements = styled.div`
  display: flex;
`;

const SelectOption = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const _renderOption = ({ label }: { label: string }) => <SelectOption>{label}</SelectOption>;

const _options = (users: Immutable.List<UserOverview>, team: Team) =>
  users
    .filter((u) => !team.users.includes(u.id))
    .toArray()
    .map((u) => ({ label: u.name, value: u.id }));

const _assignUser = (teamName, selectedUserIds, users, onSubmit, setSelectedUserIds, setIsSubmitting) => {
  if (!selectedUserIds) {
    return;
  }

  const selectedUsers = compact(
    selectedUserIds.split(',').map((selectedUserId) => users.find((u) => u.id === selectedUserId)),
  );

  if (!selectedUsers) {
    throw new Error(
      `User assignment team ${teamName} failed, because users with ids ${selectedUserIds.join(',') ?? '(undefined)'} does not exist in ${users.map((u) => u.username).join(', ')}`,
    );
  }

  onSubmit(Immutable.Set(selectedUsers)).then(() => {
    setSelectedUserIds();
    setIsSubmitting(false);
  });
};

const UsersSelector = ({ team, onSubmit }: Props) => {
  const [users, setUsers] = useState<Immutable.List<UserOverview>>(Immutable.List());
  const [selectedUserIds, setSelectedUserIds] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const _onSubmit = () => _assignUser(team.name, selectedUserIds, users, onSubmit, setSelectedUserIds, setIsSubmitting);
  const options = _options(users, team);

  useEffect(() => {
    const getUnlimited = { page: 1, perPage: 0, query: '' };

    UsersActions.loadUsersPaginated(getUnlimited).then(({ list }) => setUsers(list));
  }, [team]);

  return (
    <div>
      <FormElements>
        <StyledSelect
          onChange={setSelectedUserIds}
          optionRenderer={_renderOption}
          options={options}
          multi
          placeholder="Search for users"
          value={selectedUserIds}
        />
        <SubmitButton
          bsStyle="success"
          disabled={isSubmitting || !selectedUserIds}
          onClick={_onSubmit}
          title="Assign User"
          type="button">
          Assign User
        </SubmitButton>
      </FormElements>
    </div>
  );
};

export default UsersSelector;
