import type { Extension } from 'search/ExportWidgetAction/util/WidgetExportUtils';

// eslint-disable-next-line import/prefer-default-export
export const supportedAggregationExportFormats: Array<{ id: Extension; title: string }> = [
  { id: 'pdf', title: 'PDF' },
  { id: 'csv', title: 'CSV' },
  { id: 'json', title: 'JSON' },
  { id: 'yaml', title: 'YAML' },
  { id: 'xml', title: 'XML' },
  { id: 'xlsx', title: 'XLSX' },
];

export const supportedEventsOverviewExportFormats: Array<{ id: Extension; title: string }> = [
  { id: 'csv', title: 'CSV' },
  { id: 'json', title: 'JSON' },
];
