import ExportPage from 'export/ExportPage';
import EventsMetricsExportPage from 'security-app/components/SecurityEvents/Alerts/WidgetsExport/EventsMetricsExportPage';

const bindings = {
  routes: [
    {
      path: '/widgetexport/:exportCallId/simple_list_render',
      component: ExportPage,
      parentComponent: null,
    },
    {
      path: '/widgetexport/:exportCallId/security_events_metrics_render',
      component: EventsMetricsExportPage,
      parentComponent: null,
    },
    {
      path: '/widgetexport/:exportCallId/events_metrics_render',
      component: EventsMetricsExportPage,
      parentComponent: null,
    },
  ],
};

export default bindings;
