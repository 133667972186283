import { qualifyUrls } from 'routing/Routes';

const O365Routes = {
  INTEGRATIONS: {
    O365: {
      ACTIVITYAPI: {
        index: '/integrations/o365',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    O365: {
      CONTENT_TYPES: '/plugins/org.graylog.enterprise.integrations/office365/checkSubscriptions',
      START_SUBSCRIPTION: '/plugins/org.graylog.enterprise.integrations/office365/startSubscription',
      START_MOCK_SUBSCRIPTION: '/plugins/org.graylog.enterprise.integrations/office365/startMockSubscription',
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/office365/inputs',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    O365: {
      GRAYLOG_O365_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_O365_ActivityLog_Input.html#graylog-o365-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(O365Routes),
  unqualified: O365Routes,
};

export { DocsRoutes, ApiRoutes };
