import __request__ from 'routing/request';
interface MitreTactic {
    readonly techniques: MitreTechnique[];
    readonly name: string;
    readonly id: string;
}
interface MitreTechnique {
    readonly name: string;
    readonly id: string;
    readonly subtechniques: MitreSubTechnique[];
}
interface MitreCategories {
    readonly tactics: MitreTactic[];
}
interface MitreSubTechnique {
    readonly name: string;
    readonly id: string;
}
/**
 * Get list of Mitre tactic/techiques with resolved names
 */
export function categories(): Promise<MitreCategories> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/mitre_categories', null, {}, {
        'Accept': ['application/json']
    });
}
