import * as Immutable from 'immutable';

import type { OidcTeamSyncBackendConfig, OidcConfigFormValues } from 'authentication/components/oidc/config/types';

const prepareOidcGroupSyncConfig = (
  formValues: OidcConfigFormValues,
  backendId: string,
  serviceType: string,
): OidcTeamSyncBackendConfig => {
  const { teamSelection = Immutable.Set(), groupsClaim } = formValues;

  return {
    auth_service_backend_id: backendId,
    selection_type: 'all',
    selection: teamSelection.toJS(),
    config: {
      type: serviceType,
      groups_claim: groupsClaim,
    },
  };
};

export default prepareOidcGroupSyncConfig;
