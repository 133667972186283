import * as React from 'react';

import { useBulkTriggerSecurityEventNotification } from 'security-app/hooks/useSecurityEventsAPI';
import SendNotificationModal from 'security-app/components/SecurityEvents/Alerts/AlertsModals/NotificationsModal/SendNotificationModal';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';

type Props = {
  eventIds: Array<string>;
  onClose: () => void;
  show: boolean;
  fromBulk: boolean;
};

const SendNotificationBulk = ({ eventIds, onClose, show, fromBulk }: Props) => {
  const { triggerSecurityEventNotifications, triggeringSecurityEventNotifications } =
    useBulkTriggerSecurityEventNotification();
  const sendEventActionTelemetry = useSendEventActionTelemetry();

  const onConfirm = async (notificationId: string) => {
    await triggerSecurityEventNotifications({ ids: eventIds, notificationId: notificationId });
    sendEventActionTelemetry('SEND_NOTIFICATION', fromBulk);

    onClose();
  };

  return (
    <SendNotificationModal
      confirmDisabled={triggeringSecurityEventNotifications}
      closeModal={onClose}
      onConfirm={onConfirm}
      show={show}
    />
  );
};

export default SendNotificationBulk;
