import * as React from 'react';
import { Form, Field } from 'formik';

import { validateField } from 'util/FormsUtils';
import { Button, ButtonToolbar, Col, Input } from 'components/bootstrap';
import type { Backend } from 'logic/authentication/okta/types';
import { FormikFormGroup, InputOptionalInfo as Opt, Select, KeyValueTable } from 'components/common';
import type Role from 'logic/roles/Role';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import OidcRoutes from 'authentication/components/oidc/Routes';

import OidcBaseUrlField from './OidcBaseUrlField';

import payloadFromFormValues from '../helpers/payloadFromFormValues';
import type { ConfigFormValues } from '../types';
import ServerConnectionTest from '../ServerConnectionTest';
import getDisplayName from '../helpers/getDisplayName';

interface ServerConfigurationFormProps {
  authenticationBackend: Backend;

  isSubmitting: boolean;

  hasSecret: boolean;

  isOkta: boolean;

  values: ConfigFormValues;

  setFieldValue: (key: string, value: string) => void;

  validateForm: (values?: any) => Promise<any>;

  roles: Role[];

  goToNext: () => void;

  submitAllError: React.ReactNode | null | undefined;
}

const defaultCallbackUrl = `${window.location.origin}${OidcRoutes.AUTH_CALLBACK}`;

export const FORM_VALIDATION = {
  title: { required: true },
  description: {},
  baseUrl: { required: true },
  callbackUrl: { required: true },
  clientId: { required: true },
  clientSecret: { required: true },
  claims: {},
  defaultRoles: { required: true },
};

const ServerConfigurationForm = ({
  authenticationBackend,
  values,
  isSubmitting,
  hasSecret,
  isOkta,
  setFieldValue,
  roles,
  validateForm,
  submitAllError,
  goToNext,
}: ServerConfigurationFormProps) => {
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const { id } = authenticationBackend;
  const { type } = values;
  const rolesOptions = roles.map((role) => ({ label: role.name, value: role.id }));
  const displayName = getDisplayName(type);
  const oktaBaseUrlHelp = (
    <>
      For example <code>{'https://<your-subdomain>.okta.com'}</code> or for a custom authorization server:{' '}
      <code>{'https://<yourOktaDomain>/oauth2/<authorizationServerId>'}</code>
    </>
  );
  const help = {
    title: undefined,
    description: undefined,
    baseUrl: (
      <span>
        {`The base URL of your ${displayName} environment. `}
        {isOkta && oktaBaseUrlHelp}
      </span>
    ),
    callbackUrl: (
      <span>
        {`The URL ${displayName} will redirect back to after authentication. Most likely`} it's:{' '}
        <code>{defaultCallbackUrl}</code>
      </span>
    ),
    claims: undefined,
    clientId: undefined,
    clientSecret: undefined,
    tokenVerifierConnectTimeout: <span>{`The time in seconds allowed to validate a token with ${displayName}`}</span>,
    defaultRoles: (
      <span>
        The default Graylog roles synchronized user will obtain. All users need the <code>Reader</code> role, to use the
        Graylog web interface
      </span>
    ),
  };
  const backendValidationErrors = {
    clientSecret: undefined,
    defaultRoles: undefined,
  };

  return (
    <>
      <Col md={7}>
        <Form className="form form-horizontal">
          <FormikFormGroup
            help={help.title}
            label="Title"
            name="title"
            disabled={isSubmitting}
            validate={validateField(FORM_VALIDATION.title)}
            placeholder="Title"
          />

          <FormikFormGroup
            help={help.description}
            label={
              <>
                Description <Opt />
              </>
            }
            type="textarea"
            name="description"
            disabled={isSubmitting}
            validate={validateField(FORM_VALIDATION.description)}
            placeholder="Description"
          />

          <OidcBaseUrlField
            help={help.baseUrl}
            type={type}
            isOkta={isOkta}
            disabled={isSubmitting}
            validate={validateField(FORM_VALIDATION.baseUrl)}
          />

          <FormikFormGroup
            help={help.callbackUrl}
            label="Callback URL"
            name="callbackUrl"
            disabled={isSubmitting}
            validate={validateField(FORM_VALIDATION.callbackUrl)}
            placeholder="Callback URL"
          />

          {!isOkta && (
            <Field name="claims">
              {({ meta: { error } }) => (
                <Input
                  help={help.claims}
                  error={error}
                  id="claims"
                  label="Claims"
                  labelClassName="col-sm-3"
                  wrapperClassName="col-sm-9">
                  <KeyValueTable
                    pairs={values.claims}
                    editable={!isSubmitting}
                    onChange={(claims) => {
                      setFieldValue('claims', claims);
                    }}
                  />
                </Input>
              )}
            </Field>
          )}

          <FormikFormGroup
            help={help.clientId}
            label="OAuth Client ID"
            name="clientId"
            disabled={isSubmitting}
            validate={validateField(FORM_VALIDATION.clientId)}
            placeholder="OAuth Client ID"
          />

          {hasSecret && values.clientSecret === undefined ? (
            <Input
              id="clientSecret"
              label="OAuth Client Secret"
              labelClassName="col-sm-3"
              disabled={isSubmitting}
              wrapperClassName="col-sm-9">
              <Button type="button" onClick={() => setFieldValue('clientSecret', '')}>
                Reset Secret
              </Button>
            </Input>
          ) : (
            <FormikFormGroup
              buttonAfter={
                hasSecret && values.clientSecret !== undefined ? (
                  <Button type="button" onClick={() => setFieldValue('clientSecret', undefined)}>
                    Undo Reset
                  </Button>
                ) : undefined
              }
              help={help.clientSecret}
              label="OAuth Client Secret"
              name="clientSecret"
              error={backendValidationErrors?.clientSecret}
              placeholder="OAuth Client Secret"
              disabled={isSubmitting}
              validate={validateField(FORM_VALIDATION.clientSecret)}
              type="password"
            />
          )}

          <FormikFormGroup
            help={help.tokenVerifierConnectTimeout}
            label="Token Verifier Connect Timeout"
            type="number"
            name="tokenVerifierConnectTimeout"
            disabled={isSubmitting}
            required
            placeholder="Token Verifier Connect Timeout"
          />

          <Field name="defaultRoles" validate={validateField(FORM_VALIDATION.defaultRoles)}>
            {({ field: { name, value, onChange, onBlur }, meta: { error } }) => (
              <Input
                bsStyle={error ? 'error' : undefined}
                help={help.defaultRoles}
                error={error ?? backendValidationErrors?.defaultRoles}
                id="default-roles-select"
                label="Default Roles"
                labelClassName="col-sm-3"
                wrapperClassName="col-sm-9">
                <Select
                  multi
                  onBlur={onBlur}
                  onChange={(selectedRoles) => onChange({ target: { value: selectedRoles, name } })}
                  options={rolesOptions}
                  placeholder="Search for roles"
                  value={value}
                />
              </Input>
            )}
          </Field>
          {submitAllError}
          <ButtonToolbar className="pull-right">
            <Button
              disabled={isSubmitting}
              onClick={() => {
                sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.OIDC_SERVER_CONFIG_SAVE_CLICKED, {
                  app_pathname: getPathnameWithoutId(pathname),
                  app_section: 'oidc-server-configuration',
                  app_action_value: 'server-configuration-step',
                });
              }}
              type="submit">
              Finish & Save Service
            </Button>
            <Button
              bsStyle="success"
              disabled={isSubmitting}
              onClick={() => {
                sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.OIDC_NEXT_GROUP_SYNC_CLICKED, {
                  app_pathname: getPathnameWithoutId(pathname),
                  app_section: 'oidc-groupsync-step',
                  app_action_value: 'group-synchronization-button',
                });

                goToNext();
              }}
              type="button">
              Next: Group Synchronization
            </Button>
          </ButtonToolbar>
        </Form>
      </Col>
      <Col md={5}>
        <ServerConnectionTest
          backendId={id}
          prepareSubmitPayload={() => payloadFromFormValues(values)}
          validateForm={validateForm}
        />
      </Col>
    </>
  );
};

export default ServerConfigurationForm;
