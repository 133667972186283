import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import fetchReports from 'report/logic/fetchReports';

const retrieveReportsReferencingWidgets = (dashboardId: string, widgetIds: Array<string>) =>
  fetch<Array<string>>(
    'POST',
    qualifyUrl(`/plugins/org.graylog.plugins.report/reports/byWidgets/${dashboardId}`),
    widgetIds,
  );

const isDashboardPageUsedInReports = async (
  dashboardId: string,
  queryId: string,
  widgetIds: { [queryId: string]: Array<string> },
) => {
  const reports = await fetchReports();
  const reportIds = await retrieveReportsReferencingWidgets(dashboardId, widgetIds[queryId]);

  if (reportIds?.length === 1) {
    const reportId = reportIds[0];
    const inaccessibleReport = !reports[reportId];
    const reportTitle = inaccessibleReport ? reportId : `"${reports[reportId].title}"`;

    // eslint-disable-next-line no-alert
    return window.confirm(
      `One or more widget(s) from this dashboard page are used in ${inaccessibleReport ? 'inaccessible ' : ''}report ${reportTitle}.\n\n Do you really want to delete it?`,
    );
  }

  if (reportIds?.length > 1) {
    const reportTitles = reportIds
      .map((reportId) => {
        const inAccessibleReport = !reports[reportId];
        const reportTitle = inAccessibleReport ? reportId : `"${reports[reportId].title}"`;

        return `    - ${reportTitle}${inAccessibleReport ? ' (not permitted to see title)' : ''}`;
      })
      .join('\n');

    // eslint-disable-next-line no-alert
    return window.confirm(
      `One or more widget(s) from this dashboard page are used in reports:\n${reportTitles}.\n\n Do you really want to delete it?`,
    );
  }

  return null;
};

export default isDashboardPageUsedInReports;
