import * as React from 'react';
import { useState } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import type { Notification } from 'theme/types';
import SectionComponent from 'components/common/Section/SectionComponent';
import { Button, Label, Table } from 'components/bootstrap';
import { Icon } from 'components/common';
import ConfirmDialog from 'components/common/ConfirmDialog';
import ButtonToolbar from 'components/bootstrap/ButtonToolbar';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import usePublicNotifications from './usePublicNotifications';
import ModalForm from './ModalForm';

const StyledTable = styled(Table)`
  .form-group {
    margin: 0;

    .checkbox {
      margin: 0;
    }
  }

  tbody > tr > td {
    vertical-align: middle;
  }
`;

const TitleTH = styled.th`
  width: 35%;
`;

const BooleanTH = styled.th`
  width: 8%;
  text-align: center;
`;

const VariantTH = styled.th`
  min-width: 10%;
  text-align: center;
`;

const ActionsTD = styled.td`
  text-align: right;
`;

const OptionsTD = styled.td`
  text-align: center;
`;

const BooleanIcon = styled(Icon).attrs<{ $enabled: boolean }>(({ $enabled }) => ({
  name: $enabled ? 'check_circle' : 'cancel',
}))<{ $enabled: boolean; name?: string }>(
  ({ $enabled, theme }: { $enabled: boolean; theme: DefaultTheme }) => css`
    color: ${$enabled ? theme.colors.variant.success : theme.colors.variant.danger};
  `,
);

const PublicNotifications = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deletedData, setDeletedData] = useState<{ title: string; id: number }>({ title: undefined, id: undefined });
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [editingID, setEditingID] = useState<string>(undefined);
  const { notifications, onCreatePublicNotification, onDeletePublicNotification, onUpdatePublicNotification } =
    usePublicNotifications();
  const isEmpty = Object.keys(notifications).length === 0;
  const sendTelemetry = useSendTelemetry();

  const toggleShowConfirmDelete = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
    setEditing(false);
    setEditingID(undefined);
  };

  const handleCreateNotification = (values): Promise<void | Notification> => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.CUSTOMIZATION.NOTIFICATION_CREATED, {
      app_pathname: 'customization',
      app_section: 'public-notification',
    });

    return onCreatePublicNotification(values).then(toggleShowModal);
  };

  const handleEditNotification = (values): Promise<void | Notification> =>
    onUpdatePublicNotification(editingID, values).then(toggleShowModal);

  const showConfirmation = ({ title, id }) => {
    setDeletedData({ title, id });
    toggleShowConfirmDelete();
  };

  const showEditModal = (id) => {
    setEditing(true);
    setEditingID(id);
    setShowModal(!showModal);
  };

  const handleDelete = (id) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.CUSTOMIZATION.NOTIFICATION_DELETED, {
      app_pathname: 'customization',
      app_section: 'public-notification',
      app_action_value: 'delete',
    });

    return onDeletePublicNotification(id).then(toggleShowConfirmDelete);
  };

  return (
    <SectionComponent title="Public Notifications" headerActions={<Button onClick={toggleShowModal}>Create</Button>}>
      {isEmpty ? (
        <strong>No notifications available.</strong>
      ) : (
        <StyledTable striped condensed>
          <thead>
            <tr>
              <TitleTH>Title</TitleTH>
              <BooleanTH>Active</BooleanTH>
              <BooleanTH>Dismissible</BooleanTH>
              <BooleanTH>At Login</BooleanTH>
              <BooleanTH>Global</BooleanTH>
              <VariantTH>Variant</VariantTH>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(notifications).map((notificationID) => {
              const currentNotification = notifications[notificationID];

              return (
                <tr key={notificationID}>
                  <td>{currentNotification.title}</td>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.isActive} />
                  </OptionsTD>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.isDismissible} />
                  </OptionsTD>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.atLogin} />
                  </OptionsTD>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.isGlobal} />
                  </OptionsTD>
                  <OptionsTD>
                    <Label bsStyle={currentNotification.variant}>{currentNotification.variant.toUpperCase()}</Label>
                  </OptionsTD>
                  <ActionsTD>
                    <ButtonToolbar className="pull-right">
                      <Button onClick={() => showEditModal(notificationID)} bsSize="xs">
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          showConfirmation({ title: currentNotification.title, id: notificationID });
                        }}
                        bsStyle="danger"
                        bsSize="xs">
                        Delete
                      </Button>
                    </ButtonToolbar>
                  </ActionsTD>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      )}

      <ModalForm
        show={showModal}
        toggleShowModal={toggleShowModal}
        onSubmit={editing ? handleEditNotification : handleCreateNotification}
        submitButtonText={editing ? 'Update notification' : 'Create notification'}
        submitLoadingText={editing ? 'Updating notification...' : 'Creating notification...'}
        initialValues={
          editing
            ? notifications[editingID]
            : {
                title: '',
                shortMessage: '',
                longMessage: '',
                isActive: true,
                isDismissible: false,
                atLogin: false,
                isGlobal: false,
                variant: 'default',
                hiddenTitle: false,
              }
        }
      />

      <ConfirmDialog
        show={showConfirmDelete}
        onConfirm={() => handleDelete(deletedData.id)}
        onCancel={toggleShowConfirmDelete}
        title="Are you sure?">
        <>
          <p>
            You are about to permanently delete <strong>{deletedData.title}</strong>.
          </p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>
    </SectionComponent>
  );
};

export default PublicNotifications;
