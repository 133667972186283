import * as React from 'react';
import styled from 'styled-components';

import { SearchForm } from 'components/common';

import TeamsQueryHelper from '../../security/components/teams-overview/TeamsQueryHelper';

type Props = {
  onSearch: (query: string) => void;
};

const Container = styled.div`
  margin-bottom: 10px;
`;

const SyncedTeamsFilter = ({ onSearch }: Props) => (
  <Container>
    <SearchForm
      onReset={() => onSearch('')}
      onSearch={onSearch}
      queryHelpComponent={<TeamsQueryHelper />}
      topMargin={0}
    />
  </Container>
);

export default SyncedTeamsFilter;
