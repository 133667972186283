import * as React from 'react';

import { Section } from 'components/common';
import { Alert } from 'components/bootstrap';
import type { Stream } from 'logic/streams/types';

type Props = {
  stream: Stream;
};

const StreamIlluminateProcessingSection = ({ stream }: Props) => {
  if (!stream.title.startsWith('Illuminate')) {
    return null;
  }

  return (
    <Section title="Illuminate processing">
      <Alert bsStyle="default">
        The <b>Illuminate Processing</b> step is an immutable Pipeline that occurs before user Pipelines in the default
        processing order.
        <br />
        It collects messages that meet supported formats from the All Messages stream, parses that data into the Graylog
        GIM schema fields and routes them to this Stream.
      </Alert>
    </Section>
  );
};

export default StreamIlluminateProcessingSection;
