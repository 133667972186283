import { useQuery } from '@tanstack/react-query';

import { EnterpriseFailureHandlerFailureCount } from '@graylog/enterprise-api';

import type FetchError from 'logic/errors/FetchError';
import { defaultOnError } from 'util/conditional/onError';

type FailureCount = {
  count: number;
  source: 'elasticsearch' | 'mongodb';
  enabled: boolean;
};

type FailureCountResponse = {
    readonly [_key: string]: FailureCount;
}

const fetchFailureCountOverview = () =>  EnterpriseFailureHandlerFailureCount.count();

const useFailuresOverview = (): {
  data: FailureCountResponse;
  refetch: () => void;
  isLoading: boolean;
  error: FetchError;
  isSuccess: boolean;
} => {
  const { data, refetch, isLoading, error, isSuccess } = useQuery<FailureCountResponse, FetchError>(
    ['failures', 'counts'],
    () =>
      defaultOnError(
        fetchFailureCountOverview(),
        'Loading failure counts failed with status',
        'Could not load failure counts stats.',
      ),
    {
      notifyOnChangeProps: ['data', 'error'],
    },
  );

  return {
    data,
    refetch,
    isLoading,
    error,
    isSuccess,
  };
};

export default useFailuresOverview;
