import * as React from 'react';
import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';

import type { WidgetRef, AvailableWidgetPreview as TAvailableWidgetPreview } from 'report/types';
import type { AvailableDashboards } from 'report/report-creation/content/types';
import { widgetId, compareWidgetRefs } from 'report/report-creation/content/types';
import Icon from 'components/common/Icon';
import WidgetRow from 'report/report-creation/content/WidgetRow';
import { StyledCheckbox, Row, SmallColumn, Column, ExpandButton } from 'report/report-creation/content/common';
import Timestamp from 'components/common/Timestamp';

type DashboardRowProps = {
  checked: Array<WidgetRef>;
  dashboard: AvailableDashboards;
  widgetsInReport: Array<WidgetRef>;
  onChange: (ids: Array<WidgetRef>) => void;
};

const DashboardIcon = () => <Icon name="dashboard" type="regular" />;

const DashboardRow = ({ dashboard, checked, widgetsInReport, onChange }: DashboardRowProps) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded((_expanded) => !_expanded), []);
  const _onCheck = useCallback((id: WidgetRef) => onChange(unionWith(checked, [id], isEqual)), [checked, onChange]);
  const _onUncheck = useCallback(
    (id: WidgetRef) => onChange(differenceWith(checked, [id], isEqual)),
    [checked, onChange],
  );
  const dashboardWidgetRefs = useMemo(
    () => dashboard.widgets.map((widget) => widgetId(widget, dashboard)),
    [dashboard],
  );
  const isWidgetInReport = useCallback(
    (widget: TAvailableWidgetPreview) =>
      widgetsInReport.find((w) => w.widgetId === widget.id && w.dashboardId === dashboard.id) !== undefined,
    [dashboard.id, widgetsInReport],
  );
  const addableWidgetRefs = useMemo(
    () =>
      dashboard.widgets
        .filter((widget) => widget.eligible)
        .filter((widget) => !isWidgetInReport(widget))
        .map((widget) => widgetId(widget, dashboard)),
    [dashboard, isWidgetInReport],
  );
  const _onChangeDashboard = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      e.target.checked
        ? onChange(unionWith(checked, addableWidgetRefs, isEqual))
        : onChange(differenceWith(checked, dashboardWidgetRefs, isEqual)),
    [addableWidgetRefs, checked, dashboardWidgetRefs, onChange],
  );
  const checkboxRef = useRef<HTMLInputElement>();

  const widgetRows = expanded
    ? dashboard.widgets.map((widget) => (
        <WidgetRow
          key={`widget-${widget.id}`}
          inReport={isWidgetInReport(widget)}
          widget={widget}
          dashboard={dashboard}
          onCheck={_onCheck}
          onUncheck={_onUncheck}
          checked={checked.some((wi) => isEqual(wi, widgetId(widget, dashboard)))}
        />
      ))
    : [];

  const isEmpty = dashboard.widgets.length === 0;
  const hasNoEligibleWidgets = dashboard.widgets.every((widget) => widget.eligible === false);

  const matchingEntries = intersectionWith(dashboardWidgetRefs, checked, compareWidgetRefs);
  const isChecked = addableWidgetRefs.length > 0 && matchingEntries.length === addableWidgetRefs.length;
  const isIndetermined = matchingEntries.length > 0 && matchingEntries.length < addableWidgetRefs.length;

  // eslint-disable-next-line no-nested-ternary
  const title = isEmpty
    ? 'Dashboard has no widgets.'
    : // eslint-disable-next-line no-nested-ternary
      hasNoEligibleWidgets
      ? 'Dashboard has no widgets eligible for reporting.'
      : expanded
        ? `Click to collapse all widgets of dashboard "${dashboard.title}"`
        : `Click to expand all widgets of dashboard "${dashboard.title}"`;
  const disabled = isEmpty || hasNoEligibleWidgets || addableWidgetRefs.length === 0;

  useEffect(() => {
    if (checkboxRef?.current) {
      checkboxRef.current.indeterminate = !isChecked && isIndetermined;
    }
  }, [isChecked, isIndetermined]);

  return (
    <>
      <Row $disabled={disabled} title={title}>
        <SmallColumn $displayCursor={false}>
          <StyledCheckbox
            disabled={disabled}
            inputRef={(input) => {
              checkboxRef.current = input;
            }}
            onChange={_onChangeDashboard}
            title={`${isChecked ? 'Deselect' : 'Select'} all widgets from dashboard "${dashboard.title}"`}
            checked={isChecked}
          />
        </SmallColumn>
        <SmallColumn onClick={toggleExpanded}>
          <ExpandButton expanded={expanded} title="Expand dashboard" />
        </SmallColumn>
        <Column onClick={toggleExpanded}>
          <DashboardIcon /> {dashboard.title}
        </Column>
        <Column onClick={toggleExpanded}>{dashboard.owner}</Column>
        <Column onClick={toggleExpanded}>
          <Timestamp dateTime={dashboard.last_updated_at} />
        </Column>
      </Row>
      {widgetRows}
    </>
  );
};

export default DashboardRow;
