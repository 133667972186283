import React from 'react';

import type { ForwarderMetric } from '../Types';
import formatMetric from '../util/formatMetric';

type Props = {
  metric?: ForwarderMetric;
  prefix?: string;
  suffix?: string;
};

const CounterRate = ({ metric = null, prefix = '', suffix = '' }: Props) => {
  if (!metric) {
    return null;
  }

  const {
    rate: { one_minute: oneMinute },
  } = metric;

  return (
    <span>
      {prefix ? `${prefix} ` : null}
      {formatMetric(oneMinute)}
      {suffix ? ` ${suffix}` : null}
    </span>
  );
};

export default CounterRate;
