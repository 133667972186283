import * as React from 'react';
import URI from 'urijs';

import type { Report, ReportSummary } from 'report/types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import ReportsActions from 'report/ReportsActions';
import Routes from 'routing/Routes';
import { MenuItem, DeleteMenuItem } from 'components/bootstrap';
import ButtonToolbar from 'components/bootstrap/ButtonToolbar';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';
import UserNotification from 'util/UserNotification';
import { HoverForHelp } from 'components/common';
import { reportingUrl } from 'report/Constants';
import { MoreActions } from 'components/common/EntityDataTable';
import useFeature from 'hooks/useFeature';
import ReportRoutes from 'report/ReportRoutes';

const downloadUrl = (reportId: string) => new URI(reportingUrl(`${reportId}/generate/`)).toString();

const _reportHasRecipients = (delivery: Report['delivery']) =>
  delivery &&
  ((Array.isArray(delivery.user_receivers) && delivery.user_receivers.length > 0) ||
    (Array.isArray(delivery.email_receivers) && delivery.email_receivers.length > 0));

const SendReportMenuItem = ({
  reportDelivery,
  reportId,
  reportTitle,
}: {
  reportDelivery: Report['delivery'];
  reportId: string;
  reportTitle: string;
}) => {
  const sendTelemetry = useSendTelemetry();
  const reportHasRecipients = _reportHasRecipients(reportDelivery);
  const isDisabled = !reportHasRecipients;

  const onSendReport = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.SENT, {
      app_pathname: 'report',
      app_section: 'report-form',
    });

    await ReportsActions.sendEmail(reportId)
      .then(() => {
        UserNotification.success(
          'The Report is being generated and will be send to all recipients shortly, check your inbox in a few minutes.',
          'Report delivery scheduled',
        );
      })
      .catch(() => {
        UserNotification.error(
          `Sending Report "${reportTitle}" email failed. Please check your server logs for more information.`,
          'Could not send Report',
        );
      });
  };

  return (
    <MenuItem disabled={isDisabled} onSelect={onSendReport}>
      Send report now
      {isDisabled && (
        <HoverForHelp trigger={['hover', 'click']} placement="left" displayLeftMargin>
          Sending report disabled, add recipients first
        </HoverForHelp>
      )}
    </MenuItem>
  );
};

const DeleteReportAction = ({ reportId, reportTitle }: { reportId: string; reportTitle: string }) => {
  const { deselectEntity } = useSelectedEntities();
  const sendTelemetry = useSendTelemetry();

  const onDeleteReport = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.DELETED, {
      app_pathname: 'report',
      app_section: 'reports-overview',
    });

    // eslint-disable-next-line no-alert
    if (window.confirm(`Do you really want to delete report "${reportTitle}"? This action cannot be undone.`)) {
      await ReportsActions.delete(reportId)
        .then(() => {
          deselectEntity(reportId);
          UserNotification.success(`Report "${reportTitle}" was deleted successfully.`, 'Success');
        })
        .catch((error) => {
          UserNotification.error(`Deleting Report "${reportTitle}" failed with status: ${error}`);
        });
    }
  };

  return <DeleteMenuItem onSelect={onDeleteReport} />;
};

type Props = {
  report: ReportSummary;
};

const ReportActions = ({ report }: Props) => {
  const sendTelemetry = useSendTelemetry();
  const featureFlagIsEnabled = useFeature('new_report_creation');

  const onDownload = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.DOWNLOAD, {
      app_pathname: 'report',
      app_section: 'reports-overview',
    });
  };

  return (
    <ButtonToolbar>
      <MoreActions>
        {featureFlagIsEnabled ? (
          <MenuItem href={ReportRoutes.contents(report.id)}>Edit</MenuItem>
        ) : (
          <MenuItem href={Routes.pluginRoute('REPORTS_REPORTID_CONFIGURATION')(report.id)}>Configuration</MenuItem>
        )}
        <MenuItem href={ReportRoutes.archive(report.id)}>Report archive</MenuItem>
        <MenuItem href={downloadUrl(report.id)} target="_blank" onClick={onDownload}>
          Download report now
        </MenuItem>
        <SendReportMenuItem reportDelivery={report.delivery} reportTitle={report.title} reportId={report.id} />
        <MenuItem divider />
        <DeleteReportAction reportId={report.id} reportTitle={report.title} />
      </MoreActions>
    </ButtonToolbar>
  );
};

export default ReportActions;
