import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import type * as Immutable from 'immutable';

import { Col, Row } from 'components/bootstrap';
import { Spinner } from 'components/common';
import Routes from 'routing/Routes';
import type { ReportCreate, Report } from 'report/types';
import type { AvailableDashboard } from 'report/report-contents-page/useAvailableWidgets';
import useAvailableWidgets from 'report/report-contents-page/useAvailableWidgets';
import useHistory from 'routing/useHistory';
import type { HistoryFunction } from 'routing/useHistory';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { DEFAULT_FORMAT } from 'report/types';
import { parameterCanHaveValue } from 'report/report-creation/validateReportForm';
import { NEW_REPORT_DEFAULTS } from 'report/Constants';

import ReportContentsSelection from './ReportContentsSelection';
import ReportSummary from './ReportSummary';
import ReportContentsToolbar from './ReportContentsToolbar';
import ReportParameters from './ReportParameters';

import ReportsActions from '../ReportsActions';

type Props = {
  report?: ReportCreate | Report;
  reportLogo?: string;
  action?: 'create' | 'edit';
};

const _extractParameters = (
  report: ReportCreate | Report,
  dashboards: Immutable.List<AvailableDashboard> | Array<AvailableDashboard>,
) => {
  const dashboardWidgets = report.widgets.map(({ dashboard_widget_id: widgetId, dashboard_id: dashboardId }) => {
    if (!dashboards) {
      return { widgets: undefined, searchId: undefined };
    }

    const dashboard = dashboards.find((d) => d.id === dashboardId);

    if (!dashboard) {
      throw new Error(`Report references dashboard <${dashboardId}> - not found!`);
    }

    const widgets = Object.values(dashboard.widgets).flat();

    return {
      widgets: widgets.find((widget) => widget.id === widgetId),
      searchId: dashboard.search_id,
    };
  });

  // TODO: Duplicate parameters need to be merged?
  const parameters = Object.fromEntries(
    dashboardWidgets.flatMap((w) => w.widgets.parameters).map((parameter) => [parameter.name, parameter]),
  );

  const parameterSearchIds = Object.fromEntries(
    dashboardWidgets.flatMap(({ widgets, searchId }) => widgets.parameters.map(({ name }) => [name, searchId])),
  );

  return {
    parameters,
    parameterSearchIds,
  };
};

const formId = 'report-contents-selection-form';

const redirectToReportsPage = (history: HistoryFunction) => history.push(Routes.pluginRoute('REPORTS'));

const ReportContents = ({
  action = 'create',
  report: initialReport = NEW_REPORT_DEFAULTS,
  reportLogo: initialReportLogo = null,
}: Props) => {
  const [report, setReport] = useState(initialReport);
  const [reportLogo, setReportLogo] = useState(initialReportLogo);
  const [parameterValues, setParameterValues] = useState(report.parameterValues ?? {});
  const [dataTouched, setDataTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formAction = action === 'create' ? ReportsActions.create : ReportsActions.update;
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();

  const { data: dashboards, isLoading } = useAvailableWidgets();

  const { parameters, parameterSearchIds } = useMemo(
    () => (dashboards ? _extractParameters(report, dashboards) : { parameters: {}, parameterSearchIds: {} }),
    [report, dashboards],
  );

  const requiredParameters = useMemo(
    () =>
      Object.values(parameters).filter(
        (parameter) => parameterCanHaveValue(parameter) && !parameter.optional && !parameterValues[parameter.name],
      ),
    [parameters, parameterValues],
  );
  const requiresParameters = requiredParameters.length > 0;

  const _saveReport = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT[`${action === 'create' ? 'CREATED' : 'UPDATED'}`], {
        app_pathname: 'report',
        app_section: 'report-form',
        event_details: {
          format: report?.layout?.format ?? DEFAULT_FORMAT,
          widget_count: report?.widgets?.length,
        },
      });

      setIsSubmitting(true);
      const resetState = () => setIsSubmitting(false);

      formAction(report, reportLogo, parameterValues).then(() => {
        redirectToReportsPage(history);
      }, resetState);
    },
    [action, formAction, parameterValues, report, reportLogo, history, sendTelemetry],
  );

  const _confirmNavigation = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (
      dataTouched &&
      !window.confirm('Do you really want to abandon this page and lose your changes? This action cannot be undone.')
    ) {
      return;
    }

    history.push(Routes.pluginRoute('REPORTS'));
  }, [dataTouched, history]);

  const _updateReport = useCallback((updatedReport: ReportCreate | Report) => {
    setReport(updatedReport);
    setDataTouched(true);
  }, []);

  const _updateReportLogo = useCallback((nextReportLogo: string) => {
    setReportLogo(nextReportLogo);
    setDataTouched(true);
  }, []);

  const addParameterValue = useCallback(
    (name: string, value: string) => {
      setParameterValues({ ...parameterValues, [name]: value });
    },
    [parameterValues],
  );

  if (isLoading) {
    return (
      <div>
        <Spinner text="Loading report data, please wait..." />
      </div>
    );
  }

  return (
    <form id={formId} onSubmit={_saveReport}>
      <Row>
        <Col md={6}>
          <ReportContentsSelection
            formElementId={formId}
            report={report}
            reportLogo={reportLogo}
            dashboards={dashboards.toJS()}
            onReportChange={_updateReport}
            onReportLogoChange={_updateReportLogo}
            onCancel={_confirmNavigation}
            action={action}
            disabled={requiresParameters}
            isLoading={isSubmitting}
          />
        </Col>
        <Col md={5} mdOffset={1}>
          <ReportSummary report={report} reportLogo={reportLogo}>
            <ReportParameters
              parameters={Object.values(parameters)}
              parameterValues={parameterValues}
              onChange={addParameterValue}
              parameterSearchIds={parameterSearchIds}
            />
            <ReportContentsToolbar
              action={action}
              disabled={requiresParameters}
              onCancel={_confirmNavigation}
              formElementId={formId}
              isLoading={isSubmitting}
            />
          </ReportSummary>
        </Col>
      </Row>
    </form>
  );
};

export default ReportContents;
