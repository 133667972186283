import * as React from 'react';
import styled, { css } from 'styled-components';

import { ClipboardIconButton } from 'components/common';
import { ButtonToolbar } from 'components/bootstrap';
import IconButton from 'components/common/IconButton';

const StyledButtonToolbar = styled(ButtonToolbar)(
  ({ theme }) => css`
    position: absolute;
    right: 1px;
    top: 0;
    max-height: 100%;
    background-color: ${theme.colors.table.row.backgroundHover};
  `,
);

type Props = {
  message: { [fieldName: string]: unknown };
  onShowDetails: () => void;
};

const LogViewRowActions = ({ message, onShowDetails }: Props) => (
  <StyledButtonToolbar>
    <ClipboardIconButton buttonTitle="Copy message" text={JSON.stringify(message, null, 2)} />
    <IconButton title="Open message details" name="open_in_full" onClick={onShowDetails} />
  </StyledButtonToolbar>
);

export default LogViewRowActions;
