import * as React from 'react';
import { useMemo } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import type { SearchExecutionResult } from 'views/types';
import useSearchExecutors from 'views/components/contexts/useSearchExecutors';
import type { SearchExecutors } from 'views/logic/slices/searchExecutionSlice';
import { searchExecutionSliceReducer } from 'views/logic/slices/searchExecutionSlice';
import type { RootStoreState } from 'data-lake/preview/Types';

type Props = {
  result?: SearchExecutionResult;
};

const useInitialState = (result: SearchExecutionResult): Partial<RootStoreState> =>
  useMemo(
    () => ({
      searchExecution: {
        searchTypesToSearch: undefined,
        executionState: SearchExecutionState.empty(),
        isLoading: false,
        result,
      },
    }),
    [result],
  );

const createStore = (reducers: Array<any>, initialState: Partial<RootStoreState>, searchExecutors: SearchExecutors) => {
  const reducer = Object.fromEntries(reducers.map((r) => [r.key, r.reducer]));

  return configureStore({
    reducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: { searchExecutors },
        },
      }),
  });
};

const DataLakePreviewStoreProvider = ({ children = undefined, result = undefined }: React.PropsWithChildren<Props>) => {
  const reducers = useMemo(
    () => [
      {
        key: 'searchExecution',
        reducer: searchExecutionSliceReducer,
      },
    ],
    [],
  );
  const initialState = useInitialState(result);
  const searchExecutors = useSearchExecutors();
  const store = useMemo(
    () => createStore(reducers, initialState, searchExecutors),
    [initialState, reducers, searchExecutors],
  );

  return <Provider store={store}>{children}</Provider>;
};

export default DataLakePreviewStoreProvider;
