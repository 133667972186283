import { useFormikContext } from 'formik';

import type { TimeRange } from 'views/logic/queries/Query';

const useSearchFormValues = () => {
  const {
    values: { parameterBindings = {}, timerange = undefined, streams = undefined },
  } = useFormikContext<{
    parameterBindings: { [key: string]: string };
    timerange: TimeRange;
    streams: Array<string>;
  }>();

  return { parameterBindings, timerange, streams };
};

export default useSearchFormValues;
