import * as React from 'react';

import { useBulkSetOwnerForSecurityEvents } from 'security-app/hooks/useSecurityEventsAPI';
import UsersModal from 'security-app/components/SecurityEvents/Alerts/AlertsModals/UsersModal/UsersModal';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';
import useRefetchTable from 'security-app/components/SecurityEvents/Alerts/AlertsModals/UsersModal/useRefetchTable';

type Props = {
  eventIds: Array<string>;
  onClose: () => void;
  show: boolean;
};

const SetOwnerBulk = ({ eventIds, onClose, show }: Props) => {
  const { setOwner, settingOwners } = useBulkSetOwnerForSecurityEvents();
  const sendEventActionTelemetry = useSendEventActionTelemetry();
  const refetch = useRefetchTable();

  const onConfirm = async (ownerId: string) => {
    await setOwner({ ids: eventIds, ownerId });
    sendEventActionTelemetry('SET_OWNER', true);

    refetch();

    onClose();
  };

  return (
    <UsersModal
      confirmDisabled={settingOwners}
      closeModal={onClose}
      onConfirm={onConfirm}
      show={show}
      isLoading={settingOwners}
    />
  );
};

export default SetOwnerBulk;
