import * as React from 'react';
import styled from 'styled-components';
import defaultTo from 'lodash/defaultTo';

import { ControlLabel, MenuItem, Button, DeleteMenuItem } from 'components/bootstrap';
import { Spinner, MultiSelect, Icon } from 'components/common';
import useScopePermissions from 'hooks/useScopePermissions';
import { useModal, ConfirmDialog } from 'security-app/components/common/Modal';
import { useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import { useBulkSigmaOperations, useBulkAddNotifications, useGetNotifications } from 'security-app/hooks/useSigmaAPI';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { qualifyUrl } from 'util/URLUtils';
import { DropdownMenu } from 'security-app/components/common';

import DeleteMessage from './DeleteMessage';

const StyledInput = styled.div`
  padding: 0.5rem 0;
`;

const WarningMessage = styled.small`
  color: ${({ theme }) => theme.colors.variant.warning};
`;

const BulkActionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 16px;
  display: inline-block;
  text-align: left;
`;

type Props = {
  visibleBulkActions?: Array<string>;
};

function BulkActions({ visibleBulkActions = ['enable', 'disable', 'add_notification', 'download', 'delete'] }: Props) {
  const { checkPermissions } = useScopePermissions();
  const [showActionsDialog, toggleActionsDialog] = useModal();
  const [showNotificationsDialog, toggleNotificationsDialog] = useModal();

  const [selectedAction, setSelectedAction] = React.useState<string>('');
  const [selectedNotifications, setSelectedNotifications] = React.useState([]);

  const { notifications } = useGetNotifications(true);
  const notificationOptions = React.useMemo(
    () =>
      notifications.map((notification: { id: string; title: string }) => ({
        label: notification.title,
        value: notification.id,
      })),
    [notifications],
  );

  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const { bulkSigmaOperations, isMutating } = useBulkSigmaOperations();
  const { bulkAddNotifications, isAdding } = useBulkAddNotifications();

  const clearSelection = () => selectedRulesDispatch({ type: 'clear' });

  const getRulesToDelete = () => {
    const mutableRules = selectedRules.filter(checkPermissions);
    const immutableRules = selectedRules.filter((rule: SigmaRuleListAPIType) => !checkPermissions(rule));

    return [mutableRules, immutableRules];
  };

  const handleConfirm = () => {
    const ids = selectedAction === 'delete'
      ? getRulesToDelete()[0].map((rule: SigmaRuleListAPIType) => rule.id)
      : selectedRules.map((rule: SigmaRuleListAPIType) => rule.id);

    switch (selectedAction) {
      case 'enable':
        bulkSigmaOperations({ ids, action: 'enable' }, { onSuccess: clearSelection });
        break;
      case 'disable':
        bulkSigmaOperations({ ids, action: 'disable' }, { onSuccess: clearSelection });
        break;
      case 'delete':
        bulkSigmaOperations({ ids, action: 'delete' }, { onSuccess: clearSelection });
        break;
      case 'add_notifications':
        bulkAddNotifications(
          { ids, action: 'add_notifications', notifications: selectedNotifications },
          { onSuccess: clearSelection },
        );

        break;
      case 'download':
        // eslint-disable-next-line no-case-declarations
        const idParams = ids.map((ruleId: string) => `ruleIds=${ruleId}`).join('&');
        window.open(qualifyUrl(EnterpriseApiRoutes.SecurityApp.downloadSigmaRule(idParams).url), '_self');

        break;
      default:
        break;
    }

    toggleActionsDialog(false);
    toggleNotificationsDialog(false);
  };

  const onEnable = () => {
    toggleActionsDialog(true);
    setSelectedAction('enable');
  };

  const onDisable = () => {
    toggleActionsDialog(true);
    setSelectedAction('disable');
  };

  const onNotificationAdd = () => {
    toggleNotificationsDialog(true);
    setSelectedAction('add_notifications');
  };

  const onDownload = () => {
    toggleActionsDialog(true);
    setSelectedAction('download');
  };

  const onDelete = () => {
    toggleActionsDialog(true);
    setSelectedAction('delete');
  };

  const handleCancel = () => {
    toggleActionsDialog(false);
    toggleNotificationsDialog(false);
    setSelectedAction('');
  };

  const onClearSelection = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    selectedRulesDispatch({ type: 'clear' });
  };

  const actionIsVisible = (actionName: string) => visibleBulkActions.includes(actionName);

  return (
    <>
      <DropdownMenu
        trigger={
          <BulkActionsButtons>
            <Button disabled={selectedRules.length < 1}>
              <IconContainer>
                <Icon name="arrow_drop_down" size="xs" />
              </IconContainer>
              Bulk Actions {selectedRules.length > 0 ? selectedRules.length : null}
            </Button>
            {selectedRules.length > 0 && (
              <Button onClick={onClearSelection} title="Clear selection">
                <Icon name="close" />
              </Button>
            )}
          </BulkActionsButtons>
        }>
        {actionIsVisible('enable') && <MenuItem onClick={onEnable}>Enable</MenuItem>}
        {actionIsVisible('disable') && <MenuItem onClick={onDisable}>Disable</MenuItem>}
        {actionIsVisible('add_notification') && <MenuItem onClick={onNotificationAdd}>Add Notification</MenuItem>}
        {actionIsVisible('download') && <MenuItem onClick={onDownload}>Download</MenuItem>}
        {actionIsVisible('delete') && (<><MenuItem divider /><DeleteMenuItem onClick={onDelete} /></>)}
      </DropdownMenu>
      <ConfirmDialog
        show={showActionsDialog}
        onConfirm={handleConfirm}
        confirmText={
          isMutating ? (
            <Spinner text={`${selectedAction}ing...`} delay={0} />
          ) : (
            `${selectedAction.charAt(0).toUpperCase()}${selectedAction.slice(1)} ${selectedRules.length > 1 ? 'rules' : 'rule'}`
          )
        }
        confirmDisabled={isMutating}
        onCancel={handleCancel}
        title={
          <>
            <span>{`${selectedAction.charAt(0).toUpperCase()}${selectedAction.slice(1)} Sigma ${selectedRules.length > 1 ? 'Rules' : 'Rule'}`}</span>
            <br />
            {selectedAction === 'enable' && selectedRules.length > 1 && (
              <WarningMessage>
                <i>
                  <Icon name="warning" /> Enabling many rules at the same time can be a resource intensive task. Proceed
                  with caution.
                </i>
              </WarningMessage>
            )}
          </>
        }>
      {}
      {selectedAction === 'delete' ? (
        <DeleteMessage mutableRules={getRulesToDelete()[0]} immutableRules={getRulesToDelete()[1]} />
      ) : (
        <>
          <b>
            Are you sure you want to {selectedAction} the following{' '} {selectedRules.length > 1 ? 'rules' : 'rule'}:
          </b>
          {selectedRules.map((rule: SigmaRuleListAPIType) => (
            <span key={rule.id}>{rule.title}</span>
          ))}
        </>
      )}
      </ConfirmDialog>
      <ConfirmDialog
        show={showNotificationsDialog}
        onConfirm={handleConfirm}
        confirmText={isAdding ? <Spinner text="Adding..." delay={0} /> : 'Add notification'}
        confirmDisabled={isAdding || selectedNotifications.length === 0}
        onCancel={handleCancel}
        title={`Add Notification To Sigma ${selectedRules.length > 1 ? 'Rules' : 'Rule'}`}
      >
        <>
          <p>Add notifications to these selected rules:</p>
          {selectedRules.map((rule: SigmaRuleListAPIType) => (
            <p key={rule.id}>{rule.title}</p>
          ))}
          <StyledInput>
            <ControlLabel>Notifications</ControlLabel>
            <MultiSelect
              id="filter-notifications"
              matchProp="label"
              onChange={(selected: string) => setSelectedNotifications(selected === '' ? [] : selected.split(','))}
              options={notificationOptions}
              value={defaultTo(selectedNotifications, []).join(',')}
            />
          </StyledInput>
        </>
      </ConfirmDialog>
    </>
  );
}

export default BulkActions;
