import type { ReportLayout } from 'report/types';
import type { Orientation } from 'search/ExportWidgetAction/types';
import { DEFAULT_ORIENTATION, PAGE_SIZES_MAP, DEFAULT_SIZE } from 'report/Constants';

const usePaperDimensions = (pageSize: ReportLayout['pageSize'], orientation: Orientation = DEFAULT_ORIENTATION) => {
  const {
    portraitDimensions: { height, width },
    previewLayoutMultiplier,
  } = PAGE_SIZES_MAP[pageSize ?? DEFAULT_SIZE];
  const layoutMultiplier = previewLayoutMultiplier[orientation];

  return orientation === 'landscape'
    ? { width: height * layoutMultiplier, height: width * layoutMultiplier }
    : { height: height * layoutMultiplier, width: width * layoutMultiplier };
};

export default usePaperDimensions;
