import * as React from 'react';

import { NoEntitiesExist } from 'components/common';
import { NoEntitiesExistWrapper } from 'security-app/components/Assets/AssetModals/AssetDetailsDrawer/shared';
import type { Pack } from 'illuminate/types';
import { Col } from 'security-app/components/common/FormBaseStyles';

import PackItem from './PackItem';

type Props = {
  packs: Pack[];
};

function PackList({ packs }: Props) {
  if (packs.length < 1) {
    return (
      <NoEntitiesExistWrapper>
        <NoEntitiesExist>There are no updated packs to list</NoEntitiesExist>
      </NoEntitiesExistWrapper>
    );
  }

  return (
    <Col $width="100%" $gap="0.3rem">
      {packs.map((pack: Pack) => (
        <PackItem key={pack.pack_id} pack={pack} />
      ))}
    </Col>
  );
}

export default PackList;
