import * as React from 'react';
import { useCallback, useState } from 'react';
import { styled, css } from 'styled-components';

import { MenuItem } from 'components/bootstrap';
import BulkActionsDropdown from 'components/common/EntityDataTable/BulkActionsDropdown';
import BulkRetrievalModal from 'data-lake/preview/BulkRetrievalModal';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';

const Container = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacings.sm};
    padding-top: ${theme.spacings.sm};
  `,
);

type Props = { streamId: string };

const BulkActions = ({ streamId }: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const sendTelemetry = useSendTelemetry();
  const { selectedEntities } = useSelectedEntities();

  const toggleShowModal = useCallback(() => {
    if (!showRetrievalModal) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.RETRIEVE_LOGS_BULK_ACTION_CLICK, {
        app_pathname: '/data-storage/preview',
        app_section: 'log-view-widget',
        event_details: {
          messages_amount: selectedEntities.length,
        },
      });
    }
    setShowRetrievalModal((cur) => !cur);
  }, [selectedEntities.length, sendTelemetry, showRetrievalModal]);

  return (
    <Container>
      <BulkActionsDropdown bsSize="xs">
        <MenuItem onSelect={toggleShowModal}>Retrieve Logs</MenuItem>
      </BulkActionsDropdown>
      {showRetrievalModal && <BulkRetrievalModal onCancel={toggleShowModal} streamId={streamId} />}
    </Container>
  );
};

export default BulkActions;
