// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lKv5E5vyMJJ2JM7EPNkY {
  margin-top: 35px;
}

.mMpT3qEuFu4PrLV5um5H {
  margin-left: 15px;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/event-definitions/event-definition-form/EventConditionForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[":local(.conditionTypesInfo) {\n  margin-top: 35px;\n}\n\n:local(.hr) {\n  margin-left: 15px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conditionTypesInfo": `lKv5E5vyMJJ2JM7EPNkY`,
	"hr": `mMpT3qEuFu4PrLV5um5H`
};
export default ___CSS_LOADER_EXPORT___;
