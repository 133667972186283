import * as React from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { HoverForHelp, Select, Spinner, IconButton } from 'components/common';
import type OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import useValuesOf from 'enterprise/parameters/components/option/useValuesOf';

import type { ParameterInputComponentProps } from '../ParameterTypes';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InputAddition = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;
`;

const SelectContainerDiv = styled.div`
  min-width: 200px;
`;

const ErrorInfo = styled((props: any) => <HoverForHelp {...props} />)`
  margin-left: 5px;
`;

// TODO: Properly type this after introducing @types/react-select
type ContainerProps = {
  children: React.ReactNode;
  innerRef: any;
  innerProps: any;
};
const SelectContainer = ({ children, innerRef, innerProps }: ContainerProps) => (
  <SelectContainerDiv ref={innerRef} {...innerProps}>
    {children}
  </SelectContainerDiv>
);
const LoadingIndicator = () => (
  <InputAddition>
    <Spinner />
  </InputAddition>
);

const OptionParameterInput = ({
  parameter,
  onChange,
  value,
  searchId,
  inputSize,
}: ParameterInputComponentProps<OptionParameter>) => {
  const { data: fieldValues, isFetching, isLoading, isError, error, refetch } = useValuesOf(parameter, searchId);

  const options = useMemo(
    () => (fieldValues ? fieldValues.map((v) => ({ key: v, value: v, label: String(v) })) : []),
    [fieldValues],
  );

  const _onChange = useCallback(
    (selected: string) => onChange(parameter.name, selected, true),
    [onChange, parameter.name],
  );

  if (isLoading || isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <Select
        placeholder="Select or enter value"
        components={{ SelectContainer }}
        displayKey="key"
        valueKey="value"
        size={inputSize === 'small' ? 'small' : 'normal'}
        inputId="parameter-value-input"
        allowCreate
        onChange={_onChange}
        options={options}
        value={value}
      />
      <InputAddition>
        <IconButton title="Refresh values" name="refresh" onClick={refetch} />
      </InputAddition>
      {isError && (
        <ErrorInfo title="Error" type="error" iconSize="lg">
          {'responseMessage' in error && error.responseMessage ? error.responseMessage : String(error)}
        </ErrorInfo>
      )}
    </Container>
  );
};

export default OptionParameterInput;
