import * as React from 'react';
import styled from 'styled-components';

import { adjustFormat } from 'util/DateTime';
import type { DateTime } from 'util/DateTime';
import { Col, Row } from 'components/bootstrap';
import { Spinner } from 'components/common';
import useActiveLicenseInformation from 'license/hooks/useActiveLicenseInformation';
import { LICENSE_TYPE, LICENSE_TYPES } from 'license/constants';
import LicenseState from 'license/LicenseState';
import { useGraphWidth } from 'components/common/Graph';
import NumberUtils from 'util/NumberUtils';
import ActiveDrawDownLicenseMetrics from 'license/ActiveDrawDownLicenseMetrics';
import ActiveLicenseMetrics from 'license/ActiveLicenseMetrics';
import DrawDownLicenseVolumeGraph from 'license/DrawDownLicenseVolumeGraph';
import EnterpriseTrafficGraph from 'license/EnterpriseTrafficGraph';
import GraphDaysContextProvider from 'components/common/Graph/contexts/GraphDaysContextProvider';

const StyledDl = styled.dl`
  margin: 0;

  dt {
    float: left;
    clear: left;
  }

  dd {
    margin-left: 260px;
  }
`;

const ActiveLicenseInformation = () => {
  const { graphContainerRef } = useGraphWidth();
  const {
    data: activeLicenseData,
    isLoading: isLoadingActiveLicense,
    isError: isErrorActiveLicense,
  } = useActiveLicenseInformation();

  const formatDateTime = (dateTimeToFormat: DateTime): string => adjustFormat(dateTimeToFormat, 'date');

  if (isLoadingActiveLicense) return <Spinner />;
  if (isErrorActiveLicense || !activeLicenseData) return <p>No active license found.</p>;

  return (
    <>
      <Row>
        <GraphDaysContextProvider>
          <Col xs={6} ref={graphContainerRef}>
            {activeLicenseData.license_type === LICENSE_TYPE.drawDown && <DrawDownLicenseVolumeGraph />}
            {activeLicenseData.license_type === LICENSE_TYPE.dailyUsage && <EnterpriseTrafficGraph />}
          </Col>
        </GraphDaysContextProvider>
        <Col xs={6}>
          <StyledDl>
            <dt>Active License Type:</dt>
            <dd>{activeLicenseData.license_type}</dd>
            <dt>Product:</dt>
            <dd>
              {activeLicenseData.license_type === LICENSE_TYPE.drawDown
                ? activeLicenseData.products.split(',').join(', ')
                : LICENSE_TYPES[activeLicenseData.products]}
            </dd>
            {!typeof activeLicenseData.details?.require_remote_check === null && (
              <>
                <dt>Requires Remote Checks:</dt>
                <dd>{String(activeLicenseData.details?.require_remote_check)}</dd>
              </>
            )}
            <dt>State:</dt>
            <dd>
              <LicenseState violations={activeLicenseData.violations} version={activeLicenseData.details.version} />
            </dd>
            {activeLicenseData.license_type === LICENSE_TYPE.drawDown ? (
              <>
                <dt>Provision:</dt>
                <dd>{NumberUtils.formatBytes(activeLicenseData.details.traffic_provisioned)}</dd>
                <dt>Remaining:</dt>
                <dd>{NumberUtils.formatBytes(activeLicenseData.details.traffic_limit)}</dd>
                <dt>Expires:</dt>
                <dd>{formatDateTime(activeLicenseData.end_date)}</dd>
              </>
            ) : (
              <ActiveLicenseMetrics />
            )}
          </StyledDl>
        </Col>
      </Row>
      {activeLicenseData.license_type === LICENSE_TYPE.drawDown && <ActiveDrawDownLicenseMetrics />}
    </>
  );
};

export default ActiveLicenseInformation;
