import { qualifyUrls } from 'routing/Routes';

const SymantecEDRRoutes = {
  INTEGRATIONS: {
    SymantecEDR: {
      ACTIVITYAPI: {
        index: '/integrations/symantecedr',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    SymantecEDR: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/symantecedr/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/symantecedr/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    SymantecEDR: {
      GRAYLOG_SymantecEDR_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_symantecEDR_ActivityLog_Input.html#graylog-symantecEDR-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(SymantecEDRRoutes),
  unqualified: SymantecEDRRoutes,
};

export { DocsRoutes, ApiRoutes };
