import { qualifyUrls } from 'routing/Routes';

const CrowdStrikeRoutes = {
  INTEGRATIONS: {
    CrowdStrike: {
      ACTIVITYAPI: {
        index: '/integrations/crowdstrike',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    CrowdStrike: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/crowdstrike/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/crowdstrike/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    CrowdStrike: {
      GRAYLOG_CrowdStrike_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_crowdstrike_ActivityLog_Input.html#graylog-crowdstrike-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(CrowdStrikeRoutes),
  unqualified: CrowdStrikeRoutes,
};

export { DocsRoutes, ApiRoutes };
