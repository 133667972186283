import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { Input, Button, Modal } from 'components/bootstrap';
import BootstrapModalWrapper from 'components/bootstrap/BootstrapModalWrapper';
import ModalSubmit from 'components/common/ModalSubmit';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import type { StyleProps } from 'components/bootstrap/Button';
import type { BsSize } from 'components/bootstrap/types';

import type { Forwarder } from '../Types';
import { ForwardersActions } from '../stores/ForwardersStore';

type Props = {
  forwarder: Forwarder;
  buttonBsStyle?: StyleProps;
  buttonBsSize?: BsSize;
  onSave?: () => void;
};

const StyledButton = styled(Button)`
  vertical-align: top;
`;

type ForwarderFormState = {
  title: string;
  description: string;
  hostname: string;
};

const ForwarderForm = ({ forwarder, buttonBsStyle = 'info', buttonBsSize = null, onSave = () => {} }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const sendTelemetry = useSendTelemetry();

  const _openModal = () => setShowModal(true);
  const _closeModal = () => setShowModal(false);

  const _updateForwarder = (
    title = forwarder.title,
    description = forwarder.description,
    inputProfileId = forwarder.input_profile_id,
  ) => {
    const updatedForwarder = {
      input_profile_id: inputProfileId,
      title,
      description,
      hostname: forwarder.hostname,
    };

    ForwardersActions.update(forwarder.id, updatedForwarder).then(() => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_UPDATED, {
        app_pathname: 'forwarder',
        app_section: 'input-profile',
      });
    });
  };

  const handleSubmit = (values: ForwarderFormState) => {
    _updateForwarder(values.title, values.description, forwarder.input_profile_id);
    onSave();
    _closeModal();
  };

  const modalTitle = 'Update forwarder';

  return (
    <>
      <StyledButton bsStyle={buttonBsStyle} bsSize={buttonBsSize} onClick={_openModal}>
        Edit
      </StyledButton>
      <BootstrapModalWrapper showModal={showModal} onHide={_closeModal} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Formik<ForwarderFormState>
          initialValues={{ title: forwarder.title, description: forwarder.description, hostname: forwarder.hostname }}
          onSubmit={handleSubmit}>
          {({ values, handleChange, isSubmitting }) => (
            <Form>
              <Modal.Body>
                <Input
                  id="title"
                  name="title"
                  label="Title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                  help="Meaningful name used to identify this forwarder."
                  required
                />
                <Input
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  help="Description for this forwarder "
                />
                <Input
                  id="hostname"
                  name="hostname"
                  label="Hostname"
                  type="text"
                  disabled
                  value={values.hostname}
                  onChange={handleChange}
                  help="The hostname of this forwarder"
                />
              </Modal.Body>
              <Modal.Footer>
                <ModalSubmit
                  onCancel={_closeModal}
                  isSubmitting={isSubmitting}
                  isAsyncSubmit
                  submitButtonText="Update forwarder"
                  submitLoadingText="Updating forwarder..."
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </BootstrapModalWrapper>
    </>
  );
};

export default ForwarderForm;
