import React, { useState, useCallback } from 'react';
import { styled, css } from 'styled-components';

import { Row, Alert } from 'components/bootstrap';
import Store from 'logic/local-storage/Store';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';
import { DocumentationLink } from 'components/support';
import DocsHelper from 'util/DocsHelper';

const HIDE_DW_SEARCH_EXPLANATION_KEY = 'hide-data-lake-search-explanation';

const Container = styled(Alert)(
  ({ theme }) => css`
    margin-top: 0;
    margin-bottom: ${theme.spacings.xs};
  `,
);

const SearchFeatureExplanation = () => {
  const [hideExplanation, setHideExplanation] = useState(Store.get(HIDE_DW_SEARCH_EXPLANATION_KEY) === 'true');
  const currentUser = useCurrentUser();

  const hideFeatureExplanation = useCallback(() => {
    Store.set(HIDE_DW_SEARCH_EXPLANATION_KEY, 'true');
    setHideExplanation(true);
  }, []);

  if (hideExplanation) {
    return null;
  }

  return (
    <Row>
      <Container onDismiss={hideFeatureExplanation}>
        This section provides a basic view of logs stored in your Data Lake. The functionality here is limited. For a
        more detailed view of this data, you can use the data lake retrieval function.{' '}
        {!isPermitted(currentUser.permissions, 'data_lake_archive:restore') &&
          'Please contact your administrator to request the necessary permissions. '}
        Find more about Data Lake Preview capabilities in the{' '}
        <DocumentationLink page={DocsHelper.PAGES.DATA_LAKE_PREVIEW} text="documentation" />.
      </Container>
    </Row>
  );
};

export default SearchFeatureExplanation;
