import * as Immutable from 'immutable';

import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import StringUtils from 'util/StringUtils';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import type Parameter from 'views/logic/parameters/Parameter';

const createParametersFromNames = (parameterNames: Immutable.Set<string> | Array<string>): ParameterMap =>
  Immutable.Map<string, Parameter>(
    parameterNames.map((name: string) => [
      name,
      ValueParameter.create(
        name,
        StringUtils.capitalizeFirstLetter(name),
        '',
        'string',
        undefined,
        false,
        ParameterBinding.empty(),
      ),
    ]),
  );

export default createParametersFromNames;
