import * as Immutable from 'immutable';
import type { $PropertyType } from 'utility-types';

import type { WizardFormValues } from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';

import type { LoadGroupsPayload } from '../../actions/directoryServices/GroupSyncActions';

const prepareGroupSyncConfig = (
  formValues: WizardFormValues,
  backendId: string,
  serviceType: string,
): $PropertyType<LoadGroupsPayload, 'team_sync_backend_configuration'> => {
  const {
    teamSelectionType,
    teamSelection = Immutable.Set(),
    teamDefaultRoles,
    groupSearchBase,
    groupSearchPattern,
    teamUniqueIdAttribute,
    teamNameAttribute,
  } = formValues;

  return {
    auth_service_backend_id: backendId,
    selection_type: teamSelectionType,
    selection: teamSelection.toJS(),
    default_roles: teamDefaultRoles ? teamDefaultRoles.split(',') : [],
    config: {
      type: serviceType,
      group_search_base: groupSearchBase,
      group_search_pattern: groupSearchPattern,
      team_unique_id_attribute: teamUniqueIdAttribute,
      team_name_attribute: teamNameAttribute,
    },
  };
};

export default prepareGroupSyncConfig;
