import * as React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import type * as Immutable from 'immutable';
import type { $PropertyType } from 'utility-types';

import TeamsQueryHelp from 'security/components/teams-overview/TeamsQueryHelper';
import type { PaginatedTeams } from 'security/actions/TeamsActions';
import TeamsDomain from 'security/domainActions/TeamsDomain';
import LicenseCheck from 'license/LicenseCheck';
import type Team from 'security/logic/Team';
import PaginatedTeamItem from 'security/components/teams-assignment/PaginatedTeamItem';
import useRoles from 'common/hooks/useRoles';
import type { Pagination } from 'stores/PaginationTypes';
import PaginatedItemOverview, { DEFAULT_PAGINATION } from 'components/common/PaginatedItemOverview';
import { Table } from 'components/bootstrap';
import type {
  OverrideItemComponentProps,
  ResultsWrapperComponentProps,
  DescriptiveItem,
} from 'components/common/PaginatedItemOverview';
import { Spinner } from 'components/common';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import TeamQuickCreate from './TeamQuickCreate';
import TeamsSelector from './TeamsSelector';

const TeamSelectionWrapper =
  (shouldShowActions) =>
  ({ isEmptyResult, children }: ResultsWrapperComponentProps) =>
    isEmptyResult ? (
      <div>{children}</div>
    ) : (
      <Table condensed hover striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Roles</th>
            {shouldShowActions && <th>Actions</th>}
          </tr>
        </thead>
        {children}
      </Table>
    );

const TeamRenderer =
  (roles) =>
  ({ item, onDeleteItem }: OverrideItemComponentProps) => {
    if (roles.isLoading) {
      return <Spinner />;
    }

    const team = item as Team;
    const assignedTeamRoles = roles.roles.filter((r) => team.roles.includes(r.id));
    const teamCanBeDeleted = !team.isSynced;

    return (
      <PaginatedTeamItem
        roles={assignedTeamRoles}
        team={team}
        onDeleteItem={teamCanBeDeleted ? onDeleteItem : undefined}
      />
    );
  };

type Props = {
  readOnly?: boolean;
  loadAssignedTeams: (pagination: Pagination) => Promise<PaginatedTeams>;
  unassignTeam: (teamId: $PropertyType<Team, 'id'>) => Promise<Team>;
  assignTeams: (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) => Promise<void>;
  buildTeamWithEntity: (team: Team) => Team;
  getUnassignedTeams: (teams: Immutable.List<Team>) => Immutable.List<Team>;
};

const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const TeamsAssignment = ({
  readOnly = false,
  loadAssignedTeams,
  buildTeamWithEntity,
  getUnassignedTeams,
  unassignTeam,
  assignTeams,
}: Props) => {
  const [paginatedTeams, setPaginatedTeams] = useState<PaginatedTeams | undefined>();
  const [showCreationForm, setShowCreationForm] = useState(false);
  const roles = useRoles();
  const sendTelemetry = useSendTelemetry();
  const { pathname } = useLocation();

  const _onLoad = useCallback((pagination = DEFAULT_PAGINATION) => loadAssignedTeams(pagination), [loadAssignedTeams]);

  const _unassignTeam = (team: DescriptiveItem) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.TEAM.UNASSIGNED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'teams-role',
      app_action_value: 'unassign',
    });

    unassignTeam(team.id).then(() => {
      _onLoad().then(setPaginatedTeams);
    });
  };

  const _onAssignTeams = async (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.TEAM.ASSIGNED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'teams-role',
      app_action_value: 'assign',
    });

    assignTeams(teamIds).then(() => {
      _onLoad().then(setPaginatedTeams);
    });
  };

  const _onCreateTeam = (newTeam: Team) =>
    TeamsDomain.create(buildTeamWithEntity(newTeam)).then((createdTeam) => {
      _onLoad().then(setPaginatedTeams);

      return createdTeam;
    });

  const TeamRendererWithRoles = TeamRenderer(roles);

  return (
    <LicenseCheck featureName="teams">
      {({ licenseIsValid }) => {
        const isReadOnly = readOnly || !licenseIsValid;
        const TeamSelectionWrapperWithReadOnly = TeamSelectionWrapper(!isReadOnly);

        return (
          <>
            {!isReadOnly && (
              <>
                <h3>Assign Teams</h3>
                <Container>
                  <TeamsSelector
                    onSubmit={_onAssignTeams}
                    getUnassignedTeams={getUnassignedTeams}
                    showCreationForm={() => setShowCreationForm(true)}
                  />
                </Container>
              </>
            )}
            {!isReadOnly && showCreationForm ? (
              <>
                <h3>Quick Create Team</h3>
                <Container>
                  <TeamQuickCreate onClose={() => setShowCreationForm(false)} onSubmit={_onCreateTeam} />
                </Container>
              </>
            ) : (
              <>
                <h3>Selected Teams</h3>
                <Container>
                  <PaginatedItemOverview
                    noDataText="No selected teams have been found"
                    overrideList={paginatedTeams}
                    onLoad={_onLoad}
                    queryHelper={<TeamsQueryHelp />}
                    resultsWrapperComponent={TeamSelectionWrapperWithReadOnly}
                    overrideItemComponent={TeamRendererWithRoles}
                    onDeleteItem={!isReadOnly ? _unassignTeam : undefined}
                  />
                </Container>
              </>
            )}
          </>
        );
      }}
    </LicenseCheck>
  );
};

export default TeamsAssignment;
