import * as React from 'react';

import { DropdownButton, MenuItem, Button } from 'components/bootstrap';
import useIsInvestigationDetailsOnSecurityUI from 'security-app/components/Investigations/plugin/shared/useIsInvestigationDetailsOnSecurityUI';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';

import AddEvidenceModal from './shared/AddEvidenceModal';

type Props = {
  id: string;
  index: string;
};

const CoreMessageActions = ({ id, index }: Props) => {
  const addEvidenceModalRef = React.useRef(null);

  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const isInvestigationDetailsOnSecurityUI = useIsInvestigationDetailsOnSecurityUI();

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddLog = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { logs: [{ id, index }] },
    });

    setCollapsed(false);
  };

  return isInvestigationDetailsOnSecurityUI ? (
    <Button bsSize="sm" onClick={onAddLog} disabled={disabledAdd}>
      Add to Investigation
    </Button>
  ) : (
    <>
      <DropdownButton id="investigations-dropdown" bsSize="small" title="Investigations">
        {disabledAdd || (
          <MenuItem onClick={onAddLog} icon="extension">
            Add to investigation
          </MenuItem>
        )}
        <MenuItem onClick={() => addEvidenceModalRef.current.toggle()} icon="extension">
          Select an investigation
        </MenuItem>
      </DropdownButton>
      <AddEvidenceModal index={index} ids={[id]} type="logs" ref={addEvidenceModalRef} />
    </>
  );
};

export default CoreMessageActions;
