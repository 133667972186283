import * as React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import numeral from 'numeral';

import useUserDateTime from 'hooks/useUserDateTime';
import { getValueFromInput } from 'util/FormsUtils';
import { Button, ControlLabel, FormControl, FormGroup, HelpBlock, Input, Radio, Checkbox } from 'components/bootstrap';
import { ExpandableList, Icon, NoEntitiesExist, Select, TimezoneSelect } from 'components/common';
import type { ReportCreate, ReportFormat, Report } from 'report/types';
import type { AvailableDashboard } from 'report/report-contents-page/useAvailableWidgets';
import Logo from 'report/common/ReportLogo';
import AvailableDashboardsList from 'report/report-contents-page/AvailableDashboardsList';
import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import { DEFAULT_FORMAT, REPORT_FORMATS } from 'report/types';
import { PAGE_SIZES, MAX_LOGO_SIZE } from 'report/Constants';

import ReportContentsToolbar from './ReportContentsToolbar';

const StyledExpandableList = styled(ExpandableList)`
  .header {
    display: inline-flex;
    align-items: center;
  }
`;

const RemoveLogo = styled.div`
  margin-bottom: 10px;
`;

type Props = {
  disabled?: boolean;
  report: ReportCreate | Report;
  reportLogo?: string;
  formElementId: string;
  dashboards: Array<AvailableDashboard>;
  onReportChange: (updatedReport: ReportCreate) => void;
  onReportLogoChange: (newLogo: string | ArrayBuffer | null) => void;
  onCancel: () => void;
  action?: 'create' | 'edit';
  isLoading?: boolean;
};

const updateProp = <R extends keyof ReportCreate>(
  report: ReportCreate | Report,
  onReportChange: (updatedReport: ReportCreate | Report) => void,
  key: R,
  value: ReportCreate[R],
) => {
  const updatedReport = cloneDeep(report) as ReportCreate;
  set(updatedReport, key, value);
  onReportChange(updatedReport);
};

const _updateReportLogo = (
  { target: { files } }: React.ChangeEvent<HTMLInputElement>,
  onReportLogoChange: (newLogo: string | ArrayBuffer | null) => void,
  setLogoError: (error: { message: string }) => void,
) => {
  if (files.length === 0) {
    return;
  }

  const file = files[0];

  if (file.size > MAX_LOGO_SIZE) {
    setLogoError({
      message: `Image size is larger than ${numeral(MAX_LOGO_SIZE).format('0 b')}, please resize the image or pick a smaller one.`,
    });

    return;
  }

  const reader = new FileReader();

  reader.onload = () => {
    setLogoError(undefined);
    onReportLogoChange(reader.result);
  };

  reader.readAsDataURL(file);
};

const OrientationSelection = styled.span`
  display: block;
`;

const makeEvent = <T,>(name: string, value: T) => ({ target: { name, value } });

const ReportContentsSelection = ({
  action = 'create',
  disabled = false,
  formElementId,
  dashboards,
  report,
  reportLogo = null,
  onCancel,
  isLoading = false,
  onReportLogoChange,
  onReportChange,
}: Props) => {
  const [logoError, setLogoError] = useState<{ message: string }>();
  const { userTimezone } = useUserDateTime();

  const handleInputChange = useCallback(
    (event) => {
      updateProp(report, onReportChange, event.target.name, getValueFromInput(event.target));
    },
    [onReportChange, report],
  );

  const updateReportLogo = useCallback(
    (event: any) => _updateReportLogo(event, onReportLogoChange, setLogoError),
    [onReportLogoChange],
  );
  const updateTimezone = useCallback(
    (newValue: string) => handleInputChange(makeEvent('timezone', newValue)),
    [handleInputChange],
  );
  const updatePageSize = useCallback(
    (newPageSize: string) =>
      handleInputChange(makeEvent('layout.pageSize', newPageSize === '' ? undefined : newPageSize)),
    [handleInputChange],
  );
  const updateFormat = useCallback(
    (newFormat: ReportFormat) => handleInputChange(makeEvent('layout.format', newFormat)),
    [handleInputChange],
  );
  const resetOrientation = useCallback(
    () =>
      updateProp(report, onReportChange, 'layout', {
        ...report?.layout,
        orientation: undefined,
      }),
    [onReportChange, report],
  );

  const deleteReportLogo = useCallback(() => onReportLogoChange(null), [onReportLogoChange]);

  const updateReport = useCallback(
    (reportChanges: Partial<ReportCreate>) => {
      onReportChange({ ...report, ...reportChanges });
    },
    [onReportChange, report],
  );

  if (dashboards.length === 0) {
    return (
      <div>
        <NoEntitiesExist>
          Reporting is based on <Link to={Routes.DASHBOARDS}>dashboards</Link>, create the widgets you want to include
          in the report to get started.
        </NoEntitiesExist>
      </div>
    );
  }

  const reportFormat = report?.layout?.format ?? DEFAULT_FORMAT;

  return (
    <div>
      <h3>Contents</h3>
      <p>Write a title and description for the report and select the widgets that will be include in it.</p>

      <Input
        id="title"
        name="title"
        type="text"
        label="Title"
        help="Set a title to use in the report's cover page."
        value={report.title}
        onChange={handleInputChange}
        required
      />
      <Input
        id="subtitle"
        name="subtitle"
        type="text"
        label={
          <span>
            Subtitle <small className="text-muted">(Optional)</small>
          </span>
        }
        help="Set a subtitle to use in the report's cover page."
        value={report.subtitle || ''}
        onChange={handleInputChange}
      />

      <FormGroup>
        <ControlLabel htmlFor="format-select">Report Format</ControlLabel>
        <Select
          inputId="report-format-select"
          className="report-format-select"
          onChange={updateFormat}
          value={report.layout?.format ?? DEFAULT_FORMAT}
          placeholder="Pick a format"
          clearable={false}
          options={REPORT_FORMATS}
        />
        <HelpBlock>
          <span>
            Choose a format for the report. Selecting PDF will produce a graphical report, while other formats generate
            tabular data.
          </span>
        </HelpBlock>
      </FormGroup>

      <FormGroup controlId="logo" validationState={logoError ? 'error' : null}>
        <ControlLabel>
          Logo <small className="text-muted">(Optional)</small>
        </ControlLabel>
        <div className="clearfix" />
        {reportLogo && (
          <>
            <Logo imageSrc={reportLogo} />
            <RemoveLogo>
              <Button bsSize="xsmall" bsStyle="primary" onClick={deleteReportLogo}>
                Remove logo
              </Button>
            </RemoveLogo>
          </>
        )}
        <FormControl id="logo" type="file" accept="image/png,image/jpeg" onChange={updateReportLogo} />
        <HelpBlock>
          {logoError ? (
            logoError.message
          ) : (
            <span>
              Choose an image to use as a logo in the report&#39;s cover page. The image must be in JPEG or PNG formats
              and cannot exceed {numeral(MAX_LOGO_SIZE).format('0 b')}.
            </span>
          )}
        </HelpBlock>
      </FormGroup>
      <Input
        id="description"
        name="description"
        type="textarea"
        label={
          <span>
            Description <small className="text-muted">(Optional)</small>
          </span>
        }
        help="Add a description to include at the beginning of the report."
        value={report.description || ''}
        onChange={handleInputChange}
        rows={4}
      />

      <FormGroup>
        <ControlLabel htmlFor="timezone-select">Time Zone</ControlLabel>
        <TimezoneSelect value={report.timezone} name="timezone" clearable={false} onChange={updateTimezone} />
        <HelpBlock>
          <span>The timezone setting will affect all displayed times in the report.</span>
        </HelpBlock>
      </FormGroup>

      {reportFormat === 'PDF' ? (
        <>
          <FormGroup>
            <ControlLabel htmlFor="page-size-select">Page Size</ControlLabel>
            <Select
              inputId="page-size-select"
              className="page-size-select"
              onChange={updatePageSize}
              value={report.layout?.pageSize}
              placeholder="Pick a page size"
              options={PAGE_SIZES}
            />
            <HelpBlock>
              <span>
                Choose a page size for this report. It determines width and height of the generated report PDF. If no
                option is selected, the system-wide default will be used (currently US Letter).
              </span>
            </HelpBlock>
          </FormGroup>

          <FormGroup>
            <ControlLabel htmlFor="orientation">Page Orientation</ControlLabel>
            <OrientationSelection>
              <Radio
                inline
                type="radio"
                id="orientation-portrait"
                name="layout.orientation"
                value="portrait"
                onChange={handleInputChange}
                checked={report.layout?.orientation === 'portrait'}>
                <Icon name="crop_portrait" /> Portrait
              </Radio>
              <Radio
                inline
                type="radio"
                id="orientation-landscape"
                name="layout.orientation"
                value="landscape"
                onChange={handleInputChange}
                checked={report.layout?.orientation === 'landscape'}>
                <Icon name="crop_landscape" /> Landscape
              </Radio>
              <Radio
                inline
                type="radio"
                id="orientation-default"
                name="layout.orientation"
                onChange={resetOrientation}
                checked={report.layout?.orientation === undefined || report.layout?.orientation === null}>
                Default
              </Radio>
            </OrientationSelection>
            <HelpBlock>
              <span>
                This setting specifies how the generated PDF should be laid out. For portrait mode, the longer side is
                aligned vertically, for landscape mode the longer side is the horizontal one. If you select
                &quot;Default&quot;, no explicit orientation is used and the system-wide default is used (currently
                portrait).
              </span>
            </HelpBlock>
          </FormGroup>
        </>
      ) : null}

      <FormGroup>
        <ControlLabel>Widget Description</ControlLabel>
        <Checkbox onChange={handleInputChange} checked={report.hideWidgetDescription} name="hideWidgetDescription">
          Hide complete widget description
        </Checkbox>
        <Checkbox
          onChange={handleInputChange}
          name="hideWidgetQuery"
          checked={report.hideWidgetQuery || report.hideWidgetDescription}
          disabled={report.hideWidgetDescription}>
          Hide widget query
        </Checkbox>
        <HelpBlock>Configure how to display the description of a widget.</HelpBlock>
      </FormGroup>

      <FormGroup>
        <ControlLabel>Widgets</ControlLabel>
        <HelpBlock>
          Select the widgets to include in the report. You can create new widgets and add them to the report later on.
          Times in the widget selection are displayed in the timezone of the current user ({userTimezone}). The actual
          report will display times in the timezone configured for this report.
        </HelpBlock>
      </FormGroup>

      <StyledExpandableList>
        <AvailableDashboardsList
          reportWidgets={report.widgets}
          dashboards={dashboards}
          onReportUpdate={updateReport}
          hideWidgetQuery={report.hideWidgetQuery}
          hideWidgetDescription={report.hideWidgetDescription}
        />
      </StyledExpandableList>

      <ReportContentsToolbar
        action={action}
        disabled={disabled}
        onCancel={onCancel}
        formElementId={formElementId}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ReportContentsSelection;
