import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';

import ReportsList from './ReportsList';

import ReportsSubNav from '../common/ReportsSubNav';

const ReportsPage = () => (
  <DocumentTitle title="Reports">
    <PageHeader
      title="Reports"
      actions={<ReportsSubNav />}
      documentationLink={{
        title: 'Reporting documentation',
        path: DocsHelper.PAGES.REPORTING,
      }}>
      <span>
        Reports let you relay information from Graylog in a presentable form. They are based on dashboard widgets,
        highly customizable and can be scheduled for automatic delivery.
      </span>
    </PageHeader>

    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report} bsStyle="danger" displayWarningContainer hideChildren>
      <Row className="content">
        <Col md={12}>
          <ReportsList />
        </Col>
      </Row>
    </LicenseCheck>
  </DocumentTitle>
);

export default ReportsPage;
