import * as React from 'react';

import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import { Button, MenuItem } from 'components/bootstrap';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';

type Props = {
  onClick: () => void;
  buttonType?: 'menu' | 'button';
};

const RetrievalArchiveAction = ({ onClick, buttonType = 'menu' }: Props) => {
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const onRetrieval = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.DATA_RETRIEVAL_OPENED, {
      app_pathname: getPathnameWithoutId(pathname),
    });

    onClick();
  };

  const isMenuButton = buttonType === 'menu';
  const ItemComponent = isMenuButton ? MenuItem : Button;

  return (
    <ItemComponent onClick={onRetrieval} bsSize="xsmall">
      Retrieve logs
    </ItemComponent>
  );
};

export default RetrievalArchiveAction;
