import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { Icon, Spinner } from 'components/common';
import { useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import { Collapsible } from 'common/components';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import { DataWell } from 'security-app/components/common';
import { useImportRulesFromRepo } from 'security-app/hooks/useSigmaAPI';
import type {
  GitRepoContent,
  ImportAPIResponse,
  ImportGitRuleType,
  RuleOptionsType,
} from 'security-app/hooks/api/sigmaAPI.types';
import RuleOptionsForm from 'security-app/components/SigmaRules/RuleOptionsForm';
import { formOptionsToPayload } from 'security-app/components/SigmaRules/RuleOptionsForm/utils';

import { WindowHeader, WindowBody, IconButton, FullSpinner } from './styledComponents';
import RuleInfoTooltip from './RuleTooltip';

const RemoveButton = styled(IconButton)`
  opacity: 0.3;

  &:hover {
    opacity: 0.9;
  }
`;

type RuleOptionsFields = RuleOptionsType & {
  id?: string;
  rule?: GitRepoContent;
};

const getRuleOptionInitialValues = (rule: GitRepoContent): RuleOptionsFields => ({
  id: rule.id,
  rule: rule,
  search_within: 5,
  search_within_unit: 'MINUTES',
  execute_every: 5,
  execute_every_unit: 'MINUTES',
  use_cron_scheduling: false,
  cron_expression: '',
  cron_timezone: '',
  streams: [],
  stream_categories: [],
  notifications: [],
  filters: [],
  remediation_steps: '',
});

const formRuleToPayload = (formRule: RuleOptionsFields): ImportGitRuleType => {
  const optionsPayload = formOptionsToPayload(formRule);

  return {
    ...optionsPayload,
    id: formRule.id,
  };
};

const RuleTitle = ({ rule }: { rule: GitRepoContent }) => {
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const onRuleRemove = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    selectedRulesDispatch({ type: 'remove', payload: [rule] });
  };

  return (
    <Row $gap="0.4rem" $align="center">
      <RemoveButton $delete data-testid="unselect-rule" onClick={onRuleRemove}>
        <Icon type="solid" style={{ cursor: 'pointer' }} name="close" title="Remove rule" />
      </RemoveButton>
      <RuleInfoTooltip rule={rule} />
      {rule.title}
    </Row>
  );
};

type Props = {
  importRules: boolean;
  setImportRules: (arg: boolean) => void;
  setImportErrors: (errors: string[]) => void;
  closeModal: () => void;
};

function RightPanel({ importRules, setImportRules, setImportErrors, closeModal }: Props) {
  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();
  const [ruleOptionsList, setRuleOptionsList] = React.useState<
    RuleOptionsFields[] | ((pevRules: RuleOptionsFields) => void)
  >([]);
  const [editOptionsMap, setEditOptionsMap] = React.useState<{ [key: string]: boolean }>({});

  const { importRulesFromRepo } = useImportRulesFromRepo();

  React.useEffect(() => {
    setEditOptionsMap(Object.fromEntries(selectedRules.map((sRule: GitRepoContent) => [sRule.id, false])));
  }, [selectedRules]);

  React.useEffect(() => {
    setRuleOptionsList((prevRules: RuleOptionsFields[]) => {
      if (prevRules.length > selectedRules.length) {
        return prevRules.filter((prevRule: RuleOptionsFields) =>
          selectedRules.find((sRule: GitRepoContent) => sRule.id === prevRule.id),
        );
      }

      if (prevRules.length < selectedRules.length) {
        const newRules = selectedRules.filter(
          (sRule: GitRepoContent) => !prevRules.find((prevRule: RuleOptionsFields) => prevRule.id === sRule.id),
        );

        return [...prevRules, ...newRules.map(getRuleOptionInitialValues)];
      }

      return prevRules;
    });
  }, [selectedRules, setRuleOptionsList]);

  React.useEffect(() => {
    if (importRules) {
      const payload = (ruleOptionsList as RuleOptionsFields[]).map(formRuleToPayload);

      importRulesFromRepo({ payload }).then((data: ImportAPIResponse) => {
        setImportRules(false);
        if (data.failure_count > 0) setImportErrors(data.errors);
        else closeModal();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importRules]);

  const onSubmit = React.useCallback((values: RuleOptionsFields) => {
    setRuleOptionsList((prevRules: RuleOptionsFields[]) =>
      prevRules.map((pRule: RuleOptionsFields) => {
        if (pRule.id === values.id) return values;

        return pRule;
      }),
    );

    setEditOptionsMap((prevMap) => ({ ...prevMap, [values.id]: false }));
  }, []);

  const onFormCancel = (inRuleOptions: RuleOptionsFields) => () => {
    setEditOptionsMap((prevMap) => ({ ...prevMap, [inRuleOptions.id]: false }));
  };

  const copyOptions = (inRuleOptions: RuleOptionsFields) => () => {
    setRuleOptionsList((prevRules: RuleOptionsFields[]) =>
      prevRules.map((ruleOptions: RuleOptionsFields) => ({
        ...inRuleOptions,
        id: ruleOptions.id,
        rule: ruleOptions.rule,
      })),
    );
  };

  return (
    <>
      <WindowHeader style={{ justifyContent: 'space-between' }}>
        Selected Rules: {selectedRules.length}
        {selectedRules.length > 0 && (
          <IconButton data-testid="clear-selection" onClick={() => selectedRulesDispatch({ type: 'clear' })}>
            <Icon style={{ cursor: 'pointer' }} size="xs" name="close" title="Clear selection" />
          </IconButton>
        )}
      </WindowHeader>
      <WindowBody>
        {(ruleOptionsList as RuleOptionsFields[]).map((ruleOptions: RuleOptionsFields) => (
          <Collapsible key={`collapsible-${ruleOptions.id}`} title={<RuleTitle rule={ruleOptions.rule} />}>
            <DataWell style={{ marginBottom: '0.5rem' }}>
              <Col $width="100%">
                <RuleOptionsForm
                  key={`option-fields-${ruleOptions.id}`}
                  onSubmit={onSubmit}
                  onCancel={onFormCancel(ruleOptions)}
                  initialValues={ruleOptions}
                  formId={ruleOptions.id}
                  readOnly={!editOptionsMap[ruleOptions.id]}
                />
                {!editOptionsMap[ruleOptions.id] && (
                  <Row $gap="0.5rem">
                    <Button
                      bsStyle="primary"
                      bsSize="xs"
                      type="button"
                      onClick={() => setEditOptionsMap((prevMap) => ({ ...prevMap, [ruleOptions.id]: true }))}>
                      Edit options
                    </Button>
                    {ruleOptionsList.length > 1 && (
                      <Button bsStyle="success" bsSize="xs" type="button" onClick={copyOptions(ruleOptions)}>
                        Copy these options to all
                      </Button>
                    )}
                  </Row>
                )}
              </Col>
            </DataWell>
          </Collapsible>
        ))}
        {importRules && (
          <FullSpinner>
            <Spinner text="Importing Sigma rules..." />
          </FullSpinner>
        )}
      </WindowBody>
    </>
  );
}

export default RightPanel;
