import React from 'react';

import { Col } from 'components/bootstrap';
import { IfPermitted } from 'components/common';

import ForwarderForm from './ForwarderForm';

import type { Forwarder } from '../Types';
import ForwarderStateBadge from '../states/ForwarderStateBadge';
import ForwarderThroughput from '../metrics/ForwarderThroughput';
import { StyledHorizontalDl } from '../CommonStyledComponents';

type Props = {
  forwarder: Forwarder;
  onUpdate: () => void;
};

const ForwarderDetailsComponent = ({ forwarder, onUpdate }: Props) => (
  <>
    <Col md={12}>
      <div className="pull-right">
        <IfPermitted permissions="forwarders:edit">
          <ForwarderForm forwarder={forwarder} onSave={onUpdate} buttonBsStyle="success" />
        </IfPermitted>
      </div>
      <h2>Details</h2>
    </Col>
    <Col md={5}>
      <StyledHorizontalDl>
        <dt>Description:</dt>
        <dd>{forwarder.description || 'No description given'}</dd>
        <dt>Hostname:</dt>
        <dd>{forwarder.hostname}</dd>
        <dt>Version:</dt>
        <dd>{forwarder.version || 'N/A'}</dd>
      </StyledHorizontalDl>
    </Col>
    <Col md={7}>
      <StyledHorizontalDl>
        <dt>Status:</dt>
        <dd>
          <ForwarderStateBadge forwarderId={forwarder.id} />
        </dd>
        <dt>Metrics:</dt>
        <ForwarderThroughput forwarderId={forwarder.id} />
      </StyledHorizontalDl>
    </Col>
  </>
);

export default ForwarderDetailsComponent;
