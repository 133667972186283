import * as React from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import Job from 'data-lake/components/Job';
import { Row, Col } from 'components/bootstrap';
import useDataLakeJobs from 'data-lake/hooks/useDataLakeJobs';

type Props = {
  streamId?: string;
};
const StyledH2 = styled.h2(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.xs};
  `,
);

const DataLakeJobs = ({ streamId = null }: Props) => {
  const {
    dataLakeData: { inProgressCount, jobs },
    isLoadingDataLake,
    isError,
    onCancelDataLakeJob,
    onAcknowledgeDataLakeJob,
  } = useDataLakeJobs(streamId);
  const params = useParams<{
    archiveId?: string;
  }>();
  const { archiveId } = params;

  if (isLoadingDataLake || isError || inProgressCount === 0 || !jobs) {
    return null;
  }

  const archivingJobs = jobs
    .filter((j) => {
      if (!archiveId) {
        return true;
      }

      return j.archive.join(',').includes(archiveId);
    })
    .map((job, index) => (
      <Job
        key={job.id}
        index={index}
        job={job}
        onCancel={onCancelDataLakeJob}
        onAcknowledge={onAcknowledgeDataLakeJob}
      />
    ));

  return (
    <Row className="content">
      <Col md={12}>
        <StyledH2>Data Lake Jobs</StyledH2>
        {archivingJobs}
      </Col>
    </Row>
  );
};

export default DataLakeJobs;
