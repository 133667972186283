import { useEffect, useMemo } from 'react';

import { useStore } from 'stores/connect';
import { MetricsActions, MetricsStore } from 'stores/metrics/MetricsStore';
import MetricsExtractor from 'logic/metrics/MetricsExtractor';

const useDataLakeJournalMetrics = (nodeId: string) => {
  const { metrics: metricsState } = useStore(MetricsStore);
  const metricNames = useMemo(
    () => ({
      segments: 'org.graylog.plugins.datawarehouse.output.journal.numberOfSegments',
      messageUncommitted: 'org.graylog.plugins.datawarehouse.output.journal.uncommittedMessages',
      unflushedMessages: 'org.graylog.plugins.datawarehouse.output.journal.unflushedMessages',
      oldestSegment: 'org.graylog.plugins.datawarehouse.output.journal.oldest-segment',
      writtenMessage: 'org.graylog.plugins.datawarehouse.output.journal.writtenMessages',
      size: 'org.graylog.plugins.datawarehouse.output.journal.size',
    }),
    [],
  );

  useEffect(() => {
    Object.keys(metricNames).forEach((metricShortName) => MetricsActions.add(nodeId, metricNames[metricShortName]));

    return () => {
      Object.keys(metricNames).forEach((metricShortName) =>
        MetricsActions.remove(nodeId, metricNames[metricShortName]),
      );
    };
  }, [metricNames, nodeId]);

  const nodeMetrics = metricsState[nodeId];

  return MetricsExtractor.getValuesForNode(nodeMetrics, metricNames);
};

export default useDataLakeJournalMetrics;
