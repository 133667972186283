import React, { useRef, useCallback, useMemo, useState } from 'react';

import { Input, InputGroup, FormControl, Button } from 'components/bootstrap';
import NumberUtils from 'util/NumberUtils';

import type { UploadFile, UploadConfig } from './Types';

import './UploadField.css';

type UploadFieldProps = {
  currentFile?: UploadFile;
  enableRemoval: boolean;
  isLoading: boolean;
  isUploading: boolean;
  onUploadFile: (files: FileList) => void;
  onDeleteFile: () => void;
  accept?: string;
  uploadSuccessful?: boolean;
  uploadConfig?: UploadConfig;
};

const isFileSizeValid = (file: File, maxSize: number) => (maxSize ? file.size <= maxSize : true);

const UploadField = ({
  accept,
  uploadSuccessful,
  uploadConfig = {
    maxUploadSize: 0,
  },
  enableRemoval,
  currentFile,
  isLoading,
  isUploading,
  onUploadFile,
  onDeleteFile,
}: UploadFieldProps) => {
  const uploadRef = useRef<HTMLInputElement>();
  const [hasError, setHasError] = useState(false);

  const setUploadRef = useCallback((ref) => {
    uploadRef.current = ref;
  }, []);

  const onOpenBrowse = useCallback(() => {
    if (!uploadRef.current || isUploading || isLoading) {
      return;
    }

    uploadRef.current.click();
  }, [uploadRef, isUploading, isLoading]);

  const onChangeFile = useCallback(
    (e) => {
      if (uploadConfig && isFileSizeValid(e.target.files[0], uploadConfig?.maxUploadSize)) {
        setHasError(false);
        onUploadFile(e.target.files);
      } else {
        setHasError(true);
      }
    },
    [onUploadFile, uploadConfig],
  );

  const inputValue = useMemo(() => {
    if (currentFile) {
      return currentFile.name;
    }

    if (isLoading) {
      return 'Loading file...';
    }

    if (isUploading) {
      return 'Uploading file...';
    }

    return '';
  }, [currentFile, isUploading, isLoading]);

  const helpText = useMemo(() => {
    if (uploadSuccessful === true) {
      return 'File uploaded successfully';
    }

    if (uploadSuccessful === false) {
      return 'Something went wrong uploading the file';
    }

    if (uploadConfig?.maxUploadSize) {
      return `The CSV file to use. Max file size: ${NumberUtils.formatBytes(uploadConfig?.maxUploadSize)}`;
    }

    return ' The CSV file to use.';
  }, [uploadSuccessful, uploadConfig]);

  const errorHelpText = useMemo(
    () => `The size of selected file is over the maximum allowed. (Max file size: 
      ${NumberUtils.formatBytes(uploadConfig?.maxUploadSize)})`,
    [uploadConfig],
  );

  return (
    <div>
      <Input
        id="path"
        name="path"
        label="CSV File"
        help={hasError ? errorHelpText : helpText}
        labelClassName="col-sm-3"
        bsStyle={hasError ? 'error' : null}
        wrapperClassName="col-sm-9">
        <InputGroup>
          <InputGroup.Button>
            <Button disabled={isUploading || isLoading} onClick={onOpenBrowse}>
              Browse...
            </Button>
          </InputGroup.Button>
          <FormControl
            onClick={onOpenBrowse}
            type="text"
            value={inputValue}
            onChange={() => {}}
            tabIndex={-1}
            placeholder="No file selected."
          />
          {currentFile && enableRemoval && (
            <InputGroup.Button>
              <Button bsStyle="danger" onClick={onDeleteFile}>
                Remove
              </Button>
            </InputGroup.Button>
          )}
          <input
            className="UploadField__uploadInput"
            type="file"
            accept={accept}
            onChange={onChangeFile}
            ref={setUploadRef}
          />
        </InputGroup>
      </Input>
    </div>
  );
};

export default UploadField;
