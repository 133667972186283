import * as React from 'react';

import { useSelectedRows, useSelectedRowsDispatch, useModalContext } from 'common/contexts';
import BulkActionsDropdown from 'components/common/EntityDataTable/BulkActionsDropdown';
import { MenuItem } from 'components/bootstrap';

import ClearSelection from './clear-selection';
import ExpandedPackDetails from './expanded-pack-details';

import type { PackType } from '../types';

const TableBulkActions = () => {
  const { setModal, setEntity } = useModalContext();
  const selectedPacks = useSelectedRows();
  const onUninstall = () => {
    setEntity('disable');
    setModal('CONFIRM_DISABLE_PACKS');
  };

  const onInstall = () => {
    setEntity('enable');
    setModal('CONFIRM_ENABLE_PACKS');
  };

  return (
    <BulkActionsDropdown bsSize="xs">
      <MenuItem onSelect={onUninstall} disabled={selectedPacks.length < 1}>
        Uninstall Packs
      </MenuItem>
      <MenuItem onSelect={onInstall} disabled={selectedPacks.length < 1}>
        Install Packs
      </MenuItem>
    </BulkActionsDropdown>
  );
};
function useTableElements(withTableBulkActions: boolean = false) {
  const selectedPacks = useSelectedRows();
  const selectedPacksDispatch = useSelectedRowsDispatch();

  const renderExpandedRow = React.useCallback((pack: PackType) => <ExpandedPackDetails pack={pack} />, []);

  const expandedSections = React.useMemo(
    () => ({
      restFieldsExpandedSection: {
        title: null,
        content: renderExpandedRow,
      },
    }),
    [renderExpandedRow],
  );

  const bulkSelection = React.useMemo(
    () => ({
      onChangeSelection: (packIds: Array<string>, list: Array<PackType>) => {
        const auxSelectedPacks =
          packIds.length > 0
            ? packIds.map((packId: string) => [...selectedPacks, ...list].find((pack: PackType) => pack.id === packId))
            : [];

        selectedPacksDispatch({ type: 'upsert', payload: auxSelectedPacks });
      },
      actions: withTableBulkActions ? <TableBulkActions /> : <ClearSelection />,
      isEntitySelectable: (pack: PackType) => pack.included_in_bundle,
    }),
    [selectedPacks, selectedPacksDispatch, withTableBulkActions],
  );

  return {
    expandedSections,
    bulkSelection,
    bulkActions: <ClearSelection />,
  };
}

export default useTableElements;
