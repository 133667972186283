import * as React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';

import type UserOverview from 'logic/users/UserOverview';
import { DEFAULT_PAGINATION } from 'components/common/PaginatedItemOverview';
import { PaginatedItemOverview } from 'components/common';
import SectionComponent from 'components/common/Section/SectionComponent';
import UsersQueryHelper from 'components/users/UsersQueryHelper';
import type { PaginatedUsers } from 'stores/users/UsersStore';

import UsersSelector from './UsersSelector';

import TeamsDomain from '../../domainActions/TeamsDomain';
import type Team from '../../logic/Team';

type Props = {
  team: Team;
};

const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const UsersSection = ({ team: { id, name }, team }: Props) => {
  const [loading, setLoading] = useState(false);
  const [paginatedUsers, setPaginatedUsers] = useState<PaginatedUsers | undefined>();

  const _onLoad = useCallback(
    (pagination) => {
      setLoading(true);

      return TeamsDomain.loadUsersForTeam(id, name, pagination).then((response) => {
        setLoading(false);

        return response;
      });
    },
    [id, name],
  );

  const _onAssignUser = (users) =>
    TeamsDomain.addMembers(
      id,
      users.map((u) => u.id),
    ).then(() => _onLoad(DEFAULT_PAGINATION).then(setPaginatedUsers));

  const _onUnassignUser = (user: UserOverview) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Do you really want to remove the user "${user.fullName}" from this team?`)) {
      TeamsDomain.removeMember(id, user.id).then(() => {
        _onLoad(DEFAULT_PAGINATION).then(setPaginatedUsers);
      });
    }
  };

  return (
    <SectionComponent title="Users" showLoading={loading}>
      <h3>Assign Users</h3>
      <Container>
        <UsersSelector onSubmit={_onAssignUser} team={team} />
      </Container>
      <h3>Selected Users</h3>
      <Container>
        <PaginatedItemOverview
          noDataText="No selected users have been found."
          onLoad={_onLoad}
          overrideList={paginatedUsers}
          queryHelper={<UsersQueryHelper />}
          onDeleteItem={_onUnassignUser}
        />
      </Container>
    </SectionComponent>
  );
};

export default UsersSection;
