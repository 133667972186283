import Reflux from 'reflux';

import type { ReportCreate, ReportDelivery, ReportWidgetPosition, ParameterValues, Report } from 'report/types';

type ReportsActionsType = {
  list: () => Promise<unknown>;
  create: (report: ReportCreate, reportLogo: string, parameterValues: ParameterValues) => Promise<unknown>;
  get: (reportId: string) => Promise<Report>;
  getSummary: (reportId: string) => Promise<Report>;
  getReportLogo: (reportId: string) => Promise<{ logo: string }>;
  update: (report: Report, reportLogo: string, parameterValues: ParameterValues) => Promise<unknown>;
  updateDelivery: (reportId: string, delivery: ReportDelivery) => Promise<unknown>;
  updatePositions: (reportId: string, positions: Array<ReportWidgetPosition>) => Promise<unknown>;
  delete: (reportId: string) => Promise<unknown>;
  sendEmail: (reportId: string) => Promise<unknown>;
  getHistory: (reportId: string, reportTitle: string, skip: number, limit: number) => Promise<unknown>;
};

const ReportsActions = Reflux.createActions<ReportsActionsType>({
  list: { asyncResult: true },
  listPage: { asyncResult: true },
  create: { asyncResult: true },
  get: { asyncResult: true },
  getSummary: { asyncResult: true },
  getReportLogo: { asyncResult: true },
  update: { asyncResult: true },
  updateDelivery: { asyncResult: true },
  updatePositions: { asyncResult: true },
  delete: { asyncResult: true },
  sendEmail: { asyncResult: true },
  getHistory: { asyncResult: true },
});

export default ReportsActions;
