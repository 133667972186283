import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import { LinkContainer } from 'components/common/router';
import { IfPermitted, Spinner } from 'components/common';
import { Alert, Button, ButtonToolbar } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { useStore } from 'stores/connect';

import InputProfileSwitchForm from '../input-profiles/InputProfileSwitchForm';
import ForwarderInputsManagement from '../inputs-list/ForwarderInputsManagement';
import InputProfilesStore, { InputProfilesActions } from '../stores/InputProfilesStore';
import ForwarderInputsStore, { ForwarderInputsActions } from '../stores/ForwarderInputsStore';
import type { Forwarder, InputProfile } from '../Types';

type Props = {
  forwarder: Forwarder;
  inputProfileId?: string;
  onInputProfileSwitch: (nextInputProfileId: string) => void;
};

const StyledButton = styled(Button)`
  margin-left: 0.5em;
  vertical-align: baseline;
`;

const ForwarderInputProfile = ({ forwarder, inputProfileId = '', onInputProfileSwitch }: Props) => {
  const { all: inputProfiles } = useStore(InputProfilesStore);
  const { forwarderInputs, pagination } = useStore(ForwarderInputsStore);

  const _saveForwarderInputProfile = ({ id }: InputProfile) => {
    onInputProfileSwitch(id);
    ForwarderInputsActions.list({ inputProfileId: id });
  };

  const _handleQuery = useCallback(
    (page?: number, pageSize?: number, query: string = '') => {
      ForwarderInputsActions.list({
        inputProfileId,
        page,
        pageSize,
        query,
      });
    },
    [inputProfileId],
  );

  useEffect(() => {
    if (!inputProfiles) {
      InputProfilesActions.listAll();
    }

    if (isEmpty(forwarderInputs) && inputProfileId) {
      ForwarderInputsActions.list({ inputProfileId });
    }
  }, [inputProfileId, inputProfiles, forwarderInputs]);

  if (!inputProfiles) {
    return <Spinner />;
  }

  const inputProfile = inputProfiles.find((i) => i.id === inputProfileId);

  if (!inputProfile) {
    return (
      <Alert bsStyle="info">
        This Forwarder is not configured with an Input Profile
        <IfPermitted permissions="forwarders:edit">
          <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS_CONFIGURE_FORWARDERID')(forwarder.id)}>
            <StyledButton bsSize="xsmall" bsStyle="primary">
              Configure it now
            </StyledButton>
          </LinkContainer>
        </IfPermitted>
      </Alert>
    );
  }

  return (
    <>
      <div className="pull-right">
        <ButtonToolbar>
          <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(inputProfile.id)}>
            <Button bsStyle="default">View Input Profile</Button>
          </LinkContainer>
          {forwarder && inputProfile && inputProfiles && (
            <IfPermitted permissions="forwarders:edit">
              <InputProfileSwitchForm
                onSave={_saveForwarderInputProfile}
                forwarder={forwarder}
                currentInputProfile={inputProfile}
                inputProfiles={inputProfiles}
              />
            </IfPermitted>
          )}
        </ButtonToolbar>
      </div>
      <h2>
        From Input Profile: <em>{inputProfile.title}</em>
      </h2>
      {forwarderInputs[inputProfileId] && (
        <ForwarderInputsManagement
          inputs={forwarderInputs[inputProfileId]}
          forwarder={forwarder}
          pagination={pagination}
          onQueryChange={_handleQuery}
          inputProfile={inputProfile}
        />
      )}
    </>
  );
};

export default ForwarderInputProfile;
