import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { ReportSummary } from 'report/types';
import ReportActions from 'report/reports-page/ReportActions';
import ReportsActions from 'report/ReportsActions';
import type { SearchParams, Sort } from 'stores/PaginationTypes';
import fetchReports from 'report/reports-page/fetchReports';
import ReportQueryHelper from 'report/reports-page/ReportQueryHelper';
import PaginatedEntityTable from 'components/common/PaginatedEntityTable';

import CustomColumnRenderers from './ColumnRenderers';

const KEY_PREFIX = ['reports', 'overview'];
const keyFn = (searchParams: SearchParams) => [...KEY_PREFIX, searchParams];

const useRefetchReportsOnStoreChange = () => {
  const queryClient = useQueryClient();

  useEffect(
    () =>
      ReportsActions.delete.completed.listen(() => {
        queryClient.invalidateQueries({ queryKey: KEY_PREFIX });
      }),
    [queryClient],
  );
};

const columnsOrder = [
  'title',
  'subtitle',
  'description',
  'owner_full_name',
  'created_at',
  'timezone',
  'last_generated_at',
  'status',
  'recipients',
];

const tableLayout = {
  entityTableId: 'reports',
  defaultPageSize: 20,
  defaultDisplayedAttributes: [
    'title',
    'subtitle',
    'description',
    'owner_full_name',
    'created_at',
    'last_generated_at',
    'status',
    'recipients',
  ],
  defaultSort: { attributeId: 'title', direction: 'asc' } as Sort,
};

const additionalAttributes = [{ id: 'recipients', title: 'Recipients', sortable: false }];

const fetchOptions = { refetchInterval: 5000 };

const ReportsList = () => {
  const entityActions = useCallback((listItem: ReportSummary) => <ReportActions report={listItem} />, []);

  useRefetchReportsOnStoreChange();

  return (
    <PaginatedEntityTable<ReportSummary>
      humanName="reports"
      columnsOrder={columnsOrder}
      additionalAttributes={additionalAttributes}
      queryHelpComponent={<ReportQueryHelper />}
      entityActions={entityActions}
      entityAttributesAreCamelCase={false}
      tableLayout={tableLayout}
      fetchEntities={fetchReports}
      fetchOptions={fetchOptions}
      keyFn={keyFn}
      columnRenderers={CustomColumnRenderers}
    />
  );
};

export default ReportsList;
