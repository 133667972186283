import type Immutable from 'immutable';

import type { SearchFilter } from 'views/types';
import { REFERENCED_USER_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import { isPermitted } from 'util/PermissionsMixin';

const isFilterInaccessible = (filter: SearchFilter, userPermissions: Immutable.List<string>) =>
  filter.type === REFERENCED_USER_SEARCH_FILTER_TYPE &&
  !isPermitted(userPermissions, [`search_filters:read:${filter.id}`]);

export default isFilterInaccessible;
