import React, { useEffect } from 'react';

import ChecksumTypes from 'archive/logic/ChecksumTypes';
import CompressionTypes from 'archive/logic/CompressionTypes';
import ArchiveBackendsStore, { ArchiveBackendsActions } from 'archive/ArchiveBackendsStore';
import type { ArchiveBackendsStoreState } from 'archive/ArchiveBackendsStore';
import { useArchiveConfig } from 'archive/hooks';
import NumberUtils from 'util/NumberUtils';
import { Spinner } from 'components/common';
import { Alert } from 'components/bootstrap';
import { useStore } from 'stores/connect';

import { StyledDescriptionWrapper } from './StyledCatalogComponents';

const ArchiveConfig = () => {
  const currentBackend = useStore(ArchiveBackendsStore, (state: ArchiveBackendsStoreState) => state.backend);
  const { data: currentConfig, isLoading } = useArchiveConfig();

  useEffect(() => {
    if (currentConfig.backend_id) {
      ArchiveBackendsActions.getBackend(currentConfig.backend_id);
    }
  }, [currentConfig.backend_id, currentConfig]);

  const retentionTimeString =
    currentConfig.retention_time === undefined || currentConfig.retention_time === 0
      ? 'unlimited'
      : NumberUtils.formatNumber(currentConfig.retention_time);

  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      <h2>Configuration</h2>
      <StyledDescriptionWrapper $marginLeft={225}>
        {!currentBackend && <Alert bsStyle="danger">No backend configured. Archiving is not properly set up!</Alert>}
        <dl className="archive-config-overview">
          <dt>Backend:</dt>
          <dd>
            {currentBackend ? (
              `${currentBackend.title} - ${currentBackend.settings.output_path}`
            ) : (
              <em>No backend configured</em>
            )}
          </dd>
          <dt>Max segment size:</dt>
          <dd>{NumberUtils.formatBytes(currentConfig.max_segment_size)}</dd>
          <dt>Compression type:</dt>
          <dd>{CompressionTypes.getCompressionType(currentConfig.segment_compression_type).label}</dd>
          <dt>Checksum type:</dt>
          <dd>{ChecksumTypes.getChecksumType(currentConfig.segment_checksum_type).label}</dd>
          <dt>Restore index batch size:</dt>
          <dd>{NumberUtils.formatNumber(currentConfig.restore_index_batch_size)}</dd>
          <dt>Failure notification threshold:</dt>
          <dd>{NumberUtils.formatNumber(currentConfig.archive_failure_threshold)}</dd>
          <dt>Retention time (days):</dt>
          <dd>{retentionTimeString}</dd>
        </dl>
      </StyledDescriptionWrapper>
    </div>
  );
};

export default ArchiveConfig;
