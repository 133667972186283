import * as React from 'react';
import mapValues from 'lodash/mapValues';
import styled from 'styled-components';

import DeleteDataLakeAction from 'data-lake/data-lake-backend-config/backend-overview/actions/DeleteDataLakeAction';
import { PaginatedList, Spinner, EntityList, EntityListItem } from 'components/common';
import DataLakeBackendButton from 'data-lake/data-lake-backend-config/DataLakeBackendButton';
import type { Backend } from 'archive/types';
import AppConfig from 'util/AppConfig';
import { ButtonToolbar, Col, Row } from 'components/bootstrap';
import { BACKEND_TYPES } from 'archive/ArchiveStore';
import { ConfigurationWell } from 'components/configurationforms';
import { DEFAULT_PAGE_SIZES } from 'hooks/usePaginationQueryParameter';
import DataLakeBackendActivateButton from 'data-lake/data-lake-backend-config/DataLakeBackendActivateButton';
import useDataLakeBackends from 'data-lake/data-lake-backend-config/hooks/useDataLakeBackends';
import useDataLakeConfig from 'data-lake/configurations/hooks/useDataLakeConfig';

import DataLakeBackendState from './DataLakeBackendState';

const isCloud = AppConfig.isCloud();
const StyledRow = styled(Row)`
  dt {
    float: left;
    width: 200px;
    overflow: hidden;
    clear: left;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  dd {
    margin-left: 180px;
    display: inline-block;
  }

  dl {
    margin-bottom: 0;
  }
`;

const BackendConfigOverview = () => {
  const {
    data: backendsList,
    isInitialLoading: isLoadingBackends,
    refetch: refetchDataLakeBackend,
  } = useDataLakeBackends({ enabled: true });
  const {
    data: dataLakeBackendConfig,
    isSuccess: isCurrentBackendLoaded,
    isInitialLoading: isLoadingDataLakeConfig,
  } = useDataLakeConfig();

  if (isLoadingBackends || isLoadingDataLakeConfig) {
    return <Spinner />;
  }

  const formatBackend = (backend: Backend) => {
    const { settings, description, id, title } = backend;
    const isActiveBackend = isCurrentBackendLoaded && dataLakeBackendConfig.active_backend === id;

    const actions = !isCloud && (
      <ButtonToolbar>
        <DataLakeBackendActivateButton
          backend={backend}
          isActiveBackend={isActiveBackend}
          config={dataLakeBackendConfig}
        />
        <DataLakeBackendButton buttonType="menuItem" backendId={id} />
        {!isActiveBackend && <DeleteDataLakeAction backend={backend} refetchDataLakeBackend={refetchDataLakeBackend} />}
      </ButtonToolbar>
    );

    const normalizedSettings = mapValues(settings, (value, key: string) => {
      if (key === 'type') {
        return BACKEND_TYPES[settings[key]];
      }

      if (key === 'aws_secret_access_key') {
        return '***********';
      }

      return value;
    });

    const content = (
      <Col md={12}>
        <StyledRow>
          <Col md={12}>
            <dl>
              <ConfigurationWell id={id} configuration={normalizedSettings} />
            </dl>
          </Col>
        </StyledRow>
      </Col>
    );

    return (
      <EntityListItem
        key={`backend-${id || 'newbackend'}`}
        title={
          <>
            {title} <DataLakeBackendState isActiveBackend={isActiveBackend} />
          </>
        }
        description={description}
        actions={actions}
        contentRow={content}
      />
    );
  };

  return (
    <PaginatedList pageSize={DEFAULT_PAGE_SIZES[1]} showPageSizeSelect={false} totalItems={backendsList?.length}>
      <EntityList
        bsNoItemsStyle="info"
        noItemsText="There are no backends to display"
        items={backendsList?.map((backend) => formatBackend(backend))}
      />
    </PaginatedList>
  );
};

export default BackendConfigOverview;
