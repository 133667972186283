import * as React from 'react';
import styled, { css } from 'styled-components';

import { LinkContainer } from 'components/common/router';
import { Alert, Button, Row } from 'components/bootstrap';
import {
  useInputSetupWizard,
  ButtonCol,
  DescriptionCol,
  StepWrapper,
  useInputSetupWizardStepsHelper,
} from 'components/inputs/InputSetupWizard';

import type { EnterpriseStepsData } from '../types';

export const StyledAlert = styled(Alert)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.lg};
  `,
);

export const StyledSubheading = styled.h4(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.sm};
  `,
);

const InstallIlluminateStep = () => {
  const { goToNextStep, orderedSteps, activeStep } = useInputSetupWizard();
  const { checkHasNextStep } = useInputSetupWizardStepsHelper<EnterpriseStepsData>();
  const hasNextStep = checkHasNextStep(orderedSteps, activeStep);

  const onNextStep = () => {
    goToNextStep();
  };

  return (
    <StepWrapper>
      <h3>Install Illuminate</h3>
      <Row>
        <DescriptionCol md={12}>
          <StyledAlert bsStyle="info" title="Graylog Illuminate has not been found on your Cluster">
            <p>
              We are unable to offer any pre-built content as part of this wizard.
              <br />
              Illuminate is a collection of packs containing built-in parsers and security content for common log
              formats.
            </p>
            <p>
              When a Graylog cluster has internet access and can reach the content hub, the latest illuminate version of
              Illuminate will be downloaded (and subsequently updated) automatically.
            </p>
          </StyledAlert>
          <StyledSubheading>Want to get Illuminate working?</StyledSubheading>
          <p>
            <strong>Graylog cluster is air-gapped:</strong>
          </p>
          <p>
            Please reach out to your Customer Success representative for instructions to manually download the latest
            Illuminate Pack. Manually downloaded Illuminate packs can be installed via the Illuminate page.
          </p>
          <p>
            <strong>Graylog cluster is not air-gapped:</strong>
          </p>
          <p>
            Graylog nodes require internet connectivity to the following addresses:
            <br />
            https://contenthub.graylog.cloud/v1/bundles/
            <br />
            https://glc-illuminate-hub.s3.amazonaws.com/
          </p>
          <p>
            <strong>I’ve installed Illuminate now - what next?</strong>
          </p>
          <p>You should exit and restart this wizard for changes to take effect.</p>
        </DescriptionCol>
      </Row>
      <Row>
        <ButtonCol md={12}>
          <LinkContainer to="/illuminate/install">
            <Button>Install Illuminate</Button>
          </LinkContainer>
          {hasNextStep && (
            <Button onClick={onNextStep} bsStyle="primary">
              Proceed without Illuminate
            </Button>
          )}
        </ButtonCol>
      </Row>
    </StepWrapper>
  );
};

export default InstallIlluminateStep;
