import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';

import { ApiRoutes } from './Routes';
import { toWorkspaceInputCreateRequest } from './formDataAdapter';

import useFetch from '../common/hooks/useFetch';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';

const Container = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.variant.darkest.default};
    margin: 25px 0;
    padding: 15px;
    border-radius: 4px;
    max-width: 100%;
    overflow: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  `,
);

const Subheader = styled.h3`
  margin: 0 0 10px;
`;

const ReviewItems = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0 0 25px 10px;
    padding: 0;

    li {
      line-height: 2;
      padding: 0 5px;

      &:nth-of-type(odd) {
        background-color: ${theme.colors.table.row.backgroundStriped};
      }
    }

    strong::after {
      content: ':';
      margin-right: 5px;
    }
  `,
);

type Props = {
  onSubmit: (FormData?) => void;
  externalInputSubmit?: boolean;
};

const StepReview = ({ onSubmit, externalInputSubmit = false }: Props) => {
  const [formError, setFormError] = useState(null);
  const { formData } = useContext(FormDataContext);

  const throttleEnabled = !!formData.workspaceThrottleEnabled?.value;
  const storeFullMessage = !!formData.workspaceStoreFullMessage?.value;

  const [saveInput, setSaveInput] = useFetch(null, () => onSubmit(), 'POST', toWorkspaceInputCreateRequest(formData));

  useEffect(() => {
    setSaveInput(null);

    if (saveInput.error) {
      setFormError({
        full_message: saveInput.error,
        nice_message: <span>We were unable to save your Input, please try again in a few moments.</span>,
      });
    }
  }, [saveInput.error, setSaveInput]);

  const handleSubmit = () => {
    if (externalInputSubmit) {
      onSubmit(formData);

      return;
    }

    setSaveInput(ApiRoutes.INTEGRATIONS.WorkspaceV2.SAVE_INPUT);
  };

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Save and Start Input"
      loading={saveInput.loading}
      error={formError}
      description="Check out everything below to make sure it's correct, then click the button below to complete your Workspace setup!">
      <Container>
        <Subheader>Input Configuration</Subheader>
        <ReviewItems>
          <li>
            <strong>Name</strong>
            <span>{formData.workspaceName.value}</span>
          </li>
          <li>
            <strong>BigQuery Dataset name</strong>
            <span>{formData.workspaceDatasetName.value}</span>
          </li>
          <li>
            <strong>Polling Interval</strong>
            <span>{formData.pollingInterval.value}</span>
          </li>
          <li>
            <div>
              <strong>Subscribed To</strong>
              {formData.contentTypes.value.replaceAll(',', ', ')}
            </div>
          </li>
          <li>
            <strong>Enable Throttling</strong>
            <span>
              <Icon name={throttleEnabled ? 'check_circle' : 'cancel'} />
            </span>
          </li>
          <li>
            <strong>Store Full Message</strong>
            <span>
              <Icon name={storeFullMessage ? 'check_circle' : 'cancel'} />
            </span>
          </li>
        </ReviewItems>
      </Container>
    </FormWrap>
  );
};

export default StepReview;
