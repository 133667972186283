import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import FormikInput from 'components/common/FormikInput';
import { Input } from 'components/bootstrap';
import { TimezoneSelect } from 'components/common';
import HoverForHelp from 'components/common/HoverForHelp';

const DescriptionTextArea: React.ComponentType<React.ComponentProps<typeof FormikInput>> = styled(FormikInput)`
  resize: vertical;
`;

const timezoneLabel = (
  <>
    Time zone
    <HoverForHelp iconSize="sm" title="Time zone" placement="right">
      The time zone selected here will be used to display all times in the report.
    </HoverForHelp>
  </>
);

const Overview = () => (
  <>
    <FormikInput label="Title" name="title" id="title" required />
    <FormikInput
      label={
        <>
          Subtitle <small className="text-muted">(Optional)</small>
        </>
      }
      name="subtitle"
      id="subtitle"
    />
    <DescriptionTextArea
      type="textarea"
      rows={3}
      label={
        <>
          Description <small className="text-muted">(Optional)</small>
        </>
      }
      name="description"
      id="description"
    />
    <Field name="timezone">
      {({ field: { name, value, onChange } }) => (
        <Input id="timezone-select" label={timezoneLabel}>
          <TimezoneSelect
            value={value}
            name="timezone"
            onChange={(newValue: string) => onChange({ target: { name, value: newValue } })}
          />
        </Input>
      )}
    </Field>
  </>
);

export default Overview;
