import Reflux from 'reflux';
import type * as Immutable from 'immutable';

import type { WizardSubmitPayload } from 'logic/authentication/directoryServices/types';
import { singletonActions } from 'logic/singleton';
import type { RefluxActions } from 'stores/StoreTypes';
import type { OidcSubmitPayload } from 'authentication/components/oidc/config/types';

import type GroupSyncBackend from '../../logic/directoryServices/GroupSyncBackend';
import type { GroupSyncBackendJSON } from '../../logic/directoryServices/GroupSyncBackend';
import type Group from '../../logic/directoryServices/Group';

export type LoadGroupsResponse = {
  success: boolean;
  message: string;
  errors: Immutable.List<string>;
  list: Immutable.List<Group>;
};

export type LoadBackendResponse = {
  backend: GroupSyncBackend;
};

type GroupSyncBackendPayload = Omit<GroupSyncBackendJSON, 'id'>;

export type LoadGroupsPayload = {
  auth_service_backend_id: string | null | undefined;
  auth_service_backend_configuration: WizardSubmitPayload | OidcSubmitPayload;
  team_sync_backend_configuration: GroupSyncBackendPayload;
};

export type ActionsType = {
  create: (backendId: string, payload: GroupSyncBackendPayload) => Promise<LoadBackendResponse>;
  delete: (backendId: string) => Promise<LoadBackendResponse>;
  load: (backendId: string) => Promise<LoadBackendResponse>;
  loadGroups: (payload: LoadGroupsPayload) => Promise<LoadGroupsResponse>;
  update: (backendId: string, payload: GroupSyncBackendPayload) => Promise<LoadBackendResponse>;
};

const GroupSynchronizationActions: RefluxActions<ActionsType> = singletonActions(
  'enterprise.authentication.directoryService.GroupSync',
  () =>
    Reflux.createActions({
      create: { asyncResult: true },
      delete: { asyncResult: true },
      load: { asyncResult: true },
      loadGroups: { asyncResult: true },
      update: { asyncResult: true },
    }),
);

export default GroupSynchronizationActions;
