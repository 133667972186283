import watchListActions from 'security-content/mappings/externalActions';
import watchlists from 'security-content/mappings/watchlists';
import {
  WatchlistAddition,
  WatchlistRemoval,
  WatchlistActionHelp,
} from 'security-content/externalActionHandlers/WatchlistStatusChange';
import { replaceTemplateWithFieldValue } from 'security-content/externalActionHandlers/ExternalLinks';
import { isArrayValue } from 'search/messageFieldValueHelper';
import useLicenseCheck from 'license/useLicenseCheck';
import useExternalActionsQuery from 'security-content/hooks/useExternalActionsQuery';
import type { ExternalActions, HttpGetAction, WatchlistAction } from 'security-content/types';
import type { ActionDefinition } from 'views/components/actions/ActionHandler';
import type { LicenseCheckContextType } from 'license/LicenseCheckContext';

const assertUnreachable = (ignored: never): never => {
  throw new Error(`Unexpected action definition: ${ignored}`);
};

const licenseIsValid = (licenseCheck: LicenseCheckContextType) => !!licenseCheck?.security?.isValid;

const createLookupAction = (name: string, lookupAction: HttpGetAction): ActionDefinition => ({
  type: name,
  title: lookupAction.title,
  isHidden: ({ field, contexts: { licenseCheck } }) =>
    !licenseIsValid(licenseCheck) || !lookupAction.fieldNames.includes(field),
  resetFocus: false,
  linkTarget: replaceTemplateWithFieldValue(lookupAction.options.linkTemplate),
});

const createWatchlistActions = (name: string, watchlistAction: WatchlistAction): Array<ActionDefinition> => {
  const wlFieldType = watchlistAction.options.fieldType;
  const watchlist = Object.values(watchlists).find((wl) => wl.fieldType === wlFieldType);
  const isEnabled = ({ value }: { value?: string }) => !isArrayValue(value);
  const commonProperties = {
    type: name,
    isEnabled,
    help: (handlerArgs) => WatchlistActionHelp(isEnabled(handlerArgs)),
    resetFocus: false,
  };

  return [
    {
      ...commonProperties,
      title: `Add to ${watchlist.title}`,
      isHidden: ({ field, contexts: { currentUser, watchlistStatus, licenseCheck } }) =>
        !licenseIsValid(licenseCheck) ||
        WatchlistAddition.isHidden(wlFieldType, field, currentUser?.permissions, watchlistStatus?.status),
      handler: WatchlistAddition.handler(wlFieldType),
    },
    {
      ...commonProperties,
      title: `Remove from ${watchlist.title}`,
      isHidden: ({ field, contexts: { currentUser, watchlistStatus, licenseCheck } }) =>
        !licenseIsValid(licenseCheck) ||
        WatchlistRemoval.isHidden(wlFieldType, field, currentUser?.permissions, watchlistStatus?.status),
      handler: WatchlistRemoval.handler(wlFieldType),
    },
  ];
};

const createActions = (actionDefinitions: ExternalActions) =>
  Object.entries(actionDefinitions).flatMap(([name, actionDefinition]) => {
    switch (actionDefinition.type) {
      case 'http-get':
        return createLookupAction(name, actionDefinition);
      case 'lookup-table':
        return createWatchlistActions(name, actionDefinition);
      default:
        return assertUnreachable(actionDefinition);
    }
  });

export type UseExternalActionsResult = {
  error: Error;
  externalValueActions: Array<ActionDefinition> | null;
  isLoading: boolean;
  isError: boolean;
};

export default function useExternalActions(): UseExternalActionsResult {
  const {
    security: { isValid: isValidSecurityLicense },
  } = useLicenseCheck();
  const { data, error, isFetching, isError } = useExternalActionsQuery({
    isValidSecurityLicense,
    isLoadingSecurityLicense: false,
  });

  let externalValueActions: Array<ActionDefinition> | null = null;

  if (isFetching || isError || !isValidSecurityLicense) {
    externalValueActions = [];
  } else {
    externalValueActions = createActions({ ...watchListActions, ...data });
  }

  return {
    error,
    externalValueActions,
    isError,
    isLoading: isFetching,
  };
}
