import React, { useState } from 'react';

import useLicenseMutation from 'license/hooks/useLicenseMutation';
import { Button, ButtonToolbar } from 'components/bootstrap';
import { ConfirmDialog } from 'components/common';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import type { License, LicenseDetails } from 'license/types';

type Props = {
  id: string;
  licenseId?: License['license_id'];
  contractId?: LicenseDetails['contract_id'];
};

const LicenseActions = ({ id, licenseId, contractId }: Props) => {
  const { deselectEntity } = useSelectedEntities();
  const { deleteLicense } = useLicenseMutation();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const sendTelemetry = useSendTelemetry();

  const cancelDelete = () => {
    setShowDeleteDialog(false);
    deselectEntity(id);
  };

  const handleDelete = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.LICENSE.DELETED, {
      app_pathname: 'license',
      app_section: 'license',
    });

    deleteLicense({ licenseId, contractId }).then(() => {
      deselectEntity(id);
      setShowDeleteDialog(false);
    });
  };

  const onDelete = () => {
    setShowDeleteDialog(true);
  };

  return (
    <>
      {showDeleteDialog && (
        <ConfirmDialog
          show={showDeleteDialog}
          title="Deleting license"
          onCancel={cancelDelete}
          onConfirm={handleDelete}>
          <p>You are about to delete the license: &quot;{licenseId ?? contractId}&quot;. Are you sure?</p>
        </ConfirmDialog>
      )}

      <ButtonToolbar>
        <Button bsSize="xs" bsStyle="danger" onClick={onDelete}>
          Delete
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default LicenseActions;
