import { useQuery } from '@tanstack/react-query';

import { DataLakeBackends } from '@graylog/enterprise-api';

const useHasActiveBackend = (): {
  data: boolean | undefined;
  isInitialLoading?: boolean;
} => useQuery(['data-lake', 'backend', 'active'], () => DataLakeBackends.hasActiveBackend());

export default useHasActiveBackend;
