import * as React from 'react';

import { useModalContext } from 'common/contexts';
import { useTriggerSecurityEventNotification } from 'security-app/hooks/useSecurityEventsAPI';
import SendNotificationModal from 'security-app/components/SecurityEvents/Alerts/AlertsModals/NotificationsModal/SendNotificationModal';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';

function SendNotification() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const { triggerSecurityEventNotification, triggeringSecurityEventNotification } =
    useTriggerSecurityEventNotification();
  const sendEventActionTelemetry = useSendEventActionTelemetry();

  const closeModal = () => {
    setModal(null);
    setEntity(null);
  };

  const onConfirm = async (notificationId: string) => {
    await triggerSecurityEventNotification({ id: securityEvent.event_id, notificationId: notificationId });
    sendEventActionTelemetry('SEND_NOTIFICATION', false);

    setModal(null);
    setEntity(null);
  };

  return (
    <SendNotificationModal
      confirmDisabled={triggeringSecurityEventNotification}
      closeModal={closeModal}
      onConfirm={onConfirm}
    />
  );
}

export default SendNotification;
