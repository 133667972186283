import { useMutation } from '@tanstack/react-query';

import EnterpriseApiRoutes from 'common/ApiRoutes';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

const acknowledgeUsageWarning = async () => {
  const url = qualifyUrl(EnterpriseApiRoutes.License.notifications.acknowledgeUsageWarning().url);

  return fetch('PUT', url);
};

const useUsageWarningAcknowledgementMutation = () => {
  const acknowledge = useMutation(acknowledgeUsageWarning, {
    onError: (errorThrown) => {
      UserNotification.error(
        `Acknowledging notification failed with status: ${errorThrown}`,
        'Could not acknowledge notification',
      );
    },
    onSuccess: () => {
      UserNotification.success('Notification has been successfully ackknowledged.', 'Success!');
    },
  });

  return { acknowledgeNotifcation: acknowledge.mutateAsync };
};

export default useUsageWarningAcknowledgementMutation;
