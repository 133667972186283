import Reflux from 'reflux';
import * as Immutable from 'immutable';

import type { Store } from 'stores/StoreTypes';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import { singletonStore } from 'logic/singleton';
import PaginationURL from 'util/PaginationURL';
import type { Pagination } from 'stores/PaginationTypes';

import AuthenticationActions from '../actions/AuthenticationActions';
import type { PaginatedAuthTeams, TriggerTeamSyncResult } from '../actions/AuthenticationActions';
import type { PaginatedTeamsResponse } from '../../security/stores/TeamsStore';
import Team from '../../security/logic/Team';

const apiPrefix = '/plugins/org.graylog.plugins.security';
const ApiRoutes = {
  AuthenticationController: {
    loadTeamsPaginated: (authBackendId: string) => ({
      url: `${apiPrefix}/team-sync/services/${authBackendId}/backend/teams`,
    }),
    triggerTeamSync: (backendId: string) => ({ url: `${apiPrefix}/team-sync/trigger/${backendId}` }),
  },
};

const AuthenticationStore: Store<void> = singletonStore('enterprise.Authentication', () =>
  Reflux.createStore({
    listenables: [AuthenticationActions],

    loadTeamsPaginated(
      authBackendId: string,
      { page: newPage, perPage: newPerPage, query: newQuery }: Pagination,
    ): Promise<PaginatedAuthTeams> {
      const url = PaginationURL(
        ApiRoutes.AuthenticationController.loadTeamsPaginated(authBackendId).url,
        newPage,
        newPerPage,
        newQuery,
      );

      const promise = fetch('GET', qualifyUrl(url)).then(
        ({ teams, context, page, per_page: perPage, query, count, total }: PaginatedTeamsResponse) => ({
          list: Immutable.List(teams.map((user) => Team.fromJSON(user))),
          context: {
            roles: Immutable.Map(context?.roles),
            users: Immutable.Map(context?.users),
          },
          pagination: {
            page,
            perPage,
            query,
            count,
            total,
          },
        }),
      );

      AuthenticationActions.loadTeamsPaginated.promise(promise);

      return promise;
    },

    triggerTeamSync(backendId: string): Promise<TriggerTeamSyncResult> {
      const promise = fetch('POST', qualifyUrl(ApiRoutes.AuthenticationController.triggerTeamSync(backendId).url)).then(
        ({
          success,
          message,
          errors,
          created_teams: createdTeams,
          deleted_teams: deletedTeams,
          updated_teams: updatedTeams,
        }) => ({
          success,
          message,
          errors,
          createdTeams,
          deletedTeams,
          updatedTeams,
        }),
      );

      AuthenticationActions.triggerTeamSync.promise(promise);

      return promise;
    },
  }),
);

export { AuthenticationActions, AuthenticationStore };

export default AuthenticationStore;
