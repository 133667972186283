import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState, useMemo } from 'react';

import ForwardersContext, { getForwarderContextById } from './ForwardersContext';

import type { ForwarderResponse } from '../stores/ForwardersStore';
import { ForwardersActions } from '../stores/ForwardersStore';
import type { Forwarder, ForwardersContext as ForwardersContextType } from '../Types';

type Props = {
  children: ReactNode;
  forwarderId: string;
};

const SingleForwarderProvider = ({ forwarderId, children }: Props) => {
  const [forwarder, setForwarder] = useState<Forwarder | void>();
  const [forwarderContext, setForwarderContext] = useState<ForwardersContextType | void>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchPromise = useRef<Promise<ForwarderResponse> | void>();
  const timeout = useRef<number | void>();

  const forwardersContextValue = useMemo(() => {
    const effectiveForwardersContext = forwarderContext || {};

    return {
      forwarders: forwarder ? [forwarder] : [],
      forwardersContext: forwarderContext || {},
      isLoading,
      getForwarderContext: getForwarderContextById(effectiveForwardersContext),
    };
  }, [forwarderContext, forwarder, isLoading]);

  useEffect(() => {
    const fetchForwarder = () => {
      if (fetchPromise.current) {
        return;
      }

      setIsLoading(true);

      fetchPromise.current = ForwardersActions.get(forwarderId)
        .then((response: ForwarderResponse) => {
          setForwarder(response.forwarder);
          setForwarderContext(response.forwarderContext);

          return response;
        })
        .finally(() => {
          fetchPromise.current = undefined;
          setIsLoading(false);
        });
    };

    fetchForwarder();

    timeout.current = window.setInterval(() => {
      fetchForwarder();
    }, 5000);

    return () => {
      fetchPromise.current = undefined;
      setIsLoading(false);

      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
  }, [forwarderId]);

  return <ForwardersContext.Provider value={forwardersContextValue}>{children}</ForwardersContext.Provider>;
};

export default SingleForwarderProvider;
