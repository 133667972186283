import CorrelationFormContainer from './CorrelationFormContainer';
import CorrelationForm from './CorrelationForm';
import CorrelationSummaryContainer from './CorrelationSummaryContainer';

export default {
  eventDefinitionTypes: [
    {
      type: 'correlation-v1',
      displayName: 'Event Correlation',
      sortOrder: 1, // Sort after conditions working on log messages
      description:
        'Correlate previously defined Events to identify meaningful incidents. ' +
        'This will create new Events that you can later use.',
      formComponent: CorrelationFormContainer,
      summaryComponent: CorrelationSummaryContainer,
      defaultConfig: CorrelationForm.defaultConfig,
    },
  ],
};
