import URI from 'urijs';

import type { Input } from 'components/messageloaders/Types';
import AppConfig from 'util/AppConfig';

// eslint-disable-next-line import/prefer-default-export
export const useConfiguration = (input?: Input) => {
  let configuration: { [key: string]: string } = {};

  // This is a false positive, see: https://github.com/amilajack/eslint-plugin-compat/issues/446
  // eslint-disable-next-line compat/compat
  const graylogServerHostname = new URI(AppConfig.gl2ServerUrl()).hostname() || window.location.hostname;
  configuration.forwarder_server_hostname = AppConfig.isCloud()
    ? `ingest-${graylogServerHostname}`
    : graylogServerHostname;
  configuration.forwarder_grpc_api_token = '<your api token>';

  if (input) {
    const {
      forwarder_message_transmission_port: messageTransmissionPort,
      forwarder_grpc_enable_tls: enableTls,
      forwarder_grpc_tls_trust_chain_cert_file: trustChainCertFile,
      forwarder_configuration_port: configurationPort,
    } = input.attributes;

    configuration = {
      ...configuration,
      forwarder_configuration_port: configurationPort,
      forwarder_message_transmission_port: messageTransmissionPort,
      forwarder_grpc_enable_tls: enableTls,
    };

    if (enableTls && trustChainCertFile) {
      const certFilename = trustChainCertFile.split(/[\\|/]/).pop();
      configuration.forwarder_grpc_tls_trust_chain_cert_file = `<path to ${certFilename || 'your cert file'}>`;
    }
  }

  return [configuration];
};
