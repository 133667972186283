import type { Sort } from 'stores/PaginationTypes';

export const DEFAULT_LAYOUT = {
  entityTableId: 'datawarehouse',
  defaultPageSize: 20,
  defaultSort: { attributeId: 'last_known_stream_title', direction: 'asc' } as Sort,
  defaultDisplayedAttributes: [
    'stream',
    'message_count',
    'days_of_data',
    'restored_indices',
    'timestamp_from',
    'timestamp_to',
  ],
};
export const COLUMNS_ORDER = [
  'stream',
  'message_count',
  'days_of_data',
  'timestamp_from',
  'timestamp_to',
  'restored_indices',
];

export const ADDITIONAL_ATTRIBUTES = [
  { id: 'restored_indices', title: 'Restore operations', hidden: false },
  { id: 'stream', title: 'Stream', hidden: false },
  { id: 'days_of_data', title: 'Days of data', hidden: false },
];
