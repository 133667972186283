import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import StreamFilterFieldValue from 'data-lake/components/StreamFilterFieldValue';
import AssetsFilterFieldValue from 'data-lake/components/AssetsFilterFieldValue';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import InputFilterFieldValue from 'data-lake/components/InputsFilterFieldValue';

type Props = {
  field: FieldTypeMapping;
  fieldName: string;
  assetLabels: Array<{ id: string; name: string }> | undefined;
  inputOptions: Array<{ value: string; label: string }> | undefined;
};

const FieldContainer = styled.div`
  flex-basis: 100%;
`;

function DataLakeFilterValueField({ field, fieldName, assetLabels, inputOptions }: Props) {
  if (field?.name === 'streams') {
    return <StreamFilterFieldValue fieldName={fieldName} />;
  }

  if (field?.name === 'associated_assets') {
    return <AssetsFilterFieldValue fieldName={fieldName} assetLabels={assetLabels} />;
  }

  if (field?.name === 'gl2_source_input') {
    return <InputFilterFieldValue fieldName={fieldName} inputOptions={inputOptions} />;
  }

  return (
    <Field name={fieldName} required>
      {({ field: { name, value, onChange }, meta: { error } }) => (
        <FieldContainer>
          <Input
            type="text"
            id={name}
            name={name}
            error={error}
            formGroupClassName=""
            placeholder="Field value"
            aria-label="Field value"
            required
            onChange={onChange}
            value={value}
          />
        </FieldContainer>
      )}
    </Field>
  );
}

export default DataLakeFilterValueField;
