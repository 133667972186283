import * as React from 'react';
import { useField } from 'formik';

import { MultiSelect } from 'components/common';
import { ControlLabel } from 'components/bootstrap';
import { Col } from 'security-app/components/common/FormBaseStyles';

import { ErrorMessage } from './ErrorMessage';

type Props<T> = {
  field: any;
  options: { label: string; value: T }[];
  label?: string;
  matchProp?: 'label' | 'value';
  disabled?: boolean;
  multiple?: boolean;
};

function FormikMultiSelect<T>({
  field,
  options,
  label = undefined,
  matchProp = 'value',
  disabled = false,
  multiple = false,
}: Props<T>) {
  const [{ name, value }, { touched, error }, { setValue, setTouched }] = useField({ name: field.name, multiple });

  const onChange = React.useCallback(
    (newValue: string) => {
      setValue(newValue.split(','));
    },
    [setValue],
  );

  return (
    <Col $fullWidth $width="100%" $gap="0.3rem" $align="stretch">
      {label && <ControlLabel>{label}</ControlLabel>}
      <MultiSelect
        id={name}
        inputProps={{ 'aria-label': name }}
        name={name}
        matchProp={matchProp}
        onChange={onChange}
        onBlur={() => setTouched(true)}
        value={value.join(',')}
        disabled={disabled}
        options={options}
        menuPlacement="bottom"
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Col>
  );
}

export default FormikMultiSelect;
