import React, { useCallback, useState } from 'react';

import { Button } from 'components/bootstrap';
import type Parameter from 'views/logic/parameters/Parameter';

import ReportParameters from '../report-contents-page/ReportParameters';

type ParameterValues = { [key: string]: any };
type Props = {
  parameters: Array<Parameter>;
  parameterSearchIds: { [parameterName: string]: string };
  parameterValues: ParameterValues;
  onSubmit: (newParameterValues: ParameterValues) => void;
};

const MissingParametersForWidgetPreview = ({
  parameters,
  parameterValues: initialParameterValues,
  onSubmit,
  parameterSearchIds,
}: Props) => {
  const [parameterValues, setParameterValues] = useState(initialParameterValues);
  const addParameterValue = useCallback(
    (name, value) => setParameterValues((oldParameterValues) => ({ ...oldParameterValues, [name]: value })),
    [],
  );
  const _onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(parameterValues);
    },
    [onSubmit, parameterValues],
  );

  return (
    <form id="report-widget-preview-parameters" onSubmit={_onSubmit}>
      <p>This widget is requiring mandatory parameters for execution. Please complete the missing values below.</p>
      <ReportParameters
        parameters={parameters}
        parameterValues={parameterValues}
        onChange={addParameterValue}
        parameterSearchIds={parameterSearchIds}
      />
      <Button type="submit" form="report-widget-preview-parameters" bsStyle="success">
        Preview
      </Button>
    </form>
  );
};

export default MissingParametersForWidgetPreview;
