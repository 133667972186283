import * as React from 'react';

import { Alert } from 'components/bootstrap';
import { PaginationProvider, SelectedRowsProvider, ModalProvider } from 'common/contexts';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';
import type { ModalTypes } from 'security-app/components/SigmaRules/ImportRulesModal';
import SigmaModals from 'security-app/components/SigmaRules/ImportRulesModal';
import SearchRow from 'security-app/components/SigmaRules/SearchRow';
import List from 'security-app/components/SigmaRules/List';
import { TextOverflowEllipsis, Spinner } from 'components/common';

import useThreatRelatedSigmaRules, { sigmaRulesQuery } from './useThreatRelatedSigmaRules';

import type { CoverageEntity } from '../types';

const SigmaRulesEnable = ({ coverageEntity }: { coverageEntity: CoverageEntity }) => {
  const { loadingRules, notEnabledRules, allRules } = useThreatRelatedSigmaRules(coverageEntity);

  if (loadingRules) {
    return <Spinner />;
  }

  return (
    <PaginationProvider initQuery={sigmaRulesQuery(coverageEntity)}>
      <SelectedRowsProvider<SigmaRuleListAPIType>>
        <ModalProvider<ModalTypes>>
          {allRules.length > 0 && notEnabledRules.length === 0 ? (
            <Alert bsStyle="success">All related Sigma Rules have been activated</Alert>
          ) : (
            <>
              <SearchRow hideSearchForm visibleBulkActions={['enable', 'disable']} />
              <List
                visibleColumns={['title', 'enabled']}
                displayRowActions={false}
                onlyMatchingSystemConfig
                renderRowTitle={(rule) => <TextOverflowEllipsis>{rule.title}</TextOverflowEllipsis>}
              />
            </>
          )}
          <SigmaModals />
        </ModalProvider>
      </SelectedRowsProvider>
    </PaginationProvider>
  );
};

export default SigmaRulesEnable;
