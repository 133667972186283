import * as React from 'react';

import type { Stream } from 'stores/streams/StreamsStore';
import type { ColumnRenderer } from 'components/common/EntityDataTable/types';

import DataLakeCell from './components/DataLakeCell';

const dataLakeCustomColumnRenderer: { data_lake: ColumnRenderer<Stream> } = {
  data_lake: {
    renderCell: (_data_lake: boolean, stream: Stream) => <DataLakeCell stream={stream} />,
    staticWidth: 130,
  },
};

export default dataLakeCustomColumnRenderer;
