import styled, { css } from 'styled-components';

import { SortIcon } from 'components/common';
import { Button, Panel } from 'components/bootstrap';

export const StyledHorizontalDl = styled.dl`
  margin: 10px 0;
  > dt {
    clear: left;
    float: left;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
  }
  > *:not(dt) {
    margin-bottom: 10px;
    margin-left: 140px;
  }
`;

export const StyledPanel = styled(Panel)<{ bsStyle: string }>(
  ({ bsStyle = 'default', theme }) => css`
    &.panel {
      background-color: ${theme.colors.global.contentBackground};
      .panel-heading {
        color: ${theme.colors.variant.darker[bsStyle]};
      }
    }
  `,
);

export const StyledSortIcon = styled(SortIcon)`
  width: 12px;
  margin-left: 5px;
  line-height: 1;
`;

export const QueryHelpButton = styled(Button)`
  padding: 6px 8px;
`;
