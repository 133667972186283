import * as React from 'react';
import { useCallback, useState } from 'react';

import { Button, Row } from 'components/bootstrap';
import {
  useInputSetupWizard,
  ButtonCol,
  StepWrapper,
  useInputSetupWizardSteps,
  useInputSetupWizardStepsHelper,
  INPUT_WIZARD_STEPS,
  INPUT_WIZARD_FLOWS,
  OPEN_FLOW_STEPS,
} from 'components/inputs/InputSetupWizard';

import SelectSpotlightPacks from './components/SelectSpotlightPacks';
import SelectProcessingPacks from './components/SelectProcessingPacks';

import type { EnterpriseStepsData } from '../types';
import useFilterIlluminatePacks from '../hooks/useFilterIlluminatePacks';

export const ILLUMINATE_PACK_TYPES = {
  PROCESSING: 'PROCESSING',
  SPOTLIGHT: 'SPOTLIGHT',
} as const;

export type IlluminatePackType = (typeof ILLUMINATE_PACK_TYPES)[keyof typeof ILLUMINATE_PACK_TYPES];

const SelectIlluminateStep = () => {
  const currentStepName = INPUT_WIZARD_STEPS.SELECT_ILLUMINATE;
  const [shownSection, setShownSection] = useState<IlluminatePackType>(ILLUMINATE_PACK_TYPES.PROCESSING);
  const [selectedProcessingPacks, setSelectedProcessingPacks] = useState<Array<string>>([]);
  const [selectedSpotlightPacks, setSelectedSpotlightPacks] = useState<Array<string>>([]);
  const [touchedProcessingPacks, setTouchedProcessingPacks] = useState<boolean>(false);
  const [touchedSpotlightPacks, setTouchedSpotlightPacks] = useState<boolean>(false);
  const {
    goToPreviousStep,
    goToNextStep,
    orderedSteps,
    setOrderedSteps,
    activeStep,
    setActiveStep,
    wizardData,
    setWizardData,
  } = useInputSetupWizard();
  const { data: processingPacksData, isLoading: isLoadingProcessingPacks } = useFilterIlluminatePacks({
    type: ILLUMINATE_PACK_TYPES.PROCESSING,
    inputType: wizardData.input?.type,
  });
  const { data: spotlightPacksData, isLoading: isLoadingSpotlightPacksData } = useFilterIlluminatePacks(
    {
      type: ILLUMINATE_PACK_TYPES.SPOTLIGHT,
    },
    shownSection === ILLUMINATE_PACK_TYPES.PROCESSING,
  );
  const { stepsData, setStepsData } = useInputSetupWizardSteps<EnterpriseStepsData>();
  const { checkHasNextStep, checkHasPreviousStep, updateStepData, getStepData } =
    useInputSetupWizardStepsHelper<EnterpriseStepsData>();
  const hasPreviousStep = checkHasPreviousStep(orderedSteps, activeStep);
  const hasNextStep = checkHasNextStep(orderedSteps, activeStep);

  const enabledProcessingPacks = processingPacksData?.filter((pack) => pack.enabled).map((pack) => pack.pack_id);

  const determineSelectedProcessingPacks = useCallback(() => {
    if (touchedProcessingPacks) {
      return selectedProcessingPacks;
    }

    const previouslySelectedPacks = getStepData(stepsData, currentStepName, 'selectedIlluminatePacks');

    if (previouslySelectedPacks?.length > 0) {
      return previouslySelectedPacks;
    }

    return [];
  }, [currentStepName, selectedProcessingPacks, stepsData, touchedProcessingPacks, getStepData]);

  const availableSpotlightPacks = useCallback(
    () =>
      spotlightPacksData.filter(
        (spotlightPack) =>
          spotlightPack.requirements.every((requiredPackId) => enabledProcessingPacks.includes(requiredPackId)) ||
          spotlightPack.requirements.some((requiredPackId) =>
            determineSelectedProcessingPacks().includes(requiredPackId),
          ),
      ),
    [enabledProcessingPacks, spotlightPacksData, determineSelectedProcessingPacks],
  );

  const preselectedSpotlightPacks = useCallback(
    () =>
      determineSelectedProcessingPacks().map(
        (selectedProcessingPackId) =>
          availableSpotlightPacks().find((spotlightPack) =>
            spotlightPack.requirements.includes(selectedProcessingPackId),
          )?.pack_id,
      ),
    [determineSelectedProcessingPacks, availableSpotlightPacks],
  );

  const determineSelectedSpotlightPacks = useCallback(() => {
    if (touchedSpotlightPacks) {
      return selectedSpotlightPacks;
    }

    return [...preselectedSpotlightPacks(), ...selectedSpotlightPacks];
  }, [preselectedSpotlightPacks, selectedSpotlightPacks, touchedSpotlightPacks]);

  const onNextStep = () => {
    if (shownSection === ILLUMINATE_PACK_TYPES.PROCESSING) {
      setShownSection(ILLUMINATE_PACK_TYPES.SPOTLIGHT);
    } else {
      setStepsData(
        updateStepData(stepsData, currentStepName, {
          selectedIlluminatePacks: determineSelectedProcessingPacks(),
          selectedSpotlightPacks: determineSelectedSpotlightPacks(),
        }),
      );
      goToNextStep();
    }
  };

  const resetSpotlightPacks = () => {
    setTouchedSpotlightPacks(false);
    setSelectedSpotlightPacks([]);
  };

  const onPreviousStep = () => {
    if (shownSection === ILLUMINATE_PACK_TYPES.SPOTLIGHT) {
      resetSpotlightPacks();
      setShownSection(ILLUMINATE_PACK_TYPES.PROCESSING);
    } else {
      goToPreviousStep();
    }
  };

  const onSkip = () => {
    setOrderedSteps(OPEN_FLOW_STEPS[INPUT_WIZARD_FLOWS.NON_ILLUMINATE]);
    setActiveStep(OPEN_FLOW_STEPS[INPUT_WIZARD_FLOWS.NON_ILLUMINATE][0]);
    setWizardData({ ...wizardData, flow: INPUT_WIZARD_FLOWS.NON_ILLUMINATE });
  };

  const noProcessingPackSelected = determineSelectedProcessingPacks().length <= 0;

  return (
    <StepWrapper>
      {shownSection === ILLUMINATE_PACK_TYPES.PROCESSING && (
        <SelectProcessingPacks
          selectedProcessingPacks={determineSelectedProcessingPacks()}
          setSelectedProcessingPacks={setSelectedProcessingPacks}
          processingPacksData={processingPacksData}
          isLoadingProcessingPacks={isLoadingProcessingPacks}
          setTouched={setTouchedProcessingPacks}
        />
      )}
      {shownSection === ILLUMINATE_PACK_TYPES.SPOTLIGHT && (
        <SelectSpotlightPacks
          spotlightPacksData={availableSpotlightPacks()}
          isLoadingSpotlightPacksData={isLoadingSpotlightPacksData}
          selectedSpotlightPacks={determineSelectedSpotlightPacks()}
          setSelectedSpotlightPacks={setSelectedSpotlightPacks}
          setTouched={setTouchedSpotlightPacks}
        />
      )}
      <Row>
        <ButtonCol md={12}>
          {(hasPreviousStep || shownSection === ILLUMINATE_PACK_TYPES.SPOTLIGHT) && (
            <Button onClick={onPreviousStep}>Back</Button>
          )}
          <Button onClick={onSkip}>Skip Illuminate</Button>
          {hasNextStep && (
            <Button onClick={onNextStep} bsStyle="primary" disabled={noProcessingPackSelected}>
              Next
            </Button>
          )}
        </ButtonCol>
      </Row>
    </StepWrapper>
  );
};

export default SelectIlluminateStep;
