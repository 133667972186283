import __request__ from 'routing/request';
interface EntityScopeResponse {
    readonly is_deletable: boolean;
    readonly is_mutable: boolean;
}
interface EntityScopes {
    readonly entity_scopes: {
        readonly [_key: string]: EntityScopeResponse;
    };
}
/**
 * Generate a mapping of available Entity Scopes
 */
export function getAllEntityScopes(): Promise<EntityScopes> {
    return __request__('GET', '/entity_scopes', null, {}, {
        'Accept': ['application/json']
    });
}
