import fetch from 'logic/rest/FetchProvider';
import inputsUrl from 'forwarder/forwarders/inputsUrl';

const fetchForwarderInput = (inputId: string) =>
  fetch(
    'GET',
    inputsUrl({
      segments: ['input', inputId],
    }),
  );
export default fetchForwarderInput;
