import React from 'react';
import { useFormikContext } from 'formik';

import ConfirmLeaveDialog from 'components/common/ConfirmLeaveDialog';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';

type Props = {
  ignoredRoutes: Array<string>;
};

const WindowLeaveMessage = ({ ignoredRoutes }: Props) => {
  const { dirty } = useFormikContext<ReportFormValues>();

  return dirty ? (
    <ConfirmLeaveDialog
      question="Are you sure you want to leave the page? Any unsaved changes will be lost."
      ignoredRoutes={ignoredRoutes}
    />
  ) : null;
};

export default WindowLeaveMessage;
