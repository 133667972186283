import * as React from 'react';
import { useContext } from 'react';

import type { Message } from 'views/components/messagelist/Types';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import FieldType from 'views/logic/fieldtypes/FieldType';
import { defaultCompare } from 'logic/DefaultCompare';
import { isPermitted } from 'util/PermissionsMixin';
import InteractiveContext from 'views/components/contexts/InteractiveContext';
import WatchlistDomain from 'watchlist/domainActions/WatchlistDomain';
import WatchlistStatusContext from 'watchlist/contexts/WatchlistStatusContext';
import type { WatchlistStatus } from 'watchlist/actions/WatchlistActions';
import defaultWatchlists from 'security-content/mappings/watchlists';
import type { Watchlists } from 'security-content/types';
import useLicenseCheck from 'license/useLicenseCheck';
import useCurrentUser from 'hooks/useCurrentUser';

import WatchlistIndicator from './WatchlistIndicator';

const getWatchlistByType = (watchlists: Watchlists, type: string) => {
  const watchlist = Object.values(watchlists).find(({ fieldType }) => fieldType === type);

  if (!watchlist) {
    throw Error(`Could not find watchlist with field type ${type}`);
  }

  return watchlist.title;
};

const removeFieldFromWatchlist = (
  fieldName: string,
  fieldValue: string,
  watchlistTitle: string,
  watchlistFieldType: string,
) => {
  // eslint-disable-next-line no-alert
  if (window.confirm(`Are you sure you want to remove the ${fieldName}: ${fieldValue} from the ${watchlistTitle}?`)) {
    WatchlistDomain.delete(watchlistFieldType, fieldValue);
  }
};

const getEnrichedFields = (
  watchlists: Watchlists,
  message: Message,
  queryFields,
  fieldsWatchlistStatus: WatchlistStatus,
) => {
  const fields = Object.entries(fieldsWatchlistStatus).reduce(
    (enrichedFields, [watchlistFieldType, watchlistFields]) => {
      const watchlistTitle = getWatchlistByType(watchlists, watchlistFieldType);
      const enrichedWatchlistFields = watchlistFields.map((fieldName) => {
        const fieldValue = message.fields[fieldName];
        const { type: fieldType } = queryFields.find(
          (t) => t.name === fieldName,
          undefined,
          FieldTypeMapping.create(fieldName, FieldType.Unknown),
        );

        return { fieldType, fieldName, fieldValue, watchlistTitle, watchlistFieldType };
      });

      return [...enrichedFields, ...enrichedWatchlistFields];
    },
    [],
  );

  return fields.sort(({ fieldName: fieldName1 }, { fieldName: fieldName2 }) => defaultCompare(fieldName1, fieldName2));
};

type Props = {
  message: Message;
  watchlists?: Watchlists;
};

const WatchlistAugmentation = ({ message, watchlists = defaultWatchlists }: Props) => {
  const { status: watchlistStatus, isLoading } = useContext(WatchlistStatusContext) || {};
  const {
    security: { isValid: isValidSecurityLicense },
  } = useLicenseCheck();
  const fieldTypes = useContext(FieldTypesContext);
  const currentUser = useCurrentUser();
  const interactive = useContext(InteractiveContext);
  const allowRemoval = interactive && isPermitted(currentUser?.permissions, 'watchlist:edit');

  if (!isValidSecurityLicense || isLoading || !watchlistStatus || Object.values(watchlistStatus).length === 0) {
    return null;
  }

  const queryFields = fieldTypes.currentQuery;
  const enrichedFields = getEnrichedFields(watchlists, message, queryFields, watchlistStatus);

  return (
    <>
      <dt>Found on watchlist</dt>

      {enrichedFields.map(({ fieldName, fieldValue, fieldType, watchlistTitle, watchlistFieldType }) => (
        <WatchlistIndicator
          key={`${watchlistTitle}-${fieldName}`}
          title={watchlistTitle}
          fieldName={fieldName}
          fieldValue={fieldValue}
          message={message}
          realFieldType={fieldType}
          allowRemoval={allowRemoval}
          onDelete={() => removeFieldFromWatchlist(fieldName, fieldValue, watchlistTitle, watchlistFieldType)}
        />
      ))}
    </>
  );
};

export default WatchlistAugmentation;
