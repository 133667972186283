import React from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import {Input} from 'components/bootstrap';

const Operator = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.sm};
`);
const OperatorField = () => (
    <Field name="operator">
      {({ field }) => (
        <Operator>
          <Input type="radio"
                 name={field.name}
                 checked={field.value === 'AND'}
                 onChange={field.onChange}
                 value="AND"
                 label="AND"
                 formGroupClassName="" />
          <Input type="radio"
                 name={field.name}
                 checked={field.value === 'OR'}
                 onChange={field.onChange}
                 value="OR"
                 label="OR"
                 formGroupClassName="" />
        </Operator>
      )}
    </Field>
  );

export default OperatorField;
