import * as React from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import { Col, Row, Badge } from 'common/components';
import { useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import type { Pack } from 'illuminate/types';

const InputWrapper = styled.div`
  & > .form-group {
    margin: 0;
  }
`;

type Props = {
  packs: Array<Pack>;
  dependencies?: boolean;
};

function DependsOrRequiredBy({ packs, dependencies = false }: Props) {
  const selectedPacks = useSelectedRows();
  const selectedPacksDispatch = useSelectedRowsDispatch();

  const isSelected = React.useCallback((pack: Pack) => !!selectedPacks.find((sPack: Pack) => pack.pack_id === sPack.pack_id), [selectedPacks]);

  const onClick = (pack: Pack) => (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedPacksDispatch({ type: 'add', payload: [pack], key: 'pack_id' });
    } else {
      selectedPacksDispatch({ type: 'remove', payload: [pack], key: 'pack_id' });
    }
  }

  return (
    <>
      {packs.length > 0 && (
        <Col $gap="xs">
          <strong>{dependencies ? 'Dependencies' : 'Required by'}:</strong>
          {packs.map((rPack: Pack) => (
            <Row key={`${dependencies ? 'depends-on' : 'required-by'}-${rPack.pack_id}`} $align="center" $gap="xs">
              <InputWrapper>
                <Input
                  id={`${dependencies ? 'depends-on' : 'required-by'}-${rPack.pack_id}`}
                  type="checkbox"
                  name={`${dependencies ? 'depends-on' : 'required-by'}-${rPack.pack_id}`}
                  checked={isSelected(rPack)}
                  value={isSelected(rPack)}
                  onClick={onClick(rPack)}
                />
              </InputWrapper>
              <span>{rPack.title}</span>
              <Badge variant={rPack.enabled ? 'success' : 'danger'}>{rPack.enabled ? 'enabled' : 'not enabled'}</Badge>
            </Row>
          ))}
        </Col>
      )}
    </>
  );
}

export default DependsOrRequiredBy;
