import React from 'react';

import { Link } from 'components/common/router';

const IlluminateCSVFileAdapterDocumentation = () => (
  <div>
    <p>The Illuminate Customizable data adapter reads key value pairs from a CSV file provided by Illuminate.</p>
    <p>
      The adapter configuration cannot be modified, however custom values can be added from the{' '}
      <Link to="/illuminate/customize">Illuminate Customize</Link> page.
    </p>
    <p>If the same key exists in the customizations and the CSV file, the custom value will take precedence.</p>
  </div>
);

export default IlluminateCSVFileAdapterDocumentation;
