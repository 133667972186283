import { useCallback } from 'react';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { useGetPacksSummary } from 'illuminate/hooks/usePacksAPI';
import type { SearchParams } from 'stores/PaginationTypes';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import type { PackType } from 'illuminate/components/content-hub/types';

const useSendPacksListTelemetry = () => {
  const sendTelemetry = useSendTelemetry();
  const {
    packsSummary: { cached_version, current_version },
  } = useGetPacksSummary();

  return useCallback(
    (searchParams: SearchParams & { includeDeps?: boolean }, response) => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.PACKS_CONTENT_HUB_SEARCH, {
        app_pathname: 'illuminate',
        app_section: 'packs',
        event_details: {
          illuminate_enterprise: true,
          illuminate_version: cached_version || current_version,
          illuminate_action: 'search',
          illuminate_search_query: searchParams.query,
          illuminate_search_result: response.list.map((pack: PackType) => ({
            id: pack.pack_id,
            title: pack.title,
            version: pack.version,
          })),
        },
      });
    },
    [cached_version, current_version, sendTelemetry],
  );
};

export default useSendPacksListTelemetry;
