import * as React from 'react';
import { Field } from 'formik';

import { Input } from 'components/bootstrap';
import { loadStreamsDataLakeOptions } from 'data-lake/hooks/useDataLakes';
import PaginatedSelect from 'components/common/Select/PaginatedSelect';

type Props = {
  disabled?: boolean;
  value?: Array<string>;
  onChange: (newStreamIds: Array<string>) => void;
};

const StreamSelectField = ({ disabled = false, value = [], onChange }: Props) => {
  const selectedStreams = value.join(',');
  const placeholder = 'Select streams the retrieval operation should include.';

  const handleChange = (selected: string) => {
    onChange(selected === '' ? [] : selected.split(','));
  };

  return (
    <Field name="stream_ids">
      {({ name }) => (
        <Input id={name} label="Streams">
          <PaginatedSelect
            placeholder={placeholder}
            displayKey="key"
            disabled={disabled}
            inputId="streams-filter"
            value={selectedStreams}
            onLoadOptions={loadStreamsDataLakeOptions}
            multi={false}
            onChange={handleChange}
          />
        </Input>
      )}
    </Field>
  );
};

export default StreamSelectField;
