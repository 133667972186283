import type * as React from 'react';

import type Parameter from 'views/logic/parameters/Parameter';
import usePluginEntities from 'hooks/usePluginEntities';
import type { ValidationStateEntry } from 'enterprise/parameters/components/ParameterDeclarationForm';

export type ParameterDeclarationComponentProps<T = Parameter> = {
  idx: number;
  validationState: ValidationStateEntry;
  parameter: T;
  onChange: (name: string, value: any) => void;
  onValidate: (idx: number, state: ValidationStateEntry) => void;
};

export type ParameterInputComponentProps<T = Parameter> = {
  inputSize?: 'small' | 'large';
  onBlur?: () => void;
  onChange: (name: string, value: any, submit?: boolean) => void;
  parameter: T;
  searchId: string;
  value: any;
};

export interface ParameterType<T = Parameter> {
  type: string;
  title: string;
  component: React.ComponentType<ParameterInputComponentProps<T>>;
  editComponent: React.ComponentType<ParameterDeclarationComponentProps<T>>;
  editPermissions?: Array<string>;
}

export const useParameterTypes = () => usePluginEntities('parameterDataTypes');

export const useParameterType = (parameterType: string) => {
  const _parameterType = (parameterType === 'any' ? 'value-parameter-v1' : parameterType) ?? 'value-parameter-v1';

  return useParameterTypes().find(({ type }) => type === _parameterType);
};
