import * as React from 'react';
import { Field } from 'formik';

import { Input } from 'components/bootstrap';

const toFormStateValue = (newMinute: string | number) => {
  let minute = String(newMinute).slice(-2);

  if (String(minute).length === 1) {
    minute = `0${minute}`;
  }

  return `00:${minute}`;
};

export const fromFormStateValue = (timeString: string) => timeString?.slice(-2) ?? '';

const onTimeChange =
  (onChange: (event: { target: { value: string; name: string } }) => void, name: string) =>
  ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ target: { value: toFormStateValue(newValue), name } });
  };

type Props = {
  id: string;
  required?: boolean;
  min?: string | number;
  max?: string | number;
};

const MinuteOfHourInput = ({ id, required = false, min = 0, max = 59 }: Props) => (
  <Field name={id}>
    {({ field: { name, value, onChange, onBlur }, meta: { error, touched } }) => (
      <Input
        id={name}
        name={name}
        onBlur={onBlur}
        error={error && touched ? error : undefined}
        type="number"
        placeholder="Set minute of hour"
        formGroupClassName="no-bm"
        inputDescClassName="no-bm"
        onChange={onTimeChange(onChange, name)}
        min={min}
        max={max}
        required={required}
        value={fromFormStateValue(value)}
      />
    )}
  </Field>
);

export default MinuteOfHourInput;
