import loadAsync from 'routing/loadAsync';

const Anomaly = loadAsync(() => import('security-app/pages/anomaly/Anomalies'));
const Configuration = loadAsync(() => import('security-app/pages/anomaly/DetectorConfiguration'));
const HostActivity = loadAsync(() => import('security-app/pages/HostActivity'));
const NetworkActivity = loadAsync(() => import('security-app/pages/NetworkActivity'));
const Overview = loadAsync(() => import('security-app/pages/Overview'));
const UserActivity = loadAsync(() => import('security-app/pages/UserActivity'));
const ActivityPage = loadAsync(() => import('security-app/pages/ActivityPage'));
const Sigma = loadAsync(() => import('security-app/pages/sigma/Sigma'));
const SigmaConfig = loadAsync(() => import('security-app/pages/sigma/SigmaConfig'));
const AnomalyDetectionEdit = loadAsync(() => import('security-app/pages/anomaly/AnomalyDetectionEdit'));
const DetectorConfiguration = loadAsync(() => import('security-app/pages/anomaly/DetectorConfiguration'));
const Investigations = loadAsync(() => import('security-app/pages/investigations/Investigations'));
const InvestigationDetails = loadAsync(() => import('security-app/pages/investigations/InvestigationDetails'));
const InvestigationsConfig = loadAsync(() => import('security-app/pages/investigations/InvestigationsConfig'));
const AssetsList = loadAsync(() => import('security-app/pages/assets/AssetsList'));
const AssetsConfig = loadAsync(() => import('security-app/pages/assets/AssetsConfig'));
const AssetSources = loadAsync(() => import('security-app/pages/assets/AssetSourcesList'));
const AssetSourcesForms = loadAsync(() => import('security-app/pages/assets/AssetSourceForms'));
const SecurityOverviewPage = loadAsync(() => import('security-app/pages/Welcome/SecurityOverviewPage'));
const SecurityEventsAlerts = loadAsync(() => import('security-app/pages/security-events/SecurityEventsAlerts'));
const SecurityEventsProcedures = loadAsync(
  () => import('security-app/pages/security-events/SecurityEventsProcedures')
);
const SecurityEventsDefinitions = loadAsync(
  () => import('security-app/pages/security-events/SecurityEventsDefinitions'),
);
const SecurityEventsNotifications = loadAsync(
  () => import('security-app/pages/security-events/SecurityEventsNotifications'),
);
const VulnerabilityScanners = loadAsync(() => import('security-app/pages/assets/AssetsVCList'));
const ExecutiveOverviewPage = loadAsync(() => import('security-app/pages/Welcome/ExecutiveOverviewPage'));
const BulkSecurityEventReplayPage = loadAsync(() => import('security-app/pages/BulkSecurityEventReplayPage'));

export {
  Anomaly,
  Configuration,
  DetectorConfiguration,
  HostActivity,
  NetworkActivity,
  Overview,
  UserActivity,
  ActivityPage,
  Sigma,
  SigmaConfig,
  AnomalyDetectionEdit,
  Investigations,
  InvestigationDetails,
  InvestigationsConfig,
  AssetsList,
  AssetsConfig,
  AssetSources,
  AssetSourcesForms,
  SecurityOverviewPage,
  SecurityEventsAlerts,
  SecurityEventsDefinitions,
  SecurityEventsProcedures,
  SecurityEventsNotifications,
  VulnerabilityScanners,
  ExecutiveOverviewPage,
  BulkSecurityEventReplayPage,
};
