import React from 'react';

import InvestigationsWidget from 'security-app/components/Investigations/plugin/SearchWidgets/logic/InvestigationsWidget';
import PerspectivesProvider from 'components/perspectives/contexts/PerspectivesProvider';

const WidgetProvidersByType = ({ type, children }: React.PropsWithChildren<{ type: string }>) => {
  if (type === InvestigationsWidget.type) {
    return <PerspectivesProvider>{children}</PerspectivesProvider>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default WidgetProvidersByType;
