import * as React from 'react';
import { Formik, Form } from 'formik';

import { Input, Button, ButtonToolbar } from 'components/bootstrap';

import type { InputProfile } from '../Types';

const defaultInputProfile = { id: '', title: '', description: '' };

type Props = {
  inputProfile?: InputProfile;
  buttonToolbarClassName?: string;
  buttonText?: string;
  onCancel: () => void;
  onSubmit: (inputProfile: InputProfile | { title: string; description: string }) => void;
  InputsWrapper?: React.ComponentType<any>;
  ActionsWrapper?: React.ComponentType<any>;
};

const InputProfileForm = ({
  inputProfile = defaultInputProfile,
  onCancel,
  onSubmit,
  buttonToolbarClassName,
  buttonText = 'Save',
  InputsWrapper = React.Fragment,
  ActionsWrapper = React.Fragment,
}: Props) => {
  const { title, description } = inputProfile || defaultInputProfile;
  const EffectiveInputsWrapper = InputsWrapper || React.Fragment;
  const EffectiveActionsWrapper = ActionsWrapper || React.Fragment;

  return (
    <Formik initialValues={{ title, description }} onSubmit={onSubmit}>
      {({ values, handleChange, isSubmitting }) => (
        <Form role="form">
          <EffectiveInputsWrapper>
            <Input
              id="title"
              name="title"
              label="Title"
              type="text"
              value={values.title}
              onChange={handleChange}
              help="Meaningful name used to identify this Input Profile."
              required
            />
            <Input
              id="description"
              name="description"
              label="Description"
              type="text"
              value={values.description}
              onChange={handleChange}
              help="Description for this Input Profile."
            />
          </EffectiveInputsWrapper>
          <EffectiveActionsWrapper>
            <ButtonToolbar className={buttonToolbarClassName}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="submit" bsStyle="primary" disabled={isSubmitting}>
                {isSubmitting ? 'Saving your changes...' : buttonText}
              </Button>
            </ButtonToolbar>
          </EffectiveActionsWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default InputProfileForm;
