import __request__ from 'routing/request';
interface SummaryTemplateResponseMap {
    readonly [_key: string]: SummaryTemplateResponse;
}
interface PaginatedResponse_MessageSummaryTemplate {
    readonly [_key: string]: Object;
}
interface MessageSummaryTemplateRequest {
    readonly summary: string;
    readonly description: string;
    readonly template_id: string;
    readonly title: string;
}
interface MessageSummaryTemplate {
    readonly summary: string;
    readonly description: string;
    readonly template_id: string;
    readonly id: string;
    readonly title: string;
}
interface SummaryTemplateResponse {
    readonly summary: string;
}
/**
 * Create a new Message Summary Template
 * @param template template
 */
export function createSummaryTemplate(template?: MessageSummaryTemplateRequest): Promise<MessageSummaryTemplate> {
    return __request__('POST', '/plugins/org.graylog.plugins.contentui/summary_templates', template, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get All Message Summary Templates
 */
export function getAll(page: number = 1, page_size: number = 50): Promise<PaginatedResponse_MessageSummaryTemplate> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/summary_templates', null, { 'page': page, 'page_size': page_size }, {
        'Accept': ['application/json']
    });
}
/**
 * Get message summary template mapping as used by UI
 */
export function getTemplateMapping(): Promise<SummaryTemplateResponseMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/summary_templates/ui_mapping', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get Message Summary Template by ID
 * @param id id
 */
export function getById(id?: string): Promise<MessageSummaryTemplate> {
    return __request__('GET', `/plugins/org.graylog.plugins.contentui/summary_templates/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing Message Summary Template
 * @param id id
 * @param template template
 */
export function updateSummaryTemplate(id?: string, template?: MessageSummaryTemplateRequest): Promise<MessageSummaryTemplate> {
    return __request__('PUT', `/plugins/org.graylog.plugins.contentui/summary_templates/${id}`, template, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing Summary Template
 * @param id id
 */
export function deleteSummaryTemplate(id?: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.contentui/summary_templates/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
