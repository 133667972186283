import * as React from 'react';
import styled from 'styled-components';

import Spinner from 'components/common/Spinner';
import type { ReportSummary } from 'report/types';
import Timestamp from 'components/common/Timestamp';
import { TextOverflowEllipsis } from 'components/common';

type Props = {
  report: ReportSummary;
};

const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  gap: 5px;
`;

const Col = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
`;

const Value = styled.span`
  font-weight: 600;
  flex: 1;
  max-width: 100%;
`;

const Label = styled.span`
  font-weight: 300;
`;

// eslint-disable-next-line react/jsx-no-useless-fragment
const IfPresent = ({ children, value }: React.PropsWithChildren<{ value: any }>) =>
  value ? <>{children}</> : <i>Not available.</i>;

const ReportContents = ({ report }: Props) => {
  if (!report) {
    return <Spinner />;
  }

  return (
    <Row className="content">
      <Col>
        <Value>
          <IfPresent value={report.subtitle}>
            <TextOverflowEllipsis>{report.subtitle}</TextOverflowEllipsis>
          </IfPresent>
        </Value>
        <Label>Subtitle</Label>
      </Col>
      <Col>
        <Value>
          <TextOverflowEllipsis>{report.timezone}</TextOverflowEllipsis>
        </Value>
        <Label>Timezone</Label>
      </Col>
      <Col>
        {'owner' in report && (
          <>
            <Value>
              <IfPresent value={report.owner_full_name}>{report.owner_full_name}</IfPresent>
            </Value>
            <Label>Owner</Label>
          </>
        )}
      </Col>
      <Col>
        {'created_at' in report && (
          <>
            <Value>
              <IfPresent value={report.created_at}>
                <Timestamp dateTime={report.created_at} />
              </IfPresent>
            </Value>
            <Label>Created</Label>
          </>
        )}
      </Col>
      <Col>
        {'last_generated_at' in report && (
          <>
            <Value>
              <IfPresent value={report.last_generated_at}>
                <Timestamp dateTime={report.last_generated_at} />
              </IfPresent>
            </Value>
            <Label>Last generated</Label>
          </>
        )}
      </Col>
      <Col>
        <Value>{report.widget_count}</Value>
        <Label>Widgets</Label>
      </Col>
    </Row>
  );
};

export default ReportContents;
