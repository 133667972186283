import InvestigationsListSortConfig from './InvestigationsListSortConfig';

import InvestigationsWidget from '../InvestigationsWidget';

const InvestigationsListConfigGenerator = ({ config: { filters: attributes, sort } }: InvestigationsWidget) => [
  {
    sort: new InvestigationsListSortConfig(sort.field, sort.direction),
    type: InvestigationsWidget.type,
    attributes,
  },
];

export default InvestigationsListConfigGenerator;
