import { useQuery } from '@tanstack/react-query';

import type { ArchiveConfiguration } from 'archive/types';
import { fetchPeriodically } from 'logic/rest/FetchProvider';
import { defaultOnError } from 'util/conditional/onError';
import { qualifyUrl } from 'util/URLUtils';

export const urlPrefix = '/plugins/org.graylog.plugins.archive';
export const urlSuffix = '/config';
const url = qualifyUrl(`${urlPrefix}${urlSuffix}`);

export const ARCHIVE_CONFIG_QUERY_KEY = 'archiveConfig';

const refetchInterval = 5000;
const fetchArchiveConfig = async (): Promise<ArchiveConfiguration> => fetchPeriodically('GET', url);

const useArchiveConfig = () =>
  useQuery(
    [ARCHIVE_CONFIG_QUERY_KEY],
    () => defaultOnError(fetchArchiveConfig(), 'Loading archive config failed with status'),
    {
      retry: 0,
      refetchInterval: refetchInterval,
      keepPreviousData: true,
      notifyOnChangeProps: ['data', 'error'],
      initialData: {
        archive_path: '',
        max_segment_size: 0,
        segment_filename_prefix: '',
        segment_compression_type: 'NONE',
        metadata_filename: '',
        histogram_bucket_size: 0,
        restore_index_batch_size: 0,
        excluded_streams: [],
        segment_checksum_type: 'NONE',
        backend_id: '',
        archive_failure_threshold: 0,
        retention_time: 0,
        restrict_to_leader: true,
        parallelize_archive_creation: false,
      },
    },
  );

export default useArchiveConfig;
