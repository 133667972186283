// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QGVj1ZrEt3wXpAcQup_z {
  margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/views/components/searchbar/saved-search/SavedSearchForm.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[":local(.button) {\n  margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `QGVj1ZrEt3wXpAcQup_z`
};
export default ___CSS_LOADER_EXPORT___;
