import React from 'react';
import styled from 'styled-components';

import { LinkContainer } from 'components/common/router';
import { EntityListItem, IfPermitted } from 'components/common';
import { Button, Col, DropdownButton, MenuItem, DeleteMenuItem } from 'components/bootstrap';
import { ConfigurationWell } from 'components/configurationforms';
import Routes from 'routing/Routes';
import recentMessagesTimeRange from 'util/TimeRangeHelper';

import ForwarderInputStateControl from './ForwarderInputStateControl';

import ForwarderInputThroughput from '../metrics/ForwarderInputThroughput';
import ForwarderInputMetricContainer from '../metrics/ForwarderInputMetricContainer';
import ForwarderInputStateBadge from '../states/ForwarderInputStateBadge';
import type { Forwarder, Input, InputDescription } from '../Types';

type Props = {
  forwarder?: Forwarder;
  input: Input;
  inputDescription: InputDescription;
  expanded: boolean;
  enableEdit: boolean;
  onToggleDetails: (id: string) => void;
  onDeleteInput: (input: Input) => void;
  onEditInput: (input: Input) => void;
};

const StyledButton = styled(Button)`
  padding-left: 0;
  vertical-align: baseline;
`;

const ForwarderInputListItem = ({
  forwarder,
  input,
  inputDescription,
  expanded,
  enableEdit,
  onToggleDetails,
  onDeleteInput,
  onEditInput,
}: Props) => {
  const _toggleDetail = () => {
    onToggleDetails(input.id);
  };

  const showMetrics = !enableEdit;

  const actions = (
    <>
      {forwarder && (
        <IfPermitted permissions="forwarderinputs:changestate">
          <ForwarderInputStateControl input={input} forwarder={forwarder} />
        </IfPermitted>
      )}
      <IfPermitted permissions="searches:relative">
        <LinkContainer
          key={`received-messages-${input.id}`}
          to={Routes.search(`gl2_source_input:${input.id}`, recentMessagesTimeRange())}>
          <Button>Show received messages</Button>
        </LinkContainer>
      </IfPermitted>
      {enableEdit && (
        <DropdownButton
          key={`more-actions-${input.id}`}
          title="More actions"
          id={`more-actions-dropdown-${input.id}`}
          pullRight>
          {inputDescription && (
            <IfPermitted permissions={`forwarderinputs:edit:${input.id}`}>
              <MenuItem
                key={`edit-input-${input.id}`}
                onSelect={() => {
                  onEditInput(input);
                }}>
                Edit input
              </MenuItem>
            </IfPermitted>
          )}
          <IfPermitted permissions={`forwarderinputs:delete:${input.id}`}>
            {inputDescription && <MenuItem key={`divider-${input.id}`} divider />}
            <DeleteMenuItem
              key={`delete-input-${input.id}`}
              onSelect={() => {
                onDeleteInput(input);
              }}>
              Delete input
            </DeleteMenuItem>
          </IfPermitted>
        </DropdownButton>
      )}
    </>
  );

  const _getDescription = forwarder ? (
    <>
      <ForwarderInputMetricContainer input={input} forwarder={forwarder} />
      {input.configuration && input.configuration.bind_address && (
        <span>
          Listening on {input.configuration.bind_address}:{input.configuration.port}.&nbsp;
        </span>
      )}
      <StyledButton bsStyle="link" bsSize="small" onClick={_toggleDetail}>
        Show more
      </StyledButton>
    </>
  ) : (
    <StyledButton bsStyle="link" bsSize="small" onClick={_toggleDetail}>
      Show configuration
    </StyledButton>
  );

  const additionalContent = (
    <div>
      <Col md={12}>
        {expanded && (
          <div>
            <Col md={5}>
              <h3>Configuration</h3>
              <ConfigurationWell id={input.id} configuration={input.configuration} typeDefinition={inputDescription} />
            </Col>
            {showMetrics && (
              <Col md={5} mdOffset={2}>
                <h2>Metrics</h2>
                {forwarder && <ForwarderInputThroughput forwarder={forwarder} input={input} />}
              </Col>
            )}
          </div>
        )}
      </Col>
    </div>
  );

  const titleSuffixExtra = forwarder ? <ForwarderInputStateBadge forwarder={forwarder} input={input} /> : null;

  const titleSuffix = (
    <span>
      {inputDescription ? inputDescription.name : `Unknown input (${input.type})`}
      &nbsp;
      {titleSuffixExtra}
    </span>
  );

  return (
    <EntityListItem
      key={`entry-list-${input.id}`}
      title={input.title}
      titleSuffix={titleSuffix}
      description={_getDescription}
      actions={actions}
      contentRow={additionalContent}
    />
  );
};

export default ForwarderInputListItem;
