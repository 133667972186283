import { fetchTextFile, fetchBinaryFile } from 'util/FileDownloadUtils';
import { qualifyUrl } from 'util/URLUtils';
import type { Rows } from 'views/logic/searchtypes/pivot/PivotHandler';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { ExportWidgetDataRequest } from 'search/ExportWidgetAction/types';
import type InvestigationsWidget from 'security-app/components/Investigations/plugin/SearchWidgets/logic/InvestigationsWidget';
import { adjustFormat } from 'util/DateTime';
import type { Filter } from 'security-app/components/Investigations/plugin/SearchWidgets/logic/InvestigationsWidgetConfig';

export type Extension = string;

export type ExportType = 'search_type_data';

export type Result = {
  total: number;
  rows: Rows;
  effective_timerange: AbsoluteTimeRange;
};

export type InvestigationRequestBody = {
  exported_field_names: Array<string>;
  limit: number;
  filters: Filter;
  sorts: Array<{ field: string; order: 'ASC' | 'DESC' }>;
};

export const mimeTypeMapper: Record<Extension, string> = {
  csv: 'text/csv',
  json: 'application/json',
  yaml: 'application/yaml',
  xml: 'application/xml',
  xlsx: 'application/vnd.ms-excel',
  pdf: 'application/json',
};

export const BinaryFilesExtentions: Array<Extension> = ['xlsx', 'pdf'];

export const escapeFilename = (filename: string) => {
  if (!filename) {
    return filename;
  }

  // eslint-disable-next-line no-control-regex
  const reservedChars = /[<>:/\\|?*%\x00-\x1F,]/g;

  return filename
    .replace(reservedChars, '_')
    .trim()
    .replace(/[. ]+$/, '');
};

const getUrl = (fileName: string) => qualifyUrl(`views/search/pivot/export/${escapeFilename(fileName)}`);
const getPdfUrl = () => qualifyUrl('plugins/org.graylog.plugins.widgetexport/widgetexport/export');
const getInvestigationUrl = (fileName: string) =>
  qualifyUrl(`/plugins/org.graylog.plugins.securityapp.investigations/investigations/export/${fileName}`);

export const exportWidget = (widgetTitle: string, widgetResults: Result, extension: Extension) => {
  const fileName = `${widgetTitle}_${widgetResults?.effective_timerange?.from}-${widgetResults?.effective_timerange?.to}.${extension}`;
  const mimeType = mimeTypeMapper[extension];

  if (BinaryFilesExtentions.includes(extension))
    return fetchBinaryFile('POST', getUrl(fileName), widgetResults, mimeType, fileName);

  return fetchTextFile('POST', getUrl(fileName), widgetResults, mimeType, fileName);
};

export const exportPdfWidget = (
  widgetTitle: string,
  widget: ExportWidgetDataRequest,
  widgetResults: Result,
  extension: Extension,
  exportType: ExportType,
) => {
  const fileName = `${widgetTitle}_${widgetResults?.effective_timerange?.from}-${widgetResults?.effective_timerange?.to}.${extension}`;
  const mimeType = mimeTypeMapper[extension];

  const requestBody: { widget: ExportWidgetDataRequest; data?: { results: Result; type: ExportType } } = { widget };

  if (widgetResults && exportType) {
    requestBody.data = { results: widgetResults, type: exportType };
  }

  const requestFn = BinaryFilesExtentions.includes(extension) ? fetchBinaryFile : fetchTextFile;

  return requestFn('POST', getPdfUrl(), requestBody, mimeType, fileName);
};

export const downloadNonPdfInvestigationWidget = (
  widgetTitle: string,
  widget: InvestigationsWidget,
  extension: Extension,
) => {
  const fileName = `${widgetTitle}_${adjustFormat(new Date(), 'internal')}.${extension}`;
  const mimeType = mimeTypeMapper[extension];

  const requestFn = BinaryFilesExtentions.includes(extension) ? fetchBinaryFile : fetchTextFile;

  const requestBody: InvestigationRequestBody = {
    filters: widget.config.filters,
    exported_field_names: widget.config.fields.toArray(),
    limit: 1000,
    sorts: [{ field: widget.config.sort.field, order: widget.config.sort.direction === 'Ascending' ? 'ASC' : 'DESC' }],
  };

  return requestFn('POST', getInvestigationUrl(fileName), requestBody, mimeType, fileName);
};
