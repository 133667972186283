import * as React from 'react';
import { useCallback, useState, useEffect, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';

import type { Message } from 'views/components/messagelist/Types';
import LogViewWidget from 'logview/components/LogViewWidget';
import type { LogViewMessage } from 'logview/types';
import type DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import useOnBrowserNavigation from 'data-lake/hooks/useOnBrowserNavigation';
import type FieldType from 'views/logic/fieldtypes/FieldType';
import TypeSpecificValue from 'views/components/TypeSpecificValue';
import CustomHighlighting from 'views/components/highlighting/CustomHighlighting';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import { PAGE_SIZE, MAX_LOADED_MESSAGES, INITIAL_PAGE } from 'data-lake/preview/Constants';
import BulkActions from 'data-lake/preview/BulkActions';
import { Alert } from 'components/bootstrap';
import { pollJob } from 'views/logic/slices/executeJobResult';
import type { MostRecentJobIds } from 'data-lake/preview/Types';
import formatBackendMessages from 'data-lake/preview/utils/formatBackendMessages';
import DLLogViewWidgetContainer from 'data-lake/preview/DLLogViewWidgetContainer';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { useSendWidgetConfigUpdateTelemetry } from 'views/components/widgets/telemety';
import stopSearchResultsPolling from 'data-lake/preview/utils/stopSearchResultsPolling';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

const MessageLimitAlert = styled(Alert)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.sm};
  `,
);

const ResetListState = ({
  resetListState,
  messages,
}: {
  resetListState: () => void;
  messages: Array<LogViewMessage>;
}) => {
  const prevMessages = useRef(messages);

  // reset list state after manual search execution
  useEffect(() => {
    if (prevMessages && prevMessages.current !== messages) {
      resetListState();
      prevMessages.current = messages;
    }
  }, [messages, resetListState]);

  useOnBrowserNavigation(resetListState);

  return null;
};

type VisualisationProps = {
  editing: boolean;
  messages: Array<LogViewMessage>;
  onChangeConfig: (newConfig: DataLakeWidgetConfig) => Promise<unknown>;
  widget: DataLakeWidget;
  mostRecentJobIds: MostRecentJobIds | undefined;
  initialPageTotal: number;
  setLoading: (loading: boolean) => void;
};

const Visualisation = ({
  messages,
  widget,
  editing,
  mostRecentJobIds,
  onChangeConfig,
  initialPageTotal,
  setLoading,
}: VisualisationProps) => {
  const fieldTypes = useContext(FieldTypesContext);
  const currentUser = useCurrentUser();

  const [loadedAllMessages, setLoadedAllMessages] = useState(
    initialPageTotal < PAGE_SIZE || (initialPageTotal > PAGE_SIZE && initialPageTotal === messages.length),
  );

  const onLoadMessages = useCallback(
    async (after: { perPage: number; page: number }) => {
      const nextPage = after.page + 1;
      if (after.page * after.perPage >= MAX_LOADED_MESSAGES) {
        setLoadedAllMessages(true);

        return Promise.resolve({ messages: [], after });
      }

      const result = await pollJob({
        stopPolling: (progress) => stopSearchResultsPolling(nextPage, progress),
        jobIds: {
          nodeId: mostRecentJobIds.nodeId,
          asyncSearchId: mostRecentJobIds.asyncSearchId,
        },
        result: null,
        page: nextPage,
        perPage: PAGE_SIZE,
      });

      if (!result) {
        return Promise.resolve({ messages: [], after });
      }

      const queryId = Object.keys(result.results)[0];
      const searchTypeId = Object.keys(result.results[queryId].search_types)[0];
      const { messages: newMessages } = result.results[queryId].search_types[searchTypeId];

      if (newMessages.length === 0) {
        setLoadedAllMessages(true);
      }

      return Promise.resolve({
        messages: formatBackendMessages(newMessages),
        after: { page: nextPage, perPage: PAGE_SIZE },
      });
    },
    [mostRecentJobIds?.asyncSearchId, mostRecentJobIds?.nodeId],
  );

  const onLoadMessage = useCallback((message: { message: { [fieldName: string]: unknown } }) => {
    const filteredFields = Object.fromEntries(
      Object.entries(message.message).filter(([_, value]) => value !== null && value !== undefined),
    );

    return Promise.resolve({
      formatted_fields: filteredFields,
      filtered_fields: filteredFields,
      fields: filteredFields,
    } as unknown as Message);
  }, []);

  const renderFieldValue = useCallback(
    (field: string, fieldType: FieldType, value: string) => (
      <CustomHighlighting field={field} value={value}>
        <TypeSpecificValue field={field} value={value} type={fieldType} />
      </CustomHighlighting>
    ),
    [],
  );

  const bottomSection = useCallback((loadedMessagesCount: number) => {
    if (loadedMessagesCount !== MAX_LOADED_MESSAGES) {
      return null;
    }

    return (
      <MessageLimitAlert bsStyle="warning" className="no-bm">
        Your Preview Search has reached the maximum limit of {MAX_LOADED_MESSAGES} messages. To find more relevant logs,
        try refining your search with more specific parameters.
      </MessageLimitAlert>
    );
  }, []);

  const bulkSelection = isPermitted(currentUser.permissions, 'data_lake_archive:restore')
    ? {
        actions: <BulkActions streamId={widget.streams[0]} />,
      }
    : undefined;

  return (
    <LogViewWidget<DataLakeWidgetConfig>
      defaultFields={DataLakeWidgetConfig.defaultFields}
      config={widget.config}
      bottomSection={bottomSection}
      initialAfter={{ page: INITIAL_PAGE, perPage: PAGE_SIZE }}
      infiniteScrollDirection="DOWN"
      messages={messages}
      onLoadMessages={onLoadMessages}
      renderFieldValue={renderFieldValue}
      editing={editing}
      loadedAllMessages={() => loadedAllMessages}
      onLoadMessage={onLoadMessage}
      setLoadingState={setLoading}
      fields={fieldTypes.all}
      onChangeConfig={(config) => onChangeConfig(config)}
      bulkSelection={bulkSelection}>
      {({ resetListState }) => <ResetListState resetListState={resetListState} messages={messages} />}
    </LogViewWidget>
  );
};

type DLLogViewWidgetProps = {
  editing: boolean;
  messages: Array<LogViewMessage>;
  onChangeWidget: (newWidget: DataLakeWidget) => Promise<unknown>;
  onToggleEdit: () => void;
  widget: DataLakeWidget;
  isFetching: boolean;
  mostRecentJobIds: MostRecentJobIds | undefined;
  initialPageTotal: number;
  effectiveTimerange: AbsoluteTimeRange;
};

const DLLogViewWidget = ({
  messages,
  onToggleEdit,
  widget,
  onChangeWidget,
  editing,
  isFetching,
  mostRecentJobIds,
  initialPageTotal,
  effectiveTimerange,
}: DLLogViewWidgetProps) => {
  const sendWidgetConfigUpdateTelemetry = useSendWidgetConfigUpdateTelemetry();
  const [loading, setLoading] = useState(false);

  const onChangeConfig = useCallback(
    (newConfig: DataLakeWidgetConfig) => {
      sendWidgetConfigUpdateTelemetry();

      return onChangeWidget(widget.toBuilder().config(newConfig).build());
    },
    [onChangeWidget, sendWidgetConfigUpdateTelemetry, widget],
  );

  return (
    <DLLogViewWidgetContainer
      widget={widget}
      editing={editing}
      isFetching={isFetching}
      loading={loading}
      onToggleEdit={onToggleEdit}
      effectiveTimerange={effectiveTimerange}
      onChangeConfig={onChangeConfig}
      onChangeWidget={onChangeWidget}>
      <Visualisation
        setLoading={setLoading}
        editing={editing}
        messages={messages}
        onChangeConfig={onChangeConfig}
        widget={widget}
        mostRecentJobIds={mostRecentJobIds}
        initialPageTotal={initialPageTotal}
      />
    </DLLogViewWidgetContainer>
  );
};

export default DLLogViewWidget;
