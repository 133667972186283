import { qualifyUrls } from 'routing/Routes';

const GraphRoutes = {
  INTEGRATIONS: {
    Graph: {
      ACTIVITYAPI: {
        index: '/integrations/microsoftgraph',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Graph: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/microsoft_graph/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/microsoft_graph/check_credentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Graph: {
      GRAYLOG_Graph_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_graph_ActivityLog_Input.html#graylog-graph-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(GraphRoutes),
  unqualified: GraphRoutes,
};

export { DocsRoutes, ApiRoutes };
