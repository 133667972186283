import { createGlobalStyle, css } from 'styled-components';

import chosenStyles from './chosenStyles';

const CustomChosenStyles = createGlobalStyle(
  ({ theme }) => css`
    ${chosenStyles}
    & .chzn-container {
      margin-bottom: 6px;

      &.chzn-container-active {
        > .chzn-single,
        > .chzn-drop {
          outline: 0;
          border-color: ${theme.colors.input.borderFocus};
          box-shadow: ${theme.colors.input.boxShadow};
        }
      }

      &.chzn-container-active.chzn-with-drop {
        .chzn-single {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .chzn-single {
        /* bootstrap form-control styles */
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        background-image: none;
        border: 1px solid;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        transition:
          border-color ease-in-out 0.15s,
          box-shadow ease-in-out 0.15s;

        /* our styles */
        color: ${theme.colors.input.color};
        background-color: ${theme.colors.input.background};
        border-color: ${theme.colors.input.border};
        font-family: ${theme.fonts.family.body};
      }

      .chzn-results {
        li {
          border-color: ${theme.colors.input.background} !important;
        }

        .highlighted,
        li.highlighted em {
          color: ${theme.colors.variant.darker.default};
          background-color: ${theme.colors.variant.lightest.default};
        }
      }
    }

    .o-form-input-group {
      .o-form-control .chzn-single {
        box-shadow: none;
      }

      .o-form-control.chzn-container-active.chzn-with-drop:last-child {
        .chzn-single {
          border-left-width: 1px;
          margin-left: -1px;
        }

        .chzn-drop {
          border-left-width: 1px;
          border-top-right-radius: 4px;
          left: -1px;
          width: 101%; /* This could break with wider fields */
        }
      }

      select:first-child + .o-form-control.chzn-container-active.chzn-with-drop {
        .chzn-single {
          border-right-width: 1px;
          margin-right: -1px;
        }

        .chzn-drop {
          border-left-width: 1px;
          right: -1px;
          width: 101%; /* This could break with wider fields */
        }
      }
    }

    .o-form-multi-input-2 .chzn-done + .o-form-control,
    .o-form-multi-input-3 .chzn-done + .o-form-control {
      margin-left: 2%;
    }

    .o-form-multi-input-2 .chzn-container {
      width: 49% !important;
    }

    .o-form-multi-input-3 .chzn-container {
      width: 32% !important;
    }

    .o-form-wide .chzn-container {
      width: 100% !important;
    }

    .chzn-container-single {
      .text-select .chzn-single {
        width: 16%;
        height: 30px;
        border-color: ${theme.colors.input.border};
        cursor: pointer;
        box-sizing: border-box;
      }
    }

    .text-select.chzn-with-drop input[type='text'] {
      border-color: ${theme.colors.input.border};
    }

    .chzn-container-single .chzn-single span,
    .chzn-container-multi .chzn-choices .search-field input {
      color: ${theme.colors.input.color};
      text-shadow: none;
    }

    .chzn-container-single .chzn-default,
    .chzn-container-multi .chzn-choices .search-field .default,
    .chzn-container-multi .chzn-choices .search-choice,
    .chzn-container .chzn-results .group-result,
    .chzn-container-active .chzn-choices .search-field input {
      color: ${theme.colors.global.textDefault};
    }

    .chzn-container-multi .chzn-choices .search-choice.search-choice-disabled {
      color: ${theme.colors.variant.dark.default};
      background-color: ${theme.colors.variant.lightest.default};
    }

    .chzn-rtl .chzn-search input,
    .chzn-container-single .chzn-search input {
      background-color: ${theme.colors.input.background};
    }

    .chzn-container-active.chzn-with-drop .chzn-single,
    .chzn-container .chzn-results-scroll,
    .chzn-container .chzn-results .no-results,
    .chzn-container-multi .chzn-choices .search-choice,
    .chzn-container-multi .chzn-choices,
    .chzn-container .chzn-drop,
    .chzn-container .chzn-results li em {
      background-color: ${theme.colors.global.contentBackground};
    }
  `,
);

export default CustomChosenStyles;
