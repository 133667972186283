import * as React from 'react';

import QueryHelper from 'components/common/QueryHelper';

const queryExample = (
  <p>
    Find failed report runs:
    <br />
    <kbd>status:FAILURE</kbd>
    <br />
  </p>
);

const fieldMap = {
  status: 'The status of the report job',
};
const ReportQueryHelper = () => (
  <QueryHelper example={queryExample} entityName="report archive" commonFields={[]} fieldMap={fieldMap} />
);

export default ReportQueryHelper;
