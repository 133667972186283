import * as React from 'react';
import * as Immutable from 'immutable';
import { useState, useEffect, useContext, useMemo } from 'react';

import BackendWizardContext from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';
import type { WizardSubmitPayload } from 'logic/authentication/directoryServices/types';

import MatchingGroupsContext from './MatchingGroupsContext';

import prepareLoadGroupsPayload from '../../logic/directoryServices/prepareLoadGroupsPayload';
import { GroupSyncActions } from '../../stores/directoryServices/GroupSyncStore';

type Props = {
  children: React.ReactNode;
  prepareSubmitPayload: () => WizardSubmitPayload;
};

const MatchingGroupsProvider = ({ children, prepareSubmitPayload }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setStepsState, ...stepsState } = useContext(BackendWizardContext);
  const { authBackendMeta, formValues } = stepsState;
  const [contextValue, setContextValue] = useState({ result: undefined, finishedLoading: true });
  const matchingGroupsContextValue = useMemo(() => ({ ...contextValue, setContextValue }), [contextValue]);

  useEffect(() => {
    // When editing a backend with active group sync, load matching groups
    if (authBackendMeta.backendId && authBackendMeta.backendGroupSyncIsActive) {
      setContextValue({ finishedLoading: false, result: undefined });
      const payload = prepareLoadGroupsPayload(
        prepareSubmitPayload,
        formValues,
        authBackendMeta.backendId,
        authBackendMeta.serviceType,
      );

      GroupSyncActions.loadGroups(payload)
        .then((result) => {
          setContextValue({ finishedLoading: true, result });
        })
        .catch((error) => {
          setContextValue({ finishedLoading: true, result: { errors: Immutable.List([error]) } });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MatchingGroupsContext.Provider value={matchingGroupsContextValue}>{children}</MatchingGroupsContext.Provider>;
};

export { MatchingGroupsContext };

export default MatchingGroupsProvider;
