import * as React from 'react';

import PaginatedEntityTable from 'components/common/PaginatedEntityTable';
import QueryHelper from 'components/common/QueryHelper';
import type { SearchParams } from 'stores/PaginationTypes';
import type { PackType } from 'illuminate/components/content-hub/types';
import { packsTableElements } from 'illuminate/components/content-hub/packs-list/constants';
import { columnRendereds } from 'illuminate/components/content-hub/packs-list/column-renderers';
import useTableElements from 'illuminate/components/content-hub/packs-list/use-table-elements';
import { packEntitiesKeyFn, fetchThreatWidgetLatestPack } from 'illuminate/hooks/api/packs';
import type { PacksSearchParams } from 'illuminate/types';
import useSendPacksListTelemetry from 'illuminate/hooks/useSendPacksListTelemetry';

const defaultTableLayout = {
  ...packsTableElements.defaultLayout,
  entityTableId: 'TCW_PACKS_TABLE',
};
const IlluminatePacksList = () => {
  const sendTelemetry = useSendPacksListTelemetry();
  const { bulkSelection, expandedSections } = useTableElements(true);

  const handleFetchPacks = React.useCallback(
    async (tableParams: SearchParams) => {
      const searchParams: PacksSearchParams = {
        ...tableParams,
        filters: tableParams.filters.set('tags', ['attack']),
        includeDeps: true,
      };

      const deserializedResponse = await fetchThreatWidgetLatestPack(searchParams);

      if (searchParams.query) {
        sendTelemetry(searchParams, deserializedResponse);
      }

      return Promise.resolve(deserializedResponse);
    },
    [sendTelemetry],
  );

  return (
    <PaginatedEntityTable<PackType>
      humanName="packs list"
      entityActions={null}
      columnsOrder={packsTableElements.columnOrder}
      queryHelpComponent={<QueryHelper entityName="packs list" />}
      tableLayout={defaultTableLayout}
      fetchEntities={handleFetchPacks}
      keyFn={packEntitiesKeyFn}
      actionsCellWidth={0}
      entityAttributesAreCamelCase={false}
      columnRenderers={columnRendereds}
      bulkSelection={bulkSelection}
      expandedSectionsRenderer={expandedSections}
    />
  );
};

export default IlluminatePacksList;
