import PaginationURL from 'util/PaginationURL';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

const fetchDataLakeStreams = ({ page, perPage, query }: { page: number; perPage: number; query: string }) => {
  const paginatedURL = PaginationURL(
    DATA_LAKE_API_ROUTES.DataLakeAPIController.paginatedStreams().url,
    page,
    perPage,
    query,
    {
      sort: 'last_known_stream_title',
      order: 'asc',
    },
  );

  return fetch('GET', qualifyUrl(paginatedURL));
};

export default fetchDataLakeStreams;
