import * as React from 'react';
import { useField } from 'formik';

import { TimezoneSelect } from 'components/common';
import { ControlLabel } from 'components/bootstrap';
import useUserDateTime from 'hooks/useUserDateTime';
import { Col } from 'security-app/components/common/FormBaseStyles';

import { ErrorMessage } from './ErrorMessage';

type Props = {
  field: any;
  label?: string;
  clearable?: boolean;
  disabled?: boolean;
};

function FormikUserTiemezoneSelect({ field, label = null, clearable = false, disabled = false }: Props) {
  const { userTimezone } = useUserDateTime();
  const [{ name, value, onBlur }, { touched, error }, { setValue, setTouched }] = useField({ name: field.name });

  const onChange = (newValue: string) => {
    setValue(newValue);
    setTouched(true);
  };

  return (
    <Col $fullWidth $width="100%" $gap="0.3rem" $align="stretch">
      {label && <ControlLabel>{label}</ControlLabel>}
      <TimezoneSelect
        id={name}
        name={name}
        value={value || userTimezone}
        clearable={clearable}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Col>
  );
}

export default FormikUserTiemezoneSelect;
