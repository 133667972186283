import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import DocsHelper from 'util/DocsHelper';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';

import ReportContents from './ReportContents';

import ReportsSubNav from '../common/ReportsSubNav';

const ReportContentsPage = () => {
  const { userTimezone } = useUserDateTime();

  const initialReport = {
    title: '',
    subtitle: '',
    description: '',
    widgets: [],
    positions: [],
    delivery: undefined,
    timezone: userTimezone,
    parameterValues: undefined,
    layout: undefined,
    hideWidgetQuery: false,
    hideWidgetDescription: false,
  };

  return (
    <DocumentTitle title="New report">
      <PageHeader
        title="New report"
        actions={<ReportsSubNav />}
        documentationLink={{
          title: 'Reporting documentation',
          path: DocsHelper.PAGES.REPORTING,
        }}>
        <span>
          Decide which content should be part of the report while you see its preview. You can schedule and configure
          the report in the next screen.
        </span>
      </PageHeader>

      <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report} bsStyle="danger" displayWarningContainer hideChildren>
        <Row className="content">
          <Col md={12}>
            <ReportContents key="new" action="create" report={initialReport} />
          </Col>
        </Row>
      </LicenseCheck>
    </DocumentTitle>
  );
};

export default ReportContentsPage;
