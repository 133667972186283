import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import URI from 'urijs';

import generateId from 'logic/generateId';
import { Spinner } from 'components/common';
import connect from 'stores/connect';
import Store from 'logic/local-storage/Store';
import {
  LOGIN_INITIALIZED_STATE,
  LOGIN_INITIALIZING_STATE,
  LOGIN_TRANSITIONING_STATE,
} from 'logic/authentication/constants';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import type FetchError from 'logic/errors/FetchError';

import OidcCallback from './OidcCallback';
import { OidcConfigurationActions, OidcConfigurationStore } from './OidcConfigurationStore';
import { OidcSessionStore } from './OidcSessionStore';
import OidcGenericLoginLink from './OidcGenericLoginLink';
import type { Configuration } from './OidcSessionStore';

import OidcRoutes from '../Routes';
import OktaLoginForm from '../okta/OktaLoginForm';

type Props = {
  configuration?: Configuration;
  configurationError?: FetchError;
  isLoading: boolean;
  onErrorChange: Dispatch<SetStateAction<string | undefined>>;
  setLoginFormState: Dispatch<SetStateAction<string>>;
};

type Nonce = {
  state: string;
  redirectTo: string;
};

const OidcLogin = ({ configuration, configurationError, isLoading, onErrorChange, setLoginFormState }: Props) => {
  const uri = useMemo(() => new URI(window.location.href), []);
  const configNotLoaded = isLoading || !configuration;

  useEffect(() => {
    OidcConfigurationActions.getConfig();
  }, []);

  useEffect(() => {
    if (configurationError) {
      throw configurationError;
    }
  }, [configurationError]);

  const isCallbackUrl = useCallback(() => uri.path() === OidcRoutes.AUTH_CALLBACK, [uri]);

  const generateNonce = (redirectTo: URI = uri.resource()): Nonce => ({
    state: generateId(),
    redirectTo: redirectTo,
  });

  const [nonce, setNonce] = useState<Nonce>(() => {
    try {
      if (isCallbackUrl()) {
        return Store.sessionGet('nonce');
      }
    } catch (e) {
      return generateNonce();
    }

    return generateNonce();
  });

  const regenerateNonce = () => {
    setNonce((prevNonce) => generateNonce(prevNonce.redirectTo));
  };

  useEffect(() => {
    Store.sessionSet('nonce', nonce);
  }, [nonce]);

  useEffect(() => {
    if (configNotLoaded) {
      setLoginFormState(LOGIN_INITIALIZING_STATE);
    } else if (isCallbackUrl()) {
      setLoginFormState(LOGIN_TRANSITIONING_STATE);
    } else {
      setLoginFormState(LOGIN_INITIALIZED_STATE);
    }
  }, [configNotLoaded, isCallbackUrl, setLoginFormState]);

  if (configNotLoaded) {
    return (
      <p className="loading-text">
        <Spinner text="Loading, please wait..." delay={0} />
      </p>
    );
  }

  if (isCallbackUrl()) {
    return (
      <OidcCallback
        configuration={configuration}
        nonce={nonce}
        onErrorChange={onErrorChange}
        params={uri.query(true)}
        regenerateNonce={regenerateNonce}
      />
    );
  }

  return configuration.oauth_type === OKTA_TYPE_KEY ? (
    <OktaLoginForm checkSession configuration={configuration} nonce={nonce} />
  ) : (
    <OidcGenericLoginLink configuration={configuration} nonce={nonce} />
  );
};

export default connect(
  OidcLogin,
  {
    configuration: OidcConfigurationStore,
    oidcSession: OidcSessionStore,
  },
  ({ configuration, oidcSession, ...props }) => ({
    ...props,
    configuration: configuration.configuration,
    configurationError: configuration.error,
    isLoading: oidcSession.isLoading,
  }),
);
