import styled, { css } from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Row = styled.div<{
  $fullWidth?: boolean;
  $gap?: string;
  $align?: string;
  $justify?: string;
  $width?: string;
}>`
  display: flex;
  flex-direction: row;
  gap: ${({ $gap }) => $gap || '1rem'};
  justify-content: ${({ $justify }) => $justify || 'stretch'};
  align-items: ${({ $align }) => $align || 'flex-start'};
  width: ${({ $width }) => $width || 'auto'};

  .form-group {
    margin-bottom: 0;

    input[readonly] {
      background-color: ${({ theme }) => theme.colors.global.contentBackground} !important;
      color: ${({ theme }) => theme.colors.global.textDefault} !important;
    }
  }

  .help-block {
    margin: 0;
    margin-top: 0.5rem;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      & > div {
        flex: 1;

        & > label {
          width: 100%;
        }
      }
    `}

  @media (max-width: 991px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

export const Col = styled(Row)`
  flex-direction: column;
`;

export const SelectWrapper = styled.div<{ $withError: boolean }>(({ $withError }) => {
  if ($withError) {
    return css`
      & > label {
        color: ${({ theme }) => theme.colors.variant.danger};
      }

      & div[class$='control'] {
        border-color: ${({ theme }) => theme.colors.variant.danger};
      }

      & > small {
        color: ${({ theme }) => theme.colors.variant.danger};
      }
    `;
  }

  return null;
});

export const FormGroup = styled.div(
  ({ theme }) => css`
    input[readonly] {
      background-color: ${theme.colors.global.contentBackground} !important;
      color: ${theme.colors.global.textDefault} !important;
    }

    margin-bottom: 0 !important;

    & .form-group {
      margin-bottom: 0 !important;
      margin: 0;
    }

    & .help-block {
      margin-bottom: 0;
    }
  `,
);

export const HelpText = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.gray['50']};
    font-size: 1rem;
    margin-top: 0.5rem;
  `,
);
