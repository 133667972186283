import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';

const CollapsibleContent = styled(Col)<{ $maxHeight?: string }>`
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

const TitleRow = styled(Row)<{ $expanded: boolean }>`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacings.sm};
  border-radius: 8px;
  background-color: ${({ theme, $expanded }) => ($expanded ? theme.colors.background.secondaryNav : 'transparent')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondaryNav};
  }
`;

const Arrow = styled(Icon)<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${({ $expanded }) => ($expanded ? '-180' : '0')}deg);
  transition: transform 0.3s ease-out;
  margin-left: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

type Props = {
  children: React.ReactNode;
  title?: React.ReactNode;
  disabled?: boolean;
  expanded?: boolean;
};

function Collapsible({ title = undefined, disabled = false, expanded = false, children }: Props) {
  const ref = React.useRef(null);
  const [show, setShow] = React.useState<boolean>(expanded);
  const [maxHeight, setMaxHeight] = React.useState<string>('0');

  const onClick = (e: React.BaseSyntheticEvent) => {
    if (disabled) return;

    if (e.target.tagName !== 'INPUT') {
      const auxShow = !show;

      if (auxShow) {
        setMaxHeight(`${ref.current.scrollHeight}px`);
        setTimeout(() => setMaxHeight('fit-content'), 400);
      } else {
        if (maxHeight === 'fit-content') setMaxHeight(`${ref.current.scrollHeight}px`);
        setTimeout(() => setMaxHeight('0'), 100);
      }

      setShow(auxShow);
    }
  };

  return (
    <Col $width="100%" $gap="0.3rem">
      <TitleRow
        $align="center"
        $justify="space-between"
        $width="100%"
        onClick={onClick}
        $expanded={show}
        role="rowheader"
        aria-label="collapsible trigger"
      >
        {title}
        {!disabled && (
          <Arrow
            title="toggle details"
            name={disabled ? 'unknown_med' : 'keyboard_arrow_down'}
            type="regular"
            $expanded={show}
          />
        )}
      </TitleRow>
      <CollapsibleContent
        ref={ref}
        $maxHeight={maxHeight}
        $width="100%"
        role="contentinfo"
        aria-label="collapsible body">
        {children}
      </CollapsibleContent>
    </Col>
  );
}

export default Collapsible;
