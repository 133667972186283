import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalThemeStyles from 'theme/GlobalThemeStyles';
import useThemes from 'theme/hooks/useThemes';
import { COLOR_SCHEME_LIGHT } from 'theme/constants';
import ColorSchemeContext from 'theme/ColorSchemeContext';

type Props = {
  skipGlobalStyles?: boolean;
};
const PDFExportThemeProvider = ({ children = undefined, skipGlobalStyles = false }: React.PropsWithChildren<Props>) => {
  const { scTheme } = useThemes(COLOR_SCHEME_LIGHT, true);

  return (
    <ColorSchemeContext.Provider value={COLOR_SCHEME_LIGHT}>
      <ThemeProvider theme={scTheme}>
        {skipGlobalStyles ? null : <GlobalThemeStyles />}
        {children}
      </ThemeProvider>
    </ColorSchemeContext.Provider>
  );
};

export default PDFExportThemeProvider;
