import React, { useEffect } from 'react';
import type { SyntheticEvent } from 'react';
import { useField } from 'formik';

import { Alert } from 'components/bootstrap';
import { Select, Spinner } from 'components/common';

import RepositoryOption from './RepositoryOption';
import useRepositories from './hooks/useRepositories';

type Option = { value: string; label: string; ready: boolean; type: string; description?: string };

type Props = {
  fieldName: string;
  handleDelete: (event: SyntheticEvent, value: string) => void;
};

const RepositorySelect = ({ fieldName, handleDelete }: Props) => {
  const [{ name, onChange, onBlur }, { value, touched }, { setValue }] = useField(fieldName);

  const { repositories } = useRepositories();

  const buildOptions = (): Option[] =>
    repositories.data.map((repo) => ({
      value: repo.repository.name,
      label: repo.repository.name,
      ready: repo.ready,
      type: repo.repository.type,
      description: repo.details,
    }));

  const optionRenderer = (option: Option, isSelected: boolean) => (
    <RepositoryOption repository={option} onDelete={handleDelete} isSelected={isSelected} />
  );

  useEffect(() => {
    if (!touched && value === null && repositories.data?.length > 0) {
      setValue(repositories.data[0].repository.name);
    }
  }, [touched, setValue, value, repositories.data]);

  if (repositories.isLoading) return <Spinner text="Fetching warm storage repositories..." />;
  if (repositories.data?.length === 0)
    return <Alert bsStyle="info">No warm storage repositories existing, please create one.</Alert>;

  return (
    <Select
      placeholder="Select an existing warm storage repository"
      options={buildOptions()}
      optionRenderer={optionRenderer}
      autoFocus
      required
      onBlur={onBlur}
      onChange={(selected) => onChange({ target: { value: selected, name } })}
      value={value}
    />
  );
};

export default RepositorySelect;
