import type { Attribute } from 'stores/PaginationTypes';
import AppConfig from 'util/AppConfig';
import DataLakeRoutes from 'data-lake/Routes';
import { SEARCH_LINK_TITLE } from 'components/navigation/bindings';

import EnterpriseRoutes from '../EnterpriseRoutes';

export const DATA_LAKE_API_ROUTES = {
  DataLakeAPIController: {
    paginated: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse' }),
    paginatedStreams: () => ({ url: '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream' }),
    get: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/archives/${id}` }),
    get_stream_data_lake: (streamId: string) => ({
      url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/archive`,
    }),
    delete: (streamId: string, retrievalOperationId: string) => ({
      url: `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/archive/${streamId}/retrieved/${retrievalOperationId}`,
    }),
    bulk_delete: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/delete' }),
    bulk_retrieval: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/retrieve' }),
    config: {
      base: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/config' }),
    },
    backend: {
      base: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends' }),
      get: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}` }),
      delete: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}` }),
      update: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}` }),
    },
    backendConfig: {
      base: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/config' }),
    },
    estimate: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/size_estimate' }),
    job: {
      progress: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/progress' }),
      stream_jobs: (streamId: string) => ({
        url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/progress/${streamId}`,
      }),
      cancel: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/${id}` }),
    },
    stream_data_warehouse_config: (streamId: string) => ({
      url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/config`,
    }),
    stream_data_warehouse_status: (streamId: string) => ({
      url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/status`,
    }),
    stream_data_warehouse_set_config_status: () => ({
      url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/config/enable',
    }),
    filterableFields: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/filterable_fields' }),
    stream_retention: (streamId: string) => ({
      url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/retention_time`,
    }),
  },
};
export const DATA_LAKE_CONFIG_QUERY_KEYS = ['data-lake', 'config'];
export const DATA_LAKE_BACKEND_QUERY_KEYS = ['data-lake', 'backend'];
export const DATA_LAKE_BACKEND_CONFIG_QUERY_KEYS = ['data-lake', 'backend', 'config'];

type DataLakeSettings = {
  data_warehouse_enabled: boolean;
};
const settings: DataLakeSettings = AppConfig.pluginUISettings('org.graylog.plugins.storage.data_warehouse');

export const DATALAKE_ATTRIBUTE_NAME = 'data_lake';
export const DATALAKE_ATTRIBUTES: Array<Attribute> = [{ id: DATALAKE_ATTRIBUTE_NAME, title: 'Data Lake' }];

export const DATA_LAKE_ENABLED = settings?.data_warehouse_enabled === true;
export const DATA_LAKE_PREVIEW_ENABLED = AppConfig.isFeatureEnabled('data_warehouse_search');

export const DATA_LAKE_LICENSE_TITLE = 'Data Lake is partially Disabled.';
export const DATA_LAKE_LICENSE_TEXT =
  `Data Lake is partially disabled because there is no valid license. While in this state:\n
   - Streams already configured to use Data Lake will continue to store messages there.\n
   - Streams not already configured to use Data Lake can not be configured to use it.\n
   - Log Retrieval from the Data Lake is disabled.\n
   - Preview of logs from the Data Lake is disabled.\n`;
export const DATA_LAKE_INCLUSION_TYPE = 'DATA_WAREHOUSE_ONLY';
export const INDEXER_INCLUSION_TYPE = 'INDEXER_ONLY';
export const BOTH_INCLUSION_TYPE = 'BOTH';
export const HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY = 'data-lake-retrieval-hide-help-text';
export const DATALAKE_SECTION_OPENED_KEY = 'data-lake-section-opened';

export const DATA_LAKE_PREVIEW_NAV_ITEM = {
  description: 'Data Lake',
  position: { after: SEARCH_LINK_TITLE },
  children: [
    ...(DATA_LAKE_PREVIEW_ENABLED ? [{ path: DataLakeRoutes.PREVIEW, description: 'Preview' }] : []),
    {
      path: EnterpriseRoutes.DATA_LAKE,
      description: 'Setup',
      permissions: 'data_lake:read',
    },
  ],
};
