import * as React from 'react';
import { useCallback } from 'react';
import capitalize from 'lodash/capitalize';
import type * as Immutable from 'immutable';

import fixup from 'views/pages/StyleFixups.css';
import { Col, Row } from 'components/bootstrap';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import PageContentLayout from 'components/layout/PageContentLayout';
import type View from 'views/logic/views/View';
import type SearchExecutionState from 'views/logic/search/SearchExecutionState';
import mergeParameterValues from 'enterprise/parameters/logic/mergeParameterValues';
import type ParameterBinding from 'views/logic/parameters/ParameterBinding';

import ParameterInputForm from './ParameterInputForm';

const humanReadableViewType = (viewType: string) => capitalize(viewType);

type Props = {
  onSubmit?: (args: { executionState: SearchExecutionState }) => void;
  parameters: ParameterMap;
  executionState: SearchExecutionState;
  view: View;
};

const RequiredParametersForViewForm = ({
  onSubmit = () => {},
  parameters,
  view: { type, title, search },
  executionState,
}: Props) => {
  const _onSubmit = useCallback(
    (newValues: Immutable.Map<string, ParameterBinding>) => {
      const newExecutionState = mergeParameterValues(newValues, executionState);

      return onSubmit({ executionState: newExecutionState });
    },
    [executionState, onSubmit],
  );

  return (
    <PageContentLayout>
      <Row className="content">
        <Col md={8} mdOffset={3} className={fixup.bootstrapHeading}>
          <h1>
            {humanReadableViewType(type)}: <em>{title}</em>
          </h1>
          <p>
            This {humanReadableViewType(type).toLowerCase()} uses parameters which need to be entered before it can be
            executed. Please enter the parameter values into the form.
          </p>
        </Col>
        <ParameterInputForm parameters={parameters} searchId={search.id} onSubmit={_onSubmit} />
      </Row>
    </PageContentLayout>
  );
};

export default RequiredParametersForViewForm;
