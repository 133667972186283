import * as React from 'react';

import type { DataLake } from 'data-lake/Types';

type Props = {
  archive: DataLake;
};

const RestoredArchivesCell = ({ archive }: Props) => (
  <span>{archive.restore_history == null ? 0 : archive.restore_history.length}</span>
);

export default RestoredArchivesCell;
