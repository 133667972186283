/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import { createGlobalStyle, css } from 'styled-components';

import { RowContentStyles } from 'components/bootstrap/Row';

const GlobalThemeStyles = createGlobalStyle(
  ({ theme }) => css`
    #editor {
      height: 256px;
    }

    html {
      font-size: ${theme.fonts.size.root} !important; /* override Bootstrap default */
    }

    body {
      background-color: ${theme.colors.global.background};
      color: ${theme.colors.global.textDefault};
      font-family: ${theme.fonts.family.body};
      font-size: ${theme.fonts.size.body};
      line-height: 1.25;
      height: 100vh;
    }

    #app-root {
      height: 100%;
    }

    ul {
      list-style-type: none;
      margin: 0;
    }

    ul.no-padding {
      padding: 0;
    }

    hr {
      border-top: 1px solid ${theme.colors.global.background};
    }

    h1,
    h2 {
      font-family: ${theme.fonts.family.navigation};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: normal;
      padding: 0;
      margin: 0;
      color: ${theme.colors.global.textDefault};
    }

    h1 {
      font-size: ${theme.fonts.size.h1};
    }

    h2 {
      font-size: ${theme.fonts.size.h2};
    }

    h3 {
      font-size: ${theme.fonts.size.h3};
    }

    h4 {
      font-size: ${theme.fonts.size.h4};
    }

    h5 {
      font-size: ${theme.fonts.size.h5};
    }

    h6 {
      font-size: ${theme.fonts.size.h6};
      font-weight: bold;
    }

    a {
      color: ${theme.colors.global.link};
    }

    a:hover,
    a:focus {
      color: ${theme.colors.global.linkHover};
    }

    /* Remove boostrap outline */
    a:active,
    select:active,
    input[type='file']:active,
    input[type='radio']:active,
    input[type='checkbox']:active,
    .btn:active {
      outline: none;
      outline-offset: 0;
    }

    input.form-control,
    select.form-control,
    textarea,
    textarea.form-control {
      color: ${theme.colors.input.color};
      background-color: ${theme.colors.input.background};
      border-color: ${theme.colors.input.border};
      border-radius: 0;
      font-family: ${theme.fonts.family.body};

      &::placeholder {
        color: ${theme.colors.input.placeholder};
      }

      &:focus {
        border-color: ${theme.colors.input.borderFocus};
        box-shadow: ${theme.colors.input.boxShadow};
      }

      &[disabled],
      &[readonly],
      fieldset[disabled] & {
        background-color: ${theme.colors.input.backgroundDisabled};
        color: ${theme.colors.input.colorDisabled};
      }
    }

    textarea {
      max-width: 100%;
    }

    legend small {
      color: ${theme.colors.gray[60]};
      margin-left: 5px;
    }

    small {
      font-size: ${theme.fonts.size.small};
    }

    .input-group-addon.input-group-separator {
      border-right-width: 0;
      border-left-width: 0;
    }

    .content {
      padding-top: 15px;
      padding-bottom: 15px;
      ${RowContentStyles}

      p.description {
        margin-top: 3px;
        color: ${theme.colors.gray[50]};
      }
    }

    .notifications-none {
      margin-top: 10px;
    }

    .no-bm {
      margin-bottom: 0;
    }

    .has-bm {
      margin-bottom: 10px;
    }

    .modal form {
      margin-bottom: 0;
    }

    .alert-bar {
      margin: 10px -20px 0;
    }

    .xtrc-converter-subfields {
      margin-left: 20px;
    }

    .u-light {
      border-bottom: 1px dotted ${theme.colors.gray[70]};
      margin-bottom: 5px;
      padding-bottom: 5px;
    }

    .input-docs {
      margin-left: 3px;
    }

    .input-docs:hover {
      text-decoration: none;
    }

    .timerange-selector select {
      margin-bottom: 0;
    }

    .leader-node {
      color: ${theme.colors.variant.dark.warning};
    }

    .loglevel-metrics-row {
      margin-top: 2px;
      margin-left: 10px;
    }

    .loglevel-metrics dl {
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .loglevel-metrics dt {
      float: left;
      margin-right: 5px;
    }

    .subsystems {
      margin-top: 10px;
      margin-left: 10px;
    }

    .sources.overlay {
      background-color: ${theme.colors.gray[60]};
      height: 200px;
      line-height: 200px;
      opacity: 0.2;
      position: absolute;
      text-align: center;
      font-size: 50px;
    }

    .metrics-filter {
      margin-bottom: 15px !important;
    }

    dl.metric-timer dd {
      margin-left: 145px;
    }

    td.centered {
      text-align: center;
    }

    td.limited {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .configuration-field-optional {
      margin-left: 5px;
      font-size: ${theme.fonts.size.small};
    }

    .index-description {
      margin-bottom: 7px;
    }

    .index-description .index-info {
      margin-top: 5px;
    }

    .index-description .index-info dl {
      margin-top: 5px;
    }

    .index-details {
      margin-left: 5px;
    }

    .index-label {
      vertical-align: text-top;
    }

    .index-label .label {
      margin-right: 5px;
    }

    .index-more-actions {
      font-size: 90%;
      margin-left: 5px;
    }

    .shard-meters {
      margin-top: 10px;
    }

    .shard-meters dl {
      margin-bottom: 0;
      margin-top: 0;
    }

    .shard-meters dl dt {
      float: left;
    }

    .shard-meters dl dd {
      margin-left: 65px;
    }

    .shards {
      margin: 0;
      padding: 0;
      margin-top: 5px;
    }

    dl.system-journal {
      margin-top: 5px;
      margin-bottom: 0;
    }

    .system-journal dt {
      float: left;
    }

    .system-journal dd {
      margin-left: 120px;
    }

    dl.system-dl {
      margin: 0;
    }

    .system-dl dt {
      float: left;
      clear: left;
    }

    .system-dl dd {
      margin-left: 180px;
    }

    .no-widgets {
      margin-top: 15px;
    }

    table .dc-table-column {
      word-break: break-all;
    }

    .sources th {
      background-color: ${theme.colors.gray[20]};
      color: ${theme.utils.readableColor(theme.colors.gray[20])};
      font-weight: normal;
    }

    .sources .dc-table-column._3 {
      padding-right: 0;
      text-align: right;
    }

    .parse-error {
      background-color: ${theme.colors.variant.light.danger};
      color: ${theme.utils.contrastingColor(theme.colors.variant.light.danger)};
      padding-left: 2px;
      padding-right: 2px;
    }

    .add-alert-type {
      margin-bottom: 0;
    }

    .alerts {
      margin-top: 15px;
    }

    .alerts tbody {
      border: none;
    }

    .alerts th {
      background-color: ${theme.colors.gray[10]};
      color: ${theme.colors.global.textAlt};
      font-weight: normal;
    }

    .alerts th a {
      display: block;
    }

    .alerts th a:focus {
      color: ${theme.colors.global.textAlt};
    }

    .result-highlight-colored {
      background-color: ${theme.colors.variant.warning};
    }

    .annotation .content {
      margin-bottom: 10px;
      cursor: auto !important;
    }

    .scrollable-table {
      width: 100%;
      overflow: auto;
    }

    .form-inline label {
      margin-right: 10px;
    }

    .form-horizontal .help-block.help-standalone {
      line-height: 20px;
      margin-top: 7px;
    }

    .form-horizontal.pull-left label.control-label {
      width: auto;
    }

    .form-horizontal.pull-left div.controls {
      display: inline-block;
      float: right;
      margin-left: 20px;
    }

    .form-horizontal .input-description {
      margin-bottom: 0 !important;
    }

    form.extractor-form .control-group label {
      display: inline-block;
    }

    .configuration-bundles input[type='file'] {
      line-height: inherit !important;
    }

    table th.actions,
    table td.actions {
      width: 110px;
    }

    .btn-text {
      font-family: ${theme.fonts.family.body};
      font-size: ${theme.fonts.size.small};
      padding: 0;
      vertical-align: baseline;
    }

    .message-loader-form input {
      margin-right: 5px;
    }

    table.indexer-failures {
      margin-top: 10px;
    }

    div.row-sm {
      margin-bottom: 5px;
    }

    .table-sm {
      margin-bottom: 0;
    }

    .graylog-input-metrics {
      margin-top: 5px;
    }

    .filter .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    div.alert-callback hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    div.alert-callbacks {
      margin-top: 10px;
    }

    .alarm-callbacks {
      padding: 0;
    }

    .alarm-callbacks li:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid ${theme.colors.gray[90]};
    }

    .threaddump {
      font-size: ${theme.fonts.size.small};
    }

    h2.extractor-title {
      margin-bottom: 2px;
    }

    .stream-loader {
      margin-top: 5px;
    }

    .form-inline .typeahead-wrapper {
      display: inline-block;
      vertical-align: middle;
      width: auto;
    }

    .form-group-inline {
      display: inline-block;
      margin: 0;
    }

    .form-control-feedback {
      line-height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ul.tag-list,
    ul.pill-list {
      display: inline-block;
      list-style: none;
      padding: 0;
      position: relative;
    }

    ul.pill-list {
      margin-left: 10px;
      vertical-align: middle;
    }

    ul.tag-list > li,
    ul.pill-list > li {
      display: inline-block;
      vertical-align: middle;
    }

    .tags-input ul.tag-list > li {
      padding-top: 10px;
      padding-bottom: 5px;
    }

    .tag,
    .pill {
      font-size: ${theme.fonts.size.body};
      margin-right: 5px;
    }

    .pill {
      color: ${theme.colors.global.textDefault};
      background-color: ${theme.colors.gray[90]};
      padding: 6px 12px;
    }

    .tag-remove,
    .pill-remove {
      color: ${theme.colors.global.textDefault};
      cursor: pointer;
      margin-left: 5px;
    }

    .tag-remove::before,
    .pill-remove::before {
      content: '×';
    }

    .save-button-margin {
      margin-right: 5px;
    }

    .form-control.message-id-input {
      width: 300px;
    }

    /* additional styles for 'StyledAceEditor' */
    .ace_editor.ace_autocomplete {
      margin-top: 6px;
      background-color: ${theme.colors.input.background};
      color: ${theme.colors.input.color};
    }

    .ace_editor.ace_autocomplete .ace_marker-layer .ace_active-line {
      background-color: ${theme.colors.variant.lighter.info};
      color: ${theme.colors.input.colorDisabled};
    }

    .ace_editor.ace_autocomplete .ace_text-layer .ace_completion-highlight {
      color: ${theme.colors.variant.info};
    }

    code {
      color: ${theme.colors.variant.darker.danger};
      background-color: ${theme.colors.variant.lightest.danger};
    }

    pre {
      color: ${theme.colors.variant.darker.default};
      background-color: ${theme.colors.variant.lightest.default};
      border-color: ${theme.colors.variant.lighter.default};
    }

    input[type='range'],
    input[type='range']:focus {
      box-shadow: none;
      height: auto;
    }
  `,
);

export default GlobalThemeStyles;
