import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

import { FormikFormGroup } from 'components/common';
import { Button, Input } from 'components/bootstrap';
import type { Backend } from 'archive/types';

type Props = {
  isAccesskeySet: boolean;
  handleAccessKeyChange: (value: string) => void;
};

const AWSKeySecretFieldGroup = ({ isAccesskeySet, handleAccessKeyChange }: Props) => {
  const { setFieldValue } = useFormikContext<Backend>();
  const isCreate = useRef(!isAccesskeySet);
  const [showResetPasswordButton, setShowResetPasswordButton] = useState(isAccesskeySet);

  const setAccessKey = useCallback(
    (nextAccessKey) => {
      setFieldValue('settings.aws_secret_access_key', nextAccessKey);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (isAccesskeySet) {
      setAccessKey({ keep_value: true });
    }
  }, [isAccesskeySet, setAccessKey]);

  const toggleAccessKeyReset = useCallback(() => {
    if (showResetPasswordButton) {
      setAccessKey({ delete_value: true });
      setShowResetPasswordButton(false);

      return;
    }

    setAccessKey({ keep_value: true });
    setShowResetPasswordButton(true);
  }, [setAccessKey, showResetPasswordButton]);

  const debouncedHanldeAccessKeyChange = debounce(handleAccessKeyChange, 200);

  return (
    <>
      <FormikFormGroup
        name="settings.aws_access_key_id"
        type="text"
        label="AWS Access Key"
        placeholder="AK****************"
        help='Your AWS Key should be a 20-character long, alphanumeric string that starts with the letters "AK".'
        required
      />
      {showResetPasswordButton ? (
        <Input id="aws_secrete_key_reset" label="AWS Secrete Key" labelClassName="col-sm-3" wrapperClassName="col-sm-9">
          <Button onClick={toggleAccessKeyReset}>Reset password</Button>
        </Input>
      ) : (
        <Input
          name="settings.aws_secret_access_key"
          id="aws_secrete_key"
          type="password"
          label="AWS Secret Key"
          onChange={({ target: { value } }) => debouncedHanldeAccessKeyChange(value)}
          buttonAfter={
            !isCreate.current ? (
              <Button type="button" onClick={toggleAccessKeyReset}>
                Undo Reset
              </Button>
            ) : undefined
          }
          placeholder="****************"
          help="Your AWS Secret is usually a 40-character long, base-64 encoded string."
          labelClassName="col-sm-3"
          wrapperClassName="col-sm-9"
          required
        />
      )}
    </>
  );
};

export default AWSKeySecretFieldGroup;
