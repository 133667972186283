import * as React from 'react';

import { singleton } from 'logic/singleton';

type ScrollPosition = {
  finishedScrollPositionUpdateRef: React.MutableRefObject<boolean>;
  lastPositionRef: React.MutableRefObject<number>;
  setFinishedScrollPositionUpdate: (finished: boolean) => void;
  setLastPosition: (position: number) => void;
};

const ScrollPositionContext = React.createContext<ScrollPosition | undefined>(undefined);

export default singleton('contexts.enterprise.LogViewScrollPositionContext', () => ScrollPositionContext);
