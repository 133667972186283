import React from 'react';

import { LinkContainer } from 'components/common/router';
import { Button, Col, Row } from 'components/bootstrap';
import { EmptyEntity, IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

const EmptyInputProfileListComponent = () => {
  const currentUser = useCurrentUser();

  return (
    <Row className="content">
      <Col md={12}>
        <Row>
          <Col md={6} mdOffset={3} lg={4} lgOffset={4}>
            <EmptyEntity>
              {isPermitted(currentUser?.permissions, 'inputprofiles:create') ? (
                <>
                  <p>
                    Input Profiles allow you to create and group multiple Inputs&#39; configurations and share them
                    between different Forwarders. Click on the button below to get started and create your first Input
                    Profile.
                  </p>
                  <IfPermitted permissions="inputprofiles:create">
                    <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_NEW')}>
                      <Button bsStyle="success">Get Started!</Button>
                    </LinkContainer>
                  </IfPermitted>
                </>
              ) : (
                <p>
                  Input Profiles allow you to create and group multiple Inputs&#39; configurations and share them
                  between different Forwarders.
                </p>
              )}
            </EmptyEntity>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmptyInputProfileListComponent;
