import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';

import { isPermitted } from 'util/PermissionsMixin';
import LicenseStatusStore from 'license/LicenseStatusStore';
import connect from 'stores/connect';
import Store from 'logic/local-storage/Store';
import useCurrentUser from 'hooks/useCurrentUser';
import LicenseUsageWarningNotification from 'license/notifications/LicenseUsageWarningNotification';
import LicenseViolationNotification from 'license/notifications/LicenseViolationNotification';
import LicenseExpirationNotification from 'license/notifications/LicenseExpirationNotification';
import LicenseExpirationWarningNotification from 'license/notifications/LicenseExpirationWarningNotification';
import LicenseTrialNotification from 'license/notifications/LicenseTrialNotification';
import LicenseTrafficViolationNotificationContainer from 'license/notifications/LicenseTrafficViolationNotificationContainer';

type LicenseStatus = {
  violated: boolean;
  expired: boolean;
  expiredSince: number;
  expirationUpcoming: boolean;
  expiresIn: number;
  trial: boolean;
  trialExpiresIn: number;
  trafficViolation: boolean;
  usageThresholdWarning: boolean;
  usageThreshold: number;
};
type ExpirationWarningStatus = {
  show: boolean;
  enableReminderButton: boolean;
  showTrialWarning: boolean;
};
type Props = {
  licenseStatus: LicenseStatus;
};

const REMINDER_STORAGE_KEY = 'license:expiration-reminder';
const TRIAL_REMINDER_STORAGE_KEY = 'license:trial-reminder';

const _getExpirationWarningStatus = (licenseStatus: LicenseStatus): ExpirationWarningStatus => {
  const newStatus = { show: true, enableReminderButton: false, showTrialWarning: true };
  const { violated, expired, expiresIn } = licenseStatus;

  if (violated || expired) {
    // Shortcut to avoid localStorage access cost when we don't display the warning anyway
    return newStatus;
  }

  const value = Store.get(REMINDER_STORAGE_KEY);
  const expirationDate = moment().add(expiresIn, 'seconds');
  const almostExpired = moment().isAfter(expirationDate.subtract(5, 'days'));

  const trialMuteValue = Store.get(TRIAL_REMINDER_STORAGE_KEY);

  // If the user muted the trial warning, show it again after 1 day
  if (trialMuteValue && trialMuteValue.mutedAt) {
    newStatus.showTrialWarning = moment().isAfter(moment(trialMuteValue.mutedAt).add(1, 'days'));
  }

  // Always show the warning if the license has almost expired. Otherwise only show it if it hasn't been dismissed.
  newStatus.show = almostExpired || !value;
  // Do not allow the user to dismiss the warning if the license has almost expired.
  newStatus.enableReminderButton = !almostExpired;

  return newStatus;
};

const LicenseNotificationContainer = ({ licenseStatus }: Props) => {
  const currentUser = useCurrentUser();
  const [expirationWarningStatus, setExpirationWarningStatus] = useState<ExpirationWarningStatus>(
    _getExpirationWarningStatus(licenseStatus),
  );

  const handleExpirationReminder = () => {
    Store.set(REMINDER_STORAGE_KEY, { createdAt: moment().toISOString() });
    setExpirationWarningStatus(_getExpirationWarningStatus(licenseStatus));
  };

  const handleMuteTrialReminder = () => {
    Store.set(TRIAL_REMINDER_STORAGE_KEY, { mutedAt: moment().toISOString() });

    setExpirationWarningStatus(_getExpirationWarningStatus(licenseStatus));
  };

  const children = [];
  const {
    trial,
    trialExpiresIn,
    violated,
    expired,
    expiresIn,
    expiredSince,
    expirationUpcoming,
    trafficViolation,
    usageThresholdWarning,
    usageThreshold,
  } = licenseStatus;

  if (trial && expirationWarningStatus.showTrialWarning) {
    const duration = moment.duration(trialExpiresIn, 'seconds').humanize();

    children.push(<LicenseTrialNotification expiresIn={duration} key={1} onMuteClick={handleMuteTrialReminder} />);
  }

  if (violated) {
    children.push(<LicenseViolationNotification key={2} />);
  }

  if (trafficViolation && isPermitted(currentUser.permissions, ['notifications:read'])) {
    children.push(<LicenseTrafficViolationNotificationContainer key={3} />);
  }

  if (usageThresholdWarning && isPermitted(currentUser.permissions, ['notifications:read'])) {
    children.push(<LicenseUsageWarningNotification remainingVolume={usageThreshold} key={4} />);
  }

  if (expired) {
    const duration = moment.duration(expiredSince, 'seconds').humanize();
    children.push(<LicenseExpirationNotification currentUser={currentUser} expiredSince={duration} key={5} />);
  } else if (expirationUpcoming && expiresIn > 0 && expirationWarningStatus.show) {
    const duration = moment.duration(expiresIn, 'seconds').humanize();

    children.push(
      <LicenseExpirationWarningNotification
        expiresIn={duration}
        enableReminderButton={expirationWarningStatus.enableReminderButton}
        onReminderClick={handleExpirationReminder}
        key={6}
      />,
    );
  }

  return <div className="license-notification-container">{children}</div>;
};

export default connect(LicenseNotificationContainer, { licenseStatus: LicenseStatusStore });
