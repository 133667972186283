import type { DataLakeLogsResultJSON, DataLakeLogsResult } from 'data-lake/preview/Types';

export default {
  convert({
    effective_timerange: effectiveTimerange,
    id,
    messages,
    total_results: total,
    type,
  }: DataLakeLogsResultJSON): DataLakeLogsResult {
    return {
      effectiveTimerange,
      id,
      messages: messages,
      total,
      type: type,
    };
  },
};
