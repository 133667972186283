import DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';

export const ICEBERG_QUERY = {
  'type': 'data_warehouse_iceberg',
  'query_string': '',
} as const;

export const INITIAL_PAGE = 1;
export const PAGE_SIZE = 100;
export const MAX_LOADED_MESSAGES = 500;

export const PREVIEW_JOB_STATUS = {
  done: 'DONE',
  running: 'RUNNING',
  expired: 'EXPIRED',
  error: 'ERROR',
  timeout: 'TIMEOUT',
  reset: 'RESET',
  cancelled: 'CANCELLED',
} as const;

export const WIDGET_DEFAULTS = {
  id: 'data-lake-widget-id',
  fields: DataLakeWidgetConfig.defaultFields,
  sort: DataLakeWidgetConfig.defaultSortDirection,
  tieBreaker: 'timestamp',
  fieldFilters: [],
  fieldsOperator: 'AND' as const,
  size: PAGE_SIZE,
  timerange: { type: 'relative' as const, from: 1800 },
};

export const HIDE_DL_SEARCH_EXPIRED_RESULT_ALERT = 'hide-dl-search-expired-result-alert';
export const HIDE_DL_SEARCH_SUCCESSFUL_SEARCH_ALERT = 'hide-dl-search-successful-search-alert';
