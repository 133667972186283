import type { LicenseSubject, LicenseType, LicenseState } from 'license/types';

export const LICENSE_SUBJECTS = {
  enterprise: '/license/enterprise',
  archive: '/license/enterprise/archive',
  auditlog: '/license/enterprise/auditlog',
  illuminate: '/license/enterprise/illuminate',
  searchFilter: '/license/enterprise/search-filter',
  customization: '/license/enterprise/customization',
  views: '/license/enterprise/views',
  forwarder: '/license/enterprise/forwarder',
  report: '/license/enterprise/report',
  security: '/license/security',
  anomaly: '/license/anomaly',
} as const;

export const LICENSE_TYPES: { [key in LicenseSubject]: string } = {
  '/license/enterprise': 'Graylog Enterprise',
  '/license/enterprise/customization': 'Graylog Enterprise (Customization)',
  '/license/enterprise/archive': 'Graylog Enterprise (Archiving)',
  '/license/enterprise/auditlog': 'Graylog Enterprise (Audit Log)',
  '/license/enterprise/illuminate': 'Graylog Enterprise (Illuminate)',
  '/license/enterprise/report': 'Graylog Enterprise (Reporting)',
  '/license/enterprise/search-filter': 'Graylog Enterprise (Search Filter)',
  '/license/enterprise/views': 'Graylog Enterprise (Views)',
  '/license/enterprise/forwarder': 'Graylog Enterprise (Forwarder)',
  '/license/security': 'Graylog Security',
  '/license/anomaly': 'Graylog Anomaly Detection',
};

export const LICENSE_TYPE: { [key: string]: LicenseType } = {
  drawDown: 'Draw-Down',
  dailyUsage: 'Daily Usage',
};

export const LICENSE_STATUS: { [key: string]: LicenseState } = {
  active: 'ACTIVE',
  queued: 'QUEUED',
  expired: 'EXPIRED',
  inactive: 'INACTIVE',
};

export const DRAWDOWN_PRODUCTS: { [key: string]: string | undefined } = {
  enterprise: 'Enterprise',
  security: 'Security',
  anomaly: 'Anomaly',
  volume: 'Volume',
};

export const DRAWDOWN_PRODUCTS_NAMES: { [key: string]: string | undefined } = {
  Enterprise: 'Graylog Enterprise',
  Security: 'Graylog Security',
  Anomaly: 'Graylog Anomaly Detection',
  Volume: undefined,
};
