import React from 'react';

import type {
  Forwarder,
  ForwardersContext as ForwardersContextType,
  ForwarderContext,
  ForwarderPagination,
  SortOrder,
  ForwarderStateFilter,
} from 'forwarder/Types';

type ContextType = {
  changePagination?: (page?: number, perPage?: number, query?: string) => void;
  changeSort?: (sortByField: string, order: SortOrder) => void;
  changeQuery?: (query: string | undefined) => void;
  changeStateFilter?: (stateFilter: ForwarderStateFilter) => void;
  forwarders: Array<Forwarder>;
  forwardersContext: ForwardersContextType;
  isLoading: boolean;
  pagination?: ForwarderPagination;
  getForwarderContext: (forwarderId: string) => ForwarderContext;
};

export const getForwarderContextById = (forwardersContext: ForwardersContextType) => (id: string) =>
  forwardersContext[id] || { state: undefined, metrics: [] };

const ForwardersContext = React.createContext<ContextType>({
  forwarders: [],
  forwardersContext: {},
  isLoading: false,
  getForwarderContext: () => undefined,
});

export default ForwardersContext;
