import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';
import useParams from 'routing/useParams';

import ReportConfiguration from './ReportConfiguration';

import ReportSubNav from '../common/ReportSubNav';
import useReportAndLogo from '../hooks/useReportAndLogo';

const ReportConfigurationPage = () => {
  const { reportId } = useParams();
  const { report, reportLogo } = useReportAndLogo(reportId);

  if (!report) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  return (
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report} displayLicenseWarning={false} hideChildren>
      <DocumentTitle title={`Report ${report.title} configuration`}>
        <PageHeader
          title={
            <span>
              Report <em>{report.title}</em> configuration
            </span>
          }
          actions={<ReportSubNav reportId={report.id} />}
          documentationLink={{
            title: 'Reporting documentation',
            path: DocsHelper.PAGES.REPORTING,
          }}>
          <span>Configure the report layout and delivery schedule, adapting it to your needs.</span>
        </PageHeader>

        <Row className="content">
          <Col md={12}>
            <ReportConfiguration report={report} reportLogo={reportLogo} />
          </Col>
        </Row>
      </DocumentTitle>
    </LicenseCheck>
  );
};

const ReportConfigurationPageWrapper = () => (
  <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report} bsStyle="danger" displayWarningContainer hideChildren>
    <ReportConfigurationPage />
  </LicenseCheck>
);

export default ReportConfigurationPageWrapper;
