import * as React from 'react';

import { Spinner } from 'components/common';
import { ConfirmDialog } from 'common/components';
import { useGetFilteredPacks, useEnableLatestPacks } from 'illuminate/hooks/usePacksAPI';
import type { Pack } from 'illuminate/types';

import PackList from './PackList';

type Props = {
  onClose: () => void;
};

function Updates({ onClose }: Props) {
  const { filteredPacks, loadingFilteredPacks } = useGetFilteredPacks({ update_available: true });
  const [packs, setPacks] = React.useState<Pack[]>([]);
  const { enablePacks, enablingPacks } = useEnableLatestPacks();

  React.useEffect(() => {
    const packsArr = filteredPacks.filter((pack) => pack.enabled);

    setPacks(packsArr);
  }, [filteredPacks]);

  const onConfirm = async () => {
    const requestObject = {
      processing_pack_ids: [...packs]
        .filter((pack: Pack) => pack.type === 'PROCESSING')
        .map((pack: Pack) => pack.pack_id),
      spotlight_pack_ids: [...packs]
        .filter((pack: Pack) => pack.type === 'SPOTLIGHT')
        .map((pack: Pack) => pack.pack_id),
    };

    await enablePacks({ requestObject });
    onClose();
  };

  return (
    <ConfirmDialog
      show
      onConfirm={onConfirm}
      confirmText="Enable Packs"
      isLoading={enablingPacks}
      confirmDisabled={enablingPacks}
      onCancel={onClose}
      title="Illuminate Updates Available">
      {loadingFilteredPacks ? (
        <Spinner text="Loading updated packs" />
      ) : (
        <>
          <h3>Updated Packs</h3>
          <PackList packs={packs} />
        </>
      )}
    </ConfirmDialog>
  );
}

export default Updates;
