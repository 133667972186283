import type View from 'views/logic/views/View';

const CopyParamsToView = (sourceView: View, targetView: View): View => {
  const { parameters } = sourceView.search;

  if (!parameters || parameters.isEmpty()) {
    return targetView;
  }

  const parametersToCopy = parameters.filter(
    (searchParameter) =>
      !targetView.search.parameters.find((dashboardParameter) => searchParameter.name === dashboardParameter.name),
  );
  const newParameters = targetView.search.parameters.union(parametersToCopy);
  const newSearch = targetView.search.toBuilder().parameters(newParameters.toJS()).newId().build();

  return targetView.toBuilder().search(newSearch).build();
};

export default CopyParamsToView;
