import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Routes from 'security-app/routing/Routes';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';

const useViewInvestigation = () => {
  const navigate = useNavigate();
  const { setCollapsed, setShowDrawer, setSelectedInvestigationId } = useInvestigationDrawer();
  const { activePerspective } = useActivePerspective();

  const isSecurityPerspective = activePerspective.id === 'security';

  return useCallback(
    (investigationId: string) => {
      if (!isSecurityPerspective) {
        setSelectedInvestigationId(investigationId);
        setShowDrawer(true);
        setCollapsed(false);
      } else {
        navigate(Routes.INVESTIGATIONS.details(investigationId));
      }
    },
    [isSecurityPerspective, navigate, setCollapsed, setSelectedInvestigationId, setShowDrawer],
  );
};

export default useViewInvestigation;
