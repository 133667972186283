import * as React from 'react';

import Spinner from 'components/common/Spinner';
import ReportingWidget from 'report/common/ReportingWidget';
import type { ParameterValues } from 'report/types';
import useWidgetResult from 'report/hooks/useWidgetResult';

type Props = Pick<
  React.ComponentProps<typeof ReportingWidget>,
  | 'widget'
  | 'interactive'
  | 'showCaption'
  | 'hideDescription'
  | 'hideQuery'
  | 'showHeading'
  | 'showHandle'
  | 'limitHeight'
  | 'width'
  | 'height'
> & {
  parameterValues: ParameterValues;
  dashboardId: string;
  widgetId: string;
};

const ReportingWidgetPreview = ({ dashboardId, widgetId, parameterValues, widget, height, width, ...rest }: Props) => {
  const { data: value, isLoading, isError, error } = useWidgetResult({ dashboardId, widgetId, parameterValues });

  if (isError) {
    return (
      <span>
        <strong>Error while fetching widget:</strong> <p>{JSON.stringify(error, null, 2)}</p>
      </span>
    );
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <ReportingWidget {...rest} widget={widget} value={value} height={height} width={width} />
  );
};

export default ReportingWidgetPreview;
