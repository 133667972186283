import Reflux from 'reflux';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

import type { ForwarderInputDescriptions, ForwarderInputTypes } from '../Types';

type TypesResponse = {
  types: ForwarderInputTypes;
};

type ForwarderInputTypesActionsType = {
  list: () => Promise<[typesResponse: TypesResponse, descriptionsResponse: ForwarderInputDescriptions]>;
  get: (inputTypeId: string) => Promise<TypesResponse>;
};

type ForwarderInputTypesStoreState = {
  forwarderInputTypes: ForwarderInputTypes;
  forwarderInputDescriptions: ForwarderInputDescriptions;
};
export const ForwarderInputTypesActions = Reflux.createActions<ForwarderInputTypesActionsType>({
  list: { asyncResult: true },
  get: { asyncResult: true },
});

const ForwarderInputTypesStore = Reflux.createStore<ForwarderInputTypesStoreState>({
  listenables: [ForwarderInputTypesActions],
  sourceUrl: '/plugins/org.graylog.plugins.forwarder/forwarder/inputs/types',
  forwarderInputTypes: undefined,
  forwarderInputDescriptions: undefined,

  init() {
    this.list();
  },

  getInitialState(): {
    forwarderInputTypes: ForwarderInputTypes;
    forwarderInputDescriptions: ForwarderInputDescriptions;
  } {
    return {
      forwarderInputTypes: this.forwarderInputTypes,
      forwarderInputDescriptions: this.forwarderInputDescriptions,
    };
  },

  list() {
    const promiseTypes = fetch('GET', qualifyUrl(this.sourceUrl));
    const promiseDescriptions = fetch('GET', qualifyUrl(`${this.sourceUrl}/all`));
    const promise = Promise.all([promiseTypes, promiseDescriptions]);

    promise.then(
      ([typesResponse, descriptionsResponse]: [
        typesResponse: TypesResponse,
        descriptionsResponse: ForwarderInputDescriptions,
      ]) => {
        this.forwarderInputTypes = typesResponse.types;
        this.forwarderInputDescriptions = descriptionsResponse;
        this.trigger(this.getInitialState());
      },
      (error) => {
        UserNotification.error(`Fetching Input Types failed with status: ${error}`, 'Could not retrieve Inputs');
      },
    );

    ForwarderInputTypesActions.list.promise(promise);
  },

  get(inputTypeId: string) {
    const promise = fetch('GET', qualifyUrl(`${this.sourceUrl}/${inputTypeId}`));

    promise.catch((error) => {
      UserNotification.error(`Fetching input ${inputTypeId} failed with status: ${error}`, 'Could not retrieve input');
    });

    ForwarderInputTypesActions.get.promise(promise);
  },
});

export default ForwarderInputTypesStore;
