import * as React from 'react';

import { SelectedRowsProvider, PaginationProvider } from 'common/contexts';
import type { Pack } from 'illuminate/types';
import { Alert } from 'components/bootstrap';
import { Spinner } from 'components/common';
import IlluminatePacksList from 'security-app/components/common/ThreatCoverageWidget/ImproveCoverageModal/IlluminatePacksList';
import PacksInstallStatus from 'security-app/components/common/ThreatCoverageWidget/PacksInstallStatus';
import Modals from 'illuminate/components/content-hub/modals';

import useThreatRelatedIlluminatePacks from './useThreatRelatedIlluminatePacks';

const IlluminatePacksEnable = () => {
  const { allPacks, notEnabledPacks, isInitialLoading } = useThreatRelatedIlluminatePacks();

  if (isInitialLoading) {
    return <Spinner />;
  }

  return (
    <SelectedRowsProvider<Pack>>
      <PaginationProvider initFilters={{ tags: 'attack' }}>
        {allPacks.length > 0 && notEnabledPacks.length === 0 ? (
          <Alert bsStyle="success">All related Illuminate Packs have been activated</Alert>
        ) : (
          <div>
            <PacksInstallStatus>
              <IlluminatePacksList />
            </PacksInstallStatus>
            <Modals />
          </div>
        )}
      </PaginationProvider>
    </SelectedRowsProvider>
  );
};

export default IlluminatePacksEnable;
