import * as React from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

const Headline = styled.h3`
  margin-bottom: 15px;

  @media print {
    margin-top: 15px;

    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-inside: avoid;
    break-after: avoid;
  }
`;

type Props = {
  title: React.ReactNode;
  headerId?: string;
};

const Heading = forwardRef<HTMLHeadingElement, Props>(({ title, headerId }, ref) => (
  <Headline ref={ref} id={headerId}>
    {title}
  </Headline>
));

export default Heading;
