import * as React from 'react';

import { useModalContext } from 'common/contexts';

import Updates from './Updates';
import ConfEnableDisablePacks from './ConfActionOnPacks';

export type ModalTypes =
  | 'UPDATES'
  | 'CONFIRM_ENABLE_PACKS'
  | 'CONFIRM_DISABLE_PACKS';

function Modals() {
  const { modal, setModal, setEntity } = useModalContext();

  const onClose = () => {
    setEntity(null);
    setModal(null);
  };

  switch (modal) {
    case 'UPDATES':
      return <Updates onClose={onClose} />;
    case 'CONFIRM_ENABLE_PACKS':
    case 'CONFIRM_DISABLE_PACKS':
      return <ConfEnableDisablePacks onCancel={onClose} />;
    default:
      return null;
  }
}

export default Modals;
