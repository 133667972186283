import * as React from 'react';

import { singleton } from 'logic/singleton';
import type { SelectableLogViewMessage } from 'logview/types';

export interface SelectableLogViewMessagesState {
  selectableLogViewMessages: Array<SelectableLogViewMessage>;
}

const SelectableLogViewMessagesContext = React.createContext<SelectableLogViewMessagesState | undefined>(undefined);

export default singleton(
  'contexts.enterprise.SelectableLogViewMessagesContext',
  () => SelectableLogViewMessagesContext,
);
