/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import MongoDBDataAdapterTable from './MongoDBDataAdapterTable';

type Props = {
  dataAdapterId: string;
};

const MongoDBDataAdapterDocumentation = ({ dataAdapterId }: Props) => {
  const cidrEntries = `key: 192.168.100.0/24, value: "Finance Department subnet"
key: 192.168.101.0/24, value: "IT Department subnet"
key: 192.168.102.0/24, value: "HR Department subnet"`;

  return (
    <div>
      <h3 style={{ marginBottom: 10 }}>CIDR Lookups</h3>
      <p style={{ marginBottom: 10, padding: 0 }}>
        If this data adapter will be used to lookup IP address keys against CIDR addresses
        <br />
        then it should be marked as a CIDR lookup. For example:
        <br />
      </p>

      <pre>{cidrEntries}</pre>

      <p>
        These entries in a CIDR lookup adapter when given the key 192.168.101.64 would return 'IT Department subnet'.
      </p>
      <MongoDBDataAdapterTable dataAdapterId={dataAdapterId} />
    </div>
  );
};

export default MongoDBDataAdapterDocumentation;
