import * as React from 'react';

import { Spinner } from 'components/common';
import { Col, Row } from 'common/components/layout-styles';
import { useGetAssetsByIds } from 'security-app/hooks/useAssetsAPI';
import type { AssetAPIType, AssetDetailsType } from 'security-app/hooks/api/assetsAPI.types';

import AssetDetailsOverlay from './AssetDetailsOverlay';

type Props = {
  assetIds: string[];
  direction?: 'col' | 'row';
  addToQuery?: (id: string) => void;
};

function AssocAsssetsWidget({ assetIds, direction = 'row', addToQuery = undefined }: Props) {
  const { assets, fetchingAssets } = useGetAssetsByIds(assetIds);
  const ColRow = direction === 'row' ? Row : Col;

  if (fetchingAssets) return <Spinner text="Loading Associated Assets" />;

  if (assets.length < 1) return null;

  return (
    <ColRow $gap="xs">
      {assets.map((asset: AssetAPIType<AssetDetailsType>) => (
        <AssetDetailsOverlay key={asset.id} asset={asset} addToQuery={addToQuery} />
      ))}
    </ColRow>
  );
}

export default AssocAsssetsWidget;
