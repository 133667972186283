import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

const urlPrefix = '/plugins/org.graylog.plugins.securityapp.investigations/ai/config';

type AIReportConfigurationJson = {
  investigations_ai_reports_enabled: boolean;
};

export type AIReportConfigurationType = {
  isEnabled: boolean;
};

export const AI_REPORT_CONFIGURATION_QUERY_STRING = 'ai-report-configuration';
const getAIReportConfiguration = (): Promise<AIReportConfigurationType> =>
  fetch('GET', qualifyUrl(urlPrefix)).then((response: AIReportConfigurationJson) => ({
    isEnabled: response?.investigations_ai_reports_enabled !== false,
  }));

const useAIReportConfiguration = (): {
  data: AIReportConfigurationType;
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<AIReportConfigurationType>([AI_REPORT_CONFIGURATION_QUERY_STRING], () =>
    defaultOnError(
      getAIReportConfiguration(),
      'Fetching AI Report Configuration with status',
      'Could not fetch AI Report Configuration',
    ),
  );

  return {
    data: data ?? {
      isEnabled: false,
    },
    isLoading,
  };
};

export default useAIReportConfiguration;
