import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { Spinner } from 'components/common';
import ActiveDrawDownLicenseMetrics from 'license/ActiveDrawDownLicenseMetrics';
import DrawDownLicenseVolumeGraph from 'license/DrawDownLicenseVolumeGraph';
import ActiveLicenseMetrics from 'license/ActiveLicenseMetrics';
import useActiveLicenseInformation from 'license/hooks/useActiveLicenseInformation';
import { LICENSE_TYPE } from 'license/constants';
import { isEnterpriseLicense } from 'license/helper/activeLicense';
import { useGraphWidth } from 'components/common/Graph';
import EnterpriseTrafficGraph from 'license/EnterpriseTrafficGraph';
import ClusterTrafficGraph from 'components/cluster/ClusterTrafficGraph';
import GraphDaysContextProvider from 'components/common/Graph/contexts/GraphDaysContextProvider';

const LicenseGraphWithMetrics = () => {
  const { graphContainerRef } = useGraphWidth();
  const { data: activeLicenseData, isLoading: isLoadingActiveLicense } = useActiveLicenseInformation();

  if (isLoadingActiveLicense) return <Spinner />;

  return (
    <GraphDaysContextProvider>
      <Row>
        <Col md={12} ref={graphContainerRef}>
          {isEnterpriseLicense(activeLicenseData) ? (
            <>
              {activeLicenseData?.license_type === LICENSE_TYPE.dailyUsage && <EnterpriseTrafficGraph />}
              {activeLicenseData?.license_type === LICENSE_TYPE.drawDown && <DrawDownLicenseVolumeGraph />}
            </>
          ) : (
            <ClusterTrafficGraph />
          )}
        </Col>
      </Row>
      {isEnterpriseLicense(activeLicenseData) && (
        <>
          {activeLicenseData?.license_type === LICENSE_TYPE.drawDown && <ActiveDrawDownLicenseMetrics />}
          {activeLicenseData?.license_type === LICENSE_TYPE.dailyUsage && <ActiveLicenseMetrics />}
        </>
      )}
    </GraphDaysContextProvider>
  );
};

export default LicenseGraphWithMetrics;
