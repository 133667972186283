import * as React from 'react';
import { useMemo } from 'react';

import { Alert, Col, Row } from 'components/bootstrap';
import { LICENSE_SUBJECTS } from 'license/constants';
import type { LicenseValidity } from 'license/types';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';

const licenseStatus = (status: LicenseValidity) => {
  if (status.valid) {
    return 'valid';
  }

  if (status.expired) {
    return 'expired';
  }

  return 'invalid';
};

type Props = {
  usesFilters: boolean;
  usesParameters: boolean;
};

const MissingEnterpriseLicense = ({ usesFilters, usesParameters }: Props) => {
  const { data, isInitialLoading: isLoading } = useLicenseValidityForSubject(LICENSE_SUBJECTS.views);

  const inUse = useMemo(
    () => `
  ${usesParameters ? 'parameter' : ''}
  ${usesFilters && usesParameters ? ' or ' : ''}
  ${usesFilters ? 'filters' : ''}`,
    [usesFilters, usesParameters],
  );

  if (isLoading) return null;

  return (
    <Row className="content">
      <Col md={12}>
        <Alert bsStyle="danger" title={`Your Graylog Enterprise license is ${licenseStatus(data)}`}>
          {`To be able to use ${inUse} support in views, you need to obtain a valid Graylog Enterprise license.
          For further information, please check the `}
          <a href="https://www.graylog.org/enterprise" rel="noopener noreferrer">
            Graylog Enterprise page
          </a>
          .
        </Alert>
      </Col>
    </Row>
  );
};

export default MissingEnterpriseLicense;
