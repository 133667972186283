import * as React from 'react';

import { Spinner } from 'components/common';
import SectionGrid from 'components/common/Section/SectionGrid';

import ProfileSection from './ProfileSection';
import SharedEntitiesSection from './SharedEntitiesSection';
import UsersSection from './UsersSection';
import RolesSection from './RolesSection';

import type Team from '../../logic/Team';

type Props = {
  team: Team | null | undefined;
};

const TeamDetails = ({ team }: Props) => {
  if (!team) {
    return <Spinner />;
  }

  return (
    <>
      <SectionGrid>
        <div>
          <ProfileSection team={team} />
        </div>
        <div>
          <UsersSection team={team} />
          <RolesSection team={team} />
        </div>
      </SectionGrid>
      <SharedEntitiesSection teamId={team.id} />
    </>
  );
};

export default TeamDetails;
