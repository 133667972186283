import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Routes from 'routing/Routes';
import { qualifyUrl } from 'util/URLUtils';
import { IfPermitted, IconButton } from 'components/common';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import useScopePermissions from 'hooks/useScopePermissions';
import { useModal, ConfirmDialog } from 'security-app/components/common/Modal';
import { useDeleteSigmaRule } from 'security-app/hooks/useSigmaAPI';
import { useSelectedRowsDispatch } from 'common/contexts';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';
import { MenuItem, DeleteMenuItem } from 'components/bootstrap';
import { DropdownMenu } from 'security-app/components/common';

type Props = {
  rule: SigmaRuleListAPIType;
  editRule: (rule: SigmaRuleListAPIType) => void;
};

function RowActions({ rule, editRule }: Props) {
  const { deleteSigmaRule } = useDeleteSigmaRule();
  const selectedRulesDispatch = useSelectedRowsDispatch();
  const [showDeleteConfirm, setShowDeleteConfirm] = useModal();
  const { scopePermissions } = useScopePermissions(rule);
  const isMutable = React.useMemo(() => scopePermissions?.is_mutable, [scopePermissions]);

  const onEdit = () => {
    editRule(rule);
  };

  const onClone = () => {
    const auxRule = { ...rule, id: null, _scope: 'DEFAULT' };

    auxRule.source = auxRule.source
      .replace(/title: .+/, `title: CLONE - ${rule.title}`)
      .replace(/id: .+/, `id: ${uuidv4()}`);

    editRule(auxRule);
  };

  const onDownload = () => {
    window.open(qualifyUrl(EnterpriseApiRoutes.SecurityApp.downloadSigmaRule(`ruleIds=${rule.id}`).url), '_self');
  };

  const onEditEventDefinition = () => {
    window.open(Routes.ALERTS.DEFINITIONS.edit(rule.event_definition_id), '_blank');
  };

  const getRuleTimeRangeSeconds = (rangeNumber: number, rangeUnit: string): number => {
    let rangeValue = rangeNumber;
    if (['MINUTES', 'HOURS'].includes(rangeUnit)) rangeValue *= 60;
    if (rangeUnit === 'HOURS') rangeValue *= 60;

    return rangeValue;
  };

  const onSearchLogs = () => {
    if (rule.query) {
      const relativeSeconds = getRuleTimeRangeSeconds(rule.search_within, rule.search_within_unit);
      window.open(
        Routes.search_with_query(
          rule.query,
          'relative',
          { relative: relativeSeconds },
          rule.streams,
          rule.stream_categories,
        ),
        '_blank',
      );
    } else if (rule.use_event_definition_replay) {
      window.open(Routes.ALERTS.DEFINITIONS.replay_search(rule.event_definition_id), '_blank');
    }
  };

  const onDelete = () => {
    setShowDeleteConfirm(true);
  };

  const onConfirmedDelete = () => {
    deleteSigmaRule(
      { ruleId: rule.id },
      { onSuccess: () => selectedRulesDispatch({ type: 'remove', payload: [rule] }) },
    );
  };

  return (
    <>
      <DropdownMenu position="bottom-end" trigger={<IconButton name="more_horiz" title="Toggle rule actions" />}>
        {(rule.query || rule.use_event_definition_replay) && (
          <MenuItem data-testid="rule-action-search-log" onClick={onSearchLogs}>
            Search Logs
          </MenuItem>
        )}
        <IfPermitted permissions="sigma_rules:edit">
          <MenuItem onClick={onEdit}>Edit</MenuItem>
          <MenuItem onClick={onClone}>Clone</MenuItem>
          <MenuItem onClick={onDownload}>Download</MenuItem>
          <MenuItem onClick={onEditEventDefinition}>Edit Event Definition</MenuItem>
          {isMutable && (
            <>
              <MenuItem divider />
              <DeleteMenuItem onClick={onDelete} />
            </>
          )}
        </IfPermitted>
      </DropdownMenu>
      <ConfirmDialog
        show={showDeleteConfirm}
        onConfirm={onConfirmedDelete}
        confirmText="Delete rule"
        onCancel={() => setShowDeleteConfirm(false)}
        title="Delete rule">
        <>
          <p>Are you sure you want to delete the following rule?:</p>
          <p>{rule.title}</p>
        </>
      </ConfirmDialog>
    </>
  );
}

export default RowActions;
