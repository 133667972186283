import React from 'react';

import { Col, Row } from 'components/bootstrap';
import { EmptyEntity } from 'components/common';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

import CreateForwarderButton from './CreateForwarderButton';

const EmptyForwarderListComponent = () => {
  const currentUser = useCurrentUser();

  return (
    <Row className="content">
      <Col md={12}>
        <Row>
          <Col md={6} mdOffset={3} lg={4} lgOffset={4}>
            <EmptyEntity>
              {isPermitted(currentUser?.permissions, 'forwarders:create') ? (
                <>
                  <p>
                    Forwarders allow you to send log messages from your local network to Graylog. Click on the button
                    below to get started and configure your first Forwarder.
                  </p>
                  <CreateForwarderButton buttonTitle="Get Started!" />
                </>
              ) : (
                <p>Forwarders allow you to send log messages from your local network to Graylog.</p>
              )}
            </EmptyEntity>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmptyForwarderListComponent;
