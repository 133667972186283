import __request__ from 'routing/request';
interface PaginatedResponse_InvestigationListItem {
    readonly [_key: string]: Object;
}
interface UsersResponse {
    readonly users: SimpleUserDto[];
}
interface InvestigationFilterOptionsRequest {
    readonly archived: boolean;
    readonly fields: string[];
}
interface BulkUpdateRequest {
    readonly archived: boolean;
    readonly name: string;
    readonly id: string;
    readonly priority: number;
    readonly status: string;
    readonly assigned_to: string;
}
interface InvestigationFilterOptions {
    readonly id: string;
    readonly priority: number[];
    readonly status: string[];
    readonly assigned_to: string[];
}
interface SimilarEvidenceResponse {
    readonly logs: SimilarMessage[];
    readonly events: SimilarEvent[];
}
interface SimilarEvent {
    readonly event: EventDto;
    readonly matching_fields: string[];
}
interface ViewDetails {
    readonly id: string;
    readonly type: 'SEARCH' | 'DASHBOARD';
    readonly title: string;
}
interface EventReplayInfo {
    readonly timerange_end: string;
    readonly query: string;
    readonly streams: string[];
    readonly filters: UsedSearchFilter[];
    readonly timerange_start: string;
}
interface BulkUpdateRequests {
    readonly requests: BulkUpdateRequest[];
}
interface BulkInvestigationRequest {
    readonly ids: string[];
}
interface PortInfo {
    readonly protocol: string;
    readonly port: string;
}
interface InvestigationDetails {
    readonly summary: string;
    readonly searches: ViewDetails[];
    readonly notes: NoteDetails[];
    readonly created_at: string;
    readonly priority: number;
    readonly dashboards: ViewDetails[];
    readonly associated_assets: AssetResponse[];
    readonly archived: boolean;
    readonly updated_at: string;
    readonly name: string;
    readonly id: string;
    readonly logs: EvidenceLog[];
    readonly summary_updated_at: string;
    readonly events: EventDto[];
    readonly status: string;
    readonly assigned_to: string;
}
interface SimilarMessage {
    readonly message: EvidenceLog;
    readonly matching_fields: string[];
}
interface AssetVulnerabilities {
    readonly scanner_type: string;
    readonly scanner_id: string;
    readonly hostname: string;
    readonly os: string;
    readonly ip: string;
    readonly vulnerabilities: Vulnerability[];
    readonly scan_id: string;
    readonly scan_name: string;
    readonly ports: PortInfo[];
    readonly mac: string;
}
interface Sort {
    readonly field: string;
    readonly order: 'ASC' | 'DESC';
}
interface UpdateInvestigationRequest {
    readonly archived: boolean;
    readonly name: string;
    readonly priority: number;
    readonly status: string;
    readonly assigned_to: string;
}
interface UpdateEvidenceRequest {
    readonly searches: string[];
    readonly logs: EvidenceLog[];
    readonly dashboards: string[];
    readonly events: string[];
}
interface SimpleUserDto {
    readonly full_name: string;
    readonly id: string;
}
interface NoteDetails {
    readonly created_at: string;
    readonly id: string;
    readonly created_by: string;
    readonly content: string;
}
interface UsedSearchFilter {
}
interface TeamsResponse {
    readonly teams: SimpleTeamDto[];
}
interface EventDto {
    readonly timerange_end: string;
    readonly timestamp_processing: string;
    readonly origin_context: string;
    readonly scores: {
        readonly [_key: string]: number;
    };
    readonly replay_info: EventReplayInfo;
    readonly streams: string[];
    readonly source_streams: string[];
    readonly source: string;
    readonly priority: number;
    readonly message: string;
    readonly associated_assets: string[];
    readonly group_by_fields: {
        readonly [_key: string]: string;
    };
    readonly key_tuple: string[];
    readonly alert: boolean;
    readonly event_definition_type: string;
    readonly event_definition_id: string;
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: string;
    };
    readonly key: string;
    readonly timestamp: string;
    readonly timerange_start: string;
}
interface Vulnerability {
    readonly severity: 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW' | 'INFO' | 'NONE';
    readonly cve: string;
    readonly vulnerability_id: string;
    readonly cvss: number;
}
interface AssetResponse {
    readonly risk_score_normalized: number;
    readonly rev: number;
    readonly risk_score: number;
    readonly imported_from: string;
    readonly created_at: string;
    readonly dn: string;
    readonly rev_date: string;
    readonly priority: number;
    readonly name: string;
    readonly vulnerabilities: AssetVulnerabilities[];
    readonly details: AssetDetails;
    readonly id: string;
    readonly category: string[];
}
interface CreateInvestigationRequest {
    readonly notes: string;
    readonly name: string;
    readonly priority: number;
    readonly status: string;
    readonly assigned_to: string;
}
interface SimpleTeamDto {
    readonly name: string;
    readonly id: string;
}
interface UpdateInvestigationResponse {
    readonly errors: string[];
}
interface InvestigationsExportRequest {
    readonly exported_field_names: string[];
    readonly limit: number;
    readonly filters: AttributeFilter[];
    readonly sorts: Sort[];
}
interface BulkAddEvidenceRequest {
    readonly investigation_ids: string[];
    readonly searches: string[];
    readonly logs: EvidenceLog[];
    readonly dashboards: string[];
    readonly events: string[];
}
interface AttributeFilter {
    readonly field: string;
    readonly value: string[];
}
interface AssetDetails {
    readonly type: string;
}
interface EvidenceLog {
    readonly index: string;
    readonly id: string;
    readonly timestamp: string;
}
/**
 * Edit multiple existing investigations
 */
export function bulkEditInvestigations(JSONBody?: BulkUpdateRequests): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/investigations', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of existing investigations
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listInvestigations(sort: 'name' = 'name', page: number = 1, per_page: number = 15, query?: string, filters?: string, user_timezone: string = 'UTC', direction: 'asc' | 'desc' = 'asc', archived: boolean = false): Promise<PaginatedResponse_InvestigationListItem> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'user_timezone': user_timezone, 'sort': sort, 'direction': direction, 'archived': archived }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new investigation
 */
export function create(JSONBody?: CreateInvestigationRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/investigations', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Change the archive status of multiple investigations
 */
export function bulkArchive(JSONBody?: BulkUpdateRequests): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/investigations/archive', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Change the archive status of an investigation
 */
export function archive(id: string, archive: boolean): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/archive/${id}/${archive}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Bulk delete investigations
 */
export function bulkDeleteInvestigations(JSONBody?: BulkInvestigationRequest): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/investigations/bulk/delete', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add evidence to multiple investigations
 */
export function addEvidence(JSONBody?: BulkAddEvidenceRequest): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/investigations/bulk/evidence/add', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove evidence from an existing investigation
 */
export function removeEvidence(id: string, JSONBody?: UpdateEvidenceRequest): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/evidence/remove/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update evidence for an existing investigation
 */
export function updateEvidence(id: string, JSONBody?: UpdateEvidenceRequest): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/evidence/update/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add evidence to an existing investigation
 */
export function addEvidenceByidAndJSONBody(id: string, JSONBody?: UpdateEvidenceRequest): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/evidence/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve messages/events from evidence of the investigation similar to the specified event.
 */
export function similarEventEvidence(id: string, eventId: string): Promise<SimilarEvidenceResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/investigations/evidence/${id}/similar/event/${eventId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve messages/events from evidence of the investigation similar to the specified message.
 */
export function similarMessageEvidence(id: string, index: string, messageId: string): Promise<SimilarEvidenceResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/investigations/evidence/${id}/similar/message/${index}/${messageId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Export investigation widget data
 * @param filename filename
 */
export function export_(arg0?: InvestigationsExportRequest, filename?: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/investigations/export/${filename}`, arg0, {}, {
        'Accept': [
            'text/csv',
            'application/json',
            'application/yaml',
            'application/xml',
            'application/vnd.ms-excel'
        ]
    });
}
/**
 * Get all possible values for a list of fields
 */
export function getFilterOptions(JSONBody?: InvestigationFilterOptionsRequest): Promise<InvestigationFilterOptions> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/investigations/filter_options', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve list of teams available to be assigned to investigations
 */
export function getInvestigationTeams(): Promise<TeamsResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/teams', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve list of users available to be assigned to investigations
 */
export function getInvestigationUsers(): Promise<UsersResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/users', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a detailed view of an investigation
 */
export function getInvestigation(id: string): Promise<InvestigationDetails> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/investigations/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Edit an existing investigation
 */
export function editInvestigation(id: string, JSONBody?: UpdateInvestigationRequest): Promise<UpdateInvestigationResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Deletes an existing investigation
 */
export function deleteInvestigation(investigationId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/investigations/${investigationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Adds a new note to an investigation
 */
export function addNewNote(investigationId: string, note?: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/investigations/${investigationId}/notes`, note, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Edits an existing note of an investigation
 */
export function editNote(investigationId: string, noteId: string, note?: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/${investigationId}/notes/${noteId}`, note, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Deletes an existing note from an investigation
 */
export function deleteNote(investigationId: string, noteId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/investigations/${investigationId}/notes/${noteId}`, null, {}, {
        'Accept': ['application/json']
    });
}
