import { qualifyUrls } from 'routing/Routes';

const F5NetworksRoutes = {
  INTEGRATIONS: {
    F5Networks: {
      ACTIVITYAPI: {
        index: '/integrations/f5',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    F5Networks: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/f5networks/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/f5networks/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    F5Networks: {
      GRAYLOG_F5Networks_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_f5_ActivityLog_Input.html#graylog-f5-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(F5NetworksRoutes),
  unqualified: F5NetworksRoutes,
};

export { DocsRoutes, ApiRoutes };
