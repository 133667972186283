import React, { useContext, useMemo } from 'react';
import flatten from 'lodash/flatten';

import ListStateContext from 'logview/components/contexts/ListStateContext';
import type { LogViewMessage } from 'logview/types';
import SelectableEntitiesContext from 'logview/components/contexts/SelectableLogViewMessagesContext';

const defaultIsEntitySelectable = () => true;
const SelectableLogViewMessagesProvider = ({
  children = null,
  isEntitySelectable = defaultIsEntitySelectable,
  displayBulkSelectCol = false,
}: React.PropsWithChildren<{
  displayBulkSelectCol?: boolean,
  isEntitySelectable?: (message: LogViewMessage) => boolean;
}>) => {
  const { allLoadedPages } = useContext(ListStateContext);
  const contextValues = useMemo(() => {
    if(!displayBulkSelectCol) return ({ selectableLogViewMessages: [] })

    return ({
      selectableLogViewMessages: flatten(Object.values(allLoadedPages))
        .filter(isEntitySelectable)
        .map(({ message }: LogViewMessage) => ({
          id: message._id,
          timestamp: message.timestamp,
        })),
    });
  }, [allLoadedPages, displayBulkSelectCol, isEntitySelectable]);

  return <SelectableEntitiesContext.Provider value={contextValues}>{children}</SelectableEntitiesContext.Provider>;
};

export default SelectableLogViewMessagesProvider;
