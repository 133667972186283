import React, { useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './Routes';

import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    fetch('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.Sophos.CHECK_CREDENTIALS), {
      sophos_client_id: formData?.sophosClientId?.value || '',
      sophos_client_secret: formData?.sophosClientSecret?.value || '',
    })
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message: 'Unable to connect to Sophos using provided configuration.',
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        setFormError({
          full_message: err.message,
          nice_message: 'Unable to connect to Sophos using provided configuration.',
        });
      });
  };

  const isFormValid = formValidation.isFormValid(['sophosName', 'sophosClientId', 'sophosClientSecret'], formData);

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      loading={loading}
      title=""
      error={formError}
      description="">
      <ValidatedInput
        className=""
        id="sophosName"
        type="text"
        fieldData={formData.sophosName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        required
      />
      <ValidatedInput
        className=""
        id="sophosClientId"
        type="text"
        onChange={onChange}
        fieldData={formData.sophosClientId}
        label="Sophos Client ID"
        help="client ID for sophos account."
        required
      />
      <ValidatedInput
        className=""
        id="sophosClientSecret"
        type="password"
        onChange={onChange}
        fieldData={formData.sophosSecret}
        label="Sophos Client Secret"
        help="client secret for sophos account."
        required
      />
    </FormWrap>
  );
};

export default StepAuthorize;
