import readableFilterUsagesSummary from 'search-filter/readableFilterUsagesSummary';
import StringUtils from 'util/StringUtils';
import type { SearchFilterUsagesSummary } from 'search-filter/types';

// eslint-disable-next-line no-alert
const confirmActionUsagesAlert = ({
  action,
  usagesSummary,
  additionalExplanation = '',
}: {
  action: string;
  additionalExplanation?: string;
  usagesSummary: SearchFilterUsagesSummary['summary'];
}) =>
  window.confirm(
    `This search filter is being referenced by ${readableFilterUsagesSummary(usagesSummary)}. ${action} the search filter query will affect ${StringUtils.pluralize(usagesSummary.length, 'its', 'their')} search result.${additionalExplanation}`,
  );

export default confirmActionUsagesAlert;
