import * as React from 'react';

import type { WidgetDetails } from 'report/report-creation/content/fetchWidgetDetails';

const WidgetDetailsContext = React.createContext<WidgetDetails & { isLoading: boolean }>({
  parameters: {},
  searchIds: {},
  widgets: {},
  isLoading: false,
});
export default WidgetDetailsContext;
