import * as React from 'react';
import { Field, useField } from 'formik';
import { useQuery } from '@tanstack/react-query';

import * as API from '@graylog/server-api';

import { Input } from 'components/bootstrap';
import { FormikInput, MultiSelect } from 'components/common';
import Spinner from 'components/common/Spinner';
import FormikSwitch from 'report/report-creation/FormikSwitch';
import PlusButton from 'report/report-creation/common/PlusButton';
import ReportScheduling from 'report/report-creation/overview/ReportScheduling';
import Separator from 'report/report-creation/Separator';

const handleMultiSelectChange = (value: string) => (value === '' ? [] : value.split(','));
const toSelectValue = (values: Array<string>) => (Array.isArray(values) ? values.join(',') : '');

const fetchUsers = async () => {
  const users = await API.Users.listUsers(false, false);

  return users.users.map((user) => ({ label: `${user.full_name}`, value: user.username }));
};

const useUserOptions = () => useQuery(['users'], fetchUsers);

const Delivery = () => {
  const { data: usersOptions, isLoading } = useUserOptions();
  const [{ value: deliveryActive }] = useField('delivery.active');

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <ReportScheduling disabled={!deliveryActive}>
        {({ createScheduling }) => (
          <>
            <FormikSwitch label="Schedule" name="delivery.active" />
            <PlusButton title="Add schedule" onClick={createScheduling} />
          </>
        )}
      </ReportScheduling>
      <Separator />
      <FormikInput
        id="email_subject"
        name="delivery.email_subject"
        label="Email subject"
        help="Set an email subject to use when sending the report."
        required
      />
      <FormikInput
        id="email_body"
        name="delivery.email_body"
        type="textarea"
        label="Email body"
        help="Add an email body to use when sending the report."
        rows={6}
        required
      />
      <Field name="delivery.user_receivers">
        {({ field: { name, value, onChange, onBlur }, meta: { error, touched } }) => (
          <Input
            bsStyle={error && touched ? 'error' : undefined}
            help="Select Graylog usernames that will receive this report."
            error={error && touched ? error : undefined}
            id={name}
            label="User recipients">
            <MultiSelect
              onBlur={onBlur}
              onChange={(selectedReceivers: string) =>
                onChange({
                  target: {
                    value: handleMultiSelectChange(selectedReceivers),
                    name,
                  },
                })
              }
              options={usersOptions}
              inputId={name}
              addLabelText="Type username"
              placeholder="Type username"
              value={toSelectValue(value)}
            />
          </Input>
        )}
      </Field>

      <Field name="delivery.email_receivers">
        {({ field: { name, value, onChange, onBlur }, meta: { error, touched } }) => (
          <Input
            bsStyle={error && touched ? 'error' : undefined}
            help="Add email addresses that will receive this report."
            error={error && touched ? error : undefined}
            id={name}
            label="Email recipients">
            <MultiSelect
              onBlur={onBlur}
              onChange={(selectedReceivers: string) =>
                onChange({
                  target: {
                    value: handleMultiSelectChange(selectedReceivers),
                    name,
                  },
                })
              }
              options={[]}
              inputId={name}
              addLabelText='Add email "{label}"?'
              placeholder="Type email address"
              allowCreate
              value={toSelectValue(value)}
            />
          </Input>
        )}
      </Field>
    </>
  );
};

export default Delivery;
