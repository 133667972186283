import * as React from 'react';
import { useFormikContext } from 'formik';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import { Alert, ListGroup } from 'components/bootstrap';
import { frequencies } from 'report/report-configuration-page/SchedulingFrequencyForm';
import type { SchedulingFrequency } from 'report/types';
import { defaultCompare } from 'logic/DefaultCompare';
import toUTCFrequencyTime from 'report/report-configuration-page/toUTCFrequencyTime';
import type { ReportDeliveryFormValues } from 'report/report-configuration-page/ReportDelivery';

import SchedulingFrequenciesListItem from './SchedulingFrequenciesListItem';

const StyledListGroup = styled(ListGroup)`
  clear: both;
  margin-bottom: 3px;
  padding: 0;
`;

const sortFrequencies = (schedulingFrequencies: Array<SchedulingFrequency & { originalIndex: number }>) =>
  schedulingFrequencies.sort((f1, f2) =>
    defaultCompare(
      toUTCFrequencyTime(f1.frequency_configuration.time, f1.frequency_configuration.timezone),
      toUTCFrequencyTime(f2.frequency_configuration.time, f1.frequency_configuration.timezone),
    ),
  );

type Props = {
  onDelete: (index: number) => void;
  onUpdate: (index: number, newSchedulingFrequency: SchedulingFrequency) => void;
  disabled: boolean;
};

const SchedulingFrequenciesList = ({ onDelete, onUpdate, disabled }: Props) => {
  const {
    values: { scheduling },
  } = useFormikContext<ReportDeliveryFormValues>();

  if (!scheduling?.length) {
    return <Alert className="no-bm">No scheduling has been configured yet.</Alert>;
  }

  const groupedSchedulingFrequencies: { [frequency: string]: Array<SchedulingFrequency & { originalIndex: number }> } =
    groupBy(
      scheduling.map((schedulingFrequency, index) => ({ ...schedulingFrequency, originalIndex: index })),
      'frequency',
    );
  const sortedFrequencyGroups: Array<[string, Array<SchedulingFrequency & { originalIndex: number }>]> = sortBy(
    Object.entries(groupedSchedulingFrequencies),
    (item) => frequencies.indexOf(item[0]),
  );

  return (
    <>
      {sortedFrequencyGroups.map(([frequencyName, schedulingFrequencies]) => (
        <StyledListGroup componentClass="ul" key={frequencyName}>
          {sortFrequencies(schedulingFrequencies).map(({ originalIndex, ...schedulingFrequency }) => (
            <SchedulingFrequenciesListItem
              schedulingFrequency={schedulingFrequency}
              disabled={disabled}
              key={`${schedulingFrequency.frequency}-${schedulingFrequency.frequency_configuration?.time}-${schedulingFrequency.frequency_configuration?.timezone}`}
              onDelete={() => onDelete(originalIndex)}
              onUpdate={(newSchedulingFrequency) => onUpdate(originalIndex, newSchedulingFrequency)}
            />
          ))}
        </StyledListGroup>
      ))}
    </>
  );
};

export default SchedulingFrequenciesList;
