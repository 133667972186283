import * as React from 'react';
import type { ReactNode } from 'react';
import { useState, useEffect, useMemo } from 'react';

import { useStore } from 'stores/connect';
import ForwarderInputTypesStore, { ForwarderInputTypesActions } from 'forwarder/stores/ForwarderInputTypesStore';
import type { Forwarder, InputProfile, ForwarderInputDescriptions, ForwarderInputTypes } from 'forwarder/Types';

type Props = {
  children: ReactNode;
};

type ContextType = {
  selectedForwarder: Forwarder | null | undefined;
  setSelectedForwarder: (forwarder: Forwarder) => void;
  selectedInputProfile?: InputProfile;
  setSelectedInputProfile: (inputProfile: InputProfile) => void;
  inputTypes: ForwarderInputTypes;
  inputDescriptions: ForwarderInputDescriptions;
};

export const ForwarderWizardContext = React.createContext<ContextType>({
  selectedForwarder: undefined,
  setSelectedForwarder: () => {},
  selectedInputProfile: undefined,
  setSelectedInputProfile: () => {},
  inputTypes: {},
  inputDescriptions: {},
});

const ForwarderWizardProvider = ({ children }: Props) => {
  const [selectedForwarder, setSelectedForwarder] = useState<Forwarder>();
  const [selectedInputProfile, setSelectedInputProfile] = useState<InputProfile>();
  const { forwarderInputDescriptions: inputDescriptions, forwarderInputTypes: inputTypes } =
    useStore(ForwarderInputTypesStore);

  const forwarderWizardContextValue = useMemo(
    () => ({
      selectedForwarder,
      setSelectedForwarder,
      selectedInputProfile,
      setSelectedInputProfile,
      inputDescriptions,
      inputTypes,
    }),
    [inputDescriptions, inputTypes, selectedForwarder, selectedInputProfile],
  );

  useEffect(() => {
    if (!inputDescriptions || !inputTypes) {
      ForwarderInputTypesActions.list();
    }
  }, [inputDescriptions, inputTypes]);

  return (
    <ForwarderWizardContext.Provider value={forwarderWizardContextValue}>{children}</ForwarderWizardContext.Provider>
  );
};

export default ForwarderWizardProvider;
