import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import { Button } from 'components/bootstrap';
import { PageHeader, DocumentTitle } from 'components/common';
import UsersPageNavigation from 'components/users/navigation/UsersPageNavigation';
import DocsHelper from 'util/DocsHelper';

import LicenseCheck from '../../license/LicenseCheck';
import TeamsOverview from '../components/teams-overview/TeamsOverview';

const TeamsOverviewPage = () => (
  <DocumentTitle title="Teams Overview">
    <UsersPageNavigation />
    <PageHeader
      title="Teams Overview"
      actions={
        <LicenseCheck featureName="teams" displayLicenseWarning={false}>
          {({ licenseIsValid }) => (
            <LinkContainer to={Routes.pluginRoute('SYSTEM_TEAMS_NEW')}>
              <Button bsStyle="success" disabled={!licenseIsValid}>
                Create team
              </Button>
            </LinkContainer>
          )}
        </LicenseCheck>
      }
      documentationLink={{
        title: 'Permissions documentation',
        path: DocsHelper.PAGES.USERS_ROLES,
      }}>
      <span>
        Overview of Graylog&apos;s teams. Teams allow granting permissions and capabilities to multiple users. The team
        details page provides an overview of all entities which are being shared with a team. Teams can be managed
        manually or synced from external resources like LDAP.
      </span>
    </PageHeader>

    <TeamsOverview />
  </DocumentTitle>
);

export default TeamsOverviewPage;
