import * as React from 'react';

import { singleton } from 'logic/singleton';

type EventDetailsConfiguration = {
  hideEventDefinitionEdit: boolean;
  hideNotesEdit: boolean;
};

const EventDetailsContext = React.createContext<EventDetailsConfiguration | undefined>({
  hideEventDefinitionEdit: false,
  hideNotesEdit: false,
});

export default singleton('contexts.EventDetailsContext', () => EventDetailsContext);
