import React, { useContext, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import SelectInputProfile from './add-inputs-step/SelectInputProfile';
import CreateInputProfile from './add-inputs-step/CreateInputProfile';
import { ForwarderWizardContext } from './ForwarderWizardProvider';

import { ForwardersActions } from '../stores/ForwardersStore';
import type { Forwarder, InputProfile } from '../Types';

type Props = {
  onStepComplete: () => void;
};

const AddInputsStep = ({ onStepComplete }: Props) => {
  const { selectedForwarder, setSelectedForwarder, setSelectedInputProfile } = useContext(ForwarderWizardContext);
  const [action, setAction] = useState('select');
  const sendTelemetry = useSendTelemetry();

  if (!selectedForwarder) {
    return <div>Please select a Forwarder before continuing to this step.</div>;
  }

  const handleInputProfileSelect = (inputProfile: InputProfile) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_WIZARD_INPUT_PROFILE_SELECTED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
    });

    const updatedForwarder = cloneDeep(selectedForwarder);
    updatedForwarder.input_profile_id = inputProfile.id;

    ForwardersActions.update(selectedForwarder.id, updatedForwarder).then((forwarder: Forwarder) => {
      setSelectedForwarder(forwarder);
      setSelectedInputProfile(inputProfile);
      onStepComplete();
    });
  };

  if (action === 'create') {
    return <CreateInputProfile onCancel={() => setAction('select')} onInputProfileCreated={handleInputProfileSelect} />;
  }

  return (
    <SelectInputProfile
      onInputProfileSelect={handleInputProfileSelect}
      onCreateInputProfileClick={() => setAction('create')}
    />
  );
};

export default AddInputsStep;
