import * as React from 'react';
import { useCallback, useState } from 'react';

import { DataLakeQuery } from '@graylog/enterprise-api';

import { Button, Alert } from 'components/bootstrap';
import useFeature from 'hooks/useFeature';
import DataLakeRoutes from 'data-lake/Routes';
import useHistory from 'routing/useHistory';
import { ConfirmDialog } from 'components/common';
import { PREVIEW_JOB_STATUS } from 'data-lake/preview/Constants';
import UserNotification from 'util/UserNotification';

type StatusResponse =
  | { status: (typeof PREVIEW_JOB_STATUS)[keyof typeof PREVIEW_JOB_STATUS]; progress: number }
  | undefined;

type Props = {
  streamId: string;
};

const PreviewLakeLogsButtonComponent = ({ streamId }: Props) => {
  const history = useHistory();
  const [isLoadingActiveSearch, setIsLoadingActiveSearch] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const onNavigate = useCallback(() => {
    history.push(`${DataLakeRoutes.PREVIEW}?streams=${streamId}`);
  }, [history, streamId]);
  const toggleShowConfirmation = useCallback(() => {
    setShowConfirmDialog((cur) => !cur);
  }, []);

  const onClick = useCallback(() => {
    setIsLoadingActiveSearch(true);
    DataLakeQuery.searchJobExecutionState()
      .then(({ status }: StatusResponse) => {
        if (status === PREVIEW_JOB_STATUS.running) {
          toggleShowConfirmation();
        } else {
          onNavigate();
        }
      })
      .catch((error) => UserNotification.error(error.message))
      .finally(() => setIsLoadingActiveSearch(false));
  }, [onNavigate, toggleShowConfirmation]);

  const onConfirm = useCallback(() => {
    DataLakeQuery.resetActiveQuery().then(() => onNavigate());
  }, [onNavigate]);

  return (
    <>
      <Button bsSize="xs" title="Preview data lake logs" onClick={onClick} disabled={isLoadingActiveSearch}>
        Preview logs
      </Button>
      {showConfirmDialog && (
        <ConfirmDialog
          show={showConfirmDialog}
          onConfirm={onConfirm}
          onCancel={toggleShowConfirmation}
          title="A preview search is currently running">
          <Alert bsStyle="warning">
            A preview search is currently running. Continuing will cancel the ongoing search job. Do you want to
            proceed?
          </Alert>
        </ConfirmDialog>
      )}
    </>
  );
};

const PreviewLakeLogsButton = ({ streamId }: Props) => {
  const featureIsEnabled = useFeature('data_warehouse_search');
  if (!featureIsEnabled) {
    return null;
  }

  return <PreviewLakeLogsButtonComponent streamId={streamId} />;
};
export default PreviewLakeLogsButton;
