import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { RiskScoreBadge, DataWell } from 'security-app/components/common';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import type {
  AssetAPIType,
  AssetVulnerabilityAPIType,
  MachineAssetAPIType,
} from 'security-app/hooks/api/assetsAPI.types';

import MachineDetails from './MachineDetails';
import GeoInfo from './GeoInfo';
import CustomFields from './CustomFields';

const Description = styled.p`
  white-space: pre-wrap;
`;

type Props = {
  machineAsset: AssetAPIType<MachineAssetAPIType>;
};

function MachineData({ machineAsset }: Props) {
  const operatingSystem = new Set();

  machineAsset.vulnerabilities.forEach((vuln: AssetVulnerabilityAPIType) => {
    if (vuln.os) operatingSystem.add(vuln.os);
  });

  return (
    <>
      <DataWell>
        <Row>
          <span>Risk Score</span>
          <RiskScoreBadge riskScore={machineAsset.risk_score_normalized} />
        </Row>
      </DataWell>
      <DataWell>
        <Row aria-label="Operating system well">
          <Icon name="computer" type="regular" />
          <Col>
            <small>Operating System</small>
            <span>{[...operatingSystem].join(', ') || <i>No Operating System Information</i>}</span>
          </Col>
        </Row>
      </DataWell>
      <MachineDetails machineAsset={machineAsset} />
      <DataWell>
        <Col>
          <small>Description</small>
          <Description>{machineAsset.details.description || <i>No description</i>}</Description>
        </Col>
      </DataWell>
      <strong>Geo Information</strong>
      <GeoInfo geoInfo={machineAsset.details?.geo_info} />
      <strong>Custom Fields</strong>
      <CustomFields customFields={machineAsset.details?.custom_fields} />
    </>
  );
}

export default MachineData;
