import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Store from 'logic/local-storage/Store';
import useDataLake from 'data-lake/hooks/useDataLake';
import { Section, Spinner, IfPermitted } from 'components/common';
import FilterRulesList, { StyledSectionComponent } from 'components/streams/StreamDetails/output-filter/FilterRuleList';
import { Alert } from 'components/bootstrap';
import SectionCountLabel from 'components/streams/StreamDetails/SectionCountLabel';
import useStreamOutputFilters from 'components/streams/hooks/useStreamOutputFilters';
import { DEFAULT_PAGINATION } from 'stores/PaginationTypes';
import DataLakeSummary from 'data-lake/components/DataLakeSummary';
import useStreamDataLake from 'data-lake/hooks/useStreamDataLake';
import useStreamDataLakeConfig from 'data-lake/hooks/useStreamDataLakeConfig';
import StreamDataLakeEnableButton from 'data-lake/stream/StreamDataLakeEnableButton';
import DataLakeRetrieval from 'data-lake/components/DataLakeRetrieval';

import DataLakeRetentionForm from './DataLakeRetentionForm';

import { DATALAKE_SECTION_OPENED_KEY } from '../Constants';

export const Headline = styled.h2(
  ({ theme }) => css`
    margin-top: ${theme.spacings.sm};
    margin-bottom: ${theme.spacings.xs};
  `,
);

const StreamDataLake = () => {
  const { streamId } = useParams<{ streamId: string }>();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [openDataLakeSection, setOpenDataLakeSection] = useState(Store.get(DATALAKE_SECTION_OPENED_KEY) === 'true');
  const { data: config, isFetching } = useStreamDataLakeConfig(streamId);
  const {
    streamDataLakeData: { data: archive, isFetching: isFetchingArchive },
  } = useStreamDataLake(streamId);
  const { onDeleteDataLakeRetrievalOperationMutation } = useDataLake(streamId);
  const { data: paginatedFilters, isSuccess: isLoadingFilterSuccess } = useStreamOutputFilters(
    streamId,
    'data-warehouse',
    pagination,
  );

  useEffect(() => () => Store.set(DATALAKE_SECTION_OPENED_KEY, 'false'), []);

  if (isFetching || isFetchingArchive) {
    return <Spinner />;
  }

  const onPaginationChange = (newPage: number, newPerPage: number) =>
    setPagination({
      ...pagination,
      page: newPage,
      perPage: newPerPage,
    });

  const onDeleteRetrieveOperation = (retrievalOperationId: string) =>
    onDeleteDataLakeRetrievalOperationMutation({ streamId, retrievalOperationId });

  const onSectionCollapse = (opened: boolean) => {
    Store.set(DATALAKE_SECTION_OPENED_KEY, opened ? 'false' : 'true');
    setOpenDataLakeSection(!opened);
  };

  const shouldShowDataLake =
    (config?.enabled && !!archive) || archive?.message_count > 1 || archive?.restore_history?.length > 0;

  return (
    <Section
      title="Data Lake"
      headerLeftSection={
        <>
          <IfPermitted permissions={['data_lake_config:update']}>
            <StreamDataLakeEnableButton streamId={streamId} config={config} />
          </IfPermitted>
          {shouldShowDataLake && (
            <SectionCountLabel>
              FILTERS {isLoadingFilterSuccess ? paginatedFilters.pagination.total : 0}
            </SectionCountLabel>
          )}
        </>
      }
      actions={shouldShowDataLake && <DataLakeRetentionForm title={archive.last_known_stream_title} config={config} />}
      collapsible
      onCollapse={onSectionCollapse}
      defaultClosed={!openDataLakeSection}
      disableCollapseButton={!shouldShowDataLake}>
      {shouldShowDataLake && (
        <>
          <Alert bsStyle="default">
            Storing messages in the Data Lake does not count towards license usage (although note that if they are also
            routed to the Search Cluster, they will still be counted)
            <br />
            and will be kept until the Data Lake Retention Policy is exceeded.
            <br />
            Note: newly warehoused logs can take up to an hour to be counted in these metrics.
          </Alert>
          <DataLakeSummary dataLake={archive} />
          {paginatedFilters && (
            <FilterRulesList
              streamId={streamId}
              paginatedFilters={paginatedFilters}
              onPaginationChange={onPaginationChange}
              destinationType="data-warehouse"
              requiredPermissions={['data_lake_config:update']}
            />
          )}
          <StyledSectionComponent title="Retrieval Operations" collapsible defaultClosed>
            <DataLakeRetrieval archive={archive} onDeleteOperation={onDeleteRetrieveOperation} />
          </StyledSectionComponent>
        </>
      )}
    </Section>
  );
};

export default StreamDataLake;
