import * as React from 'react';

import { InputOptionalInfo, FormikFormGroup } from 'components/common';

type Props = {
  labelClassName?: string;
  wrapperClassName?: string;
};

const TitleFormGroup = ({ wrapperClassName, labelClassName }: Props) => (
  <FormikFormGroup
    name="title"
    label={
      <>
        Title <InputOptionalInfo />
      </>
    }
    labelClassName={labelClassName}
    wrapperClassName={wrapperClassName}
    help="Summarize the purpose of this filter."
  />
);

export default TitleFormGroup;
