import React, { useMemo } from 'react';

import { useStore } from 'stores/connect';

import InputThroughput from './InputThroughput';

import ForwarderInputStatesStore from '../stores/ForwarderInputStatesStore';
import type { Forwarder, Input, ForwarderMetric, ForwarderInputState } from '../Types';

type Props = {
  forwarder: Forwarder;
  input: Input;
};

const ForwarderInputThroughput = ({ forwarder, input }: Props) => {
  const { forwarderInputStates: states } = useStore(ForwarderInputStatesStore);

  const currentInputMetric = useMemo(() => {
    const statesByInput = (states || []).filter((state) => state.input_id === input.id);

    if (!forwarder) {
      return null;
    }

    return statesByInput
      .filter((state: ForwarderInputState) => state.forwarder_id === forwarder.id)[0]
      ?.metrics?.filter((metric: ForwarderMetric) => metric.name === 'incomingMessages');
  }, [input, forwarder, states]);

  const hasResults = currentInputMetric && currentInputMetric.length > 0;

  if (!hasResults) {
    return <dd>No metrics available</dd>;
  }

  return <InputThroughput metrics={currentInputMetric[0]} />;
};

export default ForwarderInputThroughput;
