import * as React from 'react';
import styled from 'styled-components';

import { MenuItem, Input } from 'components/bootstrap';
import { Row, Col } from 'common/components/layout-styles';
import type { FilterComponentProps } from 'stores/PaginationTypes';
import type { Filter } from 'components/common/EntityFilters/types';

const ColContainer = styled(Col)`
  max-height: 40vh;
  overflow: auto;
`;

const InputRow = styled(Row)`
  margin: ${({ theme }) => theme.spacings.sm} 0;

  & > .form-group {
    margin: 0;
  }
`;

const sortFilterValues = (filter: Filter, filterOptions: Array<Filter>, activeTagFilters: Array<Filter>) => {
  const sortedFiltersValues = filterOptions.sort((a: Filter, b: Filter) => {
    if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
    if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;

    return 0;
  });

  return filter
    ? sortedFiltersValues
    : sortedFiltersValues.filter((fOption: Filter) => !activeTagFilters?.find((aOption: Filter) => aOption.value === fOption.value));
};

function TagsFilterComponent({ attribute, filter, onSubmit, allActiveFilters }: FilterComponentProps) {
  const { filter_options } = attribute;
  const { tags: activeTagFilters } = allActiveFilters.toJS();
  const [filterOptions, setFilterOptions] = React.useState<Array<Filter>>(sortFilterValues(filter, filter_options, activeTagFilters));
  const [debounceSearchTag, setDebounceSearchTag] = React.useState(null);

  const onChange = (e: React.BaseSyntheticEvent) => {
    const allValues = sortFilterValues(filter, filter_options, activeTagFilters);
    if (debounceSearchTag) clearTimeout(debounceSearchTag);

    setDebounceSearchTag(setTimeout(() => {
      const { value } = e.target;
      const filteredOptions = allValues.filter((aFilter: Filter) => !!aFilter.title.toUpperCase().match(value.toUpperCase()));
      setFilterOptions(filteredOptions);
    }, 400));
  };

  return (
    <>
      <InputRow $width="100%">
        <Input type="text" placeholder="Search for a tag" onChange={onChange} />
      </InputRow>
      <ColContainer>
        {filterOptions.map((fOption: Filter ) => (
          <MenuItem
            key={fOption.value}
            onClick={() => onSubmit(fOption)}
            disabled={filter?.value === fOption.value}
          >
            {fOption.title}
          </MenuItem>
        ))}
      </ColContainer>
    </>
  );
}

export default TagsFilterComponent;
