import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DataLakeQuery } from '@graylog/enterprise-api';

import type { MostRecentJobIds, ActivePreviewSearch } from 'data-lake/preview/Types';
import { PREVIEW_JOB_STATUS } from 'data-lake/preview/Constants';

const useJobProgress = (
  mostRecentJobIds: MostRecentJobIds,
  initialSearchJobStatus: ActivePreviewSearch['status'],
  executedSearchId: string,
) => {
  const [isQueryEnabled, setEnableProgressFetching] = useState(initialSearchJobStatus !== PREVIEW_JOB_STATUS.done);
  const { data } = useQuery({
    queryKey: ['data-lake', 'progress', executedSearchId, mostRecentJobIds],
    queryFn: () => DataLakeQuery.searchJobExecutionStateByjob_id(mostRecentJobIds.asyncSearchId),
    refetchInterval: 200,
    cacheTime: 0,
    enabled: !!(
      isQueryEnabled &&
      executedSearchId &&
      mostRecentJobIds?.asyncSearchId &&
      mostRecentJobIds?.searchId === executedSearchId
    ),
    onSuccess: ({ status }) => {
      if (status !== PREVIEW_JOB_STATUS.running) {
        setEnableProgressFetching(false);
      }
    },
    initialData:
      initialSearchJobStatus === PREVIEW_JOB_STATUS.done
        ? { status: PREVIEW_JOB_STATUS.done, progress: 100 }
        : undefined,
  });

  return {
    data,
    setEnableProgressFetching: (newVal: boolean) => {
      setEnableProgressFetching(newVal);
    },
  };
};

export default useJobProgress;
