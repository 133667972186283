import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import { FormDataContext } from '../common/context/FormData';
import { AdvancedOptionsContext } from '../common/context/AdvancedOptions';
import AdditionalFields from '../common/AdditionalFields';
import type {
  HandleFieldUpdateType,
  FormDataContextType,
  AdvancedOptionsContextType,
} from '../common/utils/types';

interface Props {
  onChange: HandleFieldUpdateType;
  handleReadTimeOffsetChange: (...args: any[]) => void;

}

const StyledAdditionalFields = styled(AdditionalFields)`
  margin: 0 0 35px;
`;

const FormAdvancedOptions = (
  {
    onChange,
    handleReadTimeOffsetChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } = useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const {
    graphThrottleEnabled, readTimeOffset 
  } = formData;


  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  const internalHandleReadTimeOffsetChange = (e) => {
    const { value } = e.target;

    if (value >= 0) {
      onChange(e);
      handleReadTimeOffsetChange('');
    } else {
      handleReadTimeOffsetChange('Read Time Offset cannot be negative');
    }
  };

  return (
    <StyledAdditionalFields title="Advanced Options"
                            visible={isAdvancedOptionsVisible}
                            onToggle={handleToggle}>

      <Input id="graphThrottleEnabled"
             type="checkbox"
             value="enable-throttling"
             defaultChecked={graphThrottleEnabled?.value}
             onChange={onChange}
             label="Enable Throttling"
             help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled." />
      <Input
        id="readTimeOffset"
        type="number"
        value={readTimeOffset.value}
        min="0"
        onChange={internalHandleReadTimeOffsetChange}
        required
        help="How long to wait for logs to become available in the Microsoft Graph API before attempting to read them."
        label="Read Time Offset (minutes)"
      />
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
