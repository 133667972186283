import React from 'react';
import styled from 'styled-components';
import URI from 'urijs';

import { Button } from 'components/bootstrap';

import type { Configuration } from './OidcConfigurationStore';

type Props = {
  configuration: Configuration;
  nonce: {
    state: string;
    redirectTo: string;
  };
};
const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 1em;
  display: block;
  cursor: pointer;
  width: 100%;
`;

const getAuthUrl = ({ authorizationEndpoint, nonce, clientId, redirectUri }) => {
  const url = URI(authorizationEndpoint).addSearch({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: 'openid email profile',
    state: nonce.state,
    nonce: nonce.state,
  });

  return url.toString();
};

function OidcGenericLoginLink({ configuration, nonce }: Props) {
  const {
    oauth_auth_url: authorizationEndpoint,
    oauth_client_id: clientId,
    oauth_callback_url: redirectUri,
    oauth_title: title,
  } = configuration;

  const loginUrl = getAuthUrl({
    authorizationEndpoint,
    nonce,
    clientId,
    redirectUri,
  });

  return (
    <StyledA href={loginUrl}>
      <StyledButton bsStyle="info">{`Login with ${title}`}</StyledButton>
    </StyledA>
  );
}

export default OidcGenericLoginLink;
