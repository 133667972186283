import type { Moment } from 'moment';
import { useMemo } from 'react';
import * as Immutable from 'immutable';

import useWidgetData from 'search/ExportWidgetAction/pdf-export-widget-renderer/hooks/useWidgetData';
import useWidgetValues from 'search/ExportWidgetAction/pdf-export-widget-renderer/hooks/useWidgetValues';
import type { TimeRange } from 'views/logic/queries/Query';
import useFieldTypes from 'views/logic/fieldtypes/useFieldTypes';
import { ALL_MESSAGES_TIMERANGE } from 'views/Constants';

const useExportWidget = (exportCallId: string, timerangeOverride: TimeRange, now: Moment) => {
  const {
    data: { cachedValues: exportWidgetCachedValues, widget: exportWidgetData },
    isFetching: isFetchingWidgetData,
  } = useWidgetData(exportCallId);
  const { data: exportWidgetValues, isFetching: isFetchingWidgetValues } = useWidgetValues(
    exportCallId,
    exportWidgetData,
    timerangeOverride,
    now,
    !!exportWidgetData && !exportWidgetCachedValues,
  );
  const { data: allFieldTypes, isFetching: isFieldTypesLoading } = useFieldTypes(
    exportWidgetData?.streams ?? [],
    timerangeOverride ?? ALL_MESSAGES_TIMERANGE,
    !!exportWidgetData && !!exportWidgetCachedValues,
  );

  const widgetValues = useMemo(
    () =>
      exportWidgetCachedValues
        ? { ...exportWidgetCachedValues, types: Immutable.List(allFieldTypes) }
        : exportWidgetValues,
    [allFieldTypes, exportWidgetCachedValues, exportWidgetValues],
  );

  return useMemo(
    () => ({
      isFetching: isFetchingWidgetValues || isFetchingWidgetData || isFieldTypesLoading,
      widgetData: exportWidgetData,
      widgetValues: widgetValues,
    }),
    [exportWidgetData, isFetchingWidgetData, isFetchingWidgetValues, isFieldTypesLoading, widgetValues],
  );
};

export default useExportWidget;
