import * as React from 'react';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { WidgetHeader } from 'views/components/widgets';
import { IconButton, IfPermitted } from 'components/common';
import WidgetContainer from 'views/components/WidgetContainer';
import WidgetContext from 'views/components/contexts/WidgetContext';
import WidgetFrame from 'views/components/widgets/WidgetFrame';
import type DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import type DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import EditWidgetFrame from 'views/components/widgets/EditWidgetFrame';
import DataLakeLogViewEdit from 'data-lake/preview/DataLakeLogViewEdit';
import RetrieveLogsAction from 'data-lake/preview/RetrieveLogsAction';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { useSendWidgetEditTelemetry, useSendWidgetEditCancelTelemetry } from 'views/components/widgets/telemety';

type EditWrapperProps = React.PropsWithChildren<{
  config: DataLakeWidgetConfig;
  editing: boolean;
  onCancel: () => void;
  onChangeWidgetConfig: (config: DataLakeWidgetConfig) => Promise<unknown>;
  onSubmit: (newWidget: DataLakeWidget, hasChanges: boolean) => Promise<void>;
  isFetching: boolean;
}>;

const EditWrapper = ({
  onCancel,
  editing,
  children = undefined,
  onSubmit,
  config,
  onChangeWidgetConfig,
  isFetching,
}: EditWrapperProps) => {
  if (!editing) {
    return children;
  }

  return (
    <EditWidgetFrame
      onCancel={onCancel}
      displaySubmitActions={false}
      onSubmit={onSubmit}
      containerComponent={React.Fragment}
      showQueryControls={false}>
      <DataLakeLogViewEdit config={config} onCancel={onCancel} isFetching={isFetching} onChange={onChangeWidgetConfig}>
        {children}
      </DataLakeLogViewEdit>
    </EditWidgetFrame>
  );
};

const WidgetActionsMenu = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.xxs};
    align-items: center;
  `,
);

type Props = React.PropsWithChildren<{
  editing: boolean;
  loading: boolean;
  onChangeWidget: (newWidget: DataLakeWidget) => Promise<unknown>;
  onChangeConfig: (newConfig: DataLakeWidgetConfig) => Promise<unknown>;
  onToggleEdit: () => void;
  widget: DataLakeWidget;
  isFetching: boolean;
  effectiveTimerange: AbsoluteTimeRange;
}>;

const DLLogViewWidgetContainer = ({
  children = undefined,
  widget,
  editing,
  loading,
  onToggleEdit,
  onChangeConfig,
  onChangeWidget,
  isFetching,
  effectiveTimerange,
}: Props) => {
  const sendWidgetEditTelemetry = useSendWidgetEditTelemetry();
  const sendWidgetCancelTelemetry = useSendWidgetEditCancelTelemetry();
  const [configBeforeEdit, setConfigBeforeEdit] = useState<DataLakeWidgetConfig>();

  const toggleEditing = useCallback(() => {
    if (!editing) {
      sendWidgetEditTelemetry();
      setConfigBeforeEdit(widget.config);
    }

    onToggleEdit();
  }, [editing, onToggleEdit, sendWidgetEditTelemetry, widget.config]);

  const onCancelEdit = useCallback(() => {
    sendWidgetCancelTelemetry();
    onChangeConfig(configBeforeEdit);
    toggleEditing();
  }, [configBeforeEdit, onChangeConfig, sendWidgetCancelTelemetry, toggleEditing]);

  const onSubmitEdit = useCallback(
    (newWidget: DataLakeWidget, hasChanges: boolean) => {
      if (hasChanges) {
        return onChangeWidget(newWidget).then(() => toggleEditing());
      }

      toggleEditing();

      return Promise.resolve();
    },
    [onChangeWidget, toggleEditing],
  );

  return (
    <WidgetContext.Provider value={widget}>
      <WidgetContainer className="widgetFrame" isFocused>
        <WidgetFrame widgetId={widget.id}>
          <WidgetHeader title="Data Lake Logs" hideDragHandle loading={loading} editing={editing}>
            {!editing && (
              <WidgetActionsMenu>
                <IfPermitted permissions={['data_lake_archive:restore']}>
                  <RetrieveLogsAction
                    timerange={effectiveTimerange}
                    stream={widget.streams[0]}
                    fieldFilters={widget.config.fieldFilters?.toArray()}
                    fieldsOperator={widget.config.filtersOperator}
                  />
                </IfPermitted>
                <IconButton name="edit_square" title="Edit" iconType="regular" onClick={toggleEditing} />
              </WidgetActionsMenu>
            )}
          </WidgetHeader>
          <EditWrapper
            editing={editing}
            onSubmit={onSubmitEdit}
            onCancel={onCancelEdit}
            isFetching={isFetching}
            config={widget.config}
            onChangeWidgetConfig={onChangeConfig}>
            {children}
          </EditWrapper>
        </WidgetFrame>
      </WidgetContainer>
    </WidgetContext.Provider>
  );
};

export default DLLogViewWidgetContainer;
