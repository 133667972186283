import React, { createContext, useState } from 'react';

import type { AdvancedOptionsContextType } from '../types';

export const AdvancedOptionsContext = createContext<AdvancedOptionsContextType>(null);

export const AdvancedOptionsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isAdvancedOptionsVisible, setAdvancedOptionsVisibility] = useState<boolean>(false);

  return (
    <AdvancedOptionsContext.Provider
      value={{
        isAdvancedOptionsVisible,
        setAdvancedOptionsVisibility,
      }}>
      {children}
    </AdvancedOptionsContext.Provider>
  );
};
