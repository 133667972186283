import type * as Immutable from 'immutable';
import type { Optional } from 'utility-types';

import type ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type { TimeRange } from 'views/logic/queries/Query';
import type Parameter from 'views/logic/parameters/Parameter';
import type { SearchFilter } from 'views/types';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import type WidgetConfig from 'views/logic/widgets/WidgetConfig';
import type { QueryString } from 'views/logic/queries/types';

// the basic frequency conf equals the daily and hourly frequency conf
type BasicFrequencyConf = {
  time: string;
  timezone: string;
  override_widgets_timerange?: boolean;
};

type ReportWidgetId = string;

export type ReportWidgetPosition = {
  dashboard_widget_id: ReportWidgetId;
  col: number;
  row: number;
};

export type WidgetRef = { dashboardId: string; widgetId: string; title?: string; description?: string };

type MonthlyFrequencyConf = BasicFrequencyConf & { day_of_month: string };
type WeeklyFrequencyConf = BasicFrequencyConf & { day_of_week: string };

type HourlyFrequency = {
  frequency: 'HOURLY';
  frequency_configuration: BasicFrequencyConf;
};

type DailyFrequency = {
  frequency: 'DAILY';
  frequency_configuration: BasicFrequencyConf;
};

export type WeeklyFrequency = {
  frequency: 'WEEKLY';
  frequency_configuration: WeeklyFrequencyConf;
};

export type MonthlyFrequency = {
  frequency: 'MONTHLY';
  frequency_configuration: MonthlyFrequencyConf;
};

export type SchedulingFrequency = HourlyFrequency | DailyFrequency | WeeklyFrequency | MonthlyFrequency;

export type ReportDelivery = {
  active: boolean;
  email_body: string | undefined;
  email_receivers: Array<string> | undefined;
  email_subject: string | undefined;
  scheduling: Array<SchedulingFrequency>;
  user_receivers: Array<string> | undefined;
};

export type BackendReportParameterValues = { [name: string]: ParameterBinding };

export type BackendReportWidget = {
  dashboard_widget_id: string;
  dashboard_id: string;
  description?: string;
  title?: string;
  type: string;
  creator_user_id: string;
  cache_time: number;
  query: QueryString | undefined;
  timerange: TimeRange | undefined;
  streams: Array<string>;
  config: any;
  filters: Array<SearchFilter> | [];
};

export type ReportingWidgetResult = {
  dashboard_id: string;
  widget_id: string;
  result: WidgetValueSummary;
};

export type WidgetValueSummary = {
  calculatedAt: string | undefined;
  errorMessage: string | undefined;
  hasError: boolean;
  result: any;
  types: Immutable.List<FieldTypeMapping>;
};

type ReportFormatOptions = Array<{ label: string; value: ReportFormat }>;
export type ReportFormat = 'PDF' | 'CSV' | 'XLSX' | 'YML' | 'JSON' | 'XML';
const formats = ['PDF', 'CSV', 'XLSX', 'YML', 'JSON', 'XML'] as const;

export const REPORT_FORMATS_SHORT: ReportFormatOptions = formats.map((format) => ({ label: format, value: format }));

export const REPORT_FORMATS: ReportFormatOptions = [
  { label: 'Portable Document Format (PDF)', value: 'PDF' },
  { label: 'Comma-Separated Values (CSV)', value: 'CSV' },
  { label: 'Excel (XLSX)', value: 'XLSX' },
  { label: 'YAML', value: 'YML' },
  { label: 'JSON', value: 'JSON' },
  { label: 'XML', value: 'XML' },
];

export const DEFAULT_FORMAT: ReportFormat = 'PDF';

export type BackendReportLayout = {
  format?: ReportFormat;
  page_size?: 'a4' | 'letter';
  orientation: 'portrait' | 'landscape';
  print_header?: boolean;
  header?: string;
  print_footer?: boolean;
  footer?: string;
  print_page_numbers?: boolean;
  print_toc: boolean;
};

export type BackendReport = {
  delivery: ReportDelivery;
  description: string;
  hide_widget_description: boolean;
  hide_widget_query: boolean;
  id: string;
  layout: BackendReportLayout | undefined;
  parameter_values: BackendReportParameterValues;
  parameters: Record<string, Parameter> | undefined;
  positions: Array<ReportWidgetPosition>;
  status: ReportStatus | undefined;
  subtitle: string | undefined;
  timezone: string | undefined;
  title: string;
  widgets: Array<BackendReportWidget>;
};

export type BackendReportShort = {
  delivery: ReportDelivery;
  description: string;
  hide_widget_description: boolean;
  hide_widget_query: boolean;
  id: string;
  layout: BackendReportLayout | undefined;
  parameter_values: BackendReportParameterValues;
  parameters: Record<string, Parameter> | undefined;
  positions: Array<ReportWidgetPosition>;
  status: ReportStatus | undefined;
  subtitle: string | undefined;
  timezone: string | undefined;
  title: string;
  widgets: Array<BackendWidgetRef>;
  logo: string;
};

type BackendWidgetRef = {
  dashboard_id: string;
  dashboard_widget_id: string;
  title?: string;
  description?: string;
};

export type CreateReportRequest = Optional<
  Omit<BackendReport, 'widgets' | 'id' | 'owner' | 'created_at' | 'last_generated_at' | 'status' | 'parameters'> & {
    logo?: string | undefined;
    widgets: Array<BackendWidgetRef>;
  },
  'logo' | 'subtitle'
>;

export type AvailableWidgetPreview = {
  id: string;
  title: string;
  type: string;
  config: WidgetConfig;
  eligible: boolean;
  parameters: Array<Parameter>;
};

export type ParameterValues = { [name: string]: any };

export type ReportLayout = {
  format?: ReportFormat;
  pageSize?: 'a4' | 'letter';
  orientation: 'portrait' | 'landscape';
  printHeader?: boolean;
  header?: string;
  printFooter?: boolean;
  footer?: string;
  printPageNumbers?: boolean;
  toc?: boolean;
  printToc?: boolean;
};

export type ReportCreate = {
  title: string;
  subtitle?: string | undefined;
  logo?: string | undefined;
  description: string;
  widgets: Array<BackendReportWidget>;
  positions: Array<ReportWidgetPosition>;
  timezone: string | undefined;
  delivery: ReportDelivery;
  hideWidgetQuery: boolean;
  hideWidgetDescription: boolean;
  parameterValues: ParameterValues;
  parameters?: any;
  layout: ReportLayout | undefined;
};

export type Report = ReportCreate & {
  id: string;
};

export type ReportSummary = {
  created_at: string | undefined;
  delivery: ReportDelivery;
  description: string;
  id: string;
  last_generated_at: string | undefined;
  owner: string | undefined;
  owner_full_name: string | undefined;
  status: ReportStatus | undefined;
  subtitle: string | undefined;
  title: string;
  timezone: string;
  widget_count: number;
};

export type ReportStatus =
  | 'STARTING'
  | 'RUNNING'
  | 'RETRYING'
  | 'GENERATED'
  | 'SUCCESS'
  | 'FAILURE'
  | 'DELIVERING'
  | 'RETRYING_DELIVERY'
  | 'DELIVERY_FAILURE';

export type ReportHistory = {
  id: string;
  status: ReportStatus;
  message: string | undefined;
  generated_at: string;
  has_asset: boolean;
  recipients: [] | undefined;
};
