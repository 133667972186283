import { useCallback } from 'react';
import * as Immutable from 'immutable';
import { useQuery, useQueries, useMutation, useQueryClient } from '@tanstack/react-query';
import { create, windowScheduler, keyResolver } from '@yornaath/batshit';
import moment from 'moment';

import { SecurityAppSecurityEvents } from '@graylog/enterprise-api';

import UserNotification from 'util/UserNotification';
import { defaultOnError } from 'util/conditional/onError';
import type FetchError from 'logic/errors/FetchError';

import {
  fetchPaginatedSecurityEventEntities,
  newSecurityEvent,
  updateSecurityEvent,
  fetchUsersNTeams,
  triggerSecurityEventNotification,
  fetchEventDefinitions,
  fetchEntityTypes,
  toggleEventDefinitionStatus,
  copyEventDefinition,
  clearEventDefinitionNotificationsQueue,
  deleteEventDefinition,
  blkEnableDefinition,
  blkDisableDefinition,
  blkDeleteDefinition,
  fetchNotifications,
  fetchNotificationDetails,
  deleteNotification,
  testNotification,
  fetchEventDefinitionDetailsByIds,
  fetchSecurityEventByIds,
  triggerBulkSecurityEventNotification,
  securityEventEntitiesKeyFn,
  fetchSecurityEventsFilterOptions,
  fetchDetectionChainEventDefinitionSummary,
} from './api/securityEventsAPI';
import type {
  OwnerOptionType,
  PaginatedEventDefinitionsAPIType,
  PaginatedNotificationsAPIType,
  DetectionChainEventDefinitionSummary,
  SecurityEventAPIType,
  Status,
} from './api/securityEventsAPI.types';

export const securityEventsKey = 'get-security-events';
export const eventDefinitionsKey = 'get-event-definitions';

const useInvalidateSecurityEvents = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (eventIds: Array<string>) =>
      eventIds.forEach((eventId) => queryClient.invalidateQueries([securityEventsKey, eventId])),
    [queryClient],
  );
};

export type PaginatedProps = {
  page: number;
  perPage: number;
  query?: string;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  filters?: { alerts?: string; timerange?: number; associated_assets?: Array<string>; risk_score?: number };
};

const securityEventPipelines = create({
  fetcher: async (eventIds: Array<string>) => fetchSecurityEventByIds(eventIds),
  resolver: keyResolver('event_id'),
  scheduler: windowScheduler(10),
});

export const useGetSecurityEvent = (eventId: string) => {
  const { data, isLoading } = useQuery<SecurityEventAPIType, Error>(
    [securityEventsKey, eventId],
    () => securityEventPipelines.fetch(eventId),
    {
      retry: 2,
    },
  );

  return {
    loadingSecurityEvents: isLoading,
    securityEvent: data,
  };
};

const prepareFilters = (filters: PaginatedProps['filters']) => {
  let result = Immutable.OrderedMap<string, Array<string>>();
  switch (filters.alerts) {
    case 'only':
      result = result.set('alert', ['true']);
      break;
    case 'exclude':
      result = result.set('alert', ['false']);
      break;
    default:
  }

  if (filters.timerange) {
    result = result.set('timestamp', [
      `${moment().subtract(Number(filters.timerange), 'seconds').toISOString()}><${moment().toISOString()}`,
    ]);
  }

  if (filters.associated_assets) {
    result = result.set('associated_assets', filters.associated_assets);
  }

  if (filters.risk_score) {
    result = result.set('risk_score', [String(filters.risk_score)]);
  }

  return result;
};
export function useGetSecurityEvents(
  { page, perPage, query, orderBy, direction, filters }: PaginatedProps,
  userTimezone?: string,
) {
  const { data, isLoading } = useQuery(
    [securityEventsKey, page, perPage, query, orderBy, direction, filters, userTimezone],
    () =>
      defaultOnError(
        fetchPaginatedSecurityEventEntities(
          {
            page,
            pageSize: perPage,
            query,
            sort: { attributeId: orderBy, direction },
            filters: prepareFilters(filters),
          },
          userTimezone,
        ),
        'Error fetching security events',
      ),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingSecurityEvents: isLoading,
    securityEvents: isLoading ? [] : data?.list || [],
    pagination: {
      page: data?.pagination?.page || page,
      perPage: data?.pagination?.per_page || perPage,
      total: data?.pagination?.total || 0,
      grandTotal: data?.pagination?.total || 0,
      count: data?.pagination?.count || 0,
    },
  };
}

export function useFetchSecurityEventsFilterOptions() {
  const { mutateAsync, isLoading } = useMutation(fetchSecurityEventsFilterOptions, {
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    fetchSecurityEventsFilterOptions: mutateAsync,
    fetchingSecurityEventsFilterOptions: isLoading,
  };
}

export function useCreateSecurityEvent() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(newSecurityEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries([securityEventsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    createSecurityEvent: mutateAsync,
    creatingSecurityEvent: isLoading,
  };
}

export function useUpdateSecurityEvent() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateSecurityEvent, {
    onSuccess: () => {
      UserNotification.success('Event updated successfully');
      queryClient.invalidateQueries([securityEventsKey]);
      queryClient.invalidateQueries(securityEventEntitiesKeyFn(undefined).slice(0, -1));
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    updateSecurityEvent: mutateAsync,
    updatingSecurityEvent: isLoading,
  };
}

export function useGetUsersNTeams() {
  const { data, isLoading } = useQuery<OwnerOptionType[], Error>(
    ['get-users-n-teams'],
    () => defaultOnError(fetchUsersNTeams(), 'Error fetching users & teams'),
    {
      retry: 2,
    },
  );

  return {
    loadingUsersNTeams: isLoading,
    usersNTeams: data || [],
  };
}

export function useTriggerSecurityEventNotification() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(triggerSecurityEventNotification, {
    onSuccess: () => {
      UserNotification.success('Notification triggered successfully');
      queryClient.invalidateQueries(['trigger-security-event-notification']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    triggerSecurityEventNotification: mutateAsync,
    triggeringSecurityEventNotification: isLoading,
  };
}

export function useBulkTriggerSecurityEventNotification() {
  const { mutateAsync, isLoading } = useMutation(triggerBulkSecurityEventNotification, {
    onSuccess: () => {
      UserNotification.success('Notifications triggered successfully');
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    triggerSecurityEventNotifications: mutateAsync,
    triggeringSecurityEventNotifications: isLoading,
  };
}

export function useBulkSetOwnerForSecurityEvents() {
  const invalidateSecurityEvents = useInvalidateSecurityEvents();
  const { mutateAsync, isLoading } = useMutation(
    ({ ids, ownerId }: { ids: Array<string>; ownerId: string }) =>
      SecurityAppSecurityEvents.setOwnerForEvents({ event_ids: ids, owner_id: ownerId }),
    {
      onSuccess: (_, { ids }) => {
        invalidateSecurityEvents(ids);
        UserNotification.success('Set owners successfully');
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    setOwner: mutateAsync,
    settingOwners: isLoading,
  };
}

export function useBulkSetStatusForSecurityEvents() {
  const invalidateSecurityEvents = useInvalidateSecurityEvents();
  const { mutateAsync, isLoading, isError, error } = useMutation(
    ({ ids, status }: { ids: Array<string>; status: Status }) =>
      SecurityAppSecurityEvents.setStatusForEvents({ event_ids: ids, status }),
    {
      onSuccess: (_, { ids }) => {
        invalidateSecurityEvents(ids);
        UserNotification.success('Set status successfully');
      },
    },
  );

  return {
    setStatus: mutateAsync,
    settingStatus: isLoading,
    isError,
    error: error as FetchError,
  };
}

export function useBulkAddNotesForSecurityEvents() {
  const invalidateSecurityEvents = useInvalidateSecurityEvents();
  const { mutateAsync, isLoading } = useMutation(
    ({ ids, notes }: { ids: Array<string>; notes: string }) =>
      SecurityAppSecurityEvents.addNotesForEvents({ event_ids: ids, notes }),
    {
      onSuccess: (_, { ids }) => {
        invalidateSecurityEvents(ids);
        UserNotification.success('Added notes successfully');
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    addNotes: mutateAsync,
    addingNotes: isLoading,
  };
}

/**
 * Event Definitions
 * */

export function useGetEventDefinitions({ page, perPage, query, orderBy, direction }: PaginatedProps) {
  const { data, isLoading } = useQuery<PaginatedEventDefinitionsAPIType, Error>(
    [eventDefinitionsKey, page, perPage, query, orderBy, direction],
    () =>
      defaultOnError(
        fetchEventDefinitions(page, perPage, query, orderBy, direction),
        'Error fetching event definitions',
      ),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingEventDefinitions: isLoading,
    eventDefinitions: isLoading ? [] : data?.elements || [],
    pagination: data?.pagination || {
      total: 0,
      count: 0,
      page: 1,
      per_page: 10,
    },
  };
}

const eventDefinitionDetailsKey = 'get-event-definition-details';

export function useGetEventDefinitionDetails(eventDefinitionIds: string[]) {
  const queryClient = useQueryClient();
  const results = useQuery(
    [eventDefinitionDetailsKey, eventDefinitionIds],
    () =>
      fetchEventDefinitionDetailsByIds(eventDefinitionIds).then((response) => {
        response.forEach((eventDefinition) =>
          queryClient.setQueryData([eventDefinitionDetailsKey, eventDefinition.id], eventDefinition),
        );

        return response;
      }),
    {
      enabled: eventDefinitionIds.length > 0,
    },
  );

  return {
    loadingEventDefinition: results.isInitialLoading,
    eventDefinitions: results.data ?? [],
  };
}

const eventDefinitionPipelines = create({
  fetcher: async (eventDefinitionIds: Array<string>) => fetchEventDefinitionDetailsByIds(eventDefinitionIds),
  resolver: keyResolver('id'),
  scheduler: windowScheduler(10),
});

export const useGetEventDefinitionDetailsBatched = (eventDefinitionId: string) => {
  const { data, isLoading } = useQuery(
    [eventDefinitionDetailsKey, eventDefinitionId],
    () => defaultOnError(eventDefinitionPipelines.fetch(eventDefinitionId), 'Fetching event definitions failed.'),
    {
      enabled: !!eventDefinitionId,
    },
  );

  return {
    loadingEventDefinition: isLoading,
    eventDefinition: data,
  };
};

export function useGetEntityTypes() {
  const { data, isLoading } = useQuery<{ processor_types: string[] }, Error>(
    ['get-entity-types'],
    () => defaultOnError(fetchEntityTypes(), 'Error fetching entity types'),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingEntityTypes: isLoading,
    entityTypes: isLoading
      ? []
      : data.processor_types.reduce((acc, procType) => {
          if (procType === 'aggregation-v1') acc[procType] = 'Filter & Aggregation';
          if (procType === 'correlation-v1') acc[procType] = 'Event Correlation';
          if (procType === 'system-notifications-v1') acc[procType] = 'System Notifications';
          if (procType === 'anomaly-v1') acc[procType] = 'Anomaly Detector';
          if (procType === 'sigma-v1') acc[procType] = 'Sigma Detector';

          return acc;
        }, {}),
  };
}

export function useToggleEventDefinitionStatus() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(toggleEventDefinitionStatus, {
    onSuccess: () => {
      UserNotification.success('Event definition status updated successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    toggleEventDefinitionStatus: mutateAsync,
    togglingEventDefinitionStatus: isLoading,
  };
}

export function useCopyEventDefinition() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(copyEventDefinition, {
    onSuccess: () => {
      UserNotification.success('Event definition copied successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    copyEventDefinition: mutateAsync,
    copyingEventDefinition: isLoading,
  };
}

export function useClearEventDefinitionNotificationsQueue() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(clearEventDefinitionNotificationsQueue, {
    onSuccess: () => {
      UserNotification.success('Event definition notifications queue cleared successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    clearEventDefinitionNotificationsQueue: mutateAsync,
    clearingEventDefinitionNotificationsQueue: isLoading,
  };
}

export function useDeleteEventDefinition() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteEventDefinition, {
    onSuccess: () => {
      UserNotification.success('Event definition deleted successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
      queryClient.invalidateQueries([securityEventsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    deleteEventDefinition: mutateAsync,
    deletingEventDefinition: isLoading,
  };
}

export function useBlkEnableDefinition() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(blkEnableDefinition, {
    onSuccess: () => {
      UserNotification.success('Event definitions enabled successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    blkEnableDefinition: mutateAsync,
    blkEnablingDefinition: isLoading,
  };
}

export function useBlkDisableDefinition() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(blkDisableDefinition, {
    onSuccess: () => {
      UserNotification.success('Event definitions disabled successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    blkDisableDefinition: mutateAsync,
    blkDisablingDefinition: isLoading,
  };
}

export function useBlkDeleteDefinition() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(blkDeleteDefinition, {
    onSuccess: () => {
      UserNotification.success('Event definitions deleted successfully');
      queryClient.invalidateQueries([eventDefinitionsKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    blkDeleteDefinition: mutateAsync,
    blkDeletingDefinition: isLoading,
  };
}

/**
 * Notifications
 * */

export function useGetNotifications({ page, perPage, query, orderBy, direction }: PaginatedProps) {
  const { data, isLoading } = useQuery<PaginatedNotificationsAPIType, Error>(
    ['get-security-event-notifications', page, perPage, query, orderBy, direction],
    () =>
      defaultOnError(
        fetchNotifications(page, perPage, query, orderBy, direction),
        'Error fetching security event notifications',
      ),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingNotifications: isLoading,
    notifications: isLoading ? [] : data?.elements || [],
    pagination: {
      page: data?.pagination.page || page,
      perPage: data?.pagination.per_page || perPage,
      total: data?.pagination.total || 0,
      grandTotal: data?.pagination.total || 0,
      count: data?.pagination.count || 0,
    },
  };
}

export function useGetNotificationsDetails(notificationIds: string[]) {
  const results = useQueries({
    queries: notificationIds.map((notificationId: string) => ({
      queryKey: ['get-security-event-notification-details', notificationId],
      queryFn: () =>
        defaultOnError(fetchNotificationDetails(notificationId), 'Error fetching security event notification details'),
      enabled: notificationIds.length > 0,
    })),
  });

  const { data, isLoading } = results.reduce(
    (acc, result) => {
      if (Array.isArray(acc.data)) acc.data.push(result.data);
      else acc.data = [result.data];
      acc.isLoading = results.some((res) => res.isLoading);

      return acc;
    },
    { data: [], isLoading: false },
  );

  return {
    loadingNotifications: isLoading,
    notifications: data,
  };
}

export function useDeleteNotification() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteNotification, {
    onSuccess: () => {
      UserNotification.success('Notification deleted successfully');
      queryClient.invalidateQueries(['get-security-event-notifications']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    deleteNotification: mutateAsync,
    deletingNotification: isLoading,
  };
}

export function useTestNotification() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(testNotification, {
    onSuccess: () => {
      UserNotification.success('Notification sent successfully');
      queryClient.invalidateQueries(['get-security-event-notifications']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    testNotification: mutateAsync,
    testingNotification: isLoading,
  };
}

export function useDetectionChainEventDefinitionSummary(eventDefinitionId: string) {
  const { data, isLoading } = useQuery<DetectionChainEventDefinitionSummary, Error>(
    ['detection-chain-event-definition-summary'],
    () =>
      defaultOnError(
        fetchDetectionChainEventDefinitionSummary(eventDefinitionId),
        'Error fetching Detection Chain details for the event definition',
      ),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingDetectionChain: isLoading,
    detectionChain: data,
  };
}
