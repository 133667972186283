import { useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';

export type DataLakeRetentionUpdateProps = {
  streamId: string;
  retentionTime: string;
};

const onUpdateDataLakeRetention = ({ streamId, retentionTime }: DataLakeRetentionUpdateProps) =>
  fetch('POST', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.stream_retention(streamId).url), {
    retention_time: retentionTime,
  });

const useDataLakeRetention = (): {
  onUpdateDataLakeRetentionMutation: (retrievalOperationDeleteProps: DataLakeRetentionUpdateProps) => Promise<void>;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: onUpdateDataLakeRetentionMutation } = useMutation(onUpdateDataLakeRetention, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data-lake-config']);

      UserNotification.success(
        'Successfully updated Data Lake retention configuration.',
        'Update Data Lake retention configuration',
      );
    },
    onError: (errorThrown) => {
      UserNotification.error(
        `Updating Data Lake retention configuration failed with status: ${errorThrown}`,
        'Could not update Data Lake retention configuration',
      );
    },
  });

  return {
    onUpdateDataLakeRetentionMutation,
  };
};

export default useDataLakeRetention;
