import * as React from 'react';

import { Table } from 'components/bootstrap';
import { PaginatedList, Spinner } from 'components/common';
import useEventNotifications from 'components/event-notifications/hooks/useEventNotifications';
import { usePagination, useSetPagination } from 'common/contexts';
import Center from 'components/common/Center';

import ListHeader from './ListHeader';
import ListRows from './ListRows';

type Props = {
  notificationId: string;
  setNotificationId: (notification: string) => void;
};

function List({ notificationId, setNotificationId }: Props) {
  const localPagination = usePagination();
  const setLocalPagination = useSetPagination();
  const { data: paginatedEventNotifications, isInitialLoading: isLoadingEventNotifications } = useEventNotifications({
    query: '',
    page: localPagination.page,
    pageSize: localPagination.perPage,
    sort: {
      attributeId: 'title',
      direction: 'asc',
    },
  });

  const onPageChange = (newPage: number, newPageSize: number) => {
    setLocalPagination({ ...localPagination, page: newPage, perPage: newPageSize });
  };

  return (
    <div>
      {isLoadingEventNotifications ? (
        <Center>
          <div aria-label="Spinner">
            <Spinner text="Loading Notifications ..." />
          </div>
        </Center>
      ) : (
        <PaginatedList
          activePage={localPagination.page}
          pageSize={localPagination.perPage}
          onChange={onPageChange}
          totalItems={paginatedEventNotifications?.pagination.total}
          useQueryParameter={false}>
          <Table condensed hover className="table table-striped">
            <ListHeader />
            <tbody>
              <ListRows
                notificationOptions={paginatedEventNotifications.list}
                notificationId={notificationId}
                setNotificationId={setNotificationId}
              />
            </tbody>
          </Table>
        </PaginatedList>
      )}
    </div>
  );
}

export default List;
