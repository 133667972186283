import Reflux from 'reflux';

import fetch, { fetchPeriodically } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

import type { ForwarderInputState, Input, Forwarder } from '../Types';

type InputStatesResponse = {
  states: ForwarderInputState[];
};

type ForwarderInputStatesActionsType = {
  list: () => Promise<InputStatesResponse>;
  register: () => void;
  unregister: () => void;
  startInput: (forwarder: Forwarder, input: Input) => Promise<void>;
  stopInput: (forwarder: Forwarder, input: Input) => Promise<void>;
};

type ForwarderInputStatesStoreState = {
  forwarderInputStates: Array<ForwarderInputState>;
};
export const ForwarderInputStatesActions = Reflux.createActions<ForwarderInputStatesActionsType>({
  list: { asyncResult: true },
  register: { asyncResult: false },
  unregister: { asyncResult: false },
  startInput: { asyncResult: true },
  stopInput: { asyncResult: true },
});

const ForwarderInputStatesStore = Reflux.createStore<ForwarderInputStatesStoreState>({
  listenables: [ForwarderInputStatesActions],
  sourceUrl: '/plugins/org.graylog.plugins.forwarder/forwarder/inputstates',
  forwarderInputStates: null,

  listeners: 0,

  register() {
    this.listeners += 1;
  },

  unregister() {
    this.listeners -= 1;
  },

  init() {
    // fetch new status on init
    this.list();

    // fetch new status every 5 seconds
    setInterval(() => {
      if (this.listeners > 0) {
        this.list(true);
      }
    }, 5000);
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    return {
      forwarderInputStates: this.forwarderInputStates,
    };
  },

  propagateUpdate() {
    this.trigger(this.getState());
  },

  currentListingPromise: null,

  list(isPeriodicalRequest = false) {
    const fetchMethod = isPeriodicalRequest ? fetchPeriodically : fetch;

    // if already fetching... return current resolving promise
    if (this.currentListingPromise) {
      return this.currentListingPromise;
    }

    const promise = fetchMethod('GET', qualifyUrl(this.sourceUrl));

    promise
      .then((response: InputStatesResponse) => {
        this.currentListingPromise = null;

        this.forwarderInputStates = [...response.states];

        this.propagateUpdate();

        return response;
      })
      .catch((error) => {
        UserNotification.error(`Retrieving Forwarder Input State failed with status: ${error.message},
        Could not retrieve Forwarder Input States`);
      });

    ForwarderInputStatesActions.list.promise(promise);

    this.currentListingPromise = promise;

    return this.currentListingPromise;
  },

  startInput(forwarder: Forwarder, input: Input) {
    const promise = fetch(
      'PUT',
      qualifyUrl(`${this.sourceUrl}/forwarder/${forwarder.id}/input/${input.id}/requested_state/start`),
    );

    promise.then(
      () => {
        UserNotification.success(
          `Input "${input.title}" will start shortly in Forwarder "${forwarder.title}"`,
          'Start signal sent successfully',
        );
      },
      (error) => {
        UserNotification.error(
          `Could not send start signal to Forwarder "${forwarder.title}": ${error}`,
          'Could not start Input',
        );
      },
    );

    ForwarderInputStatesActions.startInput.promise(promise);
  },

  stopInput(forwarder: Forwarder, input: Input) {
    const promise = fetch(
      'PUT',
      qualifyUrl(`${this.sourceUrl}/forwarder/${forwarder.id}/input/${input.id}/requested_state/stop`),
    );

    promise.then(
      () => {
        UserNotification.success(
          `Input "${input.title}" will stop shortly in Forwarder "${forwarder.title}"`,
          'Stop signal sent successfully',
        );
      },
      (error) => {
        UserNotification.error(
          `Could not send stop signal to Forwarder "${forwarder.title}": ${error}`,
          'Could not stop Input',
        );
      },
    );

    ForwarderInputStatesActions.stopInput.promise(promise);
  },
});

export default ForwarderInputStatesStore;
