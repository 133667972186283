import * as React from 'react';

import useUserDateTime from 'hooks/useUserDateTime';
import { Table } from 'components/bootstrap';
import { PaginatedList, Spinner } from 'components/common';
import { FiltersRow, RowTitle } from 'security-app/components/common';
import { useGetSigmaRules } from 'security-app/hooks/useSigmaAPI';
import { usePagination, useSetPagination } from 'common/contexts';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';

import ListHeader from './ListHeader';
import ListRows from './ListRows';

const DFV = {
  visibleColumns: ['title', 'level', 'status', 'last_run', 'updated_at', 'enabled'],
  renderRowTitle: (rule: SigmaRuleListAPIType, editRule: (rule: SigmaRuleListAPIType) => void) => (
    <RowTitle onClick={() => editRule(rule)}>{rule.title}</RowTitle>
  ),
};

type Props = {
  visibleColumns?: Array<string>;
  displayRowActions?: boolean;
  onlyMatchingSystemConfig?: boolean,
  renderRowTitle?: (rule: SigmaRuleListAPIType, editRule: (rule: SigmaRuleListAPIType) => void) => React.ReactNode;
};

function List({
  visibleColumns = DFV.visibleColumns,
  displayRowActions = true,
  renderRowTitle = DFV.renderRowTitle,
                onlyMatchingSystemConfig = false,
}: Props) {
  const localPagination = usePagination();
  const setLocalPagination = useSetPagination();
  const { userTimezone } = useUserDateTime();

  const { rules, loadingRules, pagination } = useGetSigmaRules(localPagination, userTimezone, onlyMatchingSystemConfig);

  const onPageChange = (newPage: number, newPageSize: number) => {
    setLocalPagination({ ...localPagination, page: newPage, perPage: newPageSize });
  };

  const removeFilter = (filterKey: string) => (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const auxFilters = { ...localPagination.filters };
    delete auxFilters[filterKey];

    setLocalPagination({ ...localPagination, filters: auxFilters });
  };

  return (
    <PaginatedList
      activePage={localPagination.page}
      pageSize={localPagination.perPage}
      onChange={onPageChange}
      totalItems={pagination.total}
      useQueryParameter={false}>
      <FiltersRow filters={localPagination.filters} labels={{ updated_at: 'Last Update' }} onRemove={removeFilter} />
      <Table condensed hover className="table table-striped" data-testid="open-investigations-list">
        <ListHeader rules={rules || []} visibleColumns={visibleColumns} displayRowActions={displayRowActions} />
        <tbody>
          {loadingRules ? (
            <tr>
              <td aria-label="Loading Sigma Rules" colSpan={visibleColumns.length}>
                <Spinner text="Loading Sigma Rules ..." />
              </td>
            </tr>
          ) : (
            <ListRows
              rules={rules || []}
              renderRowTitle={renderRowTitle}
              visibleColumns={visibleColumns}
              displayRowActions={displayRowActions}
            />
          )}
        </tbody>
      </Table>
    </PaginatedList>
  );
}

export default List;
