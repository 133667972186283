import React from 'react';

import connect from 'stores/connect';
import { Spinner } from 'components/common';

import CorrelationSummary from './CorrelationSummary';
import EventsLicenseStatus from './EventsLicenseStatus';
import { EventsLicenseStore, EventsLicenseActions } from './EventsLicenseStore';

type CorrelationSummaryContainerProps = {
  config: any;
  eventsLicenseStatus: any;
};

class CorrelationSummaryContainer extends React.Component<
  CorrelationSummaryContainerProps,
  {
    [key: string]: any;
  }
> {
  componentDidMount() {
    EventsLicenseActions.getLicenseStatus();
  }

  render() {
    const { eventsLicenseStatus, ...otherProps } = this.props;

    if (eventsLicenseStatus.loading) {
      return <Spinner text="Loading Event Correlation information..." />;
    }

    return (
      <>
        <EventsLicenseStatus licenseStatus={eventsLicenseStatus} />
        <CorrelationSummary {...otherProps} />
      </>
    );
  }
}

export default connect(CorrelationSummaryContainer, {
  eventsLicenseStatus: EventsLicenseStore,
});
