import * as React from 'react';
import styled from 'styled-components';

import { BULK_SELECT_COLUMN_WIDTH } from 'components/common/EntityDataTable/Constants';

const Cell = styled.div`
  width: ${BULK_SELECT_COLUMN_WIDTH}px;
`;

type Props = {
  children: React.ReactNode;
  role?: 'columnheader' | 'cell';
};

const BulkSelectCell = ({ children, role = 'cell' }: Props) => <Cell role={role}>{children}</Cell>;

export default BulkSelectCell;
