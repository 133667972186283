import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';
import { useStore } from 'stores/connect';
import AWSRegionsStore, { AWSRegionsActions } from 'archive/AWSRegionsStore';
import type { Backend, S3BackendSettings } from 'archive/types';

const AWSRegionsFormGroup = () => {
  const { regions } = useStore(AWSRegionsStore);
  const {
    values: { settings },
    setFieldValue,
  } = useFormikContext<Backend>();
  const s3Settings: S3BackendSettings = settings;

  useEffect(() => {
    AWSRegionsActions.getRegions();

    if (!s3Settings.aws_region) {
      setFieldValue('settings.aws_region', '');

      return () => {
        setFieldValue('settings.aws_region', undefined);
      };
    }

    return () => undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue]);

  return (
    <Field name="settings.aws_region">
      {({ meta }) => (
        <Input id="default-aws-region-select" label="AWS Region" error={meta.error}>
          <Select
            id="awsRegion"
            name="AWSRegion"
            placeholder="Automatic"
            aria-label="Select AWS region"
            options={regions || []}
            matchProp="label"
            onChange={(option) => {
              setFieldValue('settings.aws_region', option);
            }}
            value={(settings as S3BackendSettings).aws_region}
          />
        </Input>
      )}
    </Field>
  );
};

export default AWSRegionsFormGroup;
