import type { ReactNode } from 'react';
import React from 'react';

import { Link } from 'components/common/router';
import { Spinner } from 'components/common';
import Routes from 'routing/Routes';
import type User from 'logic/users/User';

type Props = {
  children: ReactNode;
  user?: User;
};

const LinkToAPIToken = ({ user, children }: Props) => {
  if (!user) {
    return <Spinner />;
  }

  return (
    <Link target="_blank" to={Routes.SYSTEM.USERS.TOKENS.edit(user.id)}>
      {children}
    </Link>
  );
};

export default LinkToAPIToken;
