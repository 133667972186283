import { useFormikContext } from 'formik';
import type Immutable from 'immutable';
import { flushSync } from 'react-dom';

import type { SelectedSearchFilter } from 'search-filter/types';

import useSearchFiltersFormState from './useSearchFiltersFormState';

const useSearchFiltersFormActions = () => {
  const { setFieldValue, submitForm } = useFormikContext<{
    searchFilters: Immutable.OrderedMap<string, SelectedSearchFilter>;
  }>();
  const selectedFilters = useSearchFiltersFormState();

  const updateFilters = (
    searchFilters: Immutable.OrderedMap<string, SelectedSearchFilter> | undefined,
    shouldSubmitForm: boolean = true,
  ) => {
    setFieldValue('searchFilters', searchFilters);

    if (shouldSubmitForm) {
      flushSync(() => submitForm());
    }
  };

  const updateFilter = (
    frontendId: string,
    searchFilter: SelectedSearchFilter | ((prev: SelectedSearchFilter) => SelectedSearchFilter),
  ) => {
    const curFilter = selectedFilters.get(frontendId);
    updateFilters(
      selectedFilters.set(frontendId, searchFilter instanceof Function ? searchFilter(curFilter) : searchFilter),
    );
  };

  const addFilter = (searchFilter: SelectedSearchFilter, shouldSubmitForm?: boolean) => {
    updateFilters(selectedFilters.set(searchFilter.frontendId, searchFilter), shouldSubmitForm);
  };

  return { updateFilters, updateFilter, addFilter };
};

export default useSearchFiltersFormActions;
