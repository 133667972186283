import * as React from 'react';

import type { ParameterInputComponentProps } from './ParameterTypes';
import { useParameterType } from './ParameterTypes';

const TypeSpecificParameterInput = ({
  parameter,
  onChange,
  onBlur,
  value,
  searchId,
  inputSize,
}: ParameterInputComponentProps) => {
  const { component: Component } = useParameterType(parameter?.type);

  return (
    <Component
      parameter={parameter}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputSize={inputSize}
      searchId={searchId}
    />
  );
};

export default TypeSpecificParameterInput;
