import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import { SortableList, FormikInput, InputDescription } from 'components/common';
import IconButton from 'components/common/IconButton';
import NoEntitiesExist from 'components/common/NoEntitiesExist';
import type { ReportWidgetPosition } from 'report/types';
import defaultTitle from 'views/components/defaultTitle';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import { Button } from 'components/bootstrap';
import WidgetTitle from 'report/common/WidgetTitle';

const WidgetListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const WidgetInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  id: string;
  dashboardId: string;
  onRemove: (id: string, dashboardId: string) => void;
  index: number;
};

type ListItem = {
  id: `${string}/${string}`;
  title: React.ReactElement;
};

const WidgetDescription = ({ onRemove, id, dashboardId, index }: Props) => {
  const { widgets, isLoading } = useContext(WidgetDetailsContext);
  const [, { error }] = useField(`widgets.${index}`);
  const widgetDetails = useMemo(() => widgets[id], [widgets, id]);
  const [showDetailsConfiguration, setShowDetailsConfiguration] = useState(false);
  const _onRemove = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this widget?')) {
      onRemove(id, dashboardId);
    }
  }, [dashboardId, id, onRemove]);

  const toggleEditModal = () => {
    setShowDetailsConfiguration((cur) => !cur);
  };

  const [, { value: customTitle }] = useField(`widgets.${index}.title`);
  const detailsAreAvailable = !isLoading && !!widgetDetails;
  const widgetTitle = widgetDetails?.description ?? (widgetDetails ? defaultTitle(widgetDetails) : '');
  const title = !customTitle?.trim() ? widgetTitle : customTitle;

  return (
    <WidgetListItem>
      <WidgetInfo>
        <WidgetTitle title={title} isLoading={isLoading && !widgetDetails} detailsAreAvailable={detailsAreAvailable} />
        <div>
          <IconButton
            title="Edit widget description"
            name="edit_square"
            onClick={toggleEditModal}
            disabled={!detailsAreAvailable}
          />
          <IconButton title="Remove this widget from report" name="delete" onClick={_onRemove} />
        </div>
      </WidgetInfo>
      {error && <InputDescription error={error} className="no-bm" />}
      {showDetailsConfiguration && (
        <div>
          <FormikInput
            name={`widgets.${index}.title`}
            id={`widgets.${index}.title`}
            label="Custom Title"
            placeholder={widgetTitle}
          />
          <FormikInput
            name={`widgets.${index}.description`}
            id={`widgets.${index}.description`}
            label="Custom Description"
            type="textarea"
          />
          <Button onClick={toggleEditModal} bsSize="small">
            Hide
          </Button>
        </div>
      )}
    </WidgetListItem>
  );
};

const WidgetsList = () => {
  const [{ value: widgets }, , { setValue: setWidgets }] = useField<ReportFormValues['widgets']>('widgets');
  const [, , { setValue: setPositions }] = useField<ReportFormValues['positions']>('positions');
  const onRemove = useCallback(
    (widgetId: string, dashboardId: string) =>
      setWidgets(widgets.filter((widget) => widget.widgetId !== widgetId || widget.dashboardId !== dashboardId)),
    [setWidgets, widgets],
  );
  const onMoveItem = useCallback(
    (newWidgetItems: Array<ListItem>) => {
      const newWidgets = newWidgetItems.map((widgetItem) => {
        const [dashboardId, widgetId] = widgetItem.id.split('/');

        return widgets.find((widget) => widget.widgetId === widgetId && widget.dashboardId === dashboardId);
      });

      const newPositions: Array<ReportWidgetPosition> = newWidgets.map((widget, idx) => ({
        row: idx + 1,
        col: 1,
        dashboard_widget_id: widget.widgetId,
      }));

      setPositions(newPositions);

      return setWidgets(newWidgets);
    },
    [setPositions, setWidgets, widgets],
  );

  const listItems: Array<ListItem> = useMemo(
    () =>
      widgets.map(({ widgetId, dashboardId }, index) => ({
        id: `${dashboardId}/${widgetId}`,
        title: <WidgetDescription id={widgetId} index={index} dashboardId={dashboardId} onRemove={onRemove} />,
      })),
    [onRemove, widgets],
  );

  return listItems.length > 0 ? (
    <SortableList<ListItem> items={listItems} onMoveItem={onMoveItem} />
  ) : (
    <NoEntitiesExist>This report has no widgets yet.</NoEntitiesExist>
  );
};

export default WidgetsList;
