import React, { useState } from 'react';

import { Button } from 'components/bootstrap';
import { DATA_LAKE_CONFIG_ACTION } from 'data-lake/Types';
import DataLakeBackendFormModal from 'data-lake/data-lake-backend-config/DataLakeBackendFormModal';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { IfPermitted } from 'components/common';

type Props = {
  buttonType?: 'button' | 'menuItem';
  backendId?: string;
};

const DataLakeBackendButton = ({ backendId = undefined, buttonType = 'button' }: Props) => {
  const action: DATA_LAKE_CONFIG_ACTION = backendId ? DATA_LAKE_CONFIG_ACTION.Update : DATA_LAKE_CONFIG_ACTION.Create;
  const buttonText = action === DATA_LAKE_CONFIG_ACTION.Update ? 'Edit Data Lake Backend' : 'Create Data Lake Backend';
  const menuItemText = action === DATA_LAKE_CONFIG_ACTION.Update ? 'Edit' : 'Create';
  const sendTelemetry = useSendTelemetry();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);

    sendTelemetry(
      TELEMETRY_EVENT_TYPE.DATALAKE[`BACKEND_${action === DATA_LAKE_CONFIG_ACTION.Create ? 'CREATE' : 'EDIT'}_OPENED`],
      {
        app_pathname: 'data-lake',
      },
    );
  };

  const isMenuButton = buttonType === 'menuItem';

  return (
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.archive} hideChildren displayLicenseWarning={false}>
      {showModal && <DataLakeBackendFormModal backendId={backendId} onClose={onModalClose} />}
      <IfPermitted permissions="data_lake_backend_config:update">
        <Button bsStyle={isMenuButton ? 'default' : 'success'} bsSize="medium" onClick={onButtonClick}>
          {isMenuButton ? menuItemText : buttonText}
        </Button>
      </IfPermitted>
    </LicenseCheck>
  );
};

export default DataLakeBackendButton;
