import { useQuery } from '@tanstack/react-query';

import { DataLakeQuery } from '@graylog/enterprise-api';

import type { ActivePreviewSearch } from 'data-lake/preview/Types';
import { INITIAL_PAGE, PAGE_SIZE } from 'data-lake/preview/Constants';

const useFetchActiveSearch = (): {
  data: ActivePreviewSearch | undefined;
  isFetching?: boolean;
  refetch: () => Promise<unknown>;
} =>
  useQuery({
    queryKey: ['data-lake', 'preview', 'active-query'],
    queryFn: () => DataLakeQuery.activeQuery(INITIAL_PAGE, PAGE_SIZE),
    retry: 0,
  });

export default useFetchActiveSearch;
