import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Row } from 'security-app/components/common/FormBaseStyles';
import type { Assignee } from 'security-app/components/Investigations/hooks/useUsersAndTeams';

const ICON_TYPES = {
  team: 'group',
  user: 'person',
} as const;

const StyledIcon = styled(Icon)`
  width: 24px;
  vertical-align: middle;
`;

type Props = {
  assignee: Assignee;
};

function AssigneeCell({ assignee }: Props) {
  return (
    <Row $align="center" $gap="0.2rem">
      {!assignee ? (
        <span>--</span>
      ) : (
        <>
          <StyledIcon name={ICON_TYPES[assignee.type]} size="xs" />
          {assignee.label}
        </>
      )}
    </Row>
  );
}

export default AssigneeCell;
