import React from 'react';

import PageHeader from 'components/common/PageHeader';
import { ExternalLink } from 'components/common';

import EmbeddedAWSSecurityLakeApp from './EmbeddedAWSSecurityLakeApp';

const AWSSecurityLakeApp = () => (
  <>
    <PageHeader title="AWS Security Lake Integrations">
      <span>This feature retrieves log records from AWS Security Lake Platform.</span>
      <p>
        You need to have <ExternalLink href="https://aws.amazon.com/console/">AWS Security Lake</ExternalLink>.{' '}
      </p>
    </PageHeader>
    <EmbeddedAWSSecurityLakeApp />
  </>
);

export default AWSSecurityLakeApp;
