import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import glFetch from 'logic/rest/FetchProvider';
import { stringifyFilters } from 'security-app/components/common/Filters/stringifyFilters';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

import type {
  AssetsIndexAPIType,
  AssetAPIType,
  PriorityAPIType,
  AssetDetailsType,
  AssetSourceIndexAPIType,
  AssetSourceAPIType,
  AssetSourceConfigTypes,
  AssetSourceMappingAPIType,
  AssetSourceMappingConfigsType,
  AssetSourceMS365MappingConfigsType,
  AssetSourceMappingsIndexAPIType,
  ScannerIndexAPIType,
  ScannerAPIType,
  ScannerConfigAPITypes,
  AssetLabelsAPIType,
} from './assetsAPI.types';

export const fetchAssetLabels = async (
  page: number,
  perPage: number,
  query: string = undefined,
): Promise<AssetLabelsAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`];

  if (query) params.push(`query=${encodeURI(query)}`);

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.assetLabels(params.join('&')).url)).then(
    (response) => ({
      ...response,
      assets: response.assets?.map((asset) => ({ id: asset._id, name: asset.name })),
    }),
  );
};

export const fetchAssets = async (
  page: number,
  perPage: number,
  query: string = undefined,
  orderBy: string = undefined,
  direction: string = undefined,
  filters: ColumnFilterData = {},
): Promise<AssetsIndexAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`];

  if (query) params.push(`query=${encodeURI(query)}`);

  if (orderBy) {
    // column header name is machine_risk_score or user_risk_score
    if (orderBy.includes('risk_score')) {
      params.push('sort=risk_score_normalized');
    } else {
      params.push(`sort=${orderBy}`);
    }
  }

  if (direction) params.push(`direction=${direction}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
  }

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.index(params.join('&')).url));
};

export const fetchAssetsFilterOptions = async ({ fields }: { fields: string[] }) => {
  const payload = {
    fields: fields,
  };

  return glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.filters().url), payload);
};

export const fetchAsset = async (assetId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.singleAsset(assetId).url));

export const getAssetsById = async (query: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.assetIdLookup(query).url));

export const deleteAsset = async ({ assetId }: { assetId: string }) =>
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.singleAsset(assetId).url));

export const updateAsset = async ({ assetId, asset }: { assetId: string; asset: AssetAPIType<AssetDetailsType> }) =>
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.singleAsset(assetId).url), asset);

export const createAsset = async ({ asset }: { asset: AssetAPIType<AssetDetailsType> }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.new().url), asset);

export const fetchPriorities = async () =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.index().url));

export const fetchCategories = async () =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.index().url));

export const addPriority = async ({ priority }: { priority: string }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.add().url), priority);

export const reprioritizePriorities = async ({ payload }: { payload: { priorities: PriorityAPIType[] } }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.reprioritize().url), payload);

export const updatePriority = async ({ priorityId, priority }: { priorityId: string; priority: string }) =>
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.update(priorityId).url), priority);

export const deletePriority = async ({ priorityId }: { priorityId: string }) =>
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.delete(priorityId).url));

export const addCategory = async ({ category }: { category: string }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.add().url), category);

export const updateCategory = async ({ categoryId, category }: { categoryId: string; category: string }) =>
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.update(categoryId).url), category);

export const deleteCategory = async ({ categoryId }: { categoryId: string }) =>
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.delete(categoryId).url));

export const bulkAddCategories = async ({ assetIds, categories }: { assetIds: string[]; categories: string[] }) => {
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.bulkAddCategories().url), {
    asset_ids: assetIds,
    categories,
  });
};

/* Asset Sources */
export const fetchAssetSources = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = null,
  direction: string = null,
  filters: ColumnFilterData = {},
): Promise<AssetSourceIndexAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`];
  if (query) params.push(`query=${encodeURI(query)}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
  }

  if (orderBy) {
    params.push(`sort=${orderBy}`);
  }

  if (direction) {
    params.push(`direction=${direction}`);
  }

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.index(params.join('&')).url));
};

export const fetchAssetSource = async (sourceId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.details(sourceId).url));

export const createAssetSource = async ({ source }: { source: AssetSourceAPIType<AssetSourceConfigTypes> }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.new().url), source);

export const updateAssetSource = async ({
  sourceId,
  source,
}: {
  sourceId: string;
  source: AssetSourceAPIType<AssetSourceConfigTypes>;
}) => glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.update(sourceId).url), source);

export const deleteAssetSource = async ({ sourceId }: { sourceId: string }) =>
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.delete(sourceId).url));

export const fetchMS365DeploymentTypes = async () =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.ms365DeploymentTypes().url));

/* Asset Source Mappings */
export const fetchAssetSourceMappings = async (
  sourceId: string,
  page: number,
  perPage: number,
): Promise<AssetSourceMappingsIndexAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`, 'sort=updated_at', 'direction=desc'];

  return glFetch(
    'GET',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.index(sourceId, params.join('&')).url),
  );
};

export const fetchAssetSourceMapping = async (sourceId: string, mappingId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.details(sourceId, mappingId).url));

export const createAssetSourceMapping = async ({
  sourceId,
  mapping,
}: {
  sourceId: string;
  mapping: AssetSourceMappingAPIType<AssetSourceMappingConfigsType | AssetSourceMS365MappingConfigsType>;
}) =>
  new Promise((resolve, reject) => {
    fetch(qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.new(sourceId).url), {
      method: 'POST',
      body: JSON.stringify(mapping),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-By': 'XMLHttpRequest',
      },
    }).then(async (response) => {
      const respBody = await response.json();
      if (response.ok) resolve(respBody);
      else reject(respBody);
    });
  });

export const updateAssetSourceMapping = async ({
  sourceId,
  mappingId,
  mapping,
}: {
  sourceId: string;
  mappingId: string;
  mapping: AssetSourceMappingAPIType<AssetSourceMappingConfigsType | AssetSourceMS365MappingConfigsType>;
}) =>
  new Promise((resolve, reject) => {
    fetch(qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.update(sourceId, mappingId).url), {
      method: 'PUT',
      body: JSON.stringify(mapping),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-By': 'XMLHttpRequest',
      },
    }).then(async (response) => {
      const respBody = await response.json();
      if (response.ok) resolve(respBody);
      else reject(respBody);
    });
  });

export const deleteAssetSourceMapping = async ({ sourceId, mappingId }: { sourceId: string; mappingId: string }) =>
  glFetch(
    'DELETE',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.delete(sourceId, mappingId).url),
  );

export const importAssets = async (sourceId: string, mappingId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.import(sourceId, mappingId).url));

/* Test Asset Source & Mappings */
export const testNewAssetSource = async ({ source }: { source: AssetSourceAPIType<AssetSourceConfigTypes> }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.testNew().url), source);

export const testNewAssetSrouceMapping = async ({
  sourceId,
  mapping,
}: {
  sourceId: string;
  mapping: AssetSourceMappingAPIType<AssetSourceMappingConfigsType | AssetSourceMS365MappingConfigsType>;
}) => {
  const mappingId = mapping.id || 'new';
  const payload = { asset_import_backend_configuration: mapping };

  return glFetch(
    'POST',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.testNew(sourceId, mappingId).url),
    payload,
  );
};

export const testSavedAssetSource = async (sourceId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.testSaved(sourceId).url));

export const bulkTestAssetSource = async ({ ids }: { ids: string[] }) => {
  const requestBody = {
    ids,
  };

  return glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.bulkTest().url), requestBody);
};

export const testSavedAssetSourceMapping = async (sourceId: string, mappingId: string) =>
  glFetch(
    'GET',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.testSaved(sourceId, mappingId).url),
  );

export const toggleMappingSync = async ({
  sourceId,
  mappingId,
  enabled,
  interval_hours,
}: {
  sourceId: string;
  mappingId: string;
  enabled: boolean;
  interval_hours: number;
}) => {
  const requestBody = {
    enabled: !enabled,
    interval_hours,
  };

  return glFetch(
    'POST',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.toggleSync(sourceId, mappingId).url),
    requestBody,
  );
};

/* Vulnerability Scanners */
export const fetchScanners = async (
  page: number,
  perPage: number,
  query: string,
  orderBy: string = 'title',
  direction: string = 'asc',
  filters: ColumnFilterData = {},
): Promise<ScannerIndexAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`];

  if (query) params.push(`query=${encodeURI(query)}`);
  if (orderBy) params.push(`sort=${orderBy}`);
  if (direction) params.push(`direction=${direction}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
  }

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.index(params.join('&')).url));
};

export const fetchScannerDetails = async (scannerId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.details(scannerId).url));

export const addScanner = async ({ scanner }: { scanner: ScannerAPIType<ScannerConfigAPITypes> }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.new().url), scanner);

export const deleteScanner = async ({ scannerId }: { scannerId: string }) =>
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.delete(scannerId).url));

export const editScanner = async ({ scanner }: { scanner: ScannerAPIType<ScannerConfigAPITypes> }) =>
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.edit(scanner.id).url), scanner);

export const toggleScannerSync = async ({
  scannerId,
  enabled,
  interval_hours,
}: {
  scannerId: string;
  enabled: boolean;
  interval_hours: number;
}) => {
  const requestBody = {
    enabled: !enabled,
    interval_hours,
  };

  return glFetch(
    'POST',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.schedule(scannerId).url),
    requestBody,
  );
};

export const testNewScannerConnection = async ({ scanner }: { scanner: ScannerAPIType<ScannerConfigAPITypes> }) =>
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.testNewScannerConnection().url), scanner);

export const testScannerConnection = async ({ id }: { id: string }) =>
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.testScannerConnection(id).url));

export const importVulnerabilityScans = async (scannerId: string) =>
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.scanners.import(scannerId).url));
