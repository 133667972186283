import React, { useEffect, useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';
import type { ErrorMessageType, HandleFieldUpdateType, FormDataContextType, HandleSubmitType } from './types';

import { renderOptions } from '../common/Options';
import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    fetch('GET', qualifyUrl(ApiRoutes.INTEGRATIONS.AWSSecurityLake.GET_AWS_REGIONS)).then((response) => {
      const region = Object.keys(response).map((key) => ({ label: response[key], value: key }));
      setRegions(region);
    });
  }, []);

  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { awsSecurityLakeSecretKey } = formData;
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    fetch('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.AWSSecurityLake.CHECK_CREDENTIALS), {
      aws_access_key: formData?.awsSecurityLakeAccessKeyId?.value || '',
      aws_secret_key: formData?.awsSecurityLakeSecretKey?.value || '',
      security_lake_queue_name: formData?.securityLakeSqsQueueName?.value || '',
      aws_region: formData?.securityLakeRegion?.value || '',
    })
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message: 'Unable to connect to AWS Security Lake using provided configuration.',
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        setFormError({
          full_message: err.message,
          nice_message: 'Unable to connect to AWS Security Lake using provided configuration.',
        });
      });
  };

  const isFormValid = formValidation.isFormValid(
    ['awsSecurityLakeName', 'awsSecurityLakeAccessKeyId', 'awsSecurityLakeSecretKey', 'securityLakeRegion'],
    formData,
  );

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      loading={loading}
      title=""
      error={formError}
      description="">
      <ValidatedInput
        className=""
        id="awsSecurityLakeName"
        type="text"
        fieldData={formData.awsSecurityLakeName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={awsSecurityLakeSecretKey?.value}
        required
      />

      <ValidatedInput
        className=""
        id="awsSecurityLakeAccessKeyId"
        type="text"
        fieldData={formData.awsSecurityLakeAccessKeyId}
        onChange={onChange}
        label="AWS Access Key Id"
        help="The AWS IAM Access key ID with sufficient permission to Security Lake."
        autoComplete="off"
        required
      />

      <ValidatedInput
        className=""
        id="awsSecurityLakeSecretKey"
        type="password"
        onChange={onChange}
        fieldData={formData.awsSecurityLakeSecretKey}
        help="The AWS secret key that application uses to access the Security Lake."
        label="AWS Secret Access Key"
        required
      />
      <ValidatedInput
        type="select"
        id="securityLakeRegion"
        onChange={onChange}
        fieldData={formData.securityLakeRegion}
        help="Security Lake region where the subscriber is created."
        required
        label="Security Lake Region">
        {renderOptions(regions, 'Choose your account region', false)}
      </ValidatedInput>

      <ValidatedInput
        className=""
        id="securityLakeSqsQueueName"
        type="text"
        onChange={onChange}
        fieldData={formData.securityLakeSqsQueueName}
        help="SQS queue name created by the Security Lake Subscriber"
        label="SQS Queue Name"
        required
      />
    </FormWrap>
  );
};

export default StepAuthorize;
