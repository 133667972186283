import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';
import Select from 'components/common/Select';

import FormAdvancedOptions from './FormAdvancedOptions';
import INITIAL_FORMDATA from './_initialFormData';

import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from '../common/utils/types';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;

}

const StepSubscribe = (
  {
    onSubmit,
    onChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { pollingInterval } = formData;

  const availableContent = [
    { label: 'Email Logs', value: 'EMAIL_LOGS' },
    { label: 'Directory Audit Logs', value: 'DIRECTORY_AUDIT_LOGS' },
    { label: 'SignIn Audit Logs', value: 'SIGN_IN_AUDIT_LOGS' },
    { label: 'Provisioning Audit Logs', value: 'PROVISIONING_AUDIT_LOGS' },
  ];

  const initialContent = formData.subscriptionType.value !== 'GLOBAL'
    ? INITIAL_FORMDATA.availableContents.value.split(',').filter(x => (x !== 'SIGN_IN_AUDIT_LOGS')).join(',')
    : INITIAL_FORMDATA.availableContents.value;


  const [contentTypes, setContentTypes] = useState<string>(initialContent);

  const handleSubmit = () => {
    let error = false;
    formData.content_type.value = contentTypes;

    if (pollingInterval.value < 1) {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message:
          'Minimum allowable polling interval is 1 minute.',
      });

      error = true;
    }

    if (!error) {
      setFormError(null);
      onSubmit();
    }
  };

  const handleReadTimeOffsetChange = (errorMessage) => {
    if (errorMessage) {
      setFormError({
        full_message: errorMessage,
        nice_message: errorMessage,
      });
    } else {
      setFormError(null); // Reset formError if no error message
    }
  };

  return (
    <FormWrap onSubmit={handleSubmit}
      buttonContent="Proceed"
      title=""
      error={formError}
      description="">
      <label className="control-label" htmlFor="contents">Logs Types to Collect</label>
      <Select multi
        name="content_type"
        id="content_type"
        onChange={(selected: string) => setContentTypes(selected)}
        options={availableContent}
        value={contentTypes}
        matchProp="label" />
      <span className="help-block">The activity log for the above content types will be fetched.</span>
      <Input id="pollingInterval"
        name="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue || ''}
        min="1"
        max="60"
        step="1"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in Microsoft Graph API. The smallest allowable interval is 1 minute."
        label="Polling Interval" />

      <FormAdvancedOptions
        onChange={onChange}
        handleReadTimeOffsetChange={handleReadTimeOffsetChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
