import * as React from 'react';
import styled from 'styled-components';

import Store from 'logic/local-storage/Store';
import { useGetPacksSummary, ILLUMINATE_STORE_VERSION_KEY } from 'illuminate/hooks/usePacksAPI';

const Badge = styled.span`
  position: relative;

  &::after {
    display: 'block';
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;

    background-color: ${({ theme }) => theme.colors.brand.primary};
    border-radius: 50%;
    top: 0;
    right: -12px;
  }
`;

function IlluminateDotBadge({ text }: { text: string }) {
  const skippedVersion = Store.get(ILLUMINATE_STORE_VERSION_KEY);
  const {
    packsSummary: { new_packs_available, updated_packs_available, cached_version },
  } = useGetPacksSummary();

  return (new_packs_available || updated_packs_available) && cached_version && cached_version !== skippedVersion ? (
    <Badge title="New Illuminate packs available">{text}</Badge>
  ) : (
    <span>{text}</span>
  );
}

export default IlluminateDotBadge;
