import React, { useCallback, useState, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useFormikContext } from 'formik';

import Popover from 'components/common/Popover';
import useClickOutside from 'hooks/useClickOutside';
import type { SelectedSearchFilter, UserSearchFilter } from 'search-filter/types';
import { Input, ListGroupItem, ListGroup, Nav, NavItem } from 'components/bootstrap';
import PaginatedList from 'components/common/PaginatedList';
import TextOverflowEllipsis from 'components/common/TextOverflowEllipsis';
import Spinner from 'components/common/Spinner';
import Icon from 'components/common/Icon';
import useIsKeyHeld from 'hooks/useIsKeyHeld';
import useUserSearchFiltersQuery from 'search-filter/hooks/useUserSearchFiltersQuery';
import generateId from 'logic/generateId';
import NoSearchResult from 'components/common/NoSearchResult';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';
import useSearchFiltersFormActions from 'search-filter/hooks/useSearchFiltersFormActions';
import { FILTER_SCOPES } from 'search-filter/constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

const DEFAULT_QUERY = {
  query: '',
  per_page: 10,
  page: 1,
  sort: 'title',
  order: 'asc',
  scope: 'ALL',
};

const StyledListGroup = styled(ListGroup)`
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
`;

const StyledListGroupItem = styled(ListGroupItem)`
  padding: 0;
`;

const Header = styled.div<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${$disabled ? theme.colors.variant.lightest.default : 'transparent'};
    padding: 0 10px;
    height: 34px;
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    user-select: none;
  `,
);

const FilterContent = styled.div`
  padding: 5px 10px 0 10px;
`;

const FilterAttribute = styled.div`
  word-break: break-all;
  margin-bottom: 5px;
`;

const Hint = styled.div(
  ({ theme }) => css`
    margin-top: 9px;
    font-size: ${theme.fonts.size.small};
  `,
);

const ToggleInfoIcon = styled(Icon)`
  cursor: pointer;
  padding: 3px;
`;

const StyledNav = styled(Nav)`
  margin-bottom: 6px;

  &.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
`;

const SearchInput = styled(Input)`
  margin-bottom: 6px;
`;

const LoadPopoverItem = ({
  searchFilter,
  onSelect,
  disabled,
}: {
  searchFilter: UserSearchFilter;
  disabled: boolean;
  onSelect: (filter: SelectedSearchFilter) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setOpen((cur) => !cur);
  };

  const handleClick = () => onSelect({ ...searchFilter, frontendId: generateId(), type: 'referenced' });

  return (
    <StyledListGroupItem>
      <Header onClick={disabled ? undefined : handleClick} $disabled={disabled}>
        <TextOverflowEllipsis>{searchFilter.title || searchFilter.queryString}</TextOverflowEllipsis>
        <ToggleInfoIcon
          name={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          data-testid={`open-filter-details-button-${searchFilter.id}`}
          onClick={toggleOpen}
        />
      </Header>
      {open && (
        <FilterContent>
          <FilterAttribute>
            <i>Query:</i> {searchFilter.queryString}
          </FilterAttribute>
          {searchFilter.title && (
            <FilterAttribute>
              <i>Title:</i> {searchFilter.title}
            </FilterAttribute>
          )}
          {searchFilter.description && (
            <FilterAttribute>
              <i>Description:</i> {searchFilter.description}
            </FilterAttribute>
          )}
        </FilterContent>
      )}
    </StyledListGroupItem>
  );
};

type Props = React.PropsWithChildren<{
  onClose: () => void;
  show: boolean;
}>;

const SearchFiltersOverviewPopover = ({ children = undefined, onClose, show }: Props) => {
  const isShiftHeld = useIsKeyHeld('Shift');
  const { submitForm } = useFormikContext();
  const [filterQuery, setSearchFilter] = useState(DEFAULT_QUERY);
  const {
    data: { filters, pagination },
    isFetching,
  } = useUserSearchFiltersQuery(filterQuery);
  const selectedFilters = useSearchFiltersFormState();
  const initiallySelectedFilters = useRef(selectedFilters);
  const { addFilter } = useSearchFiltersFormActions();
  const sendTelemetry = useSendTelemetry();
  const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLDivElement | null>(null);

  useClickOutside(onClose, null, [popoverRef, control]);

  const selectedReferencedFiltersIds = useMemo(
    () =>
      selectedFilters.toList().reduce((res, cur) => {
        if (cur.type === 'referenced') return res.add(cur.id);

        return res;
      }, new Set([])),
    [selectedFilters],
  );

  const handleSearchChange = useCallback(
    (newSearchQuery: string) => {
      setSearchFilter((cur) => ({ ...cur, page: DEFAULT_QUERY.page, query: newSearchQuery }));
    },
    [setSearchFilter],
  );

  const handlePaginationChange = useCallback(
    (page: number) => {
      setSearchFilter((cur) => ({ ...cur, page }));
    },
    [setSearchFilter],
  );

  const debounceOnSearch = debounce((value: string) => handleSearchChange(value), 1000);
  const onSelectItem = useCallback(
    (filter: SelectedSearchFilter) => {
      addFilter(filter, !isShiftHeld);

      if (!isShiftHeld) {
        onClose();
      }

      sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_LOADED, {
        app_pathname: 'search',
        app_section: 'search-filter',
      });
    },
    [addFilter, isShiftHeld, onClose, sendTelemetry],
  );

  const onOverlayClose = useCallback(() => {
    if (!isEqual(selectedFilters, initiallySelectedFilters.current) && show) {
      submitForm();
    }
  }, [selectedFilters, show, submitForm]);

  const onTabSelect = useCallback(
    (tab) => {
      setSearchFilter((cur) => ({ ...cur, page: 1, scope: tab }));
    },
    [setSearchFilter],
  );

  return (
    <Popover
      position="bottom-start"
      withArrow
      width={300}
      opened={show}
      onClose={onOverlayClose}
      closeOnClickOutside={false}>
      <Popover.Target>
        <div ref={setControl}>{children}</div>
      </Popover.Target>
      <Popover.Dropdown title="Search Filters">
        <div ref={setPopoverRef}>
          <SearchInput
            type="text"
            id="search-filters-input"
            formGroupClassName=""
            placeholder="Search for filters"
            onChange={({ target: { value } }) => debounceOnSearch(value)}
          />
          <StyledNav bsStyle="pills" justified activeKey={filterQuery.scope} onSelect={onTabSelect} bsSize="sm">
            <NavItem eventKey={FILTER_SCOPES.ALL}>All</NavItem>
            <NavItem eventKey={FILTER_SCOPES.MY_FILTERS}>My Filters</NavItem>
          </StyledNav>
          {!filters?.length && isFetching && <Spinner />}
          {!!filters?.length && (
            <PaginatedList
              showPageSizeSelect={false}
              totalItems={pagination.total}
              hidePreviousAndNextPageLinks
              hideFirstAndLastPageLinks
              activePage={filterQuery.page}
              pageSize={filterQuery.per_page}
              onChange={handlePaginationChange}
              useQueryParameter={false}>
              <StyledListGroup>
                {filters.map((filter) => (
                  <LoadPopoverItem
                    disabled={selectedReferencedFiltersIds.has(filter.id)}
                    searchFilter={filter}
                    onSelect={onSelectItem}
                    key={filter.id}
                  />
                ))}
              </StyledListGroup>
            </PaginatedList>
          )}
          {!isFetching && !filters?.length && <NoSearchResult>No filters have been found.</NoSearchResult>}
          <Hint>
            <i>Hold Shift to select multiple</i>
          </Hint>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default SearchFiltersOverviewPopover;
