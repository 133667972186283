import * as React from 'react';

import { Alert } from 'components/bootstrap';

const DataLakeBackendWarning = () => (
  <Alert bsStyle="warning">
    Note that for the active backend to be changed, all data must first be deleted from the current backend.
  </Alert>
);

export default DataLakeBackendWarning;
