import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import styled, { css } from 'styled-components';

import NumberUtils from 'util/NumberUtils';
import type { Backend } from 'archive/types';
import type { BackendTypeValue } from 'archive/logic/BackendTypes';
import BackendTypes from 'archive/logic/BackendTypes';
import { HelpBlock, Input } from 'components/bootstrap';
import { FormikInput, FormSubmit, Section, Select } from 'components/common';
import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import useHistory from 'routing/useHistory';
import type { DataLakeConfig } from 'data-lake/configurations/hooks/useDataLakeConfig';
import useDataLakeConfigMutation from 'data-lake/configurations/hooks/useDataLakeConfigMutation';
import DataLakeBackendWarning from 'data-lake/data-lake-backend-config/DataLakeBackendWarning';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import DataLakeRoutes from 'data-lake/Routes';
import { formatDuration, formatRetentionLabel, validateRetention } from 'data-lake/logic/Utils';
import AppConfig from 'util/AppConfig';

type Props = {
  config: DataLakeConfig;
  backends: Array<Backend>;
};

const isCloud = AppConfig.isCloud();
const defaultValues = {
  active_backend: null,
  iceberg_commit_interval: 'PT1H',
  iceberg_target_file_size: 536870912,
  parquet_row_group_size: 134217728,
  parquet_page_size: 8192,
  journal_reader_batch_size: 500,
  optimize_job_enabled: true,
  optimize_job_interval: 'PT1H',
  parallel_retrieval_enabled: true,
  retrieval_convert_threads: -1,
  retrieval_convert_batch_size: 1,
  retrieval_inflight_requests: 3,
  retrieval_bulk_batch_size: 2500,
  retention_time: 'PT1D',
};
const StyledFormSubmit = styled(FormSubmit)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacings.md};
  `,
);
const formatBytes = (value: number) => NumberUtils.formatBytes(value);

const isValidDuration = (value: string) => moment.duration(value).asMilliseconds() >= 36000;

const validateDuration = (value: string) => !isValidDuration(value) && 'Invalid duration.';

const formatBackendValidationErrors = (backendErrors: { [fieldName: string]: string[] }) => {
  const backendErrorStrings = mapValues(backendErrors, (errorArray: Array<string>) => `${errorArray.join(' ')}`);

  return { ...backendErrorStrings };
};

const getBackendOptions = (backends: Array<Backend>) =>
  backends
    .map((backend) => ({
      value: backend.id,
      label: `${backend.title} (${BackendTypes.getBackendType(backend.settings.type as BackendTypeValue).label})`,
    }))
    .sort((a, b) => naturalSort(a.label.toLowerCase(), b.label.toLowerCase()));

const DataLakeConfigForm = ({ config, backends }: Props) => {
  const { onUpdateDataLakeConfig } = useDataLakeConfigMutation();
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();
  const sortedBackends = getBackendOptions(backends);
  const redirectToDataLake = () => history.push(DataLakeRoutes.ARCHIVE.LIST);
  const handleSubmit = (values: DataLakeConfig, { setErrors }) =>
    onUpdateDataLakeConfig({
      config: {
        ...values,
        retention_time: values.retention_time === '0' ? null : values.retention_time,
      },
    })
      .then(() => {
        redirectToDataLake();
      })
      .catch((error) => {
        if (typeof error?.additional?.body?.errors === 'object') {
          setErrors(formatBackendValidationErrors(error.additional.body.errors));
        }
      })
      .finally(() => {
        sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.CONFIGURATION_SAVED, {
          app_pathname: 'data-lake',
        });
      });

  return (
    <Formik initialValues={config || defaultValues} onSubmit={handleSubmit}>
      {({ values, isSubmitting, setFieldValue, initialValues, isValid }) => (
        <Form>
          <fieldset disabled={isCloud}>
            <Section title="Configuration information">
              <Field name="active_backend">
                {({ field: { name, value }, form: { touched, setFieldTouched } }) => (
                  <Input
                    help="Select the active backend. Note that changing active backend will entail deleting any data stored in the current backend."
                    id="active-backend"
                    label="Active Backend">
                    <Select
                      inputId="active-backend"
                      name="active_backend"
                      placeholder="Select Active Backend"
                      aria-label="Select Active Backend"
                      options={sortedBackends}
                      matchProp="label"
                      required
                      value={value}
                      disabled={isCloud}
                      onChange={(selected) => {
                        setFieldTouched(name, true);
                        setFieldValue(name, selected);
                      }}
                    />
                    <HelpBlock />
                    {touched[name] && value !== initialValues[name] && <DataLakeBackendWarning />}
                  </Input>
                )}
              </Field>
              <FormikInput
                type="text"
                name="iceberg_commit_interval"
                id="iceberg-commit-interval"
                label="Iceberg Commit Interval"
                help={
                  <>
                    <em>Advanced Option.</em> Select the Iceberg Commit Interval. This dictates the interval of the
                    commit task which makes newly ingested data available for retrieval. Smaller values minimize Graylog
                    memory and storage use for buffering. Larger values are more performant for retrieval.
                  </>
                }
                addonAfter={formatDuration(values.iceberg_commit_interval)}
                validate={validateDuration}
                required
              />
              <FormikInput
                type="number"
                name="iceberg_target_file_size"
                id="iceberg-target-file-size"
                label="Iceberg Target File Size"
                help={
                  <>
                    <em>Advanced Option.</em> Size of the Iceberg target file size. This dictates the maximum file size
                    of a segment before merging. Smaller segments minimize Graylog memory and storage use for buffering.
                    Larger values are more performant for retrieval.
                  </>
                }
                addonAfter={formatBytes(values.iceberg_target_file_size)}
                required
              />
              <FormikInput
                type="number"
                name="parquet_row_group_size"
                id="parquet-row-group-size"
                label="Iceberg Parquet Row Group Size"
                help={
                  <>
                    <em>Advanced Option.</em> Size of Parquet Row Groups. This dictates the maximum group size within a
                    segment, which influences read speed. The value set should be a factor of the Iceberg Target File
                    Size. Typically updated only when altering the Iceberg Target File Size.
                  </>
                }
                addonAfter={formatBytes(values.parquet_row_group_size)}
                required
              />
              <FormikInput
                type="number"
                name="parquet_page_size"
                id="parquet-page-size"
                label="Parquet Page Size"
                help={
                  <>
                    <em>Advanced Option.</em> Size of Parquet Page Size*. This dictates the maximum page size within a
                    group, which influences read speed. The value set should be a factor of the Iceberg Target File
                    Size. Typically updated only when altering the Iceberg Target File Size.
                  </>
                }
                addonAfter={formatBytes(values.parquet_page_size)}
                required
              />
              <FormikInput
                type="number"
                name="journal_reader_batch_size"
                id="journal-reader-batch-size"
                label="Journal Reader Batch Size"
                help={
                  <>
                    <em>Advanced Option.</em> Size of Journal Reader Batch *. Higher values will result in a higher
                    throughout of messages written from the Data Lake Journal to the Data Lake, but also greater java
                    heap memory usage.
                  </>
                }
                required
              />
            </Section>
            <Section title="Optimization settings">
              <FormikInput
                type="checkbox"
                name="optimize_job_enabled"
                id="optimize-job-enabled"
                label="Enable automatic optimization?"
                help="Whether to automatically optimize the stored data."
              />
              <FormikInput
                type="text"
                name="optimize_job_interval"
                id="optimize-job-interval"
                label="Optimize Interval"
                disabled={!values.optimize_job_enabled}
                help='Interval of the optimization job.  (i.e. "P1D" for 1 day, "PT6H" for 6 hours).'
                addonAfter={formatDuration(values.optimize_job_interval)}
                validate={validateDuration}
                required
              />
            </Section>
            <Section title="Retrieval settings">
              <FormikInput
                type="checkbox"
                name="parallel_retrieval_enabled"
                id="parallel-retrieval-enabled"
                label="Enable parallel retrieval?"
                help="Parallel retrieval performs concurrent indexing requests, which can speed up retrieval but uses more resources. The remainder of the values in this section only apply when parallel retrieval is enabled."
              />
              <FormikInput
                type="number"
                name="retrieval_inflight_requests"
                id="retrieval-inflight-requests"
                label="Maximum number of concurrent indexing requests"
                disabled={!values.parallel_retrieval_enabled}
                help='Graylog will aim to have this many concurrent batches sent for indexing at the same. This number should take into account the number of primary shards the "Restored Archives" index set has configured.'
              />
              <FormikInput
                type="number"
                name="retrieval_bulk_batch_size"
                id="retrieval-bulk-batch-size"
                label="Number of messages per indexing request"
                disabled={!values.parallel_retrieval_enabled}
                help="In conjunction with concurrent indexing requests, this setting controls how many messages are indexed at the same time and can be used to influence retrieval performance."
              />
              <FormikInput
                type="number"
                name="retrieval_convert_threads"
                id="retrieval-convert-threads"
                label="Number of threads used to convert messages"
                disabled={!values.parallel_retrieval_enabled}
                help="Defaults to number of available CPUs (value: -1). Typically you do not need to change this setting."
              />
              <FormikInput
                type="number"
                name="retrieval_convert_batch_size"
                id="retrieval-convert-batch-size"
                label="Conversion batch size"
                disabled={!values.parallel_retrieval_enabled}
                help="Size of the per-thread conversion batch, defaults to 1. Typically you do not need to change this setting."
              />
            </Section>
            <Section title="Retention setting">
              <FormikInput
                type="text"
                name="retention_time"
                id="retention-time"
                placeholder="P1D"
                label="Maximum number of days in the Data Lake"
                help="Define how many days to keep archives before deleting them. When 0 or P0D is set, automatic deletion is disabled."
                addonAfter={formatRetentionLabel(values.retention_time)}
                validate={validateRetention}
              />
            </Section>
          </fieldset>
          {!isCloud && (
            <StyledFormSubmit
              submitButtonText="Update configuration"
              submitLoadingText="Updating..."
              isSubmitting={isSubmitting}
              disabledSubmit={!isValid}
              isAsyncSubmit
              onCancel={redirectToDataLake}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DataLakeConfigForm;
