import * as React from 'react';
import type { CustomColors } from '@graylog/sawmill';

import ThemeEditorRow from './ThemeEditorRow';

interface OnChangeParams {
  mode: string;
  key: string;
  type: string;
  hex: string;
}

type Props = {
  currentThemeColors: CustomColors;
  onChange: ({ mode, key, type, hex }: OnChangeParams) => void;
  section: string;
};

const themeMap = {
  general: [
    {
      key: 'primary',
      name: 'Brand',
      type: 'brand',
    },
    {
      key: 'background',
      name: 'Body Background',
      type: 'global',
    },
    {
      key: 'contentBackground',
      name: 'Content Background',
      type: 'global',
    },
    {
      key: 'link',
      name: 'Links',
      type: 'global',
    },
    {
      key: 'tertiary',
      name: 'Text',
      type: 'brand',
    },
    {
      key: 'secondary',
      name: 'Text Inverse',
      type: 'brand',
    },
  ],
  variant: [
    {
      key: 'default',
      name: 'Default',
      type: 'variant',
    },
    {
      key: 'danger',
      name: 'Error',
      type: 'variant',
    },
    {
      key: 'info',
      name: 'Informative',
      type: 'variant',
    },
    {
      key: 'primary',
      name: 'Primary',
      type: 'variant',
    },
    {
      key: 'success',
      name: 'Success',
      type: 'variant',
    },
    {
      key: 'warning',
      name: 'Warning',
      type: 'variant',
    },
    {
      key: 'gray',
      name: 'Gray',
      type: 'variant',
    },
  ],
};

const ThemeSection = ({ currentThemeColors, onChange, section }: Props) =>
  themeMap[section].map(({ key, name, type }) => (
    <ThemeEditorRow
      colorName={name}
      key={name}
      currentThemeColors={currentThemeColors}
      onChange={onChange}
      colorKey={key}
      colorType={type}
    />
  ));

export default ThemeSection;
