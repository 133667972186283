import * as React from 'react';

import HelpBlock from 'components/bootstrap/HelpBlock';
import MarkdownEditorModal from 'components/common/MarkdownEditor/EditorModal';

type Props = {
  closeModal: () => void;
  onConfirm: (notes: string) => void;
  show?: boolean;
};

const helpBlock = (
  <HelpBlock>
    You can add additional notes here, which will be appended to the notes of all events you have selected.
  </HelpBlock>
);

const AddNotesModal = ({ closeModal, onConfirm, show = false }: Props) => {
  const [notes, setNotes] = React.useState<string>('');

  return show ? (
    <MarkdownEditorModal
      value={notes}
      show={show}
      onChange={setNotes}
      onClose={closeModal}
      onDone={onConfirm}
      helpBlock={helpBlock}
    />
  ) : null;
};

export default AddNotesModal;
