import React from 'react';

import CommonNotificationSummary from 'components/event-notifications/event-notification-types/CommonNotificationSummary';

type ScriptNotificationSummaryProps = {
  type: string;
  notification: {
    config: {
      script_path?: string;
      script_args?: string;
      script_send_stdin?: boolean;
      script_timeout?: number;
    };
  };
  definitionNotification: any;
};

function ScriptNotificationSummary({ notification, ...restProps }: ScriptNotificationSummaryProps) {
  return (
    <CommonNotificationSummary {...restProps} notification={notification}>
      <tr>
        <td>Script Path</td>
        <td>
          <code>{notification?.config?.script_path}</code>
        </td>
      </tr>
      <tr>
        <td>Script Arguments</td>
        <td>
          <code>{notification?.config?.script_args}</code>
        </td>
      </tr>
      <tr>
        <td>Send Alert Data Through STDIN</td>
        <td>
          <code>{notification?.config?.script_send_stdin ? 'Yes' : 'No'}</code>
        </td>
      </tr>
      <tr>
        <td>Script Timeout</td>
        <td>
          <code>{notification?.config?.script_timeout} ms</code>
        </td>
      </tr>
    </CommonNotificationSummary>
  );
}

export default ScriptNotificationSummary;
