import * as React from 'react';
import { useMemo } from 'react';

import PageNavigation from 'components/common/PageNavigation';
import { styleSuffix, contentSuffix, deliverySuffix } from 'report/ReportRoutes';

const pageNavigation = (prefix: string) => [
  { title: 'Overview', path: prefix, exactPathMatch: true },
  { title: 'Content', path: `${prefix}${contentSuffix}` },
  { title: 'Delivery', path: `${prefix}${deliverySuffix}` },
  { title: 'Style', path: `${prefix}${styleSuffix}` },
];
type Props = {
  prefix: string;
};

const ReportCreationNavigation = ({ prefix }: Props) => {
  const paths = useMemo(() => pageNavigation(prefix), [prefix]);

  return <PageNavigation items={paths} />;
};

export default ReportCreationNavigation;
