import * as React from 'react';
import { useCallback, useEffect } from 'react';

import Input from 'components/bootstrap/Input';
import Select from 'components/common/Select';
import { StaticSource } from 'enterprise/parameters/components/option/OptionSource';
import type { OptionSourceEditComponentProps } from 'enterprise/parameters/components/option/types';
import type { ValidationResult } from 'enterprise/parameters/components/ParameterDeclarationForm';

const DELIMITER = ',';

const parseOptions = (input: string) =>
  input
    .split(DELIMITER)
    .map((s) => s.trim())
    .filter((s) => s !== '');

const StaticSourceForm = ({
  onChange,
  idx,
  source,
  onValidate,
  validationState,
}: OptionSourceEditComponentProps<StaticSource>) => {
  const _onChange = useCallback((newValues) => onChange(StaticSource.create(parseOptions(newValues))), [onChange]);

  const { values = [] } = source ?? {};
  const options = values ? values.map((value) => ({ label: value, value })) : [];

  useEffect(() => {
    const _validationState: ValidationResult =
      values !== undefined && values.length > 0 ? ['success'] : ['error', 'Please enter at least one option'];
    onValidate(idx, { 'source.values': _validationState });
  }, [values, idx, onValidate]);

  useEffect(
    () => () => {
      onValidate(idx, { 'source.values': ['success'] });
    },
    [idx, onValidate],
  );

  return (
    <Input
      id={`values-${idx}`}
      name="values"
      label="Values"
      bsStyle={validationState?.['source.values']?.[0]}
      help={validationState?.['source.values']?.[1]}>
      <Select
        placeholder="Please add options"
        inputId="source-values-input"
        className="source-values-input"
        onChange={_onChange}
        allowCreate
        delimiter={DELIMITER}
        multi
        value={values.join(DELIMITER)}
        options={options}
      />
    </Input>
  );
};

export default StaticSourceForm;
