import * as React from 'react';

import { Link } from 'components/common/router';
import Routes from 'routing/Routes';

import type Team from '../../logic/Team';

const _getSourceType = (type = '', authServiceId) => {
  if (type === 'SYNCED' && authServiceId) {
    return <Link to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.show(authServiceId)}>{type.toLowerCase()}</Link>;
  }

  return type.toLowerCase();
};

type Props = {
  team: Team;
};

const SourceTypeCell = ({ team: { type, authServiceId } }: Props) => {
  const sourceType = _getSourceType(type, authServiceId);

  return <td>{sourceType}</td>;
};

export default SourceTypeCell;
