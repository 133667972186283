import { useQuery } from '@tanstack/react-query';

import type { ReportSummary } from 'report/types';
import fetch from 'logic/rest/FetchProvider';
import { reportingUrl } from 'report/Constants';
import { defaultOnError } from 'util/conditional/onError';

const fetchReportSummary = (reportId: string): Promise<ReportSummary> =>
  fetch('GET', reportingUrl(`${reportId}/summary`));

const useReportSummary = (
  reportId: string,
  refetchPeriodically = false,
): {
  data: ReportSummary;
  isInitialLoading: boolean;
} => {
  const { data, isInitialLoading } = useQuery(
    ['reports', 'summary', reportId],
    () =>
      defaultOnError(fetchReportSummary(reportId), 'Fetching report failed with status', 'Could not retrieve report'),
    {
      keepPreviousData: true,
      refetchInterval: refetchPeriodically ? 2000 : undefined,
    },
  );

  return {
    data,
    isInitialLoading,
  };
};

export default useReportSummary;
