import * as React from 'react';

import { Button } from 'components/bootstrap';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';

function ClearSelection() {
  const { selectedEntities, setSelectedEntities } = useSelectedEntities();
  const cancelEntitySelection = React.useCallback(() => setSelectedEntities([]), [setSelectedEntities]);

  return selectedEntities.length > 0 ? <Button bsSize="sm" onClick={cancelEntitySelection}>Clear All</Button>: null;
}

export default ClearSelection;
