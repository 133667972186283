import * as React from 'react';
import { useMemo } from 'react';
import { PluginStore } from 'graylog-web-plugin/plugin';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/toLower';

import type { SearchFilter } from 'views/types';
import {} from 'moment-duration-format';
import { useStore } from 'stores/connect';
import Timestamp from 'components/common/Timestamp';
import usePluginEntities from 'hooks/usePluginEntities';
import { DEFAULT_TIMERANGE } from 'views/Constants';
import type { Stream } from 'views/stores/StreamsStore';
import { StreamsStore } from 'views/stores/StreamsStore';
import type { RelativeTimeRange, RelativeTimeRangeStartOnly, TimeRange } from 'views/logic/queries/Query';
import type Widget from 'views/logic/widgets/Widget';
import { MESSAGE_LIST_WIDGET_LIMIT } from 'common/components/widgetRenderers/Constants';
import type { QueryString } from 'views/logic/queries/types';

export const FallbackWidgetDescription = ({ error }: { error: Error }) => (
  <span>
    Error generating widget description:
    <br />
    <code>{error.toString()}</code>
  </span>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertUnreachable = (_ignored: never): never => {
  throw new Error("Didn't expect to get here");
};

const isRelativeTimeRangeStartOnly = (timerange: RelativeTimeRange): timerange is RelativeTimeRangeStartOnly =>
  'range' in timerange;

const humanizeDuration = (seconds: number) =>
  moment
    .duration(seconds, 'seconds')
    .format('d [days] h [hours] m [minutes] s [seconds]', { trim: 'all', useToLocaleString: false });

const formatRelativeRange = (relativeRange: RelativeTimeRange) => {
  const start = humanizeDuration(
    isRelativeTimeRangeStartOnly(relativeRange) ? relativeRange.range : relativeRange.from,
  );
  const end =
    isRelativeTimeRangeStartOnly(relativeRange) || !relativeRange.to
      ? 'now'
      : `${humanizeDuration(relativeRange.to)} ago`;

  return (
    <span>
      from {start} ago until {end}
    </span>
  );
};

const TimeRangeDescription = ({ timeRange: timeRangeProp }: { timeRange: TimeRange | null | undefined }) => {
  const timeRange = timeRangeProp ?? DEFAULT_TIMERANGE;

  switch (timeRange.type) {
    case 'relative':
      // eslint-disable-next-line no-case-declarations
      return formatRelativeRange(timeRange);
    case 'absolute':
      return (
        <span>
          from <Timestamp dateTime={timeRange.from} /> to <Timestamp dateTime={timeRange.to} />
        </span>
      );
    case 'keyword':
      return <span>in the {toLower(timeRange.keyword)}</span>;
    default:
      assertUnreachable(timeRange);
  }

  return assertUnreachable(timeRange);
};

const StreamsDescription = ({ streams }: { streams: Array<Stream> }) => {
  if (!streams || streams.length === 0) {
    return <>all messages</>;
  }

  const usedStreams = streams
    .map((s) => <em>{s.title}</em>)
    .reduce((acc: React.ReactElement, current: React.ReactElement, idx: number) => {
      if (streams.length === 1) {
        return current;
      }

      if (idx === streams.length - 1) {
        return (
          <>
            {acc}and {current}
          </>
        );
      }

      return (
        <>
          {acc}
          {current},{' '}
        </>
      );
    });

  return (
    <>
      messages in {streams.length === 1 ? 'stream' : 'streams'} {usedStreams}
    </>
  );
};

const CalculatedAt = ({ date }: { date: string | Date }) => <Timestamp dateTime={date} />;

const _visualizationDescription = (config) => {
  const visualization = PluginStore.exports('visualizationTypes').filter((viz) => viz.type === config.visualization)[0];

  if (!visualization) {
    throw new Error(`Unable to find visualization component for type: ${config.visualization}`);
  }

  return visualization.displayName;
};

type WidgetType = {
  type: string;
  titleGenerator?: (widget: { config: Widget['config'] }) => string;
};

type WidgetTypeDescriptionProps = {
  widgetPlugin: WidgetType;
  config: Widget['config'];
};

const WidgetTypeDescription = ({ widgetPlugin, config }: WidgetTypeDescriptionProps) => {
  switch (widgetPlugin.type) {
    case 'AGGREGATION':
      return (
        <>
          {capitalize(_visualizationDescription(config))} {toLower(widgetPlugin.titleGenerator({ config }))}
        </>
      );
    case 'MESSAGES':
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{`Message list (limited to ${MESSAGE_LIST_WIDGET_LIMIT} messages)`}</>;
    default:
      return <>Unknown widget</>;
  }
};

const QueryDescription = ({ query }: { query: QueryString | undefined }) => {
  const { query_string: queryString = '*' } = query ?? {};

  return <em>{queryString}</em>;
};

const FiltersDescription = ({ filters }: { filters: Array<SearchFilter> }) => (
  <>
    {filters.map(({ queryString, negation }, index) => {
      const prefix = `${index > 0 ? ' AND ' : ''}`;
      const query = `${negation ? 'NOT ' : ''}(${queryString})`;

      return `${prefix}${query}`;
    })}
  </>
);

type Props = {
  calculatedAt?: string | Date;
  widgetConfig: Widget['config'];
  widgetQuery: Widget['query'];
  widgetStreams: Widget['streams'];
  widgetTimerange: Widget['timerange'];
  widgetType: Widget['type'];
  widgetFilters: Array<SearchFilter>;
  hideQuery: boolean;
};

const WidgetDescription = ({
  calculatedAt = new Date(),
  widgetConfig,
  widgetQuery,
  widgetFilters,
  widgetStreams: widgetStreamsProp,
  widgetTimerange,
  widgetType,
  hideQuery,
}: Props) => {
  const widgetStreams = useStore(StreamsStore, ({ streams }) =>
    streams.filter((stream) => widgetStreamsProp?.includes(stream.id)),
  );
  const widgetTypes = usePluginEntities('enterpriseWidgets');
  const widgetPlugin = useMemo(
    () => widgetTypes.find((w) => w.type.toUpperCase() === widgetType.toUpperCase()),
    [widgetType, widgetTypes],
  );

  if (!widgetPlugin) {
    return <>No description available.</>;
  }

  return (
    <>
      <WidgetTypeDescription widgetPlugin={widgetPlugin} config={widgetConfig} /> for{' '}
      <StreamsDescription streams={widgetStreams} /> <TimeRangeDescription timeRange={widgetTimerange} />.
      {!hideQuery && (
        <>
          {' '}
          The visualization represents data for query <QueryDescription query={widgetQuery} />
          {widgetFilters && widgetFilters.length > 0 && (
            <>
              {' '}
              and filter queries{' '}
              <em>
                <FiltersDescription filters={widgetFilters} />
              </em>
            </>
          )}
          .
        </>
      )}{' '}
      Calculated at <CalculatedAt date={calculatedAt} />.
    </>
  );
};

export default WidgetDescription;
