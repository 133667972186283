import * as React from 'react';
import * as Immutable from 'immutable';
import { useState, useEffect, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import prepareOktaLoadGroupsPayload from 'authentication/logic/okta/prepareOktaLoadGroupsPayload';
import type { ConfigFormValues, OidcSubmitPayload } from 'authentication/components/oidc/config/types';
import { OidcBackendMetaContext } from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';

import OktaMatchingGroupsContext from './OktaMatchingGroupsContext';

type Props = {
  children: React.ReactNode;
  prepareSubmitPayload: (values: ConfigFormValues) => OidcSubmitPayload;
};

const OktaMatchingGroupsProvider = ({ children, prepareSubmitPayload }: Props) => {
  const { backendId, backendGroupSyncIsActive } = useContext(OidcBackendMetaContext);
  const { values: currentFormValues } = useFormikContext<ConfigFormValues>();
  const [contextValue, setContextValue] = useState({ result: undefined, finishedLoading: true });

  const oktaMatchingGroupsContextValue = useMemo(() => ({ ...contextValue, setContextValue }), [contextValue]);

  useEffect(() => {
    // When editing a backend with active group sync, load matching groups
    if (backendId && backendGroupSyncIsActive) {
      setContextValue({ finishedLoading: false, result: undefined });
      const payload = prepareOktaLoadGroupsPayload(prepareSubmitPayload, currentFormValues, backendId, OKTA_TYPE_KEY);

      GroupSyncActions.loadGroups(payload)
        .then((result) => {
          setContextValue({ finishedLoading: true, result });
        })
        .catch((error) => {
          setContextValue({ finishedLoading: true, result: { errors: Immutable.List([error]) } });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OktaMatchingGroupsContext.Provider value={oktaMatchingGroupsContextValue}>
      {children}
    </OktaMatchingGroupsContext.Provider>
  );
};

export { OktaMatchingGroupsContext };

export default OktaMatchingGroupsProvider;
