import * as React from 'react';

const help = {
  groupSearchBase: (
    <span>
      The base tree to limit the LDAP group search query to, e.g.{' '}
      <code key="group-search-base-example">ou=people,dc=example,dc=com</code>.
    </span>
  ),
  groupSearchPattern: (
    <span>
      The search pattern used to find groups in LDAP for mapping to Graylog teams, e.g{' '}
      <code className="text-nowrap" key="group-search-pattern-example-1">
        (objectClass=group)
      </code>
    </span>
  ),
  teamUniqueIdAttribute: (
    <span>
      Which LDAP attribute to use for the uniq ID attribute of the Graylog team, e.g.{' '}
      <code key="team-unique-id-attribute">entryUUID</code>.
    </span>
  ),
  teamNameAttribute: (
    <span>
      Which LDAP attribute to use for the full name of the Graylog team, usually{' '}
      <code key="team-name-attribute-example">cn</code>.
    </span>
  ),
  teamDefaultRoles:
    'The default Graylog roles synchronized teams will obtain. All users of a team will inherit these roles.',
};

const initialValues = {
  teamUniqueIdAttribute: 'entryUUID',
  teamNameAttribute: 'cn',
};

export default { help, initialValues };
