import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import { defaultOnError } from 'util/conditional/onError';

import type { FileSystemRepository, S3Repository, Repository } from '../types';

const urlPrefix = '/plugins/org.graylog.plugins.datatiering/datatiering/repositories';

const DATA_TIERING_REPOSITORIES_KEY = 'repositories';
const DATA_TIERING_FS_PATHS_KEY = 'fs_paths';

const createRepository = (repository: FileSystemRepository | S3Repository) =>
  fetch('POST', qualifyUrl(`${urlPrefix}`), repository);

const updateRepository = (repository: FileSystemRepository | S3Repository) =>
  fetch('PUT', qualifyUrl(`${urlPrefix}/${repository.name}`), repository);

const deleteRepository = (name: string) => fetch('DELETE', qualifyUrl(`${urlPrefix}/${name}`));

const listRepositories = () => fetch('GET', qualifyUrl(`${urlPrefix}`));

const listFsPaths = () => fetch('GET', qualifyUrl(`${urlPrefix}/fs-paths`));

const useRepositories = () => {
  const queryClient = useQueryClient();

  const { data: dataRepositories, isLoading: isLoadingRepositories } = useQuery<Repository[], Error>(
    [DATA_TIERING_REPOSITORIES_KEY],
    () => defaultOnError(listRepositories(), 'Loading repositories failed with status', 'Could not load repositories.'),
  );

  const { data: dataFsPaths, isLoading: isLoadingFsPaths } = useQuery<string[], Error>(
    [DATA_TIERING_FS_PATHS_KEY],
    () =>
      defaultOnError(
        listFsPaths(),
        'Loading file system paths failed with status',
        'Could not load file system paths.',
      ),
  );

  const { mutateAsync: onCreateRepository } = useMutation(createRepository, {
    onSuccess: () => {
      UserNotification.success('Repository created successfully.');
      queryClient.invalidateQueries([DATA_TIERING_REPOSITORIES_KEY]);
    },
    onError: (error) => {
      UserNotification.error(`Repository creation failed with error: ${error}.`);
      queryClient.invalidateQueries([DATA_TIERING_REPOSITORIES_KEY]);
    },
  });

  const { mutateAsync: onUpdateRepository } = useMutation(updateRepository, {
    onSuccess: () => {
      UserNotification.success('Repository updated successfully.');
      queryClient.invalidateQueries([DATA_TIERING_REPOSITORIES_KEY]);
    },
    onError: (error) => {
      UserNotification.error(`Updating repository failed with error: ${error}.`);
      queryClient.invalidateQueries([DATA_TIERING_REPOSITORIES_KEY]);
    },
  });

  const { mutateAsync: onDeleteRepository } = useMutation(deleteRepository, {
    onSuccess: () => {
      UserNotification.success('Repository deleted successfully.');
      queryClient.invalidateQueries([DATA_TIERING_REPOSITORIES_KEY]);
    },
    onError: (error) => {
      UserNotification.error(`Repository deletion failed with error: ${error}.`);
    },
  });

  return {
    fsPaths: {
      data: dataFsPaths,
      isLoading: isLoadingFsPaths,
    },
    repositories: {
      data: dataRepositories,
      isLoading: isLoadingRepositories,
    },
    onCreateRepository,
    onDeleteRepository,
    onUpdateRepository,
  };
};

export default useRepositories;
