import * as React from 'react';
import { useEffect, useState } from 'react';

import withParams from 'routing/withParams';
import { PageHeader, DocumentTitle } from 'components/common';
import UsersPageNavigation from 'components/users/navigation/UsersPageNavigation';
import DocsHelper from 'util/DocsHelper';

import type Team from '../logic/Team';
import TeamDetails from '../components/team-details/TeamDetails';
import TeamsDomain from '../domainActions/TeamsDomain';
import TeamActionLinks from '../components/navigation/TeamActionLinks';

type Props = {
  params: {
    teamId: string;
  };
};

const PageTitle = ({ name }: { name: string | null | undefined }) => (
  <>
    Team Details{' '}
    {name && (
      <>
        - <i>{name}</i>
      </>
    )}
  </>
);

const TeamDetailsPage = ({ params }: Props) => {
  const teamId = params?.teamId;
  const [team, setTeam] = useState<Team | undefined>();
  const name = team?.name;

  useEffect(() => {
    TeamsDomain.load(teamId).then(setTeam);
  }, [teamId]);

  return (
    <DocumentTitle title={`Team Details ${name ?? ''}`}>
      <UsersPageNavigation />
      <PageHeader
        title={<PageTitle name={name} />}
        actions={<TeamActionLinks team={team} />}
        documentationLink={{
          title: 'Permissions documentation',
          path: DocsHelper.PAGES.USERS_ROLES,
        }}>
        <span>
          Overview of details like settings, assigned users and roles. This page also provides an overview of all
          entities which are being shared with this team.
        </span>
      </PageHeader>

      <TeamDetails team={team} />
    </DocumentTitle>
  );
};

export default withParams(TeamDetailsPage);
