import type Widget from 'views/logic/widgets/Widget';
import type { WidgetActionType } from 'views/components/widgets/Types';
import ExportOverviewWidgetsActionComponent from 'search/ExportWidgetAction/ExportOverviewWidgetsActionComponent';
import InvestigationsWidget from 'security-app/components/Investigations/plugin/SearchWidgets/logic/InvestigationsWidget';

const ExportInvestigationsOverviewWidgetAction: WidgetActionType = {
  type: 'export-investigation-overview-widget-action',
  position: 'menu',
  isHidden: (w: Widget) => w.type !== InvestigationsWidget.type,
  component: ExportOverviewWidgetsActionComponent,
};

export default ExportInvestigationsOverviewWidgetAction;
