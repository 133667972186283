import * as React from 'react';
import { useCallback } from 'react';

import { Input } from 'components/bootstrap';
import type { ValidationStateEntry } from 'enterprise/parameters/components/ParameterDeclarationForm';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import LookupTableParameter from 'views/logic/parameters/LookupTableParameter';
import type Parameter from 'views/logic/parameters/Parameter';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';

import { useParameterTypes } from './ParameterTypes';

type Props = {
  idx: number;
  validationState: ValidationStateEntry;
  value: string;
  onChange: (updater: (parameter: Parameter) => Parameter) => void;
};

interface ParameterBuilder {
  title(value: string): ParameterBuilder;
  name(value: string): ParameterBuilder;
  description(value: string): ParameterBuilder;
  build(): Parameter;
}

const builderForType = (newType: string): ParameterBuilder => {
  switch (newType) {
    case OptionParameter.type:
      return OptionParameter.builder();
    case ValueParameter.type:
      return ValueParameter.builder();
    case LookupTableParameter.type:
      return LookupTableParameter.builder();
    default:
      throw new Error(`Invalid parameter type: ${newType}`);
  }
};

const migrateParameterToNewType = (newType: string) => (parameter: Parameter) => {
  const { title, description, name } = parameter;

  const newBuilder = builderForType(newType);

  return newBuilder.name(name).title(title).description(description).build();
};

const ParameterDataTypesSelect = ({ idx, validationState, value, onChange }: Props) => {
  const parameterTypes = useParameterTypes();
  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newType = event.target.value;

      onChange(migrateParameterToNewType(newType));
    },
    [onChange],
  );

  return (
    <Input
      id={`dataType-${idx}`}
      type="select"
      name="type"
      label="Type"
      value={value}
      bsStyle={validationState?.type?.[0]}
      help={validationState?.type?.[1]}
      onChange={_onChange}>
      {parameterTypes.map(({ type, title }) => (
        <option key={type} value={type}>
          {title}
        </option>
      ))}
    </Input>
  );
};

export default ParameterDataTypesSelect;
