import React from 'react';

import { FormikFormGroup } from 'components/common';

const ArchiveBackendNameDescriptionFormGroup = () => (
  <>
    <FormikFormGroup
      type="text"
      name="title"
      label="Title"
      required
      help="Descriptive name of the backend."
      labelClassName=""
      wrapperClassName=""
    />
    <FormikFormGroup
      type="text"
      name="description"
      label="Description"
      required
      help="Backend description."
      labelClassName=""
      wrapperClassName=""
    />
  </>
);

export default ArchiveBackendNameDescriptionFormGroup;
