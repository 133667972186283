import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import SigmaModal from 'security-app/components/SigmaRules/ImportRulesModal/SigmaModal';
import { useGetSigmaRule, sigmaRuleKey } from 'security-app/hooks/useSigmaAPI';
import { useModalContext } from 'common/contexts';
import { ConfirmDialog } from 'security-app/components/common/Modal';
import { eventDefinitionsKey } from 'security-app/hooks/useSecurityEventsAPI';

function EditSigmaEventDefinition() {
  const queryClient = useQueryClient();
  const { entity: ruleId, setModal, setEntity } = useModalContext();
  const { rule, loadingRule } = useGetSigmaRule(ruleId);

  const onClose = React.useCallback(() => {
    queryClient.invalidateQueries([eventDefinitionsKey]);
    queryClient.invalidateQueries([sigmaRuleKey]);
    setModal(null);
    setEntity(null);
  }, [setEntity, setModal, queryClient]);

  if (!rule && !loadingRule) {
    return (
      <ConfirmDialog
        show
        onConfirm={onClose}
        confirmText="Close"
        noCancelButton
        type="danger"
        title="Edit Sigma Event Definition">
        <p>A Sigma Rule with ID: &quot;{ruleId}&quot; could not be found.</p>
      </ConfirmDialog>
    );
  }

  return loadingRule ? null : <SigmaModal rule={rule} onConfirm={onClose} onCancel={onClose} />;
}

export default EditSigmaEventDefinition;
