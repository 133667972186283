import { useMemo } from 'react';

import { useGetFilteredPacks } from 'illuminate/hooks/usePacksAPI';
import type { Packs } from 'illuminate/types';

const useThreatRelatedIlluminatePacks = (): { isInitialLoading: boolean; allPacks: Packs; notEnabledPacks: Packs } => {
  const { filteredPacks: packs, loadingFilteredPacks: isInitialLoading } = useGetFilteredPacks({
    tags: ['attack'],
    include_deps: true,
  });

  const notEnabledPacks = useMemo(() => packs.filter(({ enabled }) => !enabled), [packs]);

  return {
    isInitialLoading,
    allPacks: packs,
    notEnabledPacks,
  };
};

export default useThreatRelatedIlluminatePacks;
