import * as React from 'react';

import { InputOptionalInfo, FormikFormGroup } from 'components/common';

type Props = {
  labelClassName?: string;
  wrapperClassName?: string;
};

const DescriptionFormGroup = ({ wrapperClassName, labelClassName }: Props) => (
  <FormikFormGroup
    name="description"
    label={
      <>
        Description <InputOptionalInfo />
      </>
    }
    type="textarea"
    labelClassName={labelClassName}
    wrapperClassName={wrapperClassName}
    help="Clarify the purpose of this filter in detail. Especially useful when sharing the filter with others."
  />
);

export default DescriptionFormGroup;
