import React, { useMemo } from 'react';

import useTableElements from 'data-lake/hooks/useTableElements';
import { DEFAULT_LAYOUT, ADDITIONAL_ATTRIBUTES, COLUMNS_ORDER } from 'data-lake/data-lake-overview/Constants';
import { keyFn, fetchDataLakes } from 'data-lake/hooks/useDataLakes';
import type { DataLake } from 'data-lake/Types';
import { PaginatedEntityTable } from 'components/common';

import CustomColumnRenderers from './ColumnRenderers';

const DataLakeOverview = () => {
  const { entityActions } = useTableElements();
  const columnRenderers = useMemo(() => CustomColumnRenderers(), []);

  return (
    <PaginatedEntityTable<DataLake>
      humanName="data lakes"
      columnsOrder={COLUMNS_ORDER}
      additionalAttributes={ADDITIONAL_ATTRIBUTES}
      actionsCellWidth={320}
      entityActions={entityActions}
      tableLayout={DEFAULT_LAYOUT}
      fetchEntities={fetchDataLakes}
      keyFn={keyFn}
      entityAttributesAreCamelCase={false}
      columnRenderers={columnRenderers}
    />
  );
};

export default DataLakeOverview;
