import React, { useState } from 'react';

import { BootstrapModalWrapper, Button, Modal } from 'components/bootstrap';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import type { StyleProps } from 'components/bootstrap/Button';
import type { BsSize } from 'components/bootstrap/types';

import InputProfileForm from './InputProfileForm';

import { InputProfilesActions } from '../stores/InputProfilesStore';
import type { InputProfile } from '../Types';

type Props = {
  inputProfile: InputProfile;
  buttonBsStyle?: StyleProps;
  buttonBsSize?: BsSize;
  buttonText?: string;
  onSave: (inputProfile: InputProfile) => void;
};

const EditInputProfileForm = ({
  inputProfile,
  buttonBsStyle = 'info',
  buttonBsSize = null,
  buttonText = 'Edit',
  onSave,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const sendTelemetry = useSendTelemetry();
  const _openModal = () => setShowModal(true);
  const _closeModal = () => setShowModal(false);

  const handleSubmit = (nextInputProfile: InputProfile) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_INPUT_PROFILE_UPDATED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
    });

    InputProfilesActions.update(nextInputProfile, inputProfile.id)
      .then((result) => {
        onSave(result);

        return result;
      })
      .finally(_closeModal);
  };

  const modalTitle = inputProfile.id ? 'Update input profile' : 'Create input profile';

  return (
    <>
      <Button bsStyle={buttonBsStyle} bsSize={buttonBsSize} onClick={_openModal}>
        {buttonText}
      </Button>
      <BootstrapModalWrapper showModal={showModal} onHide={_closeModal} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <InputProfileForm
          inputProfile={inputProfile}
          onSubmit={handleSubmit}
          onCancel={_closeModal}
          buttonToolbarClassName="pull-right"
          buttonText="Update"
          InputsWrapper={Modal.Body}
          ActionsWrapper={Modal.Footer}
        />
      </BootstrapModalWrapper>
    </>
  );
};

export default EditInputProfileForm;
