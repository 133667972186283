import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;
const FloatRight = styled.div`
  position: absolute;
  right: 10px;
`;

const MetricsAction = ({ children = undefined }: React.PropsWithChildren<{}>) => (
  <Container>
    <FloatRight>{children}</FloatRight>
  </Container>
);

export default MetricsAction;
