import styled, { css } from 'styled-components';

const TD = styled.td(
  ({ theme }) => css`
    vertical-align: middle !important;
    max-width: 300px;
    padding: ${theme.spacings.md} ${theme.spacings.sm} !important;
    color: ${theme.colors.brand.tertiary};

    &:not(:last-child) {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,
);

export default TD;
