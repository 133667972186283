import * as React from 'react';

import ControlLabel from 'components/bootstrap/ControlLabel';

const BigLabel = ({ children, htmlFor }: React.PropsWithChildren<{ htmlFor: string }>) => (
  <h4>
    <ControlLabel htmlFor={htmlFor}>{children}</ControlLabel>
  </h4>
);

export default BigLabel;
