import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';

import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';

import FormWrap from '../common/FormWrap';

type StepSubscribeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepSubscribe = ({ onSubmit, onChange }: StepSubscribeProps) => {
  const { formData } = useContext(FormDataContext);
  const [formError] = useState(null);

  const { pollingInterval } = formData;

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <FormWrap onSubmit={handleSubmit} buttonContent="Proceed" title="" error={formError} description="">
      <Input
        id="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue}
        min="1"
        max="60"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in google workspace. The smallest allowable interval is 5 minutes."
        label="Polling Interval"
      />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
