import * as React from 'react';
import { useCallback, useState } from 'react';
import { useField } from 'formik';

import PlusButton from 'report/report-creation/common/PlusButton';
import AddWidgetForm from 'report/report-creation/content/AddWidgetForm';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import type { WidgetRef } from 'report/types';

import AddWidgetDrawer from './AddWidgetDrawer';

const AddWidget = () => {
  const [{ value: widgets }, , { setValue }] = useField<ReportFormValues['widgets']>('widgets');
  const [show, setShow] = useState(false);
  const onClick = useCallback(() => setShow(true), []);
  const onClose = useCallback(() => setShow(false), []);
  const onSubmit = useCallback(
    async (updatedWidgets: Array<WidgetRef>) => {
      setValue(updatedWidgets);
      setShow(false);
    },
    [setValue],
  );

  return (
    <>
      <PlusButton title="Add widgets" onClick={onClick} />
      <AddWidgetDrawer show={show} onClose={onClose}>
        <AddWidgetForm onClose={onClose} widgets={widgets} onSubmit={onSubmit} />
      </AddWidgetDrawer>
    </>
  );
};

export default AddWidget;
