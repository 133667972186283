import __request__ from 'routing/request';
/**
 * Cancels search job
 */
export function cancelAsyncSearchJob(jobId: string, nodeId: string): Promise<void> {
    return __request__('DELETE', `/views/searchjobs/${nodeId}/${jobId}/cancel`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the status of an executed query
 */
export function asyncSearchJobStatus(jobId: string, nodeId: string, page: number = 0, per_page: number = 0): Promise<void> {
    return __request__('GET', `/views/searchjobs/${nodeId}/${jobId}/status`, null, { 'page': page, 'per_page': per_page }, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
