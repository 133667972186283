import React from 'react';

import DataLakeOverview from 'data-lake/data-lake-overview/DataLakeOverview';
import DataLakeJobs from 'data-lake/components/DataLakeJobs';
import DataLakePageNavigation from 'data-lake/components/DataLakePageNavigation';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { DATA_LAKE_LICENSE_TITLE, DATA_LAKE_LICENSE_TEXT } from 'data-lake/Constants';
import { Alert, Row, Col } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';

const DataLakesPage = () => (
  <DocumentTitle title="Data Lake Overview">
    <DataLakePageNavigation />
    <PageHeader title="Data Lake Overview">
      <Alert bsStyle="info">
        Data Lake is a mechanism for storing processed data without ingesting it into OpenSearch.
        <br />
        The Data Lake backend can be either an <b>S3 bucket (recommended)</b> or network storage.
        <br />
        Messages are categorized in Data Lake by Stream. Messages can be retrieved from Data Lake by Stream for a
        defined time interval.
        <br />
        Note that storing messages in Data Lake does not count towards license usage, but retrieving messages does.
      </Alert>
    </PageHeader>
    <LicenseCheck
      licenseSubject={LICENSE_SUBJECTS.archive}
      title={DATA_LAKE_LICENSE_TITLE}
      text={DATA_LAKE_LICENSE_TEXT}
      displayWarningContainer
      bsStyle="danger"
    />
    <DataLakeJobs />
    <Row className="content">
      <Col md={12}>
        <DataLakeOverview />
      </Col>
    </Row>
  </DocumentTitle>
);

export default DataLakesPage;
