import * as React from 'react';
import range from 'lodash/range';
import { Field } from 'formik';

import { InputDescription, Select } from 'components/common';
import StringUtils from 'util/StringUtils';

const OptionValue = ({ label }: { label: string }) => <span>Day {label}</span>;

type Props = {
  id: string;
  required?: boolean;
};

const DayOfMonthSelect = ({ id, required = false }: Props) => {
  const daysOptions = range(1, 31 + 1).map((day) => {
    const stringDay = String(day);

    return { value: stringDay, label: stringDay };
  });

  return (
    <Field name={id}>
      {({ field: { name, value, onChange, onBlur }, meta: { touched, error } }) => (
        <>
          <Select
            onBlur={onBlur}
            onChange={(newValue: string) =>
              onChange({
                target: { value: newValue, name },
              })
            }
            options={daysOptions}
            inputId={name}
            placeholder="Select day of the month"
            required={required}
            valueRenderer={OptionValue}
            value={value ? StringUtils.stringify(value) : undefined}
          />
          {error && touched && <InputDescription error={error} className="no-bm" />}
        </>
      )}
    </Field>
  );
};

export default DayOfMonthSelect;
