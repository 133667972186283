import * as React from 'react';
import styled, { css } from 'styled-components';

import { BootstrapModalConfirm, MenuItem, DeleteMenuItem } from 'components/bootstrap';
import { useArchiveInvestigation, useDeleteInvestigation } from 'security-app/hooks/useInvestigationsAPI';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { IconButton } from 'components/common';
import { DropdownMenu } from 'security-app/components/common';
import useViewInvestigation from 'security-app/components/Investigations/hooks/useViewInvestigation';
import useLocation from 'routing/useLocation';
import useIsInvestigationAIReportEnabled from 'security-app/components/Investigations/AIReport/hooks/useIsInvestigationAIReportEnabled';
import AIReportButton from 'security-app/components/Investigations/AIReport/AIReportButton';

import { useInvestigationDrawer } from './contexts';

type Props = {
  investigation: InvestigationAPIType;
  onDelete?: (investigationId: string) => void;
  onArchive?: (investigationId: string) => void;
};

const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.spacings.xs};
  `,
);

function RowActionMenu({ investigation, onDelete: onDeleteProp, onArchive: onArchiveProp }: Props) {
  const viewInvestigation = useViewInvestigation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const isAIReportFeatureEnabled = useIsInvestigationAIReportEnabled();

  const { archiveInvestigation } = useArchiveInvestigation();
  const { deleteInvestigation } = useDeleteInvestigation();
  const { setCollapsed, setShowDrawer, selectedInvestigationId, setSelectedInvestigationId } = useInvestigationDrawer();
  const sendTelemetry = useSendTelemetry();

  const onArchive = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_ARCHIVED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    await archiveInvestigation({ invId: investigation.id, archive: true });

    if (typeof onArchiveProp === 'function') {
      onArchiveProp(investigation.id);
    }
  };

  const onEdit = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_EDITED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    viewInvestigation(investigation.id);
  };

  const onRestore = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_RESTORED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    await archiveInvestigation({ invId: investigation.id, archive: false });
  };

  const onDelete = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_DELETED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    if (investigation.id === selectedInvestigationId) {
      setShowDrawer(false);
      setCollapsed(false);
      setSelectedInvestigationId(null);
    }

    await deleteInvestigation({ invId: investigation.id });
    const currentInvestigationId = localStorage.getItem('currentInvestigationId');
    if (currentInvestigationId === investigation.id) localStorage.removeItem('currentInvestigationId');

    if (typeof onDeleteProp === 'function') {
      onDeleteProp(investigation.id);
    }

    setShowDeleteModal(false);
  };

  const { pathname } = useLocation();
  const isArchived = pathname.endsWith('investigations/archived');

  return (
    <Wrapper>
      {isAIReportFeatureEnabled && (
        <AIReportButton investigation={investigation} logsCount={investigation?.evidence_counts?.logs} />
      )}
      <DropdownMenu
        trigger={<IconButton name="more_horiz" title="Toggle investigation actions" />}
        position="bottom-end">
        {isArchived ? (
          <MenuItem onClick={onRestore}>Restore</MenuItem>
        ) : (
          <>
            <MenuItem onClick={onArchive}>Archive</MenuItem>
            <MenuItem onClick={onEdit}>Edit</MenuItem>
            <MenuItem divider />
            <DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
          </>
        )}
      </DropdownMenu>
      <BootstrapModalConfirm
        title="Are you sure you want to delete this investigation?"
        onConfirm={onDelete}
        onCancel={() => setShowDeleteModal(false)}
        showModal={showDeleteModal}>
        <div>You are about to delete this investigation: {investigation.name}</div>
      </BootstrapModalConfirm>
    </Wrapper>
  );
}

export default RowActionMenu;
