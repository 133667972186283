import * as React from 'react';
import styled, { css } from 'styled-components';
import { useEffect } from 'react';

import { Button, Alert, Modal, Col, Row } from 'components/bootstrap';
import { Spinner, ClipboardButton, Icon, ModalButtonToolbar } from 'components/common';
import useAIReport from 'security-app/components/Investigations/AIReport/hooks/useAIReport';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import Markdown from 'components/common/Markdown';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { downloadBLOB } from 'util/FileDownloadUtils';
import SatisfactionSurvey from 'security-app/components/Investigations/AIReport/SatisfactionSurvey';
import AIReportQuotaInfo from 'security-app/components/Investigations/AIReport/AIReportQuotaInfo';
import useAIQuotaStatus from 'security-app/components/Investigations/AIReport/hooks/useAIQuotaStatus';

type Props = {
  onClose: () => void;
  investigation: InvestigationAPIType;
};

const Footer = styled(Row)`
  display: flex;
`;

const DisclaimerContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

const DisclaimerText = styled.i`
  margin-left: 30px;
  font-size: 10px;
  color: gray;
`;

const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.md};
    flex-direction: column;
    border: 1px solid ${theme.colors.cards.border};
    border-radius: ${theme.spacings.sm};
    padding: ${theme.spacings.md} ${theme.spacings.lg};
    margin-bottom: ${theme.spacings.md};
  `,
);

const AIReportModalContent = ({ onClose, investigation }: Props) => {
  const { data: quotaData, isLoading: quotaIsLoading, isError: quotaIsError } = useAIQuotaStatus();
  const loadAIReportDataEnabled = !(quotaData?.remaining === 0) && !quotaIsLoading;
  const { data, isFetching, isSuccess, isFetched, isError, error } = useAIReport(
    investigation.id,
    loadAIReportDataEnabled,
  );
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    if (isFetched && isSuccess) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.GET_REPORT_SUCCESS, {
        app_pathname: getPathnameWithoutId(pathname),
      });
    }

    if (isFetched && !isSuccess && isError) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.GET_REPORT_FAILED, {
        app_pathname: getPathnameWithoutId(pathname),
      });
    }
  }, [isSuccess, isFetched, isError, sendTelemetry, pathname]);

  const AI_REPORT_DISCLAIMER =
    'AI-generated content may contain inaccuracies or be misleading. Please verify all information independently.';

  const onDownload = () => {
    const finalReport = `**Investigation Name: ${investigation.name}**\n===============================${data}\n\n\n${AI_REPORT_DISCLAIMER}`;

    downloadBLOB(finalReport, { fileName: `AI_Report_${investigation.name}.txt`, contentType: 'text/plain' });
  };

  return (
    <>
      <Modal.Header>
        <h3>
          <i>{investigation.name}</i> - AI Report
        </h3>
      </Modal.Header>
      <Modal.Body>
        <Wrapper>
          <AIReportQuotaInfo data={quotaData} isError={quotaIsError} isLoading={quotaIsLoading} />
          {isFetching ? (
            <Spinner text="Generating AI Report..." />
          ) : (
            <div>
              {data && !isError && <Markdown text={data} />}
              {isError && (
                <Alert bsStyle="danger" title="">
                  {error.message}
                </Alert>
              )}
            </div>
          )}
        </Wrapper>
        {!isFetching && loadAIReportDataEnabled && (
          <Footer>
            <DisclaimerContainer md={7}>
              <DisclaimerText>{AI_REPORT_DISCLAIMER}</DisclaimerText>
            </DisclaimerContainer>
            <Col md={5}>
              <SatisfactionSurvey />
            </Col>
          </Footer>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ModalButtonToolbar>
          <Button onClick={onClose}>Close</Button>
          <ClipboardButton
            title="Copy Report"
            bsStyle="info"
            text={data}
            disabled={isFetching || isError || !loadAIReportDataEnabled}
            buttonTitle="Copy Report"
          />
          <Button bsStyle="primary" onClick={onDownload} disabled={isFetching || isError || !loadAIReportDataEnabled}>
            <Icon name="note_add" type="regular" /> Download Report
          </Button>
        </ModalButtonToolbar>
      </Modal.Footer>
    </>
  );
};

export default AIReportModalContent;
