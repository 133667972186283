import * as React from 'react';
import styled from 'styled-components';

const isNullOrEmpty = (s: string | undefined | null) => s === null || s === undefined || s.trim() === '';

type Props = {
  value: string;
};

const Message = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const MessageCell = ({ value }: Props) =>
  isNullOrEmpty(value) ? <i>No further message.</i> : <Message>{value}</Message>;

export default MessageCell;
