import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { BackendMessage } from 'views/components/messagelist/Types';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import { updateWidgetConfig } from 'views/logic/slices/widgetActions';
import useViewsDispatch from 'views/stores/useViewsDispatch';
import LogViewWidget from 'logview/components/LogViewWidget';
import reexecuteSearchTypes from 'views/components/widgets/reexecuteSearchTypes';
import useOnSearchExecution from 'views/hooks/useOnSearchExecution';
import MessageDetailsContext from 'logview/components/contexts/MessageDetailsContext';
import { fetchMessage } from 'views/hooks/useMessage';
import type { After } from 'logview/types';
import Field from 'views/components/Field';
import useActiveQueryId from 'views/hooks/useActiveQueryId';
import type FieldType from 'views/logic/fieldtypes/FieldType';
import DecoratedValue from 'views/components/messagelist/decoration/DecoratedValue';
import Value from 'views/components/Value';
import useAutoRefresh from 'views/hooks/useAutoRefresh';

import LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const ResetListState = ({ resetListState }: { resetListState: () => void }) => {
  const { setActiveMessageDetailsId } = useContext(MessageDetailsContext);

  useOnSearchExecution(() => {
    resetListState();
    setActiveMessageDetailsId(undefined);
  });

  return null;
};

const sortMessages = (messages: Array<BackendMessage>) => [...messages].reverse();

type Props = {
  config: LogViewWidgetConfig;
  data: {
    after: After;
    effectiveTimerange: AbsoluteTimeRange;
    id: string;
    messages: Array<BackendMessage>;
    total: number;
  };
  editing: boolean;
  id: string;
  queryId: string;
  setLoadingState: (loading: boolean) => void;
  fields: FieldTypeMappingsList;
};

const ViewsLogViewWidget = ({ config, data, queryId, id, setLoadingState, editing, fields }: Props) => {
  const dispatch = useViewsDispatch();
  const { stopAutoRefresh } = useAutoRefresh();
  const onChangeConfig = useCallback(
    (newConfig: LogViewWidgetConfig) => dispatch(updateWidgetConfig(id, newConfig)),
    [dispatch, id],
  );
  const activeQuery = useActiveQueryId();
  const onLoadMessages = useCallback(
    async (after: After) => {
      const result = await dispatch(reexecuteSearchTypes({ [data.id]: { after } }, data.effectiveTimerange));
      const searchTypeResult = result.payload.result.results[queryId].searchTypes[data.id] as {
        messages: any;
        after: After;
      };

      return { messages: sortMessages(searchTypeResult.messages), after: searchTypeResult.after };
    },
    [data.id, data.effectiveTimerange, dispatch, queryId],
  );

  const onLoadMessage = useCallback(
    (message: { index: string; message: { _id: string } }) => fetchMessage(message.index, message.message._id),
    [],
  );
  const sortedMessages = useMemo(() => sortMessages(data.messages), [data.messages]);
  const renderFieldName = useCallback(
    (field: string, fieldType: FieldType) => <Field name={field} queryId={activeQuery} type={fieldType} />,
    [activeQuery],
  );
  const renderFieldValue = useCallback(
    (field: string, fieldType: FieldType, value: string) => (
      <Value field={field} value={value} type={fieldType} render={DecoratedValue} />
    ),
    [],
  );

  return (
    <LogViewWidget<LogViewWidgetConfig>
      config={config}
      defaultFields={LogViewWidgetConfig.defaultFields}
      onLoadMessage={onLoadMessage}
      effectiveTimerange={data.effectiveTimerange}
      messages={sortedMessages}
      initialAfter={data.after}
      loadedAllMessages={(loadedMessagesCount, after) =>
        (data.total !== undefined && loadedMessagesCount === data.total) || !after
      }
      renderFieldName={renderFieldName}
      renderFieldValue={renderFieldValue}
      onLoadMessages={onLoadMessages}
      editing={editing}
      setLoadingState={setLoadingState}
      fields={fields}
      onChangeConfig={onChangeConfig}
      onOpenMessageDetails={stopAutoRefresh}>
      {({ resetListState }) => <ResetListState resetListState={resetListState} />}
    </LogViewWidget>
  );
};

export default ViewsLogViewWidget;
