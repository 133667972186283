import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { DataLake } from '@graylog/enterprise-api';

import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import { FieldTypes } from 'views/logic/fieldtypes/FieldType';

const NON_FILTERABLE_FIELD_TYPES = [FieldTypeMapping.create('timestamp', FieldTypes.DATE())];

type DataLakeField = {
  field_name: string;
  class: string;
  in_memory_filter: boolean;
  normalize_value: boolean;
};

const mapBackendType = (fieldType: string) => {
  switch (fieldType) {
    case 'java.lang.String':
      return 'string';
    default:
      return 'unknown';
  }
};

const identifyFieldType = (fieldType: string, fieldName: string) => {
  if (fieldName === 'streams') {
    return 'streams';
  }

  if (fieldName === 'gl2_source_input') {
    return 'input';
  }

  if (fieldName === 'associated_assets') {
    return 'associated-assets';
  }

  if (['source_ip', 'associated_ip', 'destination_ip'].includes(fieldName)) {
    return 'ip';
  }

  return mapBackendType(fieldType);
};

const deserializeFieldTypes = (fieldTypes: Array<DataLakeField>) =>
  fieldTypes.map(({ field_name: fieldName, class: fieldType }) =>
    FieldTypeMapping.fromJSON({
      name: fieldName,
      type: {
        type: identifyFieldType(fieldType, fieldName),
        properties: ['enumerable'],
        index_names: [],
      },
    }),
  );

const useFieldTypes = (): {
  data: Array<FieldTypeMapping>;
  filterableFieldTypes: Array<FieldTypeMapping>;
  refetch: () => void;
  isLoading?: boolean;
  isInitialLoading?: boolean;
  inMemoryFieldsSet: Set<string>;
} => {
  const {
    data: nonDeserializedData,
    refetch,
    isLoading,
    isInitialLoading,
  } = useQuery<Array<DataLakeField>>(['data-lake', 'field-types'], () => DataLake.filterableFields(), {
    staleTime: 30000,
  });

  const inMemoryFieldsSet = useMemo<Set<string>>(() => {
    if (!nonDeserializedData) return new Set([]);

    return new Set(
      nonDeserializedData
        .filter(({ in_memory_filter }: DataLakeField) => in_memory_filter)
        .map(({ field_name }: DataLakeField) => field_name),
    );
  }, [nonDeserializedData]);
  const data = useMemo(() => deserializeFieldTypes(nonDeserializedData ?? []), [nonDeserializedData]);
  const filterableFields = useMemo(
    () =>
      data?.filter((field) => !NON_FILTERABLE_FIELD_TYPES.find((nonFilterable) => nonFilterable.name === field.name)) ??
      [],
    [data],
  );

  return {
    data: data ? [...NON_FILTERABLE_FIELD_TYPES, ...data] : [],
    filterableFieldTypes: filterableFields,
    refetch,
    isLoading,
    isInitialLoading,
    inMemoryFieldsSet,
  };
};

export default useFieldTypes;
