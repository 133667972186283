import * as React from 'react';

import { Col, Row, Badge } from 'common/components';
import type { Pack } from 'illuminate/types';

import DependsOrRequiredBy from './DependsOrRequiredBy';

type Props = {
  requiredByPacks: Array<Pack>;
  dependsOnPacks: Array<Pack>;
  requiredVersions: Array<string>;
};

function PackRecsAndDeps({ requiredByPacks, dependsOnPacks, requiredVersions }: Props) {
  if (requiredByPacks.length < 1 && dependsOnPacks.length < 1 && requiredVersions.length < 1) return null;

  return (
    <Col $gap="lg">
      {dependsOnPacks.length > 0 && <DependsOrRequiredBy packs={dependsOnPacks} dependencies />}
      {requiredByPacks.length > 0 && <DependsOrRequiredBy packs={requiredByPacks} />}
      {requiredVersions.length > 0 && (
        <Col $gap="xs">
          <strong>Requires Graylog version{requiredVersions.length > 1 ? 's' : null}:</strong>
          <Row $align="center" $gap="xs">
            {requiredVersions.map((version: string) => (
              <Badge key={`required-${version}`}>{version}</Badge>
            ))}
          </Row>
        </Col>
      )}
    </Col>
  );
}

export default PackRecsAndDeps;
