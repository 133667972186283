import { useContext, useEffect } from 'react';

import ExportSettingsContext from 'views/components/ExportSettingsContext';
import ExportTimerangeContext from 'logview/components/contexts/ExportTimerangeContext';

type Props = {
  sort: 'ASC' | 'DESC';
};

const ExportSettingsTimerange = ({ sort }: Props) => {
  const { setSettings } = useContext(ExportSettingsContext);
  const { first, last } = useContext(ExportTimerangeContext);

  useEffect(
    () =>
      setSettings({
        timerange:
          sort === 'ASC' ? { type: 'absolute', from: first, to: last } : { type: 'absolute', from: last, to: first },
      }),
    [first, last, setSettings, sort],
  );

  return null;
};

export default ExportSettingsTimerange;
