import type { UrlQueryFilters } from 'components/common/EntityFilters/types';
import type { TimeRange } from 'views/logic/queries/Query';
import { parseFilters } from 'components/events/fetchEvents';
import { categoryKey } from 'security-app/components/SecurityEvents/Alerts/Constants';

type Status = 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';

const parseSecurityEventFilters = (filters: UrlQueryFilters, defaultTimerange?: TimeRange) => {
  const result = parseFilters(filters, defaultTimerange);
  const filter = {
    associated_assets: filters.get('associated_assets', []),
    mitre_categories: filters.get(categoryKey, []),
    risk_score: filters.get('risk_score')?.[0] ? Number(filters.get('risk_score')[0]) : undefined,
    owner: filters.get('owner', []),
    status: filters.get('status', []) as Array<Status>,
  };

  return {
    ...result,
    filter: {
      priority: [],
      event_definitions: [],
      ...result.filter,
      ...filter,
    },
  };
};
export default parseSecurityEventFilters;
