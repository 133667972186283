import * as React from 'react';
import { useMemo } from 'react';

import { SAVE_COPY } from 'views/components/contexts/SearchPageLayoutContext';
import SearchPage from 'views/pages/SearchPage';
import SearchPageLayoutProvider from 'views/components/contexts/SearchPageLayoutProvider';
import type { ViewJson } from 'views/logic/views/View';
import ViewDeserializer from 'views/logic/views/ViewDeserializer';

const DynamicSearch = ({
  viewJson,
  SearchAreaContainer,
}: {
  viewJson: ViewJson;
  SearchAreaContainer?: React.ComponentType;
}) => {
  const view = useMemo(() => ViewDeserializer(viewJson), [viewJson]);

  const searchPageLayout = useMemo(
    () => ({
      sidebar: { isShown: false },
      viewActions: SAVE_COPY,
      searchAreaContainer: { component: SearchAreaContainer },
    }),
    [SearchAreaContainer],
  );

  return (
    <SearchPageLayoutProvider value={searchPageLayout}>
      <SearchPage view={Promise.resolve(view)} isNew={false} />
    </SearchPageLayoutProvider>
  );
};

export default DynamicSearch;
