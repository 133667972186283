import React from 'react';

import { OverlayTrigger, Icon } from 'components/common';

interface Props {
  message: string;
}

const NotificationHelp = ({ message }: Props) => (
  <OverlayTrigger placement="top" overlay={message} trigger={['hover', 'click']} rootClose>
    <span>
      <Icon name="help" />
    </span>
  </OverlayTrigger>
);

export default NotificationHelp;
