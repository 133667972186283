import * as React from 'react';

import { Link } from 'components/common/router';
import useFailuresOverview from 'failure/hooks/useFailuresOverview';
import { DEFAULT_TIMERANGE } from 'views/Constants';
import SearchLink from 'views/logic/search/SearchLink';
import { createElasticsearchQueryString } from 'views/logic/queries/Query';
import StreamsContext from 'contexts/StreamsContext';
import { Tooltip } from 'components/common';

type Props = React.PropsWithChildren<{
  failureType: string,
  inputId: string,
}>;

const buildSearchLink = (streamIds: Array<string>, failureType: string, inputId: string) =>
  SearchLink.builder()
    .timerange(DEFAULT_TIMERANGE)
    .query(createElasticsearchQueryString(`gl2_source_input:${inputId} AND failure_type:${failureType}`))
    .streams(streamIds)
    .build()
    .toURL();

const InputFailureLink = ({ children = null, failureType, inputId }: Props) => {
  const { data, isSuccess } = useFailuresOverview();
  const streams = React.useContext(StreamsContext);

  const failureStream = streams.find((stream) => stream.title.includes('Failures'));

  if (!isSuccess) {
    return children;
  };

  if (!data[failureType]?.enabled) {
    return (
      <Tooltip
        withArrow
        position="top"
        label={`The ${failureType} failure logging is disabled, please enable it to see the links to the logs.`}>
        <span>{children}</span>
      </Tooltip>
    )
  };

  return (
    <Link to={buildSearchLink([failureStream?.id], failureType, inputId)} >
      {children}
    </Link>
  )
}

export default InputFailureLink;
