import styled from 'styled-components';

import { Table } from 'components/bootstrap';

const DataTable = styled(Table)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  margin: 0;
  table-layout: fixed;
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.sm} ${({ theme }) => theme.spacings.sm};
  border-bottom: 1px solid ${({ theme }) => theme.colors.table.row.divider};
`;

const TableItem = styled.td<{ $label?: boolean; $paragraph?: boolean; $width?: string, $alignText?: string }>`
  color: ${({ $label, theme }) => ($label ? theme.colors.gray[40] : theme.colors.global.textDefault)};
  white-space: ${({ $paragraph }) => ($paragraph ? 'pre-wrap' : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  border: none !important;
  padding: 0 !important;
  width: ${({ $width }) => $width || 'inherit'};
  text-align: ${({ $alignText }) => $alignText || 'inherit'};
`;

DataTable.Row = TableRow;
DataTable.Item = TableItem;

export default DataTable;
