import { qualifyUrls } from 'routing/Routes';

const DefenderRoutes = {
  INTEGRATIONS: {
    Defender: {
      ACTIVITYAPI: {
        index: '/integrations/microsoftdefender',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Defender: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/microsoftdefender/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/microsoftdefender/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Defender: {
      GRAYLOG_Defender_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_defender_ActivityLog_Input.html#graylog-defender-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(DefenderRoutes),
  unqualified: DefenderRoutes,
};

export { DocsRoutes, ApiRoutes };
