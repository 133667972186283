import { useCallback, useMemo, useState } from 'react';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { useQueryClient } from '@tanstack/react-query';
import type { ColorScheme, CustomColors } from '@graylog/sawmill';
import { COLOR_SCHEME_DARK, COLOR_SCHEME_LIGHT } from '@graylog/sawmill';
import { generateColors } from '@graylog/sawmill/styled-components';
import SawmillMantine from '@graylog/sawmill/mantine';

import notifyingAction from 'domainActions/notifyingAction';
import type { CustomThemesColors } from 'customization/types';

import useCustomThemeColors from './useCustomThemeColors';

import { CustomizationActions } from '../stores/CustomizationStore';

const handleSaveTheme = notifyingAction({
  action: CustomizationActions.updateTheme,
  success: () => ({
    message: 'Custom theme successfully saved!',
  }),
  error: (error) => ({
    message: `Error Saving: ${error}`,
  }),
});

const handleResetTheme = notifyingAction({
  action: CustomizationActions.updateTheme,
  success: () => ({
    message: "Theme successfully reset to Graylog's default colors.",
  }),
  error: (error) => ({
    message: `Error Resetting: ${error}`,
  }),
});

const handleRevertTheme = notifyingAction({
  action: CustomizationActions.loadTheme,
  success: () => ({
    message: 'Theme successfully reverted to last saved colors.',
  }),
  error: (error) => ({
    message: `Error Reverting: ${error}`,
  }),
});

const useDefaultColors = () =>
  useMemo(
    () => ({
      [COLOR_SCHEME_LIGHT]: generateColors(SawmillMantine({ colorScheme: COLOR_SCHEME_LIGHT })),
      [COLOR_SCHEME_DARK]: generateColors(SawmillMantine({ colorScheme: COLOR_SCHEME_DARK })),
    }),
    [],
  );

const useCurrentColors = (customColors: CustomColors) =>
  useMemo(
    () => ({
      [COLOR_SCHEME_LIGHT]: generateColors(
        SawmillMantine({ colorScheme: COLOR_SCHEME_LIGHT, customColors: customColors[COLOR_SCHEME_LIGHT] }),
      ),
      [COLOR_SCHEME_DARK]: generateColors(
        SawmillMantine({ colorScheme: COLOR_SCHEME_DARK, customColors: customColors[COLOR_SCHEME_DARK] }),
      ),
    }),
    [customColors],
  );

const useThemeCustomizer = () => {
  const { data: prevCustomColors, isInitialLoading } = useCustomThemeColors(false);
  const [nextCustomColors, setNextCustomColors] = useState<CustomThemesColors>({});
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const customColors = useMemo(
    () => merge({}, prevCustomColors, nextCustomColors),
    [nextCustomColors, prevCustomColors],
  );
  const defaultColors = useDefaultColors();
  const currentColors = useCurrentColors(customColors);
  const isDefaultColors = useMemo(() => isEqual(defaultColors, currentColors), [currentColors, defaultColors]);

  const handleThemeRegenerate = useCallback(
    (nextColors: CustomThemesColors) => {
      queryClient.invalidateQueries(['customization', 'colors']);

      return nextColors;
    },
    [queryClient],
  );

  const onChangeTheme = useCallback(
    ({ mode, key, type, hex }: { mode: ColorScheme; key: string; type: string; hex: string }) => {
      const changedCustomColors = {
        ...nextCustomColors,
        [mode]: {
          ...nextCustomColors?.[mode],
          [type]: {
            ...nextCustomColors?.[mode]?.[type],
            [key]: hex,
          },
        },
      };

      setNextCustomColors(changedCustomColors);

      setIsSaved(false);
    },
    [nextCustomColors],
  );

  const onSaveTheme = useCallback(
    () =>
      handleSaveTheme(customColors)
        .then(handleThemeRegenerate)
        .then(() => {
          setIsSaved(true);

          return nextCustomColors;
        }),
    [customColors, handleThemeRegenerate, nextCustomColors],
  );

  const onRevertTheme = useCallback(
    () =>
      handleRevertTheme()
        .then(handleThemeRegenerate)
        .then((revertedColors) => {
          setNextCustomColors(revertedColors);
          setIsSaved(true);

          return revertedColors;
        }),
    [handleThemeRegenerate],
  );

  const onResetTheme = useCallback(() => {
    const emptyThemeColors = {};

    return handleResetTheme(emptyThemeColors)
      .then(handleThemeRegenerate)
      .then(() => {
        setNextCustomColors(emptyThemeColors);
        setIsSaved(true);

        return emptyThemeColors;
      });
  }, [handleThemeRegenerate]);

  return {
    currentColors,
    customColors,
    isLoadingCustomColors: isInitialLoading,
    isDefaultColors,
    isSaved,
    onChangeTheme,
    onResetTheme,
    onRevertTheme,
    onSaveTheme,
  };
};

export default useThemeCustomizer;
