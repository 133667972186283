import styled, { css } from 'styled-components';

import { Alert } from 'components/bootstrap';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

export const FullSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
`;

export const Window = styled.div<{ $width: number }>(
  ({ theme, $width }) => css`
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 6px;
    width: ${$width}%;
    height: 55vh;
    overflow: hidden;
    border: 1px solid ${theme.colors.input.border};
    background-color: ${theme.colors.global.background};
  `,
);

const WindowActionRow = styled.div(
  ({ theme }: { theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 8px 10px;
    height: 42px;
    color: ${theme.colors.global.link};
    background-color: ${theme.colors.global.background};
  `,
);

export const WindowHeader = styled(WindowActionRow)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.input.border};
`;

export const WindowFooter = styled(WindowActionRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.input.border};
`;

export const WindowBody = styled.div`
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Path = styled.span`
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

export const RepoPath = styled.small(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.colors.gray[50]};
  `,
);

export const Dir = styled.div`
  padding: 8px 0;
  cursor: pointer;
  font-weight: bold;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const File = styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 0;
  margin: 0;
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
`;

export const SelectedFile = styled(File)`
  &:hover {
    text-decoration: line-through;
  }
`;

export const IconButton = styled.span<{ $delete?: boolean }>`
  padding: 0;
  margin: 0;
  color: ${({ $delete, theme }) => ($delete ? theme.colors.variant.danger : 'inherit')};
`;

export const ImportErrorsBox = styled(Alert)`
  margin-top: 1rem;
  position: relative;
`;

export const ClearErrors = styled(IconButton)`
  position: absolute;
  top: 7px;
  right: 10px;
`;

export const ImportError = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & > .message {
    padding: 5px 0;
    padding-left: 10px;
  }
`;
