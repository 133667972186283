// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExQUjkrDsu4Kv4Jg_Y3r {
  float: right;
  margin-top: 10px;
}

.RhAWCiyyvYoAadbBTkYt {
  margin-top: 35px;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/event-definitions/event-definition-form/NotificationsForm.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[":local(.manageNotifications) {\n  float: right;\n  margin-top: 10px;\n}\n\n:local(.notificationSettings) {\n  margin-top: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageNotifications": `ExQUjkrDsu4Kv4Jg_Y3r`,
	"notificationSettings": `RhAWCiyyvYoAadbBTkYt`
};
export default ___CSS_LOADER_EXPORT___;
