// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WACkwfnCVB67DwbIE5MS {
  margin-top: 10px;
  margin-bottom: 10px;
}

.QNAIHkpLShbQIJ_cCNCJ {
  table-layout: fixed;
}

.dwVkNp4TeprPZsdAeaOu.alert {
  margin-bottom: 10px;
}

.dwVkNp4TeprPZsdAeaOu h4 {
  font-size: 1.44rem; /* theme.fonts.size.body h4 */
  margin-bottom: 0.5em;
}

.dwVkNp4TeprPZsdAeaOu ul {
  list-style: disc;
  margin: 0.5em 0;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/event-definitions/common/commonStyles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB,EAAE,6BAA6B;EACjD,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[":local(.title) {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n:local(.fixedTable) {\n  table-layout: fixed;\n}\n\n:local(.validationSummary).alert {\n  margin-bottom: 10px;\n}\n\n:local(.validationSummary) h4 {\n  font-size: 1.44rem; /* theme.fonts.size.body h4 */\n  margin-bottom: 0.5em;\n}\n\n:local(.validationSummary) ul {\n  list-style: disc;\n  margin: 0.5em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `WACkwfnCVB67DwbIE5MS`,
	"fixedTable": `QNAIHkpLShbQIJ_cCNCJ`,
	"validationSummary": `dwVkNp4TeprPZsdAeaOu`
};
export default ___CSS_LOADER_EXPORT___;
