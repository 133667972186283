import { qualifyUrls } from 'routing/Routes';

const SophosRoutes = {
  INTEGRATIONS: {
    Sophos: {
      ACTIVITYAPI: {
        index: '/integrations/sophos',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Sophos: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/sophos/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/sophos/check_credentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Sophos: {
      GRAYLOG_Sophos_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_sophos_ActivityLog_Input.html#graylog-sophos-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(SophosRoutes),
  unqualified: SophosRoutes,
};

export { DocsRoutes, ApiRoutes };
