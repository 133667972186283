import * as React from 'react';

import { DeleteMenuItem } from 'components/bootstrap';
import useDeleteFailedSnapshotMutation from 'data-tiering/hooks/useDeleteFailedSnapshotMutation';

type Props = {
  eventKey: string;
  indexSetId: string;
};

const DeleteFailedSnapshotMenuItem = ({ eventKey, indexSetId }: Props) => {
  const { deleteFailedSnapshot } = useDeleteFailedSnapshotMutation(indexSetId);
  const onConfirmDelete = () => {
    deleteFailedSnapshot();
  };

  const onDeleteSnapshot = () => {
    // eslint-disable-next-line no-alert
    if (
      window.confirm(
        'Deleting this snapshot will cause the rollover to warm tier (if enabled) to be retried - if it fails again, check logs for underlying cause. Do you want to proceed?',
      )
    ) {
      onConfirmDelete();
    }
  };

  return (
    <DeleteMenuItem eventKey={eventKey} onClick={onDeleteSnapshot}>
      Delete snapshot
    </DeleteMenuItem>
  );
};

export default DeleteFailedSnapshotMenuItem;
