import * as React from 'react';
import { useState, useCallback } from 'react';

import { RenderCompletionObserver } from 'components/visualizations';
import ErrorBoundary from 'export/ErrorBoundary';
import useParams from 'routing/useParams';
import { Spinner } from 'components/common';
import useExportData from 'export/useExportData';
import ThreatCoverageExportPage, { RENDER_TYPE } from 'export/ThreatCoverageExportPage';
import type { ExportData } from 'export/types';
import type { CoverageEntity } from 'security-app/components/common/ThreatCoverageWidget/types';
import RenderTypeNotSupported from 'export/RenderTypeNotSupported';
import { Container, InvisibleDiv } from 'export/Components';

import ExportErrorPage from './ExportErrorPage';

const supportedTypes = [RENDER_TYPE];

const ExportPage = () => {
  const { exportCallId } = useParams();
  const { isFetching, data } = useExportData(exportCallId);
  const [isRenderComplete, setIsRenderComplete] = useState(false);
  const handleRenderComplete = useCallback(() => {
    setIsRenderComplete(true);
  }, []);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Container>
      <RenderCompletionObserver onRenderComplete={handleRenderComplete}>
        <ErrorBoundary FallbackComponent={ExportErrorPage}>
          <>
            {data.widget.renderType === 'threat_coverage' && (
              <ThreatCoverageExportPage data={data as ExportData<Array<CoverageEntity>>} />
            )}
            {!supportedTypes.includes(data.widget.renderType) && (
              <RenderTypeNotSupported renderType={data.widget.renderType} />
            )}
          </>
        </ErrorBoundary>
      </RenderCompletionObserver>
      {isRenderComplete && <InvisibleDiv id="render-complete" />}
    </Container>
  );
};

export default ExportPage;
