import React from 'react';

import PageHeader from 'components/common/PageHeader';
import { ExternalLink } from 'components/common';

import EmbeddedO365App from './EmbeddedO365App';

const O365App = () => (
  <>
    <PageHeader title="Office 365 Integrations">
      <span>This feature retrieves log records from Office 365.</span>
      <p>
        You need to have{' '}
        <ExternalLink href="https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview">
          Azure AD
        </ExternalLink>
        , <ExternalLink href="https://www.office.com/">Office 365</ExternalLink> subscriptions and have turned on{' '}
        <ExternalLink href="https://compliance.microsoft.com/auditlogsearch?viewid=Async%20Search">
          unified logging
        </ExternalLink>
        .
      </p>
    </PageHeader>
    <EmbeddedO365App />
  </>
);

export default O365App;
