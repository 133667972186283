import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type {
  Bundles,
  IlluminateStatus,
  NewBundleType,
  HubBundleType,
  HubReleaseNotes,
  InstallStatus,
} from 'illuminate/types';
import {
  getInstalledBundles,
  getBundlesStatus,
  getLatestBundle,
  updateBundle,
  deleteBundle,
  uploadBundle,
  getHubBundles,
  installHubBundle,
  getReleaseNotes,
  getInstallationStatus,
  checkPreviousBundle,
} from 'illuminate/hooks/api/bundles';
import type FetchError from 'logic/errors/FetchError';
import { onError } from 'util/conditional/onError';

export function useGetInstalledBundles() {
  const { data, isLoading } = useQuery<Bundles, Error>(['all-bundles'], () =>
    onError(getInstalledBundles(), (error: Error) => UserNotification.error(error.message)),
  );

  return {
    bundles: (data || []) as Bundles,
    bundleVersion: isLoading ? undefined : data.find((bundle) => bundle.enabled)?.version,
    loadingBundles: isLoading,
  };
}

export function useGetBundlesStatus() {
  const { data, isLoading } = useQuery<IlluminateStatus, Error>(['bundles-status'], () =>
    onError(getBundlesStatus(), (error: Error) => UserNotification.error(error.message)),
  );

  return {
    bundlesStatus: (data || {}) as IlluminateStatus,
    loadingBundlesStatus: isLoading,
  };
}

export function useGetLatestBundle(): { version: string; isNew: boolean; isInitialLoading: boolean } {
  const { data, isInitialLoading } = useQuery<NewBundleType, Error>(
    ['check-for-new-bundle'],
    () => onError(getLatestBundle(), (error: Error) => UserNotification.error(error.message)),
    {
      refetchInterval: 1000 * 60 * 30 /* 30 minutes */,
    },
  );

  const { version, is_new_version } = data ?? { version: '', is_new_version: false };

  return { version, isNew: is_new_version, isInitialLoading };
}

export function useUpdateBundle() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateBundle, {
    onSuccess: () => {
      UserNotification.success('Bundle updated successfully');
      queryClient.invalidateQueries(['check-for-new-bundle']);
      queryClient.invalidateQueries(['bundles-status']);
      queryClient.invalidateQueries(['all-bundles']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    updateBundle: mutateAsync,
    updatingBundle: isLoading,
  };
}

export function useDeleteBundle() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteBundle, {
    onSuccess: () => {
      UserNotification.success('Bundle deleted successfully');
      queryClient.invalidateQueries(['check-for-new-bundle']);
      queryClient.invalidateQueries(['bundles-status']);
      queryClient.invalidateQueries(['all-bundles']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    deleteBundle: mutateAsync,
    deletingBundle: isLoading,
  };
}

export function useUploadBundle() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(uploadBundle, {
    onSuccess: () => {
      UserNotification.success('Bundle uploaded successfully');
      queryClient.invalidateQueries(['check-for-new-bundle']);
      queryClient.invalidateQueries(['bundles-status']);
      queryClient.invalidateQueries(['all-bundles']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    uploadBundle: (bundleFile: File, nextPercentage: (percent: number) => void) =>
      new Promise((resolve: (version: string) => void, reject: (error: Error) => void) => {
        mutateAsync(
          { bundleFile, nextPercentage },
          {
            onSuccess: (version: string) => resolve(version),
            onError: (error: Error) => reject(error),
          },
        );
      }),
    uploadingBundle: isLoading,
  };
}

/* Illuminate HUB */
export function useGetHubBundles() {
  const { data, isLoading } = useQuery<{ bundles: HubBundleType[] }, Error>(['hub-bundles'], () =>
    onError(getHubBundles(), (error: FetchError) => {
      UserNotification.error(error.message);
    }),
  );

  return {
    hubBundles: data?.bundles ?? [],
    loadingHubBundles: isLoading,
  };
}

export function useGetReleaseNotes(version: string) {
  const { data, isLoading } = useQuery<HubReleaseNotes, Error>(
    ['release-notes', version],
    () => onError(getReleaseNotes(version), (error: Error) => UserNotification.error(error.message)),
    {
      enabled: !!version,
      retry: 2,
    },
  );

  return {
    releaseNotes: data?.release_notes.split(/\n.+\n={20,25}\n/)[0].trim() ?? '#### No release notes available ...',
    loadingReleaseNotes: isLoading,
  };
}

export function useInstallHubBundle() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(installHubBundle, {
    onSuccess: () => {
      UserNotification.success('Bundle installed successfully');
      queryClient.invalidateQueries(['all-bundles']);
      queryClient.invalidateQueries(['check-for-new-bundle']);
      queryClient.invalidateQueries(['hub-bundles']);
      queryClient.invalidateQueries(['illuminate-bundle-packs']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    installHubBundle: mutateAsync,
    installingHubBundle: isLoading,
  };
}

export function useGetInstallationStatus() {
  const { data, isLoading } = useQuery<InstallStatus, Error>(
    ['install-status'],
    () => onError(getInstallationStatus(), (error: Error) => UserNotification.error(error.message)),
    {
      refetchInterval: 1000,
      retry: 2,
    },
  );

  return {
    installationStatus: isLoading ? { install_in_progress: false } : data || { install_in_progress: false },
    gettingStatus: isLoading,
  };
}

export function useCheckPreviousBundle() {
  const { data, isLoading } = useQuery<string, Error>(['check-previous'], () => checkPreviousBundle(), {
    onError: (error: Error) => UserNotification.error(error.message),
    retry: 2,
  });

  return {
    previousBundle: data,
    checkingPreviousBundle: isLoading,
  };
}
