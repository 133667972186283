import * as React from 'react';

import type { DataLake } from 'data-lake/Types';
import { Table } from 'components/bootstrap';
import DataLakeRetrievalTableEntry from 'data-lake/components/DataLakeRetrievalTableEntry';

type Props = {
  archive: DataLake;
  onDeleteOperation: (id: string) => Promise<void>;
};

const Header = () => (
  <thead>
    <tr>
      <th>#</th>
      <th>Retrieved at</th>
      <th aria-label="Retrieval status" />
      <th>From</th>
      <th>To</th>
      <th>Documents count</th>
      <th>Index failures</th>
      <th>Indices count</th>
      <th className="text-right">Actions</th>
    </tr>
  </thead>
);

const DataLakeRetrieval = ({ archive, onDeleteOperation }: Props) => {
  const hasRetrievaldIndices = !!archive?.restore_history?.length;

  return (
    <Table condensed hover>
      <Header />
      {hasRetrievaldIndices &&
        archive.restore_history.map((restoreOp, index) => (
          <DataLakeRetrievalTableEntry
            key={restoreOp.id}
            retrievalOperation={restoreOp}
            index={index}
            onDeleteOperation={onDeleteOperation}
          />
        ))}
      {!hasRetrievaldIndices && (
        <tbody>
          <tr key="no-indices">
            <td colSpan={9}>No restored indices.</td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};

export default DataLakeRetrieval;
