import React, { useCallback, useState } from 'react';
import * as Immutable from 'immutable';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import copyToClipboard from 'util/copyToClipboard';
import { DropdownButton, InputGroup, FormGroup, MenuItem, DeleteMenuItem } from 'components/bootstrap';
import type ParameterType from 'views/logic/parameters/Parameter';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import TypeSpecificParameterInput from 'enterprise/parameters/components/TypeSpecificParameterInput';
import useParametersMap from 'views/hooks/useParametersMap';

import ParameterDeclarationForm from './ParameterDeclarationForm';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0;
`;

type StyledDropdownButtonProps = React.ComponentProps<typeof DropdownButton> & { $hasError: boolean };
const StyledDropdownButton: React.ComponentType<StyledDropdownButtonProps> = styled(DropdownButton)<{
  $hasError: boolean;
}>(
  ({ theme, $hasError }) => `
  ${
    $hasError
      ? css`
          && {
            border: 1px solid ${theme.colors.variant.light.danger};
            border-right: 0;
          }
        `
      : ''
  }
`,
);

type Props = {
  parameter: ParameterType;
  searchId: string;
  onDelete: (parameterName: string, formFieldName: string) => void;
  onEdit: (parameterName: string, parameter: ParameterType) => Promise<unknown>;
  inputSize?: 'small' | 'large';
};

const Parameter = ({ parameter, searchId, onDelete, onEdit, inputSize = 'small' }: Props) => {
  const [showDeclarationForm, setShowDeclarationForm] = useState(false);
  const { name: parameterName, title } = parameter;
  const parameters = Immutable.fromJS({ [parameterName]: parameter });
  const fieldName = `parameterBindings.${parameterName}`;
  const existingParameters = useParametersMap();
  const existingParametersWithoutCurrentOne = existingParameters.remove(parameterName);

  const toggleDeclarationForm = useCallback(() => {
    setShowDeclarationForm((cur) => !cur);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(parameterName, fieldName);
  }, [onDelete, fieldName, parameterName]);

  const handleUpdate = useCallback(
    (newParameters: ParameterMap) => {
      const newParameter = newParameters.get(parameterName);

      toggleDeclarationForm();

      return onEdit(parameterName, newParameter);
    },
    [onEdit, parameterName, toggleDeclarationForm],
  );

  const onValueChange = useCallback(
    (name, onChange) => (_, newValue) =>
      onChange({
        target: { name, value: newValue },
      }),
    [],
  );

  const copyParameterNameToClipboard = useCallback(() => copyToClipboard(`$${parameterName}$`), [parameterName]);

  return (
    <Field name={fieldName}>
      {({ field: { onChange, onBlur, value, name }, meta: { error } }) => (
        <StyledFormGroup controlId={`form-inline-${name}`} validationState={error ? 'error' : null} bsSize={inputSize}>
          <InputGroup bsSize={inputSize}>
            <InputGroup.Button>
              <StyledDropdownButton id={`parameter-dropdown-${name}`} bsSize="small" $hasError={!!error} title={title}>
                <MenuItem key="edit" onSelect={toggleDeclarationForm}>
                  Edit
                </MenuItem>
                <DeleteMenuItem key="delete" onSelect={handleDelete} />
                <MenuItem divider />
                <MenuItem key="copy" onSelect={copyParameterNameToClipboard}>
                  Copy to clipboard
                </MenuItem>
              </StyledDropdownButton>
            </InputGroup.Button>

            <TypeSpecificParameterInput
              parameter={parameter}
              value={value ?? ''}
              inputSize={inputSize}
              onChange={onValueChange(name, onChange)}
              onBlur={onBlur}
              searchId={searchId}
            />
            {showDeclarationForm && (
              <ParameterDeclarationForm
                existingParameters={existingParametersWithoutCurrentOne}
                parameters={parameters}
                onClose={toggleDeclarationForm}
                onSave={handleUpdate}
              />
            )}
          </InputGroup>
        </StyledFormGroup>
      )}
    </Field>
  );
};

export default Parameter;
