import React, { useEffect, useState } from 'react';

import { useStore } from 'stores/connect';
import { Spinner } from 'components/common';

import StartNewForwarder from './select-forwarder-step/StartNewForwarder';
import SelectForwarder from './select-forwarder-step/SelectForwarder';

import ForwardersStore, { ForwardersActions } from '../stores/ForwardersStore';

type Props = {
  onStepComplete: () => void;
};

let fetchInterval;

const SelectForwarderStep = ({ onStepComplete }: Props) => {
  const [action, setAction] = useState('select');
  const { forwardersPendingConfiguration } = useStore(ForwardersStore);

  useEffect(() => {
    ForwardersActions.listPendingConfiguration({});

    fetchInterval = setInterval(() => {
      ForwardersActions.listPendingConfiguration({}, true);
    }, 10 * 1000);

    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    };
  }, [action]);

  if (!forwardersPendingConfiguration) {
    return <Spinner />;
  }

  if (action === 'start') {
    return <StartNewForwarder forwarders={forwardersPendingConfiguration} onForwarderSelect={onStepComplete} />;
  }

  return (
    <SelectForwarder
      forwarders={forwardersPendingConfiguration}
      onForwarderSelect={onStepComplete}
      onStartForwarderClick={() => setAction('start')}
    />
  );
};

export default SelectForwarderStep;
