import * as React from 'react';

import { singleton } from 'logic/singleton';

export type OidcBackendMetaContextType = {
  backendId: string;
  backendGroupSyncIsActive: boolean;
  licenseIsValid: boolean;
  setContextValue: ({
    backendId,
    backendGroupSyncIsActive,
    licenseIsValid,
  }: {
    backendId: string;
    backendGroupSyncIsActive: boolean;
    licenseIsValid: boolean;
  }) => void;
};

export const initialState = {
  backendId: undefined,
  backendGroupSyncIsActive: false,
  licenseIsValid: false,
  setContextValue: () => {},
};

const OidcBackendMetaContext = React.createContext<OidcBackendMetaContextType>(initialState);
export default singleton('contexts.authentication.enterprise.oidc.backendMeta', () => OidcBackendMetaContext);
