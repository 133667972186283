import { useState, useEffect } from 'react';
import * as Immutable from 'immutable';

import type { Pagination } from 'stores/PaginationTypes';

import type { PaginatedTeams } from '../actions/TeamsActions';
import TeamsDomain from '../domainActions/TeamsDomain';

const DEFAULT_PAGINATION: Pagination = { page: 1, perPage: 0, query: '' };

type UserFromTeam = { id: string; username: string };
type TeamUsersPerRole = { [roleId: string]: Array<UserFromTeam> };

const getTeamUsersPerRole = (teams: PaginatedTeams): TeamUsersPerRole => {
  let teamUsersPerRole = Immutable.Map<string, Immutable.Set<UserFromTeam>>();

  teams.list.forEach((team) => {
    if (team.users.size === 0 || team.roles.size === 0) {
      return;
    }

    const teamUsers = Immutable.Set<UserFromTeam>(
      team.users.map((userId) => ({ id: userId, username: `${teams.context.users.get(userId).title} (${team.name})` })),
    );

    team.roles.forEach((roleId) => {
      teamUsersPerRole = teamUsersPerRole.set(
        roleId,
        (teamUsersPerRole.get(roleId) ?? Immutable.Set()).union(teamUsers),
      );
    });
  });

  return teamUsersPerRole.toJS();
};

const _loadTeamsAndGetUsers = (pagination, setLoading, setUsers): void => {
  setLoading(true);

  TeamsDomain.loadTeamsPaginated(pagination).then((paginatedTeams: PaginatedTeams) => {
    setUsers(getTeamUsersPerRole(paginatedTeams));
    setLoading(false);
  });
};

const useTeamMembersByRole = () => {
  const [users, setUsers] = useState<TeamUsersPerRole>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => _loadTeamsAndGetUsers(DEFAULT_PAGINATION, setLoading, setUsers), []);

  if (loading || !users) {
    return {
      isLoading: loading,
      users: [],
    };
  }

  return {
    isLoading: loading,
    users,
  };
};

export default useTeamMembersByRole;
