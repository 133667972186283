import * as React from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import ArchiveCatalogTableEntry from 'archive/components/ArchiveCatalogTableEntry';
import ArchiveCatalogExportModal from 'archive/components/ArchiveCatalogExportModal';
import ArchiveActions from 'archive/ArchiveActions';
import { PAGE_SIZES } from 'archive/hooks/useArchivesQuery';
import { useArchivesQuery } from 'archive/hooks';
import NumberUtils from 'util/NumberUtils';
import {
  ConfirmDialog,
  IfPermitted,
  NoEntitiesExist,
  NoSearchResult,
  PaginatedList,
  SearchForm,
  Spinner,
} from 'components/common';
import { Table, DropdownButton, MenuItem, DeleteMenuItem } from 'components/bootstrap';
import { IndicesActions } from 'stores/indices/IndicesStore';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import QueryHelper from 'components/common/QueryHelper';
import OverlayTrigger from 'components/common/OverlayTrigger';

import { StyledCatalogWrapperComponent } from './StyledCatalogComponents';

import type { Archive, ArchiveContext } from '../types';

const StyledDropDownbutton = styled(DropdownButton)`
  margin-left: 5px;
`;

const StyledPaginatedListContainer = styled.div`
  margin-top: 32px;
`;

const fieldMap = {
  index: 'Index name of an archive',
  id: 'Archive ID',
  nodes: 'Server node names for archived messages',
  streams: 'Stream names for archived messages',
  sources: 'Source names in archived messages',
  created: 'Archive creation date',
  from: 'Minimum message timestamp in archive',
  to: 'Maximum message timestamp in archive',
};
const queryExamples = (
  <>
    <p>
      Find archives which contain messages in a date range:
      <br />
      <code>{'from:>=2017-03-23 to:<=2017-04-01'}</code>
      <br />
      <code>{'from:">=2016-02-18 18:10:37.055" to:"<=2016-02-18 18:13:16.368"'}</code>
    </p>
    <p>
      Find archives which contain messages from the <var>errors</var> stream:
      <br />
      <code>streams:errors</code>
    </p>
    <p>
      Find archives which contain messages from the <var>errors</var> and <var>warnings</var> streams:
      <br />
      <code>streams:errors streams:warnings</code>
      <br />
      <code>streams:errors,warnings</code>
    </p>
    <p>
      Find archives which contain messages from the <var>foo</var> index and the <var>errors</var> stream:
      <br />
      <code>index:foo streams:errors</code>
      <br />
    </p>
  </>
);

const queryHelpComponent = (
  <QueryHelper entityName="archives" fieldMap={fieldMap} example={queryExamples} commonFields={[]} />
);

const ArchiveCatalog = () => {
  const { page, pageSize: per_page, resetPage } = usePaginationQueryParameter(PAGE_SIZES, PAGE_SIZES?.[0]);
  const [query, setQuery] = useState('');
  const [markedArchives, setMarkedArchives] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmRestore, setShowConfirmRestore] = useState<boolean>(false);
  const [showAlreadyRestoredArchivesToRestore, setShowAlreadyRestoredArchivesToRestore] = useState<boolean>(false);
  const [showArchivesMarkedToRestoreWithSameIndexName, setShowArchivesMarkedToRestoreWithSameIndexName] =
    useState<boolean>(false);
  const sendTelemetry = useSendTelemetry();
  const queryParams = useMemo(() => ({ page, per_page, query }), [page, per_page, query]);

  const {
    isLoading,
    data: { archives, archives_context: archivesContext, backends_context: backendsContext, total },
    refetch,
  } = useArchivesQuery(queryParams);

  const onPaginationChange = () => {
    setMarkedArchives([]);
  };

  const onSearch = (newQuery: string, resetLoadingStateCb: () => void) => {
    resetPage();
    setQuery(newQuery);
    resetLoadingStateCb();
  };

  const onSearchReset = () => {
    resetPage();
    setQuery('');
  };

  const onMarkArchive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    if (checked) {
      setMarkedArchives([...markedArchives, name]);
    } else {
      setMarkedArchives(markedArchives.filter((archiveId) => archiveId !== name));
    }
  };

  const checkedMarkedArchive = (archiveId: string): boolean => markedArchives.includes(archiveId);

  const getMarkedArchiveObjects = (): Archive[] =>
    markedArchives
      .map((archiveId) => archives.find((archive) => archive.id === archiveId))
      .filter((archive?: Archive) => archive !== undefined);

  const deleteArchive = (archive: Archive): void => {
    ArchiveActions.deleteArchive(archive.backend_id, archive.archive_id).then(() => {
      const archiveContext = archivesContext[archive.backend_id][archive.archive_id];
      refetch();

      if (archiveContext.restored) {
        IndicesActions.delete(archiveContext.restored_index_name);
      }
    });
  };

  const restoreArchives = (archivesToRestore: Array<Archive>): void => {
    ArchiveActions.restoreArchives(archivesToRestore).then(() => {
      refetch();
    });
  };

  const deleteSelectedArchives = (): void => {
    setShowConfirmDelete(true);
  };

  const confirmDeleteSelectedArchives = (): void => {
    setShowConfirmDelete(false);

    getMarkedArchiveObjects().forEach((archive: Archive) => {
      deleteArchive(archive);
    });

    setMarkedArchives([]);

    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.DELETED, {
      app_pathname: 'archive',
      app_section: 'archive-catalog',
    });
  };

  const cancelDeleteSelectedArchives = (): void => {
    setShowConfirmDelete(false);
  };

  const getMarkedAlreadyRestoredArchives = (): Array<Archive> =>
    getMarkedArchiveObjects().filter((archive: Archive) => {
      const archiveContext = archivesContext[archive.backend_id][archive.archive_id] as ArchiveContext;

      return !!archiveContext.restored;
    });

  const getArchivesByIndexName = (): Record<string, Array<Archive>> => {
    const byIndexName = {};

    getMarkedArchiveObjects().forEach((archive: Archive) => {
      if (byIndexName[archive.index_name] === undefined) {
        byIndexName[archive.index_name] = [];
      }

      byIndexName[archive.index_name].push(archive);
    });

    return byIndexName;
  };

  const restoreSelectedArchives = (): void => {
    const multipleArchivesWithSameIndexNameMarked = Object.values(getArchivesByIndexName()).find(
      (archiveList: Array<Archive>) => archiveList.length > 1,
    );

    if (getMarkedAlreadyRestoredArchives().length > 0) {
      setShowAlreadyRestoredArchivesToRestore(true);
    } else if (multipleArchivesWithSameIndexNameMarked) {
      setShowArchivesMarkedToRestoreWithSameIndexName(true);
    } else {
      setShowConfirmRestore(true);
    }
  };

  const confirmRestoreSelectedArchives = (): void => {
    setShowConfirmRestore(false);

    const archivesToRestore = getMarkedArchiveObjects();
    restoreArchives(archivesToRestore);

    setMarkedArchives([]);

    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.RESTORED, {
      app_pathname: 'archive',
      app_section: 'archive-catalog',
    });
  };

  const cancelRestoreSelectedArchives = (): void => {
    setShowConfirmRestore(false);
  };

  const hideAlreadyRestoredArchivesToRestoreDialog = (): void => {
    setShowAlreadyRestoredArchivesToRestore(false);
  };

  const hideArchivesMarkedToRestoreWithSameIndexNameDialog = (): void => {
    setShowArchivesMarkedToRestoreWithSameIndexName(false);
  };

  const sizeTextForArchive = (archive: Archive): string => {
    const { index_size, segments } = archive;

    if (index_size > 0) {
      return ` (index size ${NumberUtils.formatBytes(index_size)})`;
    }

    const segmentRawSize = segments.reduce((sum, segment) => sum + segment.raw_size, 0);

    if (segmentRawSize > 0) {
      return ` (uncompressed size: ${NumberUtils.formatBytes(segmentRawSize)})`;
    }

    return '';
  };

  const totalSizeTextForArchives = (archivesToRestore: Array<Archive>): string => {
    const totalIndexSize = archivesToRestore.reduce((previousSize, archive) => archive.index_size + previousSize, 0);

    if (totalIndexSize > 0) {
      return ` with estimated total size ${NumberUtils.formatBytes(totalIndexSize)}`;
    }

    const segmentRawSize = archivesToRestore.reduce((previousSize, archive) => {
      const segmentSize = archive.segments.reduce((sum, segment) => sum + segment.raw_size, 0);

      return segmentSize + previousSize;
    }, 0);

    if (segmentRawSize > 0) {
      return ` with estimated total uncompressed size ${NumberUtils.formatBytes(segmentRawSize)}`;
    }

    return '';
  };

  const restoreTextForArchive = (archive: Archive): string => {
    const archiveContext = archivesContext[archive.backend_id][archive.archive_id] as ArchiveContext;

    return archiveContext.restored ? ' (including the restored index)' : '';
  };

  const getArchiveRow = (archive: Archive, { withSize = false, withRestore = false } = {}): React.ReactElement => (
    <li key={archive.archive_id}>
      {archive.archive_id} ({archive.index_name}){withRestore && restoreTextForArchive(archive)}
      {withSize && sizeTextForArchive(archive)}
    </li>
  );

  const archivesRows = archives.map((archive) => (
    <ArchiveCatalogTableEntry
      key={`archive-entry-${archive.id}`}
      archive={archive}
      archiveContext={archivesContext[archive.backend_id][archive.archive_id]}
      backendContext={backendsContext[archive.backend_id]}
      onMarkArchive={onMarkArchive}
      checkedMarkedArchive={checkedMarkedArchive}
    />
  ));
  const markedArchivesForBulkActions = getMarkedArchiveObjects();

  const dialogs = (
    <>
      <ConfirmDialog
        show={showConfirmDelete}
        onConfirm={confirmDeleteSelectedArchives}
        onCancel={cancelDeleteSelectedArchives}
        title="Delete archives">
        <>
          <p>
            You are about to delete {markedArchivesForBulkActions.length} archives, including their restored indices.
            This action cannot be undone.
          </p>
          <p>Archives to be deleted:</p>
          <ul className="pill-list">
            {markedArchivesForBulkActions.map((archive: Archive) => getArchiveRow(archive, { withRestore: true }))}
          </ul>
          <p />
          <p>Are you sure you want to continue?</p>
        </>
      </ConfirmDialog>
      <ConfirmDialog
        show={showConfirmRestore}
        onConfirm={confirmRestoreSelectedArchives}
        onCancel={cancelRestoreSelectedArchives}
        title="Restore archives">
        <>
          <p>
            You are about to restore {markedArchivesForBulkActions.length} archives into new indices{' '}
            {totalSizeTextForArchives(markedArchivesForBulkActions)}.
          </p>
          <p>Archives to be restored:</p>
          <ul className="pill-list">
            {markedArchivesForBulkActions.map((archive: Archive) => getArchiveRow(archive, { withSize: true }))}
          </ul>
          <p />
          <p>Are you sure you want to continue restoring?</p>
        </>
      </ConfirmDialog>
      <ConfirmDialog
        hideCancelButton
        btnConfirmText="Okay"
        show={showAlreadyRestoredArchivesToRestore}
        onConfirm={hideAlreadyRestoredArchivesToRestoreDialog}
        title="Restore archives">
        <>
          <p>Some of the selected archives are already restored.</p>
          <p>Archives already restored:</p>
          <ul className="pill-list">
            {getMarkedAlreadyRestoredArchives().map((archive: Archive) => getArchiveRow(archive))}
          </ul>
          <p />
          <p>Please unmark them and try again.</p>
        </>
      </ConfirmDialog>
      <ConfirmDialog
        hideCancelButton
        btnConfirmText="Okay"
        show={showArchivesMarkedToRestoreWithSameIndexName}
        onConfirm={hideArchivesMarkedToRestoreWithSameIndexNameDialog}
        title="Restore archives">
        <>
          <p>Some of the selected archives would be restored in an index an identical name.</p>
          <p>Archives with same index name:</p>
          <ul className="pill-list">
            {(Object.values(getArchivesByIndexName()) as Array<Array<Archive>>)
              .flat()
              .map((archive: Archive) => getArchiveRow(archive))}
          </ul>
        </>
      </ConfirmDialog>
    </>
  );

  const toolltip = <>Please mark some archives</>;

  return isLoading ? (
    <Spinner text="Loading archive catalog..." />
  ) : (
    <StyledCatalogWrapperComponent>
      {dialogs}

      <h2>
        Archive Catalog
        <span>
          &nbsp;
          <small>
            {total} total ({markedArchivesForBulkActions.length} marked)
          </small>
        </span>
      </h2>
      <StyledPaginatedListContainer>
        <PaginatedList totalItems={total} pageSizes={PAGE_SIZES} pageSize={per_page} onChange={onPaginationChange}>
          <SearchForm
            onSearch={onSearch}
            onReset={onSearchReset}
            useLoadingState
            queryHelpComponent={queryHelpComponent}>
            <StyledDropDownbutton keepMounted id="export-results-dropdown" title="Export Results">
              <ArchiveCatalogExportModal query={query || ''} />
            </StyledDropDownbutton>
            <IfPermitted permissions={['archive:delete', 'archive:restore']} anyPermissions>
              <OverlayTrigger
                overlay={toolltip}
                trigger={['hover', 'focus']}
                disabled={markedArchives.length > 0}
                placement="top">
                <StyledDropDownbutton
                  disabled={markedArchives.length === 0}
                  id="bulk-actions-dropdown"
                  title="Bulk Actions">
                  <IfPermitted permissions="archive:delete">
                    <DeleteMenuItem onClick={deleteSelectedArchives} />
                    <MenuItem divider />
                  </IfPermitted>
                  <IfPermitted permissions="archive:restore">
                    <MenuItem onClick={restoreSelectedArchives}>Restore</MenuItem>
                  </IfPermitted>
                </StyledDropDownbutton>
              </OverlayTrigger>
            </IfPermitted>
          </SearchForm>
          {!total && !query && <NoEntitiesExist>No archive catalogs have been created yet.</NoEntitiesExist>}

          {!total && query && <NoSearchResult>No archive catalogs have been found.</NoSearchResult>}

          {!!total && (
            <Table condensed hover>
              <thead>
                <tr>
                  <IfPermitted permissions={['archive:delete', 'archive:restore']} anyPermissions>
                    <th>Mark</th>
                  </IfPermitted>
                  <th>Index</th>
                  <th>Backend</th>
                  <th>Created</th>
                  <th>Range</th>
                  <th>Content</th>
                  <th>Streams</th>
                  <th className="restored">Restored</th>
                </tr>
              </thead>
              {archivesRows}
            </Table>
          )}
        </PaginatedList>
      </StyledPaginatedListContainer>
    </StyledCatalogWrapperComponent>
  );
};

export default ArchiveCatalog;
