import { qualifyUrls } from 'routing/Routes';

const SymantecSESRoutes = {
  INTEGRATIONS: {
    SymantecSES: {
      ACTIVITYAPI: {
        index: '/integrations/symantecses',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    SymantecSES: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/symantecses/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/symantecses/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    SymantecSES: {
      GRAYLOG_SymantecSES_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_symantecSES_ActivityLog_Input.html#graylog-symantecSES-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(SymantecSESRoutes),
  unqualified: SymantecSESRoutes,
};

export { DocsRoutes, ApiRoutes };
