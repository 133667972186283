import { useMemo } from 'react';

import useMitreCategories from 'security-app/components/SecurityEvents/Alerts/MitreCategories/useMitreCategories';

export type Tactic = [string, string];

const useMitreTechniques = (tactics: Array<string>) => {
  const { data, isInitialLoading } = useMitreCategories();
  const uppercaseTactics = useMemo(() => tactics?.map((tactic) => tactic.toLocaleUpperCase()) ?? [], [tactics]);
  const techniques: [string, string][] = useMemo(
    () =>
      isInitialLoading
        ? undefined
        : data.tactics
            .filter((row) => (uppercaseTactics.length > 0 ? uppercaseTactics.includes(row.id) : true))
            .flatMap((tactic) => tactic.techniques)
            .flatMap((technique) => [technique, ...technique.subtechniques])
            .map(({ id, name }) => [id, name]),
    [data?.tactics, isInitialLoading, uppercaseTactics],
  );

  return {
    techniques,
    isLoadingTechniques: isInitialLoading,
  };
};
export default useMitreTechniques;
