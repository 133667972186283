import * as React from 'react';
import { useContext } from 'react';
import * as Immutable from 'immutable';
import { useFormikContext } from 'formik';

import { Button } from 'components/bootstrap';
import { Spinner } from 'components/common';
import type { WizardSubmitPayload } from 'logic/authentication/directoryServices/types';
import type { WizardFormValues } from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';
import BackendWizardContext from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';
import { formHasErrors } from 'util/FormsUtils';

import MatchingGroupsContext from './MatchingGroupsContext';

import { GroupSyncActions } from '../../stores/directoryServices/GroupSyncStore';
import prepareLoadGroupsPayload from '../../logic/directoryServices/prepareLoadGroupsPayload';

type Props = {
  disabled: boolean;
  prepareSubmitPayload: (fromValues: WizardFormValues | null | undefined) => WizardSubmitPayload;
};

const _loadGroups = (prepareSubmitPayload, stepsState, setMatchingGroupsContext, currentFormValues, validateForm) => {
  const { formValues, authBackendMeta } = stepsState;
  const wizardFormsValues = { ...formValues, ...currentFormValues };
  const payload = prepareLoadGroupsPayload(
    prepareSubmitPayload,
    wizardFormsValues,
    authBackendMeta.backendId,
    authBackendMeta.serviceType,
  );

  setMatchingGroupsContext({ finishedLoading: false, result: undefined });

  // Validate form before loading groups, but exclude team selection
  return validateForm({ ...currentFormValues, teamSelection: Immutable.Set(['']) }).then((validationErrors) => {
    if (!formHasErrors(validationErrors)) {
      return GroupSyncActions.loadGroups(payload)
        .then((loadGroupsResult) => {
          if (loadGroupsResult) {
            setMatchingGroupsContext({ finishedLoading: true, result: loadGroupsResult });
          }
        })
        .catch((error) => {
          const errors = Immutable.List([error?.message, error?.additional?.res?.text]);
          setMatchingGroupsContext({ finishedLoading: true, result: { errors } });
        });
    }

    setMatchingGroupsContext({ finishedLoading: true, result: undefined });

    return null;
  });
};

const _getButtonText = (loadedGroups, teamSelectionType) => {
  if (!loadedGroups || loadedGroups?.size === 0) return 'Load matching groups';
  if (teamSelectionType !== 'all') return 'Reload matching groups';

  return 'Reload matching groups';
};

const LoadMatchingGroupsButton = ({ prepareSubmitPayload, disabled }: Props) => {
  const { result, finishedLoading, setContextValue: setMatchingGroupsContext } = useContext(MatchingGroupsContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setStepsState, ...stepsState } = useContext(BackendWizardContext);
  const { values: currentFormValues, validateForm } = useFormikContext<WizardFormValues>();
  const buttonBsStyle = currentFormValues.teamSelectionType !== 'all' && !result?.list ? 'info' : undefined;
  const buttonText = _getButtonText(result?.list, currentFormValues.teamSelectionType);

  return (
    <Button
      bsStyle={buttonBsStyle}
      disabled={disabled}
      onClick={() =>
        _loadGroups(prepareSubmitPayload, stepsState, setMatchingGroupsContext, currentFormValues, validateForm)
      }
      type="button">
      {!finishedLoading ? <Spinner delay={0} text={buttonText} /> : buttonText}
    </Button>
  );
};

export default LoadMatchingGroupsButton;
