import * as React from 'react';

import { StyledHeading, DescriptionCol } from 'components/inputs/InputSetupWizard';

import IlluminateList from './IlluminateList';

import { ILLUMINATE_PACK_TYPES } from '../SelectIlluminateStep';
import type { IlluminatePacks } from '../../hooks/useFilterIlluminatePacks';

type Props = {
  selectedSpotlightPacks: Array<string>;
  setSelectedSpotlightPacks: (packs: Array<string>) => void;
  spotlightPacksData: IlluminatePacks;
  isLoadingSpotlightPacksData: boolean;
  setTouched: (touched: boolean) => void;
};

const SelectSpotlightPacks = ({
  spotlightPacksData,
  isLoadingSpotlightPacksData,
  selectedSpotlightPacks,
  setSelectedSpotlightPacks,
  setTouched,
}: Props) => (
  <>
    <StyledHeading>Select Illuminate Spotlight Packs</StyledHeading>
    <DescriptionCol>
      <p>
        Please select one or more Illuminate Spotlight Packs to use with logs received from this Input. Spotlight packs
        install relevant dashboards, sigma rules and events that read logs parsed by Illuminate to Graylog GIM schema.
      </p>
      <p>Content Packs that have already been installed will be shown as grayed out.</p>
    </DescriptionCol>
    <IlluminateList
      isLoading={isLoadingSpotlightPacksData}
      listData={spotlightPacksData}
      selectedPacks={selectedSpotlightPacks}
      setSelectedPacks={setSelectedSpotlightPacks}
      setTouched={setTouched}
      type={ILLUMINATE_PACK_TYPES.SPOTLIGHT}
    />
  </>
);

export default SelectSpotlightPacks;
