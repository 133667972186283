import React from 'react';

import { toGenericInputCreateRequest } from 'enterprise-integrations/awssecuritylake/common/formDataAdapter';

import { SidebarProvider } from './context/Sidebar';
import { FormDataProvider } from './context/FormData';
import { StepsProvider } from './context/Steps';
import { AdvancedOptionsProvider } from './context/AdvancedOptions';
import AWSSecurityLake from './AWSSecurityLake';
import INITIAL_FORMDATA from './_initialFormData';

type Props = {
  onSubmit?: (InputCreateRequest) => void;
};

const EmbeddedAWSSecurityLakeApp = ({ onSubmit }: Props) => {
  const handleSubmit = (formData) => {
    if (!onSubmit) {
      return;
    }

    onSubmit(toGenericInputCreateRequest(formData));
  };

  return (
    <StepsProvider>
      <FormDataProvider initialFormData={INITIAL_FORMDATA}>
        <SidebarProvider>
          <AdvancedOptionsProvider>
            <AWSSecurityLake onSubmit={handleSubmit} externalInputSubmit={typeof onSubmit === 'function'} />
          </AdvancedOptionsProvider>
        </SidebarProvider>
      </FormDataProvider>
    </StepsProvider>
  );
};

export default EmbeddedAWSSecurityLakeApp;
