import * as React from 'react';

import type View from 'views/logic/views/View';
import { MenuItem, Button } from 'components/bootstrap';
import { Modal as ConfirmDialog } from 'security-app/components/common';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';
import useActionCondition from 'security-app/components/Investigations/plugin/useActionCondition';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';

import useSearchSrvc from './shared/useSearchSrvc';
import AddEvidenceModal from './shared/AddEvidenceModal';

export const SearchInvestigationsModal = React.forwardRef(
  ({ search }: { search: View }, ref: (r: ModalHandler) => void) => {
    const { isSearchRelative, saveAsAbsoluteSearch } = useSearchSrvc(search);
    const isAvailable = useActionCondition();

    if (!isAvailable) {
      return null;
    }

    return (
      <AddEvidenceModal
        ids={[search.id]}
        type="searches"
        searchMeta={{ searchTitle: search.title, isSearchRelative, saveAsAbsoluteSearch }}
        ref={ref}
      />
    );
  },
);

export const AddSearchConfirmationDialog = React.forwardRef(
  ({ search }: { search: View }, ref: (r: ModalHandler) => void) => {
    const [showModal, setShowModal] = React.useState(false);
    const isAvailable = useActionCondition();
    const { addEvidence } = useAddEvidence();
    const { saveAsAbsoluteSearch } = useSearchSrvc(search);

    const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();

    React.useImperativeHandle(ref, () => ({
      toggle: () => setShowModal(true),
    }));

    const onSaveAs = React.useCallback(
      (timeRangeType: 'absolute' | 'relative') => async () => {
        setShowModal(false);

        const newSearch =
          timeRangeType === 'absolute' ? await saveAsAbsoluteSearch(`Absolute - ${search.title}`) : search;

        await addEvidence({
          invId: selectedInvestigationId,
          payload: { searches: [newSearch.id] },
        });

        setCollapsed(false);
      },
      [saveAsAbsoluteSearch, selectedInvestigationId, search, addEvidence, setCollapsed],
    );

    const onCancel = React.useCallback(() => {
      setShowModal(false);
    }, []);

    const buttons = React.useMemo(
      () => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onSaveAs('relative')}>Save as Relative</Button>
          <Button bsStyle="success" onClick={onSaveAs('absolute')}>
            Save as Absolute
          </Button>
        </>
      ),
      [onCancel, onSaveAs],
    );

    if (!isAvailable) {
      return null;
    }

    return (
      <ConfirmDialog
        show={showModal}
        title="Relative search as evidence"
        confirmDialog
        onClose={onCancel}
        buttons={buttons}>
        <div>
          The search you are trying to add is relative to the current time. This means that the results will change.
          <br />
          <br />
          Would you like to make an absolute copy of this search and add it to the investigation?
        </div>
      </ConfirmDialog>
    );
  },
);

type Props = {
  loaded: boolean;
  search: View;
  modalRefs: { [key: string]: () => ModalHandler };
};

const CoreSearchActions = ({ loaded, search, modalRefs }: Props) => {
  const { searchInvestigationsModal, addToEvidenceDialog } = modalRefs;
  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);
  const { isSearchRelative } = useSearchSrvc(search);
  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddSearch = async () => {
    if (disabledAdd) return;

    if (isSearchRelative) {
      addToEvidenceDialog()?.toggle();
    } else {
      await addEvidence({
        invId: selectedInvestigationId,
        payload: { searches: [search.id] },
      });

      setCollapsed(false);
    }
  };

  return loaded ? (
    <>
      <MenuItem disabled={disabledAdd} onClick={onAddSearch} icon="extension">
        Add to investigation
      </MenuItem>
      <MenuItem icon="extension" onClick={() => searchInvestigationsModal()?.toggle()}>
        Select an investigation
      </MenuItem>
    </>
  ) : null;
};

export default CoreSearchActions;
