import { qualifyUrls } from 'routing/Routes';

const GcpRoutes = {
  INTEGRATIONS: {
    GCP: {
      ACTIVITYAPI: {
        index: '/integrations/gcp',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    GCP: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/gcp/inputs',
      GET_GCP_REGIONS: '/plugins/org.graylog.enterprise.integrations/gcp/regions',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    GCP: {
      GRAYLOG_GCP_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_gcp_ActivityLog_Input.html#graylog-gcp-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(GcpRoutes),
  unqualified: GcpRoutes,
};

export { DocsRoutes, ApiRoutes };
