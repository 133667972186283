import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { SecurityAppSecurityEventsHistogram } from '@graylog/enterprise-api';

import type { SearchParams } from 'stores/PaginationTypes';
import Spinner from 'components/common/Spinner';
import type { MiddleSectionProps } from 'components/common/PaginatedEntityTable/PaginatedEntityTable';
import useUserDateTime from 'hooks/useUserDateTime';
import { categoryKey } from 'security-app/components/SecurityEvents/Alerts/Constants';
import { parseSearchParams } from 'security-app/hooks/api/securityEventsAPI';
import useOnRefresh from 'components/common/PaginatedEntityTable/useOnRefresh';
import type { GraphProps } from 'security-app/components/SecurityEvents/Alerts/MitreCategories/Histogram';
import Histogram, { otherBucket } from 'security-app/components/SecurityEvents/Alerts/MitreCategories/Histogram';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

type Bucket = { technique: string; id: string; count: number };

const fetchTechniquesHistogram = async (
  searchParams: SearchParams,
  timezone: string,
): Promise<{ [techniqueName: string]: Bucket }> => {
  const result = await SecurityAppSecurityEventsHistogram.techniquesHistogram(
    parseSearchParams(searchParams, timezone),
  );

  return Object.fromEntries(result.techniques.map((technique) => [technique.id, technique]));
};

type Techniques = { [techniqueName: string]: Bucket };
const generateChart = (buckets: Techniques) => {
  const sortedTechniques = Object.values(buckets).sort((b1, b2) => b2.count - b1.count);
  const x = sortedTechniques.map((technique) => technique.id);
  const text = sortedTechniques.map((technique) => technique.technique);
  const y = sortedTechniques.map((technique) => technique.count);

  return { x, y, text };
};
type TechniquesGraphProps = {
  data: Techniques;
  onClickMarker: GraphProps['onClickMarker'];
};
const EventsTechniquesGraph = ({ data, onClickMarker }: TechniquesGraphProps) => {
  const { x, y, text } = useMemo(() => generateChart(data), [data]);

  return <Histogram onClickMarker={onClickMarker} x={x} y={y} text={text} type="Techniques" />;
};
const EventsTechniquesHistogram = ({ searchParams, setFilters }: MiddleSectionProps) => {
  const { userTimezone } = useUserDateTime();
  const {
    data,
    isInitialLoading: isLoadingHistogram,
    refetch,
  } = useQuery(['events', 'techniques', searchParams, userTimezone], () =>
    fetchTechniquesHistogram(searchParams, userTimezone),
  );

  const sendTelemetry = useSendTelemetry();
  const { pathname } = useLocation();

  useOnRefresh(refetch);
  const onClickMarker = useCallback(
    ({ x }: { x: string }) => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.SECURITY_EVENTS.MITRE_ATTACK_WIDGET.DRILLDOWN, {
        app_section: 'security-events',
        app_pathname: getPathnameWithoutId(pathname),
        app_action_value: { drilldown_into: 'technique' },
      });

      if (x !== otherBucket[0]) {
        setFilters(searchParams.filters.set(categoryKey, [x]));
      }
    },
    [pathname, searchParams.filters, sendTelemetry, setFilters],
  );

  return isLoadingHistogram ? <Spinner /> : <EventsTechniquesGraph data={data} onClickMarker={onClickMarker} />;
};

export default EventsTechniquesHistogram;
