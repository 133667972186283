// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JukRiiYxtY9XcsaNWreX {
  padding-left: 0;
  padding-right: 0;
}

.JukRiiYxtY9XcsaNWreX .popover-content {
  min-width: 200px;
  padding: 0;
}

.JukRiiYxtY9XcsaNWreX .list-group {
  margin-bottom: 0;
}

.JukRiiYxtY9XcsaNWreX .list-group-item {
  border-right: 0;
  border-left: 0;
  padding: 6px 15px;
}

.JukRiiYxtY9XcsaNWreX .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.JukRiiYxtY9XcsaNWreX .list-group-item:last-child {
  border-bottom: 0;
}

.GsWSC4qvAf8Rmpjjs7bD {
  min-width: 200px;
  max-height: 340px; /* 10 items */
  overflow: auto;
}

.QqlA8gfXdo2Xv25U73A_ {
  margin-bottom: 0 !important;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/common/SelectPopover.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":[":local(.customPopover) {\n  padding-left: 0;\n  padding-right: 0;\n}\n\n:local(.customPopover) .popover-content {\n  min-width: 200px;\n  padding: 0;\n}\n\n:local(.customPopover) .list-group {\n  margin-bottom: 0;\n}\n\n:local(.customPopover) .list-group-item {\n  border-right: 0;\n  border-left: 0;\n  padding: 6px 15px;\n}\n\n:local(.customPopover) .list-group-item:first-child {\n  border-top-right-radius: 0;\n  border-top-left-radius: 0;\n}\n\n:local(.customPopover) .list-group-item:last-child {\n  border-bottom: 0;\n}\n\n:local(.scrollableList) {\n  min-width: 200px;\n  max-height: 340px; /* 10 items */\n  overflow: auto;\n}\n\n:local(.dataFilterInput) {\n  margin-bottom: 0 !important;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customPopover": `JukRiiYxtY9XcsaNWreX`,
	"scrollableList": `GsWSC4qvAf8Rmpjjs7bD`,
	"dataFilterInput": `QqlA8gfXdo2Xv25U73A_`
};
export default ___CSS_LOADER_EXPORT___;
