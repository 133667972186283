import type { RootState } from 'views/types';
import type { AppDispatch } from 'stores/useAppDispatch';
import useAppDispatch from 'stores/useAppDispatch';
import type { RootStoreState } from 'data-lake/preview/Types';

export type DLPreviewDispatch = AppDispatch<RootStoreState>;

const useDLPreviewDispatch = useAppDispatch<RootState>;

export default useDLPreviewDispatch;
