import * as React from 'react';

import type { FilterComponents } from 'views/components/widgets/overview-configuration/filters/types';
import DateFilter from 'views/components/widgets/overview-configuration/filters/DateFilter';
import AssigneeFilter from 'search/overview-configuration/filters/AssigneeFilter';
import Assignee from 'search/overview-configuration/Assignee';

import StatusFilter from './StatusFilter';
import StatusValue from './StatusValue';
import PriorityFilter from './PriorityFilter';
import PriorityValue from './PriorityValue';

const dateFilterComponents = {
  configuration: (
    _selectedValues,
    editValue: Array<string>,
    onChange: (newValue: Array<string>, shouldSubmit: boolean) => void,
  ) => <DateFilter values={editValue} onChange={(newValue) => onChange(newValue, false)} />,
  valueForConfig: (values: Array<string>) => values.join(','),
  valueFromConfig: (value: string) => (value ? value.split(',') : []),
  renderValue: (values: string) => values.replace(',', ' to '),
  submitChangesOnClose: true,
};

const filterComponents: FilterComponents = [
  {
    attribute: 'created_at',
    ...dateFilterComponents,
  },
  {
    attribute: 'updated_at',
    ...dateFilterComponents,
  },
  {
    attribute: 'assigned_to',
    configuration: (selectedValues, editValue: string, onChange: (newValue: string) => void) => (
      <AssigneeFilter
        value={editValue}
        selectedAssignees={selectedValues}
        onSelect={(newValue) => onChange(newValue)}
      />
    ),
    renderValue: (value) => <Assignee assigneeId={value} />,
  },
  {
    attribute: 'status',
    configuration: (selectedValues, editValue: string, onChange: (newValue: string) => void) => (
      <StatusFilter value={editValue} selectedStatuses={selectedValues} onChange={(newValue) => onChange(newValue)} />
    ),
    renderValue: (value) => <StatusValue statusName={value} />,
    allowMultipleValues: true,
  },
  {
    attribute: 'priority',
    configuration: (selectedValues, editValue: string, onChange: (newValue: string) => void) => (
      <PriorityFilter
        value={editValue}
        selectedPriorities={selectedValues}
        onChange={(newValue) => onChange(newValue)}
      />
    ),
    renderValue: (value) => <PriorityValue priorityId={value} />,
    allowMultipleValues: true,
  },
];

export default filterComponents;
