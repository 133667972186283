import * as React from 'react';
import type { ErrorInfo } from 'react';

type Props = {
  onDidCatch: (error: Error, info: ErrorInfo) => void;
  FallbackComponent: React.ComponentType<{ error: Error; info: ErrorInfo }>;
  children: React.ReactElement;
};

type State = {
  error: Error;
  info: ErrorInfo;
};

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    onDidCatch: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
      info: undefined,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onDidCatch } = this.props;
    this.setState({ error: error, info: info });
    onDidCatch(error, info);
  }

  render() {
    const { error, info } = this.state;
    const { FallbackComponent, children } = this.props;

    if (error) {
      return <FallbackComponent error={error} info={info} />;
    }

    return children;
  }
}

export default ErrorBoundary;
