const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */

  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  graph: {
    value: '',
  },

  graphThrottleEnabled: {
    value: undefined,
  },

  pollingInterval: {
    value: 5,
  },
  content_type: {
    value: '',
  },
  subscriptionType: {
    value: 'GLOBAL',
  },
  availableContents: {
    value: 'EMAIL_LOGS,DIRECTORY_AUDIT_LOGS,SIGN_IN_AUDIT_LOGS,PROVISIONING_AUDIT_LOGS',
  },
  readTimeOffset: {
    value: 5,
  },
};

export default DEFAULT_SETTINGS;
