import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import { FormDataContext } from '../common/context/FormData';
import { AdvancedOptionsContext } from '../common/context/AdvancedOptions';
import AdditionalFields from '../common/AdditionalFields';

type FormAdvancedOptionsProps = {
  onChange: (...args: any[]) => void;
  handlePageSizeChange: (...args: any[]) => void;
  handleLagOffsetChange: (...args: any[]) => void;
};

const FormAdvancedOptions = ({ onChange, handlePageSizeChange, handleLagOffsetChange }: FormAdvancedOptionsProps) => {
  const { formData } = useContext(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } = useContext(AdvancedOptionsContext);

  const { workspaceThrottleEnabled, workspaceStoreFullMessage, pageSize, lagOffset } = formData;

  const handleToggle = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  const internalHandlePageSizeChange = (e) => {
    const { value } = e.target;
    onChange(e);

    if (value >= 1000) {
      handlePageSizeChange('');
    } else {
      handlePageSizeChange('Page size must be at least 1000');
    }
  };

  const internalHandleLagOffsetChange = (e) => {
    const { value } = e.target;

    if (value >= 0) {
      onChange(e);
      handleLagOffsetChange('');
    } else {
      handleLagOffsetChange('Lag time offset cannot be negative');
    }
  };

  return (
    <StyledAdditionalFields title="Advanced Options" visible={isAdvancedOptionsVisible} onToggle={handleToggle}>
      <Input
        id="wrokspaceThrottleEnabled"
        type="checkbox"
        value="enable-throttling"
        defaultChecked={workspaceThrottleEnabled?.value}
        onChange={onChange}
        label="Enable Throttling"
        help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled."
      />
      <Input
        id="pageSize"
        type="number"
        value={pageSize.value}
        min="1000"
        onChange={internalHandlePageSizeChange}
        required
        help="Determines the maximum number logs to return per page of query results."
        label="Page size"
      />
      <Input
        id="lagOffset"
        type="number"
        value={lagOffset.value}
        min="0"
        onChange={internalHandleLagOffsetChange}
        required
        help="Indicate the delay between when the log event occurs and when data is available in the admin console."
        label="Lag time Offset (hours)"
      />
      <Input
        id="workspaceStoreFullMessage"
        type="checkbox"
        value="enable-storeFullMessage"
        defaultChecked={workspaceStoreFullMessage?.value}
        onChange={onChange}
        label="Store Full Message"
        help="Store the full original message in the full_message field?"
      />
    </StyledAdditionalFields>
  );
};

const StyledAdditionalFields = styled(AdditionalFields)`
  margin: 0 0 35px;
`;

export default FormAdvancedOptions;
