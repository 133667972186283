import * as React from 'react';
import styled, { css } from 'styled-components';
import { useState, useCallback, useMemo } from 'react';

import { Table, Button } from 'components/bootstrap';
import { ProgressBar, SortIcon } from 'components/common';
import { defaultCompare } from 'logic/DefaultCompare';

import ImproveCoverageButton from './ImproveCoverageButton';
import { isFullyCovered } from './Utils';
import type { CoverageEntity } from './types';

const Container = styled.div(
  ({ theme }) => css`
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 0;
    min-height: 100%;
    @media print {
      max-height: none;
    }
    @media (max-width: ${theme.breakpoints.max.xl}) {
      height: initial;
      min-height: initial;
      max-height: 320px;
    }
  `,
);

const TableContainer = styled.div`
  overflow: auto;

  @media print { {
    overflow: visible;   
  }
`;

const Thead = styled.thead(
  ({ theme }) => css`
    background-color: ${theme.colors.global.contentBackground};
    position: sticky;
    top: 0;
    z-index: 1;
  `,
);

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;

const ProgressCell = styled.div`
  display: flex;
`;

const StyledProgressBar = styled(ProgressBar)`
  flex: 1;
  margin: 0;
`;

const CoveragePercentage = styled.div`
  width: 45px;
  text-align: right;
  margin-right: 5px;
`;

type Props = {
  tactics: Array<CoverageEntity>;
  onSelectTactic?: (tacticId: string) => void;
};

const TacticsList = ({ tactics, onSelectTactic = () => {} }: Props) => {
  const [coverageSortDirection, setCoverageSortDirection] = useState<'Ascending' | 'Descending'>('Ascending');

  const sortedTactics = useMemo(
    () =>
      tactics?.sort(({ name: name1, coverage: coverage1 }, { name: name2, coverage: coverage2 }) => {
        if (coverage1 !== coverage2) {
          if (coverageSortDirection === 'Ascending') {
            return coverage1 - coverage2;
          }

          return coverage2 - coverage1;
        }

        return defaultCompare(name1, name2);
      }) ?? [],
    [coverageSortDirection, tactics],
  );

  const toggleCoverageSort = useCallback(() => {
    setCoverageSortDirection((cur) => (cur === 'Ascending' ? 'Descending' : 'Ascending'));
  }, []);

  return (
    <Container>
      <TableContainer>
        <Table striped>
          <Thead>
            <tr>
              <th>
                <Header>Tactic Name</Header>
              </th>
              <th style={{ width: '160px', textAlign: 'center' }}>
                <Header>Technique Count</Header>
              </th>
              <th style={{ width: '160px' }}>
                <Header>
                  Coverage{' '}
                  <SortIcon
                    activeDirection={coverageSortDirection}
                    onChange={toggleCoverageSort}
                    title={`Sort coverage ${coverageSortDirection === 'Ascending' ? 'descending' : 'ascending'}`}
                  />
                </Header>
              </th>
            </tr>
          </Thead>
          <tbody>
            {sortedTactics.map((tactic) => {
              const { name, coverage, id, numChildren, numFullyCoveredChildren } = tactic;

              return (
                <tr key={id}>
                  <td>
                    <Button bsStyle="link" onClick={() => onSelectTactic(tactic.id)}>
                      {name}
                    </Button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {numFullyCoveredChildren}/{numChildren}
                  </td>
                  <td>
                    <ProgressCell>
                      <StyledProgressBar
                        bars={[{ value: coverage, bsStyle: isFullyCovered(coverage) ? 'success' : 'warning' }]}
                      />
                      <CoveragePercentage>{coverage}%</CoveragePercentage>
                      <ImproveCoverageButton coverageEntity={tactic} isTactic />
                    </ProgressCell>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TacticsList;
