import React, { useCallback } from 'react';

import type { SelectedSearchFilter } from 'search-filter/types';
import SearchFilterCreatePopover from 'search-filter/components/search-filter-bar/SearchFilterCreatePopover';
import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import useSearchFiltersFormActions from 'search-filter/hooks/useSearchFiltersFormActions';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

type Props = {
  showPopover: boolean;
  onChange: (newState?: boolean) => void;
};

const SearchFilterCreate = ({ showPopover, onChange }: Props) => {
  const sendTelemetry = useSendTelemetry();
  const { addFilter } = useSearchFiltersFormActions();

  const _onSave = useCallback(
    (searchFilter: SelectedSearchFilter) => {
      addFilter(searchFilter);
      onChange();

      sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_CREATED, {
        app_pathname: 'search',
        app_section: 'search-filter',
        app_action_value: 'create',
      });
    },
    [addFilter, sendTelemetry, onChange],
  );

  const onCancel = useCallback(() => onChange(false), [onChange]);
  const onClick = useCallback(() => onChange(), [onChange]);

  return (
    <SearchFilterCreatePopover onSubmit={_onSave} onCancel={onCancel} show={showPopover}>
      <Button onClick={onClick} title="Create new filter" bsSize="small">
        <Icon name="add" />
      </Button>
    </SearchFilterCreatePopover>
  );
};

export default SearchFilterCreate;
