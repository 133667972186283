import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import type { HttpGetAction, WatchlistAction } from 'security-content/types';
import { defaultOnError } from 'util/conditional/onError';

function getExternalActions(): Promise<Record<string, HttpGetAction | WatchlistAction>> {
  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.ContentUI.externalValueActions().url));
}

type UseExternalActionsQueryParams = {
  isLoadingSecurityLicense: boolean;
  isValidSecurityLicense: boolean;
};

export default function useExternalActionsQuery({
  isLoadingSecurityLicense,
  isValidSecurityLicense,
}: UseExternalActionsQueryParams) {
  const { isFetching, isError, data, error } = useQuery(
    ['externalActions'],
    () => defaultOnError(getExternalActions(), 'Error fetching external actions'),
    {
      enabled: !isLoadingSecurityLicense && isValidSecurityLicense,
    },
  );

  return {
    isFetching,
    isError,
    data,
    error: error as Error,
  };
}
