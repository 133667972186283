import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';

const StyledLogViewRow = styled.div<{ $highlight: boolean }>(
  ({ theme, $highlight }) => css`
    display: flex;
    min-width: fit-content;
    font-family: ${theme.fonts.family.monospace};
    padding: 3px 5px;
    position: relative;
    gap: ${theme.spacings.xxs};
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.table.row.divider};
    }
    /* stylelint-disable selector-max-empty-lines, indentation */
    ${$highlight &&
    css`
      background-color: ${theme.colors.table.row.backgroundHover};
      color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
    `}
    /* stylelint-enable selector-max-empty-lines, indentation */

  &:hover {
      background-color: ${theme.colors.table.row.backgroundHover};
      color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
    }
  `,
);

type Props = {
  children: React.ReactNode;
  highlight?: boolean;
  actions?: React.ReactNode;
};

const LogViewRow = ({ children, highlight = false, actions = undefined }: Props) => {
  const [showActions, setShowActions] = useState(false);
  const hasActions = !!actions;

  return (
    <StyledLogViewRow
      $highlight={highlight}
      onMouseEnter={() => hasActions && setShowActions(true)}
      onMouseLeave={() => hasActions && setShowActions(false)}>
      {children}
      {showActions && actions}
    </StyledLogViewRow>
  );
};

export default LogViewRow;
