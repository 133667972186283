import * as Immutable from 'immutable';
import { useNavigate } from 'react-router-dom';

import Routes from 'routing/Routes';
import View from 'views/logic/views/View';
import type Query from 'views/logic/queries/Query';
import type User from 'logic/users/User';
import useCurrentUser from 'hooks/useCurrentUser';
import useViewsSelector from 'views/stores/useViewsSelector';
import useSaveViewFormControls from 'views/hooks/useSaveViewFormControls';
import createSearch from 'views/logic/slices/createSearch';
import { ViewManagementActions } from 'views/stores/ViewManagementStore';
import { executePluggableSearchDuplicationHandler } from 'views/logic/views/pluggableSaveViewFormHandler';
import { selectCurrentQuery, selectCurrentQueryResults } from 'views/logic/slices/viewSelectors';

const saveSearchAs =
  (
    view: View,
    effectiveTimerange: any,
    currentQuery: Query,
    currentUser: User,
    navigate: (path: string) => void,
    saveViewControl: any,
  ) =>
  async (newTitle: string) => {
    const viewWithPluginData = await executePluggableSearchDuplicationHandler(
      view,
      currentUser.permissions,
      saveViewControl,
    );

    const newQuery = currentQuery.toBuilder().newId().timerange(effectiveTimerange).build();

    const newSearch = view.search.toBuilder().newId().queries([newQuery]).build();

    const savedSearch = await createSearch(newSearch);

    const newView = viewWithPluginData
      .toBuilder()
      .newId()
      .title(newTitle)
      .type(View.Type.Search)
      .search(savedSearch)
      .state(Immutable.Map({ [newQuery.id]: view.state.get(currentQuery.id) }))
      .build();

    const savedView = await ViewManagementActions.create(newView);
    navigate(Routes.VIEWS.VIEWID(savedView.id));

    return savedView;
  };

function useSearchSrvc(view: View) {
  const navigate = useNavigate();
  const currentQuery = useViewsSelector(selectCurrentQuery);
  const results = useViewsSelector(selectCurrentQueryResults);
  const currentUser = useCurrentUser();
  const pluggableSaveViewControls = useSaveViewFormControls();

  const isSearchRelative = currentQuery.toJSON().timerange.type === 'relative';

  const saveAsAbsoluteSearch = saveSearchAs(
    view,
    results?.effectiveTimerange,
    currentQuery,
    currentUser,
    navigate,
    pluggableSaveViewControls,
  );

  return { isSearchRelative, saveAsAbsoluteSearch };
}

export default useSearchSrvc;
