import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import AzureRoutes from './common/Routes';

type Props = { url: string };

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state

const AzureLogsInputConfiguration = ({ url = AzureRoutes.INTEGRATIONS.Azure.ACTIVITYAPI.index }: Props) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

export default AzureLogsInputConfiguration;
