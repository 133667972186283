import Immutable from 'immutable';
import type { PluginExports } from 'graylog-web-plugin/plugin';

import SearchFilterTransformationWarning from 'search-filter/components/SearchFilterTransformationWarning';
import type { SelectedSearchFilter } from 'search-filter/types';
import SearchPageSearchFilterBar from 'search-filter/components/SearchPageSearchFilterBar';
import validLicensePresent from 'license/ValidLicensePresent';
import type Query from 'views/logic/queries/Query';
import type Widget from 'views/logic/widgets/Widget';
import generateId from 'logic/generateId';
import MyFiltersDetailsPage from 'search-filter/pages/MyFiltersDetailsPage';
import MyFiltersEditPage from 'search-filter/pages/MyFiltersEditPage';
import MyFiltersCreatePage from 'search-filter/pages/MyFiltersCreatePage';
import MyFiltersOverviewPage from 'search-filter/pages/MyFiltersOverviewPage';
import type View from 'views/logic/views/View';
import getInaccessibleFiltersFromView from 'search-filter/logic/getInaccessableFiltersFromView';
import transformInaccessibleFiltersInSearch from 'search-filter/logic/transformInaccessibleFiltersInSearch';
import transformInaccessibleFiltersInDashboard from 'search-filter/logic/transformInaccessibleFiltersInDashboard';
import type { SearchBarControl } from 'views/types';
import type useSearchFiltersFormActions from 'search-filter/hooks/useSearchFiltersFormActions';
import CreateSearchFilterFromCurrentQuery from 'search-filter/CreateSearchFilterFromCurrentQuery';
import SearchFilterActionsProvider from 'search-filter/SearchFilterActionsProvider';
import type { ViewsDispatch } from 'views/stores/useViewsDispatch';
import SearchFilterBar from 'search-filter/components/search-filter-bar/SearchFilterBar';

const initSearchFilterBarValues = (entity?: Query | Widget | undefined) => {
  if (!entity) {
    return undefined;
  }

  const searchFilters = entity.filters ?? Immutable.List();

  return {
    searchFilters: Immutable.OrderedMap(
      searchFilters.map((filter) => {
        const frontendId = filter.id ?? generateId();

        return [frontendId, { ...filter, frontendId }];
      }),
    ),
  };
};

const onSubmitSearchBar = (
  { searchFilters }: { searchFilters: Immutable.OrderedMap<string, SelectedSearchFilter> },
  _dispatch: ViewsDispatch,
  currentEntity?: Query | Widget,
) => {
  if (!currentEntity) {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filters = searchFilters.map(({ frontendId, ...rest }) => rest) ?? Immutable.OrderedMap();

  return Promise.resolve(currentEntity.toBuilder().filters(filters.toList()).build());
};

declare module 'views/components/searchbar/queryinput/types' {
  interface CustomCommandContext {
    searchFilters: ReturnType<typeof useSearchFiltersFormActions>;
  }
}

const searchBarPlugin = {
  useInitialSearchValues: initSearchFilterBarValues,
  useInitialDashboardWidgetValues: initSearchFilterBarValues,
  onSearchSubmit: onSubmitSearchBar,
  onDashboardWidgetSubmit: onSubmitSearchBar,
  id: 'search-filters',
  placement: 'left',
};

const bindings: PluginExports = {
  routes: [
    { path: '/my-filters', component: MyFiltersOverviewPage },
    { path: '/my-filters/new', component: MyFiltersCreatePage },
    { path: '/my-filters/edit/:filterId', component: MyFiltersEditPage },
    { path: '/my-filters/details/:filterId', component: MyFiltersDetailsPage },
  ],
  'views.components.searchBar': [
    () =>
      validLicensePresent()
        ? ({
            ...searchBarPlugin,
            component: SearchPageSearchFilterBar,
          } as SearchBarControl)
        : null,
  ],
  'eventDefinitions.components.searchForm': [
    () =>
      validLicensePresent()
        ? ({
            ...searchBarPlugin,
            component: SearchFilterBar,
          } as SearchBarControl)
        : null,
  ],
  'views.components.saveViewForm': [
    () =>
      validLicensePresent()
        ? {
            id: 'search-filter-transformation',
            component: SearchFilterTransformationWarning,
            onSearchDuplication: async (view: View, userPermissions: Immutable.List<string>) => {
              const hasInaccessibleSearchFilters = !!getInaccessibleFiltersFromView(view, userPermissions)?.size;

              if (hasInaccessibleSearchFilters) {
                return Promise.resolve(transformInaccessibleFiltersInSearch(view, userPermissions));
              }

              return Promise.resolve(view);
            },
            onDashboardDuplication: async (view: View, userPermissions: Immutable.List<string>) => {
              const hasInaccessibleSearchFilters = !!getInaccessibleFiltersFromView(view, userPermissions)?.size;

              if (hasInaccessibleSearchFilters) {
                return Promise.resolve(transformInaccessibleFiltersInDashboard(view, userPermissions));
              }

              return Promise.resolve(view);
            },
          }
        : null,
  ],
  'views.queryInput.commands': [
    {
      name: 'create-search-filter',
      bindKey: {
        mac: 'Alt+Enter',
        win: 'Alt+Enter',
      },
      usages: ['search_query', 'widget_query'],
      exec: CreateSearchFilterFromCurrentQuery,
    },
  ],
  'views.queryInput.commandContextProviders': [SearchFilterActionsProvider],
};

export default bindings;
