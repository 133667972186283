import React from 'react';
import { Formik, Form } from 'formik';

import { FormikFormGroup, ModalSubmit } from 'components/common';
import { Modal, Row, Col } from 'components/bootstrap';

import useRepositories from './hooks/useRepositories';
import type { S3Repository } from './types';

type Props = {
  hideModal: () => void;
};

type S3RepositoryFormValues = Pick<S3Repository, 'name' | 'bucket' | 'base_path'>;

const S3RepositoryModalForm = ({ hideModal }: Props) => {
  const { onCreateRepository } = useRepositories();

  const handleSubmit = (values: S3RepositoryFormValues) =>
    onCreateRepository({ type: 's3', ...values }).then(() => hideModal());

  const initialValues = {
    name: '',
    bucket: '',
    base_path: '',
  };

  return (
    <Formik<S3RepositoryFormValues> onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting, isValidating }) => (
        <Form>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <FormikFormGroup
                  type="text"
                  label="Name"
                  name="name"
                  help="Name of your warm storage repository. Must be unique."
                  required
                />
                <FormikFormGroup
                  type="text"
                  label="Bucket"
                  name="bucket"
                  help="Name the warm storage repository bucket"
                  required
                />
                <FormikFormGroup
                  type="text"
                  label="Base Path"
                  name="base_path"
                  help="Base path of the warm storage repository"
                  required
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ModalSubmit
              submitButtonText="Create"
              submitLoadingText="Creating..."
              onCancel={hideModal}
              disabledSubmit={isValidating}
              isSubmitting={isSubmitting}
            />
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default S3RepositoryModalForm;
