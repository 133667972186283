import React, { useState, useCallback } from 'react';

import { ButtonToolbar, Table } from 'components/bootstrap';
import { IfPermitted, Timestamp } from 'components/common';
import DaysOfData from 'data-lake/data-lake-overview/cells/DaysOfData';
import type { DataLake } from 'data-lake/Types';
import RetrievalArchiveButton from 'data-lake/data-lake-overview/actions/RetrievalArchiveButton';
import RetrievalArchiveModal from 'data-lake/data-lake-overview/actions/RetrievalArchiveModal';
import DeleteArchiveModal from 'data-lake/data-lake-overview/actions/DeleteArchiveModal';
import DeleteArchiveButton from 'data-lake/data-lake-overview/actions/DeleteArchiveButton';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';
import PreviewLakeLogsButton from 'data-lake/components/PreviewLakeLogsButton';

type Props = {
  dataLake: DataLake;
};

const DataLakeSummary = ({ dataLake }: Props) => {
  const {
    data: { valid: isValidLicense },
    isInitialLoading: isLoadingLicense,
  } = useLicenseValidityForSubject(LICENSE_SUBJECTS.enterprise);
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleRetrievalModal = useCallback(() => setShowRetrievalModal((cur) => !cur), []);
  const toggleDeleteModal = useCallback(() => setShowDeleteModal((cur) => !cur), []);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Message Count</th>
            <th>Days of Data</th>
            <th>From</th>
            <th>To</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dataLake.message_count}</td>
            <td>
              <DaysOfData dataLake={dataLake} />
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <Timestamp dateTime={dataLake.timestamp_from} />
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <Timestamp dateTime={dataLake.timestamp_to} />
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              {!isLoadingLicense && isValidLicense && (
                <ButtonToolbar className="pull-right">
                  <PreviewLakeLogsButton streamId={dataLake.stream_id} />
                  <IfPermitted permissions={['data_lake_archive:restore']}>
                    <RetrievalArchiveButton onClick={toggleRetrievalModal} buttonType="button" />
                  </IfPermitted>
                  <IfPermitted permissions={['data_lake_archive:delete']}>
                    <DeleteArchiveButton onClick={toggleDeleteModal} buttonType="button" />
                  </IfPermitted>
                </ButtonToolbar>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      {showRetrievalModal && (
        <RetrievalArchiveModal archiveStreamId={dataLake.stream_id} onToggleModal={toggleRetrievalModal} />
      )}
      {showDeleteModal && <DeleteArchiveModal archiveStreamId={dataLake.stream_id} onToggleModal={toggleDeleteModal} />}
    </>
  );
};

export default DataLakeSummary;
