import * as React from 'react';

import { FormikFormGroup } from 'components/common';

const CloudEmailFormGroup = () => (
  <FormikFormGroup
    label="Email"
    name="email"
    maxLength={254}
    type="email"
    required
    help="The email is also used as username to log in."
  />
);

export default CloudEmailFormGroup;
