import React, { useEffect, useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { AWS_AUTH_TYPES } from './formDataAdapter';
import { ApiRoutes } from './Routes';
import AWSAuthenticationTypes from './AWSAuthenticationTypes';

import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';
import { renderOptions } from '../common/Options';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    fetch('GET', qualifyUrl(ApiRoutes.INTEGRATIONS.GenericS3.GET_AWS_REGIONS)).then((response) => {
      const region = Object.keys(response).map((key) => ({ label: response[key], value: key }));
      setRegions(region);
    });
  }, []);

  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { awsS3Secret } = formData;
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    fetch('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.GenericS3.CHECK_CREDENTIALS), {
      aws_s3_access_key: formData?.awsS3AccessKey?.value || '',
      aws_s3_secret: formData?.awsS3Secret?.value || '',
      sqs_queue_name: formData?.sqsQueueName?.value || '',
      aws_s3_region: formData?.awsS3Region?.value || '',
    })
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message: 'Unable to connect to AWS using provided configuration.',
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        setFormError({
          full_message: err.message,
          nice_message: 'Unable to connect to AWS using provided configuration.',
        });
      });
  };

  const authType = formData.awsAuthenticationType && formData.awsAuthenticationType.value;

  const isFormValid = formValidation.isFormValid(
    [
      'genericS3Name',
      ...(authType !== AWS_AUTH_TYPES.automatic ? ['awsS3AccessKey', 'awsS3Secret'] : []),
      'awsS3Region',
    ],
    formData,
  );

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      loading={loading}
      title=""
      error={formError}
      description="">
      <ValidatedInput
        className=""
        id="genericS3Name"
        type="text"
        fieldData={formData.genericS3Name}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={awsS3Secret?.value}
        required
      />
      <AWSAuthenticationTypes onChange={onChange} />
      <ValidatedInput
        className=""
        id="sqsQueueName"
        type="text"
        onChange={onChange}
        fieldData={formData.sqsQueueName}
        label="AWS SQS Queue Name"
        help="SQS queue name created for S3 Event Notification."
        required
      />
      <ValidatedInput
        type="select"
        id="awsS3Region"
        onChange={onChange}
        fieldData={formData.awsS3Region}
        help="S3 Bucket region where the log files exists."
        required
        label="S3 Region">
        {renderOptions(regions, 'Choose your account region', false)}
      </ValidatedInput>
    </FormWrap>
  );
};

export default StepAuthorize;
