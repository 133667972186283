import React from 'react';
import styled, { css } from 'styled-components';

import { Spinner, Timestamp } from 'components/common';
import { Alert, Row, Col } from 'components/bootstrap';
import type { AIQuotaStatus } from 'security-app/components/Investigations/AIReport/hooks/useAIQuotaStatus';

const StyledSpan = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.gray[50]};
  `,
);

const AIReportQuotaInfo = ({
  data,
  isLoading,
  isError,
}: {
  data: AIQuotaStatus;
  isLoading: boolean;
  isError: boolean;
}) => {
  if (isLoading) return <Spinner />;

  if (!data || isError) return null;

  if (!data.limit || !data.used) return null;

  const quotaUsageInPercent = Math.round((data.used / data.limit) * 100);
  const isLimitExceeded = data?.remaining === 0;

  if (isLimitExceeded) {
    return (
      <Alert bsStyle="danger" title={`${quotaUsageInPercent}% of AI quota used`}>
        You have used <strong>{quotaUsageInPercent}%</strong> of your quota ({data.remaining}/{data.limit} remaining).
        {data.reset_at && (
          <>
            <br />
            Your quota will be reset on{' '}
            <strong>
              <Timestamp dateTime={data.reset_at} format="shortReadable" />
            </strong>
            .
          </>
        )}
      </Alert>
    );
  }

  return (
    <Row>
      <Col mdPush={8} md={4} xsPush={7} xs={5}>
        <StyledSpan>
          <b>
            {data.remaining}/{data.limit}
          </b>{' '}
          reports remaining today
        </StyledSpan>
      </Col>
    </Row>
  );
};

export default AIReportQuotaInfo;
