// Please note, each project has its own collection of telemetry event types.
// Only maintain event types related to enterprise features in this file.

// eslint-disable-next-line import/prefer-default-export
export const TELEMETRY_EVENT_TYPE = {
  ALERTS_AND_EVENTS: {
    EXPORT_HISTOGRAM: 'Alerts & Events Histogram Exported',
  },
  ARCHIVE: {
    DELETED: 'Archive Deleted',
    RESTORED: 'Archive Restored',
    CREATED: 'Archive Created',
    ITEM_DETAILS_TOGGLED: 'Archive Item Details Opened',
    BACKEND_CONFIGURATION_UPDATED: 'Archive Backend Configuration Updated',
    BACKEND_CONFIGURATION_CREATED: 'Archive Backend Configuration Created',
    BACKEND_CONFIGURATION_DELETED: 'Archive Backend Configuration Deleted',
    CONFIGURATION_UPDATED: 'Archive Configuration Update',
  },
  AUTHENTICATION: {
    OIDC_GROUP_SYNC_SAVE_CLICKED: 'Authentication OIDC Group Sync Save Clicked',
    OIDC_NEXT_GROUP_SYNC_CLICKED: 'Authentication OIDC Next Group Sync Clicked',
    OIDC_PREVIOUS_SERVER_CONFIG_CLICKED: 'Authentication OIDC Previous Server Config Clicked',
    OIDC_SERVER_CONFIG_SAVE_CLICKED: 'Authentication OIDC Server Config Save Clicked',
  },
  CUSTOMIZATION: {
    NOTIFICATION_CREATED: 'Customization Notification Created',
    NOTIFICATION_DELETED: 'Customization Notification Deleted',
    NOTIFICATION_BULK_DELETED: 'Customization Notification Bulk Deleted',
    THEME_REVERTED: 'Customization Theme Reverted',
    THEME_RESETED: 'Customization Theme Reseted',
    HEADER_BADGE_UPDATED: 'Customization Header Badge Updated',
  },
  EVENTDEFINITION_CONDITION: {
    CORRELATION_WITHIN_THE_LAST_UNIT_CHANGED: 'EventDefinition Condition Correlation Within The Last Unit Changed',
    CORRELATION_EXECUTE_EVERY_UNIT_CHANGED: 'EventDefinition Condition Correlation Execute Every Unit Changed',
    CORRELATION_EXECUTED_AUTOMATICALLY_TOGGLED: 'EventDefinition Condition Correlation Executed Automatically Toggled',
    CORRELATION_EVENT_SELECTED: 'EventDefinition Condition Correlation Event Selected',
    CORRELATION_ADD_EVENT_CLICKED: 'EventDefinition Condition Correlation Add Event Clicked',
    CORRELATION_REMOVE_EVENT_CLICKED: 'EventDefinition Condition Correlation Remove Event Clicked',
    CORRELATION_OCCURRENCE_TIME_CHANGED: 'EventDefinition Condition Correlation Occurrence Time Changed',
    CORRELATION_NO_OCCURRENCE_FOLLOWED_BY_EVENT_SELECTED:
      'EventDefinition Condition Correlation No Occurrence Followed By Event Selected',
    CORRELATION_NO_OCCURRENCE_IN_THE_NEXT_SELECTED:
      'EventDefinition Condition Correlation No Occurrence In The Next Selected',
    CORRELATION_NO_OCCURRENCE_IN_THE_NEXT_UNIT_CHANGED:
      'EventDefinition Condition Correlation No Occurrence In The Next Unit Changed',
  },
  FAILURE_PROCESSING_CONFIGURATION_SAVED: 'Failure Processing Configuration Saved',
  AI_REPORT_CONFIGURATION_SAVED: 'AI Report Configuration Saved',
  FORWARDER: {
    INPUTPROFILE_FORWARDER_ASSIGNED: 'Forwarder Input Profile Forwarder Assigned',
    FORWARDER_WIZARD_WELCOME_STEP_OPENED: 'Forwarder Wizard Welcome Step Opened',
    FORWARDER_WIZARD_SELECT_FORWARDER_STEP_OPENED: 'Forwarder Wizard Select Forwarder Step Opened',
    FORWARDER_WIZARD_CONFIGURE_FORWARDER_STEP_OPENED: 'Forwarder Wizard Configure Forwarder Step Opened',
    FORWARDER_WIZARD_INPUTS_STEP_OPENED: 'Forwarder Wizard Forwarder Inputs Step Opened',
    FORWARDER_WIZARD_SUMMARY_STEP_OPENED: 'Forwarder Wizard Forwarder Summary Step Opened',
    FORWARDER_WIZARD_CONFIGURE_FORWARDER_SELECTED: 'Forwarder Wizard Configure Forwarder Selected',
    FORWARDER_WIZARD_CONFIGURE_FORWARDER_SAVED: 'Forwarder Wizard Configure Forwarder Saved',
    FORWARDER_WIZARD_INPUT_PROFILE_SELECTED: 'Forwarder Wizard Input Profile Selected',
    FORWARDER_WIZARD_INPUT_PROFILE_SAVED: 'Forwarder Wizard Input Profile Saved',
    FORWARDER_INPUT_PROFILE_CREATED: 'Forwarder Input Profile Created',
    FORWARDER_CREATED: 'Forwarder Created',
    FORWARDER_UPDATED: 'Forwarder Updated',
    FORWARDER_DELETED: 'Forwarder Created',
    FORWARDER_CONFIGURATION_REMOVED: 'Forwarder Configuration Removed',
    FORWARDER_INPUT_PROFILE_UPDATED: 'Forwarder Input Profile Updated',
    FORWARDER_INPUT_PROFILE_FORWARDER_ASSIGNED: 'Forwarder Input Profile Forwarder Assigned',
    FORWARDER_INPUT_PROFILE_CREATE_CANCELED: 'Forwarder Input Profile Create Canceled',
    FORWARDER_INPUT_PROFILE_INPUT_CREATED: 'Forwarder Input Profile Input Created',
    FORWARDER_INPUT_PROFILE_INPUT_UPDATED: 'Forwarder Input Profile Input Updated',
    FORWARDER_INPUT_PROFILE_INPUT_DELETED: 'Forwarder Input Profile Input Deleted',
    FORWARDER_WIZARD_SETUP_CANCELED: 'Forwarder Wizard Setup Canceled',
  },
  ILLUMINATE: {
    BUNDLE_UPLOADED: 'Illuminate Bundle Uploaded',
    BUNDLE_DOWNLOADED_AND_INSTALLED: 'Illuminate Bundle Downloaded and Installed',
    PACK_ENABLE_MODAL_TOGGLED: 'Illuminate Pack Enable Modal Toggled',
    PACK_DISABLE_MODAL_TOGGLED: 'Illuminate Pack Modal Toggled',
    PACKS_BULK_UPDATED: 'Illuminate Packs Bulk Updated',
    PACKS_OVERRIDE_FORM_OPENED: 'Illuminate Packs Override Form Opened',
    PACKS_OVERRIDE_SAVED: 'Illuminate Packs Override Saved',
    PACKS_CONTENT_HUB_SEARCH: 'Illuminate Content Hub Packs Search',
  },
  INPUT_SETUP_WIZARD: {
    START_INPUT_ILLUMINATE: 'Input Setup Wizard Start Illuminate triggered',
  },
  LICENSE: {
    VALIDATED: 'License Validated',
    SAVED: 'License Saved',
    DELETED: 'License Deleted',
  },
  REPORT: {
    CONFIGURED: 'Report Configured',
    DELETED: 'Report Deleted',
    SENT: 'Report Sent',
    CREATED: 'Report Created',
    UPDATED: 'Report Updated',
    DOWNLOAD: 'Report Downloaded',
  },
  SEARCH_FILTER_CREATED: 'Search Filter Created',
  SEARCH_FILTER_CREATE_CLICKED: 'Search Filter Create Clicked',
  SEARCH_FILTER_DELETED: 'Search Filter Deleted',
  SEARCH_FILTER_ITEM_COPIED: 'Search Filter Item Copied',
  SEARCH_FILTER_ITEM_DISABLED_TOGGLED: 'Search Filter Item Disabled Toggled',
  SEARCH_FILTER_ITEM_EDIT_CLICKED: 'Search Filter Item Edit Clicked',
  SEARCH_FILTER_ITEM_MENU_TOGGLED: 'Search Filter Item Menu Toggled',
  SEARCH_FILTER_ITEM_NEGATION_TOGGLED: 'Search Filter Item Negation Toggled',
  SEARCH_FILTER_ITEM_REFERENCE_REPLACED: 'Search Filter Item Reference Replaced',
  SEARCH_FILTER_ITEM_REMOVED: 'Search Filter Item Removed',
  SEARCH_FILTER_ITEM_SHARE_CLICKED: 'Search Filter Item Share Clicked',
  SEARCH_FILTER_LOADED: 'Search Filter Loaded',
  SEARCH_FILTER_LOAD_CLICKED: 'Search Filter Load Clicked',
  SEARCH_FILTER_UPDATED: 'Search Filter Updated',
  SECURITY_APP: {
    ASSET_DELETED: 'Security Asset Deleted',
    ASSET_SOURCE_DELETED: 'Security Asset Source Deleted',
    ASSET_CONFIG_REPRIORITIZED: 'Security Asset Config Reprioritized',
    ASSET_CONFIG_CATEGORY_ADDED: 'Security Asset Config Category Added',
    ASSET_CONFIG_CATEGORY_UPDATED: 'Security Asset Config Category Updated',
    ASSET_CONFIG_CATEGORY_DELETED: 'Security Asset Config Category Deleted',
    ASSET_CONFIG_PRIORITY_ADDED: 'Security Asset Config Priority Added',
    ASSET_CONFIG_PRIORITY_UPDATED: 'Security Asset Config Priority Updated',
    ASSET_CONFIG_PRIORITY_DELETED: 'Security Asset Config Priority Deleted',
    INVESTIGATION_UPDATED: 'Security Investigation Update',
    INVESTIGATION_ARCHIVED: 'Security Investigation Archived',
    INVESTIGATION_EDITED: 'Security Investigation Updated',
    INVESTIGATION_RESTORED: 'Security Investigation Restored',
    INVESTIGATION_DELETED: 'Security Investigation Deleted',
    INVESTIGATION_PRIORITY_ADDED: 'Security Investigation Priority Added',
    INVESTIGATION_PRIORITY_UPDATED: 'Security Investigation Priority Updated',
    INVESTIGATION_PRIORITY_DELETED: 'Security Investigation Priority Deleted',
    INVESTIGATION_PRIORITY_REPRIORITIZED: 'Security Investigation Priority Reprioritized',
    INVESTIGATION_PRIORITY_DEFAULT_SET: 'Security Investigation Priority Default Set',
    INVESTIGATION_PRIORITY_DEFAULT_REMOVED: 'Security Investigation Priority Default Removed',
    INVESTIGATION_STATUS_ADDED: 'Security Investigation Status Added',
    INVESTIGATION_STATUS_UPDATED: 'Security Investigation Status Updated',
    INVESTIGATION_STATUS_DELETED: 'Security Investigation Status Deleted',
    INVESTIGATION_STATUS_REPRIORITIZED: 'Security Investigation Status Reprioritized',
    INVESTIGATION_STATUS_DEFAULT_SET: 'Security Investigation Status Default Set',
    INVESTIGATION_STATUS_DEFAULT_REMOVED: 'Security Investigation Status Default Removed',
    INVESTIGATION_DETAILS_UPDATED: 'Security Investigation Details Updated',
    INVESTIGATION_DRAWER_OPENED: 'Security Investigation Drawer Opened',
    INVESTIGATION_DRAWER_CLOSED: 'Security Investigation Drawer Closed',
    INVESTIGATION_DRAWER_COLLAPSE_TOGGLED: 'Security Investigation Drawer Collapse Toggled',
    INVESTIGATION_NOTE_ADDED: 'Security Investigation Note Added',
    INVESTIGATION_NOTE_SAVED: 'Security Investigation Note Saved',
    INVESTIGATION_NOTE_EDIT: 'Security Investigation Note Updated',
    INVESTIGATION_NOTE_DELETED: 'Security Investigation Note Deleted',
    INVESTIGATION_DETAILS_SECTION_OPENED: 'Security Investigation Details Section Opened',
    INVESTIGATION_TIMELINE_FILTER_UPDATED: 'Security Investigation Timeline Filter Updated',
    INVESTIGATION_TIMELINE_CAROUSEL_NAVIGATED: 'Security Investigation Timeline Carousel Navigated',
    INVESTIGATION_REPORT_BY_AI: {
      TERMS_AND_CONDITIONS_CONSENT: 'Investigation Report By AI Terms And Conditions Consent',
      TERMS_AND_CONDITIONS_CANCELED: 'Investigation Report By AI Terms And Conditions Canceled',
      GET_REPORT_SUCCESS: 'Investigation Report By AI Get Report Success',
      GET_REPORT_FAILED: 'Investigation Report By AI Get Report Failed',
      USER_SURVEY: 'Investigation Report By AI User Survey',
    },
    SECURITY_EVENTS: {
      MITRE_ATTACK_WIDGET: {
        DRILLDOWN: 'Security Events Mitre Att&ck Widget Exported Drilldown',
      },
      EXPORT_CATEGORIES_HISTOGRAM: 'Security Events Mitre Att&ck Widget Exported',
    },
    SIGMA_IMPORT_RULES_OPENED: 'Security Sigma Rules Import Opened',
    SIGMA_IMPORT_RULES_IMPORTED: 'Security Sigma Rules Imported',
    SIGMA_RULE_UPDATED: 'Security Sigma Rules Update',
    SIGMA_RULE_CREATED: 'Security Sigma Rules Created',
    ANOMALY_DETECTION_SAVED: 'Security Anomaly Detection Saved',
    ANOMALY_DETECTION_DELETED: 'Security Anomaly Detection Deleted',
    THREAT_COVERAGE: {
      IMPROVE_COVERAGE_EXPLANATION_OPENED: 'Security Threat Improve Coverage Details Opened',
      IMPROVE_COVERAGE_STEP_OPENED: 'Security Threat Improve Coverage Step Opened',
      IMPROVE_COVERAGE_STEP_NEXT: 'Security Threat Improve Coverage Step Next Button Clicked',
      IMPROVE_COVERAGE_STEP_BACK: 'Security Threat Improve Coverage Step Back Button Clicked',
      TACTIC_DETAILS_OPENED: 'Security Threat Coverage Tactic Details Opened',
      TACTIC_DETAILS_CLOSED: 'Security Threat Coverage Tactic Details Closed',
      TECHNIQUE_DETAILS_OPENED: 'Security Threat Coverage Technique Details Opened',
      TECHNIQUE_DETAILS_CLOSED: 'Security Threat Coverage Technique Details Closed',
    },
  },
  TEAM: {
    CREATED: 'Team Created',
    ASSIGNED: 'Team Assigned',
    UNASSIGNED: 'Team Unassigned',
    DELETED: 'Team Deleted',
  },
  DATALAKE: {
    ENABLE_DATALAKE_CLICKED: 'Data Lake Enabled Clicked',
    DATA_RETRIEVAL_OPENED: 'Data Lake Data Retrieval Opened',
    DATA_DELETION_OPENED: 'Data Lake Data Deletion Opened',
    RETRIEVAL_OPERATION_DELETE_OPENED: 'Data Lake Retrieval Operation Delete Opened',
    RETRIEVAL_OPERATION_VIEW_CLICKED: 'Data Lake Retrieval Operation View Clicked',
    CONFIGURATION_SAVED: 'Data Lake Configuration Saved',
    BACKEND_EDIT_OPENED: 'Data Lake Backend Edit Opened',
    BACKEND_CREATE_OPENED: 'Data Lake Backend Create Opened',
    RETENTION_CONFIG_SAVED: 'Data Lake Retention Config Saved',
    PREVIEW: {
      SEARCH_TAKES_LONGER: 'Data Lake Preview Search took longer than 10 seconds.',
      RESET_BUTTON_CLICKED: 'Data Lake Preview Reset Button Clicked',
      SUBMIT_FIELD_FILTERS_FORM: 'Data Lake Preview Field Filters Form Submit',
      CLEAR_FIELD_FILTERS: 'Data Lake Preview Clear Field Filters',
      START_NEW_SEARCH_WHILE_LOADING: 'Data Lake Preview Start New Search While Search Is Running',
      WIDGET_FIELDS_UPDATE: 'Data Lake Preview Widget Fields Update',
      WIDGET_REEXECUTE_SEARCH: 'Data Lake Preview Widget Re-execute Search',
      CANCEL_SEARCH: 'Data Lake Preview Widget Cancel Search',
      APPLY_FILTER_FROM_EXPIRED_SEARCH: 'Data Lake Preview Apply Filter From Expired Search',
      RETRIEVE_LOGS_BUTTON_CLICK: 'Data Lake Preview Retrieve Logs Button Click',
      RETRIEVE_LOGS_BULK_ACTION_CLICK: 'Data Lake Preview Retrieve Logs Bulk Action Click',
    },
  },
  TRAFFIC_VIOLATION_CONFIGURATION_SAVED: 'Traffic Violation Configuration Saved',
} as const;
