const watchlists = {
  ips: {
    title: 'IP Watchlist',
    fieldType: 'ip',
  },
  users: {
    title: 'User Watchlist',
    fieldType: 'user',
  },
  hashes: {
    title: 'File Hash Watchlist',
    fieldType: 'hash',
  },
};
export default watchlists;
