import * as React from 'react';
import { useMemo, useContext } from 'react';

import { AdditionalContext } from 'views/logic/ActionContext';
import type { Message } from 'views/components/messagelist/Types';
import useLicenseCheck from 'license/useLicenseCheck';
import WatchlistStatusProvider from 'watchlist/contexts/WatchlistStatusProvider';
import WatchlistStatusContext from 'watchlist/contexts/WatchlistStatusContext';

const ExtendAdditionalContext = ({ children }: { children: React.ReactNode }) => {
  const licenseCheck = useLicenseCheck();
  const watchlistStatus = useContext(WatchlistStatusContext);
  const additionalContextValue = useMemo(
    () => ({
      licenseCheck,
      watchlistStatus,
    }),
    [licenseCheck, watchlistStatus],
  );

  return <AdditionalContext.Provider value={additionalContextValue}>{children}</AdditionalContext.Provider>;
};

type Props = {
  children: React.ReactNode;
  message: Message;
};

const MessageDetailsAdditionalProvider = ({ children, message }: Props) => (
  <WatchlistStatusProvider message={message}>
    <ExtendAdditionalContext>{children}</ExtendAdditionalContext>
  </WatchlistStatusProvider>
);

export default MessageDetailsAdditionalProvider;
