import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import getEventTypeCategories from 'search/contexts/api/eventTypeCategories';
import useLicenseCheck from 'license/useLicenseCheck';
import type { EventCategories } from 'security-content/types';
import { defaultOnError } from 'util/conditional/onError';

import MessageSummaryTemplateContext from './MessageSummaryTemplateContext';

type Props = {
  children: React.ReactNode;
};

export default function MessageSummaryTemplateProvider({ children }: Props) {
  const {
    security: { isValid: isValidSecurityLicense },
  } = useLicenseCheck();

  const {
    isLoading,
    isError,
    error,
    data: eventTypeCategories,
  } = useQuery<EventCategories, Error>(
    ['eventTypeCategories'],
    () => defaultOnError(getEventTypeCategories(), 'Error fetching event categories'),
    {
      enabled: isValidSecurityLicense,
    },
  );

  const contextValue = React.useMemo(
    () => ({
      isLoading: isLoading,
      isError: isError,
      error: error,
      eventTypeCategories: eventTypeCategories,
    }),
    [isLoading, isError, error, eventTypeCategories],
  );

  return (
    <MessageSummaryTemplateContext.Provider value={contextValue}>{children}</MessageSummaryTemplateContext.Provider>
  );
}
