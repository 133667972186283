import type { DefaultTheme } from 'styled-components';

const indicatorStatusColor = (indicatorStatus: string, theme: DefaultTheme) => {
  const {
    colors: {
      variant: { darker: themeColors },
    },
  } = theme;
  const defaultColor = themeColors.info;
  const colorMapping = {
    info: themeColors.info,
    primary: themeColors.primary,
    success: themeColors.success,
    warning: themeColors.warning,
    danger: themeColors.danger,
    default: themeColors.default,
    gray: themeColors.gray,
  };

  return colorMapping[indicatorStatus] ?? defaultColor;
};

export default indicatorStatusColor;
