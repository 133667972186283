import fetch from 'logic/rest/FetchProvider';
import inputsUrl from 'forwarder/forwarders/inputsUrl';

const fetchForwarderNode = (nodeId: string) =>
  fetch(
    'GET',
    inputsUrl({
      segments: ['forwarders', 'byNodeId', nodeId],
    }),
  ).then((response) => response?.forwarder);

export default fetchForwarderNode;
