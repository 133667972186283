import type { BackendReport, Report, BackendReportWidget } from 'report/types';
import _deserializeWidgetConfig from 'common/utils/deserializeWidgetConfig';
import type ParameterBinding from 'views/logic/parameters/ParameterBinding';

const deserializeBackendReport = ({
  widgets,
  parameter_values: parameterValues,
  layout,
  hide_widget_description: hideWidgetDescription,
  hide_widget_query: hideWidgetQuery,
  ...rest
}: BackendReport): Report => ({
  ...rest,
  layout: layout
    ? {
        format: layout.format,
        pageSize: layout.page_size,
        orientation: layout.orientation,
        header: layout.header,
        footer: layout.footer,
        printHeader: layout.print_header,
        printFooter: layout.print_footer,
        printPageNumbers: layout.print_page_numbers,
        printToc: layout.print_toc,
      }
    : undefined,
  widgets: widgets.map((widget) => ({ ...widget, config: _deserializeWidgetConfig<BackendReportWidget>(widget) })),
  hideWidgetDescription,
  hideWidgetQuery,
  parameterValues: Object.entries(parameterValues)
    .map(([name, { value }]: [string, ParameterBinding]) => [name, value])
    .reduce((prev, [name, value]) => ({ ...prev, [name]: value }), {}),
});

export default deserializeBackendReport;
