import React from 'react';

import { adjustFormat } from 'util/DateTime';
import type { DateTime } from 'util/DateTime';

type Props = {
  dateTime: string;
};

const DateTimeCell = ({ dateTime }: Props) => {
  const formatDateTime = (dateTimeToFormat: DateTime): string => adjustFormat(dateTimeToFormat, 'date');

  return <p>{formatDateTime(dateTime)}</p>;
};

export default DateTimeCell;
