import * as React from 'react';

import SectionComponent from 'components/common/Section/SectionComponent';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import { Button, Alert, ButtonToolbar } from 'components/bootstrap';
import { ReadOnlyFormGroup } from 'components/common';
import type GroupSyncBackend from 'authentication/logic/directoryServices/GroupSyncBackend';
import LicenseCheck from 'license/LicenseCheck';

type Props = {
  backendId: string;
  groupSyncBackend: GroupSyncBackend;
  getReadableSelectionType: (type: string) => string;
};

const OidcConfigSection = ({ backendId, groupSyncBackend, getReadableSelectionType }: Props) => {
  const { config, selectionType } = groupSyncBackend || {};

  return (
    <SectionComponent
      title="Group Synchronization"
      headerActions={
        <ButtonToolbar>
          <LinkContainer to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.edit(backendId, 'group_sync')}>
            <Button bsStyle="success" bsSize="small">
              Edit
            </Button>
          </LinkContainer>
        </ButtonToolbar>
      }>
      {groupSyncBackend ? (
        <LicenseCheck>
          <ReadOnlyFormGroup label="Groups Claim" value={config?.groupsClaim} />
          <ReadOnlyFormGroup label="Selection Type" value={getReadableSelectionType(selectionType)} />
        </LicenseCheck>
      ) : (
        <Alert>OIDC Group synchronization not configured for this authentication service.</Alert>
      )}
    </SectionComponent>
  );
};

export default OidcConfigSection;
