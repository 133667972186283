import React, { useContext, useState, useEffect } from 'react';

import Select from 'components/common/Select';
import { Input } from 'components/bootstrap';

import useFetch from './common/hooks/useFetch';
import { ApiRoutes } from './common/Routes';
import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';
import INITIAL_FORMDATA from './_initialFormData';

import FormWrap from '../common/FormWrap';

type StepSubscribeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepSubscribe = ({ onSubmit, onChange }: StepSubscribeProps) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const { pollingInterval, o365DropSensitiveDlpLogs } = formData;

  const availableContent = [
    { label: 'Azure ActiveDirectory', value: 'AZURE_ACTIVE_DIRECTORY' },
    { label: 'SharePoint', value: 'SHAREPOINT' },
    { label: 'Exchange', value: 'EXCHANGE' },
    { label: 'General', value: 'GENERAL' },
    { label: 'DLP', value: 'DLP_ALL' },
  ];

  const [contentTypes, setContentTypes] = useState(INITIAL_FORMDATA.availableContents.value);

  const [startSubscription, setStartSubscription] = useFetch(null, onSubmit, 'POST', {
    o365_tenant_id: formData.tenantId?.value,
    o365_client_id: formData.clientId?.value,
    o365_client_secret: formData.clientSecret?.value,
    o365_endpoint: formData.subscriptionType?.value || 'Enterprise and GCC government plans',
    o365_azuread_records: formData.contents?.value.includes('AZURE_ACTIVE_DIRECTORY'),
    o365_exchange_records: formData.contents?.value.includes('EXCHANGE'),
    o365_dlpall_records: formData.contents?.value.includes('DLP_ALL'),
    o365_general_records: formData.contents?.value.includes('GENERAL'),
    o365_sharepoint_records: formData.contents?.value.includes('SHAREPOINT'),
  });

  useEffect(() => {
    setStartSubscription(null);

    if (startSubscription.error) {
      setFormError({
        full_message: startSubscription.error,
        nice_message: 'Unable to start subscription at this moment.',
      });
    }

    return () => {
      setFormError(null);
    };
  }, [startSubscription.error]);

  const handleSubmit = () => {
    let error = false;
    formData.contents.value = contentTypes;

    if (pollingInterval.value < 1) {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message: 'Minimum allowable polling interval is 1 minute.',
      });

      error = true;
    }

    if (!error) {
      setFormError(null);
      setStartSubscription(ApiRoutes.INTEGRATIONS.O365.START_SUBSCRIPTION);
    }
  };

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Start Subscription &amp; Proceed"
      title=""
      error={formError}
      loading={startSubscription.loading}
      description="">
      <label className="control-label" htmlFor="contents">
        Logs Types to Collect
      </label>
      <Select
        multi
        name="contents"
        id="contents"
        onChange={(selected) => setContentTypes(selected)}
        options={availableContent}
        value={contentTypes}
        matchProp="label"
      />
      <span className="help-block">The activity log for the above content types will be fetched.</span>

      <Input
        id="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue}
        min="1"
        max="60"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in O365. The smallest allowable interval is 1 minute."
        label="Polling Interval"
      />

      <Input
        id="o365DropSensitiveDlpLogs"
        type="checkbox"
        value="enable-dropSensitiveDlpLogs"
        defaultChecked={o365DropSensitiveDlpLogs?.value}
        onChange={onChange}
        label="Drop DLP logs containing sensitive data"
        help="For each DLP event, O365 emits a summary log with no sensitive data and a detailed log with sensitive data.  When set, this option will cause the detailed logs to be dropped to prevent sensitive data from being stored in Graylog."
      />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
