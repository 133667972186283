import InputFailureLink from 'input-setup-wizard/input-diagnosis/InputFailureLink';

import Wizard from './Wizard';
import StartIlluminate from './StartIlluminate';

export default {
  inputSetupWizard: {
    EnterpriseInputSetupWizard: Wizard,
    InputFailureLink: InputFailureLink,
    ExtraSetupWizardStep: StartIlluminate,
  },
};
