import useFeature from 'hooks/useFeature';
import useAIReportConfiguration from 'security-app/components/Investigations/AIReport/hooks/useAIReportConfiguration';

const useIsInvestigationAIReportEnabled = () => {
  const isAIReportFeatureEnabled = useFeature('investigation_report_by_ai');
  const {
    data: { isEnabled },
  } = useAIReportConfiguration();

  return isEnabled && isAIReportFeatureEnabled;
};

export default useIsInvestigationAIReportEnabled;
