import React, { useContext, useEffect, useState } from 'react';

import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';
import type { ErrorMessageType, HandleFieldUpdateType, FormDataContextType, HandleSubmitType } from './types';
import useFetch from './common/hooks/useFetch';

import { renderOptions } from '../common/Options';
import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { crowdstrikeClientSecret } = formData;

  const region = [
    { label: 'US-1 Cloud', value: 'https://api.crowdstrike.com' },
    { label: 'US-2 Cloud', value: 'https://api.us-2.crowdstrike.com' },
    { label: 'EU Cloud', value: 'https://api.eu-1.crowdstrike.com' },
    { label: 'Government Cloud', value: 'https://api.laggar.gcw.crowdstrike.com' },
  ];

  const [formValidated, setFormValidated] = useState('');

  const fieldChange = (event) => {
    if (event) {
      setFormValidated(event.target.value);
    }

    onChange(event, null);
  };

  const [CrowdStrikeValidationStatus, setCrowdStrikeValidation] = useFetch(null, onSubmit, 'POST', {
    crowdstrike_client_id: formData?.crowdstrikeClientId?.value || '',
    crowdstrike_client_secret: formData?.crowdstrikeClientSecret?.value || '',
    crowdstrike_base_url: formData?.crowdstrikeBaseUrl?.value || '',
  });

  useEffect(() => {
    setCrowdStrikeValidation(null);

    if (CrowdStrikeValidationStatus.error) {
      setFormError({
        full_message: CrowdStrikeValidationStatus.error,
        nice_message: 'Unable to connect to CrowdStrike using provided configuration.',
      });
    }

    return () => {
      setFormError(null);
    };
  }, [CrowdStrikeValidationStatus.error, setCrowdStrikeValidation]);

  const handleSubmit = () => {
    setCrowdStrikeValidation(ApiRoutes.INTEGRATIONS.CrowdStrike.CHECK_CREDENTIALS);
  };

  const isFormValid = formValidation.isFormValid(
    ['crowdstrikeName', 'crowdstrikeClientId', 'crowdstrikeClientSecret', 'crowdstrikeBaseUrl'],
    formData,
  );

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      loading={CrowdStrikeValidationStatus.loading}
      title=""
      error={formError}
      description="">
      <ValidatedInput
        className=""
        id="crowdstrikeName"
        type="text"
        fieldData={formData.crowdstrikeName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={crowdstrikeClientSecret?.value}
        required
      />

      <ValidatedInput
        className=""
        id="crowdstrikeClientId"
        type="text"
        fieldData={formData.crowdstrikeClientId}
        onChange={onChange}
        label="CrowdStrike Client ID"
        help="The GUID of your application that created the subscription."
        autoComplete="off"
        required
      />

      <ValidatedInput
        className=""
        id="crowdstrikeClientSecret"
        type="password"
        onChange={onChange}
        fieldData={formData.crowdstrikeClientSecret}
        help="A secret string that the application uses to prove its identity when requesting a token."
        label="Client Secret"
        required
      />

      <ValidatedInput
        type="select"
        id="crowdstrikeBaseUrl"
        onChange={(e) => fieldChange(e)}
        fieldData={formData.crowdstrikeBaseUrl}
        help="CrowdStrike User Account Region."
        required
        label="User Region">
        {renderOptions(region, 'Choose your account region', false)}
      </ValidatedInput>
      <p>{formValidated && `Your Cloud Base Url: ${formValidated}`}</p>
    </FormWrap>
  );
};

export default StepAuthorize;
