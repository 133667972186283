import type { SyntheticEvent } from 'react';
import React from 'react';

import { Input } from 'components/bootstrap';
import type { LookupTableDataAdapterConfig } from 'logic/lookup-tables/types';

type Props = {
  config: LookupTableDataAdapterConfig;
  handleFormEvent: (event: SyntheticEvent<EventTarget>) => void;
};

function MongoDBDataAdapterFieldSet({ config, handleFormEvent }: Props) {
  return (
    <fieldset>
      <Input
        type="checkbox"
        id="cidr_lookup"
        name="cidr_lookup"
        label="CIDR lookup"
        checked={config.cidr_lookup}
        onChange={handleFormEvent}
        help="Enable if the keys in the lookup table are in CIDR notation and lookups will be done with IPs"
        wrapperClassName="col-md-offset-3 col-md-9"
      />
    </fieldset>
  );
}

export default MongoDBDataAdapterFieldSet;
