import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';
import type { GitRepoContent } from 'security-app/hooks/api/sigmaAPI.types';

export const InfoButtonStyled = styled.span`
  padding: 0;
  margin: 0;
  opacity: 0.3;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const RuleDescription = styled.div<{ $mousePos: { x: number; y: number } }>(
  ({ $mousePos, theme }) => css`
    position: fixed;
    top: ${$mousePos?.y || 0}px;
    left: ${$mousePos?.x || 0}px;
    margin-top: 23px;
    margin-left: 8px;
    padding: 8px;
    max-width: 600px;

    opacity: 0;

    white-space: pre-line;

    border: 1px solid ${theme.colors.input.border};
    border-radius: 4px;
    background-color: ${theme.colors.global.background};
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.5);

    overflow: hidden;

    z-index: 20;

    &.show {
      animation: 0.3s linear show;
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
    }

    @keyframes show {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  `,
);

const SPACE_FROM_POINTER = 8;

type Props = {
  rule: GitRepoContent;
};

function RuleInfoTooltip({ rule }: Props) {
  const [mousePos, setMousePos] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  const onMouseEnter = (event: React.MouseEvent) => {
    const { x, y } = (event.target as HTMLElement).getBoundingClientRect();
    setMousePos({ x: x + SPACE_FROM_POINTER, y: y + SPACE_FROM_POINTER });
    setShowTooltip(true);
  };

  return (
    <>
      <InfoButtonStyled
        data-testid="info-button"
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setShowTooltip(false)}>
        <Icon data-testid="more-info" style={{ cursor: 'pointer' }} name="help" size="sm" />
      </InfoButtonStyled>
      {showTooltip && (
        <RuleDescription $mousePos={mousePos} className={showTooltip ? 'show' : ''} data-testid="rule-description">
          <p>
            <b>File Name</b>: {rule.name}
          </p>
          <p>
            <b>Description</b>: {rule.rule_description}
          </p>
          {!!rule.product && (
            <p>
              <b>Product</b>: {rule.product}
            </p>
          )}
          {!!rule.tags && (
            <p>
              <b>Tags</b>: {rule.tags.split(' ').join(', ')}
            </p>
          )}
        </RuleDescription>
      )}
    </>
  );
}

export default RuleInfoTooltip;
