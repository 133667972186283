// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oioTs1UzztexPcXCEJTI {
  height: 100%;
  /* The grid prop is a workaround to fix
     * horizontal scrolling for safari */
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

.xgtbFtho5xU7DcYtL10p {
  overflow: auto;
  grid-row: 1;
  grid-column: 1;
}

@media print {
  table.messages {
    font-size: 1rem !important; /* theme.fonts.size.body */
    min-width: 50%;
    padding-left: 0;
  }

  table.messages th {
    font-weight: bold !important;
    font-size: inherit !important;
  }

  table.messages th,
  table.messages td {
    border: 1px #ccc solid !important;
    left: 0;
    padding: 5px;
    position: static;
  }
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/views/components/datatable/DataTable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ;wCACsC;EACtC,aAAa;EACb,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,WAAW;EACX,cAAc;AAChB;;AAEA;EACE;IACE,0BAA0B,EAAE,0BAA0B;IACtD,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,4BAA4B;IAC5B,6BAA6B;EAC/B;;EAEA;;IAEE,iCAAiC;IACjC,OAAO;IACP,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[":local(.container) {\n  height: 100%;\n  /* The grid prop is a workaround to fix\n     * horizontal scrolling for safari */\n  display: grid;\n  grid-template-rows: 1fr;\n  grid-template-columns: 1fr;\n}\n\n:local(.scrollContainer) {\n  overflow: auto;\n  grid-row: 1;\n  grid-column: 1;\n}\n\n@media print {\n  table.messages {\n    font-size: 1rem !important; /* theme.fonts.size.body */\n    min-width: 50%;\n    padding-left: 0;\n  }\n\n  table.messages th {\n    font-weight: bold !important;\n    font-size: inherit !important;\n  }\n\n  table.messages th,\n  table.messages td {\n    border: 1px #ccc solid !important;\n    left: 0;\n    padding: 5px;\n    position: static;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `oioTs1UzztexPcXCEJTI`,
	"scrollContainer": `xgtbFtho5xU7DcYtL10p`
};
export default ___CSS_LOADER_EXPORT___;
