import React from 'react';
import styled, { css } from 'styled-components';

import { Col, Row } from 'components/bootstrap';
import { Icon } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import AbsoluteCalendarInput from 'data-lake/components/AbsoluteCalendarInput';

type Props = {
  timerange?: AbsoluteTimeRange;
  disableTimeMinute?: boolean;
  disableTimeSecond?: boolean;
};
const Timezone = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fonts.size.small};
    padding-left: ${theme.spacings.xs};
    margin: 0;
    min-height: 34px;
    display: flex;
    align-items: center;
  `,
);

const AbsoluteWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
`;

const RangeWrapper = styled.div`
  flex: 4;
  align-items: center;
  display: flex;
  flex-direction: column;

  .DayPicker-wrapper {
    padding-bottom: 0;
  }
`;

const IconWrap = styled.div`
  flex: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-0.65em);
`;

const TimeRangeSelectInput = ({
  timerange = {
    type: 'absolute',
    from: new Date().toString(),
    to: new Date().toString(),
  },
  disableTimeMinute = false,
  disableTimeSecond = false,
}: Props) => {
  const { userTimezone } = useUserDateTime();

  return (
    <div>
      <Row>
        <Col md={12}>
          <AbsoluteWrapper>
            <RangeWrapper>
              <AbsoluteCalendarInput
                nextTimeRange={timerange}
                range="from"
                disableTimeMinute={disableTimeMinute}
                disableTimeSecond={disableTimeSecond}
              />
            </RangeWrapper>

            <IconWrap>
              <Icon name="arrow_right_alt" />
            </IconWrap>

            <RangeWrapper>
              <AbsoluteCalendarInput
                nextTimeRange={timerange}
                range="to"
                disableTimeMinute={disableTimeMinute}
                disableTimeSecond={disableTimeMinute}
              />
            </RangeWrapper>
          </AbsoluteWrapper>
        </Col>
      </Row>
      <Row className="row-sm">
        <Col md={6}>
          <Timezone>
            All timezones using: <b>{userTimezone}</b>
          </Timezone>
        </Col>
      </Row>
    </div>
  );
};

export default TimeRangeSelectInput;
