import * as React from 'react';
import styled, { css } from 'styled-components';

import { adjustFormat } from 'util/DateTime';
import type { DateTime } from 'util/DateTime';
import NumberUtils from 'util/NumberUtils';
import { Col, Row } from 'components/bootstrap';
import { Spinner } from 'components/common';
import useLicenseMetrics from 'license/hooks/useLicenseMetrics';

const StyledMetricsSection = styled(Row)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.md};
  `,
);

const StyledDl = styled.dl`
  margin: 0;

  dt {
    float: left;
    clear: left;
  }

  dd {
    margin-left: 260px;
  }
`;

const ActiveDrawDownLicenseMetrics = () => {
  const {
    isLoading: isLoadingLicenseMetrics,
    isError: isErrorLicenseMetrics,
    data: licenseMetricsData,
  } = useLicenseMetrics();

  const formatDateTime = (dateTimeToFormat: DateTime): string => adjustFormat(dateTimeToFormat, 'date');

  if (isLoadingLicenseMetrics) return <Spinner />;

  if (isErrorLicenseMetrics || !licenseMetricsData) return null;

  return (
    <StyledMetricsSection>
      <Col xs={6}>
        <StyledDl>
          {licenseMetricsData.current_day !== undefined && (
            <>
              <dt>Cluster Usage, Last 24h:</dt>
              <dd>{NumberUtils.formatBytes(licenseMetricsData.current_day)}</dd>
            </>
          )}
          {licenseMetricsData.last_7_days !== undefined && (
            <>
              <dt>Cluster Usage, Last 7 days:</dt>
              <dd>{NumberUtils.formatBytes(licenseMetricsData.last_7_days)}</dd>
            </>
          )}
          {licenseMetricsData.last_30_days !== undefined && (
            <>
              <dt>Cluster Usage, Last 30 days:</dt>
              <dd>{NumberUtils.formatBytes(licenseMetricsData.last_30_days)}</dd>
            </>
          )}
          {licenseMetricsData.last_365_days !== undefined && (
            <>
              <dt>Cluster Usage, Last 365 days:</dt>
              <dd>{NumberUtils.formatBytes(licenseMetricsData.last_365_days)}</dd>
            </>
          )}
        </StyledDl>
      </Col>
      <Col xs={6}>
        <StyledDl>
          {licenseMetricsData.percent_remaining !== undefined && (
            <>
              <dt>Remaining Provision:</dt>
              <dd>{licenseMetricsData.percent_remaining}%</dd>
            </>
          )}
          {licenseMetricsData.percent_usage_30_days !== undefined && (
            <>
              <dt>Provision Usage, last 30 days:</dt>
              <dd>{licenseMetricsData.percent_usage_30_days}%</dd>
            </>
          )}
          {licenseMetricsData.estimated_days_to_depletion !== undefined && (
            <>
              <dt>Estimated License depletion (Days):</dt>
              <dd>{licenseMetricsData.estimated_days_to_depletion}</dd>
            </>
          )}
          {licenseMetricsData.estimated_depletion_date !== undefined && (
            <>
              <dt>Estimated License depletion (Date):</dt>
              <dd>{formatDateTime(licenseMetricsData.estimated_depletion_date)}</dd>
            </>
          )}
        </StyledDl>
      </Col>
    </StyledMetricsSection>
  );
};

export default ActiveDrawDownLicenseMetrics;
