import * as React from 'react';
import { useMemo, useContext, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import ReportingWidget from 'report/common/ReportingWidget';
import { RenderCompletionObserver } from 'components/visualizations';
import type { Report, WidgetRef, BackendReportWidget } from 'report/types';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import defaultWidgetTitle from 'views/components/defaultTitle';
import Spinner from 'components/common/Spinner';
import WidgetRenderingContext from 'views/components/widgets/WidgetRenderingContext';
import { WIDGET_WIDTH, WIDGET_ASPECT_RATIO, reportWidgetHeaderId } from 'report/Constants';
import WidgetResultContext from 'report/report-creation/WidgetResultContext';
import { Alert } from 'components/bootstrap';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';
import Heading from 'common/components/widgetRenderers/Heading';
import WidgetTitle from 'report/common/WidgetTitle';

const Visualization = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const widgetTitle = (widget: WidgetRef, completeWidget: BackendReportWidget | undefined) => {
  if (widget?.title?.trim()) {
    return widget.title;
  }

  if (completeWidget) {
    return completeWidget?.description ?? defaultWidgetTitle(completeWidget);
  }

  return undefined;
};

const DeletedWidget = ({ widgetId, dashboardId }: { widgetId: string; dashboardId: string }) => {
  const onRenderComplete = useContext(RenderCompletionCallback);

  useEffect(() => {
    onRenderComplete();
  }, [onRenderComplete]);

  return (
    <>
      <Heading
        title={<WidgetTitle isLoading={false} detailsAreAvailable={false} title={undefined} />}
        headerId={reportWidgetHeaderId(dashboardId, widgetId)}
      />
      <Alert bsStyle="danger" className="has-bm">
        This widget is not accessible. You are either missing the required permissions to view this widget or it has
        been removed from the related dashboard.
      </Alert>
    </>
  );
};

type WidgetProps = {
  height: number;
  hideDescription: boolean;
  hideQuery: boolean;
  widget: WidgetRef;
  width: number;
};

const Widget = ({ hideDescription, hideQuery, height, width, widget }: WidgetProps) => {
  const { widgetId } = widget;
  const widgetSearchResult = useContext(WidgetResultContext);
  const { widgets: widgetDetails, isLoading } = useContext(WidgetDetailsContext);
  const completeWidget = widgetDetails[widgetId];
  const title = widgetTitle(widget, completeWidget);

  if (isLoading && !completeWidget) {
    return <Spinner />;
  }

  if (!isLoading && !completeWidget) {
    return <DeletedWidget widgetId={widgetId} dashboardId={widget.dashboardId} />;
  }

  return (
    <Visualization>
      <ReportingWidget
        widget={completeWidget}
        value={widgetSearchResult}
        showHandle={false}
        interactive={false}
        height={height}
        width={width}
        hideDescription={hideDescription}
        hideQuery={hideQuery}
        title={title}
        description={widget.description}
      />
    </Visualization>
  );
};

type Props = {
  hideWidgetDescription: boolean;
  hideWidgetQuery: boolean;
  onWidgetRenderComplete: (widgetId: string) => () => void;
  orientation: Report['layout']['orientation'];
  positions: Report['positions'];
  widgetResultProvider: React.ComponentType<{ widgetId: string; dashboardId: string; children: React.ReactNode }>;
  widgets: Array<WidgetRef>;
  width?: number;
};

const widgetRendering = {
  limitHeight: false,
};

const ReportWidgets = ({
  hideWidgetDescription,
  hideWidgetQuery,
  onWidgetRenderComplete,
  orientation,
  positions,
  widgets,
  widgetResultProvider: WidgetResultProvider,
  width: defaultWidth,
}: Props) => {
  const _orientation = orientation ?? 'portrait';
  const width = defaultWidth ?? WIDGET_WIDTH[_orientation];
  const height = width * WIDGET_ASPECT_RATIO[_orientation];

  const sortedWidgets = useMemo(
    () =>
      widgets.sort((widgetA, widgetB) => {
        if (positions.length === 0) {
          return 0;
        }

        const positionA = positions.find((p) => p.dashboard_widget_id === widgetA.widgetId);
        const positionB = positions.find((p) => p.dashboard_widget_id === widgetB.widgetId);

        return positionA && positionB ? positionA.row - positionB.row : 0;
      }),
    [positions, widgets],
  );

  const handleRenderComplete = useCallback(
    (widgetId: string) => onWidgetRenderComplete(widgetId),
    [onWidgetRenderComplete],
  );

  return (
    <WidgetRenderingContext.Provider value={widgetRendering}>
      {sortedWidgets.map((widget) => (
        <WidgetResultProvider key={widget.widgetId} widgetId={widget.widgetId} dashboardId={widget.dashboardId}>
          <RenderCompletionObserver onRenderComplete={handleRenderComplete(widget.widgetId)}>
            <Widget
              widget={widget}
              key={widget.widgetId}
              height={height}
              width={width}
              hideDescription={hideWidgetDescription}
              hideQuery={hideWidgetQuery}
            />
          </RenderCompletionObserver>
        </WidgetResultProvider>
      ))}
    </WidgetRenderingContext.Provider>
  );
};

export default React.memo(ReportWidgets);
