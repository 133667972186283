import Reflux from 'reflux';

import fetch from 'logic/rest/FetchProvider';
import { singletonStore, singletonActions } from 'logic/singleton';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { Status } from 'archive/types';
import type { CustomThemesColors } from 'customization/types';

export type ActionsType = {
  updateTheme: (theme: CustomThemesColors) => Promise<CustomThemesColors>;
  loadTheme: () => Promise<CustomThemesColors>;
};

export const CustomizationActions = singletonActions('Customizations', () =>
  Reflux.createActions<ActionsType>({
    updateTheme: { asyncResult: true },
    loadTheme: { asyncResult: true },
    createPublicNotification: { asyncResult: true },
    updatePublicNotification: { asyncResult: true },
    loadPublicNotification: { asyncResult: true },
    loadAllPublicNotifications: { asyncResult: true },
    deletePublicNotification: { asyncResult: true },
  }),
);

type CustomizationStoreState = {
  status: Status;
  missing: boolean;
  loading: boolean;
};

export const CustomizationStore = singletonStore('Customization', () =>
  Reflux.createStore<CustomizationStoreState>({
    listenables: [CustomizationActions],
    _state: {
      status: undefined,
      missing: false,
      loading: false,
    },

    getInitialState() {
      const { status, missing, loading } = this._state;

      return {
        status,
        missing,
        loading,
      };
    },

    updateTheme(theme: CustomThemesColors): Promise<void> {
      const url = qualifyUrl('/plugins/org.graylog.plugins.customization/theme');
      const promise = fetch('POST', url, theme);
      CustomizationActions.updateTheme.promise(promise);

      return promise;
    },

    loadTheme(): Promise<CustomThemesColors> {
      const url = qualifyUrl('/plugins/org.graylog.plugins.customization/theme');
      const promise = fetch('GET', url);

      CustomizationActions.loadTheme.promise(promise);

      return promise;
    },

    _errorHandler(message, title) {
      return (error) => {
        let errorMessage;

        try {
          errorMessage = error.additional.body.message;
        } catch (e) {
          errorMessage = error.message;
        }

        UserNotification.error(`${message}: ${errorMessage}`, title);
      };
    },

    _updateState({ status, missing, loading }) {
      this._state = {
        status,
        missing,
        loading,
      };
    },
  }),
);

export default CustomizationStore;
