// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tMxw8Z1N4W2__QwHPbzK {
  padding-left: 0;
  margin-bottom: 20px;
}

.tMxw8Z1N4W2__QwHPbzK .tMxw8Z1N4W2__QwHPbzK {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/common/ExpandableList.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[":local(.list) {\n  padding-left: 0;\n  margin-bottom: 20px;\n}\n\n:local(.list) :local(.list) {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `tMxw8Z1N4W2__QwHPbzK`
};
export default ___CSS_LOADER_EXPORT___;
