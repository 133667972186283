import styled, { css } from 'styled-components';
import chroma from 'chroma-js';

const checkmarkIcon = (color) =>
  `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cpath fill="${encodeURIComponent(color)}" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/%3E%3C/svg%3E')`;

const OktaContainerStyles = styled.div(({ theme }) => {
  const { colors, fonts, utils } = theme;
  const alertBorderColor = colors.variant.lighter.danger;
  const alertBackgroundColor = colors.variant.lightest.danger;

  const mixColor = (color1, color2 = colors.global.textDefault, ratio = 0.15) =>
    chroma.mix(color1, color2, ratio).rgba();

  const variantColor = colors.variant.info;
  const buttonAdjustColor =
    chroma(variantColor).luminance() > 0.5 ? colors.global.textSecondary : colors.global.textSecondary;

  const defaultBackground = colors.brand.primary;
  const defaultBorder = mixColor(defaultBackground, buttonAdjustColor, 0.05);
  const defaultColor = utils.contrastingColor(defaultBackground);

  const activeBackground = mixColor(variantColor, buttonAdjustColor, 0.1);
  const activeBorder = mixColor(variantColor, buttonAdjustColor, 0.15);
  const activeColor = utils.contrastingColor(activeBackground);

  const disabledBackground = mixColor(variantColor, buttonAdjustColor, 0.2);
  const disabledBorder = mixColor(variantColor, buttonAdjustColor, 0.15);
  const disabledColor = utils.contrastingColor(disabledBackground, 'AA');

  const hoverBackground = mixColor(defaultBackground);
  const hoverBorderColor = mixColor(defaultBorder);
  const hoverColor = mixColor(defaultColor);

  const activeHoverBackground = mixColor(activeBackground);
  const activeHoverBorderColor = mixColor(activeBorder);
  const activeHoverColor = mixColor(activeColor);

  return css`
    .accessibility-text {
      display: block;
      height: 0;
      overflow: hidden;
    }

    .auth-container {
      outline-style: none;
    }

    .auth-footer {
      margin-top: 1em;

      ul#help-links-container {
        list-style-type: disc;
        margin-top: 0.5em;
        padding-left: 30px;
      }

      .link:not(:last-of-type) {
        margin-right: 1em;
      }
    }

    .okta-form-title {
      display: none;
      font-size: 1.266rem; /* theme.fonts.size.h4 */
      margin-bottom: 10px;
    }

    .o-form-input {
      margin-bottom: 6px;
    }

    .o-form-error-container.o-form-has-errors {
      /* bootstrap alert styles */
      padding: 15px;
      margin-bottom: 20px;
      border: 1px solid;
      border-radius: 4px;

      /* our styles */
      background-color: ${alertBackgroundColor};
      border-color: ${alertBorderColor};
      color: ${utils.contrastingColor(alertBackgroundColor)};

      a:not(.btn) {
        color: ${utils.contrastingColor(alertBackgroundColor, 'AA')};
        font-weight: bold;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
          color: ${utils.contrastingColor(alertBackgroundColor)};
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      &.alert-dismissible {
        .close {
          top: -9px;
        }
      }

      &.o-form-has-errors {
        display: block;
        margin-bottom: 1em;
      }

      p {
        margin: 0;
      }
    }

    .o-form-input-name-remember {
      display: block;
      margin: 12px 0;
    }

    .custom-checkbox,
    .custom-radio {
      label {
        display: inline;
        font-weight: initial;
      }

      input {
        margin-right: 6px;
        vertical-align: top;
      }
    }

    .o-form-input.o-form-has-errors {
      input {
        border-color: ${colors.variant.light.danger};
      }

      .okta-form-input-error {
        color: ${colors.variant.dark.danger};
        margin-top: 5px;
      }
    }

    form .link-button,
    .auth-footer .link-button {
      margin-top: 6px;
    }

    input[type='text'],
    input[type='password'],
    input[type='tel'],
    input[type='number'],
    input[type='email'] {
      /* bootstrap form-control styles */
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      background-image: none;
      border: 1px solid;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
      transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;

      /* our styles */
      color: ${colors.input.color};
      background-color: ${colors.input.background};
      border-color: ${colors.input.border};
      font-family: ${fonts.family.body};

      &::placeholder {
        color: ${colors.input.placeholder};
      }

      &:focus {
        outline: 0;
        border-color: ${colors.input.borderFocus};
        box-shadow: ${colors.input.boxShadow};
      }

      &[disabled],
      &[readonly],
      fieldset[disabled] & {
        background-color: ${colors.input.backgroundDisabled};
        color: ${colors.input.colorDisabled};
      }
    }

    input[type='submit'],
    .button {
      /* bootstrap btn styles */
      display: inline-block;
      margin-bottom: 0;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 4px;
      user-select: none;

      /* our styles */
      background-color: ${defaultBackground};
      border-color: ${defaultBorder};
      color: ${defaultColor};
      transition:
        background-color 150ms ease-in-out,
        border 150ms ease-in-out,
        color 150ms ease-in-out;

      &:hover,
      &:focus {
        text-decoration: none;

        background-color: ${hoverBackground};
        border-color: ${hoverBorderColor};
        color: ${hoverColor};
      }

      &[disabled],
      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        box-shadow: none;

        background-color: ${disabledBackground};
        border-color: ${disabledBorder};
        color: ${disabledColor};

        &:hover {
          background-color: ${disabledBackground};
          border-color: ${disabledBorder};
          color: ${disabledColor};
        }
      }

      &.active {
        background-color: ${activeBackground};
        border-color: ${activeBorder};
        color: ${activeColor};

        &:hover {
          background-color: ${activeHoverBackground};
          border-color: ${activeHoverBorderColor};
          color: ${activeHoverColor};
        }
      }
    }

    .factors-dropdown-wrap {
      margin-bottom: 1.5em;

      .dropdown-list-title {
        display: none;
      }
    }

    .okta-dropdown-list {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-clip: padding-box;
      border: 1px solid;
      border-radius: 4px;

      border-color: ${colors.input.border};
      background-color: ${colors.global.contentBackground};
      box-shadow: 0 3px 3px ${colors.global.navigationBoxShadow};

      > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        white-space: nowrap;
        text-decoration: none;

        color: ${colors.global.textDefault};

        &:hover {
          text-decoration: none;
          outline: 0;

          color: ${colors.variant.darker.default};
          background-color: ${colors.variant.lightest.default};
        }
      }

      > .active > a {
        text-decoration: none;
        outline: 0;

        color: ${colors.variant.darker.default};
        background-color: ${colors.variant.lightest.default};

        &:hover,
        &:focus {
          color: ${colors.variant.darkest.default};
          background-color: ${colors.variant.lighter.default};
        }
      }

      > .disabled > a {
        color: ${colors.variant.dark.default};
        background-color: ${colors.variant.lightest.default};

        &:hover,
        &:focus {
          text-decoration: none;
          cursor: not-allowed;

          color: ${colors.variant.dark.default};
          background-color: ${colors.variant.lightest.default};
        }
      }
    }

    .o-form-fieldset-container {
      & > *:last-child {
        margin-bottom: 1em;
      }

      & > .accessibility-text:last-child {
        margin-bottom: 0;
      }
    }

    .enroll-factor-list {
      ul.list-content {
        margin-top: 1em;
        padding-left: 0;

        li.enroll-factor-row {
          margin-bottom: 1em;
        }
      }
    }

    .o-form-input-group > .okta-form-input-field {
      display: flex;
      align-items: center;
      width: 100%;

      > .o-form-label-inline {
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        border: 1px solid;
        border-radius: 4px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        transition:
          border-color ease-in-out 0.15s,
          box-shadow ease-in-out 0.15s;

        /* our styles */
        color: ${colors.input.color};
        background-color: ${colors.input.background};
        border-color: ${colors.input.border};
        font-family: ${fonts.family.body};
      }

      > .o-form-label-inline + .o-form-control {
        flex-grow: 1;

        > input {
          border-left: 0;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }

    .okta-form-divider {
      border-top: 1px solid ${colors.global.background};
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .icon {
      &.success-16-green,
      &.success-16-gray {
        width: 1em;
        height: 1em;
        vertical-align: top;
        position: absolute;
        right: 15px;
      }

      &.success-16-green {
        background-image: ${checkmarkIcon(colors.variant.success)};
      }

      &.success-16-gray {
        background-image: ${checkmarkIcon(colors.variant.lighter.default)};
      }
    }

    .scan-instructions {
      .scan-instructions-qrcode-wrapper {
        text-align: center;
      }

      .link {
        padding: 0 0.5em;
      }

      .refresh-qrcode {
        display: none;
      }

      &.qrcode-expired {
        .manual-setup {
          display: none;
        }

        .refresh-qrcode {
          display: inline-block;
        }
      }

      &.qrcode-success {
        .manual-setup,
        .refresh-qrcode {
          display: none;
        }
      }
    }

    .mfa-verify-totp-inline {
      margin: 0.5em 0;
    }

    .mfa-email-sent-content {
      margin-bottom: 0.5em;
    }

    .number-challenge-view {
      display: none;

      .phone {
        text-align: center;
        margin: 20px 0;

        .phone--body {
          display: inline-block;
          border-radius: 6px;
          padding: 14px 3px 0;
          margin: 0 auto;
          background: ${mixColor(colors.brand.primary)};
        }

        .phone--screen {
          min-width: 50px;
          padding: 20px 0;
          background: ${colors.global.contentBackground};
        }

        .phone--number {
          padding: 10px;
          font-size: 25px;
          color: ${colors.global.textDefault};
        }

        .phone--home-button {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin: 3px auto;
          background: white;
        }
      }

      .challenge-number {
        text-align: center;
        font-weight: bold;
      }
    }
    .js-help-links {
      li:last-child {
        display: none;
      }
    }
  `;
});

export default OktaContainerStyles;
