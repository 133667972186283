import * as React from 'react';
import Immutable from 'immutable';
import { useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';

import WidgetRenderingContext from 'views/components/widgets/WidgetRenderingContext';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import ColorMapper from 'views/components/visualizations/ColorMapper';
import Icon from 'components/common/Icon';
import ChartColorContext from 'views/components/visualizations/ChartColorContext';
import type { SearchFilter } from 'views/types';
import PluggableStoreProvider from 'views/stores/ViewsStoreProvider';
import View from 'views/logic/views/View';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import ViewState from 'views/logic/views/ViewState';
import Query from 'views/logic/queries/Query';
import Search from 'views/logic/search/Search';
import Caption from 'common/components/widgetRenderers/Caption';
import WidgetDescription, { FallbackWidgetDescription } from 'common/components/widgetRenderers/WidgetDescription';
import ErrorBoundary from 'export/ErrorBoundary';
import type { TimeRange } from 'views/logic/queries/Query';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';
import { Spinner } from 'components/common';
import PrintingWidgetVisualizationContainer from 'report/common/PrintingWidgetVisualizationContainer';
import Heading from 'common/components/widgetRenderers/Heading';
import type { QueryString } from 'views/logic/queries/types';

const MARGIN_HEIGHT = 10;

export const Container = styled.div`
  font-size: 90%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    color: black;
  }

  @media print {
    padding: 0;
    page-break-inside: avoid;
  }
`;

const DragHandleIcon = styled(Icon)`
  font-size: 1.2em;
  position: absolute;
  left: 20px;
  top: 25px;

  @media print {
    display: none;
  }
`;

type Props<WT, VT> = {
  description?: string | undefined;
  title?: string;
  headerId?: string;
  height?: number;
  hideDescription: boolean;
  hideQuery: boolean;
  interactive?: boolean;
  limitHeight?: boolean;
  showCaption?: boolean;
  showHandle?: boolean;
  showHeading?: boolean;
  value: VT;
  widget: WT;
  widgetId: string;
  width?: number;
};

const executionState = SearchExecutionState.empty();
const view = View.builder()
  .state({ query: ViewState.create() })
  .search(
    Search.builder()
      .queries([Query.builder().id('query').build()])
      .build(),
  )
  .build();

const SpinnerContainer = styled.div<{ $height: number }>(
  ({ $height }) => css`
    width: 100%;
    height: ${$height ?? 300}px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export type ValueTypeBase = {
  calculatedAt: string | undefined;
  errorMessage: string | undefined;
  hasError: boolean;
  result: any;
  types: Immutable.List<FieldTypeMapping>;
};

export type WidgetTypeBase = {
  config: any;
  filters?: Array<SearchFilter> | [];
  preview?: boolean;
  query?: QueryString | undefined;
  streams?: Array<string>;
  timerange?: TimeRange | undefined;
  type: string;
};

const widgetRendering = { limitHeight: false };

const PrintWidget = <WT extends WidgetTypeBase, VT extends ValueTypeBase>({
  description = undefined,
  title = undefined,
  height = undefined,
  hideDescription,
  hideQuery,
  headerId = undefined,
  interactive = true,
  limitHeight = false,
  showCaption = true,
  showHandle = true,
  showHeading = true,
  value,
  widget,
  widgetId,
  width = undefined,
}: Props<WT, VT>) => {
  const heading = useRef(null);
  const caption = useRef(null);

  // eslint-disable-next-line no-nested-ternary
  const Description = !hideDescription ? (
    description?.trim() ? (
      <span>{description}</span>
    ) : (
      <ErrorBoundary FallbackComponent={FallbackWidgetDescription}>
        <WidgetDescription
          calculatedAt={value?.calculatedAt}
          widgetConfig={widget.config}
          widgetTimerange={widget.timerange}
          widgetQuery={widget.query}
          widgetType={widget.type}
          widgetStreams={widget.streams}
          widgetFilters={widget.filters}
          hideQuery={hideQuery}
        />
      </ErrorBoundary>
    )
  ) : null;

  const chartColors = useMemo(
    () => widget?.config?.formattingSettings?.chartColors ?? {},
    [widget?.config?.formattingSettings?.chartColors],
  );
  const colorMap = useMemo(() => ColorMapper.create(Immutable.Map(chartColors)), [chartColors]);
  const chartColorContext = useMemo(() => ({ colors: colorMap, setColor: () => Promise.resolve() }), [colorMap]);
  const visualizationHeight =
    height - (caption.current?.scrollHeight ?? 0) - (heading.current?.scrollHeight ?? 0) - MARGIN_HEIGHT;

  return (
    <PluggableStoreProvider view={view} isNew={false} executionState={executionState} initialQuery="query">
      <AutoRefreshDisabledProvider>
        <WidgetRenderingContext.Provider value={widgetRendering}>
          <ChartColorContext.Provider value={chartColorContext}>
            <Container>
              {showHandle && <DragHandleIcon name="sort" />}
              {showHeading && <Heading title={title} ref={heading} headerId={headerId} />}
              {!value ? (
                <SpinnerContainer $height={visualizationHeight}>
                  <Spinner />
                </SpinnerContainer>
              ) : (
                <PrintingWidgetVisualizationContainer
                  widget={widget}
                  interactive={interactive}
                  limitHeight={limitHeight}
                  visualizationHeight={visualizationHeight}
                  width={width}
                  value={value}
                  widgetId={widgetId}
                />
              )}
              {showCaption && !!Description && <Caption text={Description} ref={caption} />}
            </Container>
          </ChartColorContext.Provider>
        </WidgetRenderingContext.Provider>
      </AutoRefreshDisabledProvider>
    </PluggableStoreProvider>
  );
};

export default PrintWidget;
