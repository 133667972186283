import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-duration-format';
import 'twix';
import styled from 'styled-components';

import ArchiveActions from 'archive/ArchiveActions';
import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';
import NumberUtils from 'util/NumberUtils';
import { Button, Col, Input, Row, Table } from 'components/bootstrap';
import { Icon, Timestamp, RelativeTime, Spinner, IfPermitted, PaginatedList } from 'components/common';
import { IndicesActions } from 'stores/indices/IndicesStore';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import { StyledDescriptionWrapper } from './StyledCatalogComponents';

import type { Archive, ArchiveContext, BackendContext, DiskState, Histogram } from '../types';

const StyledTbody = styled.tbody<{ $showDetails: boolean }>(
  ({ $showDetails }) => `
  ${$showDetails ? 'border-left: 4px solid #2980b9' : ''}
`,
);

const StyledHr = styled.hr`
  margin-bottom: 5px;
  margin-top: 10px;
`;

const StyledP = styled.p`
  float: left;
`;

const Checkbox = styled(Input)`
  margin: 0;
`;

type MappedStreamHistogramType = {
  streamId: string;
  name: string;
  count: number;
};

export type StreamType = {
  [key: string]: number;
};

type Props = {
  archive: Archive;
  archiveContext: ArchiveContext;
  backendContext: BackendContext;
  onMarkArchive: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkedMarkedArchive: (archiveId: string) => boolean;
};

const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_PAGE_SIZES = [5, 10, 20, 50];

const ArchiveCatalogTableEntry = ({
  archive,
  archiveContext,
  backendContext,
  onMarkArchive,
  checkedMarkedArchive,
}: Props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [diskState, setDiskState] = useState<DiskState>({ loading: true, available: false });
  const [streamHistograms, setStreamHistograms] = useState<Array<MappedStreamHistogramType>>([]);
  const [currentStreamHistogramPage, setCurrentStreamHistogramPage] = useState<Array<MappedStreamHistogramType>>([]);
  const [streamHistogramPage, setStreamHistogramPage] = useState<number>(1);
  const [streamHistogramPageSize, setStreamHistogramPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    const { id_mappings, stream_histogram } = archive;

    const getStreamName = (streamId: string) => {
      if (!id_mappings || !id_mappings.streams) {
        return streamId;
      }

      return id_mappings.streams[streamId] || streamId;
    };

    const mapStreamHistograms = (sourceStreamHistograms: StreamType): Array<MappedStreamHistogramType> =>
      Object.keys(sourceStreamHistograms)
        .map((streamId: string) => ({
          streamId: streamId,
          name: getStreamName(streamId),
          count: sourceStreamHistograms[streamId],
        }))
        .sort((a, b) => naturalSort(a.name, b.name));

    const getHistogramPageContent = (
      calcStreamHistograms: Array<MappedStreamHistogramType>,
    ): Array<MappedStreamHistogramType> =>
      calcStreamHistograms.slice(
        (streamHistogramPage - 1) * streamHistogramPageSize,
        streamHistogramPage * streamHistogramPageSize,
      );

    const calculateStreamHistograms = (sourceStreamHistogram: Histogram): StreamType => {
      const streams = {};

      Object.keys(sourceStreamHistogram).forEach((date) => {
        Object.keys(sourceStreamHistogram[date]).forEach((streamId) => {
          if (!streams[streamId]) {
            streams[streamId] = 0;
          }

          streams[streamId] += sourceStreamHistogram[date][streamId];
        });
      });

      return streams;
    };

    const calculatedStreamHistograms = calculateStreamHistograms(stream_histogram);
    const calcStreamHistograms = mapStreamHistograms(calculatedStreamHistograms);
    const histogramPageContent = getHistogramPageContent(calcStreamHistograms);

    setStreamHistograms(calcStreamHistograms);
    setCurrentStreamHistogramPage(histogramPageContent);
  }, [archive, streamHistogramPage, streamHistogramPageSize]);

  const toggleDetails = (event: React.MouseEvent<HTMLTableRowElement>) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.ITEM_DETAILS_TOGGLED, {
      app_pathname: 'archive',
      app_section: 'archive',
      event_details: {
        showing: !showDetails,
      },
    });

    event.preventDefault();
    const expanding = !showDetails;

    if (expanding) {
      // check the availability of the archive on disk
      ArchiveActions.availability(archive.id).then((state) => {
        setDiskState({ ...state, loading: false });
      });
    }

    setShowDetails(expanding);
  };

  const _onRestoreIndex = () => () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.RESTORED, {
      app_pathname: 'archive',
      app_section: 'archive-catalog',
    });

    ArchiveActions.restoreArchive(archive.backend_id, archive.archive_id).then((result) => result);
  };

  const _deleteIndex = async (indexName: string) => {
    await IndicesActions.delete(indexName);
  };

  const _onDeleteArchive = () => () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.DELETED, {
      app_pathname: 'archive',
      app_section: 'archive-catalog',
    });

    // eslint-disable-next-line no-alert
    if (
      window.confirm(`Are you sure you want to delete archive "${archive.archive_id}" for index "${archive.index_name}"?
 This will also delete the restored indices for this archive if it exist.`)
    ) {
      ArchiveActions.deleteArchive(archive.backend_id, archive.archive_id).then(() => {
        if (archiveContext.restored) {
          _deleteIndex(archiveContext.restored_index_name);
        }
      });
    }
  };

  const _onDeleteIndex = (indexName: string) => () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.DELETED, {
      app_pathname: 'archive',
      app_section: 'archive-catalog',
    });

    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete index ${indexName}?`)) {
      _deleteIndex(indexName);
    }
  };

  const getHistogramPageContent = (page: number, size: number): Array<MappedStreamHistogramType> =>
    streamHistograms.slice((page - 1) * size, page * size);

  const _onPageChange = (newPage: number, pageSize: number) => {
    if (streamHistograms.length > 0) {
      const newPageContent = getHistogramPageContent(newPage, pageSize);

      setCurrentStreamHistogramPage(newPageContent);
      setStreamHistogramPage(newPage);
      setStreamHistogramPageSize(pageSize);
    }
  };

  const renderStreamsDetails = () => {
    if (streamHistograms.length < 1) {
      return null;
    }

    const streamRows = currentStreamHistogramPage.map((histogramEntry) => (
      <tr key={histogramEntry.streamId}>
        <td>{histogramEntry.name}</td>
        <td>{NumberUtils.formatNumber(histogramEntry.count)}</td>
      </tr>
    ));

    return (
      <StyledDescriptionWrapper $marginLeft={150}>
        <div className="table-responsive">
          <PaginatedList
            activePage={streamHistogramPage}
            onChange={_onPageChange}
            totalItems={streamHistograms.length}
            pageSize={DEFAULT_PAGE_SIZE}
            pageSizes={DEFAULT_PAGE_SIZES}
            useQueryParameter={false}>
            <StyledP>
              Number of archived messages per stream. <strong>Note:</strong> Messages can be in multiple streams.
            </StyledP>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th>Stream</th>
                  <th>Message count</th>
                </tr>
              </thead>
              <tbody>{streamRows}</tbody>
            </Table>
          </PaginatedList>
        </div>
      </StyledDescriptionWrapper>
    );
  };

  const renderDetails = () => {
    const segmentSize = archive.segments.reduce((sum, segment) => sum + segment.size, 0);
    const segmentRawSize = archive.segments.reduce((sum, segment) => sum + segment.raw_size, 0);
    const compressionType = archive.segments.map((segment) => segment.compression_type)[0];
    let restoreButton;

    if (archiveContext.restored) {
      restoreButton = (
        <>
          <Button bsStyle="success" bsSize="xs" disabled>
            Restored as {archiveContext.restored_index_name}
          </Button>
          &nbsp;
          <Button bsStyle="danger" bsSize="xs" onClick={_onDeleteIndex(archiveContext.restored_index_name)}>
            Delete restored index
          </Button>
        </>
      );
    } else {
      restoreButton = (
        <IfPermitted permissions="archive:restore">
          <Button bsStyle="success" bsSize="xs" onClick={_onRestoreIndex()}>
            Restore index
          </Button>
        </IfPermitted>
      );
    }

    let rawSizeInfo;

    if (segmentRawSize > 0) {
      rawSizeInfo = ` / ${NumberUtils.formatBytes(segmentRawSize)} uncompressed`;
    }

    let archiveFileCheck;

    if (diskState.loading) {
      archiveFileCheck = <Spinner text="Checking archive availability" />;
    } else if (diskState.available) {
      archiveFileCheck = (
        <span>
          <Icon name="check" className="text-success" />
          &nbsp;Archive available
        </span>
      );
    } else {
      archiveFileCheck = (
        <span>
          <Icon name="close" className="text-danger" />
          &nbsp;Archive not available
        </span>
      );
    }

    const archiveDuration = moment
      .duration(archive.creation_duration, 'milliseconds')
      .format('h [h] m [min] s [sec] S [ms]');

    return (
      <span>
        <Row>
          <Col md={6}>
            <StyledDescriptionWrapper $marginLeft={150}>
              <dl>
                <dt>Index name:</dt>
                <dd>{archive.index_name}</dd>
                <dt>Backend:</dt>
                <dd>{backendContext.title}</dd>
                <dt>Created:</dt>
                <dd>
                  <Timestamp dateTime={archive.created_at} /> (took {archiveDuration})
                </dd>
                <dt>Message count:</dt>
                <dd>{NumberUtils.formatNumber(archive.document_count)}</dd>
                <dt>Earliest message:</dt>
                <dd>
                  <Timestamp dateTime={archive.timestamp_min} />
                </dd>
                <dt>Latest message:</dt>
                <dd>
                  <Timestamp dateTime={archive.timestamp_max} />
                </dd>
                <dt>Segment count:</dt>
                <dd>{archive.segments.length}</dd>
                <dt>Segments size:</dt>
                <dd>
                  {NumberUtils.formatBytes(segmentSize)} (compressed with {compressionType}
                  {rawSizeInfo})
                </dd>
                <dt>Segment directory:</dt>
                <dd>{archiveContext.path}</dd>
                <dt>Archive availability</dt>
                <dd>{archiveFileCheck}</dd>
                {archiveContext.restored && (
                  <span>
                    <dt>Restored index:</dt>
                    <dd>{archiveContext.restored_index_name}</dd>
                  </span>
                )}
              </dl>
            </StyledDescriptionWrapper>
          </Col>
          <Col md={6}>{renderStreamsDetails()}</Col>
        </Row>
        <IfPermitted permissions={['archive:create', 'archive:delete']}>
          <StyledHr />
          {restoreButton}
          <IfPermitted permissions="archive:delete">
            &nbsp;
            <Button bsStyle="danger" bsSize="xs" onClick={_onDeleteArchive()}>
              Delete archive
            </Button>{' '}
          </IfPermitted>
        </IfPermitted>
      </span>
    );
  };

  const renderStreamNames = () => {
    if (!archive.stream_names) {
      return <em>No streams</em>;
    }

    return archive.stream_names.sort((a, b) => naturalSort(a, b)).join(', ');
  };

  const contentRange = moment(archive.timestamp_min).twix(archive.timestamp_max);

  const preventEvent = (event) => {
    event.stopPropagation();
  };

  const currentUser = useCurrentUser();
  const colSpan = isPermitted(currentUser.permissions, 'archive:delete') ? 8 : 7;

  return (
    <StyledTbody className="archive-catalog-entry" $showDetails={showDetails}>
      <tr onClick={toggleDetails} className="toggle-details">
        <IfPermitted permissions={['archive:delete', 'archive:restore']} anyPermissions>
          <td>
            <div role="presentation" onClick={preventEvent} onMouseDown={preventEvent}>
              <Checkbox
                type="checkbox"
                id={archive.id}
                formGroupClassName="mb-0"
                wrapperClassName="mb-0"
                checked={checkedMarkedArchive(archive.id)}
                onChange={onMarkArchive}
              />
            </div>
          </td>
        </IfPermitted>
        <td>
          <strong>{archive.index_name}</strong>
        </td>
        <td>{backendContext.title}</td>
        <td>
          <RelativeTime dateTime={archive.created_at} />
        </td>
        <td>{contentRange.format({ hourFormat: 'H' })}</td>
        <td>
          {NumberUtils.formatNumber(archive.document_count)} msgs ({contentRange.humanizeLength()})
        </td>
        <td className="stretch">{renderStreamNames()}</td>
        <td className="restored">{archiveContext.restored ? <Icon name="check" /> : null}</td>
      </tr>
      {showDetails && (
        <tr className="archive-catalog-entries-details">
          <td colSpan={colSpan}>{renderDetails()}</td>
        </tr>
      )}
    </StyledTbody>
  );
};

export default ArchiveCatalogTableEntry;
