import { useMutation, useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { DATA_LAKE_API_ROUTES, DATA_LAKE_CONFIG_QUERY_KEYS } from 'data-lake/Constants';

import type { DataLakeConfig } from './useDataLakeConfig';

const updateDataLakeConfig = ({ config }: { config: DataLakeConfig }) =>
  fetch('PUT', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.config.base().url), {
    ...config,
  });

const useDataLakeConfigMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: onUpdateDataLakeConfig } = useMutation(updateDataLakeConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries(DATA_LAKE_CONFIG_QUERY_KEYS);

      UserNotification.success('Data Lake config updated successfully.', 'Updating Data Lake configuration');
    },
    onError: (errorThrown) => {
      UserNotification.error(
        `Updating Data Lake config failed with status: ${errorThrown}`,
        'Updating Data Lake configuration',
      );
    },
  });

  return { onUpdateDataLakeConfig };
};

export default useDataLakeConfigMutation;
