import { css } from 'styled-components';

import chosenSprite from './chosen-sprite.png';
import chosenSprite2x from './chosen-sprite@2x.png';

const chosenStyles = css`
  /* @group Base */

  .chzn-container {
    position: relative;
    display: block;
    vertical-align: middle;
    float: left;
    zoom: 1;
    *display: inline;
  }

  .chzn-container .chzn-drop {
    background: #fff;
    border: 1px solid #888;
    border-top: 0;
    position: absolute;
    top: 100%;
    left: -9999px;
    box-shadow: 0 1px 2px rgba(175, 175, 175, 0.15);
    z-index: 1010;
    width: 100%;
    box-sizing: border-box;
  }

  .chzn-container.chzn-with-drop .chzn-drop {
    left: 0;
  }

  /* @end */

  /* @group Single Chosen */

  .chzn-container-single .chzn-single {
    background-clip: padding-box;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    height: 28px;
    line-height: 28px;
    padding: 0 0 0 8px;
    text-decoration: none;
  }

  .chzn-container-single .chzn-single:hover {
    border: 1px solid #888;
  }

  .chzn-container-single .chzn-default {
    color: #999;
  }

  .chzn-container-single .chzn-single span {
    margin-right: 26px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333333;
    text-shadow: 0 1px 0 #ffffff;
  }

  .chzn-container-single .chzn-single abbr {
    display: block;
    position: absolute;
    right: 26px;
    top: 6px;
    width: 12px;
    height: 12px;
    font-size: 1px;
    background: url(${chosenSprite}) -42px 1px no-repeat;
  }

  .chzn-container-single .chzn-single abbr:hover {
    background-position: -42px -10px;
  }

  .chzn-container-single.chzn-disabled .chzn-single abbr:hover {
    background-position: -42px -10px;
  }

  .chzn-container-single .chzn-single div {
    position: absolute;
    right: 0;
    top: 3px;
    display: block;
    height: 100%;
    width: 18px;
  }

  .chzn-container-single .chzn-single div b {
    background: url(${chosenSprite}) no-repeat 0px 2px;
    display: block;
    width: 100%;
    height: 100%;
  }

  .chzn-container-single .chzn-search {
    padding: 3px 4px;
    position: relative;
    margin: 0;
    white-space: nowrap;
    z-index: 1010;
  }

  .chzn-container-single .chzn-search input {
    background: #fff url(${chosenSprite}) no-repeat 100% -19px;
    margin: 1px 0;
    padding: 4px 20px 4px 5px;
    outline: 0;
    border: 1px solid #aaa;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
  }

  .chzn-container-single .chzn-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
  }

  .chzn-container-single-nosearch .chzn-search {
    position: absolute;
    left: -9999px;
  }

  /* @end */

  /* @group Multi Chosen */

  .chzn-container-multi .chzn-choices {
    background-color: #fff;
    background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
    border: 1px solid #aaa;
    margin: 0;
    padding: 0;
    cursor: text;
    overflow: hidden;
    height: auto !important;
    height: 1%;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .chzn-container-multi .chzn-choices li {
    float: left;
    list-style: none;
  }

  .chzn-container-multi .chzn-choices .search-field {
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }

  .chzn-container-multi .chzn-choices .search-field input {
    color: #666;
    background: transparent !important;
    border: 0 !important;
    font-size: 100%;
    height: 15px;
    padding: 5px;
    margin: 1px 0;
    outline: 0;
    box-shadow: none;
  }

  .chzn-container-multi .chzn-choices .search-field .default {
    color: #999;
  }

  .chzn-container-multi .chzn-choices .search-choice {
    border-radius: 3px;
    background-clip: padding-box;
    background-color: #e4e4e4;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#f4f4f4', endColorstr = '#eeeeee', GradientType = 0);
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
    box-shadow:
      0 0 2px #ffffff inset,
      0 1px 0 rgba(0, 0, 0, 0.05);
    color: #333;
    border: 1px solid #aaaaaa;
    line-height: 13px;
    padding: 3px 20px 3px 5px;
    margin: 3px 0 3px 5px;
    position: relative;
    cursor: default;
  }

  .chzn-container-multi .chzn-choices .search-choice.search-choice-disabled {
    background-color: #e4e4e4;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#f4f4f4', endColorstr = '#eeeeee', GradientType = 0);
    background-image: linear-gradient(to bottom, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
    color: #666;
    border: 1px solid #cccccc;
    padding-right: 5px;
  }

  .chzn-container-multi .chzn-choices .search-choice-focus {
    background: #d4d4d4;
  }

  .chzn-container-multi .chzn-choices .search-choice .search-choice-close {
    display: block;
    position: absolute;
    right: 3px;
    top: 4px;
    width: 12px;
    height: 12px;
    font-size: 1px;
    background: url(${chosenSprite}) -42px 1px no-repeat;
  }

  .chzn-container-multi .chzn-choices .search-choice .search-choice-close:hover {
    background-position: -42px -10px;
  }

  .chzn-container-multi .chzn-choices .search-choice-focus .search-choice-close {
    background-position: -42px -10px;
  }

  /* @end */

  /* @group Results */

  .chzn-container .chzn-results {
    margin: 3px 0 4px 0;
    max-height: 240px;
    padding: 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .chzn-container-multi .chzn-results {
    margin: 0;
    padding: 0;
  }

  .chzn-container .chzn-results li {
    display: none;
    line-height: 16px;
    padding: 7px 8px;
    margin: 0;
    list-style: none;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #fff;
  }

  .chzn-container .chzn-results .active-result {
    cursor: pointer;
    display: list-item;
  }

  .chzn-container .chzn-results .highlighted {
    background: #f9f9f9;
  }

  .chzn-container .chzn-results li em {
    background: #feffde;
    font-style: normal;
  }

  .chzn-container .chzn-results .highlighted em {
    background: transparent;
  }

  .chzn-container .chzn-results .no-results {
    background: #fff;
    display: list-item;
  }

  .chzn-container .chzn-results .group-result {
    cursor: default;
    color: #999;
    font-weight: bold;
  }

  .chzn-container .chzn-results .group-option {
    padding-left: 15px;
  }

  .chzn-container-multi .chzn-drop .result-selected {
    display: none;
  }

  .chzn-container .chzn-results-scroll {
    background: white;
    margin: 0 4px;
    position: absolute;
    text-align: center;
    width: 321px; /* This should by dynamic with js */
    z-index: 1;
  }

  .chzn-container .chzn-results-scroll span {
    display: inline-block;
    height: 17px;
    text-indent: -5000px;
    width: 9px;
  }

  .chzn-container .chzn-results-scroll-down {
    bottom: 0;
  }

  .chzn-container .chzn-results-scroll-down span {
    background: url(${chosenSprite}) no-repeat -4px -3px;
  }

  .chzn-container .chzn-results-scroll-up span {
    background: url(${chosenSprite}) no-repeat -22px -3px;
  }

  /* @end */

  /* @group Active  */

  .chzn-container-active.chzn-with-drop .chzn-single {
    border: 1px solid #888;
    box-shadow: 0 1px 0 #fff inset;
    background: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .chzn-container-active .chzn-single {
    box-shadow: 0 1px 2px rgba(175, 175, 175, 0.3);
    border: 1px solid #888;
  }

  .chzn-container-active.chzn-with-drop .chzn-single div {
    background: transparent;
    border-left: none;
  }

  .chzn-container-active.chzn-with-drop .chzn-single div b {
    background-position: -18px 2px;
  }

  .chzn-container-active .chzn-choices {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border: 1px solid #5897fb;
  }

  .chzn-container-active .chzn-choices .search-field input {
    color: #111 !important;
  }

  /* @end */

  /* @group Disabled Support */

  .chzn-disabled {
    cursor: default;
    opacity: 0.5 !important;
  }

  .chzn-disabled .chzn-single {
    cursor: default;
  }

  .chzn-disabled .chzn-choices .search-choice .search-choice-close {
    cursor: default;
  }

  /* @group Right to Left */

  .chzn-rtl {
    text-align: right;
  }

  .chzn-rtl .chzn-single {
    padding: 0 8px 0 0;
    overflow: visible;
  }

  .chzn-rtl .chzn-single span {
    margin-left: 26px;
    margin-right: 0;
    direction: rtl;
  }

  .chzn-rtl .chzn-single div {
    left: 3px;
    right: auto;
  }

  .chzn-rtl .chzn-single abbr {
    left: 26px;
    right: auto;
  }

  .chzn-rtl .chzn-choices .search-field input {
    direction: rtl;
  }

  .chzn-rtl .chzn-choices li {
    float: right;
  }

  .chzn-rtl .chzn-choices .search-choice {
    padding: 3px 5px 3px 19px;
    margin: 3px 5px 3px 0;
  }

  .chzn-rtl .chzn-choices .search-choice .search-choice-close {
    left: 4px;
    right: auto;
  }

  .chzn-rtl .chzn-search {
    left: 9999px;
  }

  .chzn-rtl.chzn-with-drop .chzn-search {
    left: 0px;
  }

  .chzn-rtl .chzn-drop {
    left: 9999px;
  }

  .chzn-rtl.chzn-container-single .chzn-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0;
  }

  .chzn-rtl .chzn-results .group-option {
    padding-left: 0;
    padding-right: 15px;
  }

  .chzn-rtl.chzn-container-active.chzn-with-drop .chzn-single div {
    border-right: none;
  }

  .chzn-rtl .chzn-search input {
    background: #fff url(${chosenSprite}) no-repeat -30px -20px;
    padding: 4px 5px 4px 20px;
    direction: rtl;
  }

  .chzn-container-single.chzn-rtl .chzn-single div b {
    background-position: 6px 2px;
  }

  .chzn-container-single.chzn-rtl.chzn-with-drop .chzn-single div b {
    background-position: -12px 2px;
  }

  /* @end */

  /* @group Retina compatibility */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .chzn-rtl .chzn-search input,
    .chzn-container-single .chzn-single abbr,
    .chzn-container-single .chzn-single div b,
    .chzn-container-single .chzn-search input,
    .chzn-container-multi .chzn-choices .search-choice .search-choice-close,
    .chzn-container .chzn-results-scroll-down span,
    .chzn-container .chzn-results-scroll-up span {
      background-image: url(${chosenSprite2x}) !important;
      background-repeat: no-repeat !important;
      background-size: 52px 37px !important;
    }
  }

  /* @end */
`;

export default chosenStyles;
