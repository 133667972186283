import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { SecurityAppSecurityEventsHistogram } from '@graylog/enterprise-api';

import type { MiddleSectionProps } from 'components/common/PaginatedEntityTable/PaginatedEntityTable';
import { categoryKey } from 'security-app/components/SecurityEvents/Alerts/Constants';
import ExportResultsButton from 'security-app/components/SecurityEvents/Alerts/ExportResultsButton';
import MetricsAction from 'security-app/components/SecurityEvents/Alerts/MetricsAction';
import InteractiveContext from 'views/components/contexts/InteractiveContext';
import Spinner from 'components/common/Spinner';

import EventsTacticsHistogram from './EventsTacticsHistogram';
import EventsTechniquesHistogram from './EventsTechniquesHistogram';

const EventsCategoriesHistogram = ({ searchParams, setFilters }: MiddleSectionProps) => {
  const interactive = useContext(InteractiveContext);
  const tactic = searchParams.filters
    ?.get(categoryKey, [])
    .find((filter) => filter.toLocaleLowerCase().startsWith('ta'));

  const histogram = tactic ? (
    <EventsTechniquesHistogram searchParams={searchParams} setFilters={setFilters} />
  ) : (
    <EventsTacticsHistogram searchParams={searchParams} setFilters={setFilters} />
  );

  return (
    <div>
      <MetricsAction>
        {interactive ? (
          <ExportResultsButton
            type={tactic ? 'techniques' : 'tactics'}
            title={tactic ? 'techniques' : 'tactics'}
            searchParams={searchParams}
          />
        ) : null}
      </MetricsAction>
      {histogram}
    </div>
  );
};

const StyledCenter = styled.div`
  padding-left: 100px;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

const MissingPrerequisites = () => (
  <StyledCenter>
    <h2>Missing Illuminate</h2>
    <p>Cannot show Tactics/Techniques Histogram because you are missing Illuminate.</p>
    <p> Please install a recent bundle and try again!</p>
  </StyledCenter>
);

const SafeEventsCategoriesHistogram = ({ searchParams, setFilters }: MiddleSectionProps) => {
  const { data: status, isInitialLoading } = useQuery(['events', 'histograms', 'status'], () =>
    SecurityAppSecurityEventsHistogram.status(),
  );

  if (isInitialLoading) {
    return <Spinner />;
  }

  return status?.usable ? (
    <EventsCategoriesHistogram searchParams={searchParams} setFilters={setFilters} />
  ) : (
    <MissingPrerequisites />
  );
};
export default SafeEventsCategoriesHistogram;
