const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */
  batchSize: {
    defaultValue: '10000',
  },
  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  o365DropSensitiveDlpLogs: {
    value: true, // We want to default to true on render, but never compare the default
  },
  storeFullMessage: {
    value: false, // We want to default to true on render, but never compare the default
  },

  subscribeToAllContent: {
    value: true, // We want to default to true on render, but never compare the default
  },

  subscriptionType: {
    value: 'ENTERPRISE',
  },

  o365Name: {
    value: '',
  },

  availableContents: {
    value: 'AZURE_ACTIVE_DIRECTORY,SHAREPOINT,EXCHANGE,GENERAL,DLP_ALL',
  },

  contents: {
    value: '',
  },

  o365ThrottleEnabled: {
    value: undefined,
  },

  o365StoreFullMessage: {
    value: undefined,
  },

  pollingInterval: {
    value: '5',
  },
};

export default DEFAULT_SETTINGS;
