import React from 'react';
import { Formik, Form } from 'formik';

import { FormikFormGroup, ModalSubmit } from 'components/common';
import { Modal, Row, Col } from 'components/bootstrap';

import useRepositories from './hooks/useRepositories';
import type { FileSystemRepository } from './types';

type Props = {
  hideModal: () => void;
};

type FileSystemRepositoryFormValues = Pick<FileSystemRepository, 'name' | 'location'>;

const FileSystemRepositoryModalForm = ({ hideModal }: Props) => {
  const { fsPaths, onCreateRepository } = useRepositories();

  const buildOptions = (): { value: string; label: string }[] =>
    fsPaths.data.map((fsPath) => ({
      value: fsPath,
      label: fsPath,
    }));

  const handleSubmit = (values: FileSystemRepositoryFormValues) =>
    onCreateRepository({ type: 'fs', ...values }).then(() => hideModal());

  const initialValues = {
    name: '',
    location: '',
  };

  return (
    <Formik<FileSystemRepositoryFormValues> onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting, isValidating }) => (
        <Form>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <FormikFormGroup
                  type="text"
                  label="Name"
                  name="name"
                  help="Name of your warm storage repository. Must be unique."
                  required
                />
                {fsPaths.isLoading
                  ? 'Fetching locations...'
                  : fsPaths.data && (
                      <FormikFormGroup
                        type="select"
                        key="location"
                        name="location"
                        label="Location"
                        required
                        help="Location of your warm storage repository">
                        <>
                          <option key="placeholder" value="">
                            Select warm storage repository location
                          </option>
                          {buildOptions().map(({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </>
                      </FormikFormGroup>
                    )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ModalSubmit
              submitButtonText="Create"
              submitLoadingText="Creating..."
              onCancel={hideModal}
              disabledSubmit={isValidating}
              isSubmitting={isSubmitting}
            />
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default FileSystemRepositoryModalForm;
