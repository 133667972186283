import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import { FormDataContext } from '../common/context/FormData';
import { AdvancedOptionsContext } from '../common/context/AdvancedOptions';
import AdditionalFields from '../common/AdditionalFields';
import type { HandleFieldUpdateType, FormDataContextType, AdvancedOptionsContextType } from '../common/utils/types';

interface Props {
  onChange: HandleFieldUpdateType;
}

const StyledAdditionalFields = styled(AdditionalFields)`
  margin: 0 0 35px;
`;

const FormAdvancedOptions = ({ onChange }: Props) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } =
    useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const { symantecEDRThrottleEnabled, disableTLSCheck } = formData;

  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  return (
    <StyledAdditionalFields title="Advanced Options" visible={isAdvancedOptionsVisible} onToggle={handleToggle}>
      <Input
        id="symantecEDRThrottleEnabled"
        type="checkbox"
        value="enable-throttling"
        defaultChecked={symantecEDRThrottleEnabled?.value}
        onChange={onChange}
        label="Enable Throttling"
        help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled."
      />
      <Input
        id="disableTLSCheck"
        type="checkbox"
        value="disable-tlsCheck"
        defaultChecked={disableTLSCheck?.value}
        onChange={onChange}
        label="Disable TLS Validation"
        help="Disable the TLS validation and trust all connection?"
      />
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
