import React from 'react';
import * as Immutable from 'immutable';
import trim from 'lodash/trim';

import { Panel, BootstrapModalForm, Input } from 'components/bootstrap';
import Icon from 'components/common/Icon';
import ClipboardButton from 'components/common/ClipboardButton';
import * as FormsUtils from 'util/FormsUtils';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import type Parameter from 'views/logic/parameters/Parameter';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterDataTypesSelect from 'enterprise/parameters/components/ParameterDataTypesSelect';

import TypeSpecificDeclarationFields from './TypeSpecificDeclarationFields';

type Props = {
  allowEditingName?: boolean;
  existingParameters: ParameterMap;
  onClose: () => void;
  onSave: (newParameters: ParameterMap) => Promise<unknown>;
  parameters: ParameterMap;
  show?: boolean;
  title?: string;
};

type ValidationSuccess = ['success'];
type ValidationWarning = ['warning', string];
type ValidationError = ['error', string];
export type ValidationResult = ValidationSuccess | ValidationWarning | ValidationError;

type ValidationKeys = 'title' | 'name' | 'defaultValue' | 'description' | 'type';
export type ValidationStateEntry = { [key: string]: ValidationResult };
type ValidationState = Immutable.Map<number, ValidationStateEntry>;
type State = {
  parameters: Immutable.List<Parameter>;
  validationStates: ValidationState;
};

const IdentifierRegex = /^[\w]+$/;

const backdropForMode = (mode: QueryEditMode): boolean => {
  switch (mode) {
    case 'query':
      return true;
    case 'widget':
      return false;
    default:
      throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

export default class ParameterDeclarationForm extends React.Component<Props, State> {
  static contextType = QueryEditModeContext;

  readonly context: QueryEditMode;

  _isMounted: boolean = false;

  static defaultProps = {
    allowEditingName: false,
    title: 'Declare parameters',
    show: true,
  };

  constructor(props: Props) {
    super(props);

    const { parameters } = props;

    this.state = {
      parameters: Immutable.List(parameters.valueSeq()),
      validationStates: this._validateParameters(parameters.valueSeq()),
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFormSubmit = (e: React.FormEvent) => {
    const { onSave } = this.props;
    const { parameters } = this.state;
    e.preventDefault();
    e.stopPropagation();
    const newParameters = Immutable.Map<string, Parameter>(parameters.map((p) => [p.name, p]));

    onSave(newParameters).finally(() => {
      if (this._isMounted) {
        // isSubmitting false
      }
    });
  };

  _validateName = (name: string): ValidationResult => {
    if (!trim(name)) {
      return ['error', 'Must not be empty.'];
    }

    if (!IdentifierRegex.test(name)) {
      return ['error', 'Only characters, numbers and _ allowed.'];
    }

    const { existingParameters } = this.props;

    if (existingParameters && existingParameters.has(name)) {
      return ['error', 'This parameter name already exists.'];
    }

    return ['success'];
  };

  _validateTitle = (title: string): ValidationResult => {
    if (!trim(title)) {
      return ['error', 'Must not be empty.'];
    }

    const { existingParameters } = this.props;

    if (
      existingParameters &&
      existingParameters.find(({ title: existingTitle }) => trim(title) === trim(existingTitle))
    ) {
      return ['error', 'This title is already in use.'];
    }

    return ['success'];
  };

  _validateParameter = (p: Parameter): { [k in ValidationKeys]?: ValidationResult } => ({
    name: this._validateName(p.name),
    title: this._validateTitle(p.title),
  });

  _validateParameters = (parameters: Immutable.Iterable.Indexed<Parameter>) =>
    parameters.map((p: Parameter) => this._validateParameter(p)).toMap();

  _validate = (parameters: Immutable.List<Parameter>) => {
    const newValidationStates = this._validateParameters(parameters);

    this.setState(({ validationStates: currentValidationStates }) => ({
      validationStates: currentValidationStates.mergeDeepWith(
        (state1, state2) => ({ ...state1, ...state2 }),
        newValidationStates,
      ),
    }));
  };

  handleValidate = (idx: number, state: ValidationStateEntry) =>
    this.setState(({ validationStates }) => ({
      validationStates: validationStates.update(idx, (current) => ({ ...current, ...state })),
    }));

  updateParameter = (idx: number, updater: (parameter: Parameter) => Parameter) => {
    const { parameters } = this.state;

    const newParameters = parameters.update(idx, updater);

    this._validate(newParameters);

    this.setState({ parameters: newParameters });
  };

  handleParameterField =
    (idx: number): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const key = e.target.name;
      const value = FormsUtils.getValueFromInput(e.target);

      this.updateParameterField(idx, key, value);
    };

  updateParameterField = (idx: number, key: string, value: any) =>
    this.updateParameter(idx, (parameter: ValueParameter) => parameter.toBuilder()[key](value).build());

  renderParameter(parameter: Parameter, idx: number) {
    const { name, title, description, type } = parameter;
    const parameterSyntax = `$${name}$`;
    const { validationStates } = this.state;
    const { allowEditingName } = this.props;
    const validationState = validationStates.get(idx);

    const onChangeEvent = this.handleParameterField(idx);
    const onChange = (key: string, value: any) => this.updateParameterField(idx, key, value);

    const onChangeParameter = (updater: (parameter: Parameter) => Parameter) => this.updateParameter(idx, updater);

    return (
      <fieldset key={`fieldset-${idx}`}>
        <Input
          id={`name-${idx}`}
          type="text"
          name="name"
          label="Name"
          disabled={!allowEditingName}
          value={name}
          onChange={onChangeEvent}
          bsStyle={validationState?.name?.[0]}
          help={validationState?.name?.[1]}
          required
        />
        <Input
          id={`title-${idx}`}
          type="text"
          name="title"
          label="Title"
          value={title}
          onChange={onChangeEvent}
          bsStyle={validationState?.title?.[0]}
          help={validationState?.title?.[1]}
          required
        />
        <Input
          id={`description-${idx}`}
          type="text"
          name="description"
          label="Description"
          value={description}
          bsStyle={validationState?.description?.[0]}
          help={validationState?.description?.[1]}
          onChange={onChangeEvent}
        />

        <ParameterDataTypesSelect
          idx={idx}
          validationState={validationState}
          value={type}
          onChange={onChangeParameter}
        />

        <TypeSpecificDeclarationFields
          idx={idx}
          type={type ?? 'value-parameter-v1'}
          parameter={parameter}
          onChange={onChange}
          validationState={validationState}
          onValidate={this.handleValidate}
        />

        <Panel style={{ marginTop: 20 }}>
          <Panel.Heading>
            <Panel.Title>How to use</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            After declaring it, you can use this parameter in all queries by using the{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              <code>{parameterSyntax}</code>
              <ClipboardButton
                title={<Icon name="content_copy" />}
                bsSize="xsmall"
                text={parameterSyntax}
                buttonTitle="Copy parameter to clipboard"
              />
            </span>{' '}
            syntax in your query, whenever you want the value of the parameter to be inserted.
          </Panel.Body>
        </Panel>
      </fieldset>
    );
  }

  render() {
    const { parameters, validationStates } = this.state;
    const { title, onClose, show } = this.props;

    const errorStates = validationStates.filter(
      (states) => Object.values(states).find((state) => state[0] === 'error') !== undefined,
    );
    const submitButtonDisabled = errorStates !== undefined && !errorStates.isEmpty();

    const parameterFields = parameters
      .map((parameter: Parameter, idx: number) => this.renderParameter(parameter, idx))
      .valueSeq()
      .toJS();

    const backdrop = backdropForMode(this.context);

    return (
      <BootstrapModalForm
        submitButtonDisabled={submitButtonDisabled}
        submitButtonText="Declare parameters"
        title={title}
        data-telemetry-title="Declare parameters"
        onCancel={onClose}
        show={show}
        backdrop={backdrop}
        onSubmitForm={this.handleFormSubmit}>
        {parameterFields}
      </BootstrapModalForm>
    );
  }
}
