import useClusterConfig, { useUpdateClusterConfig } from 'hooks/useClusterConfig';
import type { BadgeConfig } from 'customization/header-badge/HeaderBadgeConfiguration';

const CUSTOM_HEADER_BADGE = 'org.graylog.plugins.customization.HeaderBadge';

export const useUpdateHeaderBadge = () => useUpdateClusterConfig<BadgeConfig>(CUSTOM_HEADER_BADGE);

const useHeaderBadge = (): { data: BadgeConfig; isInitialLoading: boolean } =>
  useClusterConfig<BadgeConfig>(CUSTOM_HEADER_BADGE);
export default useHeaderBadge;
