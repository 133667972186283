import * as React from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import AbsoluteDatePicker from 'views/components/searchbar/time-range-filter/time-range-picker/AbsoluteDatePicker';
import AbsoluteTimeInput from 'data-lake/components/AbsoluteTimeInput';

type Props = {
  startDate?: Date;
  range: 'to' | 'from';
  nextTimeRange: AbsoluteTimeRange;
  disableTimeMinute?: boolean;
  disableTimeSecond?: boolean;
};

const ErrorMessage = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.variant.dark.danger};
    font-size: ${theme.fonts.size.small};
    font-style: italic;
    padding: 3px 3px 9px;
    height: 1.5em;
  `,
);

const AbsoluteCalendarInput = ({
  startDate = undefined,
  nextTimeRange,
  range,
  disableTimeMinute = false,
  disableTimeSecond = false,
}: Props) => (
  <Field name={`nextTimeRange[${range}]`}>
    {({ field: { value, onChange, name }, meta: { error } }) => {
      const _onChange = (newValue) => onChange({ target: { name, value: newValue } });
      const dateTime = error ? nextTimeRange[range] : value || nextTimeRange[range];

      return (
        <>
          <AbsoluteDatePicker onChange={_onChange} startDate={startDate} dateTime={dateTime} />
          <AbsoluteTimeInput
            onChange={_onChange}
            dateTime={dateTime}
            disableMinute={disableTimeMinute}
            disableSecond={disableTimeSecond}
            range={range}
          />
          <ErrorMessage>{error}</ErrorMessage>
        </>
      );
    }}
  </Field>
);

export default AbsoluteCalendarInput;
