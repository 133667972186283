import styled from 'styled-components';

import { PAGE_PRINT_MARGIN } from 'report/Constants';

export const Container = styled.div`
  @media print {
    @page {
      margin: ${PAGE_PRINT_MARGIN};
    }
  }
`;

export const InvisibleDiv = styled.div`
  display: none;
`;
