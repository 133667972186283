import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { DocumentTitle, IfPermitted, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';
import { useStore } from 'stores/connect';
import { Button, Col, Row } from 'components/bootstrap';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';

import ForwarderInputsManagement from './inputs-list/ForwarderInputsManagement';
import InputProfilesForwarderList from './input-profiles/InputProfilesForwarderList';
import InputProfileAssignForm from './input-profiles/InputProfileAssignForm';
import InputProfilesStore, { InputProfilesActions } from './stores/InputProfilesStore';
import ForwarderInputsStore, { ForwarderInputsActions } from './stores/ForwarderInputsStore';
import ForwardersStore, { ForwardersActions } from './stores/ForwardersStore';
import InputProfileDetailsComponent from './input-profiles/InputProfileDetailsComponent';
import withIsPermitted from './util/withIsPermitted';
import ForwardersContext from './forwarders/ForwardersContext';
import PaginatedForwarderListProvider from './forwarders/PaginatedForwarderListProvider';
import type { Forwarder } from './Types';

type Props = {
  params: {
    inputProfileId: string;
  };
};

const StyledH2 = styled.h2`
  margin-bottom: 20px;
`;

const requiredPermissions = ({ params }) => [
  'forwarders:read',
  `inputprofiles:read:${params.inputProfileId}`,
  'forwarderinputs:read',
];

const InputProfilePage = ({ params }: Props) => {
  const { inputProfileId } = params;
  const { inputProfile } = useStore(InputProfilesStore);
  const { forwarderInputs, pagination } = useStore(ForwarderInputsStore);
  const { all: forwarders } = useStore(ForwardersStore);
  const [showForwarderList, setShowForwarderList] = useState(true);
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);

  const _saveProfileAssignment = (updatedForwarders: Array<{ forwarderId: string; inputProfileId: string | null }>) => {
    const toBeUpdatedForwarders = [];

    updatedForwarders.forEach((uf) => {
      const forwarder = forwarders.find((f) => f.id === uf.forwarderId);

      if (forwarder) {
        toBeUpdatedForwarders.push({ ...forwarder, input_profile_id: uf.inputProfileId });
      }
    });

    ForwardersActions.updates(toBeUpdatedForwarders);
  };

  const _reloadInputs = () => {
    ForwarderInputsActions.list({ inputProfileId });
  };

  const _toggleForwarderList = (isShowing: boolean) => {
    setShowForwarderList(isShowing);
  };

  const _handleQuery = (page?: number, pageSize?: number, query: string = '') => {
    ForwarderInputsActions.list({
      inputProfileId,
      page,
      pageSize,
      query,
    });
  };

  const assignedForwarders = useMemo(
    () => (forwarders || []).filter((f: Forwarder) => f.input_profile_id === inputProfileId),
    [forwarders, inputProfileId],
  );

  const _loadInputProfile = useCallback(() => {
    InputProfilesActions.get(inputProfileId);
  }, [inputProfileId]);

  useEffect(() => {
    _loadInputProfile();
    ForwarderInputsActions.list({ inputProfileId });
    ForwardersActions.listAll();
  }, [inputProfileId, _loadInputProfile]);

  if (!inputProfile) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="Input Profile">
      <PageHeader
        title={`Input Profile: ${inputProfile.title}`}
        documentationLink={{
          title: 'Input profile documentation',
          path: ForwarderDocs.INPUT_PROFILE,
        }}>
        <span>
          See an overview of {inputProfile.title} Input Profile, including the Inputs configured and the Forwarders
          using it.
        </span>
      </PageHeader>
      <LicenseCheck
        licenseSubject={LICENSE_SUBJECTS.forwarder}
        displayWarningContainer
        featureName="Forwarder"
        bsStyle="danger"
      />
      <Row className="content">
        <InputProfileDetailsComponent inputProfile={inputProfile} onUpdate={_loadInputProfile} />
      </Row>
      <Row className="content">
        <Col md={12}>
          <h2>Inputs</h2>
          {forwarderInputs[inputProfileId] && (
            <ForwarderInputsManagement
              inputs={forwarderInputs[inputProfileId]}
              inputProfile={inputProfile}
              enableEdit
              pagination={pagination}
              onQueryChange={_handleQuery}
              onInputChange={_reloadInputs}
              onActionChange={_toggleForwarderList}
            />
          )}
        </Col>
      </Row>
      <PaginatedForwarderListProvider
        initialPagination={{ query: `input_profile_id:${inputProfileId}`, sortByField: 'title', order: 'asc' }}>
        {showForwarderList && (
          <ForwardersContext.Consumer>
            {({
              forwarders: inputProfileForwarders,
              pagination: forwardersPagination,
              changePagination,
              isLoading,
            }) => {
              if (inputProfileForwarders) {
                return (
                  <Row className="content">
                    <Col md={12}>
                      <div className="pull-right">
                        <IfPermitted permissions="forwarders:edit">
                          <Button bsStyle="success" onClick={() => setShowAssignModal(true)}>
                            Assign to Forwarders
                          </Button>
                          {showAssignModal && (
                            <InputProfileAssignForm
                              forwarders={forwarders}
                              assignedForwarders={assignedForwarders}
                              inputProfileId={inputProfile.id}
                              onForwardersAssign={_saveProfileAssignment}
                              showModal={showAssignModal}
                              onClose={() => setShowAssignModal(false)}
                            />
                          )}
                        </IfPermitted>
                      </div>
                      <StyledH2>
                        Forwarders <small>{isLoading && <Spinner text="" />}</small>
                      </StyledH2>
                      {inputProfileForwarders && forwardersPagination ? (
                        <InputProfilesForwarderList
                          forwarders={inputProfileForwarders}
                          pagination={forwardersPagination}
                          onPageChange={changePagination}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </Col>
                  </Row>
                );
              }

              return <Spinner />;
            }}
          </ForwardersContext.Consumer>
        )}
      </PaginatedForwarderListProvider>
    </DocumentTitle>
  );
};

export default withParams(withIsPermitted(InputProfilePage, requiredPermissions));
