import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

const urlPrefix = '/plugins/org.graylog.plugins.securityapp.investigations/investigations/summary';

export type AIReport = {
  summary: string;
  template: string;
  topP: number;
  maxGenLength: number;
  temperature: number;
  model: string;
};

export type AIReportJSON = {
  summary: string;
  template: string;
  top_p: number;
  max_gen_length: number;
  temperature: number;
  model: string;
};

export type AIReportText = {
  text: string;
};

const fetchAIReport = (investigationId: string): Promise<string> =>
  fetch<AIReportText>('GET', qualifyUrl(`${urlPrefix}/${investigationId}`)).then(
    (report: AIReportText) => report?.text,
  );

const useAiReport = (
  investigationId: string,
  enabled: boolean,
): {
  data: string;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isFetched: boolean;
  isError: boolean;
  error: Error;
} => {
  const { data, isLoading, isFetching, isSuccess, isFetched, isError, error } = useQuery<string, Error>(
    ['investigation-ai-report', investigationId],
    () =>
      defaultOnError(
        fetchAIReport(investigationId),
        'Fetching AI Report failed with status',
        'Could not fetch AI Report',
      ),
    {
      enabled,
    },
  );

  return {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isFetched,
    isError,
    error,
  };
};

export default useAiReport;
