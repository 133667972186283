import React from 'react';

import { Link } from 'components/common/router';
import { Alert, Col, Row } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';

type EventsLicenseStatusProps = {
  licenseStatus: {
    status?: any;
    missing: boolean;
    loading: boolean;
  };
  summary?: boolean;
};

class EventsLicenseStatus extends React.Component<
  EventsLicenseStatusProps,
  {
    [key: string]: any;
  }
> {
  static defaultProps = {
    summary: false,
  };

  renderStatus = () => (
    <Alert bsStyle="danger" title="Event Correlation is disabled">
      <p>
        Event Correlation is disabled because there is no valid license. You can still create or update any Event
        Correlation, but Graylog will not create new Alerts and Events from them.
      </p>
      <IfPermitted permissions="licenses:create">
        <p>
          See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
        </p>
      </IfPermitted>
    </Alert>
  );

  render() {
    const { licenseStatus, summary } = this.props;
    const { status, missing } = licenseStatus;

    if (!missing && status && status.valid) {
      return null;
    }

    if (summary) {
      return this.renderStatus();
    }

    return (
      <Row className="row-sm">
        <Col md={12}>{this.renderStatus()}</Col>
      </Row>
    );
  }
}

export default EventsLicenseStatus;
