import useCurrentUser from 'hooks/useCurrentUser';
import { useGetUsers, useGetTeams } from 'security-app/hooks/useInvestigationsAPI';

export type AssigneeOptionsType = { label: string; value: string; type: 'user' | 'team' };

export function useUsersAndTeamsOptions() {
  const { id: currentUserId } = useCurrentUser();
  const { users } = useGetUsers(true);
  const { teams } = useGetTeams(true);

  return [
    { value: 'Users', label: 'Users', disabled: true },
    { value: currentUserId, label: 'Assign to me' },
    ...users
      .map<AssigneeOptionsType>((user) => ({ label: user.full_name, value: user.id, type: 'user' }))
      .sort((a: { label: string }, b: { label: string }) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;

        return 0;
      }),
    { value: 'Teams', label: 'Teams', disabled: true },
    ...teams
      .map<AssigneeOptionsType>((team) => ({ label: team.name, value: team.id, type: 'team' }))
      .sort((a: { label: string }, b: { label: string }) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;

        return 0;
      }),
  ];
}
