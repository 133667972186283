import * as Immutable from 'immutable';

import WidgetConfig from 'views/logic/widgets/WidgetConfig';
import Direction from 'views/logic/aggregationbuilder/Direction';

import type { InvestigationsWidgetSortConfigJSON } from './InvestigationsWidgetSortConfig';
import InvestigationsWidgetSortConfig from './InvestigationsWidgetSortConfig';

export const LIST_MODE = 'List';
export const NUMBER_MODE = 'Number';

export type VisualizationType = typeof LIST_MODE | typeof NUMBER_MODE;

export type Filter = {
  field: string;
  value: Array<string>;
};

type InternalState = {
  fields: Immutable.OrderedSet<string>;
  filters: Immutable.OrderedSet<Filter>;
  sort: InvestigationsWidgetSortConfig;
  mode: VisualizationType;
};

export type InvestigationsWidgetConfigJSON = {
  fields: Array<string>;
  filters: Array<Filter>;
  sort: InvestigationsWidgetSortConfigJSON;
  mode: VisualizationType;
};

export default class InvestigationsWidgetConfig extends WidgetConfig {
  _value: InternalState;

  static defaultFields: InternalState['fields'] = Immutable.OrderedSet(['name', 'status', 'assigned_to', 'created_at']);

  static defaultSort: InternalState['sort'] = new InvestigationsWidgetSortConfig('created_at', Direction.Descending);

  static defaultMode: InternalState['mode'] = LIST_MODE;

  constructor(
    fields: InternalState['fields'],
    filters: InternalState['filters'],
    sort: InternalState['sort'],
    mode: InternalState['mode'],
  ) {
    super();

    this._value = { fields, filters, sort, mode };
  }

  get fields() {
    return this._value.fields;
  }

  get filters() {
    return this._value.filters;
  }

  get sort() {
    return this._value.sort;
  }

  get mode() {
    return this._value.mode;
  }

  toBuilder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Immutable.Map(this._value));
  }

  toJSON() {
    const { fields = Immutable.OrderedSet(), filters = Immutable.OrderedSet(), sort, mode } = this._value;

    return {
      fields: fields.toArray(),
      filters: filters.toArray(),
      sort: sort.toJSON(),
      mode,
    };
  }

  static builder(): Builder {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder().fields(Immutable.OrderedSet()).filters(Immutable.OrderedSet());
  }

  static fromJSON(value: InvestigationsWidgetConfigJSON) {
    const { fields, filters, sort, mode } = value;

    return new InvestigationsWidgetConfig(
      Immutable.OrderedSet(fields),
      Immutable.OrderedSet(filters),
      InvestigationsWidgetSortConfig.fromJSON(sort),
      mode,
    );
  }

  static createDefault() {
    return new InvestigationsWidgetConfig(
      InvestigationsWidgetConfig.defaultFields,
      Immutable.OrderedSet(),
      InvestigationsWidgetConfig.defaultSort,
      InvestigationsWidgetConfig.defaultMode,
    );
  }
}

type BuilderState = Immutable.Map<string, any>;

class Builder {
  value: BuilderState;

  constructor(value: BuilderState = Immutable.Map()) {
    this.value = value;
  }

  fields(value: InternalState['fields']) {
    return new Builder(this.value.set('fields', value));
  }

  filters(value: InternalState['filters']) {
    return new Builder(this.value.set('filters', value));
  }

  sort(value: InternalState['sort']) {
    return new Builder(this.value.set('sort', value));
  }

  mode(value: InternalState['mode']) {
    return new Builder(this.value.set('mode', value));
  }

  build() {
    const { fields, filters, sort, mode } = this.value.toObject();

    return new InvestigationsWidgetConfig(fields, filters, sort, mode);
  }
}
