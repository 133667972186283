import * as React from 'react';
import styled, { css } from 'styled-components';
import { useState, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';

import { Button, Badge, Alert } from 'components/bootstrap';
import { Icon, ModalSubmit } from 'components/common';
import Popover from 'components/common/Popover';
import type { FieldFilters, FieldsOperator } from 'data-lake/Types';
import FieldFiltersConfiguration from 'data-lake/FieldFiltersConfiguration/FieldFiltersConfiguration';
import TypeSpecificValue from 'views/components/TypeSpecificValue';
import FieldType from 'views/logic/fieldtypes/FieldType';
import useFieldTypes from 'data-lake/hooks/useFieldTypes';
import OperatorField from 'data-lake/preview/OperatorField';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.sm};
  `,
);

type QueryBuilderFormValues = {
  fieldFilters: Array<FieldFilters>;
  operator: FieldsOperator;
};

const QueryString = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${theme.spacings.xs};
    min-height: 34px;
  `,
);

const NoFiltersInfo = styled(Alert)`
  margin-top: 0;
`;

const validateFieldFilters = (
  fieldFilters: Array<FieldFilters>,
  operator: FieldsOperator,
  inMemoryFieldsSet: Set<string>,
) => {
  const existingFieldNames = new Set();
  const allFieldsHasSameInMemoryFlag =
    fieldFilters.every(({ field_name }) => inMemoryFieldsSet.has(field_name)) ||
    fieldFilters.every(({ field_name }) => !inMemoryFieldsSet.has(field_name));

  const errors = fieldFilters.map((filter) => {
    const filterErrors: { field_name?: string; value?: string } = {};

    if (!filter.field_name) {
      filterErrors.field_name = 'Field name is required';
    }

    if (filter.field_name && operator === 'AND') {
      if (existingFieldNames.has(filter.field_name)) {
        filterErrors.field_name = 'There can only be one filter per field';
      }

      existingFieldNames.add(filter.field_name);
    }

    if (!filter.value) {
      filterErrors.value = 'Value is required';
    }

    if (operator === 'OR' && !allFieldsHasSameInMemoryFlag && inMemoryFieldsSet.has(filter.field_name)) {
      filterErrors.field_name = 'Cannot use OR filtering when both in-memory filters and iceberg filters are present.';
    }

    return Object.entries(filterErrors).length > 0 ? filterErrors : null;
  });

  const filteredErrors = errors.filter((error) => !!error);

  return filteredErrors.length > 0 ? errors : [];
};

const validateQueryBuilderForm = ({
  fieldFilters,
  operator,
  inMemoryFieldsSet,
}: QueryBuilderFormValues & { inMemoryFieldsSet: Set<string> }) => {
  const errors: { fieldFilters?: Array<{ field_name?: string; value?: string }> } = {};

  const fieldFiltersErrors = validateFieldFilters(fieldFilters, operator, inMemoryFieldsSet);

  if (fieldFiltersErrors.length > 0) {
    errors.fieldFilters = fieldFiltersErrors;
  }

  return errors;
};

const SearchBarFieldsFilter = () => {
  const [showBuilder, setShowBuilder] = useState(false);
  const toggleBuilder = () => setShowBuilder((cur) => !cur);
  const { data: fieldTypes, inMemoryFieldsSet } = useFieldTypes();
  const sendTelemetry = useSendTelemetry();

  const validation = useCallback(
    ({ fieldFilters, operator }: QueryBuilderFormValues) =>
      validateQueryBuilderForm({ fieldFilters, operator, inMemoryFieldsSet }),
    [inMemoryFieldsSet],
  );

  return (
    <Field name="fields">
      {({ field: { name, value, onChange } }) => {
        const changeValue = (newValue: { fieldFilters: Array<FieldFilters>; operator: FieldsOperator }) => {
          onChange({
            target: {
              value: newValue,
              name,
            },
          });
        };

        const onClearFieldFilters = () => {
          sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.CLEAR_FIELD_FILTERS, {
            app_pathname: '/data-storage/preview',
            app_section: 'search-bar',
            app_action_value: 'clear-field-filters',
          });
          changeValue({ fieldFilters: [], operator: 'AND' });
        };

        const onSubmitBuilder = (newValue: { fieldFilters: Array<FieldFilters>; operator: FieldsOperator }) => {
          sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.SUBMIT_FIELD_FILTERS_FORM, {
            app_pathname: '/data-storage/preview',
            app_section: 'search-bar',
            app_action_value: 'update-field-filters',
            eventDetails: {
              fieldFilters: newValue?.fieldFilters?.map(({ field_name: fieldName }) => fieldName),
              operator: newValue?.operator,
            },
          });

          changeValue(newValue);

          toggleBuilder();
        };

        return (
          <Container>
            <Popover
              position="bottom-start"
              width={500}
              opened={showBuilder}
              withArrow
              onChange={setShowBuilder}
              closeOnClickOutside
              withinPortal>
              <Popover.Target>
                <Button onClick={toggleBuilder} title="Configure field filter">
                  Filter by field <Icon name="arrow_drop_down" />
                </Button>
              </Popover.Target>

              {value?.fieldFilters?.length >= 1 && (
                <QueryString>
                  {value.fieldFilters.map(({ field_name: fieldName, value: fieldValue }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                      {index !== 0 && value.operator}
                      <Badge bsStyle="info">
                        {fieldName}:
                        <TypeSpecificValue
                          value={fieldValue}
                          field={fieldName}
                          type={(fieldTypes?.find((f) => f.name === fieldName) || { type: FieldType.Unknown }).type}
                        />
                      </Badge>
                    </React.Fragment>
                  ))}
                  <Button onClick={onClearFieldFilters} title="Clear field filters">
                    Clear
                  </Button>
                </QueryString>
              )}

              <Popover.Dropdown title="Build Field Filter" id="build-fields-filter-dropdown">
                <Formik<{ fieldFilters: Array<FieldFilters> }>
                  initialValues={
                    value?.fieldFilters?.length > 0
                      ? value
                      : { fieldFilters: [{ field_name: '', value: '' }], operator: 'AND' }
                  }
                  onSubmit={onSubmitBuilder}
                  validate={validation}>
                  {({ isValid, isSubmitting, values }) => (
                    <Form>
                      <OperatorField />
                      {values?.fieldFilters?.length < 1 && (
                        <NoFiltersInfo>All field filters have been removed.</NoFiltersInfo>
                      )}
                      <FieldFiltersConfiguration />
                      <ModalSubmit
                        submitButtonText="Update filter"
                        submitLoadingText="Updateting filter..."
                        disabledSubmit={!isValid || isSubmitting}
                        onCancel={toggleBuilder}
                      />
                    </Form>
                  )}
                </Formik>
              </Popover.Dropdown>
            </Popover>
          </Container>
        );
      }}
    </Field>
  );
};

export default SearchBarFieldsFilter;
