import React, { useCallback, useState } from 'react';
import { styled, css } from 'styled-components';

import { RelativeTime, Timestamp } from 'components/common';
import { Alert, Row } from 'components/bootstrap';
import { HIDE_DL_SEARCH_SUCCESSFUL_SEARCH_ALERT } from 'data-lake/preview/Constants';
import Store from 'logic/local-storage/Store';

const StyledRow = styled(Row)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.xs};
  `,
);

const SuccessAlert = styled(Alert)(
  ({ theme }) => css`
    padding: ${theme.spacings.xs};
    margin: 0;
    & .mantine-Alert-wrapper {
      height: 100%;
      align-items: center;
      min-height: 30px;
    }
  `,
);

const SuccessfulSearchInfo = ({ executedAt }: { executedAt: string }) => {
  const [hideExplanation, setHideExplanation] = useState(Store.get(HIDE_DL_SEARCH_SUCCESSFUL_SEARCH_ALERT) === 'true');

  const hideSuccessExplanation = useCallback(() => {
    Store.set(HIDE_DL_SEARCH_SUCCESSFUL_SEARCH_ALERT, 'true');
    setHideExplanation(true);
  }, []);

  return (
    !hideExplanation && (
      <StyledRow>
        <SuccessAlert bsStyle="success" onDismiss={hideSuccessExplanation}>
          <span>
            The preview search job started{' '}
            <b>
              <RelativeTime dateTime={executedAt} />
            </b>{' '}
            at <Timestamp dateTime={executedAt} /> has been completed. Preview results are cleared after 24 hrs.
          </span>
        </SuccessAlert>
      </StyledRow>
    )
  );
};

export default SuccessfulSearchInfo;
