import * as React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/common';
import { DataWell, Col } from 'common/components';
import type { Pack } from 'illuminate/types';

import PackRecsAndDeps from '../PackRecsAndDeps';
import { useGetRequirements } from '../utils';

const Description = styled.p`
  white-space: pre-wrap;
`;

type Props = {
  pack: Pack,
};

function ExpandedPackDetails({ pack }: Props) {
  const { fetchingAllPacks, getRequiredPacks, getRequiredByPacks } = useGetRequirements();
  const requiredPacks = React.useMemo(() => getRequiredPacks(pack), [pack, getRequiredPacks]);
  const requiredByPacks = React.useMemo(() => getRequiredByPacks(pack), [pack, getRequiredByPacks]);

  const showRequirements = React.useMemo(() => (
    requiredPacks.length > 0
    || requiredByPacks.length > 0
    || pack.version_requirements.length > 0
  ), [requiredPacks, requiredByPacks, pack]);

  return fetchingAllPacks ? <Spinner text="Fetching details" /> : (
    <Col $gap="xxs" $align="stretch">
      <DataWell>
        <Col>
          <strong>Description:</strong>
          <Description>{pack.description}</Description>
        </Col> 
      </DataWell>
      {showRequirements && (
        <DataWell>
          <PackRecsAndDeps
            requiredByPacks={requiredByPacks}
            dependsOnPacks={requiredPacks}
            requiredVersions={pack.version_requirements}
          />
        </DataWell>
      )}
    </Col>
  )
}

export default ExpandedPackDetails;
