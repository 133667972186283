import { useQuery } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import type { ExportData, ExportDataResponse, WidgetType } from 'export/types';
import { defaultOnError } from 'util/conditional/onError';

const url = qualifyUrl('plugins/org.graylog.plugins.widgetexport/widgetexport');
export const fetchExportData = <T, W>(exportCallId: string): Promise<ExportData<T, W>> =>
  fetch<ExportDataResponse<T>>('GET', `${url}/${exportCallId}`).then(
    ({ widget: { widget_type: widgetType, render_type: renderType, ...rest }, data }): ExportData<T, W> => ({
      data,
      widget: {
        widgetType,
        renderType,
        ...rest,
      } as W,
    }),
  );

const useExportData = <T, W = WidgetType>(
  exportCallId: string,
): {
  data: ExportData<T, W>;
  refetch: () => void;
  isFetching: boolean;
} => {
  const { data, refetch, isFetching } = useQuery<ExportData<T, W>>(
    ['exported-data', exportCallId],
    () => defaultOnError(fetchExportData(exportCallId), 'Fetching data failed with status', 'Could not retrieve data'),
    {
      keepPreviousData: true,
      enabled: !!exportCallId,
    },
  );

  return {
    data,
    refetch,
    isFetching,
  };
};

export default useExportData;
