import { List } from 'immutable';

import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import type {
  ConfigFormValues,
  OidcConfigFormValues,
  OidcSubmitPayload,
  OktaConfigFormValues,
} from 'authentication/components/oidc/config/types';

function payloadFromFormValues(values: ConfigFormValues): OidcSubmitPayload {
  const isOkta = values.type === OKTA_TYPE_KEY;

  return {
    title: values.title,
    description: values.description,
    default_roles: List(values.defaultRoles.split(',')),
    config: {
      type: values.type,
      ...(isOkta
        ? {
            okta_base_url: (values as OktaConfigFormValues).oktaBaseUrl,
          }
        : {
            base_url: (values as OidcConfigFormValues).baseUrl,
            claims: values.claims,
          }),
      callback_url: values.callbackUrl,
      client_id: values.clientId,
      token_verifier_connect_timeout: values.tokenVerifierConnectTimeout || 10,
      client_secret:
        typeof values.clientSecret === 'undefined' ? { keep_value: true } : { set_value: values.clientSecret },
    },
  };
}

export default payloadFromFormValues;
