import { useMutation } from '@tanstack/react-query';

import { Illuminate } from '@graylog/enterprise-api';

const enableBundles = async ({ processing_pack_ids = [], spotlight_pack_ids = [] }) =>
  Illuminate.enableCurrentPacks({ processing_pack_ids, spotlight_pack_ids });

const useSetupIlluminate = () => {
  const enableBundlesMutation = useMutation(enableBundles, { retry: false });

  return {
    enableBundlesMutation,
  };
};

export default useSetupIlluminate;
