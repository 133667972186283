import styled from 'styled-components';

const DataWell = styled.div<{ $color?: 'background' | 'content' }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings.md};
  background-color: ${({ theme, $color }) => theme.colors.global[$color || 'background']};
`;

export default DataWell;

