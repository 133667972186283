import React from 'react';

import { Input } from 'components/bootstrap';
import type { CSVFileAdapterConfig } from 'lookup/types/IlluminateCSVFileAdapterConfig';

type Props = {
  config: CSVFileAdapterConfig;
};

const IlluminateCSVFileAdapterFieldSet = ({ config }: Props) => (
  <fieldset>
    <Input
      type="text"
      id="path"
      name="path"
      label="File path"
      autoFocus
      value={config.path}
      labelClassName="col-sm-3"
      wrapperClassName="col-sm-9"
      disabled
    />
    <Input
      type="number"
      id="check_interval"
      name="check_interval"
      label="Check interval"
      required
      help="The interval to check if the CSV file needs a reload. (in seconds)"
      value={config.check_interval}
      labelClassName="col-sm-3"
      wrapperClassName="col-sm-9"
      disabled
    />
    <Input
      type="text"
      id="separator"
      name="separator"
      label="Separator"
      required
      help="The delimiter to use for separating entries."
      value={config.separator}
      labelClassName="col-sm-3"
      wrapperClassName="col-sm-9"
      disabled
    />
    <Input
      type="text"
      id="quotechar"
      name="quotechar"
      label="Quote character"
      required
      help="The character to use for quoted elements."
      value={config.quotechar}
      labelClassName="col-sm-3"
      wrapperClassName="col-sm-9"
      disabled
    />
    <Input
      type="text"
      id="key_column"
      name="key_column"
      label="Key column"
      required
      help="The column name that should be used for the key lookup."
      value={config.key_column}
      labelClassName="col-sm-3"
      wrapperClassName="col-sm-9"
      disabled
    />
    <Input
      type="text"
      id="value_column"
      name="value_column"
      label="Value column"
      required
      help="The column name that should be used as the value for a key."
      value={config.value_column}
      labelClassName="col-sm-3"
      wrapperClassName="col-sm-9"
      disabled
    />
    <Input
      type="checkbox"
      id="case_insensitive_lookup"
      name="case_insensitive_lookup"
      label="Allow case-insensitive lookups"
      checked={config.case_insensitive_lookup}
      help="Enable if the key lookup should be case-insensitive."
      wrapperClassName="col-md-offset-3 col-md-9"
      disabled
    />
    <Input
      type="checkbox"
      id="cidr_lookup"
      name="cidr_lookup"
      label="CIDR lookup"
      checked={config.cidr_lookup}
      help="Enable if the keys in the lookup table are in CIDR notation and lookups will be done with IPs"
      wrapperClassName="col-md-offset-3 col-md-9"
      disabled
    />
  </fieldset>
);

export default IlluminateCSVFileAdapterFieldSet;
