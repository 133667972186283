import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import type { WidgetComponentProps } from 'views/types';
import { PaginatedList } from 'components/common';
import type { SearchTypeOptions } from 'views/logic/search/GlobalOverride';
import reexecuteSearchTypes from 'views/components/widgets/reexecuteSearchTypes';
import useViewsDispatch from 'views/stores/useViewsDispatch';
import useAutoRefresh from 'views/hooks/useAutoRefresh';
import ErrorWidget from 'views/components/widgets/ErrorWidget';
import useOnSearchExecution from 'views/hooks/useOnSearchExecution';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';

import InvestigationsTable from './InvestigationsTable';

import { PAGINATION } from '../Constants';
import type InvestigationsWidgetSortConfig from '../logic/InvestigationsWidgetSortConfig';
import type { InvestigationsListResult } from '../types';
import type InvestigationsListConfig from '../logic/InvestigationsWidgetConfig';

type Pagination = {
  pageErrors: Array<{ description: string }>;
  currentPage: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 100%;
`;

const useResetPaginationOnSearchExecution = (setPagination: (pagination: Pagination) => void, currentPage) => {
  const resetPagination = useCallback(() => {
    if (currentPage !== 1) {
      setPagination({ currentPage: 1, pageErrors: [] });
    }
  }, [currentPage, setPagination]);
  useOnSearchExecution(resetPagination);
};

const useHandlePageChange = (
  searchTypeId: string,
  setLoadingState: (loading: boolean) => void,
  setPagination: (pagination: Pagination) => void,
) => {
  const dispatch = useViewsDispatch();
  const { stopAutoRefresh } = useAutoRefresh();

  return useCallback(
    (pageNo: number) => {
      // execute search with new offset
      const searchTypePayload: SearchTypeOptions<{
        page: number;
        per_page: number;
      }> = {
        [searchTypeId]: {
          page: pageNo,
          per_page: PAGINATION.PER_PAGE,
        },
      };

      stopAutoRefresh();
      setLoadingState(true);

      return dispatch(reexecuteSearchTypes(searchTypePayload)).then((response) => {
        const { result } = response.payload;
        setLoadingState(false);

        setPagination({
          pageErrors: result.errors,
          currentPage: pageNo,
        });
      });
    },
    [dispatch, searchTypeId, setLoadingState, setPagination, stopAutoRefresh],
  );
};

const InvestigationsList = ({
  data,
  config,
  onConfigChange,
  setLoadingState,
}: WidgetComponentProps<InvestigationsListConfig, InvestigationsListResult>) => {
  const [{ currentPage, pageErrors }, setPagination] = useState<Pagination>({
    pageErrors: [],
    currentPage: PAGINATION.INITIAL_PAGE,
  });
  const onRenderComplete = useContext(RenderCompletionCallback);
  useResetPaginationOnSearchExecution(setPagination, currentPage);
  const handlePageChange = useHandlePageChange(data.id, setLoadingState, setPagination);

  const refreshCurrentPage = useCallback(() => handlePageChange(currentPage), [currentPage, handlePageChange]);

  const onSortChange = useCallback(
    (newSort: InvestigationsWidgetSortConfig) => {
      const newConfig = config.toBuilder().sort(newSort).build();

      return onConfigChange(newConfig);
    },
    [config, onConfigChange],
  );

  useEffect(() => {
    onRenderComplete();
  }, [onRenderComplete]);

  return (
    <Container>
      <PaginatedList
        onChange={handlePageChange}
        useQueryParameter={false}
        activePage={currentPage}
        pageSize={PAGINATION.PER_PAGE}
        showPageSizeSelect={false}
        totalItems={data.totalResults ?? 0}>
        {!pageErrors?.length ? (
          <InvestigationsTable
            config={config}
            onArchive={refreshCurrentPage}
            onDelete={refreshCurrentPage}
            setLoadingState={setLoadingState}
            onSortChange={onSortChange}
            investigations={data.investigations}
          />
        ) : (
          <ErrorWidget errors={pageErrors} />
        )}
      </PaginatedList>
    </Container>
  );
};

export default InvestigationsList;
