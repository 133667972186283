import type Search from 'views/logic/search/Search';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import type { SearchTypeResult } from 'views/types';
import type { DataLakeLogsResult } from 'data-lake/preview/Types';
import useSearchResult from 'data-lake/preview/hooks/useSearchResult';
import type SearchError from 'views/logic/SearchError';

const isDataLakeResults = (searchTypeResult: SearchTypeResult): searchTypeResult is DataLakeLogsResult =>
  searchTypeResult.type === DataLakeWidget.type;

const identifyErrors = (
  searchErrors: Array<Partial<SearchError>> | undefined,
  queryErrors: Array<Partial<SearchError>> | undefined,
) => {
  if (searchErrors?.length > 0) {
    return searchErrors;
  }

  if (queryErrors?.length > 0) {
    return queryErrors;
  }

  return [];
};

const useWidgetResults = (search: Search | undefined) => {
  const { result: searchResult } = useSearchResult() ?? {};
  const activeQuery = search?.queries.first();
  const activeQueryId = activeQuery?.id;

  const query = searchResult?.results?.[activeQueryId];
  const errors = identifyErrors(searchResult?.errors, query?.errors);
  const searchTypeResults = query?.searchTypes[activeQuery.searchTypes[0].id];

  const result = { widgetData: undefined, errors, executedAt: query?.timestamp, duration: query?.duration };

  if (!search || !activeQueryId || !searchTypeResults || !isDataLakeResults(searchTypeResults)) {
    return result;
  }

  return {
    ...result,
    widgetData: searchTypeResults,
  };
};

export default useWidgetResults;
