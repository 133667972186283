import * as React from 'react';
import { useCallback } from 'react';

import { FormControl } from 'components/bootstrap';
import * as FormsUtils from 'util/FormsUtils';

import type { ParameterInputComponentProps } from '../ParameterTypes';

const GenericParameterInput = ({
  parameter: { optional, name },
  value,
  onChange,
  onBlur,
  inputSize,
}: ParameterInputComponentProps) => {
  const _onChange = useCallback(
    (e) => {
      const newValue = FormsUtils.getValueFromInput(e.target);

      return onChange(name, newValue);
    },
    [name, onChange],
  );

  return (
    <FormControl
      type="text"
      bsSize={inputSize}
      aria-label={name}
      data-testid={`generic-parameter-input-${name}`}
      placeholder="Please enter a parameter value..."
      required={!optional}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={_onChange}
    />
  );
};

export default GenericParameterInput;
