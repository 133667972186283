import { useMutation, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import { AI_REPORT_CONFIGURATION_QUERY_STRING } from 'security-app/components/Investigations/AIReport/hooks/useAIReportConfiguration';

const urlPrefix = '/plugins/org.graylog.plugins.securityapp.investigations/ai/config/investigations_ai_reports_enabled';

const enableAIReport = () => fetch('POST', qualifyUrl(urlPrefix));

const disableAIReport = () => fetch('DELETE', qualifyUrl(urlPrefix));

const useEnableAIReportMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: enable } = useMutation(enableAIReport, {
    onSuccess: () => {
      UserNotification.success('Configuration updated successfully.');
      queryClient.invalidateQueries([AI_REPORT_CONFIGURATION_QUERY_STRING]);
    },
    onError: (error) => {
      UserNotification.error(`Configuration update failed with error: ${error}.`);
    },
  });

  const { mutateAsync: disable } = useMutation(disableAIReport, {
    onSuccess: () => {
      UserNotification.success('Configuration updated successfully.');
      queryClient.invalidateQueries([AI_REPORT_CONFIGURATION_QUERY_STRING]);
    },
    onError: (error) => {
      UserNotification.error(`Configuration update failed with error: ${error}.`);
    },
  });

  return {
    enable,
    disable,
  };
};

export default useEnableAIReportMutation;
