import { Map, Set } from 'immutable';

import Widget, { widgetAttributesForComparison } from 'views/logic/widgets/Widget';
import type { WidgetState } from 'views/logic/widgets/Widget';
import type { TimeRange } from 'views/logic/queries/Query';
import isDeepEqual from 'stores/isDeepEqual';
import isEqualForSearch from 'views/stores/isEqualForSearch';
import type MessagesWidget from 'views/logic/widgets/MessagesWidget';
import { MESSAGE_FIELD } from 'views/Constants';
import type { FiltersType, SearchFilter } from 'views/types';
import type { QueryString } from 'views/logic/queries/types';

import LogViewWidgetConfig from './LogViewWidgetConfig';

export default class LogViewWidget extends Widget {
  constructor(
    id: string,
    config: LogViewWidgetConfig,
    filter: string | undefined | null,
    timerange: TimeRange | undefined | null,
    query: QueryString | undefined | null,
    streams: Array<string> = [],
    stream_categories: Array<string> = [],
    filters: FiltersType | Array<SearchFilter> = [],
  ) {
    super(id, LogViewWidget.type, config, filter, timerange, query, streams, stream_categories, filters);
  }

  static type = 'logs';

  static defaultTitle = 'Untitled Log View';

  // eslint-disable-next-line class-methods-use-this
  get isExportable() {
    return true;
  }

  static fromJSON(value: WidgetState) {
    const { id, config, filter, timerange, query, streams, stream_categories, filters } = value;

    return new LogViewWidget(
      id,
      LogViewWidgetConfig.fromJSON(config),
      filter,
      timerange,
      query,
      streams,
      stream_categories,
      filters,
    );
  }

  static fromMessageTable(messageTable: MessagesWidget): LogViewWidget {
    const { showMessageRow, fields } = messageTable.config;
    const needsMessageField = showMessageRow && !fields.includes(MESSAGE_FIELD);

    const logViewFields = Set(needsMessageField ? [...fields, MESSAGE_FIELD] : fields);

    return LogViewWidget.builder()
      .id(messageTable.id)
      .query(messageTable.query)
      .timerange(messageTable.timerange)
      .streams(messageTable.streams)
      .streamCategories(messageTable.streamCategories)
      .filter(messageTable.filter)
      .filters(messageTable.filters)
      .config(LogViewWidgetConfig.createDefault().toBuilder().fields(logViewFields).build())
      .build();
  }

  equals(other: any) {
    if (other instanceof LogViewWidget) {
      return widgetAttributesForComparison.every((key) => isDeepEqual(this._value[key], other[key]));
    }

    return false;
  }

  equalsForSearch(other: any) {
    if (other instanceof LogViewWidget) {
      return widgetAttributesForComparison.every((key) => isEqualForSearch(this._value[key], other[key]));
    }

    return false;
  }

  toBuilder() {
    const { id, config, filter, timerange, query, streams, stream_categories, filters } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Map({ id, config, filter, timerange, query, streams, stream_categories, filters }));
  }

  static builder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder();
  }

  static isLogViewWidget(widget: Widget) {
    return widget && widget.type === LogViewWidget.type;
  }
}

class Builder extends Widget.Builder {
  build(): LogViewWidget {
    const { id, config, filter, timerange, query, streams, stream_categories, filters } = this.value.toObject();

    return new LogViewWidget(id, config, filter, timerange, query, streams, stream_categories, filters);
  }
}
