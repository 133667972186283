import * as React from 'react';

import { Icon } from 'components/common';
import { RiskScoreBadge, DataTable, DataWell } from 'security-app/components/common';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import type { AssetAPIType, UserAssetAPIType } from 'security-app/hooks/api/assetsAPI.types';

type Props = {
  userAsset: AssetAPIType<UserAssetAPIType>;
};

function UserData({ userAsset }: Props) {
  return (
    <>
      <DataWell>
        <Row>
          <Icon name="person" />
          <Col>
            <small>Full Name</small>
            <span>
              {userAsset.details.first_name} {userAsset.details.last_name}
            </span>
          </Col>
        </Row>
      </DataWell>
      <DataWell>
        <Row>
          <span>Risk Score</span>
          <RiskScoreBadge riskScore={userAsset.risk_score_normalized} />
        </Row>
      </DataWell>
      <DataTable condensed striped>
        <tbody>
          <DataTable.Row>
            <DataTable.Item $label>Categories</DataTable.Item>
            <DataTable.Item>{userAsset.category.join(', ') || <i>Uncategorized</i>}</DataTable.Item>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Item $label>Type</DataTable.Item>
            <DataTable.Item>{userAsset.details.type}</DataTable.Item>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Item $label>Usernames</DataTable.Item>
            <DataTable.Item>{userAsset.details.usernames.join(', ') || <i>No usernames</i>}</DataTable.Item>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Item $label>User IDs</DataTable.Item>
            <DataTable.Item>{userAsset.details.user_ids.join(', ') || <i>No user IDs</i>}</DataTable.Item>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Item $label>Emails</DataTable.Item>
            <DataTable.Item>{userAsset.details.email_addresses.join(', ') || <i>No emails</i>}</DataTable.Item>
          </DataTable.Row>
        </tbody>
      </DataTable>
    </>
  );
}

export default UserData;
