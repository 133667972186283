import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { LicenseValidity, LicenseSubject } from 'license/types';
import type FetchError from 'logic/errors/FetchError';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { onError } from 'util/conditional/onError';

export const LICENSES_VALIDITY_KEY = 'licenses_validity';

const INITIAL_DATA = {
  valid: false,
  expired: false,
  violated: false,
};

const getLicenseValidityForSubject = (subject: LicenseSubject) =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.License.validityForSubject(subject).url));

const useLicenseValidityForSubject = (
  subject: LicenseSubject,
): {
  data: LicenseValidity;
  isInitialLoading: boolean;
  refetch: () => void;
} => {
  const { data, isInitialLoading, refetch } = useQuery<LicenseValidity, FetchError>(
    [LICENSES_VALIDITY_KEY, subject],
    () =>
      onError(getLicenseValidityForSubject(subject), (errorThrown: FetchError) => {
        if (!(errorThrown.status === 404)) {
          UserNotification.error(`Loading license status for subject failed with: ${errorThrown}`);
        }
      }),
    {
      retry: 0,
      refetchInterval: 600_000, // 10 min refetch interval,
    },
  );

  return {
    data: data ?? INITIAL_DATA,
    isInitialLoading,
    refetch,
  };
};

export default useLicenseValidityForSubject;
