import * as React from 'react';

import { singleton } from 'logic/singleton';

export type LicenseCheckContextType = {
  security: {
    isValid: boolean;
  };
  illuminate: {
    isValid: boolean;
  };
};

const LicenseCheckContext = React.createContext<LicenseCheckContextType | undefined>(undefined);

export default singleton('contexts.LicenseCheckContext', () => LicenseCheckContext);
