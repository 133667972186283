import { useMutation, useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';

export type StreamDataLakeConfigStatusProps = {
  streamIds: Array<string>;
  enabled: boolean;
};

const toggleStreamDataLakeConfigStatus = ({ streamIds, enabled }: StreamDataLakeConfigStatusProps) =>
  fetch('POST', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.stream_data_warehouse_set_config_status().url), {
    stream_ids: streamIds,
    enabled,
  });

const useStreamDataLakeStatusMutation = (): {
  onToggleStreamDataLakeConfigStatus: (streamDataLakeConfig: StreamDataLakeConfigStatusProps) => Promise<void>;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: onToggleStreamDataLakeConfigStatus } = useMutation(toggleStreamDataLakeConfigStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data-lake-config']);
    },
    onError: (errorThrown) => {
      UserNotification.error(
        `Toggling Data Lake config status failed with status: ${errorThrown}`,
        'Toggling Data Lake config status',
      );
    },
  });

  return { onToggleStreamDataLakeConfigStatus };
};

export default useStreamDataLakeStatusMutation;
