import React, { useState } from 'react';

import { Alert } from 'components/bootstrap';
import { Link } from 'components/common/router';
import DataLakeRoutes from 'data-lake/Routes';
import { HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY } from 'data-lake/Constants';
import Store from 'logic/local-storage/Store';

const RetrievalHelp = () => {
  const [hideHelpText, setHideHelpText] = useState(Store.get(HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY) === 'true');
  const hideRetrievalHelp = () => {
    Store.set(HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY, 'true');
    setHideHelpText(true);
  };

  return (
    !hideHelpText && (
      <Alert bsStyle="info" onDismiss={hideRetrievalHelp}>
        This will launch a retrieval operation to route messages from the Data Lake into the <b>Search Cluster</b>.
        <br />
        Retrieved messages that were not previously routed to the <b>Search Cluster </b> will count towards{' '}
        <b>Graylog License</b> usage.
        <br />
        Note that pulling messages from the Data Lake may take some time - progress may be tracked on the{' '}
        <Link to={DataLakeRoutes.ARCHIVE.LIST}>
          <b>Data Lake</b>
        </Link>{' '}
        page.
      </Alert>
    )
  );
};

export default RetrievalHelp;
