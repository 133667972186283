import * as React from 'react';
import { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';

import Icon from 'components/common/Icon';
import { widgetId, type AvailableDashboards } from 'report/report-creation/content/types';
import defaultTitle from 'views/components/defaultTitle';
import Popover from 'components/common/Popover';
import type { AvailableWidgetPreview as TAvailableWidgetPreview, WidgetRef } from 'report/types';
import usePluginEntities from 'hooks/usePluginEntities';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';
import ReportingWidgetContainer from 'report/common/ReportingWidgetContainer';
import AvailableWidgetPreview from 'report/common/AvailableWidgetPreview';
import { Row, Column, StyledCheckbox, SmallColumn } from 'report/report-creation/content/common';
import Alert from 'components/bootstrap/Alert';
import Badge from 'components/bootstrap/Badge';

const WidgetIcon = () => <Icon name="analytics" type="regular" />;
const PreviewTitle = styled.h3`
  margin-bottom: 10px;
`;

const PreviewContainer = styled.div`
  max-height: 600px;
  overflow: auto;
`;

export const CheckboxColumn = styled(SmallColumn)`
  text-align: right;
`;

const WidgetPreview = ({ widget, dashboard }: Pick<WidgetRowProps, 'widget' | 'dashboard'>) => {
  const widgetTypes = usePluginEntities('enterpriseWidgets');
  const widgetType = widgetTypes.find(
    (w) => w.type.toUpperCase() === (widget.type ? widget.type.toUpperCase() : 'unknown'),
  );

  return widgetType ? (
    <AutoRefreshDisabledProvider>
      <ReportingWidgetContainer>
        {({ height, width }) => (
          <AvailableWidgetPreview
            dashboardId={dashboard.id}
            widgetId={widget.id}
            widget={widget}
            searchId={dashboard.search_id}
            showHeading={false}
            showCaption={false}
            showHandle={false}
            interactive={false}
            limitHeight={false}
            height={height}
            width={width}
            hideQuery
            hideDescription
          />
        )}
      </ReportingWidgetContainer>
    </AutoRefreshDisabledProvider>
  ) : (
    <Alert bsStyle="warning" onDismiss={() => {}}>
      Could not find widget type <em>{widget.type}</em>. Please ensure the plugin is loaded.
    </Alert>
  );
};

type WidgetRowProps = {
  inReport: boolean;
  widget: TAvailableWidgetPreview;
  dashboard: AvailableDashboards;
  checked: boolean;
  onCheck: (id: WidgetRef) => void;
  onUncheck: (id: WidgetRef) => void;
};

const InReportBadge = styled(Badge)`
  margin-left: 5px;
  display: inline-flex;
`;

const InReport = () => <InReportBadge bsStyle="primary">In Report</InReportBadge>;

const WidgetRow = ({ inReport, widget, dashboard, checked, onCheck, onUncheck }: WidgetRowProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const togglePreview = useCallback(() => setShowPreview((_expanded) => !_expanded), []);
  const closePreview = useCallback(() => setShowPreview(false), []);
  const preview = showPreview ? <WidgetPreview widget={widget} dashboard={dashboard} /> : null;
  const id = useMemo(() => widgetId(widget, dashboard), [dashboard, widget]);
  const _onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => (e.target.checked ? onCheck(id) : onUncheck(id)),
    [id, onCheck, onUncheck],
  );
  const _disabled = inReport || !widget.eligible;
  const widgetTitle = widget.title ?? defaultTitle(widget);
  // eslint-disable-next-line no-nested-ternary
  const title = inReport
    ? 'Widget is already in report.'
    : !widget.eligible
      ? 'Widget is not eligible for reporting.'
      : `Click to see preview of widget "${widgetTitle}"`;

  return (
    <Row $disabled={_disabled} title={title}>
      <SmallColumn />
      <CheckboxColumn $displayCursor={false}>
        <StyledCheckbox
          onChange={_onChange}
          checked={checked}
          disabled={_disabled}
          title={`${checked ? 'Deselect' : 'Select'} widget "${widgetTitle}"`}
        />
      </CheckboxColumn>
      <Popover
        withArrow
        width={800}
        opened={showPreview}
        closeOnClickOutside
        onClose={closePreview}
        position="bottom-start">
        <Popover.Target>
          <Column onClick={togglePreview}>
            <WidgetIcon /> {widgetTitle} {inReport ? <InReport /> : null}
          </Column>
        </Popover.Target>
        <Popover.Dropdown>
          <PreviewTitle>Preview of &quot;{widgetTitle}&quot;</PreviewTitle>
          <PreviewContainer>{preview}</PreviewContainer>
        </Popover.Dropdown>
      </Popover>
    </Row>
  );
};

export default WidgetRow;
