import * as React from 'react';

import type { ColumnRenderers } from 'components/common/EntityDataTable';
import type { ReportStatus, ReportHistory } from 'report/types';
import StatusCell from 'report/report-history-page/StatusCell';
import HasAsset from 'report/report-history-page/HasAsset';
import MessageCell from 'report/report-history-page/MessageCell';
import { LinkContainer } from 'components/common/router';
import ReportRoutes from 'report/ReportRoutes';
import { Button } from 'components/bootstrap';

const customColumnRenderers = (reportId: string): ColumnRenderers<ReportHistory> => ({
  attributes: {
    status: {
      staticWidth: 140,
      renderCell: (value: ReportStatus) => <StatusCell value={value} />,
    },
    message: {
      renderCell: (value: string) => <MessageCell value={value} />,
    },
    has_asset: {
      staticWidth: 100,
      renderCell: (value: boolean, history: ReportHistory) => <HasAsset value={value} status={history.status} />,
    },
    recipients: {
      renderCell: (_, history) =>
        history.recipients && (
          <LinkContainer key="configuration-button" to={ReportRoutes.EDIT.delivery(reportId)}>
            <Button bsSize="xsmall">{history.recipients?.length}</Button>
          </LinkContainer>
        ),
      staticWidth: 100,
    },
  },
});

export default customColumnRenderers;
