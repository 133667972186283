import * as React from 'react';
import { useCallback } from 'react';
import * as Immutable from 'immutable';

import ParameterDeclarationForm from 'enterprise/parameters/components/ParameterDeclarationForm';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import newParameterState from 'enterprise/parameters/logic/newParameterState';
import useViewsDispatch from 'views/stores/useViewsDispatch';
import useParametersMap from 'views/hooks/useParametersMap';
import { declareParameters } from 'views/logic/slices/searchExecutionSlice';

type Props = {
  onClose: () => void;
};

const CreateParameterDialog = ({ onClose }: Props) => {
  const dispatch = useViewsDispatch();
  const existingParameters = useParametersMap();

  const onSave = useCallback(
    (newParameters: ParameterMap) => dispatch(declareParameters(newParameters)).then(onClose),
    [dispatch, onClose],
  );

  const newParameters = Immutable.Map({
    newParameter: newParameterState('newParameter'),
  });

  return (
    <ParameterDeclarationForm
      allowEditingName
      existingParameters={existingParameters}
      parameters={newParameters}
      data-testid="parameter-declaration-form"
      onSave={onSave}
      onClose={onClose}
    />
  );
};

export default CreateParameterDialog;
