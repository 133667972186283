import React from 'react';

import { NoSearchResult, NoEntitiesExist } from 'components/common';

type Props = {
  noSearchResultText: string;
  noEntitiesExist: string;
  colSpan: number;
  query?: string;
  filters?: { [key: string]: string };
};

const EmptyList = ({ noSearchResultText, noEntitiesExist, colSpan, query = null, filters = {} }: Props) => (
  <tr>
    <td colSpan={colSpan}>
      {query || Object.keys(filters).length > 0 ? (
        <NoSearchResult> {noSearchResultText}</NoSearchResult>
      ) : (
        <NoEntitiesExist> {noEntitiesExist}</NoEntitiesExist>
      )}
    </td>
  </tr>
);

export default EmptyList;
