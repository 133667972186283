import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES, DATA_LAKE_CONFIG_QUERY_KEYS } from 'data-lake/Constants';

const fetchDataLakeConfig = () =>
  fetch('GET', qualifyUrl(`${DATA_LAKE_API_ROUTES.DataLakeAPIController.config.base().url}`));
export type DataLakeConfig = {
  active_backend: string;
  iceberg_commit_interval: string;
  iceberg_target_file_size: number;
  parquet_row_group_size: number;
  parquet_page_size: number;
  journal_reader_batch_size: number;
  optimize_job_enabled: boolean;
  optimize_job_interval: string;
  parallel_retrieval_enabled: boolean;
  retrieval_convert_threads: number;
  retrieval_convert_batch_size: number;
  retrieval_inflight_requests: number;
  retrieval_bulk_batch_size: number;
  retention_time?: string;
};

const useDataLakeConfig = (
  enabled = true,
): {
  data: DataLakeConfig;
  refetch: () => void;
  isInitialLoading: boolean;
  isSuccess: boolean;
  error: Error;
} => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<DataLakeConfig, Error>(
    [...DATA_LAKE_CONFIG_QUERY_KEYS],
    () => fetchDataLakeConfig(),
    {
      keepPreviousData: true,
      enabled,
    },
  );

  return {
    data: data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  };
};

export default useDataLakeConfig;
