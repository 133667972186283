import React, { useContext } from 'react';

import type { SelectableLogViewMessage, LogViewBulkSelection } from 'logview/types';
import SelectedEntitiesProvider from 'components/common/EntityDataTable/contexts/SelectedEntitiesProvider';
import SelectableEntitiesContext from 'logview/components/contexts/SelectableLogViewMessagesContext';

type Props = React.PropsWithChildren<{ bulkSelection?: LogViewBulkSelection }>;
const LogViewSelectedEntitiesProvider = ({ children = null, bulkSelection = null }: Props) => {
  const { selectableLogViewMessages } = useContext(SelectableEntitiesContext);

  return (
    <SelectedEntitiesProvider<SelectableLogViewMessage>
      initialSelection={bulkSelection?.initialSelection}
      onChangeSelection={bulkSelection?.onChangeSelection}
      entities={selectableLogViewMessages}>
      {children}
    </SelectedEntitiesProvider>
  );
};

export default LogViewSelectedEntitiesProvider;
