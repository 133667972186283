import React, { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { Field } from 'formik';

import { Button, Col, Input, Row } from 'components/bootstrap';
import { ConfirmDialog } from 'components/common';
import AppConfig from 'util/AppConfig';

import useRepositories from './repository-management/hooks/useRepositories';
import CreateRepositoryModal from './repository-management/CreateRepositoryModal';
import RepositorySelect from './repository-management/RepositorySelect';

const RepositoryManagement = ({ valuesPrefix }: { valuesPrefix?: string }) => {
  const isCloud = AppConfig.isCloud();

  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const [repoToDelete, setRepoToDelete] = useState<string | undefined>(undefined);
  const { onDeleteRepository } = useRepositories();

  const handleDelete = (event: SyntheticEvent, value: string) => {
    event.stopPropagation();
    setRepoToDelete(value);
    setShowConfirmDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmDeleteDialog(false);
    setRepoToDelete(undefined);
  };

  const onDelete = () => {
    onDeleteRepository(repoToDelete);
    setShowConfirmDeleteDialog(false);
    setRepoToDelete(undefined);
  };

  const fieldName = () => {
    if (valuesPrefix) {
      return `${valuesPrefix}.data_tiering.warm_tier_repository_name`;
    }

    return 'data_tiering.warm_tier_repository_name';
  };

  return (
    <>
      <Field name={fieldName()}>
        {({ meta: { error } }) => (
          <Input
            bsStyle={error ? 'error' : undefined}
            help="Which warm storage repository should this index set be stored in"
            error={error}
            id="default-roles-select"
            label="Repository">
            <Row>
              <Col md={8}>
                <RepositorySelect fieldName={fieldName()} handleDelete={handleDelete} />
              </Col>
              <Col md={4} className="text-right">
                {!isCloud && (
                  <Button onClick={() => setShowCreationModal(true)}>Create new warm storage repository</Button>
                )}
              </Col>
            </Row>
          </Input>
        )}
      </Field>
      <CreateRepositoryModal show={showCreationModal} hideModal={() => setShowCreationModal(false)} />
      {showConfirmDeleteDialog && (
        <ConfirmDialog
          show={showConfirmDeleteDialog}
          onConfirm={onDelete}
          onCancel={handleCancelDelete}
          title="Delete warm storage repository">
          <>
            <p>
              You are about to delete the warm storage repository <strong>{repoToDelete}</strong>. This action cannot be
              undone.
            </p>
            <p>Are you sure you want to continue?</p>
          </>
        </ConfirmDialog>
      )}
    </>
  );
};

export default RepositoryManagement;
