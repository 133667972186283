import * as React from 'react';
import { useCallback, useState } from 'react';
import isObjectLike from 'lodash/isObjectLike';
import isArray from 'lodash/isArray';
import styled from 'styled-components';

import { Col, Row } from 'components/bootstrap';
import { Timestamp } from 'components/common';

const IndentNestedLists = styled.div`
  dl {
    margin-left: 10px;
  }
`;

type TypeSpecificEntryProps = {
  value: any;
};

const TypeSpecificEntry = ({ value }: TypeSpecificEntryProps) => {
  if (isArray(value)) {
    return <ArrayEntry array={value} />;
  }

  if (isObjectLike(value)) {
    return <ObjectEntry object={value} />;
  }

  return value || <i>Not present</i>;
};

type KeyValueEntryProps = {
  name: string;
  value: any;
};
const KeyValueEntry = ({ name, value }: KeyValueEntryProps) => (
  <>
    <dt key={`${name}-title`}>{name}</dt>
    <dd key={`${name}-description`}>
      <TypeSpecificEntry value={value} />
    </dd>
  </>
);

type ArrayEntryProps = {
  array: Array<unknown>;
};

const ArrayEntry = ({ array }: ArrayEntryProps) => (
  <ul>
    {array.map((i) => (
      <li key={JSON.stringify(i)}>
        <TypeSpecificEntry value={i} />
      </li>
    ))}
  </ul>
);

type ObjectEntryProps = {
  object: { [key: string]: unknown };
};
const ObjectEntry = ({ object = {} }: ObjectEntryProps) => (
  <dl>
    {Object.entries(object).map(([name, value]) => (
      <KeyValueEntry key={`${name}-${String(value)}`} name={name} value={value} />
    ))}
  </dl>
);

type Attributes = { [key: string]: unknown };
type Entry = {
  action: string;
  actor: string;
  actor_formatted: string;
  attributes: Attributes;
  message: string;
  namespace: string;
  node_id: string;
  object: string;
  success_status: 'SUCCESS' | 'FAILURE';
  timestamp: string;
};

type DetailsProps = {
  entry: Entry;
};

const Details = ({ entry }: DetailsProps) => {
  const { action, attributes, node_id: nodeId, object, actor, namespace, success_status: successStatus } = entry;

  return (
    <Row>
      <Col md={6}>
        <dl>
          <dt>Actor</dt>
          <dd>{actor}</dd>
          <dt>Namespace</dt>
          <dd>{namespace}</dd>
          <dt>Object</dt>
          <dd>{object}</dd>
          <dt>Action</dt>
          <dd>{action}</dd>
          <dt>Success status</dt>
          <dd>{successStatus}</dd>
          <dt>Node ID</dt>
          <dd>{nodeId}</dd>
        </dl>
      </Col>
      <Col md={6}>
        <IndentNestedLists>
          <ObjectEntry object={attributes} />
        </IndentNestedLists>
      </Col>
    </Row>
  );
};

type Props = {
  entry: Entry;
};

const AuditLogTableEntry = ({ entry }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = useCallback(() => setShowDetails(!showDetails), [showDetails, setShowDetails]);
  const bodyStyle = showDetails ? 'auditlog-entry details-visible' : 'auditlog-entry';

  const { timestamp, actor_formatted: actorFormatted, action, message } = entry;

  return (
    <tbody className={bodyStyle}>
      <tr onClick={toggleDetails} className="toggle-details">
        <td>
          <Timestamp dateTime={timestamp} />
        </td>
        <td>{actorFormatted}</td>
        <td>{action}</td>
        <td className="auditlog-message">{message}</td>
      </tr>
      {showDetails && (
        <tr className="auditlog-entries-details">
          <td colSpan={4}>
            <Details entry={entry} />
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default AuditLogTableEntry;
