import * as React from 'react';
import * as Immutable from 'immutable';
import type { PluginExports } from 'graylog-web-plugin/plugin';

import LookupTableParameterForm from 'enterprise/parameters/components/lookup-table/LookupTableParameterForm';
import LookupTableParameter from 'views/logic/parameters/LookupTableParameter';
import LookupTableParameterPreview from 'enterprise/parameters/components/lookup-table/LookupTableParameterPreview';
import ExecuteViewWithValue from 'enterprise/parameters/components/views/ExecuteViewWithValue';
import CreateParameterDialog from 'enterprise/parameters/creatoractions/CreateParameterDialog';
import ParameterCompletion from 'enterprise/parameters/components/searchbar/completions/ParameterCompletion';
import type { Completer } from 'views/components/searchbar/SearchBarAutocompletions';
import type { OverrideProps } from 'views/components/WidgetOverrideElements';
import GenericParameterForm from 'enterprise/parameters/components/generic/GenericParameterForm';
import GenericParameterInput from 'enterprise/parameters/components/generic/GenericParameterInput';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import Parameter from 'views/logic/parameters/Parameter';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import OptionParameterInput from 'enterprise/parameters/components/option/OptionParameterInput';
import OptionParameterForm from 'enterprise/parameters/components/option/OptionParameterForm';
import FieldSourceForm from 'enterprise/parameters/components/option/FieldSourceForm';
import StaticSourceForm from 'enterprise/parameters/components/option/StaticSourceForm';
import { FieldSource, StaticSource } from 'enterprise/parameters/components/option/OptionSource';
import validLicensePresent from 'license/ValidLicensePresent';
import parameterSearchBarControls from 'enterprise/parameters/parameterSearchBarControls';
import { MISSING_BUCKET_NAME } from 'views/Constants';
import type { ViewHook, ViewHookArguments } from 'views/logic/hooks/ViewHook';

import bindParametersFromQuery from './hooks/BindParametersFromQuery';
import showParameterFormIfMissingValues from './hooks/ShowParameterFormIfMissingValues';
import MissingEnterpriseLicense from './components/MissingEnterpriseLicense';
import ParameterBar from './components/ParameterBar';
import WidgetEditModeParameterBar from './components/WidgetEditModeParameterBar';
import QueryValidationParameterDeclaration from './components/QueryValidationParameterDeclaration';
import CopyParamsToView from './hooks/CopyParamsToView';

const checkLicenseOrNoop =
  (
    fn: ViewHook,
    fallback = ({ view, executionState }: ViewHookArguments) => Promise.resolve([view, executionState] as const),
  ): ViewHook =>
  (args: ViewHookArguments) =>
    validLicensePresent() ? fn(args) : fallback(args);

class CheckLicenseOrNoop implements Completer {
  completer: Completer;

  constructor(completer: Completer) {
    this.completer = completer;
  }

  getCompletions(...args: Parameters<Completer['getCompletions']>) {
    return validLicensePresent() ? this.completer.getCompletions(...args) : [];
  }
}

Parameter.registerSubtype(OptionParameter.type, OptionParameter);

const ParameterBindings: PluginExports = {
  'views.components.searchBar': [() => (validLicensePresent() ? parameterSearchBarControls : null)],
  'views.elements.validationErrorExplanation': [
    (props) => (validLicensePresent() ? <QueryValidationParameterDeclaration {...props} /> : null),
  ],
  'views.elements.queryBar': [() => (validLicensePresent() ? <ParameterBar /> : null)],
  'views.overrides.widgetEdit': [
    ({ override }: OverrideProps) =>
      validLicensePresent() ? <WidgetEditModeParameterBar override={override} /> : null,
  ],
  'views.hooks.executingView': [checkLicenseOrNoop(showParameterFormIfMissingValues)],
  'views.hooks.loadingView': [
    checkLicenseOrNoop(
      ({ view, executionState }: ViewHookArguments) => Promise.resolve([view, executionState]),
      async ({ view, executionState }: ViewHookArguments) => {
        const usesParameters = !view?.search?.parameters?.isEmpty();
        const usesFiltersInWidgets = () =>
          view.state.some((viewState) => viewState.widgets.some((widget) => !widget.filters.isEmpty()));
        const usesFiltersInQuery = () =>
          view.search.queries.some((query) => !(query.filters ?? Immutable.List()).isEmpty());
        const usesFilters = view.type === 'DASHBOARD' ? usesFiltersInWidgets() : usesFiltersInQuery();

        if (usesParameters || usesFilters) {
          // eslint-disable-next-line no-throw-literal
          throw <MissingEnterpriseLicense usesParameters={usesParameters} usesFilters={usesFilters} />;
        }

        return [view, executionState] as const;
      },
    ),
    // checkLicenseOrNoop(loadParametersFromView),
    checkLicenseOrNoop(bindParametersFromQuery),
  ],
  'views.hooks.copyWidgetToDashboard': [CopyParamsToView],
  'views.hooks.copyPageToDashboard': [CopyParamsToView],
  'views.completers': [new CheckLicenseOrNoop(ParameterCompletion)],
  'views.requires.provided': ['parameters'],
  valueActions: [
    {
      type: 'execute-view-with-value',
      title: 'Insert into dashboard/search',
      isEnabled: ({ value }) => value !== MISSING_BUCKET_NAME,
      component: ExecuteViewWithValue,
      condition: validLicensePresent,
    },
  ],
  creators: [
    {
      type: 'generic',
      title: 'Parameter',
      component: CreateParameterDialog,
      useCondition: validLicensePresent,
    },
  ],
  parameterDataTypes: [
    {
      type: ValueParameter.type,
      title: 'Generic',
      component: GenericParameterInput,
      editComponent: GenericParameterForm,
    },
    {
      type: OptionParameter.type,
      title: 'Dropdown',
      component: OptionParameterInput,
      editComponent: OptionParameterForm,
    },
    {
      type: LookupTableParameter.type,
      title: 'Lookup Table',
      component: LookupTableParameterPreview,
      editComponent: LookupTableParameterForm,
      editPermissions: ['lookuptables:read'],
    },
  ],
  'views.parameters.option.sources': [
    {
      type: 'static',
      title: 'Static Values',
      editComponent: StaticSourceForm,
      factory: StaticSource.createDefault,
    },
    {
      type: 'field',
      title: 'Values of field',
      editComponent: FieldSourceForm,
      factory: FieldSource.createDefault,
    },
  ],
};

export default ParameterBindings;
