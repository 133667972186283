import * as React from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';

import useDebouncedValue from 'hooks/useDebouncedValue';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import PDFPreview, { Canvas } from 'report/report-creation/PDFPreview';

export const REPORT_PREVIEW_ID = 'report-preview';

const PreviewDisabledCanvas = styled(Canvas)(
  ({ theme }) => css`
    background-color: ${theme.colors.global.background};
    align-items: center;
  `,
);

type Props = {
  report: ReportFormValues;
  missingParameters: boolean;
};

const ReportPreview = ({ report, missingParameters }: Props) => {
  const [debouncedReport] = useDebouncedValue(report, 200);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const format = report.layout?.format;
  const isPDF = !format || format === 'PDF';

  if (isPDF && missingParameters) {
    return <PreviewDisabledCanvas>Preview is disabled while parameters are missing values.</PreviewDisabledCanvas>;
  }

  return isPDF ? (
    <PDFPreview report={debouncedReport} />
  ) : (
    <PreviewDisabledCanvas>No preview available for {format} format.</PreviewDisabledCanvas>
  );
};

const PROPERTIES_FOR_PREVIEW = [
  'title',
  'subtitle',
  'description',
  'logo',
  'hideWidgetDescription',
  'hideWidgetQuery',
  'timezone',
  'parameterValues',
] as const;

const compareProperties = <T,>(entity: T, entity2: T, keys: Readonly<Array<keyof T>>) =>
  keys.every((key) => entity?.[key] === entity2?.[key]);
const layoutEqualForPreview = (layout1: Props['report']['layout'], layout2: Props['report']['layout']) =>
  compareProperties(layout1, layout2, [
    'format',
    'orientation',
    'pageSize',
    'printToc',
    'header',
    'footer',
    'printHeader',
    'printFooter',
    'printPageNumbers',
  ]);
const widgetsEqualForPreview = (widgets1: Props['report']['widgets'], widgets2: Props['report']['widgets']) =>
  isEqual(widgets1, widgets2);
const positionsEqualForPreview = (positions1: Props['report']['positions'], positions2: Props['report']['positions']) =>
  isEqual(positions1, positions2);

const areReportsEqualForPreview = (
  { report: report1, missingParameters: missingParameters1 }: Props,
  { report: report2, missingParameters: missingParameters2 }: Props,
) =>
  missingParameters1 === missingParameters2 &&
  compareProperties(report1, report2, PROPERTIES_FOR_PREVIEW) &&
  layoutEqualForPreview(report1.layout, report2.layout) &&
  widgetsEqualForPreview(report1.widgets, report2.widgets) &&
  positionsEqualForPreview(report1.positions, report2.positions);

export default React.memo(ReportPreview, areReportsEqualForPreview);
