import { useQuery } from '@tanstack/react-query';

import type { BackendReportShort } from 'report/types';
import fetch from 'logic/rest/FetchProvider';
import { reportingUrl } from 'report/Constants';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import { defaultOnError } from 'util/conditional/onError';

const deserializeResponse = ({
  delivery,
  description,
  hide_widget_description,
  hide_widget_query,
  id,
  layout,
  parameter_values,
  parameters,
  positions,
  subtitle,
  timezone,
  title,
  widgets,
  logo,
}: BackendReportShort): ReportFormValues => ({
  delivery,
  description,
  hideWidgetDescription: hide_widget_description,
  hideWidgetQuery: hide_widget_query,
  id,
  layout: layout
    ? {
        format: layout.format,
        pageSize: layout.page_size,
        orientation: layout.orientation,
        printHeader: layout.print_header,
        printFooter: layout.print_footer,
        printPageNumbers: layout.print_page_numbers,
        header: layout.header,
        footer: layout.footer,
        printToc: layout.print_toc,
      }
    : undefined,
  parameterValues: Object.fromEntries(Object.entries(parameter_values).map(([name, { value }]) => [name, value])),
  parameters,
  positions,
  title,
  timezone,
  subtitle,
  widgets: widgets.map((w) => ({
    dashboardId: w.dashboard_id,
    widgetId: w.dashboard_widget_id,
    title: w.title,
    description: w.description,
  })),
  logo,
});
const fetchReport = (reportId: string) =>
  fetch('GET', reportingUrl(`${reportId}/short`)).then((response: BackendReportShort) => deserializeResponse(response));

const useReport = (
  reportId: string,
  refetchPeriodically = false,
): {
  data: ReportFormValues;
  isInitialLoading: boolean;
  isFetching: boolean;
  error: unknown;
} => {
  const { data, isInitialLoading, isFetching, error } = useQuery(
    ['reports', 'details', reportId],
    () => defaultOnError(fetchReport(reportId), 'Fetching report failed with status', 'Could not retrieve report'),
    {
      refetchInterval: refetchPeriodically ? 2000 : undefined,
    },
  );

  return {
    data,
    isFetching,
    isInitialLoading,
    error,
  };
};

export default useReport;
