import React from 'react';

import CommonNotificationSummary from 'components/event-notifications/event-notification-types/CommonNotificationSummary';
import type {
  CreateInvestigationNotificationSummaryType,
  CreateInvestigationNotificationType,
} from 'security-app/types';
import {
  useGetPriorities,
  useGetUsers,
  useGetTeams,
  useGetInvestigations,
} from 'security-app/hooks/useInvestigationsAPI';
import { usePagination } from 'common/contexts';
import type { EventNotificationTypes } from 'components/event-notifications/types';
import type { EventNotification } from 'stores/event-notifications/EventNotificationsStore';

function CreateInvestigationNotificationSummary({
  notification = {} as EventNotification & CreateInvestigationNotificationType,
  ...restProps
}: React.ComponentProps<EventNotificationTypes['summaryComponent']> & CreateInvestigationNotificationSummaryType) {
  const localPagination = usePagination();

  const { investigations } = useGetInvestigations(localPagination);
  const investigationsMap = React.useMemo(
    () => ({
      ...investigations.reduce(
        (acc, investigation) => ({ ...acc, [investigation.id]: { label: investigation.name } }),
        {},
      ),
    }),
    [investigations],
  );

  const { users } = useGetUsers(true);
  const { teams } = useGetTeams(true);
  const usersTeamsMap = React.useMemo(
    () => ({
      ...users.reduce((acc, user) => ({ ...acc, [user.id]: { label: user.full_name, icon: 'user' } }), {}),
      ...teams.reduce((acc, team) => ({ ...acc, [team.id]: { label: team.name, icon: 'users' } }), {}),
    }),
    [users, teams],
  );

  const { priorities } = useGetPriorities(true);
  const getPriorityText = React.useCallback(
    (value: number) => (value ? priorities.find((priority) => priority.priority === value)?.text : ''),
    [priorities],
  );

  return (
    <CommonNotificationSummary {...restProps} notification={notification}>
      <tr>
        <td>Create a New Investigation for Every Alert</td>
        <td>{notification.config.create_new_investigation ? <em>True</em> : <em>False</em>}</td>
      </tr>
      {!notification.config.create_new_investigation && (
        <tr>
          <td>Investigation</td>
          <td>{investigationsMap[notification.config.investigation_id]?.label}</td>
        </tr>
      )}
      <tr>
        <td>Assign Investigation To</td>
        <td>{usersTeamsMap[notification.config.investigation_assigned_to]?.label}</td>
      </tr>
      <tr>
        <td>Investigation Priority</td>
        <td>{getPriorityText(notification.config.investigation_priority)}</td>
      </tr>
      <tr>
        <td>Investigation Status</td>
        <td>{notification.config.investigation_status}</td>
      </tr>
    </CommonNotificationSummary>
  );
}

export default CreateInvestigationNotificationSummary;
