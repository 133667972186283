import styled, { css } from 'styled-components';

export const StyledCatalogWrapperComponent = styled.div`
  table,
  .filter {
    margin-top: 15px;
    th {
      font-weight: bold;
    }
    tr.toggle-details {
      cursor: pointer;
    }

    dd:not(:last-child) {
      border-bottom: 1px solid #ececec;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }

    td {
      padding-left: 13px !important;
      padding-top: 13px !important;
    }

    dl {
      margin: 0;
    }

    .archive-catalog-entry {
      th.restored {
        text-align: center;
      }

      td {
        min-width: 110px;
        padding-right: 10px;
        white-space: nowrap;
        &.stretch {
          max-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        &.restored {
          text-align: center;
        }
      }
    }
  }
  .table-hover > tbody.archive-catalog-entry > tr.archive-catalog-entries-details:hover {
    background: none;
  }
  .archive-search-help-button {
    cursor: help;
    padding: 0 0 0 2px;
    margin-left: 5px;
  }

  .popover-wide {
    max-width: 500px;
  }

  .archive-config-overview dd {
    margin-bottom: 1px;
  }
`;

export const StyledDescriptionWrapper = styled.div<{ $marginLeft: number }>(
  ({ $marginLeft }) => css`
    margin-top: 10px;

    dt {
      float: left;
      clear: left;
    }

    dd {
      margin-left: ${$marginLeft}px;
      word-wrap: break-word;
    }
  `,
);
