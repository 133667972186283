import type {
  BackendConfig,
  BackendConfigJson,
  OidcBackendConfig,
  OidcBackendConfigJson,
  OktaBackendConfig,
  OktaBackendConfigJson,
} from 'logic/authentication/okta/types';

import { OKTA_TYPE_KEY } from './constants';

export function fromJson(input: BackendConfigJson): BackendConfig {
  const isOkta = input.type === OKTA_TYPE_KEY;
  let baseUrl;
  let claims;

  if (isOkta) {
    baseUrl = (input as Omit<OktaBackendConfigJson, 'clientSecret'>).okta_base_url;
  } else {
    const oidcInput = input as Omit<OidcBackendConfigJson, 'clientSecret'>;
    baseUrl = oidcInput.base_url;
    claims = oidcInput.claims;
  }

  return {
    type: input.type,
    ...(isOkta ? { oktaBaseUrl: baseUrl } : { baseUrl: baseUrl, claims: claims }),
    clientId: input.client_id,
    clientSecret: input.client_secret,
    tokenVerifierConnectTimeout: input.token_verifier_connect_timeout,
    callbackUrl: input.callback_url,
  };
}

export function toJson(input: BackendConfig): BackendConfigJson {
  const isOkta = input.type === OKTA_TYPE_KEY;
  let baseUrl;
  let claims;

  if (isOkta) {
    baseUrl = (input as Omit<OktaBackendConfig, 'clientSecret'>).oktaBaseUrl;
  } else {
    const oidcInput = input as Omit<OidcBackendConfig, 'clientSecret'>;

    baseUrl = oidcInput.baseUrl;
    claims = oidcInput.claims;
  }

  return {
    type: input.type,
    ...(isOkta ? { okta_base_url: baseUrl } : { base_url: baseUrl, claims: claims }),
    client_id: input.clientId,
    client_secret: input.clientSecret,
    token_verifier_connect_timeout: input.tokenVerifierConnectTimeout,
    callback_url: input.callbackUrl,
  };
}
