import * as React from 'react';
import { useCallback, useState } from 'react';
import moment from 'moment';

import { qualifyUrl, getPathnameWithoutId } from 'util/URLUtils';
import type { SearchParams } from 'stores/PaginationTypes';
import type { Extension } from 'search/ExportWidgetAction/util/WidgetExportUtils';
import {
  escapeFilename,
  mimeTypeMapper,
  BinaryFilesExtentions,
} from 'search/ExportWidgetAction/util/WidgetExportUtils';
import { parseSearchParams } from 'security-app/hooks/api/securityEventsAPI';
import { fetchBinaryFile, fetchTextFile } from 'util/FileDownloadUtils';
import useUserDateTime from 'hooks/useUserDateTime';
import AggregationWidgetExportDropdown from 'search/ExportWidgetAction/AggregationWidgetExportDropdown';
import { supportedAggregationExportFormats } from 'search/Constants';
import { MenuItem } from 'components/bootstrap';
import assertUnreachable from 'logic/assertUnreachable';
import type { TimeRange } from 'views/logic/queries/Query';
import { isTypeRelativeWithStartOnly } from 'views/typeGuards/timeRange';
import { adjustFormat } from 'util/DateTime';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';

type ExportType = 'alerts' | 'security_events' | 'tactics' | 'techniques';

const getUrl = (type: ExportType, filename: string) =>
  qualifyUrl(`/plugins/org.graylog.plugins.securityapp/security_events/histogram/${type}/${filename}`);
const getPdfUrl = () => qualifyUrl('plugins/org.graylog.plugins.widgetexport/widgetexport/export');

const widgetType = (type: ExportType) => {
  switch (type) {
    case 'alerts':
      return 'events_metrics_widget';
    case 'security_events':
    case 'tactics':
    case 'techniques':
      return 'security_events_metrics_widget';
    default:
      return assertUnreachable(type, 'Invalid widget export type');
  }
};

const renderType = (type: ExportType) => {
  switch (type) {
    case 'alerts':
      return 'EVENTS_HISTOGRAM';
    case 'security_events':
      return 'SECURITY_EVENTS_HISTOGRAM';
    case 'tactics':
    case 'techniques':
      return 'MITRE_CATEGORY_HISTOGRAM';
    default:
      return assertUnreachable(type, 'Invalid widget export type');
  }
};

const prepareTimeRangeForGraph = (timerange: TimeRange) => {
  if (isTypeRelativeWithStartOnly(timerange)) {
    const from = moment().utc().subtract(timerange.range, 'seconds');
    const to = moment();

    return [adjustFormat(from, 'internal'), adjustFormat(to, 'internal')];
  }

  return [timerange.from, timerange.to];
};

const exportResults = (
  type: ExportType,
  widgetTitle: string,
  searchParams: SearchParams,
  extension: Extension,
  timezone: string,
) => {
  const { timerange } = parseSearchParams(searchParams, timezone);
  const [from, to] = prepareTimeRangeForGraph(timerange);
  const fileName = escapeFilename(`${widgetTitle}-${from}-${to}.${extension}`);
  const mimeType = mimeTypeMapper[extension];

  const isPdf = extension === 'pdf';

  const request = isPdf
    ? {
        data: { elements: [], type: 'object_list_data' },
        widget: { widget_type: widgetType(type), render_type: renderType(type), search_parameters: searchParams },
      }
    : parseSearchParams(searchParams, timezone);

  const url = isPdf ? getPdfUrl() : getUrl(type, fileName);

  if (BinaryFilesExtentions.includes(extension)) return fetchBinaryFile('POST', url, request, mimeType, fileName);

  return fetchTextFile('POST', url, request, mimeType, fileName);
};

const tacticsTechniquesEvent = [
  TELEMETRY_EVENT_TYPE.SECURITY_APP.SECURITY_EVENTS.EXPORT_CATEGORIES_HISTOGRAM,
  { app_section: 'security-events' },
];
const typeToTelemetryEvent = {
  alerts: [TELEMETRY_EVENT_TYPE.ALERTS_AND_EVENTS.EXPORT_HISTOGRAM, { app_section: 'alerts-and-events' }],
  tactics: tacticsTechniquesEvent,
  techniques: tacticsTechniquesEvent,
};

type Props = {
  type: ExportType;
  title: string;
  searchParams: SearchParams;
  disabled?: boolean;
};
const ExportResultsButton = ({ type, title, searchParams, disabled = false }: Props) => {
  const { userTimezone } = useUserDateTime();
  const sendTelemetry = useSendTelemetry();
  const { pathname } = useLocation();
  const [isExporting, setIsExporting] = useState(false);
  const onExportAggregationWidget = useCallback(
    (extension: Extension) => {
      const [eventType, eventBase] = typeToTelemetryEvent[type];
      sendTelemetry(eventType, {
        ...eventBase,
        app_pathname: getPathnameWithoutId(pathname),
        app_action_value: { extension },
      });

      setIsExporting(true);

      return exportResults(type, title, searchParams, extension, userTimezone).finally(() => {
        setIsExporting(false);
      });
    },
    [type, sendTelemetry, pathname, title, searchParams, userTimezone],
  );

  return (
    <AggregationWidgetExportDropdown isExporting={isExporting}>
      {supportedAggregationExportFormats.map(({ id: extension, title: extensionTitle }) => (
        <MenuItem disabled={disabled} key={extension} onSelect={() => onExportAggregationWidget(extension)}>
          {extensionTitle}
        </MenuItem>
      ))}
    </AggregationWidgetExportDropdown>
  );
};
export default ExportResultsButton;
