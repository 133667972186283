import React, { useMemo, useCallback } from 'react';

import Popover from 'components/common/Popover';
import type { SelectedSearchFilter, SearchFilterUsagesSummary } from 'search-filter/types';
import type { SearchFilterEdit } from 'search-filter/components/search-filter-bar/SearchFilterForm';
import SearchFilterForm from 'search-filter/components/search-filter-bar/SearchFilterForm';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import { Spinner } from 'components/common';
import { REFERENCED_USER_SEARCH_FILTER_TYPE, INLINE_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import Routes from 'routing/Routes';
import { Link } from 'components/common/router';
import useSearchFilterUsagesSummaryQuery from 'search-filter/hooks/useSearchFilterUsagesSummaryQuery';
import readableFilterUsagesSummary from 'search-filter/readableFilterUsagesSummary';
import type { ViewType } from 'views/logic/views/View';
import StringUtils from 'util/StringUtils';
import confirmActionUsagesAlert from 'search-filter/confirmActionUsagesAlert';
import useView from 'search-filter/hooks/useView';

const excludeCurrentViewFromSummary = ({
  usagesSummary,
  isSavedView,
  viewType,
}: {
  usagesSummary: SearchFilterUsagesSummary['summary'];
  isSavedView: boolean;
  viewType: ViewType;
}): SearchFilterUsagesSummary['summary'] => {
  if (!isSavedView) {
    return usagesSummary;
  }

  return usagesSummary
    .map(({ usages, type }) => {
      if (type.toLowerCase() === viewType?.toLowerCase()) {
        return { type, usages: usages - 1 };
      }

      return { type, usages };
    })
    .filter(({ usages }) => usages > 0);
};

const UsagesWarning = ({
  filterId,
  filterType,
  usagesSummaryExcludingCurrentView,
  isFetchingUsagesSummary,
}: {
  filterType: string;
  usagesSummaryExcludingCurrentView: SearchFilterUsagesSummary['summary'];
  isFetchingUsagesSummary: boolean;
  filterId: string;
}) => {
  if (filterType !== REFERENCED_USER_SEARCH_FILTER_TYPE) {
    return null;
  }

  if (isFetchingUsagesSummary) {
    return <Spinner text="Loading usages.." />;
  }

  return (
    <p>
      <i>
        {!usagesSummaryExcludingCurrentView?.length &&
          'Please note, changes to this filter will be persisted. This filter is currently not being referenced in any other saved search or dashboard.'}
        {!!usagesSummaryExcludingCurrentView?.length && (
          <>
            Important! This search filter is also being referenced by{' '}
            <Link to={Routes.pluginRoute('MY-FILTERS_DETAILS_FILTERID')(filterId)} target="_blank">
              {readableFilterUsagesSummary(usagesSummaryExcludingCurrentView)}
            </Link>
            . Changing the search filter query will affect{' '}
            {StringUtils.pluralize(usagesSummaryExcludingCurrentView.length, 'its', 'their')} search result.
          </>
        )}
      </i>
    </p>
  );
};

const useUsagesSummaryExcludingCurrentView = (filterId: string, filterType: string) => {
  const view = useView();
  const {
    data: { summary: usagesSummary },
    isFetching: isFetchingUsagesSummary,
  } = useSearchFilterUsagesSummaryQuery(filterId, { enabled: filterType === REFERENCED_USER_SEARCH_FILTER_TYPE });
  const isSavedView = !!view?.id;
  const usagesSummaryExcludingCurrentView = excludeCurrentViewFromSummary({
    usagesSummary,
    isSavedView,
    viewType: view?.type,
  });

  return { usagesSummaryExcludingCurrentView, isFetchingUsagesSummary };
};

type Props = React.PropsWithChildren<{
  filter: SelectedSearchFilter;
  onCancel: () => void;
  onSave: (item: SelectedSearchFilter) => void;
}>;

const SearchFilterEditPopover = ({
  children,
  onSave,
  onCancel,
  filter: { id, frontendId, title, description, queryString, negation, type },
}: Props) => {
  const { put } = useUserSearchFilterMutation();
  const { usagesSummaryExcludingCurrentView, isFetchingUsagesSummary } = useUsagesSummaryExcludingCurrentView(id, type);

  const handleFormSubmit = useCallback(
    (filterData: SearchFilterEdit, checked: boolean) => {
      if (checked || type === INLINE_SEARCH_FILTER_TYPE) {
        return onSave({ ...filterData, frontendId, negation, type: INLINE_SEARCH_FILTER_TYPE });
      }

      // eslint-disable-next-line no-alert
      if (
        usagesSummaryExcludingCurrentView?.length &&
        filterData.queryString !== queryString &&
        !confirmActionUsagesAlert({ action: 'Changing', usagesSummary: usagesSummaryExcludingCurrentView })
      ) {
        return Promise.resolve();
      }

      return put({ data: filterData, id }).then(() => {
        onSave({ ...filterData, negation, id, frontendId, type: REFERENCED_USER_SEARCH_FILTER_TYPE });
      });
    },
    [frontendId, id, negation, onSave, put, queryString, usagesSummaryExcludingCurrentView, type],
  );

  const initialValues = useMemo(() => ({ title, description, queryString }), [description, queryString, title]);

  return (
    <Popover onClose={onCancel} opened width={460} withArrow withinPortal>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown title="Edit Filter" id="edit-search-filter-dropdown">
        <SearchFilterForm
          onCancel={onCancel}
          onSubmit={handleFormSubmit}
          showCheckbox={type === REFERENCED_USER_SEARCH_FILTER_TYPE}
          checkboxLabel="Create copy for current search"
          helpText='This action will create a copy of this filter and remove the reference to the filter saved in "My Filters".'
          helpTitle="Create copy"
          formDescription={
            <UsagesWarning
              filterId={id}
              filterType={type}
              usagesSummaryExcludingCurrentView={usagesSummaryExcludingCurrentView}
              isFetchingUsagesSummary={isFetchingUsagesSummary}
            />
          }
          initialValues={initialValues}
          submitButtonText="Update filter"
          submitLoadingText="Updating Filter..."
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default SearchFilterEditPopover;
