import * as React from 'react';

import { IconButton } from 'components/common';

type Props = {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onNewPage: (page: number) => void;
};

function Paginator({ currentPage, totalItems, itemsPerPage, onNewPage }: Props) {
  const pages = React.useMemo(() => {
    if (totalItems === 0) return 1;
    if (itemsPerPage === 0) return 1;

    return Math.ceil(totalItems / itemsPerPage);
  }, [totalItems, itemsPerPage]);

  const disabledPrevious = React.useMemo(() => currentPage === 1, [currentPage]);
  const disabledNext = React.useMemo(() => currentPage === pages, [currentPage, pages]);

  const handleNextPage = () => {
    if (currentPage === pages) onNewPage(currentPage);
    onNewPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage === 1) onNewPage(currentPage);
    onNewPage(currentPage - 1);
  };

  if (pages === 1) return null;

  return (
    <>
      <small>
        page {currentPage} of {pages}
      </small>
      <div>
        <IconButton
          name="chevron_left"
          onClick={handlePreviousPage}
          title="Previous page"
          disabled={disabledPrevious}
        />
        <IconButton name="chevron_right" onClick={handleNextPage} title="Next page" disabled={disabledNext} />
      </div>
    </>
  );
}

export default Paginator;
