import * as React from 'react';

import { Spinner } from 'components/common';
import { TrafficGraphWithDaySelect } from 'components/common/Graph';
import useDrawDownLicenseRemainingTraffic from 'license/hooks/useDrawDownLicenseRemainingTraffic';
import useGraphDays from 'components/common/Graph/contexts/useGraphDays';

const DrawDownLicenseVolumeGraph = () => {
  const { graphDays } = useGraphDays();
  const {
    isLoading: isLoadingDrawDownLicenseTraffic,
    isError: isErrorDrawDownLicenseTraffic,
    data: drawDownLicenseTrafficData,
  } = useDrawDownLicenseRemainingTraffic(graphDays);

  if (isLoadingDrawDownLicenseTraffic) return <Spinner />;

  if (isErrorDrawDownLicenseTraffic || !drawDownLicenseTrafficData) return null;

  return <TrafficGraphWithDaySelect title="Remaining Volume" traffic={drawDownLicenseTrafficData} />;
};

export default DrawDownLicenseVolumeGraph;
