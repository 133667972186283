import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import { Row, Col, Input } from 'components/bootstrap';
import { FormSubmit } from 'components/common';

import Team from '../../logic/Team';

type Props = {
  onClose: () => void;
  onSubmit: (team: Team) => Promise<Team>;
};

const Container = styled.div`
  padding: 15px;
`;

const TeamQuickCreate = ({ onClose, onSubmit }: Props) => {
  const _onSubmit = ({ name, description }: { name: string; description: string }, { resetForm }) => {
    const newTeam = Team.builder().name(name).description(description).build();

    return onSubmit(newTeam).then(() => {
      resetForm();
      onClose();
    });
  };

  return (
    <Container>
      <Formik onSubmit={_onSubmit} initialValues={{ name: '', description: '' }}>
        {({ isSubmitting, isValid }) => (
          <Form className="form form-horizontal">
            <Field name="name">
              {({ field: { value, onChange } }) => (
                <Input
                  type="text"
                  id="name"
                  label="Name"
                  required
                  maxLength={200}
                  onChange={onChange}
                  help="A unique name for the team"
                  labelClassName="col-sm-3"
                  wrapperClassName="col-sm-9"
                  value={value}
                />
              )}
            </Field>
            <Field name="description">
              {({ field: { value, onChange } }) => (
                <Input
                  type="text"
                  id="description"
                  label="Description"
                  required
                  onChange={onChange}
                  help="A description about the team"
                  labelClassName="col-sm-3"
                  wrapperClassName="col-sm-9"
                  value={value}
                />
              )}
            </Field>
            <Row>
              <Col xs={9} xsOffset={3}>
                <FormSubmit
                  isSubmitting={isSubmitting}
                  isAsyncSubmit
                  onCancel={onClose}
                  disabledSubmit={!isValid}
                  submitButtonText="Create team"
                  submitLoadingText="Creating Team"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default TeamQuickCreate;
