import Reflux from 'reflux';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { singletonStore, singletonActions } from 'logic/singleton';

export type TrafficLimitViolationConfigType = {
  email_recipients: Array<string>;
};

export type ActionsType = {
  getConfig: () => Promise<{}>;
  updateConfig: (config: TrafficLimitViolationConfigType) => Promise<unknown>;
};

export const TrafficLimitViolationConfigActions = singletonActions('TrafficLimitViolationConfig', () =>
  Reflux.createActions<ActionsType>({
    getConfig: { asyncResult: true },
    updateConfig: { asyncResult: true },
  }),
);

type TrafficLimitViolationStoreConfigState = {
  emailRecipients: Array<string>;
};

export const TrafficLimitViolationConfigStore = singletonStore('TrafficViolationConfig', () =>
  Reflux.createStore<TrafficLimitViolationStoreConfigState>({
    listenables: [TrafficLimitViolationConfigActions],
    _state: {
      emailRecipients: undefined,
    },

    getInitialState() {
      const { emailRecipients } = this._state;

      return {
        emailRecipients,
      };
    },

    updateConfig(config: TrafficLimitViolationConfigType): Promise<void> {
      const url = qualifyUrl('/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations/settings');
      const promise = fetch('PUT', url, config);

      promise.then(
        () => this.getConfig(),
        (error) => {
          UserNotification.error(
            `Saving Traffic violation limit configuration failed with status: ${error}`,
            'Could not save Traffic violation limit configuration',
          );
        },
      );

      TrafficLimitViolationConfigActions.updateConfig.promise(promise);

      return promise;
    },

    getConfig(): Promise<any> {
      const url = qualifyUrl('/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations/settings');
      const promise = fetch('GET', url);

      promise
        .then((response: TrafficLimitViolationConfigType) => {
          this.trigger({ emailRecipients: response.email_recipients });
        })
        .catch((error) => {
          UserNotification.error(
            `Fetching Traffic violation limit configuration failed with status: ${error}`,
            'Could not load Traffic violation limit configuration',
          );
        });

      TrafficLimitViolationConfigActions.getConfig.promise(promise);

      return promise;
    },
  }),
);
