import { PackTypes } from 'illuminate/types';
import type { Sort, Attribute } from 'stores/PaginationTypes';

export const PACKS_TABLE_ID = 'packsTable';

export const ATTRIBUTES: Array<Attribute> = [
  {
    id: 'title',
    title: 'Pack Name',
    type: 'STRING',
    searchable: true,
  },
  {
    id: 'enabled',
    title: 'Status',
    type: 'BOOLEAN',
    filterable: true,
    filter_options: [
      { value: 'true', title: 'Enabled' },
      { value: 'false', title: 'Disabled' },
    ],
  },
  {
    id: 'is_new',
    title: 'New',
    type: 'BOOLEAN',
    filterable: true,
    filter_options: [
      { value: 'true', title: 'True' },
      { value: 'false', title: 'False' },
    ],
  },
  {
    id: 'type',
    title: 'Type',
    type: 'STRING',
    filterable: true,
    filter_options: [
      { value: PackTypes.SPOTLIGHT, title: 'Spotlight' },
      { value: PackTypes.PROCESSING, title: 'Processing' },
    ],
  },
  {
    id: 'pack_id',
    title: 'Pack ID',
    type: 'STRING',
  },
];

export const packsTableElements = {
  defaultLayout: {
    entityTableId: PACKS_TABLE_ID,
    defaultPageSize: 20,
    defaultSort: { attributeId: 'is_new', direction: 'asc' } as Sort,
    defaultDisplayedAttributes: ['title', 'enabled', 'is_new', 'type'],
  },
  columnOrder: [
    'title',
    'enabled',
    'is_new',
    'type',
    'category',
    'pack_id',
  ],
};
