import type { PluginExports } from 'graylog-web-plugin/plugin';

import AIReportConfiguration from 'security-app/components/Investigations/AIReport/AIReportConfiguration';
import useEventActionCondition from 'security-app/components/Investigations/plugin/useEventActionCondition';
import {
  SendNotificationsEventAction,
  SendNotificationsModal,
} from 'security-app/components/SecurityEvents/Alerts/AlertsModals/NotificationsModal/SendNotificationsEventAction';
import {
  BulkSetOwnerAction,
  BulkSetOwnerModal,
} from 'security-app/components/SecurityEvents/Alerts/AlertsModals/UsersModal/BulkSetOwnerAction';
import {
  BulkSetStatusModal,
  BulkSetStatusAction,
} from 'security-app/components/SecurityEvents/Alerts/AlertsModals/SetStatusModal/BulkSetStatusAction';
import {
  BulkAddNotesAction,
  BulkAddNotesModal,
} from 'security-app/components/SecurityEvents/Alerts/AlertsModals/AddNotesModal/BulkAddNotesAction';

import investigationsSearchWidgetsBindings from './SearchWidgets/bindings';
import CoreEventWidgetActions, { EventsInvestigationsModal } from './CoreEventWidgetActions';
import InvestigationsMessageAction from './CoreMessageActions';
import InvestigationsSearchActions, {
  SearchInvestigationsModal,
  AddSearchConfirmationDialog,
} from './CoreSearchActions';
import InvestigationsEventAction, { EventInvestigationsModal } from './CoreEventActions';
import InvestigationsDashboardAction, { InvestigationsModal } from './CoreDashboardActions';
import useActionCondition from './useActionCondition';

const investigationsExports: PluginExports = {
  'views.components.dashboardActions': [
    {
      component: InvestigationsDashboardAction,
      key: 'investigations',
      modal: InvestigationsModal,
      useCondition: useActionCondition,
    },
  ],
  'views.components.eventActions': [
    {
      component: InvestigationsEventAction,
      key: 'investigations',
      modal: EventInvestigationsModal,
      useCondition: useEventActionCondition,
      isBulk: true,
    },
    {
      component: SendNotificationsEventAction,
      key: 'send-notification',
      modal: SendNotificationsModal,
      isBulk: true,
      useCondition: () => true,
    },
  ],
  'views.components.securityEventActions': [
    {
      component: BulkSetOwnerAction,
      key: 'set-owner',
      modal: BulkSetOwnerModal,
      isBulk: true,
      useCondition: () => true,
    },
    {
      component: BulkSetStatusAction,
      key: 'set-status',
      modal: BulkSetStatusModal,
      isBulk: true,
      useCondition: () => true,
    },
    {
      component: BulkAddNotesAction,
      key: 'add-notes',
      modal: BulkAddNotesModal,
      isBulk: true,
      useCondition: () => true,
    },
  ],
  'views.components.widgets.events.actions': [
    {
      component: CoreEventWidgetActions,
      modal: EventsInvestigationsModal,
      key: 'investigations',
      useCondition: useActionCondition,
    },
  ],
  'views.components.widgets.messageTable.messageActions': [
    {
      component: InvestigationsMessageAction,
      key: 'investigations',
      useCondition: useActionCondition,
    },
  ],
  'views.components.searchActions': [
    {
      component: InvestigationsSearchActions,
      key: 'investigations',
      modals: [
        {
          key: 'searchInvestigationsModal',
          component: SearchInvestigationsModal,
        },
        {
          key: 'addToEvidenceDialog',
          component: AddSearchConfirmationDialog,
        },
      ],
      useCondition: useActionCondition,
    },
  ],
  systemConfigurations: [
    {
      component: AIReportConfiguration,
      displayName: 'Investigation AI Report',
      skipClusterConfigRequest: true,
      configType: 'org.graylog.plugins.securityapp.investigations.ai.config',
    },
  ],
  ...investigationsSearchWidgetsBindings,
};

export default investigationsExports;
