import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Formik } from 'formik';

import { Button, Col, Modal, Row } from 'components/bootstrap';
import FormikInput from 'components/common/FormikInput';
import Spinner from 'components/common/Spinner';
import { InputDescription, ModalSubmit, IfPermitted } from 'components/common';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import type { AIReportConfigurationType } from 'security-app/components/Investigations/AIReport/hooks/useAIReportConfiguration';
import useAIReportConfiguration from 'security-app/components/Investigations/AIReport/hooks/useAIReportConfiguration';
import useEnableAIReportMutation from 'security-app/components/Investigations/AIReport/hooks/useEnableAIReportMutation';

const LabelSpan = styled.span(
  ({ theme }) => css`
    margin-left: ${theme.spacings.sm};
    font-weight: bold;
  `,
);

const AIReportConfiguration = () => {
  const { data, isLoading } = useAIReportConfiguration();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { enable, disable } = useEnableAIReportMutation();

  const sendTelemetry = useSendTelemetry();

  const _saveConfig = (values: AIReportConfigurationType) => {
    const action = values.isEnabled ? enable : disable;

    action().then(() => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.AI_REPORT_CONFIGURATION_SAVED, {
        app_pathname: 'configurations',
        app_section: 'investigation-ai-report',
      });

      setShowModal(false);
    });
  };

  const _resetConfig = () => {
    setShowModal(false);
  };

  const modalTitle = 'Configure Investigation AI Report';

  return (
    <div>
      <h2>Investigation AI Report Configuration</h2>
      <p>Configuration to enable or disable the option to create AI generated reports for security investigations.</p>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <dl className="deflist">
            <dt>Investigation AI Report:</dt>
            <dd>{data.isEnabled ? 'Enabled' : 'Disabled'}</dd>
          </dl>

          <IfPermitted permissions="*">
            <p>
              <Button
                type="button"
                bsSize="xs"
                bsStyle="info"
                onClick={() => {
                  setShowModal(true);
                }}>
                Edit configuration
              </Button>
            </p>
          </IfPermitted>

          <Modal show={showModal} onHide={_resetConfig} aria-modal="true" aria-labelledby="dialog_label">
            <Formik onSubmit={_saveConfig} initialValues={data}>
              {({ isSubmitting }) => (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title id="dialog_label">{modalTitle}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div>
                      <Row>
                        <Col sm={12}>
                          <FormikInput
                            type="checkbox"
                            name="isEnabled"
                            id="isEnabled"
                            label={<LabelSpan>Enabled</LabelSpan>}
                          />
                          <InputDescription
                            help={
                              <>
                                Enable or disable the option to create AI generated reports for security investigations.
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <ModalSubmit
                      onCancel={_resetConfig}
                      isSubmitting={isSubmitting}
                      submitButtonText="Update configuration"
                      submitLoadingText="Updating configuration..."
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AIReportConfiguration;
