import * as React from 'react';
import { useContext, useState } from 'react';
import * as Immutable from 'immutable';
import { parse } from 'qs';
import { Formik } from 'formik';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';

import type { Backend, OidcBackendConfig, OktaBackendConfig, TeamSyncConfig } from 'logic/authentication/okta/types';
import useLocation from 'routing/useLocation';
import Wizard from 'components/common/Wizard';
import { Spinner } from 'components/common';
import { Row, Col, Alert } from 'components/bootstrap';
import type FetchError from 'logic/errors/FetchError';
import OktaMatchingGroupsProvider from 'authentication/components/oidc/config/components/OktaMatchingGroupsProvider';
import payloadFromFormValues from 'authentication/components/oidc/config/helpers/payloadFromFormValues';
import { OidcBackendMetaContext } from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import convertToSeconds from 'authentication/components/oidc/config/helpers/convertToSeconds';
import useRoles from 'common/hooks/useRoles';
import OidcGroupSyncForm from 'authentication/components/oidc/config/components/OidcGroupSyncForm';

import type { ConfigFormValues } from './types';
import ServerConfigurationForm from './components/ServerConfigurationForm';
import GroupSyncForm from './components/GroupSyncForm';

const defaultValues: Backend = {
  id: undefined,
  title: '',
  description: '',
  defaultRoles: Immutable.List(),
  config: {
    type: OKTA_TYPE_KEY,
    baseUrl: '',
    callbackUrl: '',
    clientId: '',
    clientSecret: { is_set: false },
    claims: {},
    tokenVerifierConnectTimeout: '',
  },
};
const SubmitAllError = ({ error, backendId }: { error: FetchError; backendId: string | null | undefined }) => (
  <Row>
    <Col xs={9} xsOffset={3}>
      <Alert
        bsStyle="danger"
        style={{ wordBreak: 'break-word' }}
        title={`Failed to ${backendId ? 'edit' : 'create'} authentication service`}>
        {error?.message && (
          <>
            {error.message}
            <br />
            <br />
          </>
        )}
        {error?.additional?.res?.text}
      </Alert>
    </Col>
  </Row>
);

const _formatBackendValidationErrors = (backendErrors: { [inputNameJSON: string]: string[] }) => {
  const backendErrorStrings = mapValues(
    backendErrors,
    (errorArray) => `Server validation error: ${errorArray.join(' ')}`,
  );

  return mapKeys(backendErrorStrings, (_value, key) => camelCase(key));
};

interface BackendWizardProps {
  onSubmitForm: (
    values: ConfigFormValues,
    backendGroupSyncIsActive: boolean,
    shouldUpdateGroupSync?: boolean,
  ) => Promise<any>;

  authenticationBackend?: Backend;

  groupSyncValues?: TeamSyncConfig;

  backendType: string;

  isOkta: boolean;
}

const BackendWizard = ({
  authenticationBackend = defaultValues,
  onSubmitForm,
  groupSyncValues = {},
  backendType,
  isOkta,
}: BackendWizardProps) => {
  const location = useLocation();
  const searchParams = parse(location.search.substr(1));
  const [wizardStep, setWizardStep] = useState<'server_config' | 'group_sync'>(
    searchParams.initialStepKey || 'server_config',
  );
  const [submitErrors, setSubmitErrors] = useState(undefined);
  const { backendGroupSyncIsActive, licenseIsValid, backendId } = useContext(OidcBackendMetaContext);
  const { title, description, config, defaultRoles } = authenticationBackend;
  const { callbackUrl, clientId, clientSecret, tokenVerifierConnectTimeout } = config;

  const baseUrl = isOkta
    ? (config as Omit<OktaBackendConfig, 'clientSecret'>).oktaBaseUrl
    : (config as Omit<OidcBackendConfig, 'clientSecret'>).baseUrl;
  const claims = isOkta ? undefined : (config as Omit<OidcBackendConfig, 'clientSecret'>).claims;
  const { isLoading, roles } = useRoles();

  if (isLoading) {
    return <Spinner />;
  }

  const defaultCreateRoleId = roles.find((role) => role.name === 'Reader')?.id;
  const defaultRolesValues = defaultRoles.toArray().join(',');
  const hasSecret = clientSecret?.is_set;
  let hasApiToken;
  let initialValues = {
    title,
    description,
    ...(isOkta ? { oktaBaseUrl: baseUrl } : { baseUrl, claims }),
    ...(!isOkta && { groupsClaim: undefined }),
    callbackUrl,
    clientId,
    type: backendType,
    clientSecret: hasSecret ? undefined : '',
    tokenVerifierConnectTimeout: convertToSeconds(tokenVerifierConnectTimeout) || 10,
    defaultRoles: defaultRolesValues === '' ? defaultCreateRoleId : defaultRolesValues,
    teamDefaultRoles: undefined,
    teamSelectionType: undefined,
    teamSelection: Immutable.Set<string>(),
    synchronizeGroups: undefined,
    oktaApiToken: undefined,
  };

  if (groupSyncValues) {
    const { oktaApiToken, ...rest } = groupSyncValues;
    hasApiToken = oktaApiToken?.is_set;
    initialValues = { ...initialValues, oktaApiToken: hasApiToken ? undefined : '', ...rest };
  }

  const _goToStep = (step: 'server_config' | 'group_sync') => setWizardStep(step);

  const _handleSubmit = (values: ConfigFormValues, { setErrors, setSubmitting }) => {
    const _submit = () => {
      onSubmitForm(values, backendGroupSyncIsActive, licenseIsValid)
        .catch((error) => {
          if (typeof error?.additional?.body?.errors === 'object') {
            const backendValidationErrors = _formatBackendValidationErrors(error.additional.body.errors);
            setErrors(backendValidationErrors);
          } else {
            setSubmitErrors(error);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    if (backendGroupSyncIsActive && !values.synchronizeGroups) {
      // eslint-disable-next-line no-alert
      if (
        window.confirm('Do you really want to remove the group synchronization config for this authentication service?')
      ) {
        _submit();
      }

      setSubmitting(false);

      return;
    }

    _submit();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={_handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}>
      {({ isSubmitting, values, setFieldValue, handleSubmit, validateForm }) => {
        const steps = [
          {
            key: 'server_config',
            title: <span>Server Configuration</span>,
            component: (
              <ServerConfigurationForm
                values={values}
                roles={roles}
                authenticationBackend={authenticationBackend}
                isSubmitting={isSubmitting}
                hasSecret={hasSecret}
                isOkta={isOkta}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
                submitAllError={submitErrors && <SubmitAllError error={submitErrors} backendId={backendId} />}
                goToNext={() => _goToStep('group_sync')}
              />
            ),
          },
          {
            key: 'group_sync',
            title: (
              <span>
                Group Synchronization <small>(Optional)</small>
              </span>
            ),
            component: isOkta ? (
              <GroupSyncForm
                values={values}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                hasApiToken={hasApiToken}
                handleSubmit={handleSubmit}
                validateForm={validateForm}
                submitAllError={submitErrors && <SubmitAllError error={submitErrors} backendId={backendId} />}
                goToPrevious={() => _goToStep('server_config')}
              />
            ) : (
              <OidcGroupSyncForm
                values={values}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                validateForm={validateForm}
                submitAllError={submitErrors && <SubmitAllError error={submitErrors} backendId={backendId} />}
                goToPrevious={() => _goToStep('server_config')}
              />
            ),
          },
        ];

        return (
          <OktaMatchingGroupsProvider prepareSubmitPayload={payloadFromFormValues}>
            <Wizard
              hidePreviousNextButtons
              justified
              horizontal
              containerClassName=""
              onStepChange={setWizardStep}
              activeStep={wizardStep}
              steps={steps}
            />
          </OktaMatchingGroupsProvider>
        );
      }}
    </Formik>
  );
};

export default BackendWizard;
