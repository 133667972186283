import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { adjustFormat, toUTCFromTz } from 'util/DateTime';

export const toInternalTime = (date: string, userTimezone: string) =>
  adjustFormat(toUTCFromTz(date, userTimezone), 'internal');

export const formatDuration = (value: string) => moment.duration(value).humanize();

const durationRegex =
  /^P(?!$)(\d+(?:\.\d+)?Y)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?W)?(\d+(?:\.\d+)?D)?(T(?=\d)(\d+(?:\.\d+)?H)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?S)?)?$/;
const isValidISO8601Duration = (value: string) => durationRegex.test(value);

export const formatRetentionLabel = (value: string) =>
  moment.duration(value).asDays() === 0 ? 'disabled' : formatDuration(value);

export const validateRetention = (value: string) => {
  if (!isEmpty(value) && value !== '0' && !isValidISO8601Duration(value)) {
    return 'This is not a valid duration';
  }

  const days = moment.duration(value).asDays();

  return days !== 0 && days < 1 && 'Must be in days';
};

export const getDataLakeUriParams = (
  values: { from: string; to: string; full_delete?: boolean; wipe_restores?: boolean },
  userTimezone: string,
) => {
  const from = values.from ? toInternalTime(values.from, userTimezone) : '';
  const to = values.to ? toInternalTime(values.to, userTimezone) : '';

  const fullDelete = !!values.full_delete;
  const wipeRestores = !!values.wipe_restores;

  const timeRangeUriParams =
    from !== '' || to !== '' ? `from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}` : '';

  const fullDeleteUriParams = fullDelete ? `full_delete=${fullDelete}&wipe_restores=${wipeRestores}` : '';

  if (fullDelete) {
    if (timeRangeUriParams.length > 0) {
      return `${timeRangeUriParams}&${fullDeleteUriParams}`;
    }

    return fullDeleteUriParams;
  }

  return timeRangeUriParams;
};
