import * as React from 'react';

import { singleton } from 'logic/singleton';
import type { EventCategories } from 'security-content/types';

export type MessageSummaryTemplateContextType = {
  isLoading: boolean;
  isError: boolean;
  error: Error;
  eventTypeCategories: EventCategories;
};

const MessageSummaryTemplateContext = React.createContext<MessageSummaryTemplateContextType | undefined>(undefined);

export default singleton('contexts.MessageSummaryTemplate', () => MessageSummaryTemplateContext);
