import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';

import type { FilterComponentProps } from 'stores/PaginationTypes';
import { ListGroupItem, Input } from 'components/bootstrap';
import Spinner from 'components/common/Spinner';
import IconButton from 'components/common/IconButton';
import useMitreCategories from 'security-app/components/SecurityEvents/Alerts/MitreCategories/useMitreCategories';
import { NoSearchResult } from 'components/common';

const Container = styled.div(
  ({ theme }) => css`
    width: fit-content;
    color: ${theme.colors.global.textDefault};
    padding: 3px 10px;
  `,
);

const ScrollableArea = styled.div`
  max-height: 60vh;
  overflow: auto;
`;

const StyledListGroup = styled.div`
  margin-bottom: 0;
`;

const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  padding: 2px;
`;

const Indent = styled.div`
  margin-left: 10px;
`;

type Suggestion = {
  id: string;
  value: string;
  children: Array<Suggestion>;
};

type Props = {
  onClick: (newValue: string) => void;
  suggestions: Array<Suggestion>;
  isLoading: boolean;
  onFilter: (newFilter: string) => void;
};

const ExpandIcon = ({
  expanded,
  onChange,
}: {
  expanded: boolean;
  onChange: (fn: (expanded: boolean) => boolean) => void;
}) => {
  const onClick = useCallback(() => onChange((_expanded) => !_expanded), [onChange]);

  return (
    <IconButton
      title={`${expanded ? 'Collapse' : 'Expand'} category`}
      name={expanded ? 'keyboard_arrow_down' : 'chevron_right'}
      onClick={onClick}
    />
  );
};

const Abbreviate = styled.div`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Category = ({
  name,
  suggestion,
  onClick,
}: Pick<Props, 'onClick'> & {
  suggestion: Suggestion;
  name: string;
}) => {
  const [expanded, setExpanded] = useState(true);
  const _onClick = useCallback(() => onClick(suggestion.id), [onClick, suggestion.id]);

  return (
    <>
      <StyledListGroupItem key={`filter-value-${suggestion.id}`}>
        <ListGroupItem onClick={_onClick}>
          <Abbreviate title={suggestion.value}>{suggestion.value}</Abbreviate>
        </ListGroupItem>
        {suggestion.children?.length > 0 ? <ExpandIcon expanded={expanded} onChange={setExpanded} /> : null}
      </StyledListGroupItem>
      {expanded
        ? suggestion.children.map((s) => (
            <Indent>
              <Category key={`${name}-${s.id}`} name={[name, s.id].join(',')} suggestion={s} onClick={onClick} />
            </Indent>
          ))
        : null}
    </>
  );
};

const SearchInput = styled(Input)`
  margin-bottom: 6px;
`;

const Tactics = ({ onClick, isLoading, suggestions, onFilter }: Props) => {
  const debounceOnSearch = debounce((value: string) => onFilter(value), 1000);

  return (
    <Container>
      <SearchInput
        autoFocus
        type="text"
        id="search-filters-input"
        formGroupClassName=""
        placeholder="Search for tactic/technique"
        onChange={({ target: { value } }) => debounceOnSearch(value)}
      />

      {isLoading && <Spinner />}

      {suggestions?.length > 0 ? (
        <ScrollableArea>
          <StyledListGroup>
            {suggestions.map((tactic) => (
              <Category key={tactic.id} name={tactic.id} suggestion={tactic} onClick={onClick} />
            ))}
          </StyledListGroup>
        </ScrollableArea>
      ) : (
        <NoSearchResult>No entities found</NoSearchResult>
      )}
    </Container>
  );
};

const filterSuggestions = (node: Suggestion, lowerQuery: string, skipCheck: boolean = false) => {
  const filteredChildren = node.children
    ?.map((child) => filterSuggestions(child, lowerQuery))
    .filter((child) => child !== null);

  if (skipCheck || node.value.toLowerCase().includes(lowerQuery) || filteredChildren?.length > 0) {
    return { ...node, children: filteredChildren };
  }

  return null;
};

const TacticTechniqueFilter = ({ onClick }: Pick<Props, 'onClick'>) => {
  const { data: categories, isInitialLoading } = useMitreCategories();
  const [filter, setFilter] = useState<string>(undefined);

  const suggestions = useMemo(
    () =>
      categories.tactics.map(({ id, name, techniques }) => ({
        id,
        value: `${name} (${id})`,
        children: techniques.map((technique) => ({
          id: technique.id,
          value: `${technique.name} (${technique.id})`,
          children: technique.subtechniques.map((subtechnique) => ({
            id: subtechnique.id,
            value: `${subtechnique.name} (${subtechnique.id})`,
            children: [],
          })),
        })),
      })),
    [categories.tactics],
  );

  const filteredSuggestions = useMemo(
    () =>
      filter
        ? filterSuggestions({ id: 'root', value: 'root', children: suggestions }, filter.toLocaleLowerCase(), true)
            .children
        : suggestions,
    [filter, suggestions],
  );

  return (
    <Tactics onClick={onClick} suggestions={filteredSuggestions} isLoading={isInitialLoading} onFilter={setFilter} />
  );
};

const TacticTechniqueFilterContainer = ({ onSubmit }: FilterComponentProps) => {
  const onClick = useCallback(
    (value: string) =>
      onSubmit(
        {
          value,
          title: value,
        },
        true,
      ),
    [onSubmit],
  );

  return <TacticTechniqueFilter onClick={onClick} />;
};
export default TacticTechniqueFilterContainer;
