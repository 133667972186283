import __request__ from 'routing/request';
interface DataNodeInformation {
    readonly data_node_status: 'UNCONFIGURED' | 'PREPARED' | 'STARTING' | 'AVAILABLE' | 'UNAVAILABLE' | 'REMOVING' | 'REMOVED';
    readonly hostname: string;
    readonly opensearch_version: string;
    readonly datanode_version: string;
    readonly ip: string;
    readonly roles: string[];
    readonly node_name: string;
    readonly upgrade_possible: boolean;
    readonly manager_node: boolean;
}
interface ManagerNode {
    readonly node_uid: string;
    readonly name: string;
}
interface ClusterState {
    readonly cluster_name: string;
    readonly active_shards: number;
    readonly active_primary_shards: number;
    readonly initializing_shards: number;
    readonly unassigned_shards: number;
    readonly delayed_unassigned_shards: number;
    readonly shard_replication: 'ALL' | 'PRIMARIES';
    readonly manager_node: ManagerNode;
    readonly status: string;
    readonly number_of_nodes: number;
    readonly relocating_shards: number;
    readonly opensearch_nodes: Node[];
}
interface FlushResponse {
    readonly total: number;
    readonly failed: number;
    readonly successful: number;
}
interface Version {
    readonly version: unknown;
}
interface Node {
    readonly ip: string;
    readonly roles: string[];
    readonly host: string;
    readonly name: string;
    readonly version: string;
}
interface DatanodeUpgradeStatus {
    readonly cluster_healthy: boolean;
    readonly outdated_nodes: DataNodeInformation[];
    readonly up_to_date_nodes: DataNodeInformation[];
    readonly shard_replication_enabled: boolean;
    readonly cluster_state: ClusterState;
    readonly server_version: Version;
}
/**
 * Start shard replication for opensearch cluster managed by the data node
 */
export function startReplication(): Promise<FlushResponse> {
    return __request__('POST', '/datanodes/upgrade/replication/start', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Stop shard replication for opensearch cluster managed by the data node
 */
export function stopReplication(): Promise<FlushResponse> {
    return __request__('POST', '/datanodes/upgrade/replication/stop', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Display existing cluster configuration
 */
export function status(): Promise<DatanodeUpgradeStatus> {
    return __request__('GET', '/datanodes/upgrade/status', null, {}, {
        'Accept': ['application/json']
    });
}
