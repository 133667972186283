import type { ViewsDispatch } from 'views/stores/useViewsDispatch';
import { addWidget } from 'views/logic/slices/widgetActions';

import InvestigationsWidgetConfig from './InvestigationsWidgetConfig';
import InvestigationsWidget from './InvestigationsWidget';

export const CreateInvestigationsWidget = () =>
  InvestigationsWidget.builder().newId().config(InvestigationsWidgetConfig.createDefault()).build();

export default () => async (dispatch: ViewsDispatch) => dispatch(addWidget(CreateInvestigationsWidget()));
