import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
type ReportWidgetReferenceArray = ReportWidgetReference[];
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_property: string;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly id: string;
    readonly sortable: boolean;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface PageListResponse_AvailableDashboardSummary {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: AvailableDashboardSummary[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface UsedSearchFilter {
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
type DashboardSummaryArray = DashboardSummary[];
interface WidgetConfigDTO {
}
interface ReportDashboardWidget {
    readonly creator_user_id: string;
    readonly cache_time: number;
    readonly dashboard_widget_id: string;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly description: string;
    readonly filters: UsedSearchFilter[];
    readonly type: string;
    readonly config: WidgetConfigDTO;
    readonly dashboard_id: string;
}
interface WidgetSummary {
    readonly eligible: boolean;
    readonly id: string;
    readonly type: string;
    readonly title: string;
    readonly parameters: Parameter[];
    readonly config: WidgetConfigDTO;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface TimeRange {
    readonly type: string;
}
interface ReportWidgetReference {
    readonly widget_id: string;
    readonly description: string;
    readonly title: string;
    readonly dashboard_id: string;
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface AvailableDashboardSummary {
    readonly owner: string;
    readonly last_updated_at: string;
    readonly id: string;
    readonly title: string;
    readonly widgets: WidgetSummary[];
    readonly search_id: string;
}
interface WidgetDetailsResponse {
    readonly widgets: ReportDashboardWidget[];
    readonly search_ids: {
        readonly [_key: string]: string;
    };
    readonly parameters: {
        readonly [_key: string]: Parameter[];
    };
}
interface Parameter {
    readonly name: string;
    readonly data_type: string;
    readonly binding: Binding;
    readonly description: string;
    readonly optional: boolean;
    readonly default_value: Object;
    readonly title: string;
    readonly type: string;
}
interface DashboardSummary {
    readonly query_titles: {
        readonly [_key: string]: string;
    };
    readonly id: string;
    readonly widgets: {
        readonly [_key: string]: WidgetSummary[];
    };
    readonly title: string;
    readonly search_id: string;
}
type stringArray = string[];
interface BackendQuery {
}
/**
 * Get a list of all dashboards and their widgets
 */
export function list(): Promise<DashboardSummaryArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.report/widgets', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a detailed list of specified widgets
 */
export function details(JSONbody: ReportWidgetReferenceArray): Promise<WidgetDetailsResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.report/widgets/details', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of all dashboards and their widgets
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function listPaginated(sort: 'title' | 'owner' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc', query?: string, filters?: stringArray): Promise<PageListResponse_AvailableDashboardSummary> {
    return __request__('GET', '/plugins/org.graylog.plugins.report/widgets/paginated', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query, 'filters': filters }, {
        'Accept': ['application/json']
    });
}
