import * as React from 'react';
import { useMemo } from 'react';
import * as Immutable from 'immutable';

import ExportTimerangeContext from 'logview/components/contexts/ExportTimerangeContext';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { LogViewMessage } from 'logview/types';

type Props = {
  children: React.ReactNode;
  pages: { [pageIndex: number]: Array<LogViewMessage> };
  effectiveTimerange?: AbsoluteTimeRange;
};

const firstMessageTimestamp = (pages: Immutable.OrderedSet<[number, Array<LogViewMessage>]>) => {
  const [, oldestMessageChunk] = pages.first();
  const oldestMessage = oldestMessageChunk[oldestMessageChunk.length - 1];

  return oldestMessage?.message?.timestamp as string;
};

const lastMessageTimestamp = (pages: Immutable.OrderedSet<[number, Array<LogViewMessage>]>) => {
  const [, newestMessageChunk] = pages.last();
  const newestMessage = newestMessageChunk[0];

  return newestMessage?.message?.timestamp as string;
};

const ExportTimerangeContextProvider = ({ children, pages, effectiveTimerange }: Props) => {
  const exportTimerange = useMemo(() => {
    const orderedPages = Immutable.Map(pages)
      .map<[number, LogViewMessage[]]>((value, key) => [Number(key), value])
      .toOrderedSet();
    const first = firstMessageTimestamp(orderedPages) ?? effectiveTimerange?.from;
    const last = lastMessageTimestamp(orderedPages) ?? effectiveTimerange?.to;

    return { first, last };
  }, [effectiveTimerange, pages]);

  return <ExportTimerangeContext.Provider value={exportTimerange}>{children}</ExportTimerangeContext.Provider>;
};

export default ExportTimerangeContextProvider;
