import React from 'react';

import { Row, Col } from 'components/bootstrap';
import DocsHelper from 'util/DocsHelper';
import { DocumentTitle, PageHeader } from 'components/common';
import AuditLogStatus from 'auditlog/AuditLogStatus';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { AUDIT_LOG_LICENSE_TITLE, AUDIT_LOG_LICENSE_TEXT } from 'auditlog/constants';

import AuditLogPageNavigation from './AuditLogPageNavigation';

const AuditLogConfigurationPage = () => (
  <DocumentTitle title="Audit log configuration">
    <AuditLogPageNavigation />
    <PageHeader
      title="Audit Log Configuration"
      documentationLink={{
        title: 'Audit log documentation',
        path: DocsHelper.PAGES.AUDIT_LOG,
      }}>
      <span>
        Graylog Audit Log lets you create a chronological record of activities performed in Graylog, that you can later
        use to reconstruct and examine the sequence of events that occurred when investigating an event. You can modify
        the audit log configuration in the Graylog configuration file. Please remember to restart the server afterwards.
      </span>
    </PageHeader>

    <LicenseCheck
      text={AUDIT_LOG_LICENSE_TITLE}
      title={AUDIT_LOG_LICENSE_TEXT}
      licenseSubject={LICENSE_SUBJECTS.auditlog}
      displayWarningContainer
      bsStyle="danger"
    />

    <Row className="content">
      <Col md={12}>
        <AuditLogStatus />
      </Col>
    </Row>
  </DocumentTitle>
);

export default AuditLogConfigurationPage;
