import type { GenericS3GenericInputCreateRequest, GenericS3InputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const AWS_AUTH_TYPES = {
  automatic: 'Automatic',
  keysecret: 'Key & Secret',
};

export const toGenericS3InputCreateRequest = ({
  genericS3Name,
  awsAuthenticationType,
  awsS3AccessKey,
  awsS3Secret,
  contentTypes,
  awsS3Region,
  genericS3ThrottleEnabled,
  pollingInterval,
  compressionType,
  sqsQueueName,
  key,
  secret,
  overrideSource,
  sqsMessageBatchSize,
}: FormDataType): GenericS3InputCreateRequest => ({
  name: genericS3Name.value,
  ...(awsAuthenticationType?.value === AWS_AUTH_TYPES.keysecret
    ? {
        aws_s3_access_key: awsS3AccessKey?.value,
        aws_s3_secret: awsS3Secret?.value,
      }
    : {
        aws_s3_access_key: key,
        aws_s3_secret: secret,
      }),
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  enable_throttling: !!genericS3ThrottleEnabled?.value,
  content_types: contentTypes.value,
  aws_s3_region: awsS3Region.value,
  compression_type: compressionType.value,
  sqs_queue_name: sqsQueueName.value,
  override_source: overrideSource.value,
  sqs_message_batch_size: sqsMessageBatchSize.value,
});

export const toGenericInputCreateRequest = ({
  genericS3Name,
  awsS3AccessKey,
  awsS3Secret,
  contentTypes,
  awsS3Region,
  genericS3ThrottleEnabled,
  pollingInterval,
  compressionType,
  sqsQueueName,
  awsAuthenticationType,
  key,
  secret,
  overrideSource,
  sqsMessageBatchSize,
}: FormDataType): GenericS3GenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.generics3.GenericS3Input',
  title: genericS3Name.value,
  global: false,
  configuration: {
    ...(awsAuthenticationType?.value === AWS_AUTH_TYPES.keysecret
      ? {
          aws_s3_access_key: awsS3AccessKey?.value,
          aws_s3_secret: awsS3Secret?.value,
        }
      : {
          aws_s3_access_key: key,
          aws_s3_secret: secret,
        }),
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    throttling_allowed: !!genericS3ThrottleEnabled?.value,
    content_types: contentTypes.value,
    aws_s3_access_key: awsS3AccessKey.value,
    aws_s3_secret: awsS3Secret.value,
    aws_s3_region: awsS3Region.value,
    compression_type: compressionType.value,
    sqs_queue_name: sqsQueueName.value,
    override_source: overrideSource.value,
    sqs_message_batch_size: sqsMessageBatchSize.value,
  },
});
