/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useState, useCallback, useMemo } from 'react';

import useIsMountedRef from 'hooks/useIsMountedRef';

import type { Warnings } from './FormWarningsContext';
import FormWarningsContext from './FormWarningsContext';

type Props = {
  children: React.ReactNode;
};

const FormWarningsProvider = ({ children }: Props) => {
  const isMountedRef = useIsMountedRef();
  const [warnings, setWarnings] = useState<Warnings>({});

  const setFieldWarning = useCallback(
    <T extends keyof Warnings>(fieldName: T, warning: Warnings[T]) => {
      if (isMountedRef.current === true) {
        setWarnings((curWarnings) => ({ ...curWarnings, [fieldName]: warning }));
      }
    },
    [isMountedRef],
  );

  const formWarningsContextValue = useMemo(() => ({ warnings, setFieldWarning }), [setFieldWarning, warnings]);

  return <FormWarningsContext.Provider value={formWarningsContextValue}>{children}</FormWarningsContext.Provider>;
};

export default FormWarningsProvider;
