import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';
import Select from 'components/common/Select';

import FormAdvancedOptions from './FormAdvancedOptions';

import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from '../common/utils/types';
import ValidatedInput from '../common/ValidatedInput';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe = ({ onSubmit, onChange }: Props) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { pollingInterval, noOfChannels } = formData;
  const { setFormData } = useContext<FormDataContextType>(FormDataContext);

  const availableContent = [
    { label: 'Events', value: 'events' },
    { label: 'Incidents', value: 'incidents' },
  ];

  const [contentTypes, setContentTypes] = useState<string>('');
  const [contentTypeVal, setContentTypeVal] = useState(false);
  const { checkpointIntervalSeconds, streamGUID } = formData;
  const contentArray = contentTypes.split(',');

  const handleSubmit = () => {
    const isPresent = contentArray.includes('events');
    let error = false;
    formData.contentTypes.value = contentTypes;

    if (isPresent && streamGUID.value === ' ') {
      setFormError({
        full_message: 'Please provide GUID of your Event Stream',
        nice_message: 'Stream GUID is required for event streaming.',
      });

      error = true;
    }

    if (contentTypes.length === 0) {
      setContentTypeVal(true);
      error = true;
    }

    if (checkpointIntervalSeconds.value < 5) {
      setFormError({
        full_message: 'Please provide valid checkpoint interval',
        nice_message: 'Minimum allowable checkpoint interval is 5 seconds.',
      });

      error = true;
    }

    if (pollingInterval.value < 1) {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message: 'Minimum allowable polling interval is 1 minute.',
      });

      error = true;
    }

    if (!error) {
      setFormError(null);
      onSubmit();
    }
  };

  const handleContentType = (val: string) => {
    const value = { value: val, dirty: false };
    setFormData('logTypes', value);
    setContentTypes(val);
    setContentTypeVal(false);
  };

  return (
    <FormWrap onSubmit={handleSubmit} buttonContent="Proceed" title="" error={formError} description="">
      <label className="control-label" htmlFor="contents">
        Logs Types to Collect
      </label>
      <Select
        multi
        name="content_type"
        id="contentTypes"
        onChange={handleContentType}
        options={availableContent}
        value={contentTypes || ''}
        matchProp="label"
      />
      {contentTypeVal && contentTypes.length === 0 && <label htmlFor="contentTypes">Select at least one</label>}

      <span className="help-block">The activity log for the above content types will be fetched.</span>

      {contentArray.includes('events') && (
        <>
          <ValidatedInput
            className=""
            id="streamGUID"
            type="text"
            onChange={onChange}
            fieldData={formData.streamGUID}
            label="Stream GUID"
            help="Unique identifier of event stream (GUID)."
            required
          />
          <Input
            id="noOfChannels"
            name="noOfChannels"
            type="number"
            value={noOfChannels.value || noOfChannels.defaultValue || ''}
            min="1"
            max="60"
            step="1"
            onChange={onChange}
            required
            help="The numbers of channels in the Event Stream."
            label="Number of Channels"
          />
        </>
      )}

      <Input
        id="pollingInterval"
        name="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue || ''}
        min="1"
        max="60"
        step="1"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new Incidents in Symantec SES. The smallest allowable interval is 1 minute."
        label="Polling Interval"
      />
      <Input
        id="checkpointIntervalSeconds"
        name="checkpointIntervalSeconds"
        type="number"
        value={checkpointIntervalSeconds.value || checkpointIntervalSeconds.defaultValue || ''}
        min="1"
        max="60"
        step="1"
        onChange={onChange}
        required
        help="How often (in seconds) Graylog will record checkpoints for Symantec SES log collection."
        label="Checkpoint Interval"
      />

      <FormAdvancedOptions onChange={onChange} selectedContentTypes={contentTypes} />
    </FormWrap>
  );
};

export default StepSubscribe;
