import * as React from 'react';
import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import type { Optional } from 'utility-types';
import type { FormikErrors } from 'formik';
import { Form, setNestedObjectValues } from 'formik';
import { flushSync } from 'react-dom';

import useReportForm from 'report/report-creation/useReportForm';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import { Row, Col, Button } from 'components/bootstrap';
import Icon from 'components/common/Icon';
import FormatSelect from 'report/report-creation/FormatSelect';
import ReportCreationNavigation from 'report/report-creation/ReportCreationNavigation';
import ReportCreationRouter from 'report/report-creation/ReportCreationRouter';
import ReportPreview from 'report/report-creation/ReportPreview';
import ReportActions from 'report/report-creation/ReportActions';
import type { Report, WidgetRef, BackendReport } from 'report/types';
import { DEFAULT_FORMAT } from 'report/types';
import useHistory, { type HistoryFunction } from 'routing/useHistory';
import ReportRoutes from 'report/ReportRoutes';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import UserNotification from 'util/UserNotification';
import { generateReport, showDownloadDialog } from 'report/report-creation/overview/download';
import useWidgetDetails from 'report/hooks/useWidgetDetails';
import { Spinner } from 'components/common';

export type ReportFormValues = Optional<Omit<Report, 'widgets'> & { widgets: Array<WidgetRef> }, 'id'>;

const SIDEBAR_WIDTH = 4;

const StyledForm = styled(Form)(
  ({ theme }) => css`
    height: 100%;

    @media (max-width: ${theme.breakpoints.max.md}) {
      height: auto;
    }
  `,
);

const ContentRow = styled(Row)(
  ({ theme }) => css`
    height: 100%;

    @media (max-width: ${theme.breakpoints.max.md}) {
      height: auto;
    }
  `,
);

const ContentCol = styled(Col)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${theme.breakpoints.max.md}) {
      height: auto;
    }
  `,
);

const HeaderCol = styled(Col)`
  display: flex;
  padding: 0 15px;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const GrowingRow = styled(Row)`
  flex: 1;
`;

const SidebarCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div(
  ({ theme }) => css`
    padding: 0 15px;
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
    overflow: auto;

    @media (max-width: ${theme.breakpoints.max.md}) {
      height: auto;
      overflow: initial;
    }
  `,
);

const PreviewCol = styled(Col)(
  ({ theme }) => css`
    height: 100%;
    overflow: hidden;
    padding: 0 15px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${theme.breakpoints.max.md}) {
      min-height: 500px;
    }
  `,
);

const SubmitCol = styled(Col)`
  padding: 15px 15px 0 15px;
`;

type Page = 'overview' | 'delivery' | 'style' | 'content';

const firstErroneousPage = (errors: FormikErrors<ReportFormValues>): Page => {
  if (errors.title) {
    return 'overview';
  }

  if (errors?.delivery && Object.keys(errors.delivery)) {
    return 'delivery';
  }

  if (errors?.layout && Object.keys(errors.layout)) {
    return 'style';
  }

  return 'content';
};

const redirectToErroneousPage = (errors: FormikErrors<ReportFormValues>, history: HistoryFunction) => {
  const page = firstErroneousPage(errors);
  history.push(page === 'overview' ? '' : page);
};

const useSubmitHandlers = (action: 'create' | 'edit') => {
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();
  const { values, validateForm, submitForm, setTouched, resetForm } = useReportForm();

  const trySubmit = useCallback(
    async (submitAction: 'onSubmit' | 'submitAndGenerate' | 'submitAndGoToList'): Promise<BackendReport | void> => {
      const validationErrors = await validateForm();

      if (Object.keys(validationErrors).length > 0) {
        setTouched(setNestedObjectValues(validationErrors, true));

        UserNotification.error(
          'Some changes were not be saved, please check for missing required fields before closing.',
        );

        redirectToErroneousPage(validationErrors, history);

        return Promise.resolve();
      }

      sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT[`${action === 'create' ? 'CREATED' : 'UPDATED'}`], {
        app_pathname: 'report',
        app_section: 'report-form',
        event_details: {
          format: values.layout?.format ?? DEFAULT_FORMAT,
          widget_count: values.widgets?.length ?? 0,
          submit_type: submitAction,
          overview: {
            timezone: values.timezone,
            schedule: values.delivery.scheduling,
          },
          delivery: {
            user_receivers_count: values.delivery.user_receivers?.length ?? 0,
            email_receivers_count: values.delivery.email_receivers?.length ?? 0,
          },
          style: {
            layout: {
              page_size: values.layout?.pageSize,
              page_orientation: values.layout?.orientation,
              showFooter: values.layout?.footer,
              print_toc: values.layout?.printToc,
            },
            hideWidgetDescription: values.hideWidgetDescription,
            hideWidgetQuery: values.hideWidgetQuery,
          },
        },
      });

      return submitForm();
    },
    [action, history, sendTelemetry, setTouched, submitForm, validateForm, values],
  );

  const resetFormDirty = useCallback(() => {
    // resetting form dirty state is necessary before redirecting to a different page
    flushSync(() => resetForm({ values }));
  }, [resetForm, values]);

  const goToEditPageAfterCreation = useCallback(
    (beReport: BackendReport) => {
      if (action === 'create') {
        resetFormDirty();
        history.push(ReportRoutes.contents(beReport.id));
      }
    },
    [action, history, resetFormDirty],
  );

  const onSubmit = useCallback(async () => {
    const report = await trySubmit('onSubmit');

    if (report) {
      goToEditPageAfterCreation(report);
    }
  }, [goToEditPageAfterCreation, trySubmit]);

  const onSubmitAndGenerate = useCallback(async () => {
    const report = await trySubmit('submitAndGenerate');

    if (report && report.id) {
      const generateResponse = await generateReport(report.id);

      if (generateResponse.status === 202) {
        UserNotification.success('Report is being generated');
        resetFormDirty();
        history.push(ReportRoutes.archive(report.id));

        return;
      }

      if (generateResponse.ok) {
        await showDownloadDialog(report.title, generateResponse);
        goToEditPageAfterCreation(report);
      } else {
        const blob = await generateResponse.blob();
        const errorMessage = await blob.text();
        UserNotification.error(`Error during report generation: ${errorMessage}`);
      }
    }
  }, [goToEditPageAfterCreation, history, resetFormDirty, trySubmit]);

  const onSubmitAndGoToList = useCallback(async () => {
    const report = await trySubmit('submitAndGoToList');

    if (report && report.id) {
      resetFormDirty();
      history.push(ReportRoutes.OVERVIEW);
    }
  }, [history, resetFormDirty, trySubmit]);

  return { onSubmit, onSubmitAndGenerate, onSubmitAndGoToList };
};

type Props = {
  action: 'create' | 'edit';
  onCancel: () => void;
  pathPrefix: string;
  title: string;
};

const ReportFormContent = ({ action, pathPrefix, onCancel, title }: Props) => {
  const { values, errors } = useReportForm();
  const { onSubmit, onSubmitAndGenerate, onSubmitAndGoToList } = useSubmitHandlers(action);
  const hasMissingParameters = Object.keys(errors?.parameterValues ?? {}).length > 0;
  const { data: widgetDetails, isLoading } = useWidgetDetails(values?.widgets);
  const widgetDetailsContextValue = useMemo(
    () => ({
      ...widgetDetails,
      isLoading,
    }),
    [isLoading, widgetDetails],
  );

  return !widgetDetails ? (
    <Spinner />
  ) : (
    <WidgetDetailsContext.Provider value={widgetDetailsContextValue}>
      <StyledForm>
        <ContentRow className="content">
          <ContentCol sm={12}>
            <ContentRow>
              <ContentCol md={SIDEBAR_WIDTH}>
                <Row>
                  <HeaderCol>
                    <Button bsSize="small" onClick={onCancel}>
                      <Icon name="arrow_back" /> Cancel
                    </Button>
                    <h1>{title}</h1>
                  </HeaderCol>
                </Row>
                <GrowingRow>
                  <SidebarCol>
                    <ReportCreationNavigation prefix={pathPrefix} />
                    <FormContainer>
                      <ReportCreationRouter />
                    </FormContainer>
                  </SidebarCol>
                </GrowingRow>
              </ContentCol>
              <ContentCol>
                <PreviewCol>
                  <FormatSelect />
                  <ReportPreview report={values} missingParameters={hasMissingParameters} />
                </PreviewCol>
              </ContentCol>
            </ContentRow>
            <Row>
              <SubmitCol>
                <ReportActions
                  submitButtonText={`${action === 'create' ? 'Create' : 'Update'}`}
                  onSubmit={onSubmit}
                  onSubmitAndGenerate={onSubmitAndGenerate}
                  onSubmitAndGoToList={onSubmitAndGoToList}
                />
              </SubmitCol>
            </Row>
          </ContentCol>
        </ContentRow>
      </StyledForm>
    </WidgetDetailsContext.Provider>
  );
};

export default ReportFormContent;
