import { useQuery } from '@tanstack/react-query';

import { MitreCategories } from '@graylog/enterprise-api';

const useMitreCategories = () => {
  const { data, isInitialLoading } = useQuery(['mitre', 'categories'], MitreCategories.categories);

  return {
    data,
    isInitialLoading,
  };
};
export default useMitreCategories;
