import * as React from 'react';
import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import styled, { css } from 'styled-components';

import { Alert, Col, Row } from 'components/bootstrap';
import type { Report } from 'report/types';
import { REPORT_TZ_FALLBACK } from 'report/Constants';
import UserDateTimeProvider from 'contexts/UserDateTimeProvider';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';

import PaperSheet from '../common/PaperSheet';
import ReportPreview from '../common/ReportPreview';
import ReportsActions from '../ReportsActions';

const StyledCoverPageRow = styled(Row)`
  padding: 20px 0 40px 0;
  white-space: pre-wrap;

  > h2 {
    margin-bottom: 10px;
  }
`;

const StyledHr = styled.hr(
  ({ theme }) => css`
    border: none;
    border-top: 2px solid ${theme.colors.gray['100']};
    color: ${theme.colors.global.textDefault};
    height: 5px;
    overflow: visible;
    text-align: center;

    &::after {
      background: ${theme.colors.global.contentBackground};
      content: 'end of cover page';
      font-family: monospace;
      padding: 0 4px;
      position: relative;
      top: -0.9em;
    }
  `,
);

const StyledAlert = styled(Alert)(
  ({ theme }) => css`
    font-size: ${theme.fonts.size.root};
  `,
);

const StyledP = styled.p`
  white-space: pre-wrap;
`;

const StyledLogo = styled.div`
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  img {
    max-width: 150px;
    max-height: 150px;
  }
`;

const _onPositionsChange = (report, positions) => {
  const {
    id,
    positions: { reportPositions },
  } = report;
  const updatedPositions = positions.map((position) => ({
    dashboard_widget_id: position.id,
    row: position.row,
    col: position.col,
  }));

  if (!isEqual(updatedPositions, reportPositions)) {
    ReportsActions.updatePositions(id, updatedPositions);
  }
};

type ReportContentsProps = {
  report: Report;
  onPositionsChange: (positions: unknown) => void;
};

const ReportContents = ({ report, onPositionsChange }: ReportContentsProps) => {
  if (report.widgets.length === 0) {
    return (
      <StyledAlert bsStyle="info" title="No widgets have been added to this report.">
        Modify the report&#039;s content and select which widgets you want to include in the report.
      </StyledAlert>
    );
  }

  return (
    <ReportPreview
      widgets={report.widgets}
      positions={report.positions}
      hideWidgetDescription={report.hideWidgetDescription}
      hideWidgetQuery={report.hideWidgetQuery}
      parameterValues={report.parameterValues}
      onPositionsChange={onPositionsChange}
    />
  );
};

type Props = {
  report: Report;
  reportLogo?: string | undefined;
};

const ReportLayout = ({ report, reportLogo }: Props) => {
  const onPositionsChange = useCallback((positions) => _onPositionsChange(report, positions), [report]);
  const reportTz = report.timezone ?? REPORT_TZ_FALLBACK;

  return (
    <UserDateTimeProvider tz={reportTz}>
      <AutoRefreshDisabledProvider>
        <h3>Layout</h3>
        <p>
          This is a preview of the report. Drag and drop widgets to sort the report contents, your changes will be
          updated automatically.
        </p>
        <PaperSheet>
          <StyledCoverPageRow>
            <Col md={12}>
              <h2 className="text-center">{report.title}</h2>
              <h4 className="text-center has-bm">{report.subtitle}</h4>
              <p className="text-center">All times are displayed in the time zone {reportTz}.</p>
              {reportLogo && (
                <StyledLogo>
                  <img src={reportLogo} alt="report-logo" />
                </StyledLogo>
              )}
            </Col>
          </StyledCoverPageRow>
          <StyledHr />
          <Row>
            <Col md={12}>
              <StyledP>{report.description}</StyledP>
              <ReportContents report={report} onPositionsChange={onPositionsChange} />
            </Col>
          </Row>
        </PaperSheet>
      </AutoRefreshDisabledProvider>
    </UserDateTimeProvider>
  );
};

export default ReportLayout;
