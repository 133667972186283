import * as React from 'react';
import { useCallback, useState } from 'react';

import type { UserSearchFilter } from 'search-filter/types';
import { Link, LinkContainer } from 'components/common/router';
import ShareButton from 'components/common/ShareButton';
import EntityListItem from 'components/common/EntityListItem';
import Icon from 'components/common/Icon';
import { Col, ButtonToolbar, Button, DropdownButton, DeleteMenuItem } from 'components/bootstrap';
import Routes from 'routing/Routes';
import UserFilterShareModal from 'search-filter/components/UserFilterShareModal';
import useSearchFilterUsagesSummaryQuery from 'search-filter/hooks/useSearchFilterUsagesSummaryQuery';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import confirmActionUsagesAlert from 'search-filter/confirmActionUsagesAlert';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

const Title = ({ filter }: { filter: UserSearchFilter }) => (
  <Link to={Routes.pluginRoute('MY-FILTERS_DETAILS_FILTERID')(filter.id)}>{filter.title || 'No Title'}</Link>
);

type Props = {
  filter: UserSearchFilter;
};

const FiltersOverviewItem = ({ filter }: Props) => {
  const [showFilterShareModal, setShowFilterShareModal] = useState<boolean>(false);
  const { isFetching: isFetchingUsagesSummary, refetch } = useSearchFilterUsagesSummaryQuery(filter.id, {
    enabled: false,
  });
  const { delete: deleteFilter } = useUserSearchFilterMutation();
  const sendTelemetry = useSendTelemetry();

  const toggleShareModalOpen = useCallback(() => {
    setShowFilterShareModal((cur) => !cur);
  }, [setShowFilterShareModal]);
  const onDeleteMyFilter = useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_DELETED, {
      app_pathname: 'my-filters',
      app_section: 'search-filter',
    });

    if (!isFetchingUsagesSummary) {
      refetch().then(({ data: { summary: usagesSummary } }) => {
        if (
          usagesSummary?.length &&
          !confirmActionUsagesAlert({
            action: 'Deleting',
            usagesSummary,
            additionalExplanation:
              '\nThe filter will be removed from entities where you are the owner and transformed to an inline filter where you are not an owner',
          })
        ) {
          return Promise.resolve();
        }

        return deleteFilter(filter.id)?.then((res) => res);
      });
    }
  }, [isFetchingUsagesSummary, refetch, deleteFilter, filter.id, sendTelemetry]);

  return (
    <>
      <EntityListItem
        title={<Title filter={filter} />}
        actions={
          <ButtonToolbar>
            <ShareButton entityType="search_filter" entityId={filter.id} onClick={toggleShareModalOpen} />
            <LinkContainer to={Routes.pluginRoute('MY-FILTERS_EDIT_FILTERID')(filter.id)}>
              <Button>
                <Icon name="edit_square" /> Edit
              </Button>
            </LinkContainer>
            <DropdownButton
              key="more-actions-button"
              title="More actions"
              pullRight
              id={`more-actions-dropdown-${filter.id}`}>
              <DeleteMenuItem onSelect={onDeleteMyFilter} />
            </DropdownButton>
          </ButtonToolbar>
        }
        contentRow={
          <Col xs={12}>
            {filter.queryString}
            <br />
            {filter.description || <i>No description given.</i>}
          </Col>
        }
      />
      {showFilterShareModal && <UserFilterShareModal filter={filter} toggleShareModalOpen={toggleShareModalOpen} />}
    </>
  );
};

export default FiltersOverviewItem;
