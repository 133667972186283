import React from 'react';

import Workspace from './Workspace';
import INITIAL_FORMDATA from './_initialFormData';
import { toGenericInputCreateRequest } from './formDataAdapter';

import { SidebarProvider } from '../common/context/Sidebar';
import { StepsProvider } from '../common/context/Steps';
import { FormDataProvider } from '../common/context/FormData';
import { AdvancedOptionsProvider } from '../common/context/AdvancedOptions';

type Props = {
  onSubmit?: (InputCreateRequest) => void;
};

const EmbeddedWorkspaceV2App = ({ onSubmit }: Props) => {
  const handleSubmit = (formData) => {
    if (!onSubmit) {
      return;
    }

    onSubmit(toGenericInputCreateRequest(formData));
  };

  return (
    <StepsProvider>
      <FormDataProvider initialFormData={INITIAL_FORMDATA}>
        <SidebarProvider>
          <AdvancedOptionsProvider>
            <Workspace onSubmit={handleSubmit} externalInputSubmit={typeof onSubmit === 'function'} />
          </AdvancedOptionsProvider>
        </SidebarProvider>
      </FormDataProvider>
    </StepsProvider>
  );
};

export default EmbeddedWorkspaceV2App;
