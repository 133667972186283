import * as React from 'react';

import type { GitRepoAPIModel } from 'security-app/hooks/api/sigmaAPI.types';

import { Path } from '../styledComponents';

type Props = {
  repo: GitRepoAPIModel;
  repoPath: string;
  setRepoPath: (newPath: string) => void;
  setRepo: (newRepo: GitRepoAPIModel) => void;
};

const PathNav = ({ repo, repoPath, setRepoPath, setRepo }: Props) => {
  const backToRepoList = () => {
    setRepo(null);
    setRepoPath('');
  };

  const changePath = (inPath: string) => () => {
    if (inPath === '/') {
      setRepoPath('');
    }

    let pathArray = repoPath.split('/');
    const pathIdx = pathArray.indexOf(inPath);
    pathArray = pathArray.slice(0, pathIdx + 1);

    setRepoPath(pathArray.length === 0 ? '' : pathArray.join('/'));
  };

  return (
    <div>
      <Path onClick={backToRepoList}>repoList/</Path>
      {repo &&
        repoPath.split('/').map((path: string) => (
          <Path data-testid={path} key={path} onClick={changePath(path)}>
            {path || 'root'}/
          </Path>
        ))}
    </div>
  );
};

export default PathNav;
