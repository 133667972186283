import { useEffect, useState } from 'react';
import type * as Immutable from 'immutable';

import { GroupSyncActions } from '../../stores/directoryServices/GroupSyncStore';
import type GroupSyncBackend from '../../logic/directoryServices/GroupSyncBackend';

type InitialValues = {
  groupSearchBase?: string;
  groupSearchPattern?: string;
  teamDefaultRoles?: string;
  teamNameAttribute?: string;
  teamUniqueIdAttribute?: string;
  teamSelectionType?: string;
  teamSelection?: Immutable.Set<string>;
  synchronizeGroups?: boolean;
};

const _prepareFormValues = (backendGroupSync) => {
  const {
    selectionType,
    selection,
    defaultRoles,
    config: { groupSearchBase, groupSearchPattern, teamUniqueIdAttribute, teamNameAttribute },
  } = backendGroupSync;

  return {
    groupSearchBase,
    groupSearchPattern,
    teamDefaultRoles: defaultRoles.join(),
    teamNameAttribute,
    teamUniqueIdAttribute,
    teamSelectionType: selectionType,
    teamSelection: selection,
    synchronizeGroups: true,
  };
};

const useInitialGroupSyncValues = (backendId: string, initialFormValues: InitialValues) => {
  const [backendGroupSync, setBackendGroupSync] = useState<GroupSyncBackend | undefined>();
  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (backendId) {
      GroupSyncActions.load(backendId)
        .then((result) => {
          if (result && result.backend) {
            setBackendGroupSync(result.backend);
          }

          setFinishedLoading(true);
        })
        .catch(() => {
          setFinishedLoading(true);
          setBackendGroupSync(undefined);
        });
    }
  }, [backendId]);

  return {
    finishedLoading: backendId ? finishedLoading : true,
    formValues: backendGroupSync ? _prepareFormValues(backendGroupSync) : initialFormValues,
  };
};

export default useInitialGroupSyncValues;
