import type * as Immutable from 'immutable';

import transformInaccessibleFilters from 'search-filter/logic/transformInaccessibleFilters';
import type View from 'views/logic/views/View';
import type Query from 'views/logic/queries/Query';
import createSearch from 'views/logic/slices/createSearch';

const transformInaccessibleFiltersInQueries = (
  queries: Immutable.Set<Query>,
  userPermissions: Immutable.List<string>,
) =>
  queries.map((query) => {
    const updatedFilters = transformInaccessibleFilters(query.filters, userPermissions);

    return query.toBuilder().filters(updatedFilters.toList()).build();
  });

const transformInaccessibleFiltersInSearch = async (view: View, userPermissions: Immutable.List<string>) => {
  const updatedQueries = transformInaccessibleFiltersInQueries(view.search.queries, userPermissions);
  const newSearchWithNewId = await createSearch(view.search.toBuilder().queries(updatedQueries.toSet()).build());

  return view.toBuilder().search(newSearchWithNewId).build();
};

export default transformInaccessibleFiltersInSearch;
