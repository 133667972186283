import React, { useState, useCallback, useEffect } from 'react';

import { useStore } from 'stores/connect';

import UploadField from './UploadField';
import UploadFieldStore, { UploadFieldActions } from './UploadFieldStore';
import type { UploadFile, UploadConfig } from './Types';

type UploadFieldContainerProps = {
  enableRemoval?: boolean;
  fileId?: string;
  onUpdate: (fileId?: string) => void;
  accept?: string;
  setDisableFormSubmission: (formValidationState: boolean) => void;
};

const UploadFieldContainer = ({
  enableRemoval = false,
  fileId,
  onUpdate,
  accept,
  setDisableFormSubmission,
}: UploadFieldContainerProps) => {
  const [isLoading, setIsLoading] = useState(!!fileId);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadConfig, setUploadConfig] = useState<UploadConfig | typeof undefined>();
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean | typeof undefined>();
  const [currentFile, setCurrentFile] = useState<UploadFile | typeof undefined>();
  useStore(UploadFieldStore);

  useEffect(() => {
    if (!fileId || !isLoading) {
      return;
    }

    UploadFieldActions.get(fileId)
      .then((response) => {
        setCurrentFile({
          id: fileId,
          name: response.file_name,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fileId, isLoading]);

  useEffect(() => {
    UploadFieldActions.getConfig().then((response) => {
      setUploadConfig({
        maxUploadSize: response.max_file_size_bytes,
      });
    });
  }, []);

  const onDeleteFile = useCallback(() => {
    setCurrentFile(undefined);
    onUpdate(undefined);
  }, [onUpdate]);

  const onUploadFile = useCallback(
    (files: FileList) => {
      if (currentFile) {
        onDeleteFile();
      }

      setIsUploading(true);
      setDisableFormSubmission(true);

      UploadFieldActions.upload(files[0])
        .then((response) => {
          const { id, file_name: name } = response;

          setCurrentFile({
            id,
            name,
          });

          onUpdate(id);

          setUploadSuccessful(true);
        })
        .catch(() => {
          setUploadSuccessful(false);
        })
        .finally(() => {
          setIsUploading(false);
          setDisableFormSubmission(false);
        });
    },
    [currentFile, onDeleteFile, onUpdate, setDisableFormSubmission],
  );

  return (
    <UploadField
      accept={accept}
      uploadSuccessful={uploadSuccessful}
      currentFile={currentFile}
      enableRemoval={enableRemoval}
      isLoading={isLoading}
      isUploading={isUploading}
      uploadConfig={uploadConfig}
      onUploadFile={onUploadFile}
      onDeleteFile={onDeleteFile}
    />
  );
};

export default UploadFieldContainer;
