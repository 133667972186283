import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { Select } from 'components/common';
import { DEFAULT_FORMAT, REPORT_FORMATS_SHORT } from 'report/types';

const StyledSelect = styled(Select)`
  width: 120px;
  margin-bottom: 15px;
`;

const FormatSelect = () => (
  <Field name="layout.format">
    {({ field: { name, value, onChange } }) => (
      <StyledSelect
        inputId="report-format-select"
        className="report-format-select"
        onChange={(newValue: string) => onChange({ target: { name, value: newValue } })}
        value={value ?? DEFAULT_FORMAT}
        placeholder="Pick a format"
        clearable={false}
        options={REPORT_FORMATS_SHORT}
      />
    )}
  </Field>
);

export default FormatSelect;
