import { useQuery } from '@tanstack/react-query';
import * as Immutable from 'immutable';

import fetch from 'logic/rest/FetchProvider';
import URLUtils from 'util/URLUtils';
import type { WidgetState } from 'views/logic/widgets/Widget';
import Widget from 'views/logic/widgets/Widget';
import Parameter from 'views/logic/parameters/Parameter';
import { defaultCompare } from 'logic/DefaultCompare';
import type { DashboardSummary, WidgetSummary } from 'report/AvailableWidgetsStore';
import type { AvailableWidgetPreview } from 'report/types';

export type AvailableDashboard = {
  id: string;
  title: string;
  widgets: { [key: string]: Array<AvailableWidgetPreview> };
  query_titles: { [queryId: string]: string };
  search_id: string;
};

const deserializeWidget = (widget: WidgetSummary) => ({
  id: widget.id,
  title: widget.title,
  type: widget.type,
  config: Widget.fromJSON(widget as unknown as WidgetState).config,
  parameters: widget.parameters.map((p) => Parameter.fromJSON(p)),
  eligible: widget.eligible,
});

const fetchAvailableWidgets = (): Promise<Immutable.List<AvailableDashboard>> =>
  fetch('GET', URLUtils.qualifyUrl('/plugins/org.graylog.plugins.report/widgets'))
    .then((dashboards: Array<DashboardSummary>) =>
      Immutable.List(
        dashboards.map(({ widgets, ...rest }) => ({
          ...rest,
          widgets: Object.fromEntries(
            Object.entries(widgets).map(([key, widgetsSet]) => [key, widgetsSet.map(deserializeWidget)]),
          ),
        })),
      ),
    )
    .then((dashboards: Immutable.List<AvailableDashboard>) =>
      dashboards.sort((d1, d2) => defaultCompare(d1.title, d2.title)).toList(),
    );

const useAvailableWidgets = () => useQuery(['report.availableWidgets'], fetchAvailableWidgets);

export default useAvailableWidgets;
