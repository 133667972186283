import styled, { css } from 'styled-components';

const Separator = styled.div(
  ({ theme }) => css`
    padding-top: 30px;
    margin-top: 30px;
    border-top: ${theme.colors.gray[90]} 1px solid;
  `,
);
export default Separator;
