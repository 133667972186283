import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Formik } from 'formik';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import NumberUtils from 'util/NumberUtils';
import fetch from 'logic/rest/FetchProvider';
import { Button, Col, Modal, Row } from 'components/bootstrap';
import FormikInput from 'components/common/FormikInput';
import Spinner from 'components/common/Spinner';
import { InputDescription, ModalSubmit, IfPermitted } from 'components/common';
import { SOURCE_URL } from 'report/Constants';
import { qualifyUrl } from 'util/URLUtils';

const StyledDefList = styled.dl.attrs({
  className: 'deflist',
})(
  ({ theme }) => css`
    &&.deflist {
      dt {
        margin-right: 10px;
      }
      dd {
        padding-left: ${theme.spacings.md};
        margin-left: 200px;
      }
    }
  `,
);

const LabelSpan = styled.span(
  ({ theme }) => css`
    margin-left: ${theme.spacings.sm};
    font-weight: bold;
  `,
);

const configUrl = qualifyUrl(`${SOURCE_URL}/config`);
const configKey = ['reporting', 'config'];

const fetchReportingConfig = () => fetch('GET', configUrl);
const updateReportingConfig = (config) => fetch('PUT', configUrl, config);

const ReportingConfig = () => {
  const { data: config, isLoading } = useQuery(configKey, fetchReportingConfig);
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState<boolean>(false);

  const _saveConfig = (values) => {
    updateReportingConfig(values).then(() => {
      queryClient.invalidateQueries(configKey);

      setShowModal(false);
    });
  };

  const _resetConfig = () => {
    setShowModal(false);
  };

  const modalTitle = 'Configure Reporting';

  return (
    <div>
      <h2>Reporting</h2>
      <p>These settings control the automated reporting system.</p>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <StyledDefList>
            <dt>Maximum Size of Reports History:</dt>
            <dd>{NumberUtils.formatBytes(config.maximum_history_size)}</dd>
          </StyledDefList>

          <IfPermitted permissions="clusterconfigentry:edit">
            <p>
              <Button
                type="button"
                bsSize="xs"
                bsStyle="info"
                onClick={() => {
                  setShowModal(true);
                }}>
                Edit configuration
              </Button>
            </p>
          </IfPermitted>

          <Modal show={showModal} onHide={_resetConfig} aria-modal="true" aria-labelledby="dialog_label">
            <Formik onSubmit={_saveConfig} initialValues={config}>
              {({ isSubmitting }) => (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title id="dialog_label">{modalTitle}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div>
                      <Row>
                        <Col sm={12}>
                          <FormikInput
                            type="number"
                            name="maximum_history_size"
                            id="maximum_history_size"
                            label={<LabelSpan>Maximum Report History Size</LabelSpan>}
                          />
                          <InputDescription
                            help={
                              <>
                                This is the maximum size of the report history stored in the database. When exceeded,
                                oldest entries will be deleted.
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <ModalSubmit
                      onCancel={_resetConfig}
                      isSubmitting={isSubmitting}
                      submitButtonText="Update configuration"
                      submitLoadingText="Updating configuration..."
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ReportingConfig;
