import * as React from 'react';

import ParametersContext from 'search-filter/contexts/ParametersContext';
import useParameters from 'views/hooks/useParameters';

const ParametersContextProvider = ({ children }: React.PropsWithChildren) => {
  const parametersContext = useParameters();

  return <ParametersContext.Provider value={parametersContext}>{children}</ParametersContext.Provider>;
};

export default ParametersContextProvider;
