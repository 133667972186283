import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

const urlPrefix = '/plugins/org.graylog.plugins.datatiering/datatiering/repositories';

const DATA_TEARING_READINESS_KEY = 'data_tiering_ready';

type Readiness = {
  ready: boolean;
  details: {
    supported_by_search_version: boolean;
    supported_repository_types: Array<'s3' | 'fs'>;
    restored_snapshots_assignable: boolean;
  };
};

const getReadiness = () => fetch('GET', qualifyUrl(`${urlPrefix}/readiness`));

const useDataTiering = () => {
  const { data, isLoading } = useQuery<Readiness, Error>([DATA_TEARING_READINESS_KEY], () =>
    defaultOnError(
      getReadiness(),
      'Fetching Data Tiering readiness failed with status',
      'Could not fetch Data Tiering readiness.',
    ),
  );

  return {
    readiness: {
      data,
      isLoading,
    },
  };
};

export default useDataTiering;
