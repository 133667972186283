import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';

import useFetch from './common/hooks/useFetch';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import { toDefenderInputCreateRequest } from './common/formDataAdapter';
import type { ErrorMessageType, FormDataContextType, HandleSubmitType } from './types';

import FormWrap from '../common/FormWrap';

const Container = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.variant.darkest.default};
    margin: 25px 0;
    padding: 15px;
    border-radius: 4px;
    width: max-content;
  `,
);

const Subheader = styled.h3`
  margin: 0 0 10px;
`;

const ReviewItems = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0 0 25px 10px;
    padding: 0;

    li {
      line-height: 2;
      padding: 0 5px;

      &:nth-of-type(odd) {
        background-color: ${theme.colors.table.row.backgroundStriped};
      }
    }

    strong::after {
      content: ':';
      margin-right: 5px;
    }
  `,
);

type Props = {
  onSubmit: HandleSubmitType;
  externalInputSubmit: boolean;
};

const StepReview = ({ onSubmit, externalInputSubmit = false }: Props) => {
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { formData } = useContext<FormDataContextType>(FormDataContext);

  const throttleEnabled = !!formData.defenderThrottleEnabled?.value;

  const [saveInput, setSaveInput] = useFetch(null, () => onSubmit(), 'POST', toDefenderInputCreateRequest(formData));

  useEffect(() => {
    // Wrong !!
    setSaveInput(null);

    if (saveInput.error) {
      setFormError({
        full_message: saveInput.error,
        nice_message: <span>We were unable to save your Input, please try again in a few moments.</span>,
      });
    }
  }, [saveInput.error, setSaveInput]);

  const handleSubmit = () => {
    if (externalInputSubmit) {
      onSubmit(formData);

      return;
    }

    setSaveInput(ApiRoutes.INTEGRATIONS.Defender.SAVE_INPUT);
  };

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Save and Start Input"
      loading={saveInput.loading}
      error={formError}
      description="Check out everything below to make sure it's correct, then click the button below to complete your Microsoft Defender setup!">
      <Container>
        <Subheader>Input Configuration</Subheader>
        <ReviewItems>
          <li>
            <strong>Name</strong>
            <span>{formData.defenderName.value}</span>
          </li>
          <li>
            <strong>Enable Throttling</strong>
            <span>
              <Icon name={throttleEnabled ? 'check_circle' : 'cancel'} />
            </span>
          </li>
          <li>
            <strong>Application Client ID</strong>
            <span>{formData.clientId.value}</span>
          </li>
        </ReviewItems>
      </Container>
    </FormWrap>
  );
};

export default StepReview;
