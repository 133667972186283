import * as React from 'react';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { useModalContext } from 'common/contexts';

import GitRulesModal from './GitRules';
import SigmaModal from './SigmaModal';
import UploadRulesModal from './UploadRulesModal';

export type ModalTypes = 'MANUAL-ADD' | 'IMPORT-FROM-GIT' | 'UPLOAD-RULES';

function SigmaModals() {
  const sendTelemetry = useSendTelemetry();
  const { modal, setModal, entity: sigmaRule, setEntity } = useModalContext();

  if (modal) {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.SIGMA_IMPORT_RULES_OPENED, {
      app_pathname: 'security',
      app_section: 'sigma',
      event_details: { action: modal },
    });
  }

  const onClose = () => {
    setEntity(null);
    setModal(null);
  };

  switch (modal) {
    case 'MANUAL-ADD':
      return <SigmaModal rule={sigmaRule} onConfirm={onClose} onCancel={onClose} />;
    case 'IMPORT-FROM-GIT':
      return <GitRulesModal onClose={onClose} />;
    case 'UPLOAD-RULES':
      return <UploadRulesModal onClose={onClose} />;
    default:
      return null;
  }
}

export default SigmaModals;
