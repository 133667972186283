import __request__ from 'routing/request';
interface CollectorAction {
    readonly collector_id: string;
    readonly properties: {
        readonly [_key: string]: Object;
    };
}
type CollectorActionArray = CollectorAction[];
/**
 * Set a collector action
 * @param sidecarId The id this Sidecar is registering as.
 */
export function setAction(sidecarId: string, JSONbody: CollectorActionArray): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.sidecar/sidecar/action/${sidecarId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns queued actions for the specified Sidecar id
 */
export function getAction(sidecarId: string): Promise<CollectorActionArray> {
    return __request__('GET', `/plugins/org.graylog.plugins.sidecar/sidecar/action/${sidecarId}`, null, {}, {
        'Accept': ['application/json']
    });
}
