import __request__ from 'routing/request';
interface PaginatedResponse_TeamDTO {
    readonly [_key: string]: Object;
}
type stringArray = string[];
/**
 * Get a paginated list of teams for a role
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getUsersForRole(sort: 'name' | 'description' = 'name', roleId?: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_TeamDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/authz/roles/${roleId}/teams`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Assign a role to many teams
 */
export function addRoleToTeams(roleId?: string, teamIds?: stringArray): Promise<void> {
    return __request__('PUT', `/plugins/org.graylog.plugins.security/authz/roles/${roleId}/teams/add`, teamIds, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove a role from a team
 */
export function removeRoleFromTeam(roleId?: string, teamId?: string): Promise<void> {
    return __request__('PUT', `/plugins/org.graylog.plugins.security/authz/roles/${roleId}/teams/remove`, teamId, {}, {
        'Accept': ['application/json']
    });
}
