import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';
import Select from 'components/common/Select';

import FormAdvancedOptions from './FormAdvancedOptions';
import INITIAL_FORMDATA from './_initialFormData';

import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from '../common/utils/types';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe = ({ onSubmit, onChange }: Props) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { pollingInterval } = formData;

  const availableContent = [
    { label: 'Archive Message View', value: 'ARCHIVE_MESSAGE_VIEW' },
    { label: 'Archive Search', value: 'ARCHIVE_SEARCH' },
    { label: 'Audit Events', value: 'AUDIT_EVENTS' },
    { label: 'DLP', value: 'DLP' },
    { label: 'Message Release', value: 'MESSAGE_RELEASE' },
    { label: 'Rejection', value: 'REJECTION' },
    { label: 'Search', value: 'SEARCH' },
    { label: 'TTP Attachment Protection', value: 'TTP_ATTACHMENT_PROTECTION' },
    { label: 'TTP Impersonation Protect', value: 'TTP_IMPERSONATION_PROTECT' },
    { label: 'TTP Url', value: 'TTP_URL' },
  ];

  const [contentTypes, setContentTypes] = useState(INITIAL_FORMDATA.availableContents.value);
  const [contentTypeVal, setContentTypeVal] = useState(false);

  const handleSubmit = () => {
    let error = false;

    if (contentTypes.length === 0) {
      setContentTypeVal(true);
      error = true;
    }

    formData.contentTypes.value = contentTypes;

    if (pollingInterval.value < 1) {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message: 'Minimum allowable polling interval is 1 minute.',
      });

      error = true;
    }

    if (!error) {
      setFormError(null);
      onSubmit();
    }
  };

  return (
    <FormWrap onSubmit={handleSubmit} buttonContent="Proceed" title="" error={formError} description="">
      <label className="control-label" htmlFor="contents">
        Logs Types to Collect
      </label>
      <Select
        multi
        name="contentTypes"
        id="contentTypes"
        onChange={(selected) => {
          setContentTypes(selected);
        }}
        options={availableContent}
        value={contentTypes || ''}
        matchProp="label"
      />

      {contentTypeVal && contentTypes.length === 0 && (
        <label htmlFor="contentTypes" style={{ color: 'red' }}>
          Select at least one Log Type
        </label>
      )}

      <span className="help-block">The activity log for the above content types will be fetched.</span>
      <Input
        id="pollingInterval"
        name="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue || ''}
        min="1"
        max="60"
        step="1"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in Mimecast API. The smallest allowable interval is 1 minute."
        label="Polling Interval"
      />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
