import * as React from 'react';
import styled, { css, useTheme } from 'styled-components';
import get from 'lodash/get';

import MessageSummaryTemplateContext from 'search/contexts/MessageSummaryTemplateContext';
import indicatorStatusColor from 'security-content/indicatorStatusColor';
import useLicenseCheck from 'license/useLicenseCheck';
import type { EventCategories, EventCategory, IndicatorTemplate } from 'security-content/types';
import { parseIfArrayValue } from 'search/messageFieldValueHelper';
import type MessagesWidgetConfig from 'views/logic/widgets/MessagesWidgetConfig';

type MessageFields = { [key: string]: any };

const indicatorStatusFields = ['info', 'primary', 'success', 'warning', 'danger', 'default', 'gray'];
const indicatorAttributes = ['indicator', 'defaultIndicator', ...indicatorStatusFields];

const parseArray = (value) => {
  const parsedArrayValue = parseIfArrayValue(value);

  return parsedArrayValue ?? [];
};

const removeMultipleSpaces = (input: string) => input.replace(/  +/g, ' ');

const replaceFieldPlaceholder = (input: string, replaceFn: (placeholder: string, fieldName: string) => string) =>
  removeMultipleSpaces(input.replace(/{(\w+)}/g, replaceFn));

const messageEventTypeCategories = (eventTypeCategories: EventCategories, messageFields: MessageFields) => {
  const { gim_event_subcategory: eventCategories } = messageFields;
  const eventCategoriesList = parseArray(eventCategories);

  if (eventCategoriesList.length === 0) {
    return [];
  }

  return eventCategoriesList
    .flatMap((categoryName) => get(eventTypeCategories, categoryName))
    .filter((eventCategory) => !!eventCategory) as Array<EventCategories>;
};

const messageSummary = (eventTypeCategory: EventCategory, messageFields: MessageFields) => {
  if (!eventTypeCategory) {
    return undefined;
  }

  const formatString = eventTypeCategory.summary;

  if (!formatString) {
    return undefined;
  }

  return {
    formatString: replaceFieldPlaceholder(formatString, (fieldNamePlaceholder, fieldName) =>
      messageFields[fieldName] !== undefined ? fieldNamePlaceholder : '',
    ),
    summary: replaceFieldPlaceholder(formatString, (_fieldNamePlaceholder, fieldName) =>
      messageFields[fieldName] !== undefined ? messageFields[fieldName] : '',
    ),
  };
};

const messageIndicatorStatus = (eventTypeCategory: EventCategory, messageFields: MessageFields) => {
  const indicatorTemplate = eventTypeCategory.indicatorTemplate ?? {};

  const indicator: IndicatorTemplate = Object.entries(eventTypeCategory)
    .filter(([key]) => indicatorAttributes.includes(key))
    .reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value,
      }),
      indicatorTemplate,
    );

  if (!indicator.indicator) {
    return indicator.defaultIndicator;
  }

  const fieldValue = messageFields[indicator.indicator];

  if (!fieldValue) {
    return undefined;
  }

  const matchingStatus = Object.entries(indicator)
    .filter(([key]) => indicatorStatusFields.includes(key))
    .filter(([, matchingValues]) => Array.isArray(matchingValues) && matchingValues.includes(fieldValue))
    .map(([key]) => key);

  if (matchingStatus.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`Found more than one indicator status for field value ${fieldValue}.`);
  }

  if (!Array.isArray(matchingStatus) || !matchingStatus.length) {
    return !eventTypeCategory.defaultIndicator
      ? indicatorTemplate.defaultIndicator
      : eventTypeCategory.defaultIndicator;
  }

  return matchingStatus[0];
};

const messageSummaries = (eventTypeCats, messageFields, theme) =>
  eventTypeCats
    .map((eventTypeCat) => {
      const summary = messageSummary(eventTypeCat, messageFields);

      if (!summary) {
        return undefined;
      }

      const indicatorStatus = messageIndicatorStatus(eventTypeCat, messageFields);
      const summaryColor = indicatorStatusColor(indicatorStatus, theme);

      return {
        summary,
        summaryColor,
      };
    })
    .filter((eventTypeCat) => !!eventTypeCat);

const Container = styled.div<{ $color: string; $hideOverflow: boolean }>(
  ({ $color, $hideOverflow }) => css`
    color: ${$color};
    white-space: pre-line;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    ${$hideOverflow &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
  `,
);

type Props = {
  config?: MessagesWidgetConfig;
  hideOverflow?: boolean;
  messageFields: { [key: string]: any };
  renderMessageRow: () => React.ReactNode;
};

const MessageSummary = ({
  config = { showSummary: true } as MessagesWidgetConfig,
  renderMessageRow,
  hideOverflow = false,
  messageFields,
}: Props) => {
  const theme = useTheme();
  const {
    security: { isValid: isValidSecurityLicense },
  } = useLicenseCheck();
  const contextValue = React.useContext(MessageSummaryTemplateContext);
  const { isLoading, isError, eventTypeCategories } = contextValue ?? {};

  if (!config.showSummary || !isValidSecurityLicense || isError || isLoading) {
    return <>{renderMessageRow()}</>;
  }

  const eventTypeCats = messageEventTypeCategories(eventTypeCategories, messageFields);
  const summaries = messageSummaries(eventTypeCats, messageFields, theme);

  if (summaries.length === 0) {
    return <>{renderMessageRow()}</>;
  }

  return summaries.map(({ summary: { formatString, summary }, summaryColor }) => (
    <Container
      $color={summaryColor}
      $hideOverflow={hideOverflow}
      title={hideOverflow ? summary : formatString}
      key={formatString}>
      {summary}
    </Container>
  ));
};

export default MessageSummary;
