import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { stringifyFilters } from 'security-app/components/common/Filters/stringifyFilters';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import fetch from 'logic/rest/FetchProvider';
import type { SearchJobResult } from 'views/logic/SearchResult';
import type { SearchJson } from 'views/logic/search/Search';
import type { ViewJson } from 'views/logic/views/View';

import type {
  InvestigationAPIType,
  InvestigationsDetailsAPIType,
  InvestigationsIndexAPIType,
  NewInvestigationAPIType,
  UpdateEvidenceAPIType,
  BulkAddEvidenceAPIType,
  UpdateInvestigationAPIType,
  ArchiveInvestigationAPIType,
  PriorityAPIType,
} from './investigationsAPI.types';

export const fetchInvestigations = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = undefined,
  direction: string = undefined,
  filters: ColumnFilterData = {},
  archived: boolean = false,
  userTimezone: string = null,
): Promise<InvestigationsIndexAPIType> => {
  const params = [`page=${page}`, `per_page=${perPage}`, `archived=${archived}`];
  if (query) params.push(`query=${encodeURI(query)}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
    params.push(`user_timezone=${encodeURI(userTimezone)}`);
  }

  if (orderBy) {
    params.push(`sort=${orderBy}`);
  }

  if (direction) {
    params.push(`direction=${direction}`);
  }

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.index(params.join('&')).url));
};

export const fetchInvestigationsFilterOptions = async ({
  fields,
  archived,
}: {
  fields: string[];
  archived: boolean;
}) => {
  const payload = {
    fields,
    archived,
  };

  return fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.filters().url), payload);
};

const investigationToUpdatePayload = (investigation: InvestigationAPIType): UpdateInvestigationAPIType => ({
  id: investigation.id,
  name: investigation.name,
  assigned_to: investigation.assigned_to,
  priority: investigation.priority,
  status: investigation.status,
  archived: investigation.archived,
});

export const updateInvestigations = async ({ investigations }: { investigations: InvestigationAPIType[] }) => {
  const payload = {
    requests: investigations.map(investigationToUpdatePayload),
  };

  return fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.update().url), payload);
};

export const getInvestigationsDashboardView = async (viewId: string): Promise<ViewJson> =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.Dashboards.viewConfig(viewId).url));

export const getInvestigationsDashboardSearch = async (searchId: string): Promise<SearchJson> =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.Dashboards.searchJob(searchId).url));

export const getInvestigationsDashboardSearchResults = async ({
  searchId,
  payload,
}: {
  searchId: string;
  payload: any;
}): Promise<SearchJobResult> =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.Dashboards.searchJobResults(searchId).url), payload);

export const getInvestigationDetails = async (invId: string): Promise<InvestigationsDetailsAPIType> =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.singleInvestigation(invId).url));

export const archiveInvestigation = async ({ invId, archive }: { invId: string; archive: boolean }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.archive(invId, archive).url));

export const bulkArchiveInvestigation = async ({ payload }: { payload: { requests: ArchiveInvestigationAPIType[] } }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.bulkArchive().url), payload);

export const deleteInvestigation = async ({ invId }: { invId: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.singleInvestigation(invId).url));

export const bulkDeleteInvestigations = async ({ payload }: { payload: { ids: string[] } }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.bulkDelete().url), payload);

export const addEvidence = async ({ invId, payload }: { invId: string; payload: UpdateEvidenceAPIType }) =>
  fetch(
    'PUT',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.addEvidenceToInvestigation(invId).url),
    payload,
  );

export const bulkAddEvidence = async ({ payload }: { payload: BulkAddEvidenceAPIType }) =>
  fetch(
    'PUT',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.bulkAddEvidenceToInvestigation().url),
    payload,
  );

export const removeEvidence = async ({ invId, payload }: { invId: string; payload: UpdateEvidenceAPIType }) =>
  fetch(
    'PUT',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.removeEvidenceFromInvestigation(invId).url),
    payload,
  );

export const addNote = async ({ invId, note }: { invId: string; note: string }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.addNoteToInvestigation(invId).url), note);

export const editNote = async ({ invId, noteId, note }: { invId: string; noteId: string; note: string }) =>
  fetch(
    'PUT',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.editInvestigationNote(invId, noteId).url),
    note,
  );

export const deleteNote = async ({ invId, noteId }: { invId: string; noteId: string }) =>
  fetch(
    'DELETE',
    qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.deleteInvestigationNote(invId, noteId).url),
  );

export const newInvestigation = async ({ payload }: { payload: NewInvestigationAPIType }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.new().url), payload);

export const fetchPriorities = async () =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.index().url));

export const fetchStatuses = async () =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.statuses.index().url));

export const fetchUsers = async () =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.fetchUsers().url));

export const fetchTeams = async () =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.fetchTeams().url));

export const fetchInvestigationsConfig = async () =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.fetchConfig().url));

export const enableNotifications = async ({ enabled }: { enabled: boolean }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.enableNotifications(enabled).url));

export const addPriority = async ({ priority }: { priority: string }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.add().url), priority);

export const reprioritizePriorities = async ({ payload }: { payload: { priorities: PriorityAPIType[] } }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.reprioritize().url), payload);

export const updatePriority = async ({ priorityId, priority }: { priorityId: string; priority: string }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.update(priorityId).url), priority);

export const deletePriority = async ({ priorityId }: { priorityId: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.delete(priorityId).url));

export const addStatus = async ({ status }: { status: string }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.statuses.add().url), status);

export const updateStatus = async ({ statusId, status }: { statusId: string; status: string }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.statuses.update(statusId).url), status);

export const deleteStatus = async ({ statusId }: { statusId: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.statuses.delete(statusId).url));

export const setDefaultPriority = async ({ priorityId }: { priorityId: string }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.setDefault(priorityId).url));

export const removeDefaultPriority = async () =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.priorities.removeDefault().url));

export const setDefaultStatus = async ({ statusId }: { statusId: string }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.statuses.setDefault(statusId).url));

export const removeDefaultStatus = async () =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.statuses.removeDefault().url));
