import { useQuery } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

const fetchIsDatanodeConfigured = async () => fetch('GET', qualifyUrl('/datanode/configured'));

const useIsDatanodeConfigured = (): {
  isDatanodeConfigured: boolean;
  isLoading: boolean;
} => {
  const { data: isDatanodeConfigured, isInitialLoading: isLoading } = useQuery(
    ['IsDatanodeConfigured'],
    fetchIsDatanodeConfigured,
  );

  return {
    isDatanodeConfigured,
    isLoading,
  };
};

export default useIsDatanodeConfigured;
