import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Button, MenuItem } from 'components/bootstrap';
import { useModalContext } from 'common/contexts';
import { DropdownMenu } from 'security-app/components/common';

const IconContainer = styled.div`
  width: 16px;
  display: inline-block;
  text-align: left;
`;

function AddRulesMenu({ pushRight = false }: { pushRight?: boolean }) {
  const { setModal, setEntity } = useModalContext();

  const manualAdd = () => {
    setEntity(undefined);
    setModal('MANUAL-ADD');
  };

  const importFromGit = () => {
    setEntity(undefined);
    setModal('IMPORT-FROM-GIT');
  };

  const uploadRuleFiles = () => {
    setEntity(undefined);
    setModal('UPLOAD-RULES');
  };

  return (
    <DropdownMenu
      position={pushRight ? 'bottom-end' : 'bottom-start'}
      trigger={
        <Button bsStyle="primary" data-testid="new-btn">
          <IconContainer>
            <Icon name="arrow_drop_down" size="xs" />
          </IconContainer>
          Add Rules
        </Button>
      }>
      <MenuItem onClick={manualAdd}>Manual Add</MenuItem>
      <MenuItem onClick={importFromGit}>Import From Git</MenuItem>
      <MenuItem onClick={uploadRuleFiles}>Upload Rules</MenuItem>
    </DropdownMenu>
  );
}

export default AddRulesMenu;
