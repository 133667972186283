import React from 'react';

import { LinkContainer } from 'components/common/router';
import { DocumentTitle, PageHeader } from 'components/common';
import withParams from 'routing/withParams';
import { Button, Col, Row } from 'components/bootstrap';
import Routes from 'routing/Routes';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import withIsPermitted from 'forwarder/util/withIsPermitted';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import ForwarderWizard from './ForwarderWizard';

type ForwarderWizardPageProps = {
  params: {
    forwarderId?: string;
  };
};

const REQUIRED_PERMISSIONS = [
  'forwarders:create',
  'forwarders:edit',
  'forwarders:read',
  'inputprofiles:create',
  'inputprofiles:edit',
  'inputprofiles:read',
  'forwarderinputs:create',
  'forwarderinputs:edit',
  'forwarderinputs:read',
];

const ForwarderWizardPage = ({ params }: ForwarderWizardPageProps) => {
  const pageHeader = params && params.forwarderId ? 'Configure Forwarder' : 'New Forwarder';
  const sendTelemetry = useSendTelemetry();

  const onForwarderSetupExit = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_WIZARD_SETUP_CANCELED, {
      app_pathname: 'forwarder',
      app_section: 'forwarder-wizard',
    });
  };

  return (
    <DocumentTitle title={pageHeader}>
      <>
        <PageHeader
          title={pageHeader}
          documentationLink={{
            title: 'Forwarder documentation',
            path: ForwarderDocs.FORWARDER,
          }}
          actions={
            <LinkContainer onClick={() => onForwarderSetupExit()} to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
              <Button bsStyle="info">Exit Forwarder Setup</Button>
            </LinkContainer>
          }>
          <span>Set up a Graylog Forwarder to start sending data into Graylog.</span>
        </PageHeader>

        <Row className="content">
          <Col md={12}>
            <ForwarderWizard forwarderId={params && params.forwarderId} />
          </Col>
        </Row>
      </>
    </DocumentTitle>
  );
};

export default withParams(withIsPermitted(ForwarderWizardPage, REQUIRED_PERMISSIONS));
