import * as React from 'react';
import styles from 'styled-components';

import { Row as BsRow } from 'components/bootstrap';
import DocumentationLink from 'components/support/DocumentationLink';

import { Container, Col, Row } from './FlexBox';

const ContainerWithBackground = styles(Container)`
  background-color: ${({ theme }) => theme.colors.global.contentBackground};
  padding: ${({ theme }) => theme.spacings.md};
  border-radius: ${({ theme }) => theme.spacings.xs};
  justify-content: stretch;
  align-content: stretch;
  overflow: auto;
`;

const Header = styles(Row)`
  margin-bottom: ${({ theme }) => theme.spacings.sm};

  & > h1 {
    width: 100%;
  }
`;

type Props = {
  children: React.ReactNode,
  variant?: 'row' | 'column',
  title?: string,
  subTitle?: string,
  rightSection?: React.ReactNode,
  docLink?: { title: string, path: string },
};

function SectionContainer({children, variant = 'column', title = undefined, subTitle = undefined, rightSection = undefined, docLink = undefined}: Props) {
  return (
    <BsRow style={{ padding: 0 }}>
      <ContainerWithBackground $direction={variant} $width="100%" className="content">
        {(title || rightSection) && (
          <Header $align="flex-start" $justify="space-between">
            {title && (
              <h1>
                <Col $gap="xs">
                  {title}
                  {subTitle && <small className="description no-bm">{subTitle}</small>}
                </Col>
              </h1>
            )}
            {(rightSection || docLink) && (
              <Col $gap="sm" $align="flex-end">
                {docLink ? <DocumentationLink text={docLink.title} page={docLink.path} displayIcon /> : null}
                {rightSection}
              </Col>
            )}
          </Header>
        )}
        {children}
      </ContainerWithBackground>
    </BsRow>
  );
}

export default SectionContainer;
