import * as React from 'react';
import * as Immutable from 'immutable';
import { useContext, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import type { Props as GroupSyncFromProps } from 'components/authentication/directoryServices/BackendWizard/GroupSyncStep';
import { InputOptionalInfo as Opt, FormikFormGroup, Select } from 'components/common';
import { Button, ButtonToolbar, Alert, Row, Col, Input } from 'components/bootstrap';
import BackendWizardContext from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';
import { validateField, formHasErrors } from 'util/FormsUtils';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import MatchingGroupsOverview from './MatchingGroupsOverview';
import SelectedGroupsOverview from './SelectedGroupsOverview';
import SelectionTypeOptions from './SelectionTypeOptions';
import GroupSyncCheckbox from './GroupSyncCheckbox';

import LicenseCheck from '../../../license/LicenseCheck';

const Headline = styled.h3`
  margin-bottom: 20px;
`;

// Form validation needs to include all input names
// to be able to associate backend validation errors with the form
export const formValidation = (selectionType: string | null | undefined) => {
  let teamSelection;

  if (selectionType !== 'all') {
    teamSelection = {
      required: true,
    };
  }

  const validation = {
    groupSearchBase: {
      required: true,
    },
    groupSearchPattern: {
      required: true,
    },
    teamNameAttribute: {
      required: true,
    },
    teamUniqueIdAttribute: {
      required: true,
    },
    teamDefaultRoles: {},
    teamSelectionType: {},
    teamSelection,
  };

  return validation;
};

const _getInitialValues = (formValues) => {
  const {
    groupSearchPattern,
    groupSearchBase,
    teamUniqueIdAttribute,
    teamNameAttribute,
    teamDefaultRoles,
    teamSelectionType,
    teamSelection,
    synchronizeGroups,
  } = formValues;

  return {
    synchronizeGroups,
    groupSearchPattern,
    groupSearchBase,
    teamUniqueIdAttribute,
    teamNameAttribute,
    teamDefaultRoles,
    teamSelectionType: teamSelectionType || 'all',
    teamSelection: teamSelection || Immutable.Set(),
  };
};

const GroupSyncForm = ({
  help = {},
  prepareSubmitPayload,
  formRef,
  onSubmitAll,
  submitAllError,
  validateOnMount,
  roles,
  excludedFields,
}: GroupSyncFromProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setStepsState, ...stepsState } = useContext(BackendWizardContext);
  const { backendValidationErrors } = stepsState;
  const rolesOptions = roles.map((role) => ({ label: role.name, value: role.id })).toArray();
  const _formValidation = useCallback((teamSelectionType) => formValidation(teamSelectionType), []);
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const _onSubmitAll = (validateForm, values, licenseIsValid) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.DIRECTORY_GROUP_SYNC_SAVE_CLICKED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'services-groupsync-step',
      app_action_value: 'save-service',
    });

    if (values.synchronizeGroups) {
      return validateForm().then((errors) => {
        if (!formHasErrors(errors)) {
          onSubmitAll(licenseIsValid);
        }
      });
    }

    return onSubmitAll(licenseIsValid);
  };

  return (
    <LicenseCheck>
      {({ licenseIsValid }) => {
        const disableGroupSyncStep = !licenseIsValid;

        return (
          <Formik
            initialValues={_getInitialValues(stepsState.formValues)}
            onSubmit={() => onSubmitAll(licenseIsValid)}
            // @ts-ignore
            innerRef={formRef}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={validateOnMount}>
            {({ isSubmitting, values, setFieldValue, validateForm }) => {
              const validation = _formValidation(values.teamSelectionType);
              const disableForm = disableGroupSyncStep || !values.synchronizeGroups;

              const _onGroupSelect = (groupId) => {
                const selectedGroupsIds = values.teamSelection ?? Immutable.List();

                if (selectedGroupsIds?.includes(groupId)) {
                  setFieldValue('teamSelection', selectedGroupsIds.remove(groupId));
                } else {
                  setFieldValue('teamSelection', selectedGroupsIds.add(groupId));
                }
              };

              return (
                <Form className="form form-horizontal">
                  <Row>
                    <Col xs={12}>
                      <Alert bsStyle="info" title="Introduction">
                        This step enables importing {stepsState.authBackendMeta.serviceTitle} groups as Graylog teams.
                        The group synchronization can be activated by clicking on the checkbox with the label{' '}
                        <i>Enable Group Synchronization</i>. Afterwards fill out the form and test your settings in the
                        second section <i>Load matching groups</i>. The last section <i>Select groups to import</i>{' '}
                        allows you to specify which groups you want to import.
                      </Alert>
                    </Col>
                  </Row>

                  <GroupSyncCheckbox disabled={disableGroupSyncStep} />

                  <hr />

                  <FormikFormGroup
                    help={help.groupSearchBase}
                    validate={validateField(validation.groupSearchBase)}
                    error={backendValidationErrors?.groupSearchBase}
                    label="Group Search Base DN"
                    disabled={disableForm}
                    name="groupSearchBase"
                    placeholder="Group Search Base"
                  />

                  <FormikFormGroup
                    help={help.groupSearchPattern}
                    validate={validateField(validation.groupSearchPattern)}
                    error={backendValidationErrors?.groupSearchPattern}
                    disabled={disableForm}
                    label="Group Search Pattern"
                    name="groupSearchPattern"
                    placeholder="Group Search Pattern"
                  />

                  <FormikFormGroup
                    help={help.teamNameAttribute}
                    validate={validateField(validation.teamNameAttribute)}
                    error={backendValidationErrors?.teamNameAttribute}
                    disabled={disableForm}
                    label="Team Name Attribute"
                    name="teamNameAttribute"
                    placeholder="Team Name Attribute"
                  />

                  {!excludedFields.teamUniqueIdAttribute && (
                    <FormikFormGroup
                      help={help.teamUniqueIdAttribute}
                      label="Team ID Attribute"
                      error={backendValidationErrors?.teamUniqueIdAttribute}
                      validate={validateField(validation.teamUniqueIdAttribute)}
                      disabled={disableForm}
                      name="teamUniqueIdAttribute"
                      placeholder="Team ID Attribute"
                    />
                  )}

                  <Field name="teamDefaultRoles" validate={validateField(validation.teamDefaultRoles)}>
                    {({ field: { name, value, onChange, onBlur }, meta: { error } }) => (
                      <Input
                        bsStyle={error ? 'error' : undefined}
                        help={help.teamDefaultRoles}
                        error={error ?? backendValidationErrors?.teamDefaultRoles}
                        id="default-team-roles-select"
                        disabled={disableForm}
                        label={
                          <>
                            Default Team Roles <Opt />
                          </>
                        }
                        labelClassName="col-sm-3"
                        wrapperClassName="col-sm-9">
                        <Select
                          multi
                          onBlur={onBlur}
                          onChange={(selectedRoles) => onChange({ target: { value: selectedRoles, name } })}
                          options={rolesOptions}
                          disabled={disableForm}
                          placeholder="Search for roles"
                          value={value}
                        />
                      </Input>
                    )}
                  </Field>

                  <Headline>Load matching groups</Headline>

                  <MatchingGroupsOverview
                    disabled={disableForm}
                    prepareSubmitPayload={prepareSubmitPayload}
                    onGroupSelect={_onGroupSelect}
                  />

                  <Headline>Select groups to import</Headline>

                  <SelectionTypeOptions disabled={disableForm} />

                  <SelectedGroupsOverview onGroupSelect={_onGroupSelect} validation={validation} />

                  {submitAllError}

                  <ButtonToolbar className="pull-right">
                    <Button
                      bsStyle="primary"
                      disabled={isSubmitting}
                      onClick={() => _onSubmitAll(validateForm, values, licenseIsValid)}>
                      Finish & Save Service
                    </Button>
                  </ButtonToolbar>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </LicenseCheck>
  );
};

export default GroupSyncForm;
