import * as React from 'react';
import styled, { css } from 'styled-components';

import { IfPermitted, PaginatedList, SearchForm, Spinner } from 'components/common';
import { Button, ButtonGroup, Col, Row, Table } from 'components/bootstrap';
import QueryHelper from 'components/common/QueryHelper';

import ForwarderEntry from './ForwarderEntry';
import CreateForwarderButton from './CreateForwarderButton';

import type { Forwarder, ForwarderPagination, ForwarderStateFilter, InputProfile, SortOrder } from '../Types';
import { StyledSearchFormWrapper, StyledToolbar } from '../wizard/StyledWizardComponents';
import { StyledSortIcon } from '../CommonStyledComponents';

const StyledTable = styled(Table)`
  tr > th {
    width: 15%;

    &.rowTitle {
      width: 25%;
    }
    &.rowStatus {
      width: 120px;
      min-width: 120px;
    }
    &.rowDescription {
      width: 20%;
    }
    &.rowVersion {
      width: 150px;
      min-width: 150px;
    }
    &.rowMetrics {
      width: 10%;
    }
    &.rowActions {
      width: 160px;
      min-width: 160px;
    }
  }
`;

const ResponsiveTable = styled.div.attrs(() => ({
  className: 'table-responsive',
}))(
  ({ theme }) => css`
    @media (max-width: ${theme.breakpoints.max.md}) {
      .dropdown-menu {
        position: static !important;
      }
    }
    @media (min-width: ${theme.breakpoints.min.md}) {
      overflow: inherit;
    }
  `,
);

const StyledSubHeader = styled.span`
  margin-left: 0.5em;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 10px;
`;

const ForwardersTable = ({
  forwarders,
  inputProfiles,
}: {
  forwarders: Array<Forwarder>;
  inputProfiles: Array<InputProfile>;
}) => {
  if (forwarders.length === 0) {
    return (
      <tr>
        <td colSpan={100}>No Forwarders match your search criteria.</td>
      </tr>
    );
  }

  return (
    <>
      {forwarders.map((forwarder) => {
        const inputProfile = inputProfiles.find((i) => i.id === forwarder.input_profile_id);

        return <ForwarderEntry key={forwarder.id} forwarder={forwarder} inputProfile={inputProfile || null} />;
      })}
    </>
  );
};

const queryExamples = (
  <>
    <p>
      Find Forwarder by parts of their title:&nbsp;
      <kbd>title:local</kbd>&nbsp;
      <kbd>title:database</kbd>
    </p>
    <p>
      Searching without a field name matches against the <code>title</code> field: &nbsp;
      <kbd>local</kbd> is the same as &nbsp;
      <kbd>title:local</kbd>
    </p>
  </>
);

const fieldMap = {
  hostname: 'The hostname of the Forwarder',
  version: 'The version of the Forwarder',
};

const HelpPopover = () => (
  <QueryHelper
    entityName="Forwarder"
    example={queryExamples}
    commonFields={['title', 'description']}
    fieldMap={fieldMap}
  />
);

type Props = {
  forwarders: Array<Forwarder>;
  inputProfiles: Array<InputProfile>;
  isLoading?: boolean;
  pagination: ForwarderPagination;
  onQueryChange?: (query?: string) => void;
  onSortChange?: (sortByField: string, order: SortOrder) => void;
  onStateFilterChange?: (stateFilter: ForwarderStateFilter) => void;
};

const ForwardersListComponent = ({
  forwarders,
  inputProfiles,
  isLoading = false,
  onQueryChange = () => {},
  onSortChange = () => {},
  onStateFilterChange = () => {},
  pagination,
}: Props) => {
  const { total, query, sortByField, order, stateFilter } = pagination;

  const _handleSortChange = (field: string) => {
    let nextOrder: SortOrder = 'asc';

    if (sortByField === field) {
      nextOrder = order === 'asc' ? 'desc' : 'asc';
    }

    onSortChange(field, nextOrder);
  };

  const _handleStateFilterChange = (event) => {
    onStateFilterChange(event.target.name);
  };

  const tableHeaders = [
    { sortable: true, field: 'title', label: 'Title', className: 'rowTitle' },
    { sortable: true, field: 'state', label: 'Status', className: 'rowStatus' },
    { sortable: true, field: 'description', label: 'Description', className: 'rowDescription' },
    { sortable: true, field: 'hostname', label: 'Hostname', className: 'rowHostname' },
    { sortable: true, field: 'version', label: 'Version', className: 'rowVersion' },
    { sortable: false, field: undefined, label: 'Input Profile', className: 'rowInputProfile' },
    { sortable: false, field: undefined, label: 'Metrics', className: 'rowMetrics' },
    { sortable: false, field: undefined, label: 'Actions', className: 'rowActions' },
  ];

  const queryHelp = <HelpPopover />;

  return (
    <div>
      <Row className="content">
        <Col md={12}>
          <h2>
            Forwarders
            <small>
              <StyledSubHeader>{`${total} Total`}</StyledSubHeader>
              <StyledSubHeader>{isLoading && <Spinner text="" />}</StyledSubHeader>
            </small>
          </h2>
          <StyledSearchFormWrapper>
            <SearchForm query={query} queryHelpComponent={queryHelp} onSearch={onQueryChange} onReset={onQueryChange}>
              <StyledToolbar>
                <IfPermitted permissions="forwarders:create">
                  <CreateForwarderButton buttonTitle="New Forwarder" />
                </IfPermitted>
              </StyledToolbar>
            </SearchForm>
          </StyledSearchFormWrapper>

          <StyledButtonGroup>
            <Button
              active={stateFilter === 'connected'}
              name="connected"
              bsSize="sm"
              onClick={_handleStateFilterChange}>
              Connected
            </Button>
            <Button
              active={stateFilter === 'disconnected'}
              name="disconnected"
              bsSize="sm"
              onClick={_handleStateFilterChange}>
              Disconnected
            </Button>
            <Button active={stateFilter === 'any'} name="any" bsSize="sm" onClick={_handleStateFilterChange}>
              Any
            </Button>
          </StyledButtonGroup>

          <PaginatedList totalItems={total}>
            <ResponsiveTable>
              <StyledTable hover>
                <thead>
                  <tr>
                    {tableHeaders.map(({ sortable, field, label, className }) =>
                      sortable && field ? (
                        <th key={field} className={className}>
                          {label}
                          <StyledSortIcon
                            onChange={() => _handleSortChange(field)}
                            activeDirection={sortByField === field ? order : null}
                            ascId="asc"
                            descId="desc"
                          />
                        </th>
                      ) : (
                        <th key={label} className={className}>
                          {label}
                        </th>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  <ForwardersTable forwarders={forwarders} inputProfiles={inputProfiles} />
                </tbody>
              </StyledTable>
            </ResponsiveTable>
          </PaginatedList>
        </Col>
      </Row>
    </div>
  );
};

export default ForwardersListComponent;
