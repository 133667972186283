import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import type { WidgetRef } from 'report/types';
import { toBackendWidgetRef } from 'report/report-creation/content/types';
import type { WidgetDetails } from 'report/report-creation/content/fetchWidgetDetails';
import fetchWidgetDetails from 'report/report-creation/content/fetchWidgetDetails';

const generateKey = (widgets: Array<WidgetRef>) => widgets.map(({ widgetId }) => widgetId).sort();

const useWidgetDetails = (widgets: Array<WidgetRef>): { data: WidgetDetails; isLoading: boolean } => {
  const keys = useMemo(() => generateKey(widgets), [widgets]);
  const widgetRefs = useMemo(() => widgets.map(toBackendWidgetRef), [widgets]);
  const { data, isFetching } = useQuery({
    queryKey: keys,
    queryFn: () => fetchWidgetDetails(widgetRefs),
    keepPreviousData: true,
  });

  return { data, isLoading: isFetching };
};

export default useWidgetDetails;
