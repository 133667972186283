import * as React from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import { useGetUsers, useGetTeams } from 'security-app/hooks/useInvestigationsAPI';

export type Assignee = { label: string; type: 'user' | 'team' };
export type AssigneeListItem = Record<string, Assignee>;

const useUsersAndTeams = () => {
  const { users } = useGetUsers(true);
  const { teams } = useGetTeams(true);
  const { id: currentUserId, firstName, lastName } = useCurrentUser();
  const currentUserFullName = currentUserId.endsWith('admin') ? 'Administrator' : `${firstName} ${lastName}`;

  return React.useMemo(
    () => ({
      [currentUserId]: { label: currentUserFullName, type: 'user' } as Assignee,
      ...users.reduce<AssigneeListItem>(
        (acc, user) => ({ ...acc, [user.id]: { label: user.full_name, type: 'user' } }),
        {},
      ),
      ...teams.reduce<AssigneeListItem>((acc, team) => ({ ...acc, [team.id]: { label: team.name, type: 'team' } }), {}),
    }),
    [users, teams, currentUserId, currentUserFullName],
  );
};

export default useUsersAndTeams;
