import React from 'react';

import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const isCloud = AppConfig.isCloud();

const ArchivePageHeaderDescription = () => (
  <>
    <span>
      The Graylog archive feature allows you to create archives from indices. The generated archives are simple flat
      files that can be moved to cheap storage and re-imported at any time.{' '}
    </span>
    {isCloud ? (
      <span>Old indices are automatically archived with default index retention strategy settings.</span>
    ) : (
      <span>
        Archive your old indices automatically by setting your index retention strategy to archive on the{' '}
        <Link to={Routes.SYSTEM.INDICES.LIST}>indices</Link> page.
      </span>
    )}
  </>
);

export default ArchivePageHeaderDescription;
