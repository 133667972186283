import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedGraphApp from './EmbeddedGraphApp';

const GraphApp = () => (
  <>
    <PageHeader title="Microsoft Graph Input">
      <span>This input retrieves log records using Microsoft Graph API.</span>
    </PageHeader>
    <EmbeddedGraphApp />
  </>
);

export default GraphApp;
