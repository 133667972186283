import * as React from 'react';
import { useMemo } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';
import { defaultCompare } from 'logic/DefaultCompare';
import { useIsAllowedToFetchAssets } from 'security-app/hooks/useAssetsAPI';

type Props = {
  fieldName: string;
  assetLabels: Array<{ id: string; name: string }> | undefined;
};

const FieldContainer = styled.div`
  flex-basis: 100%;
`;

const AssetsFilterFieldValue = ({ fieldName, assetLabels }: Props) => {
  const isAllowedToFetchAssets = useIsAllowedToFetchAssets();

  const assetsOptions = useMemo(
    () =>
      assetLabels
        ?.sort((s1, s2) => defaultCompare(s1.name, s2.name))
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    [assetLabels],
  );

  return (
    <Field name={fieldName} required>
      {({ field: { name, value, onChange }, meta: { error } }) => (
        <FieldContainer>
          <Input error={error} name={name} formGroupClassName="" id={name}>
            <Select
              options={assetsOptions ?? []}
              value={Array.isArray(value) ? value?.join(',') : value}
              displayKey="key"
              multi
              inputId={name}
              onChange={(newVal) => {
                onChange({ target: { value: newVal?.split(','), name } });
              }}
              placeholder="Assets"
              allowCreate={!isAllowedToFetchAssets}
            />
          </Input>
        </FieldContainer>
      )}
    </Field>
  );
};

export default AssetsFilterFieldValue;
