import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

import { Spinner, Markdown } from 'components/common';
import { Input } from 'components/bootstrap';
import Modal from 'components/bootstrap/Modal';
import ModalSubmit from 'components/common/ModalSubmit';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import useAcceptanceState from 'security-app/components/Investigations/AIReport/hooks/useAcceptanceState';
import useAcceptanceMutation from 'security-app/components/Investigations/AIReport/hooks/useAcceptanceMutation';

type Props = {
  onClose: () => void;
  onConsent: () => void;
};

const DisclaimerModalContent = ({ onClose, onConsent }: Props) => {
  const {
    data: { accepted, terms },
    isLoading,
  } = useAcceptanceState();
  const acceptTerms = useAcceptanceMutation(onConsent);
  const [checked, setChecked] = useState(false);
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const onCancel = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.TERMS_AND_CONDITIONS_CANCELED, {
      app_pathname: getPathnameWithoutId(pathname),
    });

    onClose();
  };

  const _onConsent = useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.TERMS_AND_CONDITIONS_CONSENT, {
      app_pathname: getPathnameWithoutId(pathname),
    });

    acceptTerms(terms?.version);
  }, [sendTelemetry, pathname, acceptTerms, terms?.version]);

  useEffect(() => {
    if (accepted) {
      onConsent();
    }
  }, [accepted, onConsent]);

  return accepted === false && !isLoading ? (
    <>
      <Modal.Header>
        <h3>Terms and Conditions</h3>
      </Modal.Header>
      <Modal.Body>
        <Markdown text={terms?.text} />
        <Input
          type="checkbox"
          id="consent-AI-Report-disclamer"
          label="By using the AI reporting feature, you acknowledge that you have read, understood, and agreed to the terms outlined in this disclaimer."
          help={
            <span>
              Graylog Germany GmbH reserves the right to modify this disclaimer at any time. Any changes will be
              communicated to users through appropriate channels.
            </span>
          }
          checked={checked}
          onChange={onCheckboxChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <ModalSubmit submitButtonText="Next" onSubmit={_onConsent} onCancel={onCancel} disabledSubmit={!checked} />
      </Modal.Footer>
    </>
  ) : (
    <Spinner />
  );
};

export default DisclaimerModalContent;
