import styled from 'styled-components';

import { ProgressBar } from 'components/common';
import { Alert, Col, Row } from 'components/bootstrap';

export const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FlexRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: 0;

  div[role='progressbar'] {
    white-space: nowrap;
  }
`;

export const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
`;

export const FileListContainer = styled.div`
  height: 40vh;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.input.border};
  background-color: ${({ theme }) => theme.colors.global.background};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.input.placeholder};
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.colors.input.border};
  padding: 8px;
  flex-shrink: 0;
  flex-grow: 0;

  button {
    color: ${({ theme }) => theme.colors.variant.success};

    &:hover {
      background-color: ${({ theme }) => theme.colors.global.background};
    }
  }
`;

export const ListBody = styled.div<{ $isDragActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  padding: 8px 2px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
  border: 1px solid transparent;
  border-color: ${({ theme, $isDragActive }) => ($isDragActive ? theme.colors.variant.success : 'transparent')};
`;

export const FileListItem = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  color: ${({ $active, theme }) => ($active ? theme.colors.global.textDefault : theme.colors.input.placeholder)};
  cursor: pointer;
  min-width: 0;

  button {
    color: ${({ $active, theme }) => ($active ? theme.colors.variant.danger : 'gray')};
    flex-shrink: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.global.textDefault};

    button {
      color: ${({ theme }) => theme.colors.variant.danger};
      background-color: ${({ theme }) => theme.colors.global.background};
    }
  }
`;

export const DragMessage = styled.small`
  position: absolute;
  top: 40%;
  color: ${({ theme }) => theme.colors.input.placeholder};
  width: 100%;
  text-align: center;
  margin-left: -8px;
  line-height: 1.5;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const FileName = styled.span<{ $withError: boolean }>`
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  color: ${({ $withError, theme }) => ($withError ? theme.colors.variant.danger : 'inherit')};
`;

export const FileNameTitle = styled.h4`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0;
  overflow: auto;
`;
