import * as React from 'react';

import { Spinner } from 'components/common';
import { useSelectedRows } from 'common/contexts';
import { Col, Row, Collapsible, DataWell, Badge } from 'common/components';
import PackRecsAndDeps from 'illuminate/components/content-hub/PackRecsAndDeps';
import type { Pack } from 'illuminate/types';

import { useGetRequirements } from '../../utils';

function Title({ pack, requiredPacks, requiredByPacks }: { pack: Pack, requiredPacks: Array<Pack>, requiredByPacks: Array<Pack> }) {
  return (
    <Row $justify="space-between" $align="center">
      <span>{pack.title}</span>
      {requiredPacks.length > 0 && <Badge>dependencies</Badge>}
      {requiredByPacks.length > 0 && <Badge>requirements</Badge>}
    </Row>
  );
}

function PacksList() {
  const { fetchingAllPacks, getRequiredPacks, getRequiredByPacks } = useGetRequirements();
  const selectedPacks = useSelectedRows();

  const disableCollapsible = React.useCallback((pack: Pack) => (
    getRequiredPacks(pack).length < 1
    && getRequiredByPacks(pack).length < 1
    && pack.version_requirements.length < 1
  ), [getRequiredPacks, getRequiredByPacks]);

  if (fetchingAllPacks) return <Spinner text="Loading dependencies" />;

  return (
    <Col $gap={0}>
      {selectedPacks.map((pack: Pack) => (
        <Collapsible
          key={pack.pack_id}
          title={<Title pack={pack} requiredPacks={getRequiredPacks(pack)} requiredByPacks={getRequiredByPacks(pack)} />}
          disabled={disableCollapsible(pack)}
        >
          <DataWell>
            <PackRecsAndDeps
              requiredByPacks={getRequiredByPacks(pack)}
              dependsOnPacks={getRequiredPacks(pack)}
              requiredVersions={pack.version_requirements} />
          </DataWell>
        </Collapsible>
      ))}
    </Col>
  );
}

export default PacksList;
