import * as React from 'react';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { useEnableLatestPacks, useDisableLatestPacks, useGetPacksSummary } from 'illuminate/hooks/usePacksAPI';
import { useModalContext, useSelectedRows } from 'common/contexts';
import { ConfirmDialog } from 'common/components';
import type { Pack } from 'illuminate/types';

import PacksList from './packs-list';

import { buildEnableDisablePayload } from '../../utils';

const CONST_MAP = {
  enable: {
    data: 'creation',
    title: 'Install',
  },
  disable: {
    data: 'loss',
    title: 'Uninstall',
  },
};

function ConfEnableDisablePacks({ onCancel }: { onCancel: () => void }) {
  const { entity: actionType } = useModalContext();
  const selectedPacks = useSelectedRows();
  const { enablingPacks, enablePacks } = useEnableLatestPacks();
  const { disablingPacks, disablePacks } = useDisableLatestPacks();
  const sendTelemetry = useSendTelemetry();
  const { packsSummary: { cached_version, current_version } } = useGetPacksSummary();
  const updatingPacks = React.useMemo(() => enablingPacks || disablingPacks, [enablingPacks, disablingPacks]);

  React.useEffect(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE[`PACK_${actionType.toUpperCase()}_MODAL_TOGGLED`], {
      app_pathname: 'illuminate',
      app_section: 'packs',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: '',
      },
    });
  }, [actionType, sendTelemetry]);

  const handleEnablingPacks = async () => {
    const packsToEnable = selectedPacks.filter((pack: Pack) => !pack.enabled);
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.PACKS_BULK_UPDATED, {
      app_pathname: 'illuminate',
      app_section: 'packs',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: cached_version || current_version,
        illuminate_action: actionType,
        illuminate_packs: packsToEnable.map((pack: Pack) => ({ id: pack.pack_id, title: pack.title, version: pack.version })),
      },
    });

    const requestObject = buildEnableDisablePayload(packsToEnable);

    return enablePacks({ requestObject });
  };

  const handleDisablingPacks = async () => {
    const packsToDisable = selectedPacks.filter((pack: Pack) => pack.enabled);
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.PACKS_BULK_UPDATED, {
      app_pathname: 'illuminate',
      app_section: 'packs',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: current_version,
        illuminate_action: actionType,
        illuminate_packs: packsToDisable.map((pack: Pack) => ({ id: pack.pack_id, title: pack.title, version: pack.version })),
      },
    });

    const requestObject = buildEnableDisablePayload(packsToDisable);

    return disablePacks({ requestObject });
  };

  const onConfirm = async () => {
    if (actionType === 'enable') await handleEnablingPacks();
    if (actionType === 'disable') await handleDisablingPacks();
    onCancel();
  };

  return (
    <ConfirmDialog
      show
      onConfirm={onConfirm}
      confirmDisabled={updatingPacks}
      isLoading={updatingPacks}
      confirmText="Confirm"
      onCancel={onCancel}
      title={`${CONST_MAP[actionType].title} Illuminate Pack${selectedPacks.length > 1 ? 's' : ''}`}
    >
      <p>You are about to <b>{actionType}</b> the packs listed below</p>
      <p>This could cause potential {CONST_MAP[actionType].data} of data and will pause all processing for a few seconds.</p>
      <p>Do you wish to continue?</p>
      <PacksList />
    </ConfirmDialog>
  );
}

export default ConfEnableDisablePacks;

