import { useQuery } from '@tanstack/react-query';

import type { DataLake } from 'data-lake/Types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';

export const fetchStreamDataLake = (streamId: string): Promise<DataLake> =>
  fetch('GET', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.get_stream_data_lake(streamId).url));

const useStreamDataLake = (
  streamId: string,
): {
  streamDataLakeData: {
    data: DataLake;
    refetch: () => void;
    isFetching: boolean;
    error: Error;
  };
} => {
  const { data, refetch, isFetching, error } = useQuery<DataLake, Error>(
    ['data-lake', streamId],
    () => fetchStreamDataLake(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  return {
    streamDataLakeData: {
      data,
      refetch,
      isFetching,
      error,
    },
  };
};

export default useStreamDataLake;
