import * as React from 'react';
import { useState, useCallback } from 'react';
import { OrderedSet } from 'immutable';
import styled, { css } from 'styled-components';

import DescriptionBox from 'views/components/aggregationbuilder/DescriptionBox';
import Input from 'components/bootstrap/Input';
import StickyBottomActions from 'views/components/widgets/StickyBottomActions';
import SaveOrCancelButtons from 'views/components/widgets/SaveOrCancelButtons';
import FieldsConfiguration from 'views/components/widgets/FieldsConfiguration';
import FieldSelect from 'views/components/aggregationwizard/FieldSelect';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const Col = styled.div`
  height: 100%;
`;

const ControlsCol = styled(Col)`
  min-width: 315px;
  max-width: 500px;
  flex: 1.2;
  padding-right: 5px;
  margin-right: 10px;
`;

const LogViewCol = styled(Col)`
  flex: 3;
  overflow-y: hidden;
`;

const Warning = styled.div(
  ({ theme }) => css`
    padding: 5px;
    border: 1px solid ${theme.colors.variant.warning};
    border-radius: 3px;
    background: ${theme.colors.variant.lightest.warning};
    color: ${theme.utils.contrastingColor(theme.colors.variant.lightest.warning)};
  `,
);

type Props = {
  config: LogViewWidgetConfig;
  onChange: (newConfig: LogViewWidgetConfig) => void;
  children: React.ReactNode;
  onCancel: () => void;
};

const LogViewEditWidget = ({ config, onChange, children, onCancel }: Props) => {
  const [currentConfig, setCurrentConfig] = useState(config);

  const setTieBreaker = useCallback(
    (value) => {
      const tieBreaker = value && value.length > 0 ? value : null;
      const newConfig = currentConfig.toBuilder().tieBreaker(tieBreaker).build();
      setCurrentConfig(newConfig);
      onChange(newConfig);
    },
    [currentConfig, onChange],
  );
  const updateFields = useCallback(
    (fields) => {
      const newConfig = currentConfig.toBuilder().fields(OrderedSet(fields)).build();
      setCurrentConfig(newConfig);
      onChange(newConfig);
    },
    [currentConfig, onChange],
  );
  const changeSortDirection = useCallback(
    (direction) => {
      const newConfig = currentConfig.toBuilder().sort(direction).build();
      setCurrentConfig(newConfig);
      onChange(newConfig);
    },
    [currentConfig, onChange],
  );

  return (
    <Container>
      <ControlsCol>
        <StickyBottomActions actions={<SaveOrCancelButtons onCancel={onCancel} />} alignActionsAtBottom>
          <DescriptionBox description="Sorting direction by Timestamp">
            <Input
              type="radio"
              id="sort-asc"
              name="sort"
              value="asc"
              label="Ascending"
              onChange={() => changeSortDirection('ASC')}
              checked={currentConfig.sort === 'ASC'}
            />
            <Input
              type="radio"
              id="sort-desc"
              name="sort"
              value="desc"
              label="Descending"
              onChange={() => changeSortDirection('DESC')}
              checked={currentConfig.sort === 'DESC'}
            />
            <Input
              id="tie-breaker"
              name="tie-breaker"
              help="This field decides the sort order if multiple messages have the same timestamp."
              label="Tie breaker">
              <FieldSelect
                id="field-create-select"
                onChange={setTieBreaker}
                name="tie-breaker"
                excludedFields={['timestamp']}
                value={currentConfig.tieBreaker}
                menuPortalTarget={document.body}
                placeholder="Select a tie breaker"
                ariaLabel="Select a tie breaker"
              />
            </Input>
          </DescriptionBox>
          <DescriptionBox description="Field Selection and Order">
            <FieldsConfiguration
              onChange={updateFields}
              selectSize="normal"
              selectedFields={config.fields.toArray()}
              menuPortalTarget={document.body}
            />
            {currentConfig.fields.size <= 0 && <Warning>No fields selected</Warning>}
          </DescriptionBox>
        </StickyBottomActions>
      </ControlsCol>
      <LogViewCol>{children}</LogViewCol>
    </Container>
  );
};

export default LogViewEditWidget;
