import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';

import SchedulingFrequencyForm from 'report/report-configuration-page/SchedulingFrequencyForm';
import type { SchedulingFrequency } from 'report/types';
import { ListGroupItem, ButtonToolbar } from 'components/bootstrap';
import { IconButton, Icon } from 'components/common';
import FrequencySummary from 'report/report-configuration-page/FrequencySummary';

const Container = styled(ListGroupItem)`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 32px;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OverridesInfo = styled.div`
  padding: 5px 0;
`;
const StyledButtonToolbar = styled(ButtonToolbar)`
  white-space: nowrap;
`;

type Props = {
  onDelete: () => void;
  onUpdate: (frequency: SchedulingFrequency) => void;
  schedulingFrequency: SchedulingFrequency;
  disabled: boolean;
};

const SchedulingFrequenciesListItem = ({ schedulingFrequency, onDelete, onUpdate, disabled }: Props) => {
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const _onUpdate = (newFrequency: SchedulingFrequency) => {
    onUpdate(newFrequency);
    setShowUpdateForm(false);
  };

  return (
    <>
      <Container disabled={disabled}>
        <FlexContainer>
          <FrequencySummary
            frequency={schedulingFrequency.frequency}
            frequencyConfiguration={schedulingFrequency.frequency_configuration}
          />
          <StyledButtonToolbar>
            <IconButton disabled={disabled} title="Delete scheduling frequency" onClick={onDelete} name="delete" />
            <IconButton
              disabled={disabled}
              title="Edit scheduling frequency"
              onClick={() => setShowUpdateForm(true)}
              name="edit_square"
            />
          </StyledButtonToolbar>
        </FlexContainer>
        {schedulingFrequency?.frequency_configuration?.override_widgets_timerange && (
          <OverridesInfo>
            <Icon name="info" />
            <i> This scheduling overrides widgets time ranges with selected frequency</i>
          </OverridesInfo>
        )}
      </Container>
      {showUpdateForm && (
        <SchedulingFrequencyForm
          onSubmit={_onUpdate}
          onCancel={() => setShowUpdateForm(false)}
          title="Edit scheduling frequency"
          submitButtonText="Update frequency"
          submitLoadingText="Updating frequency..."
          schedulingFrequency={schedulingFrequency}
        />
      )}
    </>
  );
};

export default SchedulingFrequenciesListItem;
