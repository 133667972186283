import prepareOktaGroupSyncConfig from 'authentication/logic/okta/prepareOktaGroupSyncConfig';
import type { ConfigFormValues, OidcSubmitPayload } from 'authentication/components/oidc/config/types';
import type { LoadGroupsPayload } from 'authentication/actions/directoryServices/GroupSyncActions';

const prepareOktaLoadGroupsPayload = (
  prepareAuthBackendPayload: (values: ConfigFormValues) => OidcSubmitPayload,
  formValues: ConfigFormValues,
  backendId: string | null | undefined,
  serviceType: string,
): LoadGroupsPayload => {
  const teamSyncAuthBackendId = backendId ?? '<test>';
  const oktaConfig = prepareAuthBackendPayload(formValues);
  const groupSyncConfig = prepareOktaGroupSyncConfig(formValues, teamSyncAuthBackendId, serviceType);

  return {
    auth_service_backend_id: backendId,
    auth_service_backend_configuration: {
      ...oktaConfig,
    },
    team_sync_backend_configuration: groupSyncConfig,
  };
};

export default prepareOktaLoadGroupsPayload;
