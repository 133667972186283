import * as React from 'react';
import styled, { css } from 'styled-components';

import FieldTypeIcon from 'views/components/sidebar/fields/FieldTypeIcon';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import FieldType from 'views/logic/fieldtypes/FieldType';

const ValueContainer = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fonts.family.monospace};
    white-space: pre-wrap;
  `,
);

type HeadRowProps = {
  onSelectAll: () => void;
  allSelected: () => boolean;
  hideCheckbox: boolean;
};

export const LogViewMessageDetailsHeadRow = ({ onSelectAll, allSelected, hideCheckbox }: HeadRowProps) => (
  <tr>
    {!hideCheckbox && (
      <th>
        <input title="Select all" type="checkbox" onChange={onSelectAll} checked={allSelected()} />
      </th>
    )}
    <th>Type</th>
    <th>Field</th>
    <th>Value</th>
  </tr>
);

type Props = {
  field: string;
  hideCheckbox: boolean;
  onSelect: () => void;
  renderFieldName?: (field: string, fieldType: FieldType) => React.ReactElement;
  renderFieldValue?: (field: string, fieldType: FieldType, value: string) => React.ReactElement;
  selected: boolean;
  type: FieldTypeMapping;
  value: string;
};

const LogViewMessageDetailsRow = ({
  field,
  value,
  type,
  onSelect,
  selected,
  hideCheckbox,
  renderFieldName = (fieldName: string) => <>{fieldName}</>,
  renderFieldValue = (_fieldName: string, _fieldType: FieldType, fieldValue: string) => <>{fieldValue}</>,
}: Props) => {
  const fieldType = type?.type ?? FieldType.Unknown;

  return (
    <tr key={field}>
      {!hideCheckbox && (
        <td>
          <input
            title={`${selected ? 'Remove from' : 'Add to'} table`}
            onChange={onSelect}
            type="checkbox"
            checked={selected}
          />
        </td>
      )}
      <td>
        <FieldTypeIcon type={fieldType} />
      </td>
      <td>{renderFieldName(field, fieldType)}</td>
      <ValueContainer>{renderFieldValue(field, fieldType, value)}</ValueContainer>
    </tr>
  );
};

export default LogViewMessageDetailsRow;
