import * as React from 'react';

import { Button } from 'components/bootstrap';
import { SelectedRowsProvider } from 'common/contexts';
import { Modal } from 'common/components';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import { useListGitRepos } from 'security-app/hooks/useSigmaAPI';
import type { GitRepoAPIModel } from 'security-app/hooks/api/sigmaAPI.types';

import { Window } from './styledComponents';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import ImportErrorsAlert from './ImportErrorsAlert';

type ButtonsProps = {
  onCancel: () => void;
  importDisabled: boolean;
  setImportRules: (arg: boolean) => void;
};

const Buttons = ({ onCancel, importDisabled, setImportRules }: ButtonsProps) => (
  <Row $width="100%" $gap="0.5rem" $justify="flex-end" $align="center">
    <Button type="reset" form="ruleOptions" onClick={onCancel}>
      Cancel
    </Button>
    <Button bsStyle="success" disabled={importDisabled} onClick={() => setImportRules(true)}>
      Import rules
    </Button>
  </Row>
);

function GitRulesModal({ onClose }: { onClose: () => void }) {
  const [importRules, setImportRules] = React.useState<boolean>(false);
  const [importErrors, setImportErrors] = React.useState<string[]>([]);
  const [repo, setRepo] = React.useState<GitRepoAPIModel>(null);
  const { repos, loadingRepos } = useListGitRepos({ page: 1, perPage: 10000 });

  return (
    <Modal
      show
      onClose={onClose}
      title={
        <div>
          Viewing: <small>{repo?.name || ''}</small>
        </div>
      }
      maxWidth="1200px"
      buttons={
        <Buttons
          onCancel={onClose}
          importDisabled={importRules || importErrors.length > 0}
          setImportRules={setImportRules}
        />
      }>
      <Col $width="100%">
        <SelectedRowsProvider>
          <Row $width="100%" $align="stretch" $justify="stretch">
            <Window data-testid="navigator" $width={45}>
              <LeftPanel repo={repo} setRepo={setRepo} repos={repos} loadingRepos={loadingRepos} />
            </Window>
            <Window data-testid="selection" $width={65}>
              <RightPanel
                importRules={importRules}
                setImportRules={setImportRules}
                setImportErrors={setImportErrors}
                closeModal={onClose}
              />
            </Window>
          </Row>
        </SelectedRowsProvider>
        {importErrors.length > 0 && <ImportErrorsAlert importErrors={importErrors} setImportErrors={setImportErrors} />}
      </Col>
    </Modal>
  );
}

export default GitRulesModal;
