import type Parameter from 'views/logic/parameters/Parameter';
import type { CompleterContext } from 'views/components/searchbar/SearchBarAutocompletions';
import type { CompletionResult } from 'views/components/searchbar/queryinput/ace-types';

const _wrapParameter = (name: string) => `$${name}$`;

const _parameterResult = (parameter: Parameter, score: number = 1): CompletionResult => {
  const name = _wrapParameter(parameter.name);
  const description = parameter.description?.length > 0 ? ` - ${parameter.description}` : '';

  return {
    name,
    value: name,
    score,
    meta: `parameter${description}`,
  };
};

const ParameterCompletion = {
  getCompletions: ({ prefix, view }: Pick<CompleterContext, 'prefix' | 'view'>): Array<CompletionResult> => {
    const parameters = view?.search?.parameters;

    return parameters
      ?.filter(({ name }) => _wrapParameter(name).indexOf(prefix) >= 0)
      .map((parameter) => _parameterResult(parameter))
      .toArray();
  },
};

export default ParameterCompletion;
