import * as React from 'react';
import { useContext, useCallback } from 'react';
import * as Immutable from 'immutable';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { ListingWithCount, NoSearchResult, PaginatedDataTable } from 'components/common';
import { Alert, Input } from 'components/bootstrap';
import type { WizardSubmitPayload } from 'logic/authentication/directoryServices/types';
import ConnectionErrors from 'components/authentication/directoryServices/BackendWizard/ConnectionErrors';
import type { WizardFormValues } from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';

import LoadMatchingGroupsButton from './LoadMatchingGroupsButton';
import MatchingGroupsContext from './MatchingGroupsContext';

const TABLE_HEADER = ['Selected', 'Title', 'Name', 'Members'];

const ListWrapper = styled.div`
  position: relative;
`;

const TableRow = styled.tr<{ clickable: boolean }>(
  ({ clickable }) => `
  cursor: ${clickable ? 'pointer' : 'initial'};
`,
);

const Checkbox = styled(Input)`
  && {
    min-height: auto;
    padding-top: 0;
  }
`;

const _tableRow = (group, selectionType, selectedGroupsIds, onGroupSelect) => {
  const isSelected = selectionType === 'all' || selectedGroupsIds.includes(group.id);
  const clickable = selectionType !== 'all';

  return (
    <TableRow onClick={() => clickable && onGroupSelect(group.id)} clickable={clickable}>
      <td>
        <Checkbox
          type="checkbox"
          id={`select-group-${group.id}`}
          onChange={() => {}}
          checked={isSelected}
          disabled={selectionType === 'all'}
          formGroupClassName=""
        />
      </td>
      <td className="limited">{group.title}</td>
      <td className="limited">{group.name}</td>
      <td className="limited">
        <ListingWithCount count={group.membersTotal} listing={group.membersExcerpt.join(', ')} />
      </td>
    </TableRow>
  );
};

type Props = {
  onGroupSelect: any;
  prepareSubmitPayload: (fromValues: WizardFormValues | null | undefined) => WizardSubmitPayload;
  disabled: boolean;
};

const MatchingGroupsOverview = ({ prepareSubmitPayload, onGroupSelect, disabled }: Props) => {
  const { result: loadedGroupsResult, finishedLoading } = useContext(MatchingGroupsContext);
  const loadedGroups = loadedGroupsResult?.list || Immutable.List();
  const {
    values: { teamSelectionType, teamSelection },
  } = useFormikContext<WizardFormValues>();
  const headerCellFormatter = useCallback((header: React.ReactNode) => <th>{header}</th>, []);

  return (
    <Input id="matchingGroups" label="Matching Groups" labelClassName="col-sm-3" wrapperClassName="col-sm-9">
      <>
        <LoadMatchingGroupsButton prepareSubmitPayload={prepareSubmitPayload} disabled={disabled} />
        {finishedLoading && loadedGroupsResult && (
          <>
            <hr />
            {loadedGroupsResult.success && (
              <ListWrapper>
                {loadedGroupsResult?.list?.size >= 1 && (
                  <PaginatedDataTable
                    id="matching-groups-list"
                    className="table-hover"
                    headers={TABLE_HEADER}
                    headerCellFormatter={headerCellFormatter}
                    filterBy="title"
                    filterKeys={['title']}
                    noDataText={<NoSearchResult>Filter does not match any groups.</NoSearchResult>}
                    rows={loadedGroups.toJS()}
                    dataRowFormatter={(group) => _tableRow(group, teamSelectionType, teamSelection, onGroupSelect)}
                  />
                )}
              </ListWrapper>
            )}
            {loadedGroupsResult?.errors?.size === 0 && loadedGroupsResult?.list?.size === 0 && (
              <Alert bsStyle="warning">
                <b>{loadedGroupsResult.message}</b>
              </Alert>
            )}
            {loadedGroupsResult?.errors?.size >= 1 && (
              <ConnectionErrors errors={loadedGroupsResult.errors.toJS()} message={loadedGroupsResult.message} />
            )}
          </>
        )}
      </>
    </Input>
  );
};

export default MatchingGroupsOverview;
