import omit from 'lodash/omit';
import intersection from 'lodash/intersection';
import flattenDeep from 'lodash/flattenDeep';

import type Series from 'views/logic/aggregationbuilder/Series';
import type { Extension, Result } from 'search/ExportWidgetAction/util/WidgetExportUtils';
import {
  downloadNonPdfInvestigationWidget,
  exportWidget,
  exportPdfWidget,
} from 'search/ExportWidgetAction/util/WidgetExportUtils';
import type Widget from 'views/logic/widgets/Widget';
import AggregationWidget from 'views/logic/aggregationbuilder/AggregationWidget';
import type View from 'views/logic/views/View';
import type User from 'logic/users/User';
import type Query from 'views/logic/queries/Query';
import EventsWidget from 'views/logic/widgets/events/EventsWidget';
import startDownload from 'views/components/export/startDownload';
import ExportStrategy from 'views/components/export/ExportStrategy';
import type SearchExecutionState from 'views/logic/search/SearchExecutionState';
import InvestigationsWidget from 'security-app/components/Investigations/plugin/SearchWidgets/logic/InvestigationsWidget';
import MessagesWidget from 'views/logic/widgets/MessagesWidget';
import LogViewWidget from 'logview/logic/LogViewWidget';
import type { ExportPayload } from 'util/MessagesExportUtils';
import type { Orientation, Position } from 'search/ExportWidgetAction/types';

const MAXIMUM_GRID_SIZE = 12;

type DownloadByRenderPageParams = {
  widget: Widget;
  extension: Extension | string;
  view: View;
  widgetResult: Result;
  currentUser: User;
  currentQuery: Query;
  widgetTitle: string;
  executionState: SearchExecutionState;
  orientation?: Orientation;
};
const potentiallyLongFields = ['message'];

const getNonTableOrientation = (width: number): Orientation => {
  if (width > MAXIMUM_GRID_SIZE / 2) return 'landscape';

  return 'portrait';
};

const getTableOrientation = (fields: Array<string>, width: number): Orientation => {
  const hasLongValues = fields && intersection(fields, potentiallyLongFields).length > 0;

  if (fields && ((hasLongValues && fields.length > 2) || (!hasLongValues && fields.length > 9))) return 'landscape';

  return getNonTableOrientation(width);
};

const getWidgetPosition = (view: View, currentQuery: Query, widget: Widget): Position => {
  let widgetPosition = view.state.get(currentQuery.id).widgetPositions[widget.id];

  if (widgetPosition.width === Infinity) {
    widgetPosition = widgetPosition.toBuilder().width(MAXIMUM_GRID_SIZE).build();
  }

  return widgetPosition.toJSON() as Position;
};

const exportByRenderPage = ({
  widget,
  extension,
  view,
  widgetResult,
  currentUser,
  currentQuery,
  widgetTitle,
  executionState,
  orientation,
}: DownloadByRenderPageParams) => {
  const searchTypeId = view.widgetMapping?.get(widget.id).first();
  const searchId = view.search.id;
  const position = getWidgetPosition(view, currentQuery, widget);

  return exportPdfWidget(
    widgetTitle,
    omit(
      {
        ...widget.toJSON(),
        search_id: searchId,
        search_type_id: searchTypeId,
        creator_user_id: currentUser.id,
        query_id: currentQuery.id,
        widget_type: 'exportable_search_widget',
        parameter_values: executionState.parameterBindings,
        timezone: currentUser.timezone,
        orientation: orientation ?? getNonTableOrientation(position.width),
        position,
      },
      ['id'],
    ),
    widgetResult,
    extension as Extension,
    'search_type_data',
  );
};

const exportInvestigationsWidgetNonPdf = ({
  widget,
  extension,
  widgetTitle,
}: {
  widget: InvestigationsWidget;
  extension: Extension;
  widgetTitle: string;
}) => downloadNonPdfInvestigationWidget(widgetTitle, widget, extension);

const downloadByExportStrategy = ({
  widget,
  extension,
  view,
  widgetResult,
  currentUser,
  currentQuery,
  executionState,
  payload,
}: {
  widget: Widget;
  extension: Extension;
  view: View;
  widgetResult: Result;
  currentUser: User;
  currentQuery: Query;
  executionState: SearchExecutionState;
  payload?: ExportPayload;
}) => {
  const widgetTitle = view.getWidgetTitleByWidget(widget);

  const strategies = {
    [AggregationWidget.type]: () => {
      const isTable = widget?.config?.visualization === 'table';
      const series = widget.config.series.map((s: Series) => s.function);
      const allFields = flattenDeep([widget.config.rowPivots.map(({ fields }) => fields), series]);
      const { width } = getWidgetPosition(view, currentQuery, widget);
      const orientation = isTable ? getTableOrientation(allFields, width) : getNonTableOrientation(width);

      switch (extension) {
        case 'pdf':
          return {
            downloadFile: () =>
              exportByRenderPage({
                widget,
                extension,
                orientation,
                view,
                widgetResult: (widgetResult as unknown as { chart: Result })?.chart as Result,
                currentUser,
                currentQuery,
                widgetTitle,
                executionState,
              }),
          };
        default:
          return {
            downloadFile: () =>
              exportWidget(widgetTitle, (widgetResult as unknown as { chart: Result })?.chart as Result, extension),
          };
      }
    },
    [EventsWidget.type]: () => {
      switch (extension) {
        case 'pdf':
          return {
            downloadFile: () =>
              exportByRenderPage({
                widget,
                extension,
                view,
                widgetResult: null,
                currentUser,
                currentQuery,
                widgetTitle,
                executionState,
              }),
          };
        default:
          return {
            downloadFile: () => {
              const { downloadFile: downloadFn } = ExportStrategy.createExportStrategy(view.type);
              const selectedFields = widget.config.fields.toArray().map((field: string) => ({ field }));

              return startDownload(extension, downloadFn, view, executionState, widget, selectedFields, undefined, {});
            },
          };
      }
    },
    [InvestigationsWidget.type]: () => {
      const investigationWidget = widget as InvestigationsWidget;

      switch (extension) {
        case 'pdf':
          return {
            downloadFile: () =>
              exportByRenderPage({
                widget,
                extension,
                view,
                widgetResult: null,
                currentUser,
                currentQuery,
                widgetTitle,
                executionState,
              }),
          };
        default:
          return {
            downloadFile: () =>
              exportInvestigationsWidgetNonPdf({ widget: investigationWidget, widgetTitle, extension }),
          };
      }
    },
    [MessagesWidget.type]: () => {
      switch (extension) {
        case 'pdf':
          return {
            downloadFile: () => {
              const widgetConfig = widget.config.toBuilder().fields(payload.fields_in_order).build();
              const { width } = getWidgetPosition(view, currentQuery, widget);

              return exportByRenderPage({
                widget: widget.toBuilder().config(widgetConfig).build(),
                orientation: getTableOrientation(payload.fields_in_order, width),
                extension,
                view,
                widgetResult: null,
                currentUser,
                currentQuery,
                widgetTitle,
                executionState,
              });
            },
          };
        default:
          return null;
      }
    },
    [LogViewWidget.type]: () => {
      const widgetConfig = widget.config.toBuilder().fields(payload.fields_in_order).build();
      const { width } = getWidgetPosition(view, currentQuery, widget);

      switch (extension) {
        case 'pdf':
          return {
            downloadFile: () =>
              exportByRenderPage({
                widget: widget.toBuilder().config(widgetConfig).build(),
                orientation: getTableOrientation(payload.fields_in_order, width),
                extension,
                view,
                widgetResult: null,
                currentUser,
                currentQuery,
                widgetTitle,
                executionState,
              }),
          };
        default:
          return null;
      }
    },
  };

  const downLoadFn = strategies[widget.type]().downloadFile;

  return downLoadFn();
};

export default downloadByExportStrategy;
