import * as React from 'react';
import * as Immutable from 'immutable';
import type { $PropertyType } from 'utility-types';

import type Role from 'logic/roles/Role';
import type { Pagination } from 'stores/PaginationTypes';

import TeamsAssignment from '../teams-assignment/TeamsAssignment';
import type Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';

type Props = {
  role: Role;
  readOnly: boolean;
};

const RolesTeamsAssignment = ({ role, readOnly }: Props) => {
  const _getUnassignedTeams = (teams: Immutable.List<Team>) => teams.filter((t) => !t.roles.includes(role.id)).toList();
  const _loadAssignedTeams = (pagination: Pagination) => TeamsDomain.loadTeamsForRole(role.id, pagination);
  const _unassignTeam = (teamId: $PropertyType<Team, 'id'>) => TeamsDomain.removeRole(role.id, teamId);
  const _assignTeams = (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) =>
    TeamsDomain.addRoleToTeams(role.id, teamIds);
  const _buildTeamWithEntity = (team: Team) =>
    team
      .toBuilder()
      .roles(Immutable.Set([role.id]))
      .build();

  return (
    <TeamsAssignment
      getUnassignedTeams={_getUnassignedTeams}
      readOnly={readOnly}
      loadAssignedTeams={_loadAssignedTeams}
      unassignTeam={_unassignTeam}
      buildTeamWithEntity={_buildTeamWithEntity}
      assignTeams={_assignTeams}
    />
  );
};

export default RolesTeamsAssignment;
