import styled, { css } from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import type { DefaultTheme } from 'styled-components';

export const NavTabLink = styled(RouterNavLink)(
  ({ theme }: { theme: DefaultTheme }) => css`
    font-size: 1.4rem;
    transition: all 0.33s ease-in-out;
    padding: 0.3rem 1rem;
    border-bottom: 4px solid transparent;
    color: ${theme.colors.gray[30]};
    margin-bottom: -2px;

    &:hover,
    &:focus {
      text-decoration: none;
      color: ${theme.colors.global.link};
    }

    &.active {
      color: ${theme.colors.global.link};
      border-bottom: 3px solid ${theme.colors.global.link};
    }
  `,
);

export const SectionHeading = styled.h2`
  font-size: 2rem;
`;

export const NavRow = styled.div<{ $isSecurityPerspective?: boolean }>(
  ({ $isSecurityPerspective }) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${$isSecurityPerspective ? '0' : '0.5rem'};
  `,
);

const Header = styled.div<{ $isSecurityPerspective?: boolean }>(
  ({ $isSecurityPerspective }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    border-bottom: ${$isSecurityPerspective ? 'none' : `2px solid ${(props: any) => props.theme.colors.gray[80]}`};
  `,
);

export default Header;
