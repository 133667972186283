import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { OverlayTrigger, Spinner, Icon } from 'components/common';
import { useDetectionChainEventDefinitionSummary } from 'security-app/hooks/useSecurityEventsAPI';
import { Row, Col } from 'common/components/layout-styles';
import type { DetectionChainRule } from 'security-app/hooks/api/securityEventsAPI.types';

const StyledIcon = styled(Icon)<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) => $disabled ? theme.colors.text.disabled : theme.colors.text.primary};
`;

const Title = styled.span<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) => $disabled ? theme.colors.text.disabled : theme.colors.text.primary};
`;

const Note = styled.span`
  margin-top: ${({ theme }) => theme.spacings.sm};
  color: ${({ theme }) => theme.colors.text.disabled};
`;

type Props = {
  eventDefinitionId: string;
};

function DetectionChainDetails({ chainRules }: { chainRules: Array<DetectionChainRule> }) {
  return (
    <Col>
      {chainRules.map((rule: DetectionChainRule) => (
        <Row key={rule.id} $gap="sm" $align="center">
          <StyledIcon name={rule.seen ? 'task' : 'scan_delete'} size="sm" $disabled={!rule.seen} />
          <Title $disabled={!rule.seen}>{rule.title}</Title>
        </Row>
      ))}
      <Note><i>*Note: Grayed out rules have not been detected in your data</i></Note>
    </Col>
  );
}

function DetectionChain({ eventDefinitionId }: Props) {
  const { detectionChain, loadingDetectionChain } = useDetectionChainEventDefinitionSummary(eventDefinitionId);

  if (loadingDetectionChain) return <Spinner text="Loading Detection Chain details" />

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="right"
      overlay={<DetectionChainDetails chainRules={detectionChain.rules}/>}
      title="Detection Chain Rules"
      width={800}
    >
      <Row $gap="xxs" $align="center">
        <Button bsStyle="link">
          <strong>{detectionChain.title}</strong>
        </Button>
        <Icon name="arrow_right" size="sm" />
      </Row>
    </OverlayTrigger>
  );
}

export default DetectionChain;
