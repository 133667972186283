import { qualifyUrls } from 'routing/Routes';

const GenericS3Routes = {
  INTEGRATIONS: {
    GenericS3: {
      ACTIVITYAPI: {
        index: '/integrations/generics3',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    GenericS3: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/generics3/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/generics3/checkCredentials',
      GET_AWS_REGIONS: '/plugins/org.graylog.enterprise.integrations/generics3/getawsregions',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    GenericS3: {
      GRAYLOG_GenericS3_ACTIVITY_LOG_INPUT:
        'integrations/inputs/graylog_generic_s3_ActivityLog_Input.html#graylog-genericS3-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(GenericS3Routes),
  unqualified: GenericS3Routes,
};

export { DocsRoutes, ApiRoutes };
