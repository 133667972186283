import * as React from 'react';
import { Field } from 'formik';

import Input from 'components/bootstrap/Input';

const DescriptionFormGroup = () => (
  <Field name="description">
    {({ field: { value, onChange } }) => (
      <Input
        type="textarea"
        id="description"
        label="Description"
        required
        onChange={onChange}
        help="A description about the team"
        labelClassName="col-sm-3"
        wrapperClassName="col-sm-9"
        value={value ?? ''}
      />
    )}
  </Field>
);

export default DescriptionFormGroup;
