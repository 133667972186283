import * as React from 'react';
import styled, { css } from 'styled-components';

import type { ReportStatus } from 'report/types';
import { Label } from 'components/bootstrap';
import assertUnreachable from 'logic/assertUnreachable';

export const STATUS_LABELS = {
  GENERATED: 'Generated',
  SUCCESS: 'Succeeded',
  DELIVERY_FAILURE: 'Delivery failed',
  FAILURE: 'Failed',
  RETRYING_DELIVERY: 'Retrying delivery',
  RETRYING: 'Retrying',
  STARTING: 'Starting',
  DELIVERING: 'Delivering',
  RUNNING: 'Running',
};

const StatusLabel = styled(Label)(
  () => css`
    display: inline-flex;
    justify-content: center;
    gap: 4px;
  `,
);

const labelForStatus = (status: ReportStatus) => {
  if (status in STATUS_LABELS) {
    return STATUS_LABELS[status];
  }

  return assertUnreachable(status as never, 'Report status not handled');
};

const styleForStatus = (status: ReportStatus) => {
  switch (status) {
    case 'GENERATED':
      return 'primary';
    case 'SUCCESS':
      return 'success';
    case 'DELIVERY_FAILURE':
    case 'FAILURE':
      return 'danger';
    case 'RETRYING_DELIVERY':
    case 'RETRYING':
      return 'warning';
    case 'STARTING':
    case 'DELIVERING':
    case 'RUNNING':
      return 'info';
    default:
      return assertUnreachable(status, 'Report status not handled');
  }
};

type Props = {
  value: ReportStatus;
};

const StatusCell = ({ value }: Props) => {
  const bsStyle = styleForStatus(value);

  return (
    <StatusLabel value={value} bsStyle={bsStyle}>
      {labelForStatus(value)}
    </StatusLabel>
  );
};

export default StatusCell;
