import * as React from 'react';
import { useState, useCallback } from 'react';
import * as Immutable from 'immutable';
import styled from 'styled-components';

import PaginatedItemOverview, { DEFAULT_PAGINATION } from 'components/common/PaginatedItemOverview';
import SectionComponent from 'components/common/Section/SectionComponent';
import RolesSelector from 'components/permissions/RolesSelector';
import RolesQueryHelp from 'components/users/RolesQueryHelp';
import type { DescriptiveItem } from 'components/common/PaginatedItemOverview';
import type { PaginatedRoles } from 'actions/roles/AuthzRolesActions';

import TeamsDomain from '../../domainActions/TeamsDomain';
import type Team from '../../logic/Team';

const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const _updateTeam = (team, { roles }) => {
  const newTeam = team.toBuilder().roles(Immutable.Set(roles)).build();

  return TeamsDomain.update(newTeam);
};

type Props = {
  team: Team;
};

const RolesSection = ({ team }: Props) => {
  const [loading, setLoading] = useState(false);
  const [paginatedRoles, setPaginatedRoles] = useState<PaginatedRoles | undefined>();

  const _onLoad = useCallback(
    (pagination = DEFAULT_PAGINATION) => {
      setLoading(true);

      return TeamsDomain.loadRolesForTeam(team.id, team.name, pagination).then((newPaginatedRoles) => {
        setLoading(false);

        return newPaginatedRoles;
      });
    },
    [team.id, team.name],
  );

  const onRolesUpdate = (data: { roles: Array<string> }) =>
    _updateTeam(team, data).then(() => _onLoad().then(setPaginatedRoles));

  const _onAssignRole = (newRoles: Immutable.Set<DescriptiveItem>) => {
    const teamRoles = team.roles;
    const newRoleIds = teamRoles.union(newRoles.map((r) => r.id)).toJS();

    return onRolesUpdate({ roles: newRoleIds });
  };

  const onDeleteRole = (role: DescriptiveItem) => {
    const newUserRoles = team.roles.remove(role.id).toJS();

    // eslint-disable-next-line no-alert
    if (window.confirm(`Do you really want to remove the role "${role.name}" from this team?`)) {
      onRolesUpdate({ roles: newUserRoles });
    }
  };

  return (
    <SectionComponent title="Roles" showLoading={loading}>
      <h3>Assign Roles</h3>
      <Container>
        <RolesSelector onSubmit={_onAssignRole} assignedRolesIds={team.roles} />
      </Container>
      <h3>Selected Roles</h3>
      <Container>
        <PaginatedItemOverview
          noDataText="No selected roles have been found."
          onLoad={_onLoad}
          overrideList={paginatedRoles}
          onDeleteItem={onDeleteRole}
          queryHelper={<RolesQueryHelp />}
        />
      </Container>
    </SectionComponent>
  );
};

export default RolesSection;
