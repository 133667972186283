import * as React from 'react';

import useScopePermissions from 'hooks/useScopePermissions';
import type { GenericEntityType } from 'logic/lookup-tables/types';

import GLCheckbox from './GLCheckbox';

type CheckboxWithPermissionsProps = {
  entity: Partial<GenericEntityType>,
  isSelected: (entity: Partial<GenericEntityType>) => boolean,
  onSelect: (entity: Partial<GenericEntityType>) => (e: React.BaseSyntheticEvent) => void,
  hideCheckbox?: boolean,
};

function GLCheckboxWithScope({ entity, hideCheckbox = false, isSelected, onSelect }: CheckboxWithPermissionsProps) {
  const { scopePermissions } = useScopePermissions(entity);
  const isMutable = React.useMemo(() => scopePermissions?.is_mutable, [scopePermissions]);

  if (hideCheckbox || !isMutable) return null;

  return <GLCheckbox checked={isSelected(entity)} onChange={onSelect(entity)} data-testid="rule-checkbox" />;
}

export default GLCheckboxWithScope;
