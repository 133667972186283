import * as React from 'react';
import { PluginStore } from 'graylog-web-plugin/plugin';
import unionWith from 'lodash/unionWith';
import xorWith from 'lodash/xorWith';

import type { AvailableWidgetPreview as AvailableWidgetPreviewType, BackendReportWidget, Report } from 'report/types';
import type { AvailableDashboard } from 'report/report-contents-page/useAvailableWidgets';
import ReportingWidgetContainer from 'report/common/ReportingWidgetContainer';
import AvailableWidgetPreview from 'report/common/AvailableWidgetPreview';
import ContentSelectionSubheader from 'report/report-contents-page/ContentSelectionSubheader';
import { ExpandableListItem } from 'components/common';
import Routes from 'routing/Routes';
import Alert from 'components/bootstrap/Alert';
import { getValueFromInput } from 'util/FormsUtils';

import type { AvailableWidgetUpdate } from './AvailableDashboardsList';

type Props = {
  reportWidgets: Report['widgets'];
  dashboard: AvailableDashboard;
  widgets: Array<AvailableWidgetPreviewType>;
  queryId: string;
  hideWidgetQuery: boolean;
  hideWidgetDescription: boolean;
  updateWidgets: (
    updatedWidgets: Array<AvailableWidgetUpdate>,
    mergeFn: (
      reportWidgets: Array<BackendReportWidget>,
      updatedWidgets: Array<AvailableWidgetUpdate>,
      compareFn: (
        w1: AvailableWidgetUpdate | BackendReportWidget,
        w2: AvailableWidgetUpdate | BackendReportWidget,
      ) => boolean,
    ) => Array<BackendReportWidget>,
  ) => void;
};

const AvailableWidgetsList = ({
  widgets,
  reportWidgets,
  dashboard,
  queryId,
  updateWidgets,
  hideWidgetQuery,
  hideWidgetDescription,
}: Props) => {
  const isWidgetInReport = (dashboardId, widgetId) =>
    reportWidgets.some((w) => w.dashboard_id === dashboardId && w.dashboard_widget_id === widgetId);

  const updateWidgetSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const widgetId = event.target.value;
    const isWidgetChecked = getValueFromInput(event.target);

    const eligibleWidgets = Object.values(dashboard.widgets)
      .flat()
      .filter((widget) => widget.eligible);
    const widgetsToUpdate = eligibleWidgets.filter((w) => w.id === widgetId);
    const updatedWidgets = widgetsToUpdate.map((originalWidget) => ({
      ...originalWidget,
      dashboard_id: dashboard.id,
      dashboard_widget_id: originalWidget.id,
    }));

    updateWidgets(updatedWidgets, isWidgetChecked ? unionWith : xorWith);
  };

  return (
    <>
      {widgets.map((widget) => {
        const widgetType = PluginStore.exports('enterpriseWidgets').find(
          (w) => w.type.toUpperCase() === (widget.type ? widget.type.toUpperCase() : 'unknown'),
        );
        const formattedWidget = widgetType ? (
          <ReportingWidgetContainer>
            {({ height, width }) => (
              <AvailableWidgetPreview
                dashboardId={dashboard.id}
                widgetId={widget.id}
                widget={widget}
                searchId={dashboard.search_id}
                showHeading={false}
                showCaption={false}
                showHandle={false}
                interactive={false}
                limitHeight={false}
                height={height}
                width={width}
                hideQuery={hideWidgetQuery}
                hideDescription={hideWidgetDescription}
              />
            )}
          </ReportingWidgetContainer>
        ) : (
          <Alert bsStyle="warning" onDismiss={() => {}}>
            Could not find widget type <em>{widget.type}</em>. Please ensure the plugin is loaded.
          </Alert>
        );

        const header = widget.eligible ? (
          widget.title
        ) : (
          <span>
            {widget.title} <i>(This widget is not eligible for reporting, due to an unsupported type.)</i>
          </span>
        );
        const subheader = (
          <ContentSelectionSubheader
            link={`${Routes.dashboard_show(dashboard.id)}?page=${queryId}&focusedId=${widget.id}&editing=true`}
            entityName="widget"
            editPermissions={[`view:edit:${dashboard.id}`, 'view:edit']}
          />
        );

        return (
          <ExpandableListItem
            key={widget.id}
            header={header}
            name={dashboard.id}
            value={widget.id}
            subheader={subheader}
            expandable={widget.eligible}
            disabled={!widget.eligible}
            checked={isWidgetInReport(dashboard.id, widget.id)}
            onChange={updateWidgetSelection}>
            {formattedWidget}
          </ExpandableListItem>
        );
      })}
    </>
  );
};

export default AvailableWidgetsList;
