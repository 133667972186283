import Reflux from 'reflux';
import get from 'lodash/get';

import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { LICENSE_SUBJECTS } from 'license/constants';

export type License = {
  missing: boolean;
  loading: boolean;
  status: {
    valid: boolean;
    expired: boolean;
    violated: boolean;
  };
};

type ActionsType = {
  status: () => Promise<License>;
};

const ViewsLicenseActions = Reflux.createActions<ActionsType>({ status: { asyncResult: true } });
const ViewsLicenseStore = Reflux.createStore<License>({
  listenables: [ViewsLicenseActions],

  getInitialState() {
    return this._status;
  },

  init() {
    this._status = {
      status: undefined,
      missing: true,
      loading: true,
    };

    this.status();
  },

  status() {
    const promise = fetch(
      'GET',
      URLUtils.qualifyUrl(EnterpriseApiRoutes.License.validityForSubject(LICENSE_SUBJECTS.views).url),
    ).then(
      (response) => {
        this._status = { status: response, missing: false, loading: false };

        return this._status;
      },
      (error) => {
        const status = get(error, 'additional.status');

        if (status === 404) {
          this._status = { status: undefined, missing: true, loading: false };
        } else {
          const errorMessage = get(error, 'additional.body.message') || get(error, 'message');

          this._status = { status: undefined, missing: true, loading: false, error: errorMessage };
        }

        return this._status;
      },
    );

    promise.then((result) => this.trigger(result));

    ViewsLicenseActions.status.promise(promise);

    return promise;
  },
});

export { ViewsLicenseStore, ViewsLicenseActions };
