import * as React from 'react';
import { useField } from 'formik';

import { MarkdownEditor, MarkdownPreview } from 'components/common/MarkdownEditor';
import { ControlLabel } from 'components/bootstrap';
import { Col } from 'security-app/components/common/FormBaseStyles';

import { ErrorMessage } from './ErrorMessage';

type Props = {
  field: any;
  height?: number;
  label?: string;
  disabled?: boolean;
};

function FormikMarkdownEditor({ field, height = 150, label = null, disabled = false }: Props) {
  const [{ name, value }, { touched, error }, { setValue, setTouched }] = useField({ name: field.name });

  const onChange = React.useCallback(
    (newValue: string) => {
      setValue(newValue);
      setTouched(true);
    },
    [setValue, setTouched],
  );

  return (
    <Col $fullWidth $width="100%" $gap="0.3rem" $align="stretch">
      {label && <ControlLabel>{label}</ControlLabel>}
      {disabled ? (
        <MarkdownPreview show withFullView height={height} value={value} />
      ) : (
        <>
          <MarkdownEditor id={name} height={height} value={value} onChange={onChange} />
          {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Col>
  );
}

export default FormikMarkdownEditor;
