import React, { useContext, useState, useEffect } from 'react';

import Select from 'components/common/Select';
import { Input } from 'components/bootstrap';

import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';
import INITIAL_FORMDATA from './_initialFormData';

import FormWrap from '../common/FormWrap';

type StepSubscribeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepSubscribe = ({ onSubmit, onChange }: StepSubscribeProps) => {
  const { formData } = useContext(FormDataContext);
  const [formError] = useState(null);

  const { pollingInterval } = formData;

  const availableLog = [
    { label: 'Admin', value: 'ADMIN_ACTIVITY_LOGS' },
    { label: 'Drive', value: 'DRIVE_ACTIVITY_LOGS' },
    { label: 'Authorization Token', value: 'AUTHORIZATION_TOKEN_LOGS' },
    { label: 'Message Tracking', value: 'MESSAGE_TRACKING_LOGS' },
    { label: 'Calendar', value: 'CALENDAR_ACTIVITY_LOGS' },
    { label: 'Login', value: 'LOGIN_ACTIVITY_LOGS' },
  ];

  const [logTypes, setLogTypes] = useState(INITIAL_FORMDATA.availableLogs.value);
  const [logTypeVal, setLogTypeVal] = useState(false);

  const handleSubmit = () => {
    if (logTypes.length === 0) {
      setLogTypeVal(true);

      return;
    }

    formData.logs.value = logTypes;
    onSubmit();
  };

  useEffect(() => {
    console.log(INITIAL_FORMDATA.availableLogs.value);
  }, []);

  return (
    <FormWrap onSubmit={handleSubmit} buttonContent="Proceed" title="" error={formError} description="">
      <label className="control-label" htmlFor="logs">
        Logs Types to Collect
      </label>
      <Select
        multi
        name="logs"
        id="logs"
        onChange={(selected) => {
          setLogTypes(selected);
          console.log(selected);
        }}
        options={availableLog}
        value={logTypes}
        matchProp="label"
      />
      {logTypeVal && logTypes.length === 0 && <label>Select atleast one</label>}
      <span className="help-block">The activity log for the above log types will be fetched.</span>

      <Input
        id="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue}
        min="1"
        max="60"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in google workspace. The smallest allowable interval is 5 minutes."
        label="Polling Interval"
      />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
