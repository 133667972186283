import { useMemo } from 'react';

import useUserDateTime from 'hooks/useUserDateTime';
import { useGetSigmaRules } from 'security-app/hooks/useSigmaAPI';

import type { CoverageEntity } from '../types';

export const sigmaRulesQuery = (coverageEntity?: CoverageEntity) =>
  `tags:${coverageEntity ? [coverageEntity.id, ...(coverageEntity.childrenIds ?? [])].join() : 'attack'}`;

const useThreatRelatedSigmaRules = (coverageEntity?: CoverageEntity) => {
  const { userTimezone } = useUserDateTime();
  const { rules = [], loadingRules } = useGetSigmaRules(
    { page: 1, perPage: 100000, query: sigmaRulesQuery(coverageEntity) },
    userTimezone,
    true
  );
  const notEnabledRules = useMemo(() => rules.filter(({ enabled }) => !enabled), [rules]);

  return {
    allRules: rules,
    loadingRules,
    notEnabledRules,
  };
};

export default useThreatRelatedSigmaRules;
