import * as React from 'react';
import styled from 'styled-components';
import Immutable from 'immutable';
import { useCallback, useContext, useMemo } from 'react';

import FieldSelect from 'data-lake/components/FieldSelect';
import StickyBottomActions from 'views/components/widgets/StickyBottomActions';
import SaveOrCancelButtons from 'views/components/widgets/SaveOrCancelButtons';
import DescriptionBox from 'views/components/aggregationbuilder/DescriptionBox';
import FieldsConfiguration from 'views/components/widgets/FieldsConfiguration';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import { Alert, Button } from 'components/bootstrap';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import { WAHREHOUSE_PREVIEW_FORM_ID } from 'data-lake/preview/SearchBar';
import { Icon } from 'components/common';
import type DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';

import DataLakeFieldsContext from './DataLakeFieldsContext';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const Col = styled.div`
  height: 100%;
`;

const ControlsCol = styled(Col)`
  min-width: 315px;
  max-width: 500px;
  flex: 1.2;
  padding-right: 5px;
  margin-right: 10px;
`;

const LogViewCol = styled(Col)`
  flex: 3;
  overflow-y: hidden;
`;

// eslint-disable-next-line react/require-default-props
const LogViewEditFieldSelect = (props: React.ComponentProps<typeof FieldSelect>) => (
  <FieldSelect {...props} allowCreate />
);

const hasCustomFields = (widgetFields: Immutable.OrderedSet<string>, defaultFields: FieldTypeMappingsList) =>
  widgetFields
    .subtract(['message', 'timestamp'])
    .filter((field) => !defaultFields.find((warehouseField) => warehouseField.name === field)).size >= 1;

type Props = React.PropsWithChildren<{
  config: DataLakeWidgetConfig;
  onCancel: () => void;
  onChange: (newConfig: DataLakeWidgetConfig) => void;
  isFetching: boolean;
}>;

const DataLakeLogViewEdit = ({ children = undefined, config, onChange, onCancel, isFetching }: Props) => {
  const sendTelemetry = useSendTelemetry();
  const { all: defaultFields } = useContext(FieldTypesContext);

  const onChangeFields = useCallback(
    (newFields: Array<string>) => {
      const newFieldsSet = Immutable.OrderedSet(newFields);

      sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.WIDGET_FIELDS_UPDATE, {
        app_pathname: '/data-storage/preview',
        app_section: 'log-view-widget',
        event_details: { fields: newFields, hasCustomFields: hasCustomFields(newFieldsSet, defaultFields) },
      });

      onChange(config.toBuilder().fields(Immutable.OrderedSet(newFields)).build());
    },
    [config, defaultFields, onChange, sendTelemetry],
  );

  const widgetHasCustomFields = useMemo(
    () => hasCustomFields(config.fields, defaultFields),
    [config.fields, defaultFields],
  );

  const onReexecuteSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (
        // eslint-disable-next-line no-alert
        !window.confirm('Re-executing the search job may take some time. Do you want to proceed?')
      ) {
        e.preventDefault();
      } else {
        sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.WIDGET_REEXECUTE_SEARCH, {
          app_pathname: '/data-storage/preview',
          app_section: 'log-view-widget',
        });
      }
    },
    [sendTelemetry],
  );

  return (
    <DataLakeFieldsContext.Provider value={Immutable.List<FieldTypeMapping>(config.fields)}>
      <Container>
        <ControlsCol>
          <StickyBottomActions actions={<SaveOrCancelButtons onCancel={onCancel} />} alignActionsAtBottom>
            <DescriptionBox description="Field Selection and Order">
              <FieldsConfiguration
                onChange={onChangeFields}
                fieldSelect={LogViewEditFieldSelect}
                createSelectPlaceholder="Select or type in any field name"
                selectSize="normal"
                selectedFields={config.fields.toArray()}
                menuPortalTarget={document.body}
              />
            </DescriptionBox>
            {widgetHasCustomFields && (
              <Alert bsStyle="warning">
                <p>
                  Newly added custom fields will appear in the log viewer with empty values. To populate these values,
                  you need to re-execute the Data Preview query.
                </p>
                <Button
                  type="submit"
                  bsSize="xsmall"
                  bsStyle="success"
                  disabled={isFetching}
                  onClick={onReexecuteSearch}
                  form={WAHREHOUSE_PREVIEW_FORM_ID}>
                  <Icon name="search" />
                  Re-execute search
                </Button>
              </Alert>
            )}
            <Alert bsStyle="info">
              The field select contains options for fields which exist as columns in the data lake. It is possible to
              type in and add any custom field name, by clicking on the <i>Create &quot;custom_field&quot;</i> option.
              <br />
            </Alert>
          </StickyBottomActions>
        </ControlsCol>
        <LogViewCol>{children}</LogViewCol>
      </Container>
    </DataLakeFieldsContext.Provider>
  );
};

export default DataLakeLogViewEdit;
