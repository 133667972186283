import * as React from 'react';
import { useCallback } from 'react';

import { SecurityAppSecurityEventsHistogram } from '@graylog/enterprise-api';

import type { MiddleSectionProps } from 'components/common/PaginatedEntityTable/PaginatedEntityTable';
import EventsHistogram from 'components/events/EventsHistogram';
import useUserDateTime from 'hooks/useUserDateTime';
import type { SearchParams } from 'stores/PaginationTypes';
import { defaultTimeRange } from 'components/events/fetchEvents';
import { parseSearchParams } from 'security-app/hooks/api/securityEventsAPI';
import parseSecurityEventFilters from 'security-app/components/SecurityEvents/Alerts/parseSecurityEventFilters';

const fetchSecurityEventsHistogram = async (searchParams: SearchParams, timezone: string) => {
  const parsedFilters = parseSecurityEventFilters(searchParams.filters, defaultTimeRange);
  const { timerange } = parsedFilters;

  return SecurityAppSecurityEventsHistogram.histogram(parseSearchParams(searchParams, timezone)).then((results) => ({
    timerange,
    results,
  }));
};

const SecurityEventsHistogram = ({ searchParams, setFilters }: MiddleSectionProps) => {
  const { userTimezone } = useUserDateTime();

  const _fetchSecurityEventsHistogram = useCallback(
    (_searchParams: SearchParams) => fetchSecurityEventsHistogram(_searchParams, userTimezone),
    [userTimezone],
  );

  return (
    <EventsHistogram
      searchParams={searchParams}
      setFilters={setFilters}
      eventsHistogramFetcher={_fetchSecurityEventsHistogram}
    />
  );
};

export default SecurityEventsHistogram;
