import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { ConfirmDialog } from 'security-app/components/common/Modal';
import { PaginationProvider } from 'security-app/components/common/contexts';

import List from './List';

type Props = {
  confirmDisabled: boolean;
  closeModal: () => void;
  onConfirm: (notificationId: string) => void;
  show?: boolean;
};

const SendNotificationModal = ({ onConfirm, closeModal, confirmDisabled, show = true }: Props) => {
  const [notificationId, setNotificationId] = React.useState<string>('');

  useEffect(() => {
    setNotificationId('');
  }, [show]);

  const _onConfirm = useCallback(() => onConfirm(notificationId), [notificationId, onConfirm]);

  return (
    <ConfirmDialog
      show={show}
      onCancel={closeModal}
      onConfirm={_onConfirm}
      confirmDisabled={confirmDisabled}
      title="Send Notification">
      <PaginationProvider>
        <List notificationId={notificationId} setNotificationId={setNotificationId} />
      </PaginationProvider>
    </ConfirmDialog>
  );
};

export default SendNotificationModal;
