import { v4 as uuidv4 } from 'uuid';

import { useValidateSigmaRule, useValidateSigmaRuleZip } from 'security-app/hooks/useSigmaAPI';
import { formOptionsToPayload } from 'security-app/components/SigmaRules/RuleOptionsForm/utils';
import type {SigmaRuleZipValidationAPIResponse} from 'security-app/hooks/api/sigmaAPI.types';
import type {ValidationResponse} from 'security-app/hooks/api/sigmaAPI';

import type { FileFormType, BodyPayload } from './types';

const getFileFormId = () => {
  const id = uuidv4().split('-');

  return id[id.length - 1];
};

export const getFilesWithOptions = (files: File[]): FileFormType[] =>
  files.map((file: File) => ({
    id: getFileFormId(),
    file,
    ruleValidation: file.type === 'application/zip' ? {results: [{
      file_name: file.name,
      errors: [],
      is_temporal: false,
    }]} : {errors: [], is_temporal: false},
    search_within: 5,
    search_within_unit: 'MINUTES',
    execute_every: 5,
    execute_every_unit: 'MINUTES',
    use_cron_scheduling: false,
    cron_expression: '',
    cron_timezone: '',
    streams: [],
    stream_categories: [],
    notifications: [],
    filters: [],
    remediation_steps: '',
  }));

export const ACCEPTED_FILE_TYPES = {
  'application/yaml': ['.yml', '.yaml'],
  'application/zip': ['.zip'],
};

export const getBodyPayload = (fileForms: FileFormType[]): BodyPayload =>
  fileForms.reduce(
    (acc: BodyPayload, file: FileFormType) => ({ ...acc, [file.id]: formOptionsToPayload(file) }),
    {} as BodyPayload,
  );

export function useRuleSchemaValidation() {
  const { validateSigmaRule } = useValidateSigmaRule();
  const { validateSigmaRuleZip } = useValidateSigmaRuleZip();

  const validateRule = async (inFileForm: FileFormType): Promise<FileFormType> => {
    const ruleSchema = await new Promise((resolve: (rawRule: string) => void) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsText(inFileForm.file);
    });

    const validationResponse = await validateSigmaRule(ruleSchema);

    return Promise.resolve({ ...inFileForm, ruleValidation: validationResponse });
  };

  const validateZip = async (inFileForm: FileFormType): Promise<FileFormType> => {
    const validationResponse = await validateSigmaRuleZip(inFileForm.file);

    return Promise.resolve({ ...inFileForm, ruleValidation: validationResponse });
  };

  const validateRuleSchemas = async (inFiles: Array<FileFormType>) =>
    Promise.all([
      ...inFiles.filter((file: FileFormType) => file.file.type !== 'application/zip').map(validateRule),
      ...inFiles.filter((file: FileFormType) => file.file.type === 'application/zip').map(validateZip),
    ]);

  return { validateRuleSchemas };
}

export const hasError = (file: FileFormType): boolean => {
  if (!file.ruleValidation) {
    return false;
  }

  if (typeof file.ruleValidation === 'string') {
    return true;
  }

  if (file.file.type === 'application/zip') {
    const zipResponse = file.ruleValidation as SigmaRuleZipValidationAPIResponse;

    return zipResponse.results.filter(result => result.errors.length > 0).length > 0;
  }

  return (file.ruleValidation as ValidationResponse).errors.length > 0;
}
