import Reflux from 'reflux';

import fetch from 'logic/rest/FetchProvider';
import URLUtils from 'util/URLUtils';
import type { RefluxActions } from 'stores/StoreTypes';

import type { MongoDBDataAdapterEntryJSON } from '../models/MongoDBDataAdapterEntry';
import MongoDBDataAdapterEntry from '../models/MongoDBDataAdapterEntry';

export type StatsData = {
  entriesCount: number;
};

export type MongoDBDataAdapterState = {
  data: Array<MongoDBDataAdapterEntry>;
  statsData: StatsData;
};

type MongoDataAdapterStoreUnsubscribe = () => void;

type MongoDBDataAdapterStoreType = {
  listen: (cb: (state: MongoDBDataAdapterState) => void) => MongoDataAdapterStoreUnsubscribe;
  getInitialState: () => MongoDBDataAdapterState;
};

type DataAdapterId = string;

type PaginatedResponse = {
  total: number;
  page: number;
  per_page: number;
  count: number;
  entries: Array<MongoDBDataAdapterEntryJSON>;
  grand_total: number;
};

export type PaginatedEntries = {
  pagination: {
    total: number;
    page: number;
    perPage: number;
    count: number;
  };
  entries: Array<MongoDBDataAdapterEntry>;
};

type MongoDBDataAdapterActionsType = RefluxActions<{
  create: (entry: MongoDBDataAdapterEntry) => Promise<MongoDBDataAdapterState>;
  update: (entry: MongoDBDataAdapterEntry) => Promise<MongoDBDataAdapterState>;
  delete: (entry: MongoDBDataAdapterEntry) => Promise<MongoDBDataAdapterState>;
  search: (
    dataAdapterId: DataAdapterId,
    query: string,
    page: number,
    perPage: number,
  ) => Promise<MongoDBDataAdapterState>;
  stats: (adapterId: string) => Promise<any>;
}>;

export const MongoDBDataAdapterActions: MongoDBDataAdapterActionsType = Reflux.createActions({
  create: { asyncResult: true },
  update: { asyncResult: true },
  delete: { asyncResult: true },
  search: { asyncResult: true },
  stats: { asyncResult: true },
});

const URL = '/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/';

export const MongoDBDataAdapterStore: MongoDBDataAdapterStoreType = Reflux.createStore({
  listenables: [MongoDBDataAdapterActions],
  data: {
    entries: [],
    pagination: {
      total: 0,
      count: 0,
      page: 0,
      perPage: 0,
    },
  },
  statsData: null,

  adapterUrl(path) {
    const effectivePath = path ? `${URL}${path}` : URL;

    return URLUtils.qualifyUrl(effectivePath);
  },

  getInitialState(): MongoDBDataAdapterState {
    return { data: this.data, statsData: this.statsData };
  },

  create(entry: MongoDBDataAdapterEntry) {
    const promise = fetch('POST', this.adapterUrl(entry.dataAdapterId), entry.toJSON());
    MongoDBDataAdapterActions.create.promise(promise);
  },

  update(entry: MongoDBDataAdapterEntry) {
    const promise = fetch('PUT', this.adapterUrl(`${entry.dataAdapterId}/${entry.id}`), entry.toJSON());
    MongoDBDataAdapterActions.update.promise(promise);
  },

  search(dataAdapterId: DataAdapterId, query: string, page: number, perPage: number) {
    const promise = fetch(
      'GET',
      URLUtils.qualifyUrl(`${URL}/${dataAdapterId}?query=${query}&page=${page}&per_page=${perPage}`),
    );

    promise.then((response: PaginatedResponse) => {
      const entries = response.entries.map((entry) => MongoDBDataAdapterEntry.fromJSON(entry));

      this.data = {
        pagination: {
          total: response.total,
          page: response.page,
          perPage: response.per_page,
          count: response.count,
        },
        entries: entries,
      };

      this.trigger({ data: this.data });

      return response;
    });

    MongoDBDataAdapterActions.search.promise(promise);
  },

  delete(entry: MongoDBDataAdapterEntry) {
    const promise = fetch('DELETE', this.adapterUrl(`${entry.dataAdapterId}/${entry.id}`));
    MongoDBDataAdapterActions.delete.promise(promise);
  },

  stats(adapterId: string) {
    const promise = fetch('GET', this.adapterUrl(`stats/${adapterId}`));

    promise.then((response) => {
      this.statsData = {
        entriesCount: response.entries_count,
      };

      this.trigger({ statsData: this.statsData });
    });

    MongoDBDataAdapterActions.stats.promise(promise);
  },
});
