import React from 'react';
import styled from 'styled-components';

import { Button, Panel } from 'components/bootstrap';
import { ClipboardButton, Icon } from 'components/common';
import type { Token } from 'stores/users/UsersStore';
import AppConfig from 'util/AppConfig';
import type { Input } from 'components/messageloaders/Types';
import { useConfiguration } from 'forwarder/wizard/select-forwarder-step/useConfiguration';
import ForwarderInputInformation from 'forwarder/wizard/select-forwarder-step/start-new-forwarder-steps/ForwarderInputInformation';

const StyledConfigurationPanel = styled(Panel)`
  font-size: 1.1em;
  margin: 20px;
  padding: 20px;
  border-color: ${(props) => props.theme.colors.input.border};

  dd {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray[90]};
    font-size: 1.2em;
    margin-bottom: 5px;
  }
`;

const CenteredContent = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const StyledCopyButtonTitle = styled.span`
  font-size: 1.2em;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

type ForwarderConfigurationOptionsProps = {
  forwarderInput?: Input;
  token?: Token;
};

const ForwarderConfigurationOptions = ({ forwarderInput, token }: ForwarderConfigurationOptionsProps) => {
  const [configuration] = useConfiguration(forwarderInput);

  if (!AppConfig.isCloud() && !forwarderInput) {
    return null;
  }

  const ButtonTitle = (
    <StyledCopyButtonTitle>
      <StyledIcon name="content_copy" /> Copy as configuration snippet
    </StyledCopyButtonTitle>
  );

  const clipboardContent = Object.entries(configuration)
    .map(([key, value]) => {
      const effectiveValue = key === 'forwarder_grpc_api_token' && token ? token.token : String(value);

      return `${key} = ${effectiveValue}`;
    })
    .join('\n');

  return (
    <StyledConfigurationPanel>
      <dl>
        {Object.entries(configuration).map(([key, value]) => (
          <React.Fragment key={key}>
            <dt>{key}</dt>
            <dd>{key === 'forwarder_grpc_api_token' && token ? token.token : String(value)}</dd>
          </React.Fragment>
        ))}
      </dl>
      <CenteredContent>
        <ClipboardButton title={ButtonTitle} text={clipboardContent} bsStyle="info" />
      </CenteredContent>
    </StyledConfigurationPanel>
  );
};

type Props = {
  eventKey: string;
  token?: Token;
  forwarderInputs?: Array<Input>;
  onStepComplete: () => void;
};

const ConfigureNewForwarderStep = ({ eventKey, token, forwarderInputs, onStepComplete }: Props) => (
  <Panel eventKey={eventKey}>
    <Panel.Heading>
      <Panel.Title>
        <Panel.Toggle tabIndex={0}>3. Configure Forwarder</Panel.Toggle>
      </Panel.Title>
    </Panel.Heading>
    <Panel.Body collapsible>
      <p>
        Once Graylog Forwarder is installed, add the following configuration options in order to connect to this Graylog
        instance.
      </p>
      <ForwarderInputInformation forwarderInputs={forwarderInputs} />
      <ForwarderConfigurationOptions forwarderInput={forwarderInputs?.[0]} token={token} />
      <StyledButton bsStyle="primary" bsSize="sm" onClick={onStepComplete}>
        Continue
      </StyledButton>
    </Panel.Body>
  </Panel>
);

export default ConfigureNewForwarderStep;
