import * as React from 'react';
import styled from 'styled-components';

import { SectionHeading } from 'security-app/components/Layout';

const StyledSectionHeading = styled(SectionHeading)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6rem;
  padding: 15px;
  border: 1px solid ${(props: any) => props.theme.colors.variant.lighter.default};
  border-radius: 5px;
  margin-bottom: 0.4rem;
  background-color: ${(props: any) => props.theme.colors.global.contentBackground};
`;

const SectionHeadingNavButtonWrapper = styled.div`
  margin-right: 1rem;
`;

const SectionHeadingMainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`;

const SectionHeadingRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`;

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  actionButton?: React.ReactNode;
  navButton?: React.ReactNode;
};

const PageHeader = ({ title, description = null, actionButton = null, navButton = null }: Props) => (
  <StyledSectionHeading>
    {navButton && <SectionHeadingNavButtonWrapper>{navButton}</SectionHeadingNavButtonWrapper>}
    <SectionHeadingMainContent>
      <SectionHeadingRow>
        {title}
        {actionButton}
      </SectionHeadingRow>
      {description && <SectionHeadingRow>{description}</SectionHeadingRow>}
    </SectionHeadingMainContent>
  </StyledSectionHeading>
);

export default PageHeader;
