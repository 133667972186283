import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';
import Select from 'components/common/Select';

import FormAdvancedOptions from './FormAdvancedOptions';

import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from '../common/utils/types';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe = ({ onSubmit, onChange }: Props) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { pollingInterval } = formData;

  const availableContent = [
    { label: 'Events', value: 'EVENTS' },
    { label: 'Incidents', value: 'INCIDENTS' },
    { label: 'Audit Events', value: 'AUDIT_EVENTS' },
    { label: 'System Activities', value: 'SYSTEM_ACTIVITIES' },
  ];

  const [contentTypes, setContentTypes] = useState<string>('');

  const handleSubmit = () => {
    let error = false;
    formData.content_type.value = contentTypes;

    if (pollingInterval.value < 1) {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message: 'Minimum allowable polling interval is 1 minute.',
      });

      error = true;
    }

    if (!error) {
      setFormError(null);
      onSubmit();
    }
  };

  return (
    <FormWrap onSubmit={handleSubmit} buttonContent="Proceed" title="" error={formError} description="">
      <label className="control-label" htmlFor="contents">
        Logs Types to Collect
      </label>
      <Select
        multi
        name="content_type"
        id="content_type"
        onChange={(selected: string) => setContentTypes(selected)}
        options={availableContent}
        value={contentTypes}
        matchProp="label"
      />
      <span className="help-block">The activity log for the above content types will be fetched.</span>
      <Input
        id="pollingInterval"
        name="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue || ''}
        min="1"
        max="60"
        step="1"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in Symantec EDR. The smallest allowable interval is 1 minute."
        label="Polling Interval"
      />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
