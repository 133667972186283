import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedGCPApp from './EmbeddedGCPApp';

type ExternalLinkProps = {
  href: string;
  title: string;
};

const ExternalLink = ({ href, title }: ExternalLinkProps) => (
  <a href={href} rel="noopener noreferrer" target="_blank">
    {title}
  </a>
);

const GCPApp = () => (
  <>
    <PageHeader title="Google Cloud Integrations">
      <span>This feature retrieves log records from Google Cloud Platform.</span>
      <p>
        A <ExternalLink href="https://console.cloud.google.com" title="Google Cloud Platform" /> subscription is
        required.{' '}
      </p>
    </PageHeader>
    <EmbeddedGCPApp />
  </>
);

export default GCPApp;
