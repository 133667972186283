import { useMemo } from 'react';

import useMitreCategories from 'security-app/components/SecurityEvents/Alerts/MitreCategories/useMitreCategories';

export type Tactic = [string, string];

const useMitreTactics = () => {
  const { data, isInitialLoading } = useMitreCategories();
  const tactics: [string, string][] = useMemo(
    () => (isInitialLoading ? undefined : data.tactics.map((tactic) => [tactic.id, tactic.name] as const)),
    [data?.tactics, isInitialLoading],
  );

  return {
    tactics,
    isLoadingTactics: isInitialLoading,
  };
};
export default useMitreTactics;
