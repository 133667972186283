import React from 'react';
import styled from 'styled-components';
import mapValues from 'lodash/mapValues';

import ArchiveBackendDeletionForm from 'archive/components/ArchiveBackendDeletionForm';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from 'archive/hooks/useArchivesQuery';
import { LinkContainer } from 'components/common/router';
import { EntityList, EntityListItem, PaginatedList } from 'components/common';
import { Button, Col, DropdownButton, Row, DeleteMenuItem } from 'components/bootstrap';
import { ConfigurationWell } from 'components/configurationforms';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';
import ButtonToolbar from 'components/bootstrap/ButtonToolbar';

import { BACKEND_TYPES } from '../ArchiveStore';
import type { Backend, Pagination } from '../types';

const isCloud = AppConfig.isCloud();

const StyledRow = styled(Row)`
  dt {
    float: left;
    width: 200px;
    overflow: hidden;
    clear: left;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  dd {
    margin-left: 180px;
    display: inline-block;
  }

  dl {
    margin-bottom: 0;
  }
`;
const ArchiveBackendWrapper = styled.div`
  .entity-list,
  .alert {
    margin-top: 10px;
  }
`;

type Props = {
  backends: Array<Backend>;
  pagination: Pagination;
  onDelete: (backend: Backend, deleteArchives: boolean) => void;
  onPageChange: (newPage: number, pageSize: number) => void;
};

const ArchiveBackends = ({ backends, pagination, onDelete, onPageChange }: Props) => {
  const deletionModals = {};

  const _onDelete = (backend: Backend) => () => {
    if (backend.id && deletionModals[backend.id]) {
      deletionModals[backend.id].open();
    }
  };

  const formatBackend = (backend: Backend) => {
    const { settings, description, id, title } = backend;
    const actions = !isCloud && (
      <ButtonToolbar>
        <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS_BACKENDID')(id)}>
          <Button>Edit</Button>
        </LinkContainer>
        <DropdownButton title="More Actions" id={`backend-dropdown-${id || 'newbackend'}`} pullRight>
          <DeleteMenuItem onSelect={_onDelete(backend)} />
        </DropdownButton>
      </ButtonToolbar>
    );

    const normalizedSettings = mapValues(settings, (value, key) => {
      if (key === 'type') {
        return BACKEND_TYPES[settings[key]];
      }

      if (key === 'aws_secret_access_key') {
        return '***********';
      }

      return value;
    });
    const content = (
      <Col md={12}>
        <StyledRow>
          <Col md={12}>
            <dl>
              <ConfigurationWell id={id} configuration={normalizedSettings} />
            </dl>
            <ArchiveBackendDeletionForm
              ref={(c) => {
                deletionModals[id || 'newbackend'] = c;
              }}
              backend={backend}
              onDelete={onDelete}
            />
          </Col>
        </StyledRow>
      </Col>
    );

    return (
      <EntityListItem
        key={`backend-${id || 'newbackend'}`}
        title={title}
        description={description}
        actions={actions}
        contentRow={content}
      />
    );
  };

  return (
    <ArchiveBackendWrapper>
      <h2>
        Archive Backends
        <span>
          &nbsp;<small>{pagination.total} total</small>
        </span>
      </h2>

      <p className="description archive-backends-description">
        Graylog archives are written to the configured backend.
      </p>

      <div>
        <PaginatedList
          pageSize={DEFAULT_PAGE_SIZE}
          pageSizes={PAGE_SIZES}
          totalItems={pagination.total}
          onChange={onPageChange}
          showPageSizeSelect={false}>
          <EntityList
            bsNoItemsStyle="info"
            noItemsText="There are no backends to display"
            items={backends.map((backend) => formatBackend(backend))}
          />
        </PaginatedList>
      </div>
    </ArchiveBackendWrapper>
  );
};

export default ArchiveBackends;
