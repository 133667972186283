import * as React from 'react';
import range from 'lodash/range';
import moment from 'moment';
import { Field } from 'formik';

import { Select, InputDescription } from 'components/common';

type Props = {
  id: string;
  required?: boolean;
};

const TimeSelect = ({ id, required = false }: Props) => {
  const time = moment().minutes(0);
  const hours = range(0, 24).map((hour) => {
    const formattedTime = time.hours(hour).format('HH:mm');

    return { value: formattedTime, label: formattedTime };
  });

  return (
    <Field name={id}>
      {({ field: { name, value, onChange, onBlur }, meta: { error, touched } }) => (
        <>
          <Select
            onBlur={onBlur}
            onChange={(newValue: string) =>
              onChange({
                target: { value: newValue, name },
              })
            }
            options={hours}
            inputId={name}
            placeholder="Select time of the day"
            required={required}
            value={value}
          />
          {error && touched && <InputDescription error={error} className="no-bm" />}
        </>
      )}
    </Field>
  );
};

export default TimeSelect;
