import React from 'react';

import type { DataAdapter } from 'lookup/types/IlluminateCSVFileAdapterConfig';

type Props = {
  dataAdapter: DataAdapter;
};

const IlluminateCSVFileAdapterSummary = ({ dataAdapter }: Props) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>File path</dt>
      <dd>{config.path}</dd>
      <dt>Separator</dt>
      <dd>
        <code>{config.separator}</code>
      </dd>
      <dt>Quote character</dt>
      <dd>
        <code>{config.quotechar}</code>
      </dd>
      <dt>Key column</dt>
      <dd>{config.key_column}</dd>
      <dt>Value column</dt>
      <dd>{config.value_column}</dd>
      <dt>Check interval</dt>
      <dd>{config.check_interval} seconds</dd>
      <dt>Case-insensitive lookup</dt>
      <dd>{config.case_insensitive_lookup ? 'yes' : 'no'}</dd>
      <dt>CIDR lookup</dt>
      <dd>{config.cidr_lookup ? 'yes' : 'no'}</dd>
    </dl>
  );
};

export default IlluminateCSVFileAdapterSummary;
