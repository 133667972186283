import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/bootstrap';

const FormContent = styled.div`
  display: flex;
  flex-direction row;

  > :not(:last-child) {
    margin-right: 5px;
  }

  > * {
    display: inline-block;
  }
`;

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 1rem;
`;

const SearchField = styled.input<{ $searchFieldMinWidth?: string }>`
  width: 30%;
  min-width: ${({ $searchFieldMinWidth }) => $searchFieldMinWidth || '300px'};
`;

const StyledButton = styled(Button)<{ $isRounded?: boolean }>(
  ({ $isRounded }) => css`
    border-radius: ${$isRounded ? '4px' : '0px'};
  `,
);

type Props = {
  query?: string;
  onSearch: (query: string) => void;
  onReset?: () => void;
  placeholder?: string;
  searchFieldMinWidth?: string;
  roundedButtons?: boolean;
};

const SearchForm = ({
  query = '',
  onSearch,
  onReset = () => {},
  placeholder = 'Enter search query...',
  searchFieldMinWidth = '',
  roundedButtons = false,
}: Props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>(query);

  const handleOnSearch = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (searchTerm) onSearch(searchTerm);
    else onReset();
  };

  const handleReset = () => {
    setSearchTerm('');
    onReset();
  };

  return (
    <SearchRow>
      <form className="form-inline" onSubmit={handleOnSearch}>
        <FormContent>
          <SearchField
            id="search-input"
            type="text"
            data-testid="search-input"
            placeholder={placeholder}
            autoComplete="off"
            value={searchTerm}
            onChange={(e: React.BaseSyntheticEvent) => setSearchTerm(e.target.value)}
            className="query form-control"
            $searchFieldMinWidth={searchFieldMinWidth}
          />
          <StyledButton
            bsStyle="primary"
            data-testid="execute-search"
            onClick={handleOnSearch}
            $isRounded={roundedButtons}>
            Search
          </StyledButton>
          <StyledButton data-testid="reset-search" onClick={handleReset} $isRounded={roundedButtons}>
            Reset
          </StyledButton>
        </FormContent>
      </form>
    </SearchRow>
  );
};

export default SearchForm;
