import React from 'react';

import getDisplayName from 'authentication/components/oidc/config/helpers/getDisplayName';
import { FormikFormGroup } from 'components/common';

type OidcBaseUrlFieldProps = {
  help: React.ReactElement;
  type: string;
  isOkta: boolean;
  disabled: boolean;
  validate: (...args: any[]) => string;
};

const OidcBaseUrlField = ({ help, type, isOkta, disabled, validate }: OidcBaseUrlFieldProps) => {
  const label = `${getDisplayName(type)} base URL`;

  const name = isOkta ? 'oktaBaseUrl' : 'baseUrl';

  return (
    <div>
      <FormikFormGroup
        help={help}
        label={label}
        name={name}
        disabled={disabled}
        validate={validate}
        placeholder={label}
      />
    </div>
  );
};

export default OidcBaseUrlField;
