import * as React from 'react';
import styled from 'styled-components';

import Drawer from 'components/common/Drawer';

const StyledDrawer = styled(Drawer)`
  .mantine-Drawer-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
`;

type Props = React.PropsWithChildren<{
  show: boolean;
  onClose: () => void;
}>;

const AddWidgetDrawer = ({ children, show, onClose }: Props) => (
  <StyledDrawer
    opened={show}
    onClose={onClose}
    position="right"
    size="66%"
    title="Add Widgets"
    closeOnClickOutside={false}>
    {children}
  </StyledDrawer>
);

export default AddWidgetDrawer;
