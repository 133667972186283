import * as React from 'react';

import { Button, Col } from 'components/bootstrap';
import { Modal } from 'common/components';
import { useUploadSigmaRules } from 'security-app/hooks/useSigmaAPI';
import type { FormStateType } from 'security-app/components/common/FormikComponents';
import type { SigmaRuleZipValidationErrorAPIType, SigmaRuleZipValidationAPIResponse } from 'security-app/hooks/api/sigmaAPI.types';
import type { ValidationResponse } from 'security-app/hooks/api/sigmaAPI';

import { FlexRow, FlexCol, StyledProgressBar, StyledAlert } from './styled-components';
import { getBodyPayload, hasError } from './utils';
import FileList from './FileList';
import FileOptionsForm from './FileOptionsForm';
import type { FileFormType } from './types';

type ButtonsProps = {
  confirmDisabled: boolean;
  cancelDisabled: boolean;
  onCancel: () => void;
  uploadFiles: () => void;
};

const Buttons = ({ confirmDisabled, cancelDisabled, onCancel, uploadFiles }: ButtonsProps) => (
  <>
    <Button onClick={onCancel} disabled={cancelDisabled}>
      Cancel
    </Button>
    <Button bsStyle="success" onClick={uploadFiles} disabled={confirmDisabled}>
      Upload rules
    </Button>
  </>
);

type Props = {
  onClose: () => void;
};

function UploadRulesModal({ onClose }: Props) {
  const [files, setFiles] = React.useState<FileFormType[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<FileFormType>(files[0]);
  const [uploadProgress, setUploadProgress] = React.useState<number | null>();
  const [validFormStateMap, setValidFormStateMap] = React.useState<{ [key: string]: boolean }>({});
  const { uploadSigmaRules, uploadingRules } = useUploadSigmaRules();

  const reportProgress = React.useCallback((percent: number) => {
    setUploadProgress(percent);
  }, []);

  const uploadFiles = React.useCallback(async () => {
    const bodyPayload = getBodyPayload(files);

    await uploadSigmaRules({
      fileForms: files,
      body: bodyPayload,
      progressCB: reportProgress,
    });

    const badFiles = files.filter((fileForm: FileFormType) => hasError(fileForm));

    if (badFiles.length > 0) {
      setFiles(badFiles);
      setSelectedFile(undefined);
      setUploadProgress(undefined);

      return;
    }

    onClose();
  }, [onClose, files, uploadSigmaRules, reportProgress]);

  const handleValidFormState = React.useCallback(
    (formState: FormStateType) => {
      setValidFormStateMap((prevMap: { [key: string]: boolean }) => ({
        ...prevMap,
        [selectedFile.id]: formState.isValid,
      }));
    },
    [selectedFile?.id],
  );

  const canImport = React.useMemo(
    () => Object.values(validFormStateMap).every((value: boolean) => value),
    [validFormStateMap],
  );

  const renderError = (file: FileFormType) => {
    if (file.file.type === 'application/zip') {
      return (file.ruleValidation as SigmaRuleZipValidationAPIResponse).results.map((validation: SigmaRuleZipValidationErrorAPIType) => {
        if (typeof validation === 'string') return <p key={validation}>{validation}</p>;

        return (
          <div key={validation.file_name}>
            <strong>{validation.file_name}:</strong>
            {validation.errors.map((ruleError: string) => (
              <p key={ruleError}>{ruleError}</p>
            ))}
          </div>
        );
      })
    }

    return (
      <div key={file.file.name}>
        <strong>{file.file.name}:</strong>
        {(file.ruleValidation as ValidationResponse).errors.map((ruleError: string) => (
          <p key={ruleError}>{ruleError}</p>
        ))}
      </div>
    );
  }

  return (
    <Modal
      show
      onClose={onClose}
      title="Upload Sigma Rules"
      maxWidth="900px"
      buttons={
        <Buttons
          confirmDisabled={uploadingRules || !canImport || files.length < 1}
          cancelDisabled={uploadingRules}
          onCancel={onClose}
          uploadFiles={uploadFiles}
        />
      }>
      <FlexRow>
        <Col md={5}>
          <FileList files={files} setFiles={setFiles} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
        </Col>
        <FlexCol md={7}>
          {selectedFile && (
            <FileOptionsForm
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setFiles={setFiles}
              formState={handleValidFormState}
            />
          )}
          {uploadProgress && (
            <StyledProgressBar
              bars={[{ value: uploadProgress, bsStyle: 'info', animated: true, label: `Uploading ${uploadProgress}%` }]}
            />
          )}
        </FlexCol>
      </FlexRow>
      {selectedFile && hasError(selectedFile) && (
        <StyledAlert bsStyle="danger" aria-label="Rule format errors">
          {renderError(selectedFile)}
        </StyledAlert>
      )}
    </Modal>
  );
}

export default UploadRulesModal;
