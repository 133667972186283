import * as Immutable from 'immutable';

import type { ViewHookArguments } from 'views/logic/hooks/ViewHook';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';

const bindParametersFromQuery = async ({ query, view, executionState }: ViewHookArguments) => {
  // Get parameter values from query, filtered by parameter presence
  const parameters = view.search?.parameters ?? Immutable.Set();
  const queryParameterValues = Immutable.Map(query)
    .filter((_, key) => parameters.find((p) => p.name === key) !== undefined)
    .toMap();

  // Set filtered parameter values from query in search execution state.
  let { parameterBindings } = executionState;

  parameters.forEach((parameter) => {
    if (queryParameterValues.get(parameter.name) || parameter.defaultValue) {
      parameterBindings = parameterBindings.set(
        parameter.name,
        ParameterBinding.forValue(queryParameterValues.get(parameter.name, parameter.defaultValue)),
      );
    }
  });

  const newExecutionState = executionState.toBuilder().parameterBindings(parameterBindings).build();

  return [view, newExecutionState] as const;
};

export default bindParametersFromQuery;
