import React, { useState } from 'react';

import type Parameter from 'views/logic/parameters/Parameter';

import MissingParametersForWidgetPreview from './MissingParametersForWidgetPreview';
import ReportingWidgetPreview from './ReportingWidgetPreview';

type Props = Omit<React.ComponentProps<typeof ReportingWidgetPreview>, 'parameterValues'> & {
  widget: {
    parameters: Array<Parameter>;
  };
  searchId: string;
};

const AvailableWidgetPreview = (props: Props) => {
  const {
    widget: { parameters },
    searchId,
  } = props;
  const [parameterValues, setParameterValues] = useState({});
  const requiredParameters = parameters
    .filter((p) => !p.optional && !p.defaultValue && p.needsBinding)
    .filter((p) => !parameterValues[p.name]);
  const parameterSearchIds = parameters.reduce(
    (searchIds, parameter) => ({ ...searchIds, [parameter.name]: searchId }),
    {},
  );

  if (requiredParameters && requiredParameters.length > 0) {
    return (
      <MissingParametersForWidgetPreview
        parameters={parameters}
        parameterValues={parameterValues}
        onSubmit={setParameterValues}
        parameterSearchIds={parameterSearchIds}
      />
    );
  }

  return <ReportingWidgetPreview {...props} parameterValues={parameterValues} />;
};

export default AvailableWidgetPreview;
