import * as React from 'react';
import styled from 'styled-components';

import { PADDING_BY_SIZE, getBGColorByVariant, getTextColorByVariant } from './utils';
import type { BadgeStyles, BadgeSize } from './types';

const BadgeContainer = styled.div<{ $size: string; $variant: string; $width?: string, $solid?: boolean }>`
  padding: ${({ theme, $size }) => theme.spacings[PADDING_BY_SIZE[$size].topBottom]}
    ${({ theme, $size }) => theme.spacings[PADDING_BY_SIZE[$size].leftRight]};
  background-color: ${({ theme, $variant, $solid }) => getBGColorByVariant(theme, $variant, $solid)};
  border-radius: ${({ theme }) => theme.spacings.xs};
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width || 'auto'};
`;

const BadgeText = styled.small<{ $size: string; $variant: string, $solid: boolean }>`
  text-transform: uppercase;
  color: ${({ theme, $variant, $solid }) => getTextColorByVariant(theme, $variant, $solid)};
  text-align: center;
  font-weight: ${({ $size }) => $size === 'xs' ? 'normal' : 'bold'};
  font-size: ${({ theme, $size }) => $size === 'xs' ? '0.6rem' : theme.fonts.size.small};
  line-height: normal;
`;

type Props = {
  children?: string | number;
  variant?: BadgeStyles;
  size?: BadgeSize;
  solid?: boolean;
  empty?: boolean;
  title?: string;
};

function Badge({ children = undefined, variant = 'default', size = 'sm', solid = false, empty = false, title = undefined }: Props) {
  if (!children && typeof children !== 'number' && !empty) return null;

  return (
    <BadgeContainer role="status" title={title} aria-label={children?.toString()} $size={size} $variant={variant} $solid={solid}>
      <BadgeText $size={size} $variant={variant} $solid={solid}>
        {children}
      </BadgeText>
    </BadgeContainer>
  );
}

export default Badge;
