import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import F5NetworksRoutes from './common/Routes';

type Props = { url: string };

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state

const F5NetworksInputConfiguration = ({ url = F5NetworksRoutes.INTEGRATIONS.F5Networks.ACTIVITYAPI.index }: Props) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

export default F5NetworksInputConfiguration;
