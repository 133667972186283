export const PADDING_BY_SIZE = {
  xs: { topBottom: 'xxs', leftRight: 'xs' },
  sm: { topBottom: 'xxs', leftRight: 'sm' },
  md: { topBottom: 'xs', leftRight: 'sm' },
  lg: { topBottom: 'xs', leftRight: 'md' },
  xl: { topBottom: 'sm', leftRight: 'md' },
};

const INVERT_MODE = {
  dark: 'light',
  light: 'dark',
};

const baseColorByStyle = (theme: any) => ({
  info: theme.colors.variant[INVERT_MODE[theme.mode]].info,
  success: theme.colors.variant[INVERT_MODE[theme.mode]].success,
  warning: theme.colors.variant[INVERT_MODE[theme.mode]].warning,
  danger: theme.colors.variant[INVERT_MODE[theme.mode]].danger,
});

export const getBGColorByVariant = (theme: any, variant: string, solid = false) => {
  const colorMap = {
    ...baseColorByStyle(theme),
    default: theme.colors.background.secondaryNav,
  };

  // eslint-disable-next-line no-nested-ternary
  return `${colorMap[variant]}${(variant === 'default') ? 'ff' : solid ? '70' : '25'}`;
};

export const getTextColorByVariant = (theme: any, style: string, solid = false) => {
  if (solid) return theme.colors.text.primary;

  const colorMap = {
    ...baseColorByStyle(theme),
    default: theme.colors.text.primary,
  };

  return colorMap[style];
};
