import __request__ from 'routing/request';
interface SortPreferences {
}
interface EntityListPreferences {
    readonly per_page: number;
    readonly custom_preferences: {
        readonly [_key: string]: Object;
    };
    readonly displayed_attributes: string[];
    readonly sort: SortPreferences;
}
/**
 * Get preferences for user's entity list
 */
export function get(entity_list_id: string): Promise<EntityListPreferences> {
    return __request__('GET', `/entitylists/preferences/${entity_list_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create or update user preferences for certain entity list
 */
export function create(JSONbody: EntityListPreferences, entity_list_id: string): Promise<unknown> {
    return __request__('POST', `/entitylists/preferences/${entity_list_id}`, JSONbody, {}, {
        'Accept': []
    });
}
