import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { Col, Row } from 'components/bootstrap';

const MIN_PAPER_HEIGHT = 150;

const Container = styled.div<{ $minHeight: number }>(
  ({ $minHeight }) => `
  min-height: ${$minHeight}px;
  border: 1px outset #aaa;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 3em;
  width: 95%;
`,
);

const PaperSheet = ({ children = [] }: { children: React.ReactNode }) => {
  const [minPaperHeight, setMinPaperHeight] = useState(MIN_PAPER_HEIGHT);
  const paperSheet = useRef(null);

  const updatePaperHeight = useCallback(() => {
    if (paperSheet.current) {
      const width = paperSheet.current.offsetWidth;
      const minHeight = 1.41 * width;
      setMinPaperHeight(minHeight);
    }
  }, []);

  useEffect(() => {
    updatePaperHeight();
  }, [updatePaperHeight]);

  return (
    <Container ref={paperSheet} $minHeight={minPaperHeight}>
      <Row>
        <Col md={12}>{children}</Col>
      </Row>
    </Container>
  );
};

export default PaperSheet;
