import { useMemo } from 'react';

import type Search from 'views/logic/search/Search';
import type { FormValues } from 'data-lake/preview/SearchBar';
import useUserDateTime from 'hooks/useUserDateTime';
import { onInitializingTimerange } from 'views/components/TimerangeForForm';
import type { DataLakeSearchType } from 'data-lake/preview/Types';
import { WIDGET_DEFAULTS } from 'data-lake/preview/Constants';

const useInitialSearchBarFormValues = (executedSearch: Search, initialUrlParams: FormValues) => {
  const { formatTime } = useUserDateTime();

  return useMemo(() => {
    if (executedSearch) {
      const searchType = executedSearch.queries.first().searchTypes[0] as DataLakeSearchType;

      return {
        stream: searchType.streams[0],
        timerange: onInitializingTimerange(searchType.timerange, formatTime),
        fields: {
          fieldFilters: searchType.field_filters ?? WIDGET_DEFAULTS.fieldFilters,
          operator: searchType.filters_operator ?? WIDGET_DEFAULTS.fieldsOperator,
        },
      };
    }

    return {
      stream: initialUrlParams.stream,
      timerange: initialUrlParams.timerange ?? WIDGET_DEFAULTS.timerange,
      fields: {
        fieldFilters: initialUrlParams.fields?.fieldFilters ?? WIDGET_DEFAULTS.fieldFilters,
        operator: initialUrlParams.fields?.operator ?? WIDGET_DEFAULTS.fieldsOperator,
      },
    };
  }, [executedSearch, formatTime, initialUrlParams.fields, initialUrlParams.stream, initialUrlParams.timerange]);
};

export default useInitialSearchBarFormValues;
