import { useQuery } from '@tanstack/react-query';

import type { RequestQuery, PaginatedUserSearchFilters } from 'search-filter/types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import PaginationURL from 'util/PaginationURL';
import { defaultOnError } from 'util/conditional/onError';

export const urlPrefix = '/plugins/org.graylog.plugins.searchfilters';
export const USER_FILTER_QUERY_KEY = 'user_filter_query_key';

export const DEFAULT_PAGINATION = {
  per_page: 25,
  page: 1,
  count: 0,
  total: 0,
};

const fetchUserFilters = async ({
  query,
  page,
  per_page,
  scope,
}: RequestQuery): Promise<PaginatedUserSearchFilters> => {
  const additional = scope ? { scope } : undefined;
  const url = PaginationURL(`${urlPrefix}/search_filters/paginated`, page, per_page, query, additional);

  return fetch('GET', qualifyUrl(url));
};

const useUserSearchFiltersQuery = (
  requestQuery: RequestQuery,
): { data: PaginatedUserSearchFilters; isFetching: boolean } =>
  useQuery(
    [USER_FILTER_QUERY_KEY, requestQuery],
    () =>
      defaultOnError(
        fetchUserFilters(requestQuery),
        'Loading search filters  failed with status',
        'Could not load search filters',
      ),
    {
      retry: 0,
      keepPreviousData: true,
      initialData: {
        filters: [],
        pagination: DEFAULT_PAGINATION,
      },
    },
  );

export default useUserSearchFiltersQuery;
