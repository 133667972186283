import Reflux from 'reflux';
import type * as Immutable from 'immutable';

import { singletonActions } from 'logic/singleton';
import type { RefluxActions } from 'stores/StoreTypes';
import type { PaginatedEntityShares } from 'actions/permissions/EntityShareActions';
import type { Pagination, PaginatedList } from 'stores/PaginationTypes';
import type { PaginatedUsers } from 'stores/users/UsersStore';
import type { PaginatedRoles } from 'actions/roles/AuthzRolesActions';

import type Team from '../logic/Team';

export type PaginatedTeams = PaginatedList<Team> & {
  context: {
    users: Immutable.Map<string, { title: string }>;
    roles: Immutable.Map<string, { title: string }>;
  };
};

export type ActionsType = {
  create: (team: Team) => Promise<Team>;
  load: (teamId: Team['id']) => Promise<Team>;
  update: (team: Team) => Promise<Team>;
  delete: (team: Team) => Promise<void>;
  addMembers: (teamId: string, userId: Immutable.Set<string>) => Promise<Team>;
  addMemberToTeams: (userId: string, teamIds: Immutable.Set<string>) => Promise<void>;
  addRoleToTeams: (roleId: string, teamIds: Immutable.Set<string>) => Promise<void>;
  removeMember: (teamId: string, userId: string) => Promise<Team>;
  removeRole: (teamId: string, roleId: string) => Promise<Team>;
  loadUsersForTeam: (teamId: string, teamName: string, pagination: Pagination) => Promise<PaginatedUsers>;
  loadRolesForTeam: (teamId: string, roleName: string, pagination: Pagination) => Promise<PaginatedRoles>;
  loadTeamsForUser: (userId: string, pagination: Pagination) => Promise<PaginatedTeams>;
  loadTeamsForRole: (roleId: string, pagination: Pagination) => Promise<PaginatedTeams>;
  loadTeamsPaginated: (pagination: Pagination) => Promise<PaginatedTeams>;
  loadTeamSharesPaginated(teamId: string, pagination: Pagination): Promise<PaginatedEntityShares>;
};

const TeamsActions: RefluxActions<ActionsType> = singletonActions('enterprise.security.Teams', () =>
  Reflux.createActions({
    create: { asyncResult: true },
    load: { asyncResult: true },
    update: { asyncResult: true },
    delete: { asyncResult: true },
    addMembers: { asyncResult: true },
    addMemberToTeams: { asyncResult: true },
    addRoleToTeams: { asyncResult: true },
    removeMember: { asyncResult: true },
    removeRole: { asyncResult: true },
    loadTeamsForUser: { asyncResult: true },
    loadTeamsForRole: { asyncResult: true },
    loadUsersForTeam: { asyncResult: true },
    loadRolesForTeam: { asyncResult: true },
    loadTeamsPaginated: { asyncResult: true },
    loadTeamSharesPaginated: { asyncResult: true },
  }),
);

export default TeamsActions;
