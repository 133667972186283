import * as React from 'react';
import { useCallback, useMemo } from 'react';

import RowCheckbox from 'components/common/EntityDataTable/RowCheckbox';
import type { WidgetRef } from 'report/types';
import type { AvailableDashboards } from 'report/report-creation/content/types';
import { compareWidgetRefs, widgetId } from 'report/report-creation/content/types';

type Props = {
  dashboards: Array<AvailableDashboards>;
  disabledWidgets: Array<WidgetRef>;
  onChange: (newWidgets: Array<WidgetRef>) => void;
  selected: Array<WidgetRef>;
};

const BulkSelectCheckBox = ({ dashboards, disabledWidgets, onChange, selected }: Props) => {
  const allWidgets = useMemo(
    () =>
      dashboards.flatMap((d) =>
        d.widgets
          .filter((widget) => widget.eligible)
          .map((widget) => widgetId(widget, d))
          .filter(
            (widgetRef) => disabledWidgets.find((disabled) => compareWidgetRefs(disabled, widgetRef)) === undefined,
          ),
      ),
    [dashboards, disabledWidgets],
  );
  const disabled = useMemo(() => allWidgets.length === 0, [allWidgets.length]);
  const allSelected = useMemo(
    () => !disabled && allWidgets.find((widget) => !selected.includes(widget)) === undefined,
    [allWidgets, disabled, selected],
  );

  const toggleAll = useCallback(() => {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(allWidgets);
    }
  }, [allSelected, allWidgets, onChange]);

  return <RowCheckbox onChange={toggleAll} checked={allSelected} title={allSelected ? 'Unselect all' : 'Select all'} />;
};

export default BulkSelectCheckBox;
