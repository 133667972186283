import Reflux from 'reflux';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { singletonStore, singletonActions } from 'logic/singleton';

export type ActionsType = {
  acknowledgeTrafficViolation: () => Promise<{}>;
  getTrafficViolation: () => Promise<unknown>;
};

export const LicenseTrafficViolationActions = singletonActions('TrafficViolation', () =>
  Reflux.createActions<ActionsType>({
    acknowledgeTrafficViolation: { asyncResult: true },
    getTrafficViolation: { asyncResult: true },
  }),
);

type TrafficViolationResponse = {
  state: {
    acknowledged: boolean;
    email_sent: boolean;
    most_recent_violation: string;
  };
};

type TrafficViolationStoreState = {
  acknowledged: boolean;
  mostRecentViolation: string;
};

export const LicenseTrafficViolationStore = singletonStore('TrafficViolation', () =>
  Reflux.createStore<TrafficViolationStoreState>({
    listenables: [LicenseTrafficViolationActions],
    _state: {
      acknowledged: undefined,
      mostRecentViolation: undefined,
    },

    getInitialState() {
      const { acknowledged, mostRecentViolation } = this._state;

      return {
        acknowledged,
        mostRecentViolation,
      };
    },

    acknowledgeTrafficViolation(): Promise<void> {
      const url = qualifyUrl('/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations/acknowledgement');
      const promise = fetch('PUT', url, {});

      promise.then(
        () => this.getTrafficViolation(),
        (error) => {
          UserNotification.error(
            `Saving Traffic limit violation acknowlegment failed with status: ${error}`,
            'Could not save Traffic limit violation acknowledgment',
          );
        },
      );

      LicenseTrafficViolationActions.acknowledgeTrafficViolation.promise(promise);

      return promise;
    },

    getTrafficViolation(): Promise<any> {
      const url = qualifyUrl('/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations');
      const promise = fetch('GET', url);

      promise
        .then((response: TrafficViolationResponse) => {
          this.trigger({
            acknowledged: response.state.acknowledged,
            mostRecentViolation: response.state.most_recent_violation,
          });
        })
        .catch((error) => {
          UserNotification.error(
            `Fetching Traffic limit violation failed with status: ${error}`,
            'Could not retrieve Traffic limit violation',
          );
        });

      LicenseTrafficViolationActions.getTrafficViolation.promise(promise);

      return promise;
    },
  }),
);
