import * as React from 'react';
import styled from 'styled-components';
import { useCallback } from 'react';

import { IfPermitted } from 'components/common';
import { TableHead, TableHeaderCell } from 'views/components/datatable';
import IfInteractive from 'views/components/dashboard/IfInteractive';
import { INVESTIGATION_ATTRIBUTES } from 'security-app/components/Investigations/Constants';
import useUsersAndTeams from 'security-app/components/Investigations/hooks/useUsersAndTeams';
import { useGetPriorities } from 'security-app/hooks/useInvestigationsAPI';
import useLicenseCheck from 'license/useLicenseCheck';
import InvalidLicenseWidgetInfo from 'search/InvalidLicenseWidgetInfo';
import AttributeSortIcon from 'views/components/widgets/overview-configuration/AttributeSortIcon';
import type Direction from 'views/logic/aggregationbuilder/Direction';

import InvestigationsTableRow from './InvestigationsTableRow';

import InvestigationsWidgetSortConfig from '../logic/InvestigationsWidgetSortConfig';
import type { InvestigationListItem } from '../types';
import type InvestigationsListConfig from '../logic/InvestigationsWidgetConfig';

const TableWrapper = styled.div`
  overflow: auto;
`;

const ActionsHeader = styled(TableHeaderCell)`
  && {
    min-width: auto;
    width: 35px;
  }
`;

type Props = {
  config: InvestigationsListConfig;
  investigations: Array<InvestigationListItem>;
  onArchive: (investigationId: string) => void;
  onDelete: (investigationId: string) => void;
  onSortChange: (sort: InvestigationsWidgetSortConfig) => Promise<unknown>;
  setLoadingState: (loading: boolean) => void;
};

const InvestigationsTable = ({ investigations, config, onSortChange, setLoadingState, onArchive, onDelete }: Props) => {
  const usersAndTeams = useUsersAndTeams();
  const { priorities } = useGetPriorities(true);
  const { security } = useLicenseCheck();
  const _onSortChange = useCallback(
    (fieldName: string, nextDirection: Direction) =>
      onSortChange(new InvestigationsWidgetSortConfig(fieldName, nextDirection)),
    [onSortChange],
  );

  if (!security?.isValid) {
    return <InvalidLicenseWidgetInfo featureName="Investigation" />;
  }

  return (
    <TableWrapper>
      <table className="table table-condensed">
        <TableHead>
          <tr>
            {config.fields.toArray().map((field) => (
              <TableHeaderCell key={field}>
                {INVESTIGATION_ATTRIBUTES[field]?.title ?? field}
                <AttributeSortIcon
                  onSortChange={_onSortChange}
                  attribute={field}
                  attributeTitle={INVESTIGATION_ATTRIBUTES[field]?.title ?? field}
                  activeAttribute={config.sort.field}
                  activeDirection={config.sort.direction}
                  setLoadingState={setLoadingState}
                />
              </TableHeaderCell>
            ))}
            <IfInteractive>
              <IfPermitted permissions="investigations:edit">
                <ActionsHeader />
              </IfPermitted>
            </IfInteractive>
          </tr>
        </TableHead>
        <tbody>
          {investigations?.map((investigation) => (
            <InvestigationsTableRow
              key={investigation.id}
              priorities={priorities}
              investigation={investigation}
              onArchive={onArchive}
              onDelete={onDelete}
              usersAndTeams={usersAndTeams}
              fields={config.fields}
            />
          ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default InvestigationsTable;
