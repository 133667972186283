import { useQuery } from '@tanstack/react-query';

import type { SearchParams, Attribute, PaginatedListJSON } from 'stores/PaginationTypes';
import PaginationURL from 'util/PaginationURL';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { ReportHistory } from 'report/types';
import { SOURCE_URL } from 'report/Constants';
import FiltersForQueryParams from 'components/common/EntityFilters/FiltersForQueryParams';
import { defaultOnError } from 'util/conditional/onError';

const INITIAL_DATA = {
  pagination: { total: 0 },
  list: [],
  attributes: [],
};

type Options = {
  enabled: boolean;
};

type PaginatedReportHistoryResponse = PaginatedListJSON & {
  elements: Array<ReportHistory>;
  attributes: Array<Attribute>;
};

export const fetchReportHistory = (reportId: string, searchParams: SearchParams) => {
  const url = PaginationURL(
    `${SOURCE_URL}/reports/${reportId}/history`,
    searchParams.page,
    searchParams.pageSize,
    searchParams.query,
    {
      sort: searchParams.sort.attributeId,
      order: searchParams.sort.direction,
      filters: FiltersForQueryParams(searchParams.filters),
    },
  );

  return fetch<PaginatedReportHistoryResponse>('GET', qualifyUrl(url)).then(
    ({ elements, total, count, page, per_page: perPage, attributes }) => ({
      list: elements,
      pagination: { total, count, page, perPage },
      attributes,
    }),
  );
};

const useReportHistory = (
  reportId: string,
  searchParams: SearchParams,
  { enabled }: Options = { enabled: true },
): {
  data: {
    list: Array<ReportHistory>;
    pagination: { total: number };
    attributes: Array<Attribute>;
  };
  refetch: () => void;
  isInitialLoading: boolean;
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    ['reports', 'history', reportId, searchParams],
    () =>
      defaultOnError(
        fetchReportHistory(reportId, searchParams),
        'Fetching report archive failed with status',
        'Could not retrieve report archive',
      ),
    {
      keepPreviousData: true,
      enabled,
    },
  );

  return {
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  };
};

export default useReportHistory;
