import * as React from 'react';
import { Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import { useCallback, useContext } from 'react';

import Input from 'components/bootstrap/Input';
import QueryValidation from 'views/components/searchbar/queryvalidation/QueryValidation';
import FormWarningsContext from 'contexts/FormWarningsContext';
import type { TimeRange } from 'views/logic/queries/Query';
import { ParameterDeclarationProvider } from 'search-filter/logic/ParameterDeclarationProvider';
import useView from 'search-filter/hooks/useView';
import ViewsQueryInput from 'views/components/searchbar/ViewsQueryInput';

type Props = {
  labelClassName?: string;
  wrapperClassName?: string;
  timeRange?: TimeRange;
  streams?: Array<string>;
};

const Container = styled.div`
  display: flex;
`;

const QueryStringFormGroup = ({
  labelClassName = 'col-sm-3',
  wrapperClassName = 'col-sm-9',
  timeRange = {} as TimeRange,
  streams = [],
}: Props) => {
  const view = useView();
  const { isValidating, handleSubmit, validateForm, isSubmitting, isValid } = useFormikContext<{
    queryString?: string;
  }>();
  const { warnings } = useContext(FormWarningsContext);
  const disableExecution = isSubmitting || isValidating || !isValid;
  const onSaveParametersSuccess = useCallback(
    ({ toggleShowDeclarationForm }: { toggleShowDeclarationForm: () => void }) => {
      toggleShowDeclarationForm();
    },
    [],
  );

  return (
    <Input
      id="queryString"
      labelClassName={labelClassName}
      wrapperClassName={wrapperClassName}
      help="The filter query will extend the main search query with the operator AND."
      label="Query">
      <Field name="queryString" label="Query" id="queryString-field" title="query">
        {({ field: { name, value, onChange }, meta: { error } }) => (
          <Container>
            <ViewsQueryInput
              value={value}
              view={view}
              placeholder="Type your search query here..."
              error={error}
              inputId={name}
              isValidating={isValidating}
              warning={warnings?.queryString}
              onChange={onChange}
              name={name}
              timeRange={timeRange}
              streams={streams}
              disableExecution={disableExecution}
              validate={validateForm}
              onExecute={handleSubmit as () => void}
            />
            <ParameterDeclarationProvider
              value={{
                onSaveParametersSuccess,
              }}>
              <QueryValidation />
            </ParameterDeclarationProvider>
          </Container>
        )}
      </Field>
    </Input>
  );
};

export default QueryStringFormGroup;
