import * as React from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Message } from 'views/components/messagelist/Types';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import { WatchlistActions, ApiRoutes } from 'watchlist/stores/WatchlistStore';
import defaultWatchlistFieldTypes from 'security-content/mappings/watchlistFields';
import type { WatchlistStatus } from 'watchlist/actions/WatchlistActions';
import type { WatchlistFields } from 'security-content/types';
import useLicenseCheck from 'license/useLicenseCheck';
import { parseIfArrayValue } from 'search/messageFieldValueHelper';

import WatchlistStatusContext from './WatchlistStatusContext';

export type WatchlistStatusCheck = {
  [watchlistFieldType: string]: { [fieldName: string]: any } | undefined;
};

type MessageFields = { [key: string]: any };

type Props = {
  children: React.ReactNode;
  message: Message;
  watchlistFieldTypes?: WatchlistFields;
};

const parseFieldValue = (messageFieldValue: string) => {
  const parsedArrayValue = parseIfArrayValue(messageFieldValue);

  return parsedArrayValue ?? messageFieldValue;
};

const getMatchingFields = (watchlistFields: Array<string>, messageFields: { [fieldName: string]: any }) =>
  watchlistFields.reduce((matches, fieldName) => {
    const messageFieldValue = messageFields[fieldName];

    if (messageFieldValue !== undefined) {
      return {
        ...matches,
        [fieldName]: parseFieldValue(messageFieldValue),
      };
    }

    return matches;
  }, {});

const getMatchingWatchlistFieldsByType = (watchlistFieldTypes: WatchlistFields, messageFields: MessageFields) =>
  Object.entries(watchlistFieldTypes).reduce((result, [watchlistFieldType, watchlistFields]) => {
    const matchingFields = getMatchingFields(watchlistFields, messageFields);

    if (Object.values(matchingFields).length !== 0) {
      return {
        ...result,
        [watchlistFieldType]: matchingFields,
      };
    }

    return result;
  }, {});

const checkWatchlistStatus = (fields: WatchlistStatusCheck) => {
  const url = qualifyUrl(ApiRoutes.WatchlistApiController.checkStatusForFields().url);

  return fetch('POST', url, fields).catch((error) =>
    UserNotification.error(`Checking watchlist status for message fields failed with status: ${String(error)}`),
  );
};

const filterEmptyLists = (fieldsWatchlistStatus: WatchlistStatus | undefined) => {
  if (!fieldsWatchlistStatus) {
    return undefined;
  }

  return Object.entries(fieldsWatchlistStatus).reduce((lists, [watchlistFieldType, watchlistFields]) => {
    if (!watchlistFields || watchlistFields.length === 0) {
      return lists;
    }

    return { ...lists, [watchlistFieldType]: watchlistFields };
  }, {});
};

const useRefetchOnStatusChange = (refetch: () => void, matchingWatchlistFieldsByType: WatchlistStatusCheck) => {
  const refetchOnDemand = useCallback(
    ({ payload: { watchlistFieldType, value: updatedValue } }) => {
      const shouldUpdate =
        Object.values(matchingWatchlistFieldsByType[watchlistFieldType]).find(
          (fieldValue) => fieldValue === updatedValue,
        ) !== undefined;

      if (shouldUpdate) {
        refetch();
      }
    },
    [matchingWatchlistFieldsByType, refetch],
  );

  useEffect(() => {
    const unlistenUpdate = WatchlistActions.update.completed.listen(refetchOnDemand);
    const unlistenDelete = WatchlistActions.delete.completed.listen(refetchOnDemand);

    return () => {
      unlistenUpdate();
      unlistenDelete();
    };
  }, [refetch, refetchOnDemand]);
};

const WatchlistStatusProvider = ({ children, message, watchlistFieldTypes = defaultWatchlistFieldTypes }: Props) => {
  const matchingWatchlistFieldsByType = getMatchingWatchlistFieldsByType(watchlistFieldTypes, message.fields);
  const shouldCheckStatus = Object.keys(matchingWatchlistFieldsByType).length !== 0;
  const {
    security: { isValid: isValidSecurityLicense },
  } = useLicenseCheck();
  const {
    data: fieldsWatchlistStatus,
    isLoading,
    refetch,
  } = useQuery(['watchlistStatus', message.id], () => checkWatchlistStatus(matchingWatchlistFieldsByType), {
    enabled: !!(shouldCheckStatus && isValidSecurityLicense),
  });

  useRefetchOnStatusChange(refetch, matchingWatchlistFieldsByType);

  const contextValue = useMemo(
    () => ({
      refetch,
      isLoading,
      status: filterEmptyLists(fieldsWatchlistStatus),
    }),
    [fieldsWatchlistStatus, isLoading, refetch],
  );

  return <WatchlistStatusContext.Provider value={contextValue}>{children}</WatchlistStatusContext.Provider>;
};

export default WatchlistStatusProvider;
