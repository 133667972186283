import React from 'react';

import { ExternalLink } from 'components/common';

const ThreatFoxAdapterDocumentation = () => (
  <div>
    <p>
      The abuse.ch ThreatFox tracker maintains a database of indicators of compromise (IOCs). This data adapter will
      automatically download the full ThreatFox database and periodically check for and download updates. You can learn
      more about ThreatFox by visiting the official site{' '}
      <ExternalLink href="https://threatfox.abuse.ch/">here</ExternalLink>
    </p>
  </div>
);

export default ThreatFoxAdapterDocumentation;
