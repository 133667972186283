import React, { useEffect, useState, useCallback, useRef } from 'react';

import { Spinner } from 'components/common';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';

import { OidcSessionActions } from './OidcSessionStore';
import OidcGenericLoginLink from './OidcGenericLoginLink';
import type { Configuration } from './OidcConfigurationStore';

import OktaLoginForm from '../okta/OktaLoginForm';

type Props = {
  configuration: Configuration;
  nonce: {
    state: string;
    redirectTo: string;
  };
  params: {
    state: string;
    code?: string;
    error_description?: string;
  };
  onErrorChange: (nextError: string) => void;
  regenerateNonce: () => void;
};

const OidcCallback = ({ configuration, nonce, onErrorChange, params, regenerateNonce }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const isRegenerated = useRef(false);

  const generateNonce = useCallback(() => {
    if (!isRegenerated.current) {
      regenerateNonce();
      isRegenerated.current = true;
    }
  }, [regenerateNonce]);

  useEffect(() => {
    const handleLoginFailure = () => {
      setLoginFailed(true);
      generateNonce();
    };

    if (loginFailed) {
      return () => {};
    }

    if (params.error_description) {
      onErrorChange(params.error_description);
      handleLoginFailure();

      return () => {};
    }

    if (nonce.state !== params.state) {
      onErrorChange(
        'A possible CSRF attempt has been detected, the state parameter does not match the request. Please contact an administrator.',
      );
      handleLoginFailure();

      return () => {};
    }

    setIsLoading(true);

    const promise = OidcSessionActions.login(params.code);

    promise.catch((error) => {
      if (error.status === 401) {
        onErrorChange('Login failed. This might be a temporary problem. Please try again.');
      } else {
        onErrorChange('Unknown error, please retry or contact an administrator.');
      }

      // Metrics for this failure are handled in the store
      handleLoginFailure();

      setIsLoading(false);

      return error;
    });

    return () => {
      if (promise) {
        promise.cancel();
      }
    };
  }, [generateNonce, loginFailed, nonce.state, onErrorChange, params]);

  if (isLoading) {
    return (
      <p className="loading-text">
        <Spinner text="Loading, please wait..." delay={0} />
      </p>
    );
  }

  return configuration.oauth_type === OKTA_TYPE_KEY ? (
    <OktaLoginForm configuration={configuration} nonce={nonce} />
  ) : (
    <OidcGenericLoginLink configuration={configuration} nonce={nonce} />
  );
};

export default OidcCallback;
