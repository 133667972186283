import * as React from 'react';

import HandlerContext from 'search-filter/contexts/HandlerContext';
import useHandlerContext from 'views/components/useHandlerContext';

const HandlerContextProvider = ({ children }: React.PropsWithChildren) => {
  const handlerContext = useHandlerContext();

  return <HandlerContext.Provider value={handlerContext}>{children}</HandlerContext.Provider>;
};

export default HandlerContextProvider;
