import { useQuery } from '@tanstack/react-query';

import { Illuminate } from '@graylog/enterprise-api';

import UserNotification from 'util/UserNotification';
import type FetchError from 'logic/errors/FetchError';
import { onError } from 'util/conditional/onError';

export const FILTER_ILLUMINATE_PACKS_KEY = 'filter_illuminate_packs';

const INITIAL_DATA = [];

export type IlluminatePacks = Array<IlluminatePack>;

export type IlluminatePack = {
  is_core: boolean;
  requirements: string[];
  release_notes: string;
  input_types: string[];
  pack_id: string;
  is_new: boolean;
  description: string;
  type: string;
  title: string;
  enabled: boolean;
  tags: string[];
  license: string[];
  is_compatible: boolean;
  version_requirements: string[];
  category: string;
  update_available: boolean;
  included_in_bundle: boolean;
};

type FilterArgs = {
  type?: 'PROCESSING' | 'SPOTLIGHT';
  inputType?: string;
};

const filterIlluminatePacks = async (filters: FilterArgs) => {
  const filterArgs = {
    query: undefined,
    category: undefined,
    tags: undefined,
    inputType: filters.inputType,
    is_new: undefined,
    update_available: undefined,
    enabled: undefined,
    type: filters.type,
    include_deps: undefined,
  };

  return Illuminate.filterPacks(...Object.values(filterArgs));
};

const useFilterIlluminatePacks = (
  filters: FilterArgs = {},
  enabled: boolean = true,
): {
  data: IlluminatePacks;
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<IlluminatePacks, FetchError>(
    [FILTER_ILLUMINATE_PACKS_KEY, filters.type, filters.inputType],
    () =>
      onError(filterIlluminatePacks(filters), (errorThrown: FetchError) => {
        if (!(errorThrown.status === 404)) {
          UserNotification.error(`Loading Illuminate packs failed with: ${errorThrown}`);
        }
      }),
    { enabled },
  );

  return {
    data: data ?? INITIAL_DATA,
    isLoading,
  };
};

export default useFilterIlluminatePacks;
