import * as React from 'react';

import { Collapsible } from 'common/components';
import { Row } from 'security-app/components/common/FormBaseStyles';
import { DataWell } from 'security-app/components/common';
import type { Pack } from 'illuminate/types';

type Props = {
  pack: Pack;
};

function Title({ pack }: Props) {
  return (
    <Row $gap="0.5rem" $align="center">
      <span>{pack.title}</span>
    </Row>
  );
}

function PackItem({ pack }) {
  return (
    <Collapsible title={<Title pack={pack} />}>
      <DataWell style={{ whiteSpace: 'break-spaces' }}>{pack.description}</DataWell>
    </Collapsible>
  );
}

export default PackItem;
