import { useMutation, useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { LICENSES_QUERY_KEY } from 'license/hooks/useLicenses';
import { LICENSES_VALIDITY_KEY } from 'license/hooks/useLicenseValidityForSubject';
import { ACTIVE_LICENSE_QUERY_KEY } from 'license/hooks/useActiveLicenseInformation';

const saveLicense = async (token: string) => {
  const url = qualifyUrl(EnterpriseApiRoutes.License.save().url);

  return fetch('POST', url, token);
};

const verifyLicense = async (token: string) => {
  const url = qualifyUrl(EnterpriseApiRoutes.License.verify().url);

  return fetch('POST', url, token);
};

const deleteLicense = async ({ licenseId, contractId }: { licenseId?: string; contractId?: string }) => {
  const params = [];

  if (licenseId) {
    params.push(`license_id=${licenseId}`);
  }
  if (contractId) {
    params.push(`contract_id=${contractId}`);
  }

  const url = qualifyUrl(EnterpriseApiRoutes.License.delete(params.join('&')).url);

  return fetch('DELETE', url);
};

const useLicenseMutation = () => {
  const queryClient = useQueryClient();

  const save = useMutation(saveLicense, {
    onError: (errorThrown) => {
      UserNotification.error(`Saving license failed with status: ${errorThrown}`, 'Could not save license');
    },
    onSuccess: () => {
      UserNotification.success('License has been successfully saved.', 'Success!');

      queryClient.invalidateQueries([LICENSES_VALIDITY_KEY]);
      queryClient.refetchQueries({ queryKey: [LICENSES_QUERY_KEY], type: 'active' });

      return queryClient.refetchQueries({ queryKey: [ACTIVE_LICENSE_QUERY_KEY], type: 'active' });
    },
  });

  const verify = useMutation(verifyLicense, {
    onError: (errorThrown) => {
      UserNotification.error(`Verifying license failed with status: ${errorThrown}`, 'Could not verify license');
    },
    onSuccess: () => {
      UserNotification.success('License has been successfully verified.', 'Success!');
    },
  });

  const remove = useMutation(deleteLicense, {
    onError: (errorThrown) => {
      UserNotification.error(`Deleting license failed with status: ${errorThrown}`, 'Could not delete license');
    },
    onSuccess: () => {
      UserNotification.success('License has been successfully deleted.', 'Success!');

      queryClient.invalidateQueries([LICENSES_VALIDITY_KEY]);
      queryClient.refetchQueries({ queryKey: [LICENSES_QUERY_KEY], type: 'active' });

      return queryClient.refetchQueries({ queryKey: [ACTIVE_LICENSE_QUERY_KEY], type: 'active' });
    },
  });

  return {
    saveLicense: save.mutateAsync,
    verifyLicense: verify.mutateAsync,
    deleteLicense: remove.mutateAsync,
  };
};

export default useLicenseMutation;
