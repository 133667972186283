import React, { useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './Routes';

import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { connectionString } = formData;

  const handleSubmit = () => {
    const fetcher = fetch('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.Mimecast.CHECK_CREDENTIALS), {
      base_url: formData?.baseUrl?.value || '',
      access_key: formData?.accessKey?.value || '',
      secret_key: formData?.secretKey?.value || '',
      application_id: formData?.applicationId?.value || '',
      application_key: formData?.applicationKey?.value || '',
    });

    fetcher
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message: 'Unable to connect to Mimecast using provided configuration.',
          });
        }
      })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message: 'Unable to connect to Mimecast using provided configuration.',
        });
      });
  };

  const isFormValid = formValidation.isFormValid(
    ['mimecastName', 'baseUrl', 'accessKey', 'secretKey', 'applicationId', 'applicationKey'],
    formData,
  );

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      className="col-md-7"
      title=""
      error={formError}
      description="">
      <ValidatedInput
        className=""
        id="mimecastName"
        type="text"
        fieldData={formData.mimecastName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={connectionString?.value}
        required
      />

      <ValidatedInput
        className=""
        id="baseUrl"
        type="text"
        fieldData={formData.baseUrl}
        onChange={onChange}
        label="Mimecast Base Url"
        help="The base API url for your Mimecase instance. (e.g. https://<region>-api.mimecast.com)"
        autoComplete="off"
        required
      />

      <ValidatedInput
        className=""
        id="applicationId"
        type="text"
        onChange={onChange}
        fieldData={formData.applicationId}
        label="Application ID"
        help="The ID of the Mimecast API application."
        required
      />

      <ValidatedInput
        className=""
        id="applicationKey"
        type="password"
        onChange={onChange}
        fieldData={formData.applicationKey}
        label="Application Key"
        help="The application key used for authentication."
        required
      />

      <ValidatedInput
        className=""
        id="accessKey"
        type="text"
        onChange={onChange}
        fieldData={formData.accessKey}
        label="Access Key"
        help="The Access Key for the authorized API user account."
        required
      />

      <ValidatedInput
        className=""
        id="secretKey"
        type="password"
        onChange={onChange}
        fieldData={formData.secretKey}
        label="Secret Key"
        help="The Secret Key for the authorized API user account."
        required
      />
    </FormWrap>
  );
};

export default StepAuthorize;
