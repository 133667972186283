import * as React from 'react';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { PluginStore } from 'graylog-web-plugin/plugin';

import { Select } from 'components/common';
import { defaultCompare } from 'logic/DefaultCompare';
import {
  Input,
  Alert,
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  FormGroup,
  HelpBlock,
  Row,
} from 'components/bootstrap';
import type { ConfigurationFieldWithEncryption } from 'components/configurationforms';

import InputConfigurationForm from './InputConfigurationForm';

import type {
  Input as ForwarderInput,
  InputFormContent,
  ForwarderInputTypes,
  ForwarderInputDescriptions,
} from '../Types';

const formatInputTypeOptions = (inputTypes: ForwarderInputTypes) => {
  const inputTypesEntries = Object.entries(inputTypes);
  const options = inputTypesEntries.map(([id, label]) => ({ value: id, label: label, disabled: false }));
  options.sort((inputTypeA, inputTypeB) => defaultCompare(inputTypeA.label, inputTypeB.label));

  if (options.length === 0) {
    options.push({ value: 'none', label: 'No inputs available', disabled: true });
  }

  return options;
};

const CustomInputWrapper = styled.div`
  padding: 15px;
`;

type Props = {
  action?: 'create' | 'edit';
  input?: ForwarderInput;
  inputTypes: ForwarderInputTypes;
  inputDescriptions: ForwarderInputDescriptions;
  onCancel: () => void;
  onSubmit: (inputForm: InputFormContent) => void;
};

const defaultFormValues = { type: undefined, title: '', configuration: {} };

const InputForm = ({ action = 'create', input, inputTypes, inputDescriptions, onCancel, onSubmit }: Props) => {
  const [configurationFieldStates, setConfigurationFieldStates] = useState<{ [key: string]: { dirty?: boolean } }>({});

  const isConfigurationFieldDirty = (field: string): boolean => {
    if (!configurationFieldStates[field]) {
      return false;
    }

    return configurationFieldStates[field] && configurationFieldStates[field].dirty;
  };

  const encryptedSubmitValues = (type: string, configurationValues: { [key: string]: any }): { [key: string]: any } => {
    if (!type || !configurationValues) {
      return configurationValues;
    }

    const newEncryptedConfigurationValues = {};

    const cleanFields = Object.keys(configurationValues).filter((field) => !isConfigurationFieldDirty(field));

    const encryptedCleanFields = cleanFields.filter(
      (field) =>
        (inputDescriptions[type].requested_configuration[field] as ConfigurationFieldWithEncryption).is_encrypted,
    );

    encryptedCleanFields.forEach((field) => {
      if (configurationValues[field].is_set !== undefined) {
        newEncryptedConfigurationValues[field] = { keep_value: true };
      }
    });

    return { ...configurationValues, ...newEncryptedConfigurationValues };
  };

  const handleSubmit = ({ type, title, configuration }) => {
    const configurationValues = encryptedSubmitValues(type, configuration);
    onSubmit({ type, title, configuration: configurationValues }); // Ensure we only propagate expected fields
  };

  const _getCustomInputComponent = (inputType: string): React.ComponentType<any> | void => {
    const customConfiguration = PluginStore.exports('inputConfiguration').find(
      (inputConfig) => inputConfig.type === inputType,
    );

    return customConfiguration?.embeddedComponent;
  };

  let initialValues;

  if (action === 'edit' && input) {
    const { type, title, configuration } = input;
    initialValues = { type, title, configuration };
  } else {
    initialValues = defaultFormValues;
  }

  const handleConfigurationFieldChange = (
    field: string,
    value: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    dirty: boolean = true,
  ) => {
    const configurationFieldState = configurationFieldStates[field];
    setConfigurationFieldStates({ ...configurationFieldStates, [field]: { ...configurationFieldState, ...{ dirty } } });
    setFieldValue(`configuration.${field}`, value);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, setFieldValue }) => {
        const inputDescription = values.type ? inputDescriptions[values.type] : undefined;
        const CustomInputComponent = action === 'create' && values.type && _getCustomInputComponent(values.type);

        return (
          <Row>
            <Col md={7}>
              <Form>
                {action === 'create' && (
                  <FormGroup controlId="inputType">
                    <ControlLabel>Input Type</ControlLabel>
                    <Select
                      id="inputType"
                      name="inputType"
                      placeholder="Select Input Type"
                      options={formatInputTypeOptions(inputTypes)}
                      matchProp="label"
                      onChange={(option) => {
                        setFieldValue('type', option);
                        setFieldValue('configuration', {});
                      }}
                      value={values.type}
                    />
                    <HelpBlock>Select the Input type you want to create.</HelpBlock>
                  </FormGroup>
                )}
                {inputDescription &&
                  (CustomInputComponent ? (
                    <Alert bsStyle="info">
                      This Input provides a custom form, follow the steps shown below to create the Forwarder Input.
                    </Alert>
                  ) : (
                    <>
                      <Input
                        id="title"
                        name="title"
                        label="Title"
                        type="text"
                        value={values.title}
                        onChange={handleChange}
                        help="Meaningful name used to identify this Input."
                        required
                      />
                      <InputConfigurationForm
                        type={values.type}
                        handleConfigurationChange={(field, value, dirty) =>
                          handleConfigurationFieldChange(field, value, setFieldValue, dirty)
                        }
                        inputDescription={inputDescription}
                        setDefaultValues={(defaultValues) => setFieldValue('configuration', defaultValues)}
                        configurationFieldStates={configurationFieldStates}
                        values={values.configuration}
                      />
                    </>
                  ))}
                {!CustomInputComponent && (
                  <ButtonToolbar>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button bsStyle="primary" type="submit">
                      {action === 'create' ? 'Create' : 'Update'} Input
                    </Button>
                  </ButtonToolbar>
                )}
              </Form>
            </Col>
            {CustomInputComponent && (
              <Col md={12}>
                <hr />
                <CustomInputWrapper>
                  <CustomInputComponent onSubmit={handleSubmit} />
                </CustomInputWrapper>
              </Col>
            )}
          </Row>
        );
      }}
    </Formik>
  );
};

export default InputForm;
