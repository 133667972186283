import styled, { css } from 'styled-components';

export const Description = styled.p(
  ({ theme }) => css`
    white-space: pre-wrap;
    margin-top: ${theme.spacings.xl};
  `,
);

export default Description;
