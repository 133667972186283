import React, { useCallback, useState } from 'react';

import type { FormValues } from 'data-lake/preview/SearchBar';
import { Button } from 'components/bootstrap';
import RetrievalArchiveModal from 'data-lake/data-lake-overview/actions/RetrievalArchiveModal';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { FieldsOperator, FieldFilters } from 'data-lake/Types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

type Props = {
  fieldsOperator: FieldsOperator;
  fieldFilters: Array<FieldFilters>;
  stream: FormValues['stream'];
  timerange: AbsoluteTimeRange;
};

const RetrieveLogsAction = ({ fieldsOperator, fieldFilters, stream, timerange }: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const sendTelemetry = useSendTelemetry();
  const onToggleModal = useCallback(() => {
    if (!showRetrievalModal) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.RETRIEVE_LOGS_BUTTON_CLICK, {
        app_pathname: '/data-storage/preview',
        app_section: 'log-view-widget',
      });
    }
    setShowRetrievalModal((cur) => !cur);
  }, [sendTelemetry, showRetrievalModal]);

  return (
    <>
      <Button onClick={onToggleModal} title="Retrieve logs" bsSize="xsmall">
        Retrieve logs
      </Button>
      {showRetrievalModal && (
        <RetrievalArchiveModal
          archiveStreamId={stream}
          onToggleModal={onToggleModal}
          defaultTimeRange={timerange}
          defaultFieldOperator={fieldsOperator}
          defaultFieldFilters={fieldFilters}
        />
      )}
    </>
  );
};

export default RetrieveLogsAction;
