import * as React from 'react';

const ModalContext = React.createContext(null);

export function ModalProvider<ModalTypes, EntityType = unknown>({ children }: { children: React.ReactNode }) {
  const [modal, setModal] = React.useState<ModalTypes>(null);
  const [entity, setEntity] = React.useState<EntityType>();
  const value = React.useMemo(() => ({ modal, setModal, entity, setEntity }), [modal, entity]);

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

export function useModalContext() {
  const context = React.useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }

  return context;
}
