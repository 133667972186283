import React from 'react';

import Spinner from 'components/common/Spinner';
import ClusterManagementTeaserSearch from 'components/datanode/ClusterManagement/ClusterManagementTeaserSearch';
import viewJson from 'components/datanode/ClusterManagement/sample-dashboards/overview_view.json';
import type { ViewJson } from 'views/logic/views/View';

import DynamicSearch from './DynamicSearch';
import useIsDatanodeConfigured from './useIsDatanodeConfigured';

import LicenseCheck from '../license/LicenseCheck';

const EnterpriseLicenseSubject = '/license/enterprise';

const ClusterManagementSearch = () => {
  const { isDatanodeConfigured, isLoading } = useIsDatanodeConfigured();

  return (
    <LicenseCheck licenseSubject={EnterpriseLicenseSubject} displayLicenseWarning={false}>
      {({ licenseIsValid }) => {
        if (isLoading) {
          return <Spinner />;
        }

        if (licenseIsValid && isDatanodeConfigured) {
          return <DynamicSearch viewJson={viewJson as unknown as ViewJson} />;
        }

        return <ClusterManagementTeaserSearch />;
      }}
    </LicenseCheck>
  );
};

export default ClusterManagementSearch;
