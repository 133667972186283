import type { License } from 'license/types';
import { LICENSE_TYPE, DRAWDOWN_PRODUCTS, LICENSE_SUBJECTS } from 'license/constants';

export const isEnterpriseLicense = (activeLicense?: License): boolean => {
  if (!activeLicense) return false;

  if (activeLicense.license_type === LICENSE_TYPE.drawDown) {
    return activeLicense.products.split(',').some((product) => product === DRAWDOWN_PRODUCTS.enterprise);
  }

  if (activeLicense.license_type === LICENSE_TYPE.dailyUsage) {
    return activeLicense.products === LICENSE_SUBJECTS.enterprise;
  }

  return false;
};

export default isEnterpriseLicense;
