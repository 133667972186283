import React from 'react';
import { Field } from 'formik';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';

const AWS_AUTH_TYPES_OPTIONS = [
  { value: 'automatic', label: 'Automatic' },
  { value: 'keysecret', label: 'Key & Secret' },
];
type Props = {
  updateAuthenticationType?: (option: string) => void;
  currentType?: string;
};

const AWSAuthenticationTypeFormGroup = ({ updateAuthenticationType = () => {}, currentType = 'automatic' }: Props) => (
  <Field>
    {() => (
      <Input id="default-aws-auth-type-select" label="AWS Authentication Type">
        <Select
          id="awsAuthenticationType"
          name="AWSAuthenticationType"
          placeholder="Select Authentication Type"
          options={AWS_AUTH_TYPES_OPTIONS}
          matchProp="label"
          onChange={(option: string) => {
            updateAuthenticationType(option);
          }}
          value={currentType}
        />
      </Input>
    )}
  </Field>
);

export default AWSAuthenticationTypeFormGroup;
