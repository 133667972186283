import { Map } from 'immutable';

import type { WidgetState } from 'views/logic/widgets/Widget';
import Widget from 'views/logic/widgets/Widget';
import type { TimeRange } from 'views/logic/queries/Query';
import isDeepEqual from 'stores/isDeepEqual';
import isEqualForSearch from 'views/stores/isEqualForSearch';
import DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';

export default class DataLakeWidget extends Widget {
  constructor(
    id: string,
    config: DataLakeWidgetConfig,
    timerange: TimeRange | undefined | null,
    streams: Array<string> = [],
  ) {
    super(id, DataLakeWidget.type, config, undefined, timerange, undefined, streams, undefined, undefined);
  }

  static type = 'iceberg-rows';

  static defaultTitle = 'Untitled Log View';

  // eslint-disable-next-line class-methods-use-this
  get isExportable() {
    return true;
  }

  static fromJSON(value: WidgetState) {
    const { id, config, timerange, streams } = value;

    return new DataLakeWidget(id, DataLakeWidgetConfig.fromJSON(config), timerange, streams);
  }

  equals(other: any) {
    if (other instanceof DataLakeWidget) {
      return ['id', 'config', 'timerange', 'streams'].every((key) => isDeepEqual(this._value[key], other[key]));
    }

    return false;
  }

  equalsForSearch(other: any) {
    if (other instanceof DataLakeWidget) {
      return ['id', 'config', 'timerange', 'streams'].every((key) => isEqualForSearch(this._value[key], other[key]));
    }

    return false;
  }

  toBuilder() {
    const { id, config, timerange, streams } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Map({ id, config, timerange, streams }));
  }

  static builder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder();
  }
}

class Builder extends Widget.Builder {
  build(): DataLakeWidget {
    const { id, config, timerange, streams } = this.value.toObject();

    return new DataLakeWidget(id, config, timerange, streams);
  }
}
