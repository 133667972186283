import Reflux from 'reflux';

import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import inputsUrl from 'forwarder/forwarders/inputsUrl';

import type { Input, InputFormContent } from '../Types';

export type PaginatedForwarderInputsResponse = {
  total: number;
  page: number;
  per_page: number;
  count: number;
  forwarder_inputs: Array<Input>;
  grand_total: number;
};
type ForwarderInputRequestParams = {
  inputProfileId?: string;
  query?: string;
  page?: number;
  pageSize?: number;
};

type ForwarderInputsActionsType = {
  list: (params: ForwarderInputRequestParams) => Promise<PaginatedForwarderInputsResponse>;
  listAll: (params: ForwarderInputRequestParams) => Promise<PaginatedForwarderInputsResponse>;
  create: (inputProfileId: string, input: InputFormContent) => Promise<Input>;
  update: (inputProfileId: string, inputId: string, input: InputFormContent) => Promise<Input>;
  delete: (inputProfileId: string, input: Input) => Promise<void>;
};
type ForwarderInputsStoreState = {
  forwarderInputs: {
    [key: string]: Array<Input>;
  };
  pagination: {
    total: number;
    count: number;
    page: number;
    perPage: number;
    query: string;
  };
};
export const ForwarderInputsActions = Reflux.createActions<ForwarderInputsActionsType>({
  list: { asyncResult: true },
  listAll: { asyncResult: true },
  create: { asyncResult: true },
  update: { asyncResult: true },
  delete: { asyncResult: true },
});

const ForwarderInputsStore = Reflux.createStore<ForwarderInputsStoreState>({
  listenables: [ForwarderInputsActions],
  forwarderInputs: {},
  pagination: {
    total: 0,
    count: 0,
    page: 1,
    perPage: 10,
    query: '',
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    return {
      forwarderInputs: this.forwarderInputs,
      pagination: this.pagination,
    };
  },

  propagateUpdate() {
    this.trigger(this.getState());
  },

  list({ inputProfileId, query = '', page = 1, pageSize = 10 }) {
    const promise = fetch(
      'GET',
      inputsUrl({
        segments: ['profiles', inputProfileId, 'inputs'],
        query: {
          query: query,
          page: page,
          per_page: pageSize,
        },
      }),
    );

    promise.then(
      (response: PaginatedForwarderInputsResponse) => {
        this.forwarderInputs = { ...this.forwarderInputs, [inputProfileId]: response.forwarder_inputs };

        this.pagination = {
          total: response.total,
          count: response.count,
          page: response.page,
          perPage: response.per_page,
          query: query,
        };

        this.propagateUpdate();

        return response;
      },
      (error) => {
        UserNotification.error(`Retrieving Forwarder Input failed with status: ${error.message},
        Could not retrieve Forwarder Inputs`);
      },
    );

    ForwarderInputsActions.list.promise(promise);
  },

  listAll({ inputProfileId, query = '', page = 1, pageSize = 0 }) {
    const promise = fetch(
      'GET',
      inputsUrl({
        segments: ['profiles', inputProfileId, 'inputs'],
        query: {
          query: query,
          page: page,
          per_page: pageSize,
        },
      }),
    );

    promise.then(
      (response: PaginatedForwarderInputsResponse) => {
        this.forwarderInputs = { ...this.forwarderInputs, [inputProfileId]: response.forwarder_inputs };

        this.pagination = {
          total: response.total,
          count: response.count,
          page: response.page,
          perPage: response.per_page,
          query: query,
        };

        this.propagateUpdate();

        return response;
      },
      (error) => {
        UserNotification.error(`Retrieving Forwarder Input failed with status: ${error.message},
        Could not retrieve Forwarder Inputs`);
      },
    );

    ForwarderInputsActions.listAll.promise(promise);
  },
  create(inputProfileId: string, input: InputFormContent) {
    const promise = fetch('POST', inputsUrl({ segments: ['profiles', inputProfileId, 'inputs'] }), input);

    promise.then(
      (response) => {
        UserNotification.success('Forwarder Input created successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Forwarder Input create failed with status: ${error.message},
        Could not create Forwarder Input`);
      },
    );

    ForwarderInputsActions.create.promise(promise);
  },

  update(inputProfileId: string, inputId: string, input: InputFormContent) {
    const promise = fetch('PUT', inputsUrl({ segments: ['profiles', inputProfileId, 'inputs', inputId] }), input);

    promise.then(
      (response) => {
        UserNotification.success('Forwarder Input updated successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Forwarder Input update failed with status: ${error.message},
        Could not update Forwarder Input`);
      },
    );

    ForwarderInputsActions.update.promise(promise);
  },

  delete(inputProfileId: string, input: Input) {
    const promise = fetch('DELETE', inputsUrl({ segments: ['profiles', inputProfileId, 'inputs', input.id] }));

    promise.then(
      (response) => {
        UserNotification.success('Forwarder Input deleted successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Forwarder Input delete failed with status: ${error.message},
        Could not delete Forwarder Input`);
      },
    );

    ForwarderInputsActions.delete.promise(promise);
  },
});

export default ForwarderInputsStore;
