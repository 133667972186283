import React, { useEffect } from 'react';

import { isEnterpriseLicense } from 'license/helper/activeLicense';
import useActiveLicenseInformation from 'license/hooks/useActiveLicenseInformation';
import { LICENSE_TYPE } from 'license/constants';
import { TrafficGraphWithDaySelect } from 'components/common/Graph';
import { useStore } from 'stores/connect';
import { ClusterTrafficActions, ClusterTrafficStore } from 'stores/cluster/ClusterTrafficStore';
import useGraphDays from 'components/common/Graph/contexts/useGraphDays';

const EnterpriseTrafficGraph = () => {
  const { graphDays } = useGraphDays();
  const { traffic } = useStore(ClusterTrafficStore);
  const {
    data: activeLicenseData,
    isLoading: isLoadingActiveLicense,
    isError: isErrorActiveLicense,
  } = useActiveLicenseInformation();

  const getTrafficLimit = () => {
    if (isLoadingActiveLicense || isErrorActiveLicense || !activeLicenseData) {
      return undefined;
    }

    if (!isEnterpriseLicense(activeLicenseData)) {
      return undefined;
    }

    if (activeLicenseData.license_type === LICENSE_TYPE.drawDown) {
      return undefined;
    }

    const {
      details: { traffic_limit: trafficLimit },
    } = activeLicenseData;

    return trafficLimit;
  };

  useEffect(() => {
    if (graphDays) {
      ClusterTrafficActions.getTraffic(graphDays);
    }
  }, [graphDays]);

  return <TrafficGraphWithDaySelect traffic={traffic?.output} trafficLimit={getTrafficLimit()} />;
};

export default EnterpriseTrafficGraph;
