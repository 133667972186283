import * as React from 'react';

import { useBulkAddNotesForSecurityEvents } from 'security-app/hooks/useSecurityEventsAPI';
import AddNotesModal from 'security-app/components/SecurityEvents/Alerts/AlertsModals/AddNotesModal/AddNotesModal';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';
import useRefetchTable from 'security-app/components/SecurityEvents/Alerts/AlertsModals/UsersModal/useRefetchTable';

type Props = {
  eventIds: Array<string>;
  onClose: () => void;
  show: boolean;
};

const AddNotesBulk = ({ eventIds, onClose, show }: Props) => {
  const { addNotes } = useBulkAddNotesForSecurityEvents();
  const refetch = useRefetchTable();
  const sendEventActionTelemetry = useSendEventActionTelemetry();

  const onConfirm = async (notes: string) => {
    await addNotes({ ids: eventIds, notes });
    sendEventActionTelemetry('ADD_NOTES', true);

    refetch();
    onClose();
  };

  return show ? <AddNotesModal closeModal={onClose} onConfirm={onConfirm} show /> : null;
};

export default AddNotesBulk;
