import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import { FormDataContext } from './context/FormData';
import { AdvancedOptionsContext } from './context/AdvancedOptions';
import type { HandleFieldUpdateType, FormDataContextType, AdvancedOptionsContextType } from './types';

import AdditionalFields from '../common/AdditionalFields';

interface Props {
  onChange: HandleFieldUpdateType;
}
const StyledAdditionalFields = styled(AdditionalFields)`
  margin: 0 0 35px;
`;

const containerStyle = {
  display: 'flex',
};

const hoursStyle = {
  paddingTop: '30px',
  paddingLeft: '10px',
  fontWeight: 'bold',
} as const;

const FormAdvancedOptions = ({ onChange }: Props) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } =
    useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const { salesforceThrottleEnabled, accessTokenExpiresIn } = formData;

  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  return (
    <StyledAdditionalFields title="Advanced Options" visible={isAdvancedOptionsVisible} onToggle={handleToggle}>
      <Input
        id="salesforceThrottleEnabled"
        type="checkbox"
        value="enable-throttling"
        defaultChecked={salesforceThrottleEnabled?.value}
        onChange={onChange}
        label="Enable Throttling"
        help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled."
      />
      <div style={containerStyle}>
        <Input
          id="accessTokenExpiresIn"
          name="accessTokenExpiresIn"
          type="number"
          value={accessTokenExpiresIn.value || accessTokenExpiresIn.defaultValue || ''}
          min="1"
          step="1"
          onChange={onChange}
          required
          help="Determines how often (in hours) the Salesforce API access token will expire. Default value is 1 hour."
          label="Salesforce Access Token Expires In"
        />
        <span style={hoursStyle}>Hours</span>
      </div>
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
