import React, { useContext, useState, useEffect } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';

import { renderOptions } from '../common/Options';
import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';
import handleFileUpload from '../helpers/fileReader';

type StepAuthorizeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepAuthorize = ({ onSubmit, onChange }: StepAuthorizeProps) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    fetch('GET', qualifyUrl(ApiRoutes.INTEGRATIONS.GCP.GET_GCP_REGIONS)).then((response) => {
      const respRegions = Object.keys(response).map((key) => ({ label: response[key] }));
      setRegions(respRegions);
    });
  }, []);

  const { formData } = useContext(FormDataContext);

  const isFormValid = formValidation.isFormValid(['gcpName', 'projectId', 'clientId', 'serviceAccountKey'], formData);

  const onFileUpload = (e) => {
    e.persist();
    handleFileUpload(e.target.files[0], (fileName, fileContent) => onChange(e, { fileName, fileContent }));
  };

  const accountKeyHelpText =
    formData.serviceAccountKey && formData.serviceAccountKey.fileName
      ? `Currently using file: ${formData.serviceAccountKey.fileName}.`
      : 'Service account key JSON.';

  return (
    <FormWrap onSubmit={onSubmit} buttonContent="Verify &amp; Proceed" disabled={isFormValid} title="" description="">
      <ValidatedInput
        id="gcpName"
        type="text"
        fieldData={formData.gcpName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        required
      />

      <ValidatedInput
        id="projectId"
        type="text"
        fieldData={formData.projectId}
        onChange={onChange}
        label="Project ID"
        help="The GUID of the project to which the logs belong."
        autoComplete="off"
        required
      />

      <ValidatedInput
        id="clientId"
        type="text"
        onChange={onChange}
        fieldData={formData.clientId}
        help="The GUID of your application."
        label="Application (client) ID"
        required
      />

      <ValidatedInput
        id="serviceAccountKey"
        type="file"
        onChange={onFileUpload}
        fieldData={formData.serviceAccountKey}
        label="Service account key"
        help={accountKeyHelpText}
        accept=".json"
        required
      />
      <ValidatedInput
        type="select"
        id="gcpRegion"
        onChange={onChange}
        fieldData={formData.gcpRegion}
        help="Choose the region where the Bigquery dataset can be created (optional)."
        label="BigQuery Dataset Region">
        {renderOptions(regions, 'Select a region', false)}
      </ValidatedInput>
    </FormWrap>
  );
};

export default StepAuthorize;
